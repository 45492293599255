import * as React from "react";
import { motion } from "framer-motion";
import { useModal, usePreventScroll } from "@react-aria/overlays";
import { AriaDialogProps } from "@react-types/dialog";
import { FocusScope } from "@react-aria/focus";

import { useDrawers } from "../contexts/Drawers";
import DrawerButton, { DrawerButtonProps } from "./DrawerButton";
import DrawerHeader, { DrawerHeaderProps } from "./DrawerHeader";

const sizes = {
  xs: "31.25rem",
  sm: "31.25rem",
  md: "31.25rem",
  lg: "31.25rem",
  xl: "31.25rem",
};

// TODO: Add React Aria Dialog & Overlay Options to DrawerProps
export interface DrawerProps extends AriaDialogProps {
  anchor?: "bottom" | "left" | "right" | "top";
  drawerButtonProps?: DrawerButtonProps;
  drawerHeaderProps?: DrawerHeaderProps;
  hideBackButton?: boolean;
  hideBackdrop?: boolean;
  hideHeader?: boolean;
  isDismissable?: boolean;
  maxWidth?: keyof typeof sizes;
  onClose?: (e?: React.MouseEvent) => void;
  // Use render via the context to pass a functional component and avoid confusion with React children
  // Fix for "Warning: Functions are not valid as a React child."
  render?: () => React.ReactNode;
  title?: string;
}

function Backdrop({
  hideBackdrop = true,
  ...props
}: {
  hideBackdrop?: boolean;
}) {
  return (
    <motion.div
      {...props}
      initial={{ opacity: 0 }}
      animate={{ opacity: hideBackdrop ? 0 : 0.3 }}
      exit={{ opacity: 0 }}
      style={{
        alignItems: "center",
        background: "var(--drawer-mask-background)",
        display: "flex",
        inset: 0,
        justifyContent: "center",
        overflow: "hidden",
        position: "fixed",
        zIndex: 1200,
      }}
    />
  );
}

Backdrop.defaultProps = {
  hideBackdrop: true,
};

/**
 * Very basic responsive drawer with optional props for reusability and flexibility.
 * Built-in basic header, back button and footer button.
 * Designed to work in isolation without the Drawers context.
 */
export default function Drawer({
  anchor = "right",
  closeButtonProps,
  drawerButtonProps = {},
  drawerHeaderProps,
  hideBackdrop = true,
  hideHeader,
  maxWidth = "md",
  render,
  title = "",
  ...props
}: DrawerProps & {
  // Type definitions for props that are not included in the context drawer state
  children?: React.ReactNode;
  closeButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}) {
  const { children, isDismissable = true } = props;

  const ref = React.useRef<HTMLDivElement>(null);
  const { drawerRef, dialogProps, overlayProps, titleProps, underlayProps } =
    useDrawers(props, ref);

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();

  const showDrawerButton = Boolean(Object.keys(drawerButtonProps).length);

  return (
    <motion.div
      data-testid="drawer"
      style={{ position: "fixed", inset: 0, zIndex: 3999 }}
    >
      <Backdrop
        {...underlayProps}
        {...(isDismissable && closeButtonProps)}
        {...{
          hideBackdrop,
        }}
      />
      <FocusScope contain restoreFocus autoFocus>
        <div
          {...overlayProps}
          {...dialogProps}
          {...modalProps}
          ref={drawerRef || ref}
          style={{
            outline: "none",
          }}
        >
          <motion.div
            className="universal-drawer"
            transition={{ ease: "easeInOut", duration: 0.3 }}
            initial={{
              opacity: 0,
              [anchor]: "-100%",
            }}
            animate={{
              opacity: 1,
              [anchor]: 0,
            }}
            exit={{
              opacity: 0,
              [anchor]: "-100%",
            }}
            style={{
              background: "var(--drawer-background)",
              boxShadow: "var(--drawer-boxShadow)",
              display: "flex",
              flex: "1 0 auto",
              flexDirection: "column",
              outline: 0,
              overflowX: "hidden",
              overflowY: "scroll",
              zIndex: 1201,
              position: "fixed",
              top: 0,
              height: "100%",
              minHeight: "100vh",
              ...((anchor === "top" || anchor === "bottom") && {
                maxHeight: sizes[maxWidth],
              }),
              // ...((anchor === "left" || anchor === "right") && {
              //   maxWidth: sizes[maxWidth],
              // }),
            }}
          >
            {!hideHeader && ( // Visible by default for accessibilty reasons
              <DrawerHeader
                {...{
                  title,
                  titleProps,
                  backButtonProps: closeButtonProps,
                  ...drawerHeaderProps, // Overwrite DrawerHeader props
                }}
              />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: "1",
                height: "100%",
              }}
            >
              {children || render()}
            </div>
            {showDrawerButton && ( // Hidden by default, use drawerButtonProps to include
              <DrawerButton
                {...{
                  buttonProps: closeButtonProps, // Drawer button default action is to close the drawer
                  label: "Close",
                  ...drawerButtonProps, // Overwrite DrawerButton props
                }}
              />
            )}
          </motion.div>
        </div>
      </FocusScope>
    </motion.div>
  );
}

Drawer.defaultProps = {
  children: null,
  closeButtonProps: {},
};
