import * as React from "react";
import { getDisplayName } from "utils/helpers/other";
import { useTemplateContext } from "components/providers/Template";
import type { StyleKey } from "../../@types/theme";

/**
 * renamed this file to `withStyle`  (from `withTemplate`)
 * as its a better name for what it does. That is add a style prop to the component
 *
 * @param {Component} Component - React component to inject style prop into
 * @param {string} name - key name of the style value inside theme.json to source theme value from
 */

export interface WithStyleProps {
  style?: StyleKey;
}

function withStyle<P>(
  Component: React.ComponentType<P>,
  name: string,
): React.ComponentType<P & WithStyleProps> {
  const withStyleComponent = (props: P) => {
    const { style, theme } = useTemplateContext();

    return <Component style={theme.styles[style][name]} {...(props as P)} />;
  };

  withStyleComponent.displayName = `withStyle(${getDisplayName(Component)})`;

  return withStyleComponent;
}

export default withStyle;
export { withStyle };
