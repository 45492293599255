import * as React from "react";
import { View } from "components/elementsThemed";
import cx from "classnames";
import css from "./progressBar.module.scss";

/**
 * Parent component uses this to show a progress bar with theme & progress percentage
 *
 * @param views.views
 * @param {object} views - views by parent component that uses withTemplate HOC using theme file
 * @param {number} progressPercentage - % of the progress to show on the bar
 * @param views.progressPercentage
 */
const ProgressBar = ({ views, progressPercentage, label }) => {
  return (
    <div className={css["bar-container"]}>
      <View
        type={views.bar}
        role="progressbar"
        className={cx("bar", css.bar)}
        style={{ width: `${progressPercentage}%` }}
        aria-label={label}
        aria-valuenow={progressPercentage}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </div>
  );
};

export default ProgressBar;
