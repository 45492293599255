import * as React from "react";
import { Field } from "components/elementsThemed";
import { useCachedAddresses } from "../../../contexts/CachedAdresses";
import { WebApis, images, Copy } from "utils";
import { Image } from "../../fragments";
import { withTemplate } from "../../hocs";
import styles from "./kioskForm.module.scss";
import { LiveSearch } from "../LiveSearch";

const { FieldItem } = Field;

const KioskForm = ({ style, ...props }) => {
  const [address, setAddress] = React.useState("");
  const [fetching, setFetching] = React.useState(false);
  const { recentAddresses, updateRecentAddressesState } = useCachedAddresses();

  const getGeo = async () => {
    setFetching(true);
    try {
      const coords = await WebApis.Geo.getPosition();
      props.onGeo({
        lat: coords.coords.latitude,
        long: coords.coords.longitude,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setFetching(false);
    }
  };

  const checkAndGetGeo = async () => {
    if (navigator?.permissions) {
      // Permission API is implemented
      await navigator?.permissions
        ?.query({
          name: "geolocation",
        })
        .then((permission) => {
          if (permission?.state === "granted") {
            return getGeo();
          }
          return {};
        });
    }
  };

  React.useEffect(() => {
    checkAndGetGeo();
  }, []);

  return (
    <FieldItem
      type={style.labels.label}
      className={styles.field}
      label={Copy.RG_INFO_STATIC.LOCATION_TEXT}
    >
      <LiveSearch
        type={style.inputs.standard}
        resultType={style.buttons.searchResults}
        recentAddresses={recentAddresses}
        onSelect={(i) => {
          setAddress(i.text);
          updateRecentAddressesState(i);
          props.onSelect(i.id);
        }}
        inputProps={{
          className: "input-dine-in",
          icon: (
            <Image
              src={images?.icon_geo}
              className={`${styles["geo-icon"]} ${
                fetching ? styles.blink : ""
              }`}
              mediaName="icon_geo"
              mediaType="svg"
              onClick={getGeo}
              alt="location image"
            />
          ),
          onChange: (i) => setAddress(i),
          placeholder: "Enter Your Address",
          value: address,
        }}
      />
    </FieldItem>
  );
};

export default withTemplate(KioskForm, "address");
