import {withTypeSelector} from "components/hocs";
import {config} from "utils";
import Type1 from "./Type1";
import Type2 from "./Type2";

export default withTypeSelector(
  {
    Type1,
    Type2,
  },
  config?.theme?.checkout?.guests,
  Type1,
);
