import * as React from "react";
import { MemoryRouter, Route } from "react-router-dom";
import { motion } from "framer-motion";
import { Nav, NavItem } from "./routerNav";
import styles from "./routerTabs.module.css";

const RouterTabs = ({ children }) => {
  const [activeTab] = React.useState(0);
  const navNames = React.Children.map(children, (child) => child.props.name);
  return (
    <MemoryRouter
      initialEntries={[`/${navNames[activeTab]}`]}
      initialIndex={activeTab}
    >
      <div>
        <Route
          render={({ location }) => (
            <>
              <Nav>
                {navNames.map((i) => (
                  <NavItem to={`/${i}`} active={activeTab === i}>
                    {i}
                  </NavItem>
                ))}
              </Nav>
              <motion.div
                key={location.key}
                className={styles.content}
                initial={{
                  x: "100%",
                }}
                animate={{
                  x: 0,
                }}
                exit={{
                  x: "-100%",
                }}
                transition={{
                  ease: [0.35, 0, 0.25, 1],
                }}
              >
                {children}
              </motion.div>
            </>
          )}
        />
      </div>
    </MemoryRouter>
  );
};

export default React.memo(RouterTabs);
