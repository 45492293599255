import css from "./item.module.scss";
import css3 from "./type3.module.scss"; // Type2b
import css5 from "./type5.module.scss"; // Type2c

const TYPE_5_MAX_HEIGHT = 57;
const TYPE_7_MAX_HEIGHT = 80;

const oneColumnProps = {
  md: "1-1",
  sm: "1-1",
  xl: "1-1",
  xs: "1-1",
};

const twoColumnProps = {
  md: "1-2",
  sm: "1-2",
  xl: "1-2",
  xs: "1-1",
};

const threeColumnProps = {
  sm: "1-3",
  xs: "1-2",
};

const designConfig = (type) => {
  const defaults = {
    clampName: false,
    colProps: threeColumnProps,
    hideDescription: false,
    maxHeight: 0, // false
    showBackgroundImage: false,
    showImage: false,
    styles: css,
  };

  switch (type) {
    case "Type2":
    case "Type2a":
      return {
        ...defaults,
        showBackgroundImage: true,
      };
    case "Type3":
    case "Type2b":
      return {
        ...defaults,
        styles: css3,
      };
    case "Type5":
    case "Type2c":
      return {
        ...defaults,
        clampName: true,
        colProps: twoColumnProps,
        hideDescription: true,
        maxHeight: TYPE_5_MAX_HEIGHT,
        styles: css5,
      };
    case "Type7":
    case "Type2d":
      return {
        ...defaults,
        clampName: true,
        colProps: twoColumnProps,
        maxHeight: TYPE_7_MAX_HEIGHT,
        styles: css5,
      };
    case "Type2e":
      return {
        ...defaults,
        clampName: true,
        colProps: twoColumnProps,
        hideDescription: true,
        maxHeight: TYPE_5_MAX_HEIGHT,
        showImage: true,
        styles: css5,
      };
    case "Type2f":
      return {
        ...defaults,
        clampName: true,
        colProps: oneColumnProps,
        hideDescription: false,
        maxHeight: TYPE_5_MAX_HEIGHT,
        showImage: true,
        styles: css5,
      };
    case "Type2g":
      return {
        ...defaults,
        clampName: false,
        colProps: twoColumnProps,
        hideDescription: false,
        maxHeight: TYPE_7_MAX_HEIGHT,
        showImage: true,
        styles: css5,
      };
    default:
      return defaults;
  }
};
export { designConfig };
