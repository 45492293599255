import * as React from "react";
import csx from "classnames";
import { Text, View, Button } from "components/elementsThemed";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { Condition } from "components/elements";
import { Copy } from "utils";
import cssNoQuantityLabel from "./QuantitySelector.module.scss";
import cssHasQuantityLabel from "./QuantitySelectorTwo.module.scss";

const If = Condition.Condition;

const QuantitySelector = ({
  hasQuantityLabel = false,
  forwardStyles = {},
  buttonType,
  viewType,
  labelType,
  textLabelType,
  quantity,
  inc,
  dec,
  withAddButton = true,
  name,
}) => {
  const styleObject = hasQuantityLabel
    ? cssHasQuantityLabel
    : cssNoQuantityLabel;

  return (
    <div
      className={csx(
        styleObject.quantitySelector,
        forwardStyles["modifierItem-selectors"],
      )}
    >
      <If is={hasQuantityLabel}>
        <Text type={textLabelType}>
          {Copy.ITEM_DETAILS_STATIC.QUANTITY_TEXT_LABEL}
        </Text>
      </If>

      <div
        className={csx(
          styleObject["quantitySelector-container"],
          forwardStyles["modifierItem-selectors--container"],
        )}
      >
        <Button
          type={buttonType}
          className={csx(
            styleObject["quantity-btn"],
            forwardStyles["modifierItem-selectors--btn"],
          )}
          onClick={dec}
          data-cy="remove-qty-btn"
        >
          <VisuallyHidden>Decrease {name} quantity</VisuallyHidden>
          <div aria-hidden="true">-</div>
        </Button>
        {withAddButton ? (
          <>
            <View
              type={viewType}
              className={csx(
                styleObject["quantity-content"],
                forwardStyles["modifierItem-selectors--qty"],
              )}
            >
              <Text aria-live="polite" type={labelType} data-cy="quantity">
                <VisuallyHidden>{name} Quantity: </VisuallyHidden>
                {quantity}
              </Text>
            </View>
            <Button
              type={buttonType}
              className={csx(
                styleObject["quantity-btn"],
                forwardStyles["modifierItem-selectors--btn"],
              )}
              onClick={inc}
              data-cy="add-qty-btn"
            >
              <VisuallyHidden>Increase {name} quantity</VisuallyHidden>
              <div aria-hidden="true">+</div>
            </Button>
          </>
        ) : (
          <View
            type={viewType}
            className={csx(
              styleObject["quantity-content"],
              forwardStyles["modifierItem-selectors--qty"],
            )}
            onClick={inc}
          >
            <Text type={labelType}>{quantity}</Text>
          </View>
        )}
      </div>
    </div>
  );
};

export default QuantitySelector;
