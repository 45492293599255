import * as React from "react";
import useInView from "./useInView";

const useLazyLoad = (
  ref: React.MutableRefObject<HTMLElement>,
  rootMargin = "0px",
) => {
  const isOnScreen = useInView(ref, rootMargin);
  const [wasOnScreen, setWasOnScreen] = React.useState(false);

  React.useEffect(() => {
    // We only care that the element came into view at least once
    if (!wasOnScreen && isOnScreen) {
      setWasOnScreen(true);
    }
  }, [isOnScreen]);

  return wasOnScreen;
};

export default useLazyLoad;
