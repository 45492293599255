import { constants } from "utils";

const { DEBUG_TOOLS } = process.env;

const {
  ANALYTICS: {
    CART_CLEARED,
    CART_VIEWED,
    CHECKOUT_STARTED,
    PRODUCT_ADDED,
    PRODUCT_MODIFIED,
    PRODUCT_REMOVED,
    PRODUCT_CLICKED,
    PURCHASE,
    UNNAMED_EVENT,
  },
} = constants;

const mapPixelItems = (items = {}) =>
  items.map((i) => ({
    id: i?.itemId,
    item_price: i?.price,
    name: i?.name,
    quantity: 1,
    restaurantGroup: i?.resGroupId,
  }));

const mapPixelContentIds = (items = {}) => items.map((i) => i?.itemId);

const mapPixelAddToCart = (payload) => ({
  content_category: payload?.category,
  content_name: payload?.item?.name,
  currency: "USD",
  value: payload?.item?.price,
});

const mapPixelPurchase = (payload) => ({
  content_ids: mapPixelContentIds(payload?.items || {}),
  content_type: "product",
  contents: mapPixelItems(payload?.items || {}),
  currency: "USD",
  order_type: payload?.orderType,
  value: payload?.finalAmount,
});

const pixelEvent = (eventType, payload) => {
  const facebookInit = typeof window !== "undefined" && !!window.fbq;

  if (!facebookInit) {
    console.warn(
      "[lbx-tools]",
      `Facebook pixel event was fired but not initialized. (${eventType})`,
    );
    return;
  }

  window.fbq("track", eventType, payload);

  if (DEBUG_TOOLS) {
    console.info(
      "[lbx-tools]",
      `Facebook pixel event was fired. (${eventType})`,
    );
  }
};

export default (event, payload) => {
  switch (event) {
    case PRODUCT_ADDED:
      return pixelEvent("AddToCart", mapPixelAddToCart(payload));
    case PURCHASE:
      return pixelEvent("Purchase", mapPixelPurchase(payload));
    case CART_CLEARED:
    case CART_VIEWED:
    case CHECKOUT_STARTED:
    case PRODUCT_MODIFIED:
    case PRODUCT_REMOVED:
    case PRODUCT_CLICKED:
    case UNNAMED_EVENT:
    default:
      return pixelEvent("ViewContent", payload);
  }
};
