import * as React from "react";
import { config } from "utils";
import styles from "./symbols.module.css";

const iconStyle = (config.theme.icons && config.theme.icons.social) || 1;

const Facebook = () => {
  switch (iconStyle) {
    case 0:
      return (
        <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
      );
    case 1:
      return (
        <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
      );
    case 2:
      return (
        <path d="M0 0v24h24v-24h-24zm16 7h-1.923c-.616 0-1.077.252-1.077.889v1.111h3l-.239 3h-2.761v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z" />
      );
    case 3:
      return (
        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z" />
      );
    case 4:
      return (
        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z" />
      );
    case 5:
      return (
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z" />
      );
    default:
      return <></>;
  }
};

const Twitter = () => {
  switch (iconStyle) {
    case 1:
      return (
        <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
      );
    case 2:
      return (
        <path d="M0 0v24h24v-24h-24zm18.862 9.237c.208 4.617-3.235 9.765-9.33 9.765-1.854 0-3.579-.543-5.032-1.475 1.742.205 3.48-.278 4.86-1.359-1.437-.027-2.649-.976-3.066-2.28.515.098 1.021.069 1.482-.056-1.579-.317-2.668-1.739-2.633-3.26.442.246.949.394 1.486.411-1.461-.977-1.875-2.907-1.016-4.383 1.619 1.986 4.038 3.293 6.766 3.43-.479-2.053 1.079-4.03 3.198-4.03.944 0 1.797.398 2.396 1.037.748-.147 1.451-.42 2.085-.796-.245.767-.766 1.41-1.443 1.816.664-.08 1.297-.256 1.885-.517-.44.656-.997 1.234-1.638 1.697z" />
      );
    case 3:
      return (
        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-.139 9.237c.209 4.617-3.234 9.765-9.33 9.765-1.854 0-3.579-.543-5.032-1.475 1.742.205 3.48-.278 4.86-1.359-1.437-.027-2.649-.976-3.066-2.28.515.098 1.021.069 1.482-.056-1.579-.317-2.668-1.739-2.633-3.26.442.246.949.394 1.486.411-1.461-.977-1.875-2.907-1.016-4.383 1.619 1.986 4.038 3.293 6.766 3.43-.479-2.053 1.08-4.03 3.199-4.03.943 0 1.797.398 2.395 1.037.748-.147 1.451-.42 2.086-.796-.246.767-.766 1.41-1.443 1.816.664-.08 1.297-.256 1.885-.517-.439.656-.996 1.234-1.639 1.697z" />
      );
    case 4:
      return (
        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.066 9.645c.183 4.04-2.83 8.544-8.164 8.544-1.622 0-3.131-.476-4.402-1.291 1.524.18 3.045-.244 4.252-1.189-1.256-.023-2.317-.854-2.684-1.995.451.086.895.061 1.298-.049-1.381-.278-2.335-1.522-2.304-2.853.388.215.83.344 1.301.359-1.279-.855-1.641-2.544-.889-3.835 1.416 1.738 3.533 2.881 5.92 3.001-.419-1.796.944-3.527 2.799-3.527.825 0 1.572.349 2.096.907.654-.128 1.27-.368 1.824-.697-.215.671-.67 1.233-1.263 1.589.581-.07 1.135-.224 1.649-.453-.384.578-.87 1.084-1.433 1.489z" />
      );
    case 5:
      return (
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.5 8.778c-.441.196-.916.328-1.414.388.509-.305.898-.787 1.083-1.362-.476.282-1.003.487-1.564.597-.448-.479-1.089-.778-1.796-.778-1.59 0-2.758 1.483-2.399 3.023-2.045-.103-3.86-1.083-5.074-2.572-.645 1.106-.334 2.554.762 3.287-.403-.013-.782-.124-1.114-.308-.027 1.14.791 2.207 1.975 2.445-.346.094-.726.116-1.112.042.313.978 1.224 1.689 2.3 1.709-1.037.812-2.34 1.175-3.647 1.021 1.09.699 2.383 1.106 3.773 1.106 4.572 0 7.154-3.861 6.998-7.324.482-.346.899-.78 1.229-1.274z" />
      );
    default:
      return <></>;
  }
};

const Instagram = () => {
  switch (iconStyle) {
    case 1:
      return (
        <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
      );
    case 2:
      return (
        <path d="M14.667 12c0 1.473-1.194 2.667-2.667 2.667-1.473 0-2.667-1.193-2.667-2.667 0-1.473 1.194-2.667 2.667-2.667 1.473 0 2.667 1.194 2.667 2.667zm3.846-3.232c.038.843.046 1.096.046 3.232s-.008 2.389-.046 3.233c-.1 2.15-1.109 3.181-3.279 3.279-.844.038-1.097.047-3.234.047-2.136 0-2.39-.008-3.232-.046-2.174-.099-3.181-1.132-3.279-3.279-.039-.845-.048-1.098-.048-3.234s.009-2.389.047-3.232c.099-2.152 1.109-3.181 3.279-3.279.844-.039 1.097-.047 3.233-.047s2.39.008 3.233.046c2.168.099 3.18 1.128 3.28 3.28zm-2.405 3.232c0-2.269-1.84-4.108-4.108-4.108-2.269 0-4.108 1.839-4.108 4.108 0 2.269 1.84 4.108 4.108 4.108 2.269 0 4.108-1.839 4.108-4.108zm1.122-4.27c0-.53-.43-.96-.96-.96s-.96.43-.96.96.43.96.96.96c.531 0 .96-.43.96-.96zm6.77-7.73v24h-24v-24h24zm-4 12c0-2.172-.009-2.445-.048-3.298-.131-2.902-1.745-4.52-4.653-4.653-.854-.04-1.126-.049-3.299-.049s-2.444.009-3.298.048c-2.906.133-4.52 1.745-4.654 4.653-.039.854-.048 1.127-.048 3.299 0 2.173.009 2.445.048 3.298.134 2.906 1.746 4.521 4.654 4.654.854.039 1.125.048 3.298.048s2.445-.009 3.299-.048c2.902-.133 4.522-1.745 4.653-4.654.039-.853.048-1.125.048-3.298z" />
      );
    case 3:
      return (
        <path d="M15.233 5.488c-.843-.038-1.097-.046-3.233-.046s-2.389.008-3.232.046c-2.17.099-3.181 1.127-3.279 3.279-.039.844-.048 1.097-.048 3.233s.009 2.389.047 3.233c.099 2.148 1.106 3.18 3.279 3.279.843.038 1.097.047 3.233.047 2.137 0 2.39-.008 3.233-.046 2.17-.099 3.18-1.129 3.279-3.279.038-.844.046-1.097.046-3.233s-.008-2.389-.046-3.232c-.099-2.153-1.111-3.182-3.279-3.281zm-3.233 10.62c-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.84-4.108 4.108-4.108s4.108 1.839 4.108 4.108c0 2.269-1.839 4.108-4.108 4.108zm4.271-7.418c-.53 0-.96-.43-.96-.96s.43-.96.96-.96.96.43.96.96-.43.96-.96.96zm-1.604 3.31c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667zm4.333-12h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm.952 15.298c-.132 2.909-1.751 4.521-4.653 4.654-.854.039-1.126.048-3.299.048s-2.444-.009-3.298-.048c-2.908-.133-4.52-1.748-4.654-4.654-.039-.853-.048-1.125-.048-3.298 0-2.172.009-2.445.048-3.298.134-2.908 1.748-4.521 4.654-4.653.854-.04 1.125-.049 3.298-.049s2.445.009 3.299.048c2.908.133 4.523 1.751 4.653 4.653.039.854.048 1.127.048 3.299 0 2.173-.009 2.445-.048 3.298z" />
      );
    case 4:
      return (
        <path d="M14.829 6.302c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828c-.088-1.883-.973-2.783-2.87-2.87zm-2.829 9.293c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594s3.595 1.609 3.595 3.594c0 1.985-1.61 3.595-3.595 3.595zm3.737-6.491c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84zm-1.404 2.896c0 1.289-1.045 2.333-2.333 2.333s-2.333-1.044-2.333-2.333c0-1.289 1.045-2.333 2.333-2.333s2.333 1.044 2.333 2.333zm-2.333-12c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043s2.14.008 2.887.043c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z" />
      );
    case 5:
      return (
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 7.082c1.602 0 1.792.006 2.425.035 1.627.074 2.385.845 2.46 2.459.028.633.034.822.034 2.424s-.006 1.792-.034 2.424c-.075 1.613-.832 2.386-2.46 2.46-.633.028-.822.035-2.425.035-1.602 0-1.792-.006-2.424-.035-1.63-.075-2.385-.849-2.46-2.46-.028-.632-.035-.822-.035-2.424s.007-1.792.035-2.424c.074-1.615.832-2.386 2.46-2.46.632-.029.822-.034 2.424-.034zm0-1.082c-1.63 0-1.833.007-2.474.037-2.18.1-3.39 1.309-3.49 3.489-.029.641-.036.845-.036 2.474 0 1.63.007 1.834.036 2.474.1 2.179 1.31 3.39 3.49 3.49.641.029.844.036 2.474.036 1.63 0 1.834-.007 2.475-.036 2.176-.1 3.391-1.309 3.489-3.49.029-.64.036-.844.036-2.474 0-1.629-.007-1.833-.036-2.474-.098-2.177-1.309-3.39-3.489-3.489-.641-.03-.845-.037-2.475-.037zm0 2.919c-1.701 0-3.081 1.379-3.081 3.081s1.38 3.081 3.081 3.081 3.081-1.379 3.081-3.081c0-1.701-1.38-3.081-3.081-3.081zm0 5.081c-1.105 0-2-.895-2-2 0-1.104.895-2 2-2 1.104 0 2.001.895 2.001 2s-.897 2-2.001 2zm3.202-5.922c-.397 0-.72.322-.72.72 0 .397.322.72.72.72.398 0 .721-.322.721-.72 0-.398-.322-.72-.721-.72z" />
      );
    default:
      return <></>;
  }
};

const Defaults = () => (
  <>
    <symbol id="cart" viewBox="0 0 30 30">
      <g>
        <g>
          <path
            className={styles.st0}
            d="M23.8,7v20.9H6.7V7H23.8 M24.9,5.9H5.6V29h19.3V5.9L24.9,5.9z"
          />
        </g>
        <path
          className={styles.st1}
          d="M9.6,9.8V6.1c0-2.2,1.8-3.9,3.9-3.9h0c2.2,0,3.9,1.8,3.9,3.9v3.7"
        />
        <path
          className={styles.st1}
          d="M12.8,6.9V6.1c0-2.2,1.8-3.9,3.9-3.9h0c2.2,0,3.9,1.8,3.9,3.9v0.8"
        />
      </g>
      <path
        className={styles.st0}
        d="M17.3,20H9.9c-0.1,0-0.3-0.1-0.3-0.3v-4.4c0-0.1,0.1-0.3,0.3-0.3h7.5c0.1,0,0.3,0.1,0.3,0.3v4.4 C17.6,19.9,17.5,20,17.3,20z"
      />
      <circle className={styles.st2} cx="22.9" cy="7" r="4.8" />
    </symbol>
    <symbol id="user" viewBox="0 0 30 30">
      <g>
        <g>
          <path
            className={styles.st0}
            d="M15,4.6c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5s-4.5-2-4.5-4.5S12.5,4.6,15,4.6 M15,3.5c-3.1,0-5.6,2.5-5.6,5.6
                        s2.5,5.6,5.6,5.6s5.6-2.5,5.6-5.6S18.1,3.5,15,3.5L15,3.5z"
          />
        </g>
        <path
          className={styles.st1}
          d="M4.1,25.2c0-6,4.9-10.9,10.9-10.9s10.9,4.9,10.9,10.9"
        />
      </g>
    </symbol>
  </>
);

const Symbols = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
    {config.theme.icons && config.theme.icons.source === "symbols" && (
      <Defaults />
    )}
    <symbol id="lbx-logo" viewBox="0 0 787.9 131.3">
      <g>
        <path d="M0,2.2h20.5v108.6h60.9v19.4H0V2.2z" />
        <path
          d="M101.5,121.6c-6-6.5-9.1-15.8-9.1-28V38.8h20.1v50.5c0,7.9,1.6,13.7,4.9,17.5c3.3,3.7,8.3,5.6,15,5.6
                    c6.5,0,11.4-2.1,14.9-6.3c3.5-4.2,5.2-9.8,5.2-16.7V38.8h20.1v91.4h-20.1v-10.4c-2.4,3.2-5.8,5.9-10.1,8.1c-4.3,2.3-9.3,3.4-15,3.4
                    C116.2,131.3,107.5,128.1,101.5,121.6z"
        />
        <path
          d="M196,38.8h20.1v10.4c2.4-3.2,5.8-5.9,10.1-8.1c4.3-2.3,9.3-3.4,15-3.4c11.3,0,20,3.2,26.1,9.7c6,6.5,9.1,15.8,9.1,28v54.9
                    h-20.1V79.7c0-7.9-1.6-13.7-4.8-17.5c-3.2-3.7-8-5.6-14.4-5.6c-6.7,0-11.9,2.1-15.5,6.3c-3.6,4.2-5.4,9.8-5.4,16.7v50.5H196V38.8z"
        />
        <path
          d="M315.8,125.2c-7.1-4.1-12.6-9.7-16.7-16.7c-4.1-7.1-6.1-15.1-6.1-24c0-8.9,2-16.9,6.1-24c4.1-7.1,9.7-12.6,16.7-16.7
                    c7.1-4.1,15.1-6.1,24-6.1c13.3,0,24.4,4.6,33.5,13.9l-14.8,13.3c-5.1-5.5-11.3-8.2-18.7-8.2c-7.8,0-14.1,2.6-19,7.7
                    c-4.9,5.1-7.3,11.8-7.3,20.1c0,8.3,2.4,15,7.3,20.1c4.9,5.1,11.2,7.7,19,7.7c7.8,0,14.2-2.6,19.1-7.8s7.4-11.9,7.4-20h20.1
                    c0,8.8-2,16.7-6.1,23.9c-4.1,7.1-9.7,12.7-16.7,16.8s-15,6.1-23.8,6.1C330.9,131.3,322.9,129.3,315.8,125.2z"
        />
        <path
          d="M401.5,2.2L421.6,0v49.2c2.9-3.5,6.6-6.3,11.1-8.4c4.4-2.1,9.5-3.1,15.1-3.1c11,0,19.6,3.3,25.8,9.8
                    c6.2,6.5,9.3,15.8,9.3,27.9v54.9h-20.1V79.7c0-7.3-1.6-13-4.9-17c-3.3-4-8.3-6-15-6c-6.5,0-11.6,2.2-15.5,6.5
                    c-3.8,4.3-5.8,9.8-5.8,16.5v50.5h-20.1V2.2z"
        />
        <path
          d="M533.2,128.4c-4.5-1.9-8.4-4.5-11.7-7.7v9.5h-20.1V2.2L521.5,0v48.3c2.9-3.2,6.8-5.7,11.5-7.7c4.8-1.9,9.9-2.9,15.4-2.9
                    c9,0,16.9,1.9,23.8,5.8c6.8,3.8,12.1,9.3,15.9,16.4c3.8,7.1,5.7,15.3,5.7,24.7c0,9.4-1.9,17.6-5.7,24.7
                    c-3.8,7.1-9.1,12.5-15.9,16.4c-6.8,3.8-14.8,5.8-23.8,5.8C542.8,131.3,537.7,130.3,533.2,128.4z M566.7,104.1
                    c4.9-5.2,7.3-11.7,7.3-19.7s-2.4-14.5-7.3-19.7c-4.9-5.2-11.3-7.8-19.2-7.8c-7.8,0-14.1,2.6-19,7.8c-4.9,5.2-7.3,11.7-7.3,19.7
                    s2.4,14.5,7.3,19.7c4.9,5.2,11.2,7.8,19,7.8C555.4,111.9,561.8,109.3,566.7,104.1z"
        />
        <path d="M731.4,76.1l-23.8-37.3H731l11.5,19l11.5-19h23.4l-23.8,37.3l34.2,54.1h-23.4l-21.9-35.8l-21.9,35.8h-23.4L731.4,76.1z" />
        <path
          d="M692.9,60.6c-4-7.1-9.6-12.7-16.7-16.8c-7.1-4.1-15.1-6.1-24-6.1c-8.9,0-16.9,2-24,6.1c-7.1,4.1-12.7,9.7-16.7,16.8
                    c-4,7.1-6,15.1-6,23.9s2,16.7,6,23.9c4,7.1,9.6,12.7,16.7,16.8c7.1,4.1,15.1,6.1,24,6.1c8.9,0,16.9-2,24-6.1
                    c7.1-4.1,12.7-9.7,16.7-16.8c4-7.1,6-15.1,6-23.9S696.9,67.8,692.9,60.6z M634.4,65.8c0-2,1.6-3.6,3.6-3.6h28.3
                    c2,0,3.6,1.6,3.6,3.6L634.4,65.8L634.4,65.8z M675.8,97.6c0,4.8-3.9,8.7-8.7,8.7h-30c-4.8,0-8.7-3.9-8.7-8.7V78.3
                    c0-4.8,3.9-8.7,8.7-8.7h30c4.8,0,8.7,3.9,8.7,8.7V97.6z"
        />
      </g>
    </symbol>
    <symbol id="facebook" viewBox="0 0 24 24">
      <Facebook />
    </symbol>
    <symbol id="twitter" viewBox="0 0 24 24">
      <Twitter />
    </symbol>
    <symbol id="instagram" viewBox="0 0 24 24">
      <Instagram />
    </symbol>
    <symbol id="back" viewBox="0 0 8 15" fill="none">
      <path
        d="M7.15566 14.2065L1 7.98233L7.15566 1.00004"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>
  </svg>
);

export default Symbols;
