export const FORGOT_PASSWORD_TEXT = "Forgot Password?";
export const INPUT_LABEL = "Password";
export const INPUT_PLACEHOLDER = "Password";
export const LOGIN_BUTTON_TEXT = "Login";

export default {
  FORGOT_PASSWORD_TEXT,
  INPUT_LABEL,
  INPUT_PLACEHOLDER,
  LOGIN_BUTTON_TEXT,
};
