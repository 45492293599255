import { useEffect, useState } from "react";
import { axios } from "../utils";

const { methods, handleError } = axios;

export type InitialRequest = {
  data?: Record<string, any>;
  headers?: Record<string, any>;
  method: string;
  path: string;
};

export type ResourceResponse<T> = {
  error: any;
  fetching: boolean;
  resource: T | T[];
};

export const useResource = <T>(
  initialRequest: InitialRequest = {
    data: {},
    headers: {},
    method: "get",
    path: "",
  },
  defaultValue: T[] = [],
  callback:
    | null
    | ((v: {
        error: unknown;
        fetching: boolean;
        resource: any;
      }) => void) = null,
  // Catch-all call this effect or not
  condition = false,
): [
  ResourceResponse<T>,
  React.Dispatch<React.SetStateAction<InitialRequest>>,
] => {
  const [fetching, setFetching] = useState(true);
  const [resource, setResource] = useState(defaultValue || []);
  const [error, setError] = useState<any>({});
  const [request, setRequest] = useState(initialRequest);

  useEffect(() => {
    if (!condition) {
      const { method = "get", path, data = {}, headers = {} } = request;
      const fetch = async () => {
        setFetching(true);

        try {
          const apiResponse = await (
            methods as Record<
              string,
              (a: string, b: unknown, c: unknown) => Promise<any>
            >
          )[method](path, data, { headers });

          setResource(apiResponse.data);
        } catch (e) {
          console.error(`${path} error ~`, e);
          setError(handleError(e));
        } finally {
          setFetching(false);
        }
      };

      fetch();
    }
  }, []);

  useEffect(() => {
    if (fetching || typeof callback !== "function") {
      // empty
    } else {
      // Keep for troubleshooting
      // console.log(`${request?.path} callback ~ `, { fetching, resource, error });
      callback({
        error,
        fetching,
        resource,
      });
    }
  }, [fetching, callback]);

  return [
    {
      error,
      fetching,
      resource,
    },
    setRequest,
  ];
};

export default useResource;
