import * as React from "react";
import css from "./overlay.module.css";

interface OverlayProps {
  clickable: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

function Overlay({ clickable, onClick, children }: OverlayProps) {
  const props = {
    className: css.overlay,
    onClick: clickable ? onClick : undefined,
    onKeyDown: clickable ? onClick : undefined,
    role: clickable ? "button" : undefined,
  };
  return (
    <div className={css.container}>
      <div {...props} />
      {children}
    </div>
  );
}

export default Overlay;
