export const DEFAULT_ADDRESS: Address = {
  city: "",
  country: "",
  geo: {
    lat: 40.7831,
    long: -73.9712,
  },
  home: "",
  state: "",
  street1: "",
  street2: "",
  zip: "",
} as const;

export const DEFAULT_PATRON_STATE = {
  firstName: "Test",
  lastName: "User",
  email: "testuser@fake.com",
  phone: "",
  id: "",
  isEmployee: false,
  squareCustomers: {
    card: {},
  },
  addresses: DEFAULT_ADDRESS,
} as const;

export const DEFAULT_MENU = {};

export const DEFAULT_PATRON = {
  accessToken: "",
  fetching: true,
  isLoggedIn: true,
  isEmployee: false,
  signIn: () => {},
  signOut: () => {},
  patron: DEFAULT_PATRON_STATE,
  updateAddress: () => {},
  updateCard: () => {},
  updatePatron: () => {},
} as const;

const mockData = {
  firstTime: true,
  hasPromotion: false,
  isDelivery: false,
  isKiosk: false,
  isPickup: true,
  items: [],
  kioskInfoVisibility: true,
  location: {
    id: "6018ce68ec8b1e31013323b2",
    serviceEstimates: {
      delivery: 15,
      increment: null,
      prep: 30,
      threshold: null,
    },
    cuisine: "bareburger!",
    denormalizedHours: [
      {
        type: "pickup",
        day: 5,
        open: "00:00",
        close: "23:00",
      },
      {
        type: "pickup",
        day: 6,
        open: "11:00",
        close: "23:00",
      },
      {
        type: "pickup",
        day: 0,
        open: "12:00",
        close: "21:00",
      },
      {
        type: "pickup",
        day: 1,
        open: "10:00",
        close: "23:59",
      },
      {
        type: "pickup",
        day: 2,
        open: "00:00",
        close: "22:00",
      },
      {
        type: "pickup",
        day: 3,
        open: "00:00",
        close: "22:00",
      },
      {
        type: "pickup",
        day: 4,
        open: "00:00",
        close: "23:00",
      },
      {
        type: "delivery",
        day: 4,
        open: "12:00",
        close: "13:00",
      },
      {
        type: "delivery",
        day: 5,
        open: "12:00",
        close: "22:00",
      },
      {
        type: "delivery",
        day: 6,
        open: "12:00",
        close: "22:00",
      },
      {
        type: "delivery",
        day: 0,
        open: "12:00",
        close: "21:00",
      },
      {
        type: "delivery",
        day: 1,
        open: "12:00",
        close: "22:00",
      },
      {
        type: "delivery",
        day: 2,
        open: "11:00",
        close: "22:00",
      },
      {
        type: "delivery",
        day: 3,
        open: "12:00",
        close: "22:00",
      },
      {
        type: "dinein",
        day: 4,
        open: "12:00",
        close: "22:00",
      },
      {
        type: "dinein",
        day: 5,
        open: "12:00",
        close: "22:00",
      },
      {
        type: "dinein",
        day: 6,
        open: "12:00",
        close: "22:00",
      },
      {
        type: "dinein",
        day: 0,
        open: "12:00",
        close: "21:00",
      },
      {
        type: "dinein",
        day: 1,
        open: "12:00",
        close: "22:00",
      },
      {
        type: "dinein",
        day: 2,
        open: "12:00",
        close: "22:00",
      },
      {
        type: "dinein",
        day: 3,
        open: "12:00",
        close: "22:00",
      },
    ],
    isApplePayAccepted: true,
    isCashAccepted: false,
    isDeliveryEnabled: true,
    isGooglePayAccepted: true,
    isKioskEnabled: true,
    isOpen: false,
    isPickupEnabled: true,
    isRestricted: false,
    slug: "2db4481f-1144-4376-b3f5-14e778aef580",
    timeZone: "America/Chicago",
    _id: "6018ce68ec8b1e31013323b2",
    address: {
      street2: "",
      geo: {
        lat: 40.7634438,
        long: -73.9210736,
      },
      city: "Long Island City",
      state: "NY",
      street1: "33-21 31st Ave",
      zip: "11103",
    },
    hours: [
      {
        pickupOpen: "10:00",
        pickupClose: "23:59",
        deliveryOpen: "12:00",
        deliveryClose: "22:00",
        dineInOpen: "12:00",
        dineInClose: "22:00",
        day: 1,
      },
      {
        pickupOpen: "00:00",
        pickupClose: "22:00",
        deliveryOpen: "11:00",
        deliveryClose: "22:00",
        dineInOpen: "12:00",
        dineInClose: "22:00",
        day: 2,
      },
      {
        pickupOpen: "00:00",
        pickupClose: "22:00",
        deliveryOpen: "12:00",
        deliveryClose: "22:00",
        dineInOpen: "12:00",
        dineInClose: "22:00",
        day: 3,
      },
      {
        pickupOpen: "00:00",
        pickupClose: "23:00",
        deliveryOpen: "12:00",
        deliveryClose: "13:00",
        dineInOpen: "12:00",
        dineInClose: "22:00",
        day: 4,
      },
      {
        pickupOpen: "00:00",
        pickupClose: "23:00",
        deliveryOpen: "12:00",
        deliveryClose: "22:00",
        dineInOpen: "12:00",
        dineInClose: "22:00",
        day: 5,
      },
      {
        pickupOpen: "11:00",
        pickupClose: "23:00",
        deliveryOpen: "12:00",
        deliveryClose: "22:00",
        dineInOpen: "12:00",
        dineInClose: "22:00",
        day: 6,
      },
      {
        pickupOpen: "12:00",
        pickupClose: "21:00",
        deliveryOpen: "12:00",
        deliveryClose: "21:00",
        dineInOpen: "12:00",
        dineInClose: "21:00",
        day: 0,
      },
    ],
    name: "Queens, 31st Ave [1]",
    phone: {
      isVerified: false,
      value: "7187777011",
    },
    restaurantGroup: "5c8c19f85de30e5d282ac88d",
    distanceInMiles: null,
    nextAvailableDate: "Fri 04/01/2022",
    nextAvailableTime: "11:11 AM",
    isAcceptingPreorders: false,
    isPreorderRequired: false,
    isPinCodeRequired: true,
  },
  order: {
    address: {
      city: "",
      lat: "",
      long: "",
      state: "",
      street1: "",
      street2: "",
      zip: "",
    },
    code: null,
    deliveryNotes: "",
    diningOption: "pickup",
    discount: null,
    idempotencyKey: "500bdbdf-22ee-465e-b079-9408e72056bf",
    note: "",
    promotionCodeId: null,
    promotionId: null,
    scheduledAt: null,
    table: {
      id: null,
      number: null,
    },
    lat: "",
    long: "",
    giftCard: {
      balance: 0,
      giftCardNumber: null,
    },
  },
  packingItems: [],
};
