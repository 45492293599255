import * as React from "react";

/**
 * Returms image tag with the attributes set
 * @param {object} props
 * @param {string} props.src - URL of the image
 * @param {string} props.defaultSrc - Fallback src used for placeholders if src is not provided
 * @param {string} props.alt - alt text of image
 */

type ImageProps = {
  src?: string;
  defaultSrc?: string;
  alt?: string;
  className?: string;
};

function Image({ src, defaultSrc, alt, ...props }: ImageProps) {
  const image = src || defaultSrc;
  return <img src={image} alt={alt} {...props} />;
}

Image.defaultProps = {
  alt: "",
  defaultSrc: "",
  src: "",
  className: "",
};

export default Image;
