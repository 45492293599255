import { Copy } from "../../../utils/Copy";
import { TRACKING_STATUSES } from "../../../utils/constants";

/**
 * Progress Map lists out the labels and descriptions for a progressSegment.
 * The order of these entries are important since theyre used to determine
 * which segements needs to be checked of
 */
export const progressMap = [
  {
    description: Copy.ORDER_TRACKING.ORDER_RECEIVED_DESCRIPTION,
    requiredStatus: TRACKING_STATUSES.ORDER_RECEIVED,
    title: Copy.ORDER_TRACKING.ORDER_RECEIVED_TITLE,
  },
  {
    description: Copy.ORDER_TRACKING.ORDER_PICKED_UP_DESCRIPTION,
    requiredStatus: TRACKING_STATUSES.ORDER_PICKUP,
    title: Copy.ORDER_TRACKING.ORDER_PICKED_UP_TITLE,
  },
  {
    description: Copy.ORDER_TRACKING.ORDER_OUT_FOR_DELIVERY_DESCRIPTION,
    requiredStatus: TRACKING_STATUSES.OUT_FOR_DELIVERY,
    title: Copy.ORDER_TRACKING.ORDER_OUT_FOR_DELIVERY_TITLE,
  },
];

/**
 * Depending on the currentStatus of an order tracking response, returns boolean
 * if stage that is being checked has already been passed that status in the order
 * processing process
 *
 * @author Mohammad Afzal
 * @param {string} currentStatus - Current status of a order, value comes from /orders/:id:/tracking endpoint
 * @param {string} status - the fixed status at any stage to compare against
 * @returns {boolean} if the currentStatus is before or after status
 */
export const isProgressSegmentComplete = (currentStatus, status) => {
  if (!currentStatus) return false;
  const statusIndex = progressMap.findIndex((i) => i.requiredStatus === status);
  const currentStatusIndex = progressMap.findIndex(
    (i) => i.requiredStatus === currentStatus,
  );
  return currentStatusIndex >= statusIndex;
};
