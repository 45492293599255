/* eslint-disable react/destructuring-assignment */
import * as React from "react";
import ClampLines from "react-clamp-lines";
import { Condition } from "components/elements";
import { withTemplate } from "components/hocs";
import { View, Segment, Text, Cell, Field } from "components/elementsThemed";

import { config, images as imagesUtils, Copy } from "utils";
import BackButton from "../../../../../BackButton/BackButton";
import TabSelector from "../../../Tabs";
import GroupSelector from "../../../Group";
import FooterSelector from "../../../Footer";
import GlobalMessage from "../../../globalMessage";

import styles from "./subgroupsType2.module.scss";

const { Condition: If } = Condition;
const { Textarea } = Field;

const SubgroupsType2 = React.memo(
  ({
    addModifier,
    artMiscImage,
    buttonText,
    calories,
    decQuantity,
    diet,
    errors,
    globalError,
    group,
    handleScroll,
    id,
    incQuantity,
    item,
    itemImageRef,
    modifications,
    mods,
    notes,
    onChangeNotes,
    onSelect,
    onSubmit,
    optionRefs,
    optionsHash,
    placeholderImage,
    price,
    quantity,
    removeModifier,
    reset,
    scrollingRef,
    selectedItem,
    setGlobalError,
    style,
    tabs,
    ...props
  }) => {
    const { segmentViews, views, cells, labels, inputs } = style;

    const { name, description, items, images } = group;

    const image = images?.[0] ?? config?.images?.art_item_placeholder;

    const containerRef = React.useRef(null);

    return (
      <View type={views.background} className={styles.itemDetails}>
        <If is={props.showClose}>
          <div className={styles["itemDetails-back"]}>
            <BackButton
              imgSrc={imagesUtils?.button_back_item_details}
              onClick={props.close}
            />
          </div>
        </If>
        <Cell
          type={cells.header}
          render={({ views: cellViews, labelTextStyles }) => (
            <View
              type={cellViews.background}
              className={styles["itemDetails-header"]}
            >
              <div>
                <Text type={labelTextStyles.primary}>{name}</Text>
              </div>
              <div>
                <Text type={labelTextStyles.secondary}>
                  <ClampLines
                    id={id}
                    buttons={false}
                    text={description}
                    lines={1}
                    ellipsis="..."
                  />
                </Text>
              </div>
            </View>
          )}
        />

        <div
          id="itemDetailsContainer"
          className={styles["itemDetails-content"]}
          ref={containerRef}
        >
          <div
            id="itemImage"
            ref={itemImageRef}
            className={styles["itemDetails-image"]}
            style={{
              backgroundImage: `url(${image})`,
            }}
          />

          <GroupSelector
            items={items}
            types={{
              default: cells.primary,
              group: cells.group,
              selected: cells.primarySelected,
            }}
            error={errors.find((i) => i.type === "item")}
            labelType={labels.warning}
            selected={selectedItem}
            onSelect={onSelect}
          />

          <div className={styles["itemDetails-tabs"]}>
            <If is={selectedItem}>
              <Options
                artMiscImage={artMiscImage}
                cells={cells}
                type={segmentViews.standard}
                item={item}
                tabs={tabs}
                handleScroll={handleScroll}
                optionRefs={optionRefs}
                optionsHash={optionsHash}
                diet={diet}
                modifications={modifications}
                setGlobalError={setGlobalError}
                errors={errors}
                addModifier={addModifier}
                removeModifier={removeModifier}
                onReset={reset}
                containerRef={containerRef}
              />
              <If is={config.ordering.notes}>
                <div className={styles["itemDetails-notes"]}>
                  <Textarea
                    label={
                      Copy.ITEM_DETAILS_STATIC.GROUP_SELECTION_TXTAREA_LABEL
                    }
                    name="notes"
                    resizable={false}
                    type={inputs.standard}
                    value={notes}
                    placeholder={
                      Copy.ITEM_DETAILS_STATIC
                        .GROUP_SELECTION_TXTAREA_PLACEHOLDER
                    }
                    rows={4}
                    onChange={onChangeNotes}
                  />
                </div>
              </If>
            </If>
          </div>
        </div>

        <View type={views.footer} className={styles["itemDetails-footer"]}>
          <GlobalMessage
            error={globalError}
            afterQueueSuccess={() => setGlobalError(null)}
            render={(error) => (
              <View
                type={views.background}
                className={styles["itemDetails-error"]}
              >
                <Text type={labels.warning}>{error}</Text>
              </View>
            )}
          />
          <FooterSelector
            showQuantity={props.showQuantity}
            quantityType={cells.quantity}
            quantity={quantity}
            incQuantity={incQuantity}
            decQuantity={decQuantity}
            buttonType={cells.bottom}
            onClick={onSubmit}
            buttonText={buttonText()}
          />
        </View>
      </View>
    );
  },
);

const Options = ({
  addModifier,
  artMiscImage,
  cells,
  containerRef,
  errors,
  handleScroll,
  modifications,
  optionRefs,
  optionsHash,
  removeModifier,
  tabs,
  type,
}) => {
  if (!tabs.length) {
    return (
      <div
        style={{
          backgroundImage: `url(${artMiscImage})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          display: "none",
          height: "100%",
        }}
      />
    );
  }

  return (
    <Segment
      type={type}
      render={(segmentProps) => (
        <TabSelector
          optionRefs={optionRefs}
          handleScroll={handleScroll}
          {...segmentProps}
          tabs={tabs}
          errors={errors}
          cells={cells}
          addModifier={addModifier}
          removeModifier={removeModifier}
          menuOptions={optionsHash}
          modifications={modifications}
          containerRef={containerRef}
        />
      )}
    />
  );
};

export default withTemplate(SubgroupsType2, "itemDetails");
