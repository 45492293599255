import * as React from "react";
import csx from "classnames";
import { useCell } from "../../../hooks/useTemplate";
import { View } from "../../elementsThemed/View";
import { Text } from "../../elementsThemed/Text";
import css from "./trackingProgress.module.scss";
import { Copy } from "../../../utils/Copy";

/**
 * Returns UI for Driver information located at the bottom of TrackingProgress component
 *
 * @author Mohammad Afzal
 * @param {object} props - React props
 * @param {string} props.type - Cell type string to be used in get cell styling values
 * @param {string} props.className - optional className added to the wrapping element returned
 * @param {string} props.driverName - Name of driver returned from /orders/:id/tracking
 * @param {string} props.serviceName - Name of delivery service returned from /orders/:id/tracking
 * @param {string} props.driverContact - Contact information of driver returned from /orders/:id/tracking
 * @returns {React.Element} - Returns JSX of Driver information
 */
const DriverInformation = ({
  type,
  className,
  driverName,
  serviceName,
  driverContact,
}) => {
  const { labelTextStyles, views } = useCell(type);
  return (
    <View
      type={views.background}
      className={csx(css["driverInfo-container"], className)}
    >
      <View
        type={views.primary}
        className={css["driverInfo-container--labelContainer"]}
      >
        <Text type={labelTextStyles.primary}>
          {Copy.ORDER_TRACKING.DRIVER_INFO_SERVICE_LABEL}
        </Text>
        &nbsp;
        <Text type={labelTextStyles.secondary}>{serviceName}</Text>
      </View>
      <View
        type={views.primary}
        className={css["driverInfo-container--labelContainer"]}
      >
        <Text type={labelTextStyles.primary}>
          {Copy.ORDER_TRACKING.DRIVER_INFO_DRIVER_LABEL}
        </Text>
        &nbsp;
        <Text type={labelTextStyles.secondary}>{driverName}</Text>
      </View>
      <View
        type={views.primary}
        className={css["driverInfo-container--labelContainer"]}
      >
        <Text type={labelTextStyles.primary}>
          {Copy.ORDER_TRACKING.DRIVER_INFO_DRIVER_CONTACT_LABEL}
        </Text>
        &nbsp;
        <Text type={labelTextStyles.secondary}>{driverContact}</Text>
      </View>
    </View>
  );
};

export { DriverInformation };
