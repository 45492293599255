import * as React from "react";

const selfClosingTags = [
  "area",
  "base",
  "br",
  "embed",
  "hr",
  "iframe",
  "img",
  "input",
  "link",
  "meta",
  "param",
  "source",
  "track",
];
// this component takes as its props the tag name, children
const Jsx = ({ tag: Tag, ...props }) => {
  const finalProps = props;
  if (selfClosingTags.includes(Tag)) {
    delete finalProps.children;
  }
  return <Tag {...finalProps} />;
};

export default Jsx;
