import * as React from "react";
import { Condition as If } from "components/elements/Condition";
import { View, Text, Radio } from "components/elementsThemed";
import { useCell } from "hooks";
import { formatPrice } from "utils/helpers/string";
import { QuantitySelector } from "components/fragments";
import css from "./packingItemType2.module.scss";

const PackingItemType1 = ({
  isSingleSelectable,
  name,
  price,
  type,
  itemQuantity,
  onAddPackingItem,
  onRemovePackingItem,
}) => {
  const { views, labelTextStyles, buttons } = useCell(type);
  const isSelected = itemQuantity >= 0;

  return (
    <View
      type={isSelected ? views.secondary : views.background}
      Component="li"
      className={css.packingItem}
      active={isSelected ? "1" : "0"}
    >
      <div className={css["packingItem-name"]}>
        <Text
          type={
            isSelected
              ? labelTextStyles.primarySelected
              : labelTextStyles.primary
          }
        >
          {name}
        </Text>
      </div>

      <div className={css["packingItem-price"]}>
        {price ? (
          <Text
            type={labelTextStyles.secondary}
            className={css["packingItem-price-content"]}
          >
            {formatPrice(price)}
          </Text>
        ) : null}
      </div>

      <If is={itemQuantity === 0}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <div
          className={css["packingItem-overlay"]}
          onClick={onAddPackingItem}
          onKeyDown={onAddPackingItem}
          role="listitem"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex="0"
        />
      </If>

      <If is={isSingleSelectable && itemQuantity > 0}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <div
          className={css["packingItem-overlay"]}
          onClick={onRemovePackingItem}
          onKeyDown={onRemovePackingItem}
          role="listitem"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex="0"
        />
        <Radio
          type={buttons.primary}
          className={css["packingItem-radio"]}
          value={isSelected}
          onChange={onRemovePackingItem}
        />
      </If>

      <If is={!isSingleSelectable && itemQuantity > 0}>
        <QuantitySelector
          viewType={views.secondary}
          buttonType={buttons.primary}
          labelType={labelTextStyles.primarySelected}
          quantity={itemQuantity}
          inc={onAddPackingItem}
          dec={onRemovePackingItem}
        />
      </If>
    </View>
  );
};

export default PackingItemType1;
