import * as React from "react";
import styles from "./customTipping.module.scss";
import { convertDecimalToPercentage, mapPercentageToString } from "./helpers";
import { TipProps } from "./types";

const Tip: React.FC<TipProps> = ({
  singleTip,
  totalAmount,
  setTip,
  activeBorder,
  isCustomized,
  customTipActiveStyle,
  setCustomize,
  setCurrentTip,
  selected,
}) => {
  const amount = mapPercentageToString(totalAmount, singleTip);
  const percentage = convertDecimalToPercentage(singleTip);

  // coerce amount to number
  const amountInNumber = +amount;

  // selected tip when clicked
  const handleTip = () => {
    setTip(amountInNumber);
    setCurrentTip({
      amount: amountInNumber,
      currentTipLabel: percentage,
      selectedIndex: singleTip,
    });
    setCustomize(false);
  };

  return (
    <fieldset
      style={activeBorder}
      className={styles["tipsContainer-wrapper--singleTip"]}
    >
      <legend className={styles["tipsContainer-wrapper--legend"]}>
        {percentage}%
      </legend>
      <button
        className={styles["tipsContainer-wrapper--amount"]}
        onClick={handleTip}
        aria-pressed={selected ? "true" : "false"}
      >
        <span
          className={
            selected
              ? styles["tipsContainer-wrapper--customAmountSelected"]
              : null
          }
          style={selected && !isCustomized ? customTipActiveStyle : null}
        >
          ${amount}
        </span>
      </button>
    </fieldset>
  );
};

export { Tip };
