import * as React from "react";
import { Formik, Form } from "formik";
import csx from "classnames";

import { Condition, Layout } from "components/elements";
import { View, Text, Field } from "components/elementsThemed";
import DrawerButton from "../../../../components/DrawerButton";
import { Schemas, config, images, Copy } from "utils";
import { stringReplacer } from "utils/helpers/string";

import commonStyles from "../../../index.module.scss";
import css from "./tableNumberType1.module.scss";

const { Flex } = Layout;
const { Condition: If } = Condition;
const { Input } = Field;

const TableNumber = ({ order, style, inputRef, error, onSubmit }) => {
  const { views, inputs, cells, labels } = style;

  return (
    <Formik
      initialValues={{
        tableNumber: order.order.table.number,
      }}
      validationSchema={Schemas.TableNumberSchema}
      validateOnChange={false}
      onSubmit={onSubmit}
      render={({
        errors,
        values,
        status,
        setFieldValue,
        isSubmitting,
        setStatus,
      }) => (
        <View
          type={views.background}
          className={commonStyles.container}
          Component={Form}
        >
          <div className={css.tableNumber}>
            {images?.table_number && (
              <img
                className={css["tableNumber-image"]}
                src={images.table_number}
                alt="Table Number"
              />
            )}
            <Text type={labels.header} className={css["tableNumber-header"]}>
              {stringReplacer(Copy.KIOSK_STATIC.INTRODUCTION_HEADER, [
                {
                  replaceTarget: "{restaurant}",
                  replaceValue: config.restaurant,
                },
              ])}
            </Text>
            <If is={Copy.KIOSK_STATIC.INTRODUCTION_LOCATION}>
              <Text
                type={labels.location}
                className={css["tableNumber-location"]}
              >
                {stringReplacer(Copy.KIOSK_STATIC.INTRODUCTION_LOCATION, [
                  {
                    replaceTarget: "{location.name}",
                    replaceValue: order?.location?.name,
                  },
                ])}
              </Text>
            </If>
            <Text
              type={labels.title}
              className={css["tableNumber-title"]}
              dangerouslySetInnerHTML={{
                __html: Copy.KIOSK_STATIC.INTRODUCTION_TITLE,
              }}
            />
            <Text
              type={labels.description}
              className={css["tableNumber-description"]}
              dangerouslySetInnerHTML={{
                __html: Copy.KIOSK_STATIC.INTRODUCTION_TEXT,
              }}
            />
            <View type={views.primary} className={css["tableNumber-fields"]}>
              <Input
                inputRef={inputRef}
                className={[css["tableNumber-input"], "input-table-number"]}
                label=""
                name="tableNumber"
                type={inputs.standard}
                error={errors.tableNumber}
                value={values.tableNumber}
                placeholder={Copy.KIOSK_STATIC.CHECKOUT_PLACEHOLDER}
                onChange={(e) => {
                  setStatus("");
                  setFieldValue("tableNumber", e.target.value);
                }}
              />
            </View>
            {(status || error) && (
              <Text type={labels.error}>{status || error}</Text>
            )}
            <Flex className={css["tableNumber-information"]}>
              <If is={Copy.KIOSK_STATIC.INTRODUCTION_TEXT_1}>
                <Text
                  type={labels.information}
                  className={csx(css["tableNumber-description"])}
                  dangerouslySetInnerHTML={{
                    __html: Copy.KIOSK_STATIC.INTRODUCTION_TEXT_1,
                  }}
                />
              </If>
              <If is={Copy.KIOSK_STATIC.INTRODUCTION_TEXT_2}>
                <Text
                  type={labels.information}
                  className={csx(css["tableNumber-description2"])}
                  dangerouslySetInnerHTML={{
                    __html: Copy.KIOSK_STATIC.INTRODUCTION_TEXT_2,
                  }}
                />
              </If>
            </Flex>
          </div>
          <DrawerButton
            buttonProps={{
              htmlType: "submit",
              disabled: error || isSubmitting,
            }}
          >
            {Copy.KIOSK_STATIC.CONFIRMATION_BUTTON}
          </DrawerButton>
        </View>
      )}
    />
  );
};

export default TableNumber;
