/**
 * https://www.notion.so/lunchboxtech/Using-client-side-API-response-validation-and-normalization-2d9c96eb5afd48739e79cb526f00781b
 */
import * as Yup from "yup";

/**
 * Dictionary of Yup schemas where keys are regular expressions
 * that can match a URL path and values are Yup schemas that
 * should describe responses from that route.
 *
 * FIXME: This structure assumes that POST, GET, etc requests for
 * the same route will have the same schema, which _should_ be true
 * in an ideal world. If that's not the case, this will need to be
 * rethought to allow per-method schemas.
 */
const responseSchemas = {
  "/locations$": Yup.array()
    .of(
      Yup.object({
        phone: Yup.object({
          isVerified: Yup.boolean(),
          value: Yup.string(),
        }),
      }),
    )
    .required(),
};

/**
 * Given a URL, get an associated Yup schema for the response.
 *
 * @param {string} url               URL of request
 * @returns {(Yup.Schema|undefined)} Yup schema or undefined
 */
export function getSchemaFromResponseUrl(url: string) {
  /**
   * The first matching response validator.
   * If it exists, will be a [path, fn] tuple.
   */
  const validatorEntry = Object.entries(responseSchemas).find(([key]) =>
    new RegExp(key).test(url),
  );

  // No validator found.
  if (!validatorEntry) {
    return undefined;
  }

  return validatorEntry[1];
}
