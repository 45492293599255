import * as React from "react";
import { getUnixTime } from "date-fns";
import { Layout } from "components";
import { Order as OrderProvider, Menu } from "components/providers";
import { usePatronContext } from "components/providers/Patron/PatronContext";
import { Loader, Empty } from "components/fragments";
import { Text, View } from "components/elementsThemed";
import { Layout as LayoutElement } from "components/elements";
import { withTemplate } from "components/hocs";
import { useResource } from "hooks";
import { images, Copy } from "utils";
import styles from "pages/Checkout/checkoutType2.module.scss";
import OrderRoutes from "../Order";

const { Flex } = LayoutElement;

const mapMods = (mods) => {
  const uniqueOptions = mods.reduce((accu, i) => {
    const accuTemp = [...accu];
    if (accuTemp[i.option]) {
      accuTemp[i.option].push(i.item);
    } else {
      accuTemp[i.option] = [i.item];
    }
    return accuTemp;
  }, {});
  return Object.entries(uniqueOptions).map(([key, value]) => ({
    items: value,
    option: key,
  }));
};

const OrderPage = ({ style: { labels, views }, match, history }) => {
  const patronContext = usePatronContext();
  const [{ resource: res, fetching, error }] = useResource({
    path: `/cart/${match.params.id}`,
  });
  if (fetching) {
    return null;
  }

  const onClose = () => history.push(`/location/${res.location.slug}`);

  const { orderType, deliveryInfo, discount, scheduledAt } = res;

  const items = (res && res.items ? res.items : []).map((i) => {
    const mods = mapMods(i.modifications);
    return {
      group: i.group,
      item: i.item.id,
      mods,
      name: i.name,
      price: i.price,
    };
  });

  const initOrderData = {
    discount,
    scheduledAt: scheduledAt ? getUnixTime(new Date(scheduledAt)) : null,
  };

  if (orderType === "delivery") {
    initOrderData.address = deliveryInfo;
  }

  return (
    <Layout>
      <View type={views.background} className={styles["checkout-layout"]}>
        {/* Not styled correctly
        <div>
          <Image
            className={`${styles.logo}`}
            mediaName="art_logo_1"
            mediaType="svg"
            alt="Logo"
          />
          <div className={styles.title}>
            <Text type={labels.secondary}>
              {Copy.CHECKOUT_STATIC.CART_NOT_FOUND_MESSAGE}
            </Text>
          </div>
        </div> */}
        {Object.keys(error).length ? (
          <View
            type={views.background}
            Component={Flex}
            justify="center"
            className={styles.content}
          >
            <Empty imgSrc={images?.art_empty_cart}>
              <Text type={labels.emptyCart}>
                {Copy.CHECKOUT_STATIC.COMPLETE_ORDER_MESSAGE}
              </Text>
            </Empty>
          </View>
        ) : (
          <OrderProvider.MemoryOrder.Provider
            location={res.location}
            items={items}
            orderType={orderType}
            order={initOrderData}
          >
            {(order) => (
              <Menu.MenuProvider
                location={res.location.id}
                orderType={res.orderType}
              >
                {({ isFetching }) =>
                  isFetching ? (
                    <View
                      Component={Flex}
                      type={null}
                      className={styles.checkout}
                      direction="col"
                      justify="center"
                    >
                      <Loader />
                    </View>
                  ) : (
                    <OrderRoutes
                      cart={res.id}
                      orderContext={order}
                      patronContext={patronContext}
                      history={history}
                      location={res.location}
                      isLoggedIn={patronContext.isLoggedIn}
                      isEmployee={patronContext.patron.isEmployee}
                      close={onClose}
                    />
                  )
                }
              </Menu.MenuProvider>
            )}
          </OrderProvider.MemoryOrder.Provider>
        )}
      </View>
    </Layout>
  );
};

export default withTemplate(OrderPage, "cart");
