import * as React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";

// Third Party Libraries
// To best leverage Stripe’s advanced fraud functionality,
// ensure that Stripe.js is loaded on every page, not just your checkout page.
import "@stripe/stripe-js";
import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";

// Local Libraries
// TODO: Add initializer for loggers, move to hooks, add logger functions for better warning/error handling
import "./loggers";
import * as serviceWorker from "./serviceWorker";
import App from "./App";

// Stylesheets
import "normalize.css/normalize.css";
import "./styles/main.scss";
import "@lunchboxinc/lunchbox-components/dist/style.css";

const { BUILD_ENV } = process.env;

if (BUILD_ENV !== "production") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);
}

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Router basename={process.env.PUBLIC_URL ?? ""}>
      <App className="fs-unmask" />
    </Router>
  </QueryClientProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
