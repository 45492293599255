import * as React from "react";
import { VALIDATE_TABLE_NUMBER, HANDLE_ERROR } from "utils/api";
import { Copy, config } from "utils";
import { useOrderContext } from "components/providers/Order/OrderContext";

const withTableNumber =
  (Component) =>
  ({ style, onSuccess, ...props }) => {
    const order = useOrderContext();
    const inputRef = React.useRef();

    const isWebsiteKioskEnabled = config?.kiosk?.enabled;
    const { isKioskEnabled } = order?.location;

    // TODO: Check dine-in hours...

    React.useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, [inputRef]);

    const validateTableNumber = async ({ tableNumber }, actions) => {
      try {
        const { data } = await VALIDATE_TABLE_NUMBER({
          tableNumber,
        });

        if (data.isValid && data.table.id) {
          onSuccess({ id: data.table.id, number: tableNumber });
          actions.setStatus(data.message);
        } else {
          actions.setStatus(data.message);
        }
      } catch (error) {
        const e = HANDLE_ERROR(error);
        actions.setStatus(e.data || Copy.KIOSK_STATIC.UNKNOWN_ERROR_MESSAGE);
      } finally {
        actions.setSubmitting(false);
      }
    };

    return (
      <Component
        order={order}
        style={style}
        inputRef={inputRef}
        error={
          !(isWebsiteKioskEnabled && isKioskEnabled) &&
          Copy.KIOSK_STATIC.DISABLED_MESSAGE
        }
        onSubmit={validateTableNumber}
        {...props}
      />
    );
  };

export default withTableNumber;
