import * as React from "react";
import Item from "../ModifierItem";
import {
  getOptionName,
  isMultiSelectable,
  isOptionalSingle,
  isSameCalories,
  isSamePrice,
} from "../helpers";

type WithOptionProps = {
  addModifier: (payload: any) => void;
  error: any; // tbd
  errorRef: any; // tbd
  forwardRef: React.Ref<HTMLElement>;
  handleScroll: (optionId: string) => void;
  id: string;
  isSingleSelectionOnly: boolean;
  items: any; // tbd
  max: number;
  min: number;
  name: string;
  navIndex: any; // tbd
  optionItemTypes: any;
  optionRef: any;
  removeModifier: (payload: any) => void;
  selectedMods: any; // tbd
  type: string;
  sameCalories: boolean;
};

type ModifierItemType = {
  id: string;
  items: any; // tbd
  samePrice: boolean;
  selectedMods: any; // tbd
  optionItemTypes: any; // tbd
  [x: string]: any;
};

const mapModifierItems = ({
  id,
  items,
  samePrice,
  selectedMods,
  optionItemTypes,
  ...props
}: ModifierItemType) =>
  items?.map((item: any) => {
    const filterItems =
      selectedMods?.filter((mod: any) => mod?.item === item?.id) || [];
    const currentItem = filterItems?.[0] || {};
    const quantity = currentItem?.quantity || 0;
    const parent = {
      isNested: item?.isNested,
      item: item?.id,
      modifiers: item?.isNested ? currentItem?.modifiers || {} : currentItem,
      multiSelect: props?.multiSelect,
      option: id,
      quantity,
    };

    return (
      <Item
        {...props}
        checked={quantity}
        item={item}
        key={item.id}
        parent={parent}
        price={samePrice ? 0 : item.price}
        quantity={quantity}
        type={optionItemTypes[quantity ? "selected" : "default"]}
      />
    );
  });

const withOption = <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P & WithOptionProps> =>
  function ({
    addModifier,
    error,
    errorRef,
    forwardRef,
    handleScroll,
    id = "",
    isSingleSelectionOnly,
    items = [],
    max = 1,
    min = 0,
    name = "",
    navIndex,
    optionItemTypes,
    optionRef,
    removeModifier,
    selectedMods,
    type,
  }: WithOptionProps) {
    const optionalSingle = isOptionalSingle(min, max);
    const singleSelect = isSingleSelectionOnly || optionalSingle;
    const multiSelect = singleSelect ? false : isMultiSelectable(min, max);
    const samePrice = isSamePrice(items);
    const sameCalories = isSameCalories(items);

    const modifierItemData = {
      addModifier,
      className: "modifier-item",
      handleScroll,
      id,
      isOptionalSingle: singleSelect, // TODO: Change items to use singleSelect
      items,
      multiSelect,
      optionItemTypes,
      removeModifier,
      sameCalories,
      samePrice,
      selectedMods,
    };

    const modifierItems = mapModifierItems(modifierItemData);

    const additionalProps = {
      error,
      errorRef,
      forwardRef,
      id,
      modifierItems,
      name: getOptionName(items, name),
      navIndex,
      optionRef,
      type,
    };
    return <Component {...(additionalProps as P)} />;
  };

export default withOption;
