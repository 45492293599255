import { withTypeSelector } from "components/hocs";
import { config } from "utils";
import Type1 from "./Type1";
import Type2 from "./Type2";
import Type3 from "./Type3";

export default withTypeSelector(
  {
    Type1,
    Type2,
    Type3,
  },
  config.theme.item_details.footer,
  Type1,
);
