import * as React from "react";

import { Layout } from "components/elements";
import { withTemplate, OrderingHocs } from "components/hocs";
import { View } from "components/elementsThemed";
import { Loader, Empty } from "components/fragments";
import { Copy, constants } from "utils";
import PackingContext from "./packingContext";
import Menu from "./Menu";
import DrawerButton from "../../../components/DrawerButton";

import css from "./index.module.scss";

const { Flex } = Layout;
const {
  DATA_TEST: { CART_FOOTER_NEXT },
} = constants;

const { Provider } = PackingContext;

const { withPackingInstructions } = OrderingHocs;

const PackingInstructions = ({
  style: { views, cells, labels },
  onSuccess,
  packingMenu,
  packingItems,
  onAddItems,
  clearPackingItems,
  errors,
  onUpdateSelectedItems,
  setHeader,
}) => {
  if (packingMenu.fetching) {
    return (
      <View
        type={views.background}
        Component={Flex}
        grow={1}
        align="center"
        justify="center"
      >
        <Loader />
      </View>
    );
  }

  const onAddPackingItems = () => {
    clearPackingItems();
    onAddItems();
    onSuccess();
  };

  return (
    <View
      type={views.background}
      Component={Flex}
      className={css.packingInstruction}
    >
      <Flex grow={1} className={css["packingInstruction-content"]}>
        <Provider
          menus={packingMenu.menus.hash}
          groups={packingMenu.groups.hash}
          items={packingMenu.items.hash}
          options={packingMenu.options.hash}
          errors={errors}
          packingItems={packingItems}
        >
          {packingMenu.menus.array.length ? (
            packingMenu.menus.array.map((i) => (
              <Menu
                key={i.id}
                id={i.id}
                type={labels.primary}
                groupType={cells.group}
                itemType={cells.item}
                onUpdateSelectedItems={onUpdateSelectedItems}
                packingItems={packingItems}
                setHeader={setHeader}
              />
            ))
          ) : (
            <Empty>{Copy.CART_STATIC.NO_PACKING_INSTRUCTION_MESSAGE}</Empty>
          )}
        </Provider>
      </Flex>
      <DrawerButton
        buttonProps={{
          "data-cy": CART_FOOTER_NEXT,
          containerClass: CART_FOOTER_NEXT,
          disabled: errors.length,
          onClick: onAddPackingItems,
        }}
      >
        {Copy.CART_STATIC.NEXT_BUTTON_TEXT}
      </DrawerButton>
    </View>
  );
};

export default withTemplate(
  withPackingInstructions(PackingInstructions),
  "packingInstructions",
);
