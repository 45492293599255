export const GUESTS_CONTACT_SECTION_OPEN_HEADER = "ENTER CONTACT INFORMATION";
export const GUESTS_CONTACT_SECTION_CLOSE_HEADER = "CONTACT";
export const GUESTS_PAYMENT_SECTION_OPEN_HEADER = "PAYMENT";
export const GUESTS_PAYMENT_SECTION_CLOSE_HEADER = "PAYMENT";
export const GUESTS_PAYMENT_SECTION_CREDIT_DEBIT_CARD = "Credit/Debit Card";
export const GUESTS_INFORMATION_DISCLAIMER =
  "Your receipt will be sent to the email address provided.";
export const GUESTS_INFORMATION_PHONE_MESSAGE =
  "We might contact you regarding your order";
export const GUESTS_LEGAL =
  "By continuing to checkout, you agree to<br />Lunchbox’s Terms of Service and Privacy Policy";
export const GUESTS_PRIMARY_BUTTON = "Sign In";
export const GUESTS_PRIMARY_HEADER = "Log In / Sign Up";
export const GUESTS_PRIMARY_TITLE = "Join us to collect and use reward points!";
export const GUESTS_SECONDARY_HEADER = "Check Out as Guest";
export const GUESTS_SECONDARY_TITLE = "What’s your full name for this order?";
export const GUESTS_NEXT_PAYMENT = "NEXT:PAYMENT";
export const GUESTS_NEXT_REVIEW = "NEXT: REVIEW & PLACE ORDER";

export default {
  GUESTS_CONTACT_SECTION_OPEN_HEADER,
  GUESTS_CONTACT_SECTION_CLOSE_HEADER,
  GUESTS_PAYMENT_SECTION_OPEN_HEADER,
  GUESTS_PAYMENT_SECTION_CLOSE_HEADER,
  GUESTS_PAYMENT_SECTION_CREDIT_DEBIT_CARD,
  GUESTS_INFORMATION_DISCLAIMER,
  GUESTS_INFORMATION_PHONE_MESSAGE,
  GUESTS_LEGAL,
  GUESTS_NEXT_PAYMENT,
  GUESTS_NEXT_REVIEW,
  GUESTS_PRIMARY_BUTTON,
  GUESTS_PRIMARY_HEADER,
  GUESTS_PRIMARY_TITLE,
  GUESTS_SECONDARY_HEADER,
  GUESTS_SECONDARY_TITLE,
};
