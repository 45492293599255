export const orderReducer = (state, { type, payload }) => {
  switch (type) {
    case "CHANGE_DINING_OPTIONS": {
      return {
        ...state,
        diningOption: payload.diningOption,
        scheduledAt: null,
      };
    }
    case "UPDATE_DETAILS":
    case "UPDATE_IDEMPOTENCY": {
      return { ...state, ...payload };
    }
    case "CHANGE_ADDRESS": {
      return {
        ...state,
        address: payload.address,
        lat: payload.lat,
        long: payload.long,
      };
    }
    default: {
      return state;
    }
  }
};

export const itemReducer = (state, { type, payload }) => {
  switch (type) {
    case "ADD_ITEM": {
      const { quantity, item } = payload;
      const newItems = new Array(quantity).fill(0).map(() => item);
      return [...state, ...newItems];
    }
    case "ADD_ITEMS": {
      return [...state, ...payload.items];
    }
    case "REMOVE_ITEM": {
      return state.filter((i, index) => index !== payload.index);
    }
    case "UPDATE_ITEM": {
      const { index, item } = payload;
      const newItems = [...state];
      newItems[index] = { ...newItems[index], ...item };
      return newItems;
    }
    case "CLEAR_ITEMS": {
      return [];
    }
    case "REMOVE_REWARD_ITEM": {
      return state.filter((i) => !Boolean(i.redeemAmount));
    }
    default: {
      return state;
    }
  }
};
