// Refer to https://bit.ly/3pbTSUP for more information about the
// People-Based Attribution (PBA) solution with the AppsFlyer Web SDK

const { DEBUG_TOOLS } = process.env;

const getAppsFlyerData = () => {
  const appsFlyerInit = typeof window !== "undefined" && !!window.AF;

  if (!appsFlyerInit) return {};

  const URL = window?.URL;
  const params = new URL(document?.location)?.searchParams;
  const advertisingId = params && params.get("af_ad_id");

  // Refer to https://bit.ly/2ZnBY7r for more info about attribution link structure and parameters
  const appsFlyerData = {
    advertisingId,
    appsFlyerDeviceId: window?.AF_SDK?.PLUGINS?.PBA?.afWebUserId,
  };

  if (DEBUG_TOOLS) {
    console.info("[lbx-tools]", `AppsFlyer Data:`, appsFlyerData);
  }

  return appsFlyerData;
};

const setAppsFlyerUser = (CUID) => {
  const appsFlyerInit = typeof window !== "undefined" && !!window.AF;

  if (!appsFlyerInit) return;

  if (window.AF) window.AF("pba", "setCustomerUserId", CUID);

  if (DEBUG_TOOLS) {
    console.info(
      "[lbx-tools]",
      `Assigned AppsFlyer Customer User ID (CUID).`,
      CUID,
    );
  }
};

export { getAppsFlyerData, setAppsFlyerUser };
