import * as React from "react";
import { Text, View, Button } from "components/elementsThemed";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { withTemplate } from "components/hocs";
import { Routes } from "utils";
import { REDEEM_BANK_REWARD, HANDLE_ERROR } from "utils/api";
import { useCell } from "hooks";
import { useHistory } from "react-router-dom";
import { Drawer } from "components/fragments";
import { Condition } from "components/elements";
import BankPoints from "./BankPoints";
import css from "./BankBased.module.scss";

const { Condition: If } = Condition;

const BankCard = ({
  pointsBalance,
  reward,
  _toggleRewardDrawer,
  _setDrawerMessage,
  _setRewardId,
}) => {
  const [isRedeemable, setIsRedeemable] = React.useState(false);
  const { labelTextStyles, views, buttons } = useCell("bankCard");

  const checkAvailableBalance = () => {
    const hasEnoughAvailableBalance = pointsBalance >= reward.redeemAmount;
    setIsRedeemable(hasEnoughAvailableBalance);
  };

  React.useEffect(() => {
    checkAvailableBalance();
  }, [isRedeemable]);

  const { name, description, additionalDescription, redeemAmount, id, bankId } =
    reward;
  const history = useHistory();

  const drawerMessage = `Are you sure you want to redeem ${redeemAmount} points for ${name}?`;

  const onClick = () => {
    _setDrawerMessage(drawerMessage);
    _setRewardId(id);
    _toggleRewardDrawer();
  };

  const showBankRewardsInfo = () => {
    history.push({
      pathname: `${Routes.BANK_REWARDS}${id}`,
      state: {
        additionalDescription,
        bankId,
        description,
        id,
        isRedeemable,
        name,
        redeemAmount,
      },
    });
  };

  return (
    <View type={views.background} className={css.container}>
      <View type={views.background} className={css["bankCard--content"]}>
        <div className={css["bankCard--reward"]}>
          <Text type={labelTextStyles.primary}>{name}</Text>
          <Button
            disabled={!isRedeemable}
            onClick={onClick}
            className={!buttons?.primary && css["bankCard--button"]}
            type={buttons?.primary}
          >
            Claim
          </Button>
        </div>
        <div className={css["bankCard--desc"]}>
          <Text type={labelTextStyles.secondary}>{description}</Text>
        </div>
        <div className={css["bankCard--learn"]}>
          <Text type={labelTextStyles.tertiary}>
            <Button
              className={css["bankCard--link"]}
              type={buttons.link}
              onClick={showBankRewardsInfo}
            >
              Learn More
            </Button>
          </Text>
        </div>
        <div className={css["bankCard--divider"]}> </div>
      </View>
    </View>
  );
};

const BankBased = ({ loyaltyData, updateWallet }) => {
  const [isRewardDrawerOpen, setIsRewardDrawerOpen] = React.useState(false);
  const [drawerMessage, setDrawerMessage] = React.useState("");
  const [rewardId, setRewardId] = React.useState("");
  const { labelTextStyles, views, buttons } = useCell("bankCard");
  const { bank } = loyaltyData;
  const bankId = bank?.id;
  const toggleRewardDrawer = () => setIsRewardDrawerOpen(!isRewardDrawerOpen);

  const claimReward = async () => {
    try {
      const res = await REDEEM_BANK_REWARD({
        bank: bankId,
        reward: rewardId,
      });
      const { wallet } = res.data;
      const newWallet = wallet?.promotions;
      updateWallet(newWallet);
    } catch (error) {
      const e = HANDLE_ERROR(error);
      console.error(e);
    }
  };

  const onConfirm = () => {
    toggleRewardDrawer();
    claimReward();
  };

  return (
    <View className={css["bank-loyalty-section"]}>
      <Row spacing={10}>
        <If is={bank}>
          <View>
            <BankPoints
              title={Math.floor(bank?.pointsBalance) || 0}
              text="Points Available"
              labels={labelTextStyles}
              views={views}
            />
            <View className={css["bank-loyalty-title"]}>
              <div>
                <Text type={labelTextStyles.primary}>{bank?.description}</Text>
              </div>
            </View>
          </View>
          <If is={bank?.rewards?.length}>
            <View type={views.background} className={css["bank-loyalty-cards"]}>
              {bank?.rewards?.map((reward) => (
                <BankCard
                  pointsBalance={Math.round(bank?.pointsBalance)}
                  reward={{ bankId, ...reward }}
                  _toggleRewardDrawer={toggleRewardDrawer}
                  _setDrawerMessage={setDrawerMessage}
                  _setRewardId={setRewardId}
                />
              ))}
            </View>
          </If>
        </If>
      </Row>
      <Drawer.ResponsiveDrawer
        isOpen={isRewardDrawerOpen}
        onChange={setIsRewardDrawerOpen}
        ariaLabel="Loyalty Rewards"
      >
        <View
          className={css["drawer--container"]}
          type={views.drawerBackground}
        >
          <div className={css["drawer--dialogue"]}>
            <Text type={labelTextStyles.drawer}>{drawerMessage}</Text>
          </div>
          <Col
            xs={{ offset: "1-12", span: "5-6" }}
            sm={{ offset: "1-8", span: "3-4" }}
            style={{
              marginLeft: 0,
            }}
          >
            <Row gutter={60}>
              <Col xs="1-2">
                <Button
                  block
                  className={css["drawer--button"]}
                  type={buttons.confirm}
                  onClick={onConfirm}
                >
                  Confirm
                </Button>
              </Col>
              <Col xs="1-2">
                <Button
                  block
                  className={css["drawer--button"]}
                  type={buttons.cancel}
                  onClick={toggleRewardDrawer}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Col>
        </View>
      </Drawer.ResponsiveDrawer>
    </View>
  );
};

export default withTemplate(BankBased, "loyalty");
