/**
 * https://www.notion.so/lunchboxtech/Using-client-side-API-response-validation-and-normalization-2d9c96eb5afd48739e79cb526f00781b
 */

import { AxiosResponse } from "axios";
// eslint-disable-next-line import/no-unresolved
import { BaseLocation } from "../../@types/location";

/**
 * @callback DataFetcher
 * @param {any} data
 * @param {Axios.AxiosRequestConfig} config
 */

/**
 * Given a data-fetching function of the form (data, config) => Promise,
 * apply a transformation function to the result by default. This allows
 * us to apply data normalization for an entire route by default that
 * can also be overwritten at the call site.
 *
 * - If a _default_ transformation function isn't provided, the data won't
 *   be transformed at all.
 * - If a `transform` function is passed in the config argument to the
 *   resulting function, it will be used instead of the default.
 *
 * @param {DataFetcher} fn               Data-fetching function
 * @param {Function}    defaultTransform Transformation to apply to data by default
 * @example
 * ```
 * // Wraps data-fetching with a default transform
 * const fetchThing = addDefaultTransform(
 *   (data, config) => axios.post('url', data, config),
 *   defaultTransformation
 * )
 * // applies default transformation
 * fetchThing(data)
 * // applies custom transformation
 * fetchThing(data, { transform: customFn })
 * ```
 */
export function addDefaultTransformToDataFetcher<T>(
  fn: (data: any, config?: any) => Promise<AxiosResponse<T>>,
  defaultTransform = (d: any) => d,
) {
  return function wrappedAxiosCall(
    data: any,
    { transform = undefined, ...config } = {},
  ) {
    const transformFn = transform || defaultTransform;

    return fn(data, config).then((res) => ({
      ...res,
      data: transformFn(res.data),
    }));
  };
}

/**
 * Given an API response for a set of locations, ensure that each has
 * all necessary data.
 *
 * TODO: At the moment, this only handles normalization for a location's
 * phone data.  This should be expanded to cover defaults for the full
 * data set.
 *
 * @typedef Location
 * @property {Object}       phone
 * @property {boolean}      phone.isVerified
 * @property {string|null}  phone.value
 *
 * @param {RestaurantLocation[]} locations Array of locations from API response
 */
export function normalizeLocations(locations: BaseLocation[]) {
  return locations.map((location) => ({
    ...location,
    // phone might be null if it wasn't added in the backend,
    // but we need it to be an object with a value key.
    phone: location.phone || {
      isVerified: false,
      value: null,
    },
  }));
}
