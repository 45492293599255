import * as React from "react";
import styled from "styled-components";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { images } from "utils";
import Flex from "./elements/Layout/flex";

interface LayoutProps {
  children: React.ReactNode;
}

const Container = styled(Flex)`
  background-image: url("${images?.art_background}");
  background-size: cover;
  background: #f0f2f5;
  flex: auto;
  height: 100vh;
  min-height: 0;
`;

const contentProps = {
  lg: {
    offset: "1-3",
    span: "1-3",
  },
  md: {
    offset: "1-4",
    span: "1-2",
  },
  sm: {
    offset: "1-6",
    span: "2-3",
  },
  xs: {
    span: "1",
  },
};

export function Layout({ children }: LayoutProps) {
  return (
    <Container direction="col" justify="center">
      <Row flex style={{ flexGrow: 1 }}>
        <Col
          {...contentProps}
          style={{
            alignSelf: "center",
            display: "flex",
            height: "80vh",
            marginTop: "4.5rem",
          }}
        >
          {children}
        </Col>
      </Row>
    </Container>
  );
}
