import * as React from "react";
import { AnimatePresence, motion } from "framer-motion";

import { images } from "../utils";
import BackButton from "./BackButton/BackButton";

export interface DrawerHeaderProps {
  backButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  title?: string;
  titleProps?: React.HTMLAttributes<HTMLElement>;
  hideBackButton?: boolean;
}

/**
 * If you don't like the way this looks then you likely shouldn't be using it.
 */
export default function DrawerHeader({
  backButtonProps,
  title = "",
  titleProps,
  hideBackButton,
}: DrawerHeaderProps) {
  return (
    <motion.div
      data-testid="drawer-header"
      style={{
        zIndex: 1, // boxShadow will be hidden without it...
        display: "flex",
        padding: "1rem",
        boxShadow: "0px 4px 15px rgba(146, 146, 146, 0.1)",
      }}
    >
      <AnimatePresence>
        {!hideBackButton && ( // Close button visible by default for accesisiblty reasons
          <motion.div>
            <BackButton
              data-testid="drawer-backbtn"
              imgSrc={images?.button_back_login}
              {...backButtonProps} // If you need to overwrite onClick handler
            />
          </motion.div>
        )}
        {title && ( // The `style` prop expects a mapping from style properties to values.
          <motion.h3
            {...(!hideBackButton && {
              style: { margin: "0", padding: "0 1rem" },
            })}
          >
            <span {...titleProps}>{title}</span>
          </motion.h3>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
