import { Button } from "./Button";
import { Radio } from "./Radio";
import { RadioButton } from "./RadioButton";
import { Text } from "./Text";
import Cell from "./Cell";
import Segment from "./Segment";
import { View } from "./View";
// this rule disabling could be removed as long as we totally switch to named exports from default exports
// eslint-disable-next-line import/no-named-as-default
import Field from "./Field";
import Dialogue from "./Dialogue";

/**
 * @namespace ElementsThemed
 */

export {
  Button,
  Cell,
  Dialogue,
  Field,
  Radio,
  RadioButton,
  Segment,
  Text,
  View,
};
export default {
  Button,
  Cell,
  Dialogue,
  Field,
  Radio,
  RadioButton,
  Segment,
  Text,
  View,
};
