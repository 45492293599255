import { config } from "utils";
import { formatPrice } from "utils/helpers/string";

export const isOptionalSingle = (min, max) =>
  ([null, 0].includes(min) || (config.not_replace_mod && min === 1)) &&
  max === 1;

export const isSameCalories = (items = []) =>
  items.every((v) => v?.calories === items?.[0]?.calories);

export const isSamePrice = (items = []) =>
  items.every((v) => v?.price === items?.[0]?.price);

export const findError = (errors, id) => {
  const match = errors.find((i) => i.optionId === id);
  if (match) {
    return match.message;
  }
  return null;
};

export const getOptionName = (items, name) => {
  const samePrice = isSamePrice(items);
  const sameCalories = isSamePrice(items);

  if (samePrice && items?.[0] && items?.[0]?.price !== 0) {
    return `${name}  |  +${formatPrice(items?.[0]?.price)} each`;
  }

  if (
    sameCalories &&
    items?.[0] &&
    ![0, null, undefined].includes(items?.[0]?.calories)
  ) {
    return `${name}  | ${items?.[0]?.calories} cals`;
  }

  return name;
};

export const isMultiSelectable = (min, max) => {
  if (max) {
    if (min < max && max > 1) {
      return true;
    }
    if (max !== 1 && min === max) {
      return true;
    }
  } else if (min > 1 || min === 0) {
    return true;
  }
  return max === null;
};

export const formatCaloriesAndPrice = (calories, price) => {
  const itemPriceCal = calories ? `${calories} | ${price}` : `${price}`;
  return itemPriceCal;
};
