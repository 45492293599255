import * as React from "react";
import { useResource, usePrevious } from "hooks";
import { Loader } from "../../fragments/Loader";

const AutoSend = ({ email, onSuccess }) => {
  const [{ fetching }] = useResource({
    data: {
      email,
    },
    method: "post",
    path: "/patron/forgot-password",
  });
  const prevFetching = usePrevious(fetching);
  React.useEffect(() => {
    if (!fetching && prevFetching) {
      onSuccess(email);
    }
  }, [fetching]);

  if (fetching) {
    return <Loader />;
  }

  return null;
};

export default AutoSend;
