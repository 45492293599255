import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { Condition } from "components/elements";
import { Text, View, Button } from "components/elementsThemed";
import { withTemplate } from "components/hocs";
import { Copy } from "utils";
import {
  enableScroll,
  disableScroll,
  onWheelHandler,
} from "utils/helpers/scroll";
import RewardCardsSelector from "../../RewardCards";
import css from "./walletRewardType1.module.scss";

const { Condition: If } = Condition;

/**
 * Renders Your Rewards section in Your Rewards under Profile
 *
 * @author Htin Linn Aung
 * @memberof Templates.Templates/Loyalty
 * @param {object} props
 * @param {object} prop.style - Theme - Theme value from template provider
 * @param {boolean} props.showRedeem - To decide whether to show redeem indicator
 * @param {Function} props.onClickReward - Callback for when a reward card rendered by this component is clicked
 * @param props.style
 * @param props.onClickRedeem
 * @param {object} props.history - Memory Router history - from Loyalty.jsx
 * @param {object} props.loyaltyData - Loyalty data fetched by parent to this component
 */
const WalletRewardType1 = ({
  style,
  showRedeem,
  onClickReward,
  onClickRedeem,
  loyaltyData,
}) => {
  const { labels, views, buttons, cells } = style;
  const scrollingTabRef = React.useRef();

  const renderRewardsInWallet = () => {
    if (loyaltyData?.wallet?.length) {
      return loyaltyData.wallet.map((reward) => (
        <RewardCardsSelector
          key={reward.id}
          type={cells.walletCard}
          onClick={onClickReward}
          {...reward}
        />
      ));
    }
    return (
      <View type={views.secondary} className={css.rewardCard}>
        <Row>
          <Text type={labels.empty}>
            {Copy.PROFILE_STATIC.LOYALTY_NO_REWARDS}
          </Text>
        </Row>
      </View>
    );
  };

  return (
    <View type={views.borderedBackground} className={css.loyaltyRewardSection}>
      <Row spacing={10}>
        <Col xs="1-2">
          <Text as="h3" type={labels.subtitle}>
            {Copy.PROFILE_STATIC.YOUR_WALLET}
          </Text>
        </Col>
        <Col xs="1-2">
          <If is={showRedeem}>
            <Button
              type={buttons.alternate}
              className={css.redeemPromoButton}
              onClick={onClickRedeem}
            >
              redeem a promo
            </Button>
          </If>
        </Col>
      </Row>
      <Row spacing={10}>
        <div className={css.horizontalScrollbar} ref={scrollingTabRef}>
          {renderRewardsInWallet()}
        </div>
      </Row>
    </View>
  );
};

WalletRewardType1.defaultProps = {
  history: {},
  loyaltyData: {
    wallet: [],
  },
  onClickRedeem: null,
  onClickReward: null,
  showRedeem: false,
};
export default withTemplate(WalletRewardType1, "loyalty");
