import * as React from "react";
import PropTypes from "prop-types";
import { config } from "utils";
import { FETCH_PATRON, FETCH_PATRON_ADDRESS, PATRON_SIGN_OUT } from "utils/api";
import { analytics } from "hooks";
import PatronContext from "./PatronContext";

const storageKey = config.local_storage_key;

const initializeToken = () => {
  const token =
    window.localStorage !== null && window.localStorage.getItem(storageKey);
  return token;
};

const PatronProvider = ({ children }) => {
  const [accessToken, setAccessToken] = React.useState(initializeToken);
  const [patron, setPatron] = React.useState({});
  const [fetching, setFetching] = React.useState(!!accessToken);

  const updatePatron = ({ firstName, lastName, email, phone, birthday }) => {
    const newState = { ...patron };
    if (firstName) {
      newState.firstName = firstName;
    }
    if (lastName) {
      newState.lastName = lastName;
    }
    if (email) {
      newState.email = email;
    }
    if (phone) {
      newState.phone = phone;
    }
    if (birthday) {
      newState.birthday = birthday;
    }
    setPatron(newState);
  };
  const updateCard = (card) => {
    setPatron({ ...patron, squareCustomers: { card } });
  };

  const login = ({ token, ...patronData }) => {
    if (token) {
      setAccessToken(token);
    }

    if (!patronData && localStorage !== null) {
      localStorage.removeItem(storageKey);
    } else {
      setPatron(patronData);
    }
  };

  const logout = async () => {
    try {
      await PATRON_SIGN_OUT();
    } catch (error) {
      console.error(error);
    } finally {
      setPatron({});
      setAccessToken("");
      if (localStorage !== null) {
        localStorage.removeItem(storageKey);
      }
    }
  };

  const fetchPatron = async () => {
    setFetching(true);
    try {
      const res = await FETCH_PATRON();
      if (res.status === 200) {
        login(res.data);
      }
    } catch (e) {
      if (localStorage !== null) {
        localStorage.removeItem(storageKey);
      }
    } finally {
      setFetching(false);
    }
  };

  const updateAddress = async (newAddress) => {
    if (!patron.id) {
      return;
    }
    try {
      const { data } = await FETCH_PATRON_ADDRESS({ address: newAddress });
      if (data && data.address) {
        setPatron({
          ...patron,
          addresses: [data.address],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (accessToken) {
      fetchPatron();
    }
  }, []);
  React.useEffect(() => {
    if (accessToken && window.localStorage !== null) {
      window.localStorage.setItem(storageKey, accessToken);
    }
  }, [accessToken]);

  React.useEffect(() => {
    const { firstName = "", id = "", lastName = "" } = patron;

    if (!id) return;

    const email = patron?.email?.value ?? patron?.email ?? "";
    const phone = patron?.phone?.value ?? patron?.phone ?? "";

    analytics.user.signedin({
      email,
      firstName,
      lastName,
      phone,
      userId: id,
    });
  }, [patron]);

  const contextValues = {
    accessToken,
    fetching,
    isLoggedIn: !!patron.id,
    login,
    logout,
    patron,
    updateAddress,
    updateCard,
    updatePatron,
  };

  if (fetching) {
    return null;
  }

  return (
    <PatronContext.Provider value={contextValues}>
      {children}
    </PatronContext.Provider>
  );
};

PatronProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PatronProvider;
