export const CHECKOUT_LABEL = "Table #:";
export const CHECKOUT_META_TEXT_1 = "You'll be chowing down in no time!";
export const CHECKOUT_PLACEHOLDER = "Enter Your Table Number";
export const CHECKOUT_TEXT = "Your Table Number";
export const CONFIRMATION_BUTTON = "Confirm My Table Number";
export const DISABLED_MESSAGE =
  "Table ordering is currently unavailable. Please contact your server to order.";
export const INTRODUCTION_HEADER = "Welcome to {restaurant}!";
export const INTRODUCTION_LOCATION = "";
export const INTRODUCTION_TEXT =
  "Your table number can be found on your table.<br />Having trouble? Reach out to your server!";
export const INTRODUCTION_TEXT_1 = "";
export const INTRODUCTION_TEXT_2 = "";
export const INTRODUCTION_TITLE = "Enter a Table Number to Order";
export const TABLE_SELECTOR_BUTTON = "Edit your table number";
export const TABLE_SELECTOR_TEXT = "My Table Number";
export const UNKNOWN_ERROR_MESSAGE =
  "An unknown error occurred. Please try again or contact the restaurant for help.";
export const ERROR_MESSAGE = "dine in";

export const SCHEDULED_TIME_FIELD_TEXT = "Estimated Wait Time";
export const CHECKOUT_ORDER_TYPE = "Table Ordering";

export default {
  CHECKOUT_LABEL,
  CHECKOUT_META_TEXT_1,
  CHECKOUT_PLACEHOLDER,
  CHECKOUT_TEXT,
  CONFIRMATION_BUTTON,
  DISABLED_MESSAGE,
  ERROR_MESSAGE,
  INTRODUCTION_HEADER,
  INTRODUCTION_LOCATION,
  INTRODUCTION_TEXT,
  INTRODUCTION_TEXT_1,
  INTRODUCTION_TEXT_2,
  INTRODUCTION_TITLE,
  SCHEDULED_TIME_FIELD_TEXT,
  TABLE_SELECTOR_BUTTON,
  TABLE_SELECTOR_TEXT,
  UNKNOWN_ERROR_MESSAGE,
  CHECKOUT_ORDER_TYPE,
};
