import * as React from "react";
import cx from "classnames";

import { ORDER_TYPES, TRACKING_STATUSES } from "../../../../utils/constants";
import { Copy } from "../../../../utils/Copy";
import { TrackingProgress } from "../../../../components/templates/TrackingProgress";
import { Condition as If } from "../../../../components/elements/Condition";
import { Flex } from "../../../../components/elements/Layout";
import { View } from "../../../../components/elementsThemed/View";
import { Text } from "../../../../components/elementsThemed/Text";
import DrawerButton from "../../../../components/DrawerButton";
import { withTemplate } from "../../../../components/hocs";
import AddressDetail from "./addressDetail";
import { OrderInformation } from "./orderInformation";

import css from "./completeType7.module.scss";
import commonCSS from "../../../index.module.scss";

import type { CompleteStyleProps } from "../complete";
import type { Order } from "../../../../@types/order";

const getTitleTextByOrderType = (isPickupOrder) =>
  isPickupOrder
    ? Copy.CHECKOUT_STATIC.COMPLETE_TYPE4_PICKUP_FROM_TEXT
    : Copy.CHECKOUT_STATIC.COMPLETE_TYPE4_DELIVERING_FROM_TEXT;
const getTextByOrderType = (isPickupOrder, ticketInformation) =>
  isPickupOrder
    ? ticketInformation.location.name
    : ticketInformation.deliveryInfo;
const getPrepTimeTextByOrderType = (isPickupOrder) =>
  isPickupOrder ? "Estimated Prep Time: " : "Estimated Delivery Time:";

const trackingStatusLabels = {
  [TRACKING_STATUSES.ORDER_RECEIVED]:
    Copy.ORDER_TRACKING.ORDER_RECEIVED_DESCRIPTION,
  [TRACKING_STATUSES.ORDER_PICKUP]:
    Copy.ORDER_TRACKING.ORDER_PICKED_UP_DESCRIPTION,
  [TRACKING_STATUSES.OUT_FOR_DELIVERY]:
    Copy.ORDER_TRACKING.ORDER_OUT_FOR_DELIVERY_DESCRIPTION,
};

const getTrackingDescription = (status) =>
  trackingStatusLabels[status] ??
  Copy.CHECKOUT_STATIC.COMPLETE_ORDER_IN_KITCHEN_MESSAGE;

/**
 * As of now this type is used for olo customers since it support olo order tracking UI
 *
 * @param {object} props - bag of react props passed to component
 * @param {object} props.deliveryTime
 * @param {object} props.eta
 * @param {Function} props.onSuccess - callback when the footerbutton is pressed
 * @param props.scheduleTime - if the order is scheduled, this field is the time it will be fulfilled
 * @param {object} props.style - theme files styling value
 * @param {object }props.ticketInformation - post order response
 * @param {object} props.trackingData - order/:id/tracking response
 */

interface CompleteType7Props {
  deliveryTime: string;
  onSuccess: () => void;
  scheduleTime: string;
  style: CompleteStyleProps;
  ticketInformation: Order;
  trackingData: {
    status: string;
  };
}

function CompleteType7({
  deliveryTime,
  onSuccess,
  scheduleTime,
  style,
  ticketInformation,
  trackingData,
}: CompleteType7Props) {
  const { labels, views } = style;
  const isPickupOrder = ticketInformation.orderType === ORDER_TYPES.PICKUP;
  const isDeliveryOrder = ticketInformation.orderType === ORDER_TYPES.DELIVERY;
  const titleText = getTitleTextByOrderType(isPickupOrder);
  const orderTypeText = getTextByOrderType(isPickupOrder, ticketInformation);
  const prepTimeText = getPrepTimeTextByOrderType(isPickupOrder);

  // "purchase-confirmation" is gtm Ecommerce-anchor, please do not remove
  return (
    <View
      type={views.background}
      grow={1}
      Component={Flex}
      className={`${commonCSS.container} ${css.complete}`}
    >
      <div className={cx(css["complete-content"], "purchase-confirmation")}>
        <View
          type={views.secondary}
          Component={Flex}
          grow={0}
          className={css["complete-content-information"]}
          direction="col"
        >
          <OrderInformation
            labels={labels}
            views={views}
            scheduledAt={ticketInformation?.scheduledAt}
            scheduleTime={scheduleTime}
            prepTimeText={prepTimeText}
            deliveryTime={deliveryTime}
            orderStatusDescription={getTrackingDescription(trackingData.status)}
          />
          <View
            type={views.tertiary}
            className={css["complete-content-divider"]}
          />
          {isDeliveryOrder && (
            <>
              <Text type={labels.secondary}>
                {Copy.CHECKOUT_STATIC.COMPLETE_ORDER_SMS_INFO}
              </Text>
              <View
                type={views.tertiary}
                className={css["complete-content-divider"]}
              />
            </>
          )}
          <AddressDetail
            labels={labels}
            titleText={titleText}
            orderTypeText={orderTypeText}
          />
          <View
            type={views.tertiary}
            className={css["complete-content-divider"]}
          />
          <If is={trackingData && isDeliveryOrder}>
            <div className={css["complete-content-map"]}>
              <TrackingProgress style={style} trackingData={trackingData} />
            </div>
          </If>
        </View>
      </div>
      <div className={css["complete-footer"]}>
        <DrawerButton buttonProps={{ onClick: onSuccess }}>
          {Copy.CHECKOUT_STATIC.COMPLETE_BACK_TO_MENU_BUTTON_TEXT}
        </DrawerButton>
      </div>
    </View>
  );
}

const ThemedComplete = withTemplate(CompleteType7, "confirmation");
ThemedComplete.displayName = "CompleteType7";

export { ThemedComplete as CompleteType7 };
