import * as React from "react";
import classNames from "classnames";
import Button from "../../../../../components/elementsThemed/Button";
import Image from "../../../../../components/elements/Image";
import { useCell } from "../../../../../hooks";
import { images } from "../../../../../utils";

import type { OrderItem } from "../../../../../@types/item";
import css from "./placementModifierItem.module.scss";

export type PlacementModifierItemProps = {
  item: OrderItem;
  selected: boolean;
  onClick: () => void;
  quantity: number;
};

export const PlacementModifierItem = ({
  item,
  onClick: handleModifierSelect,
  quantity = 0,
}: PlacementModifierItemProps) => {
  const isSelected = Boolean(quantity);
  const cellType = isSelected ? "optionItemSelected" : "optionItem";
  const { buttons } = useCell(cellType);

  const modifierIconMap = {
    right: (
      <Image src={images.icon_placement_right} alt="Right half of pizza" />
    ),
    left: <Image src={images.icon_placement_left} alt="Left half of pizza" />,
    whole: <Image src={images.icon_placement_whole} alt="Entire pizza" />,
  };

  return (
    <Button
      aria-checked={isSelected}
      aria-disabled={isSelected}
      className={classNames(
        css.placementModifierItem,
        isSelected && css["placementModifierItem-active"],
      )}
      htmlType="button"
      key={item.id}
      onClick={handleModifierSelect}
      role="checkbox"
      type={buttons.tertiary}
    >
      {modifierIconMap[item.name.toLocaleLowerCase()]}
    </Button>
  );
};
