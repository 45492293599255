import { Reducer, useReducer, useState } from "react";

const SET = "SET";

type Action<T> = {
  type: typeof SET;
  payload: Partial<T> | string;
  field: string;
};

const formReducer = <T, A extends Action<T>>(state: T, action: A) => {
  if (action.type === SET) {
    return {
      ...state,
      [action.field]: action.payload,
    };
  }

  return state;
};

const useForm = <T>({ initialValues = {} }: { initialValues: Partial<T> }) => {
  const [values, dispatchValues] = useReducer<Reducer<Partial<T>, Action<T>>>(
    formReducer,
    Object.entries(initialValues).reduce(
      (accu, [key, value]) => Object.assign(accu, { [key]: value }),
      {},
    ),
  );

  const [errors, dispatchErrors] = useReducer<
    Reducer<Partial<T>, Action<string>>
  >(
    formReducer,
    Object.entries(initialValues).reduce(
      (accu, [key]) => Object.assign(accu, { [key]: null }),
      {},
    ),
  );

  const [status, setStatus] = useState("");

  const updateField = (name: string, value: string | Partial<T>) =>
    dispatchValues({
      field: name,
      payload: value,
      type: SET,
    });

  const updateError = (name: string, value: string) =>
    dispatchErrors({
      field: name,
      payload: value,
      type: SET,
    });

  const updateStatus = (value: string) => {
    setStatus(value);
  };

  return {
    errors,
    status,
    updateError,
    updateField,
    updateStatus,
    values,
  };
};

export default useForm;
