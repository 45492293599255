/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/destructuring-assignment */
import * as React from "react";
import { useQuery } from "react-query";
import { usePrevious, analytics } from "hooks";
import { FETCH_MENU, FETCH_UPSELLS_MENU, FETCH_LOYALTY } from "utils/api";
import { config } from "utils";
import { matchesRegex } from "utils/helpers/string";
import { menuReducer, upsellsMenuReducer } from "./reducers";

import MenuContext from "./MenuContext";
import { useOrderContext } from "../Order/OrderContext";
import { usePatronContext } from "../Patron/PatronContext";

import {
  createUniqueResGroupMap,
  setImageUrlToHttps,
  getMarketMenuMap,
} from "./utils";

const regexpsMarket = [new RegExp("^/market")];

const defaultLoyaltyData = {
  data: {
    bank: {
      rewards: [],
      pointsBalance: 0,
    },
  },
};

const INIT_MENU = {
  groups: {
    array: [],
    hash: {},
  },
  items: {
    array: [],
    hash: {},
  },
  menus: {
    array: [],
    hash: {},
  },
  options: {
    array: [],
    hash: {},
  },
};

const MenuProvider = ({ children }) => {
  const orderContext = useOrderContext();
  const patronContext = usePatronContext();
  const [fetching, setFetching] = React.useState(false);
  // eslint-disable-next-line no-use-before-define
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const prevLocation = usePrevious(location);
  // eslint-disable-next-line no-use-before-define
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const prevOrderType = usePrevious(orderType);
  const [upsellsFetching, setUpsellsFetching] = React.useState(false);

  const [menus, dispatch] = React.useReducer(menuReducer, INIT_MENU);
  const [packingMenu, dispatchPackingMenu] = React.useReducer(
    menuReducer,
    INIT_MENU,
  );
  const [upsellsMenu, dispatchUpsellMenu] = React.useReducer(
    upsellsMenuReducer,
    INIT_MENU,
  );

  const [restaurantGroups, setRestaurantGroups] = React.useState([]);
  const [resGroupToMenusMap, setResGroupToMenusMap] = React.useState(new Map());
  const { scheduledAt } = orderContext.order;
  const [marketPlaceHighlightMenuGroup, setMarketPlaceHighlightMenuGroup] =
    React.useState(null);

  const location = orderContext.location.id;
  const orderType = orderContext.order.diningOption;
  const isInMarketPage = matchesRegex(regexpsMarket, window.location.pathname);

  const { data: loyaltyData } = useQuery("loyalties", () => FETCH_LOYALTY(), {
    placeholderData: defaultLoyaltyData,
    enabled: patronContext?.isLoggedIn,
  });

  React.useEffect(() => {
    const fetchMenus = async () => {
      setFetching(true);
      try {
        const [menuData, packingMenuData, upsellableItemsData] =
          await Promise.all([
            FETCH_MENU(
              {
                orderType,
                scheduledAt,
              },
              {
                headers: {
                  locationId: location,
                },
              },
            ),
            FETCH_MENU(
              { menuType: "packing", orderType },
              { headers: { locationId: location } },
            ),
            FETCH_UPSELLS_MENU({}, { headers: { locationId: location } }),
          ]);
        // Convert all images to https before returning the  menu obj
        const newMenuData = setImageUrlToHttps(menuData.data);
        const newPackingMenuData = setImageUrlToHttps(packingMenuData.data);
        dispatch({
          payload: newMenuData,
          type: "ADD",
        });
        dispatchPackingMenu({
          payload: newPackingMenuData,
          type: "ADD",
        });
        const { groups, options, items } = upsellableItemsData.data;
        dispatchUpsellMenu({
          payload: { groups, options, items, menus: [] },
          type: "ADD",
        });
        setRestaurantGroups(newMenuData.restaurantGroups);
      } catch (error) {
        console.log(error);
      } finally {
        setFetching(false);
      }
    };

    if (location) {
      if (prevLocation !== location || orderType !== prevOrderType) {
        fetchMenus();
      }
    }
  }, [location, prevLocation, orderType, prevOrderType, scheduledAt]);

  const fetchUpsellMenu = async () => {
    setUpsellsFetching(true);
    try {
      const { data } = await FETCH_UPSELLS_MENU(
        {},
        { headers: { locationId: location } },
      );
      dispatchUpsellMenu({
        payload: {
          groups: data.groups,
          items: data.items,
          menus: [],
          options: data.options,
        },
        type: "ADD",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setUpsellsFetching(false);
      }, 250);
    }
  };

  React.useEffect(() => {
    if (menus?.menus?.array?.length) {
      const ResGroupToMenusMap = createUniqueResGroupMap(
        menus.menus.array,
        isInMarketPage,
      );
      setResGroupToMenusMap(ResGroupToMenusMap);
    }
  }, [menus?.menus?.array]);

  React.useEffect(() => {
    if (menus?.groups?.hash) {
      setMarketPlaceHighlightMenuGroup(getMarketMenuMap(menus.groups.hash));
    }
  }, [menus?.groups?.hash]);

  /**
   * Returns a function with the hash source scoped to make invokation take one less arg
   *
   * @param {string} source - Either upsell, item, empty default to item
   * @returns {boolean}
   */
  const isItemSubgroup = (source) => {
    /**
     * Checks if the itemId passed into it is part of a subgroup.
     * Is the itemId found is a group which is part of another group
     * A subgroup item is an item that is part of a menu group in which that
     * group is a accessible from another menu group
     * Succintly is  menu > group > group (aka subgroup) > item (itemIdToCheck)
     *
     * @param {string} itemIdToCheck - Id of the item to check if its part of a subgroup
     * @returns {boolean}
     */
    return (itemIdToCheck) => {
      const groupSource =
        source === "upsell" ? upsellsMenu.groups.array : menus.groups.array;
      return (
        groupSource.findIndex(
          (i) => i.subgroups.filter((x) => x.id === itemIdToCheck).length,
        ) !== -1 && !config.flatten_subgroups
      );
    };
  };

  React.useEffect(() => {
    const locationName = orderContext.location.name;
    if (!location) return;

    analytics.location.locationSelected({
      location: locationName,
    });
  }, [location]);

  const contextValues = {
    fetching,
    fetchUpsellMenu,
    groups: menus.groups.array,
    groupsHash: isInMarketPage
      ? marketPlaceHighlightMenuGroup
      : menus.groups.hash,
    isItemSubgroup: isItemSubgroup("item"),
    isUpsellItemSubgroup: isItemSubgroup("upsell"),
    items: menus.items.array,
    itemsHash: menus.items.hash,
    location,
    menus: menus.menus.array,
    menusHash: menus.menus.hash,
    options: menus.options.array,
    optionsHash: menus.options.hash,
    rewardsMenu: loyaltyData?.data?.bank,
    packingMenu,
    resGroupToMenusMap,
    restaurantGroups,
    upsellableItems: upsellsMenu.items.array,
    upsellsFetching,
    upsellsGroups: upsellsMenu.groups.array,
    upsellsGroupsHash: upsellsMenu.groups.hash,
    upsellsItems: upsellsMenu.items.array,
    upsellsItemsHash: upsellsMenu.items.hash,
    upsellsMenu: upsellsMenu.menus.array,
    upsellsMenuHash: upsellsMenu.menus.hash,
    upsellsOptions: upsellsMenu.options.array,
    upsellsOptionsHash: upsellsMenu.options.hash,
  };
  return (
    <MenuContext.Provider value={contextValues}>
      {children(contextValues)}
    </MenuContext.Provider>
  );
};

// MenuProvider.propTypes = {
//   children: PropTypes.node.isRequired
// };

export default MenuProvider;
