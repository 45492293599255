import * as React from "react";

import {
  initialLocation,
  initialOrder,
  initialItems,
  initialPackingItems,
  initialRewardItems,
  DEFAULT_GIFT_CARD,
} from "./initializers";

export const DEFAULT_ORDER_CONTEXT = {
  addManyItems: () => null,
  addManyPackingItemsToOrder: () => null,
  // Packing item methods
  addPackingItemToOrder: () => null,
  addRewardItemToOrder: () => null,
  // Item methods
  addToOrder: () => null,
  // Location methods
  changeLocation: () => null,
  clearItems: () => null,
  clearPackingItems: () => null,
  clearRewardItems: () => null,
  editItemAtIndex: () => null,
  editPackingItemAtIndex: () => null,
  editRewardItemAtIndex: () => null,
  firstTime: true,
  // Computed values
  hasPromotion: false,
  isDelivery: false,
  isKiosk: false,
  isPickup: false,
  items: initialItems(),
  kioskInfoToggle: () => null,
  kioskInfoOnSuccess: () => null,
  kioskInfoVisibility: true,
  location: initialLocation,
  order: {
    ...initialOrder("/", initialLocation),
    giftCard: DEFAULT_GIFT_CARD,
  },
  packingItems: initialPackingItems(),
  removeFromOrder: () => null,
  removePackingItemFromOrder: () => null,
  removeRewardItemFromItems: () => null,
  resetDiscount: () => null,
  resetGiftCard: () => null,
  resetIdempotency: () => null,
  resetOrder: () => null,
  rewardItems: initialRewardItems(),
  scheduledAt: "",
  setDeliveryAddress: () => null,
  setDeliveryNotes: () => null,
  setDiscount: () => null,
  setGiftCard: () => null,
  // Order Details methods
  setOrderType: () => null,
  setScheduledAt: () => null,
  setTableNumber: () => null,
};

const OrderContext = React.createContext();

const useOrderContext = () => {
  const contextValues = React.useContext(OrderContext);
  if (!contextValues) {
    throw new Error(
      "useOrderContext is being accessed outside a OrderContextProvider",
    );
  }
  return contextValues;
};

export { OrderContext, useOrderContext };
