export const ACCEPT_EDITS_BUTTON_TEXT = "Accept Edits";
export const ADD_BUTTON_TEXT = "Add To Cart";
export const ADD_PROMO_CODE_BUTTON_TEXT = "Add a promo code";
export const ADD_TO_CART_BUTTON_TEXT = "Add To Cart";
export const CART_ERROR_MESSAGE =
  "Unable to proceed with your order. Please contact the restaurant to place your order. We apologize for the inconvenience.";
export const CHECKOUT_DELIVERY_HEADER_TEXT = "Your Delivery Summary";
export const CHECKOUT_HEADER_TEXT = "Order Summary";
export const CHECKOUT_KIOSK_HEADER_TEXT = "Your Dine-in Summary";
export const CHECKOUT_PICKUP_HEADER_TEXT = "Your Pickup Summary";
export const CHECKOUT_PLACE_ORDER_BUTTON_TEXT = "Place Order";
export const CLEAR_CART = "Clear Cart";
export const CONFIRM_DINING_TIME = "Confirm {diningOption} time";
export const CONTINUE_CHECKOUT_BUTTON_TEXT = "Continue To Checkout";
export const DELIVERY_FROM_TEXT = "Delivery From";
export const DELIVERY_ORDER_NOTES_LABEL = "Delivery Instructions";
export const DELIVERY_ORDER_NOTES_PLACEHOLDER =
  "e.g. ring the bell after drop-off, leave next to the porch, call upon arrival, etc";
export const DELIVERY_TIP_HEADER_TEXT = "Driver Tip";
export const DISPLAYED_TIME_ZONE = "Times displayed in ";
export const EDIT_BUTTON_TEXT = "Modify";
export const EMPTY_MESSAGE = "Your cart is empty.";
export const GIFT_CARD_TEXT = "Gift Card";
export const CUSTOM_TIP_ERROR = "";
export const HOME_ERROR_MESSAGE = "Sorry about this but an error occurred.";
export const INVALID_PROMO_CODE = "Invalid Promo";
export const ITEM_REMOVE_BUTTON_TEXT = "Remove";
export const ITEM_UNAVAILABLE_TEXT = "Item Unavailable";
export const KIOSK_FROM_TEXT = "Table Ordering From";
export const LOGGED_IN_EMPLOYEE_MESSAGE = "Create Link";
export const LOGGED_IN_USER_MESSAGE = "Checkout";
export const LOYALTY_CREDIT_TEXT = "Loyalty Credit";
export const SEE_REWARDS_BUTTON_TEXT = "See your available rewards!";
export const MINIMUM_ORDER_AMOUNT_BUTTON_TEXT = "Delivery Minimum";
export const MINIMUM_ORDER_AMOUNT_MESSAGE =
  "The price for your order, before tip, must be at least ${deliveryMin} to place a delivery order.";
export const NEXT_BUTTON_TEXT = "Next";
export const NO_PACKING_INSTRUCTION_MESSAGE =
  "No Packing instructions available. Please continue.";
export const NO_PAYMENT_INFORMATION_BUTTON_TEXT = "Payment Required";
export const NO_PAYMENT_INFORMATION_MESSAGE =
  "Please make sure to fill out your payment information.";
export const NOT_LOGGED_IN_USER_MESSAGE = "Login to Checkout";
export const ORDER_DISCOUNT_TEXT = "Discount";
export const ORDERING_FROM_TEXT = "Ordering From";
export const ORDER_HEADER_TEXT = "Order Confirmation";
export const PAY_WITH_CASH_OPTION_TEXT = "Pay with Cash";
export const PICKUP_FROM_TEXT = "Pickup From";
export const PICKUP_ORDER_NOTES_LABEL = "Pickup Instructions";
export const PICKUP_ORDER_NOTES_PLACEHOLDER = "";
export const PICKUP_TIP_HEADER_TEXT = "Restaurant Tip";
export const POPULAR_ADDITIONS_HEADER = "Popular Additions";
export const POPULAR_ADDITIONS_TEXT = "Popular Additions";
export const PRICE_SUBTOTAL_TEXT = "Subtotal";
export const PROMOS_DISCOUNTS_HEADER_TEXT = "Promos & Discounts";
export const REMOVE_BUTTON_TEXT = "Remove";
export const REWARD_MESSAGE =
  "\nYou're ${amountToReward}\naway from your\n${calculatedReward} reward!";
export const REWARD_BALANCE_MESSAGE =
  "\nUSING {usedRewardPoints}\n OF {pointsBalance} POINTS";
export const SCHEDULE_DINING_DATE_HEADER = "Schedule {diningOption} Date";
export const SCHEDULED_DATE_ASAP_ORDER = "Order Now";
export const SCHEDULED_DATE_CONFIRM_TIME = "Confirm {orderType} time";
export const SCHEDULED_DATE_DAY_LABEL = "Available Days";
export const SCHEDULED_DATE_DAY_PLACEHOLDER = "Please select a day";
export const SCHEDULED_DATE_EMPTY = "Please schedule your order";
export const SCHEDULED_DATE_INVALID_TIME =
  "Please select a time for your order";
export const SCHEDULED_DATE_TIME_LABEL = "Available Times";
export const SCHEDULED_DATE_TIME_PLACEHOLDER =
  "Select a day to see available times";
export const SEE_ORDER_CONFIRMATION = "See Order Confirmation";
export const SELECT_LOCATION_BUTTON_TEXT = "No Location Selected";
export const SELECT_LOCATION_MESSAGE = "Please select a location.";
export const SET_DINING_TIME = "Set to {diningOption} ASAP";
export const SYSTEM_ERROR_MESSAGE =
  "Unable to process your order. Please contact the restaurant to confirm your order. We apologize for the inconvenience.";
export const TIP_HEADER_TEXT = "Tip";
export const TOTAL_PRICE_TEXT = "Total";

export const SCHEDULED_DATE_LOCATION_PICKUP_UNAVAILABLE =
  "Location is unavailable for pickup orders";
export const SCHEDULED_DATE_LOCATION_DELIVERY_UNAVAILABLE =
  "Location is unavailable for delivery orders";
export const SCHEDULED_DATE_LOCATION_DINEIN_UNAVAILABLE =
  "Location is unavailable for dine in orders";
export const SCHEDULED_DATE_LOCATION_ORDER_TYPE_UNAVAILABLE =
  "Location is unavailable for ordering";
export const SCHEDULED_DATE_LOCATION_CLOSED =
  "Location is unavailable for online orders";

export const SCHEDULE_BUTTON = "Reschedule";
export const VALIDATE_DISCOUNT_FAILED =
  "Discount code not applied, please try again.";

export default {
  ACCEPT_EDITS_BUTTON_TEXT,
  ADD_BUTTON_TEXT,
  ADD_PROMO_CODE_BUTTON_TEXT,
  ADD_TO_CART_BUTTON_TEXT,
  CART_ERROR_MESSAGE,
  CHECKOUT_DELIVERY_HEADER_TEXT,
  CHECKOUT_HEADER_TEXT,
  CHECKOUT_KIOSK_HEADER_TEXT,
  CHECKOUT_PICKUP_HEADER_TEXT,
  CHECKOUT_PLACE_ORDER_BUTTON_TEXT,
  CLEAR_CART,
  CONFIRM_DINING_TIME,
  CONTINUE_CHECKOUT_BUTTON_TEXT,
  CUSTOM_TIP_ERROR,
  DELIVERY_FROM_TEXT,
  DELIVERY_ORDER_NOTES_LABEL,
  DELIVERY_ORDER_NOTES_PLACEHOLDER,
  DELIVERY_TIP_HEADER_TEXT,
  DISPLAYED_TIME_ZONE,
  EDIT_BUTTON_TEXT,
  EMPTY_MESSAGE,
  GIFT_CARD_TEXT,
  HOME_ERROR_MESSAGE,
  INVALID_PROMO_CODE,
  ITEM_REMOVE_BUTTON_TEXT,
  ITEM_UNAVAILABLE_TEXT,
  KIOSK_FROM_TEXT,
  LOGGED_IN_EMPLOYEE_MESSAGE,
  LOGGED_IN_USER_MESSAGE,
  LOYALTY_CREDIT_TEXT,
  MINIMUM_ORDER_AMOUNT_BUTTON_TEXT,
  MINIMUM_ORDER_AMOUNT_MESSAGE,
  NEXT_BUTTON_TEXT,
  NOT_LOGGED_IN_USER_MESSAGE,
  NO_PACKING_INSTRUCTION_MESSAGE,
  NO_PAYMENT_INFORMATION_BUTTON_TEXT,
  NO_PAYMENT_INFORMATION_MESSAGE,
  ORDERING_FROM_TEXT,
  ORDER_DISCOUNT_TEXT,
  ORDER_HEADER_TEXT,
  PAY_WITH_CASH_OPTION_TEXT,
  PICKUP_FROM_TEXT,
  PICKUP_ORDER_NOTES_LABEL,
  PICKUP_ORDER_NOTES_PLACEHOLDER,
  PICKUP_TIP_HEADER_TEXT,
  POPULAR_ADDITIONS_HEADER,
  POPULAR_ADDITIONS_TEXT,
  PRICE_SUBTOTAL_TEXT,
  PROMOS_DISCOUNTS_HEADER_TEXT,
  REMOVE_BUTTON_TEXT,
  REWARD_BALANCE_MESSAGE,
  SCHEDULED_DATE_ASAP_ORDER,
  SCHEDULED_DATE_CONFIRM_TIME,
  SCHEDULED_DATE_DAY_LABEL,
  SCHEDULED_DATE_DAY_PLACEHOLDER,
  SCHEDULED_DATE_EMPTY,
  SCHEDULED_DATE_INVALID_TIME,
  SCHEDULED_DATE_LOCATION_CLOSED,
  SCHEDULED_DATE_LOCATION_DELIVERY_UNAVAILABLE,
  SCHEDULED_DATE_LOCATION_DINEIN_UNAVAILABLE,
  SCHEDULED_DATE_LOCATION_ORDER_TYPE_UNAVAILABLE,
  SCHEDULED_DATE_LOCATION_PICKUP_UNAVAILABLE,
  SCHEDULED_DATE_TIME_LABEL,
  SCHEDULED_DATE_TIME_PLACEHOLDER,
  SCHEDULE_BUTTON,
  SCHEDULE_DINING_DATE_HEADER,
  SEE_ORDER_CONFIRMATION,
  SEE_REWARDS_BUTTON_TEXT,
  SELECT_LOCATION_BUTTON_TEXT,
  SELECT_LOCATION_MESSAGE,
  SET_DINING_TIME,
  SYSTEM_ERROR_MESSAGE,
  TIP_HEADER_TEXT,
  TOTAL_PRICE_TEXT,
  VALIDATE_DISCOUNT_FAILED,
};
