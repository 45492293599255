/*
 * Order Routes
 * Author: Isaiah Sanchez <isanchez@lunchbox.io>
 *
 * This component is rendered by pages/Order.
 * This component defines all of the ordering routes.
 */

import * as React from "react";
import { Switch } from "react-router-dom";
import _ from "lodash";

import { config, Copy, Routes } from "utils";
import { ItemDetails, LoginSignup, PaymentForm } from "components/templates";
import { Routes as RouteComponents } from "components/fragments";
import { ErrorBoundary } from "components/elements";
import { useMenuContext } from "components/providers/Menu/MenuContext";
import { analytics } from "hooks";
import Cart from "../Cart";
import Guests from "./Guests";
import Checkout from "../Checkout";
import Locations from "../Locations";
import Complete from "./Complete";
import ClearDiscount from "./ClearDiscount";
import Discount from "./Discount";
import GenerateLink from "./GenerateLink";
import PackingInstructions from "./PackingInstructions";
import Confirm from "./Prompt";
import ScheduleDate from "./ScheduleDate";
import TableNumber from "./TableNumber";
import Upsells from "./Upsells";
import InterceptCheckoutComplete from "./InterceptCheckoutComplete";
import BeamPersonalCommunityImpactTabs from "./BeamPersonalCommunityImpactTabs";
import css from "./style.module.scss";
import { getGroupIdByItemId } from "pages/Menu/utils";

const { RouteWithProps } = RouteComponents;

const animationConfig = {
  animate: "in",
  className: css["order-route"],
  exit: "out",
  initial: "initial",
  transition: {
    type: "tween",
  },
  variants: {
    in: {
      x: 0,
    },
    initial: {
      x: "100%",
    },
    out: {
      x: "-100%",
    },
  },
};

const beamAnimationConfig = {
  animate: "in",
  className: css["order-route"],
  exit: "out",
  initial: "initial",
  variants: {
    in: {
      y: 0,
    },
    initial: {
      y: "100%",
    },
    out: {
      y: "100%",
    },
  },
};

/**
 * FallBack component if none of the Beam widgets works
 *
 * @param props
 * @memberof Pages.Pages/Order
 */
const FallBack = (props) => {
  React.useEffect(() => {
    props.onComplete();
  }, []);
  return null;
};

const OrderRoutes = React.memo(
  ({
    browserLocation,
    location,
    history,
    cart,
    orderContext,
    patronContext,
    setHeader,
    onClose,
  }) => {
    // Hooks
    const [beam, setBeam] = React.useState({});
    const [ticketInformation, setTicketInformation] = React.useState({});

    const { packingMenu, upsellableItems, rewardsMenu, groups } =
      useMenuContext();

    // Constants
    const hasPackingMenu = packingMenu && packingMenu.menus.array.length;
    const hasUpsells = upsellableItems.length;

    // Render
    return (
      <Switch location={location} key={location.pathname}>
        <RouteWithProps
          exact
          path={Routes.ROOT}
          component={Cart}
          cart={cart}
          history={history}
          orderContext={orderContext}
          patronContext={patronContext}
          rewardsMenu={rewardsMenu}
          hasUpsells={hasUpsells}
          hasPackingMenu={hasPackingMenu}
          animation={animationConfig}
          onUpsellSuccess={() => {
            if (hasPackingMenu) {
              history.push(Routes.Cart);
            } else if (
              config[orderContext.order.diningOption].guests &&
              (!patronContext.patron.firstName ||
                !patronContext.patron.lastName)
            ) {
              history.push(Routes.GUESTS);
            }
          }}
          addUpsellToCart={(item, group) => {
            history.push({
              pathname: Routes.UPSELLS_ITEM_DETAIL(item),
              state: { group },
            });
          }}
          setHeader={setHeader}
        />
        <RouteWithProps
          path={Routes.FETCH_DISCOUNT}
          component={Discount}
          items={orderContext.items}
          orderType={orderContext.order.diningOption}
          scheduledAt={orderContext.order.scheduledAt}
          order={orderContext}
          onSuccess={(discount) => {
            orderContext.setDiscount(discount);
            history.goBack();
          }}
          setHeader={setHeader}
          animation={animationConfig}
        />
        <RouteWithProps
          path={Routes.CLEAR_DISCOUNT}
          component={ClearDiscount}
          onConfirm={() => {
            if (localStorage) {
              // Remove the discount code from local storage
              localStorage.removeItem("discountCode");
            }
            orderContext.resetDiscount();
            history.goBack();
          }}
          onCancel={() => history.replace(Routes.ROOT)}
          animation={animationConfig}
        />

        <RouteWithProps
          path={Routes.FETCH_ITEM_DETAIL}
          component={({
            match: {
              params: { index },
            },
            location: {
              state: { isGroup },
            },
          }) => {
            const orderItem = orderContext.items[index];
            const isRewardItem = Boolean(orderItem.redeemAmount);
            const itemDetailProps = {
              ...orderItem,
              buttonText: Copy.CART_STATIC.ACCEPT_EDITS_BUTTON_TEXT,
              close: () => history.goBack(),
              id: isGroup ? orderItem.group : orderItem.item,
              isModifyingItem: true,
              isRewardItem: isRewardItem,
              mods: orderItem.state,
              onConfirm: (data) => {
                if (isRewardItem) {
                  return orderContext.editRewardItemAtIndex(data, 0);
                }
                return orderContext.editItemAtIndex(data, index);
              },
              rewardItem: orderItem,
            };

            return <ItemDetails {...itemDetailProps} />;
          }}
          animation={animationConfig}
        />

        <RouteWithProps
          path={Routes.REMOVE_CART_ITEM}
          component={({
            match: {
              params: { index },
            },
          }) => {
            const onConfirm = () => {
              if (index !== undefined) {
                analytics.product.removed({});
                orderContext.removeFromOrder(Number(index));
              } else {
                orderContext.resetDiscount();
                orderContext.clearItems();
                orderContext.clearRewardItems();
                orderContext.clearPackingItems();
              }
              const orderItem = orderContext.items[index];
              const isRewardItem = Boolean(orderItem?.redeemAmount);
              if (isRewardItem) {
                orderContext.clearRewardItems();
              }
              history.replace(Routes.ROOT);
            };
            const text =
              index !== undefined
                ? `"${orderContext.items[index].name}"`
                : "All Items";
            return (
              <Confirm
                onConfirm={onConfirm}
                onCancel={() => history.replace(Routes.ROOT)}
                name={text}
              />
            );
          }}
          animation={animationConfig}
        />

        <RouteWithProps
          exact
          path={Routes.PACKING}
          component={PackingInstructions}
          onSuccess={() => {
            if (
              config[orderContext.order.diningOption].guests &&
              (!patronContext.patron.firstName ||
                !patronContext.patron.lastName)
            ) {
              history.push(Routes.GUESTS);
            } else {
              history.push(Routes.CHECK_OUT);
            }
          }}
          animation={animationConfig}
          setHeader={setHeader}
        />

        <RouteWithProps
          exact
          path={Routes.UPSELLS}
          component={Upsells}
          onSuccess={() => {
            if (hasPackingMenu) {
              history.push(Routes.PACKING);
            } else if (
              config[orderContext.order.diningOption].guests &&
              (!patronContext.patron.firstName ||
                !patronContext.patron.lastName)
            ) {
              history.push(Routes.GUESTS);
            } else {
              history.push(Routes.CHECK_OUT);
            }
          }}
          addToCart={(item, group, restaurantGroup) => {
            history.push({
              pathname: Routes.UPSELLS_ITEM_DETAIL(item),
              state: { group, restaurantGroup },
            });
          }}
          setHeader={setHeader}
          animation={animationConfig}
        />
        <RouteWithProps
          path={Routes.UPSELLS_ITEM}
          component={({
            match: {
              params: { id },
            },
            location: {
              state: { group },
            },
          }) => (
            <ItemDetails
              id={id}
              onConfirm={(item, quantity) => {
                orderContext.addToOrder(
                  {
                    group: getGroupIdByItemId(item.item, groups),
                    ...item,
                    isGroup: false,
                  },
                  quantity,
                  true,
                );
              }}
              showQuantity
              buttonText={Copy.CART_STATIC.ADD_TO_CART_BUTTON_TEXT}
              close={() => {
                if (config?.theme?.checkout?.upsell_layout === "Type2") {
                  history.replace(Routes.ROOT);
                } else {
                  history.replace(Routes.UPSELLS);
                }
              }}
              isUpsell
            />
          )}
          animation={animationConfig}
        />

        <RouteWithProps
          path={Routes.LOCATIONS}
          component={Locations}
          order={orderContext}
          animation={animationConfig}
        />
        <RouteWithProps
          path={Routes.SET_TABLE_NUMBER}
          component={TableNumber}
          order={orderContext}
          setHeader={setHeader}
          onSuccess={(data) => {
            orderContext.setTableNumber(data);
            history.goBack();
          }}
          animation={animationConfig}
        />
        <RouteWithProps
          path={Routes.FETCH_SCHEDULE_DATE}
          component={ScheduleDate}
          order={orderContext}
          setHeader={setHeader}
          onSuccess={() => history.replace("/")}
          animation={animationConfig}
        />

        <RouteWithProps
          path={Routes.LOG_IN}
          trackerPath="cart"
          component={LoginSignup}
          lastLocation={browserLocation.pathname}
          onUpdateRoute={() => {
            return null;
          }}
          onComplete={({ isEmployee }) => {
            if (isEmployee) {
              history.replace(Routes.LINK);
            } else if (config?.theme?.checkout?.upsell_layout === "Type2") {
              history.replace(Routes.ROOT);
            } else if (hasUpsells) {
              history.replace(Routes.UPSELLS);
            } else if (hasPackingMenu) {
              history.replace(Routes.PACKING);
            } else {
              history.replace(Routes.CHECK_OUT);
            }
          }}
          animation={animationConfig}
        />
        <RouteWithProps
          exact
          path={Routes.GUESTS}
          component={Guests}
          history={history}
          patron={patronContext.patron}
          updatePatron={patronContext.updatePatron}
          animation={animationConfig}
        />
        <RouteWithProps
          exact
          path={Routes.CHECK_OUT}
          component={Checkout}
          history={history}
          patronContext={patronContext}
          orderContext={orderContext}
          beam={beam}
          setBeam={setBeam}
          setHeader={setHeader}
          animation={beamAnimationConfig}
          cart={cart}
          onSuccess={(order) => {
            setTicketInformation(order);
            history.replace(Routes.INTERCEPT_CHECKOUT_COMPLETE);
          }}
        />
        {/* Interceptor between Checkout & Complete for Beam new user */}
        <RouteWithProps
          path={Routes.INTERCEPT_CHECKOUT_COMPLETE}
          component={InterceptCheckoutComplete}
          render={() => {
            const onComplete = () => {
              if (!_.isEmpty(beam)) beam.postTransaction(beam);
              history.replace(Routes.PURCHASE_COMPLETE);
            };
            const onSkipBeamSelection = () => {
              history.replace(Routes.PURCHASE_COMPLETE);
            };
            return (
              <ErrorBoundary error={<FallBack onComplete={onComplete} />}>
                <InterceptCheckoutComplete
                  patronEmail={patronContext?.patron?.email?.value}
                  beam={beam}
                  setBeam={setBeam}
                  onComplete={onComplete}
                  onSkipBeamSelection={onSkipBeamSelection}
                  setHeader={setHeader}
                />
              </ErrorBoundary>
            );
          }}
          animation={animationConfig}
        />
        <RouteWithProps
          path={Routes.FETCH_CARDS}
          component={PaymentForm}
          order={orderContext}
          onSuccess={() => history.goBack()}
          setHeader={setHeader}
          animation={animationConfig}
        />
        <RouteWithProps
          path={Routes.PURCHASE_COMPLETE}
          component={Complete}
          beam={beam}
          ticketInformation={ticketInformation}
          order={orderContext}
          onSuccess={onClose}
          onSeeMoreImpact={() => {
            history.replace(Routes.PERSONAL_COMMUNITY_IMPACT);
          }}
          setHeader={setHeader}
          animation={animationConfig}
        />
        <RouteWithProps
          path={Routes.PERSONAL_COMMUNITY_IMPACT}
          component={BeamPersonalCommunityImpactTabs}
          beam={beam}
          onClose={onClose}
          setHeader={setHeader}
          animation={animationConfig}
        />
        <RouteWithProps
          path={Routes.LINK}
          component={GenerateLink}
          order={orderContext}
          onSuccess={onClose}
          animation={animationConfig}
        />
      </Switch>
    );
  },
);

export default OrderRoutes;
