import * as React from "react";
import Styled from "styled-components";
import classnames from "classnames";
import styles from "./card.module.scss";

/**
 * !DEPRECATED: Do not use. This component is too opinonated
 *
 * @param props
 */
const Card = Styled(({ className, children, style, ...props }) => {
  const classes = classnames(
    styles.card,
    props.shadow ? styles["card-shadow"] : undefined,
    props.onClick ? styles.clickable : undefined,
    className,
  );
  return (
    <div
      {...props}
      className={classes}
      ref={props.innerRef}
      style={style}
      onClick={props.onClick}
      {...props}
    >
      {children}
    </div>
  );
})`
  ${(props) =>
    props.hoverable &&
    props.shadow &&
    `
    &:hover {
      box-shadow:  0 12px 19px -6px  ${props.theme.colors.accentLight};
    }
  `}
`;

/**
 * !DEPRECATED: Do not use. This component is too opinonated
 *
 * @param props
 */
const CardBody = Styled(({ className, bordered, ...props }) => {
  const classes = classnames(
    styles["card-body"],
    bordered ? styles.bordered : undefined,
    className,
  );
  return (
    <div className={classes} style={props.style}>
      {props.children}
    </div>
  );
})`
  border-bottom-color: ${(props) => props.theme.colors.alternateGray};
`;

/**
 * !DEPRECATED: Do not use. This component is too opinonated
 *
 * @param root0
 * @param root0.className
 * @param root0.children
 */
const CardTitle = ({ className, children }) => {
  const classes = classnames(styles["card-title"], className);
  return <div className={classes}>{children}</div>;
};

Card.defaultProps = {
  shadow: true,
};

CardBody.defaultProps = {
  bordered: true,
};

export { Card, CardBody, CardTitle };
export default {
  Card,
  CardBody,
  CardTitle,
};
