/*
 * Schedule Date Field
 * Author: Isaiah Sanchez <isanchez@lunchbox.io>
 *
 * This component is rendered by pages/Cart.
 * This component renders a Pickup or Delivery Time field.
 */

import * as React from "react";
import { fromUnixTime, format } from "date-fns";
import { Copy } from "utils";
import {
  displayScheduledAtTime,
  displayScheduledAtTimeDelivery,
  formatTime,
} from "utils/helpers/time";
import { useOrderContext } from "components/providers";
import type { ResourceResponse } from "hooks/useResource";
import Field from "../Field";
import { mapValidateResponseCopy } from "./utils";

type ScheduleFieldProps = {
  type: string;
  onClick: () => void;
  validateOrder: ResourceResponse<any>;
  className: string;
};

const ScheduleField = React.memo<ScheduleFieldProps>(
  ({ onClick, validateOrder, ...props }) => {
    const {
      isDelivery,
      isKiosk,
      location,
      order: { scheduledAt },
    } = useOrderContext();

    const {
      isAsapOrderingEnabled = null,
      serviceEstimate,
      nextAvailableTime,
      nextAvailableDate,
      reason,
    } = validateOrder?.resource;

    let labelText = "Pickup Time";
    let labelValue = "ASAP";
    let scheduleAtFormatter = displayScheduledAtTime;

    if (isDelivery) {
      labelText = "Delivery Time";
      scheduleAtFormatter = displayScheduledAtTimeDelivery;
    }

    if (serviceEstimate) {
      labelValue = `ASAP (${formatTime(serviceEstimate)})`;
    }

    if (isKiosk) {
      labelText = Copy.KIOSK_STATIC.SCHEDULED_TIME_FIELD_TEXT;
      labelValue = `${formatTime(serviceEstimate)}`;
    }

    if (!isAsapOrderingEnabled) {
      if (reason) {
        labelValue = mapValidateResponseCopy(reason);
      } else {
        labelValue =
          nextAvailableTime?.pretty ??
          nextAvailableDate?.pretty ??
          Copy.CART_STATIC.SCHEDULED_DATE_EMPTY;
      }
    }

    if (scheduledAt) {
      const formattedScheduledAtDate = format(
        fromUnixTime(scheduledAt),
        "EEE, M/d/yyyy",
      );
      const formattedScheduledAt = `${formattedScheduledAtDate} ${scheduleAtFormatter(
        scheduledAt,
        location?.timeZone,
      )}`;
      labelValue = formattedScheduledAt;
    }

    const buttonProps = {
      children: "Edit",
      onClick,
    };

    return (
      <Field
        label={labelText}
        value={labelValue}
        buttonProps={isKiosk ? undefined : buttonProps}
        {...props}
      />
    );
  },
);

export default ScheduleField;
