import * as React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./collapsable.module.scss";

const Open = ({ children }) => children;
const Close = ({ children }) => children;

class Collapsable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
    };
    this.toggle = () =>
      this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  static Open = Open;

  static Close = Close;

  render() {
    const { children, className, title } = this.props;
    const { isOpen } = this.state;
    const open = React.Children.toArray(children).find((i) => i.type === Open);
    const close = React.Children.toArray(children).find(
      (i) => i.type === Close,
    );

    return (
      <>
        <div
          className={cx(className, styles.container)}
          role="button"
          onClick={this.toggle}
          onKeyDown={this.toggle}
          tabIndex="0"
        >
          <span className={styles.title}>{title}</span>
          <span className={styles.toggle}>
            <i className={isOpen ? styles.down : styles.left} />
          </span>
        </div>
        <div className={styles.content}>{isOpen ? open : close}</div>
      </>
    );
  }
}

Collapsable.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  title: PropTypes.node,
};

Collapsable.defaultProps = {
  children: null,
  isOpen: false,
  title: null,
};

export { Collapsable, Close, Open };
export default Collapsable;
