import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { Text, Button, View } from "components/elementsThemed";
import { ResponsiveDrawer } from "components/fragments/Drawer/responsiveDrawer";
import { Loyalty } from "components/templates";
import { withTemplate } from "components/hocs";
import { Condition as If } from "components/elements/Condition";
import { config, Copy, Routes } from "utils";
import { stringReplacer } from "utils/helpers/string";
import { usePatronContext } from "components/providers/Patron/PatronContext";

import { Redirect, useHistory } from "react-router-dom";
import MobileAppDownload from "components/templates/MobileAppDownload";
import { useCell } from "hooks/useTemplate";
import { useRewardsMenuDrawer } from "hooks/useRewardsMenuDrawer";
import Logout from "../Logout";
import AccountInformation from "./accountInformation";
import ProfileCard from "./profilecard";
import OrderHistory from "./OrderHistory";
import { Loyalty as LoyaltyMenu } from "../Loyalty/loyalty";
import css from "./profile.module.scss";

const mdLayoutStyle = "1-2";
const lgLayoutStyle = "1-3";

function Profile({ style, order: orderContext }) {
  const { labels, cells } = style;
  const { labelTextStyles, buttons, views } = useCell(cells.header);
  const history = useHistory();
  const { handleRewardItemSelected, closeDrawer } = useRewardsMenuDrawer({
    closeDrawerOnAddingItem: true,
  });

  const { isLoggedIn, logout, patron } = usePatronContext();
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <View Component={Row} className={css.profile}>
      <Col
        xs={{ offset: "1-24", span: "22-24" }}
        sm={{ offset: "1-12", span: "5-6" }}
        lg={{ offset: "1-8", span: "3-4" }}
      >
        <View type={views.background} className={css["profile-header"]}>
          <Row gutter={15} spacing={15}>
            <Col xs="1">
              <Text
                as="h1"
                className="profile-header-welcome"
                type={labelTextStyles.primary}
              >
                {stringReplacer(Copy.PROFILE_STATIC.USER_WELCOME_TEXT, [
                  {
                    replaceTarget: "{firstName}",
                    replaceValue: patron.firstName,
                  },
                ])}
              </Text>
            </Col>
            <Col xs="1">
              <ResponsiveDrawer
                trigger={<Button type={buttons.primary}>Logout</Button>}
                ariaLabel="Logout"
              >
                {({ close }) => <Logout onConfirm={logout} onCancel={close} />}
              </ResponsiveDrawer>
            </Col>
          </Row>
        </View>
        <Row gutter={15} spacing={15}>
          <Col
            data-pl="account-info"
            xs="1"
            md={mdLayoutStyle}
            lg={lgLayoutStyle}
            className={css["profile-section-container"]}
          >
            <ProfileCard
              viewType={style.views.background}
              textType={labels.section}
              title={Copy.PROFILE_STATIC.ACCOUNT_INFO}
            >
              <AccountInformation style={style} />
            </ProfileCard>
          </Col>

          <If is={config.order_history.enabled}>
            <Col
              xs="1"
              md={mdLayoutStyle}
              lg={lgLayoutStyle}
              className={css["profile-section-container"]}
            >
              <ProfileCard
                viewType={style.views.background}
                textType={labels.section}
                title={Copy.PROFILE_STATIC.ORDER_HISTORY}
                componentStyle={css["profile-section-content"]}
              >
                <OrderHistory
                  backToLocationPage={({ slug, orderType, resGroupId }) =>
                    slug
                      ? history.push(
                          Routes.LOCATION_INFO({ orderType, slug, resGroupId }),
                        )
                      : history.push(Routes.ROOT)
                  }
                  orderContext={orderContext}
                  style={style}
                />
              </ProfileCard>
            </Col>
          </If>
          <If is={config?.loyalty?.enabled}>
            <Col
              xs="1"
              md={mdLayoutStyle}
              lg={lgLayoutStyle}
              className={css["profile-section-container"]}
            >
              <ProfileCard
                className={css.rewards}
                viewType={style.views.background}
                textType={labels.section}
                title={Copy.PROFILE_STATIC.YOUR_REWARDS}
              >
                {Boolean(config?.loyalty?.menu) ? (
                  <LoyaltyMenu
                    handleRewardItemSelected={handleRewardItemSelected}
                    onClose={closeDrawer}
                  />
                ) : (
                  <Loyalty walletProps={{ showRedeem: true }} />
                )}
              </ProfileCard>
            </Col>
          </If>
        </Row>
        <If is={config.apple_app_store_link || config.google_play_store_link}>
          <Row>
            <Col xs="1" md={{ offset: "1-3", span: "1-3" }}>
              <Text
                type={labels.section}
                style={{ display: "block", textAlign: "center" }}
              >
                {Copy.PROFILE_STATIC.MOBILE_APP_DOWNLOAD}
              </Text>
            </Col>
          </Row>
          <Row spacing={30}>
            <Col xs="1" md={{ offset: "1-3", span: "1-3" }}>
              <MobileAppDownload />
            </Col>
          </Row>
        </If>
      </Col>
    </View>
  );
}

export default withTemplate(Profile, "profile");
