import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { Text, View } from "components/elementsThemed";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { useCell } from "hooks";
import css from "./option.module.scss";

function Option({
  error,
  errorRef,
  id,
  modifierItems,
  name,
  navIndex,
  optionRef,
  type,
}) {
  const { views, labelTextStyles } = useCell(type);
  const nameWithoutSpace = name.toLowerCase().split(" ").join("-");

  return (
    <div className={css.option} role="group" aria-labelledby={nameWithoutSpace}>
      <View
        type={views.background}
        className={css["option-content"]}
        data-id={id}
      >
        <Row spacing={16}>
          <Col>
            <div ref={optionRef}>
              <div className={css["option-line"]} data-id={navIndex}>
                <Text
                  type={labelTextStyles.secondary}
                  className={css["option-lineText"]}
                  id={nameWithoutSpace}
                >
                  {name}
                </Text>
              </div>
            </div>
          </Col>
          <div
            aria-live="polite"
            ref={errorRef}
            className={css["option-error"]}
          >
            {error && (
              <Text type={labelTextStyles.tertiary}>
                <VisuallyHidden>Error: </VisuallyHidden>
                {error}
              </Text>
            )}
          </div>
        </Row>
      </View>

      <Row flex spacing={16} gutter={16} className={css["option-items"]}>
        {modifierItems}
      </Row>
    </div>
  );
}

Option.displayName = "OptionType5";

export default React.memo(Option);
