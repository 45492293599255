import { withCSSTheme } from "./withCSSTheme";
import withTemplate from "./withTemplate";
import withErrorBoundary from "./withErrorBoundary";
import withTypeSelector from "./withTypeSelector";
import * as OrderingHocs from "./OrderingHocs";

/**
 * @namespace HOCs
 */

export {
  withCSSTheme,
  withTemplate,
  withErrorBoundary,
  withTypeSelector,
  OrderingHocs,
};
export default {
  withCSSTheme,
  withErrorBoundary,
  withTemplate,
  withTypeSelector,
  ...OrderingHocs,
};
