import * as React from "react";
import { Copy } from "utils";

/**
 * @deprecated Use loader fragment instead.
 */
const Loader = (props) => {
  const { loadingMessage } = props;

  return <h1>{Copy.STATIC_COPY.LOADER_LOADING_MESSAGE(loadingMessage)}</h1>;
};

export default Loader;
