import { getQuery } from "../../../hooks/useQueryState";
import { constants, Schemas, config } from "utils";
import uuidv4 from "uuid/v4";

const { AddressSchema } = Schemas;

const {
  ORDER_TYPES: { PICKUP, DELIVERY, KIOSK, DINEIN },
} = constants;

const initDiningOption = config?.tabs?.[0] ?? PICKUP;

const { localStorage } = window;

const INIT_ORDER = (values = {}) => ({
  ...values,
  address: {
    city: "",
    lat: "",
    long: "",
    state: "",
    street1: "",
    street2: "",
    zip: "",
  },
  code: null,
  deliveryNotes: "",
  diningOption: initDiningOption,
  discount: null,
  idempotencyKey: uuidv4(),
  note: "",
  promotionCodeId: null,
  promotionId: null,
  scheduledAt: null,
  table: {
    id: null,
    number: null,
  },
});

const initialLocation = () => {
  const location =
    localStorage !== null && JSON.parse(localStorage.getItem("location"));
  return location || {};
};

const initialOrder = (pathname, location) => {
  let order =
    (localStorage !== null && JSON.parse(localStorage.getItem("order"))) || {};
  const isValidOption = config?.tabs?.includes(order?.diningOption);

  const params = pathname?.split("/").map((i) => i.toLowerCase());
  const page = params[1];
  const locationHandle = params[2];
  const diningOptionHandle = params[3];

  const isDirectKiosk =
    diningOptionHandle === KIOSK || diningOptionHandle === DINEIN;

  // Check if dining option is enabled in client configuration
  if (!isValidOption) {
    order = {};
  }

  // Only parse dining option when visting the menu page (/location), handle and location are set
  if (page === "location" && locationHandle && location) {
    // If the diningOption is delivery and the address is not valid, switch to pickup
    // This can happen if localStorage is cleared (refresh)
    if (order?.diningOption === DELIVERY) {
      const isAddressValid = AddressSchema.isValidSync(order?.address);
      if (!isAddressValid) {
        order.diningOption = PICKUP;
      }
    }

    // Always use pocket kiosk when using direct link
    if (isDirectKiosk) {
      order.diningOption = KIOSK;
    }

    // If there is still no dining option or it's kiosk without using handle
    // then use the default dining option as defined in the client configuration
    if (
      !order?.diningOption ||
      (order.diningOption === KIOSK && !isDirectKiosk)
    ) {
      order.diningOption = initDiningOption;
    }
  }

  return {
    ...INIT_ORDER(),
    ...order,
    scheduledAt: null,
  };
};
const initialItems = () => {
  const items =
    localStorage !== null && JSON.parse(localStorage.getItem("items"));
  return items || [];
};
const initialPackingItems = () => {
  const items =
    localStorage !== null && JSON.parse(localStorage.getItem("packingItems"));
  return items || [];
};

const initialRewardItems = () => {
  const items =
    localStorage !== null && JSON.parse(localStorage.getItem("rewardItems"));
  return items || [];
};

const DEFAULT_GIFT_CARD = {
  balance: 0,
  giftCardNumber: null,
};

export {
  DEFAULT_GIFT_CARD,
  INIT_ORDER,
  initialItems,
  initialLocation,
  initialOrder,
  initialPackingItems,
  initialRewardItems,
};

export default {
  DEFAULT_GIFT_CARD,
  INIT_ORDER,
  initialItems,
  initialLocation,
  initialOrder,
  initialPackingItems,
  initialRewardItems,
};
