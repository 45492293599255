import * as React from "react";
import * as Sentry from "@sentry/browser";
import _isEmpty from "lodash/isEmpty";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { useResource, usePollingCheckout, useCheckPriceQuery } from "hooks";
import { Copy, config, constants, Routes } from "utils";
import { CheckoutProvider } from "components/providers/CheckoutType2";
import { stringReplacer } from "utils/helpers/string";
import { roundFloat } from "utils/helpers/math";
import { transformRequestError } from "utils/helpers/other";
import { withTemplate } from "../../components/hocs";
import { mapOrderValidation, mapOrder } from "../Order/utils";
import { Loader } from "../../components/fragments/Loader";
import { View } from "../../components/elementsThemed/View";
import { ConditionalWrapper } from "../../components/elements/Condition";
import { errorActions, errorReducer } from "./utils";
import {
  mapGuestCheckoutFormValues,
  orderValidation,
  orderSubmission,
  initTip,
  orderSummaryHeader,
} from "./utils/helpers";
import {
  CartTab,
  CheckoutTab,
  CheckoutButton,
  InformationTab,
  PaymentTab,
} from "./components";

import css from "./checkoutType2.module.scss";

/**
 * @todo Refactor Checkout V2 - remove unused props, reduce nested props
 */

const {
  ORDER_TYPES: { PICKUP, DELIVERY, KIOSK },
} = constants;

const INIT_ERROR = {
  buttonText: "",
  message: "",
  type: null,
};

const isKnownReason = (reason) => {
  return [
    "pickup-closed",
    "delivery-closed",
    "pickup-unavailable",
    "delivery-unavailable",
    "invalid-scheduledAt",
    "items",
    "delivery-radius",
    "unavailable",
    "order-minimum",
  ].includes(reason);
};

const Checkout = ({
  style,
  history,
  patronContext,
  orderContext,
  cart,
  setHeader,
  onSuccess,
}) => {
  // Constants
  const { views, cells } = style;
  const { resetOrder } = orderContext;
  const { diningOption } = orderContext.order;
  const locationId = orderContext.location.id;
  const { isLoggedIn } = patronContext;
  const { idempotencyKey } = orderContext.order;

  const {
    table: { number: tableNumber },
  } = orderContext.order;
  // checkout page header
  const orderheader = stringReplacer(orderSummaryHeader(diningOption), [
    { replaceTarget: "{tableNumber}", replaceValue: tableNumber },
  ]);

  // set the header when the component mount
  React.useEffect(() => {
    if (setHeader) setHeader(orderheader);
  }, []);

  // Checkout Hooks
  const [tabs, setTabs] = React.useState({
    cart: {
      completed: true,
      opened: false,
    },
    info: {
      completed: isLoggedIn,
      opened: !isLoggedIn,
    },
    payment: {
      completed: false,
      opened: isLoggedIn,
    },
  });
  /**
   * @todo Rename to isOrdering
   */
  const [showCustomTipModal, setShowCustomTipModal] = React.useState(false);
  const [ordering, setOrdering] = React.useState(false);
  const [isMobilePay, setIsMobilePay] = React.useState(
    localStorage.getItem("isMobilePay") === "true",
  );
  const [availablePaymentTypes, setAvailablePaymentTypes] = React.useState({});
  const [buttonDisabled, setButtonDisabled] = React.useState(!isLoggedIn);
  const [errorMessage, setErrorMessage] = React.useState(true);
  const [error, dispatchError] = React.useReducer(errorReducer, INIT_ERROR);
  const { validationError, serverError, clearErrors } =
    errorActions(dispatchError);
  const buttonText =
    tabs.info.completed && tabs.payment.opened
      ? Copy.CART_STATIC.CHECKOUT_PLACE_ORDER_BUTTON_TEXT
      : "Proceed to Payment";
  const mobilePayEnabled =
    isMobilePay &&
    Object.keys(availablePaymentTypes).length &&
    tabs.payment.opened;
  // Payment Tab Hooks
  const [resource, setResource] = React.useState({
    amount: 0,
    appliedCredit: 0,
    appliedDiscounts: 0,
    customerCard: null,
    delivery: 0,
    preDiscountPrice: 0,
    taxAmount: 0,
    totalAmount: 0,
  });
  const [tip, setTip] = React.useState(
    !config[diningOption].tip
      ? 0
      : initTip(diningOption, resource.preDiscountPrice || 0),
  );
  const [isCash, setIsCash] = React.useState(false);
  const [invalidDiscount, setInvalidDiscount] = React.useState(false);
  const paymentFormRef = React.useRef();
  const checkPriceQuery = useCheckPriceQuery();

  let tipAmount = tip;
  /**
   * @todo Use config[diningOption].tip
   */
  if (
    (diningOption === PICKUP && !config.pickup.tip) ||
    (diningOption === DELIVERY && !config.delivery.tip) ||
    (diningOption === KIOSK && !config.kiosk.tip)
  ) {
    tipAmount = 0;
  }
  const finalAmount = (() => {
    const parsedNumber = parseFloat(tipAmount);
    const cleanNumber = Number.isNaN(parsedNumber) ? 0 : parsedNumber;
    return roundFloat(resource?.totalAmount + cleanNumber);
  })();

  // Your Info Tab Hooks
  const infoFormRef = React.useRef();
  const [formData, setFormData] = React.useState(() => {
    const patronInfo = {
      email: patronContext.patron?.email?.value ?? "",
      firstName: patronContext.patron.firstName,
      lastName: patronContext.patron.lastName,
      phone: patronContext.patron?.phone?.value ?? "",
    };
    return mapGuestCheckoutFormValues(
      config.theme.checkout.guest_checkout_fields,
      patronInfo,
    );
  });

  const validate = (res) => {
    const payload = orderValidation({
      diningOption,
      isCash,
      isLoggedIn,
      locationId: orderContext.location.id,
      mobilePayEnabled,
      res,
    });
    if (Object.keys(payload).length) {
      validationError(payload);
      setErrorMessage("");
      setButtonDisabled(true);
    } else {
      clearErrors();
      setErrorMessage("");
      setButtonDisabled(false);
    }
  };

  // Resources
  const [validateOrder] = useResource(
    {
      data: mapOrderValidation(orderContext),
      headers: {
        locationId,
      },
      method: "post",
      path: Routes.VALIDATE_ORDER,
    },
    [],
    ({ resource: res, error: err }) => {
      if (err?.data) {
        setButtonDisabled(true);
        setErrorMessage(
          (isKnownReason(err?.raw?.reason) && err?.raw?.message) ||
            Copy.CART_STATIC.CART_ERROR_MESSAGE,
        );
      } else if (
        (res?.discount && res?.discount?.status !== "valid") ||
        res?.isValid !== true ||
        res?.reason
      ) {
        setInvalidDiscount(true);
        setButtonDisabled(true);
        setErrorMessage(
          (isKnownReason(res?.reason) && res?.message) ||
            Copy.CART_STATIC.CART_ERROR_MESSAGE,
        );
      } else {
        setButtonDisabled(false);
        setErrorMessage("");
      }
    },
  );

  React.useEffect(() => {
    if (checkPriceQuery.isFetching) return;

    if (checkPriceQuery.error && checkPriceQuery.error.data) {
      validationError({
        buttonText: Copy.CART_STATIC.CHECKOUT_PLACE_ORDER_BUTTON_TEXT,
        message: Copy.CART_STATIC.CART_ERROR_MESSAGE,
      });
      setButtonDisabled(true);
    } else if (
      checkPriceQuery.data &&
      checkPriceQuery.data.isValid !== undefined &&
      !checkPriceQuery.data.isValid
    ) {
      validationError({
        buttonText: Copy.CART_STATIC.CHECKOUT_PLACE_ORDER_BUTTON_TEXT,
        message: validateOrder.resource.message,
      });
      setButtonDisabled(false);
      setResource(checkPriceQuery.data);
    } else {
      validate(checkPriceQuery.data);
      setResource(checkPriceQuery.data);
    }
  }, [checkPriceQuery.isFetching]);

  const onPaymentFailed = (err) => {
    if (err) {
      console.error(err);
    }
    setOrdering(false);
    validationError({
      buttonText: Copy.CART_STATIC.CHECKOUT_PLACE_ORDER_BUTTON_TEXT,
      message: Copy.CHECKOUT_STATIC.PAYMENT_FAILED_ERROR_TEXT,
    });
  };

  // Actions
  const toDiscount = () => history.push(Routes.FETCH_DISCOUNT);
  const clearDiscount = () => history.push(Routes.CLEAR_DISCOUNT);
  const openCart = () => {
    // Only move to the cart if it is closed.
    if (!tabs.cart.opened) {
      setTabs({
        cart: {
          completed: tabs.cart.completed,
          opened: !tabs.cart.opened,
        },
        info: {
          completed: tabs.info.completed,
          opened: false,
        },
        payment: {
          completed: tabs.payment.completed,
          opened: false,
        },
      });
    }
    clearErrors();
    setErrorMessage("");
    setButtonDisabled(false);
  };
  const openInfo = () => {
    if (!isLoggedIn) {
      setTabs({
        cart: {
          completed: true,
          opened: false,
        },
        info: {
          completed: false,
          opened: true,
        },
        payment: {
          completed: false,
          opened: false,
        },
      });
    } else {
      setTabs({
        cart: {
          completed: true,
          opened: false,
        },
        info: {
          completed: true,
          opened: false,
        },
        payment: {
          completed: false,
          opened: true,
        },
      });
    }
  };
  const openPayment = () => {
    if (tabs.info.completed) {
      setTabs({
        cart: {
          completed: true,
          opened: false,
        },
        info: {
          completed: tabs.info.completed,
          opened: false,
        },
        payment: {
          completed: false,
          opened: !tabs.payment.opened,
        },
      });
      setButtonDisabled(false);
    }
  };

  // end ordeflow v2

  // display error
  const orderError = (err) =>
    validationError({
      buttonText: Copy.CART_STATIC.CHECKOUT_PLACE_ORDER_BUTTON_TEXT,
      message: err.message,
    });
  // pollingcheckout hoooks
  const { pollingCheckout } = usePollingCheckout(
    idempotencyKey,
    resetOrder,
    onSuccess,
    setOrdering,
    serverError,
    orderError,
  );

  // end ordeflow v2
  const placeOrder = async ({
    cardNonce = null,
    transactionId = null,
  } = {}) => {
    setOrdering(true);
    try {
      const payload = {
        cart,
        idempotencyKey,
        isCash,
        locationId,
        order: mapOrder(orderContext),
        tip: tipAmount,
        ...(!isLoggedIn && { guestInfo: formData }),
        ...(cardNonce && { cardNonce }),
        ...(transactionId && { transactionId }),
        giftCardNumber: orderContext?.order?.giftCard?.giftCardNumber || "",
        giftCardPinCode: orderContext?.order?.giftCard?.pinCode || "",
      };
      const { data } = await orderSubmission(payload);

      // if polling is set to true use the pollingcheckout
      if (config?.ordering?.polling) {
        await pollingCheckout();
      } else {
        resetOrder();
        onSuccess(data);
      }
    } catch (err) {
      Sentry.captureException(err);
      const e = transformRequestError(err);

      if (e.message) {
        validationError({
          buttonText: Copy.CART_STATIC.CHECKOUT_PLACE_ORDER_BUTTON_TEXT,
          message: e.message,
        });
      } else {
        serverError();
      }
      setOrdering(false);
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (tabs.cart.opened) {
      openInfo();
    }
    if (tabs.info.opened) {
      if (infoFormRef?.current) {
        infoFormRef.current.handleSubmit();
      }
    }
    if (tabs.payment.opened) {
      if (paymentFormRef?.current) {
        paymentFormRef.current.dispatchEvent(new Event("submit"));
      } else {
        placeOrder({});
      }
    }
  };

  const [{ resource: locationCreds, fetching }] = useResource({
    data: {},
    method: "get",
    path: "/location/credentials",
  });

  const [stripePromise, setstripePromise] = React.useState(
    Promise.resolve(null),
  );

  React.useEffect(() => {
    if (locationCreds?.publicKey) {
      setstripePromise(loadStripe(locationCreds?.publicKey));
    }
  }, [locationCreds]);

  React.useEffect(() => {
    setTip(
      initTip(
        diningOption,
        Object.keys(resource).length && resource.preDiscountPrice
          ? resource.preDiscountPrice
          : 0,
      ),
    );
  }, [resource.preDiscountPrice]);

  React.useEffect(() => {
    setTip(
      initTip(
        diningOption,
        Object.keys(resource).length && resource.preDiscountPrice
          ? resource.preDiscountPrice
          : 0,
      ),
    );
  }, [resource.preDiscountPrice]);

  React.useEffect(() => {
    if (isCash) {
      setTip(0);
    }
  }, [isCash]);

  React.useEffect(() => {
    validate(resource);
  }, [isCash, resource, availablePaymentTypes, isMobilePay]);

  React.useEffect(() => {
    if (localStorage) localStorage.setItem("isMobilePay", isMobilePay);
  }, [isMobilePay]);

  const onFormChange = (values) => {
    const data = mapGuestCheckoutFormValues(
      config.theme.checkout.guest_checkout_fields,
      values,
    );
    setFormData(data);
    patronContext.updatePatron(data);
    setTabs((prevTabs) => ({
      ...prevTabs,
      info: {
        completed: true,
        opened: false,
      },
      payment: {
        completed: false,
        opened: true,
      },
    }));
  };

  if (validateOrder.fetching || checkPriceQuery.fetching || fetching) {
    return (
      <View className={css.checkout}>
        <div className={css.loader}>
          <Loader />
        </div>
      </View>
    );
  }

  return (
    <ConditionalWrapper
      condition={stripePromise}
      wrapper={(children) => (
        <Elements stripe={stripePromise}>{children}</Elements>
      )}
    >
      <CheckoutProvider
        ordering={ordering}
        setOrdering={setOrdering}
        tipAmount={tipAmount}
        finalAmount={finalAmount}
        placeOrder={placeOrder}
        onPaymentFailed={onPaymentFailed}
        availablePaymentTypes={availablePaymentTypes}
        setAvailablePaymentTypes={setAvailablePaymentTypes}
        usePaymentRequest={
          config?.payment_processor === "stripe" &&
          config?.ordering?.mobile_pay &&
          finalAmount
        }
      >
        <View type={views.background} className={css.checkout}>
          <AnimateSharedLayout>
            <motion.ul
              className={[css["checkout-tabs"], "checkout-tabs"].join(" ")}
              layout
            >
              <AnimatePresence>
                <CheckoutTab
                  key={1}
                  theme={style}
                  css={css}
                  title="Your Cart"
                  isOpen={tabs.cart.opened}
                  toggleOpen={openCart}
                  price={`$${(resource.amount - resource.delivery).toFixed(2)}`}
                >
                  <CartTab
                    theme={style}
                    history={history}
                    orderContext={orderContext}
                    subtotal={roundFloat(resource.amount - resource.delivery)}
                    resource={resource}
                    validateOrder={validateOrder}
                    toDiscount={toDiscount}
                    clearDiscount={clearDiscount}
                    invalidDiscount={invalidDiscount}
                    setTip={setTip}
                    tipAmount={tip}
                  />
                </CheckoutTab>
                {!isLoggedIn && !tabs.cart.opened && (
                  <CheckoutTab
                    key={2}
                    theme={style}
                    css={css}
                    isOpen={tabs.info.opened}
                    toggleOpen={openInfo}
                    title={isLoggedIn ? "Use Account Info" : "Your Info"}
                    completed={tabs.info.completed}
                  >
                    <InformationTab
                      theme={style}
                      formData={formData}
                      patronContext={patronContext}
                      innerRef={infoFormRef}
                      inputHandler={(errors) => {
                        if (buttonDisabled && _isEmpty(errors)) {
                          setButtonDisabled(false);
                        } else if (!buttonDisabled && !_isEmpty(errors)) {
                          setButtonDisabled(true);
                        }
                      }}
                      onSuccess={onFormChange}
                    />
                  </CheckoutTab>
                )}
                {!tabs.cart.opened && tabs.info.completed && (
                  <CheckoutTab
                    key={3}
                    history={history}
                    theme={style}
                    css={css}
                    title="Your Payment"
                    isOpen={tabs.payment.opened}
                    toggleOpen={openPayment}
                  >
                    <PaymentTab
                      availablePaymentTypes={availablePaymentTypes}
                      buttonDisabled={buttonDisabled}
                      clearDiscount={clearDiscount}
                      error={errorMessage || error?.message}
                      history={history}
                      invalidDiscount={invalidDiscount}
                      isCash={isCash}
                      isMobilePay={isMobilePay}
                      mobilePayEnabled={mobilePayEnabled}
                      orderContext={orderContext}
                      patronContext={patronContext}
                      paymentFormRef={paymentFormRef}
                      placeOrder={placeOrder}
                      resource={resource}
                      setButtonDisabled={setButtonDisabled}
                      setErrorMessage={setErrorMessage}
                      setIsCash={setIsCash}
                      setIsMobilePay={setIsMobilePay}
                      setTip={setTip}
                      stripePromise={stripePromise}
                      theme={style}
                      tip={tip}
                      setShowCustomTipModal={setShowCustomTipModal}
                      toDiscount={toDiscount}
                    />
                  </CheckoutTab>
                )}
              </AnimatePresence>
            </motion.ul>
          </AnimateSharedLayout>
          {showCustomTipModal ? null : (
            <CheckoutButton
              stripePromise={stripePromise}
              type={cells.bottom}
              onClick={onSubmit}
              buttonText={
                tabs.payment.opened && error.type === 1
                  ? error.buttonText
                  : buttonText
              }
              disabled={error.type === 1 || errorMessage || buttonDisabled}
              error={error}
              ordering={ordering}
              setOrdering={setOrdering}
              finalAmount={finalAmount}
              tipAmount={tipAmount}
              placeOrder={placeOrder}
              onPaymentFailed={onPaymentFailed}
              mobilePayEnabled={mobilePayEnabled}
              orderContext={orderContext}
            />
          )}
        </View>
      </CheckoutProvider>
    </ConditionalWrapper>
  );
};

export default withTemplate(Checkout, "checkout");
