import * as React from "react";
import { Elements } from "components";
import { View } from "components/elementsThemed";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { Image } from "components/fragments";
import { withTemplate } from "components/hocs";
import { config, images } from "utils";

import css from "./mobileAppDownload.module.scss";

type MobileAppStyle = {
  views: {
    base: string;
  };
};

type MobileAppDownloadProps = {
  style?: MobileAppStyle;
};

const {
  Condition: { Condition: If },
} = Elements;

const mdLgColLayout =
  config.apple_app_store_link && config.google_play_store_link
    ? { offset: 0, span: "1-2" }
    : { offset: 0, span: "1" };
/**
 * Renders mobile app download text, imgs with links
 *
 * @param style.style
 * @param {object} style
 */
function MobileAppDownload({ style }: MobileAppDownloadProps) {
  const { views } = style;

  return (
    <View Component={Row} type={views?.base} className={css.section}>
      <Col className={css["section-content"]}>
        <Row className={css["images-row"]}>
          <If is={config.apple_app_store_link}>
            <Col
              xs={{ offset: "1-4", span: "1-2" }}
              md={mdLgColLayout}
              lg={mdLgColLayout}
              className={css["section-content"]}
            >
              <a
                href={config.apple_app_store_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={images?.app_store_download}
                  className={css["app-store-img"]}
                  mediaName="app_store_download"
                  mediaType="svg"
                  alt="Apple App Store Logo"
                />
              </a>
            </Col>
          </If>
          <If is={config.google_play_store_link}>
            <Col
              xs={{ offset: "1-4", span: "1-2" }}
              md={mdLgColLayout}
              lg={mdLgColLayout}
              className={css["section-content"]}
            >
              <a
                href={config.google_play_store_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={images?.google_play_store_download}
                  className={css["play-store-img"]}
                  mediaName="google_play_store_download"
                  mediaType="svg"
                  alt="Google Play Store Logo"
                />
              </a>
            </Col>
          </If>
        </Row>
      </Col>
    </View>
  );
}

MobileAppDownload.defaultProps = {
  style: {
    views: {
      base: "",
    },
  },
};

export default withTemplate(
  MobileAppDownload,
  config.loyalty.enabled ? "loyalty" : "giftCard",
);
