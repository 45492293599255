import * as React from "react";
import { MemoryRouter, Route, Switch } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import { parseISO, format } from "date-fns";
import { OrderItem as orderItemComponent } from "@lunchboxinc/lunchbox-components-v2/dist/templateComponents";

import BackButton from "../../../components/BackButton/BackButton";
import { Fragments } from "components";
import { useResource } from "hooks";
import { Routes, Copy, images, constants, config } from "utils";
import { Loader, Empty } from "components/fragments";
import { Text } from "components/elementsThemed";
import { FETCH_LOCATIONS } from "utils/api";
import withHistoryCard from "./withHistoryCard";
import ReceiptDetail from "./receiptDetail";
import { useMenuContext } from "components/providers/Menu/MenuContext";

import styles from "./orderHistory.module.scss";

const isMarketPlace = config?.market_place?.enabled;
const OrderItem = withHistoryCard(orderItemComponent);

const {
  Routes: { RouteWithProps },
} = Fragments;

const {
  ORDER_TYPES: { PICKUP, DELIVERY },
} = constants;

const formatToNestMods = (mods) => {
  return mods.map((mod) => {
    return {
      modifiers: [
        {
          item: mod.item,
          modifiers: formatToNestMods(mod?.modifications),
          option: mod.option,
        },
      ],
      option: mod.option,
    };
  });
};

const formatOrderHistoryByDate = (orders) => {
  const orderMap = new Map();
  orders.forEach((order) => {
    const date = format(
      parseISO(order.createdAt),
      config.order_history.date_format || "MM/dd/yyyy",
    );
    if (orderMap.has(date)) {
      orderMap.set(date, [...orderMap.get(date), order]);
    } else {
      orderMap.set(date, [order]);
    }
  });
  return orderMap;
};

const formatItems = (items) =>
  items.map((item) => ({
    ...item,
    mods: formatToNestMods(item.modifications),
  }));

const findLocationByLocationId = (locations, targetId) =>
  locations.filter((location) => targetId === location.id);

const generateHistoryCards = ({ ordersMap, cells, labels, reOrder, history }) =>
  [...ordersMap].map(([date, orders]) => {
    return (
      <>
        <Text
          type={labels.section}
          className={styles["orderHistory-content-date"]}
        >
          {date}
        </Text>
        {orders.map((order) => (
          <LazyLoad key={order.id} offset={250} height="100%" width="100%" once>
            <OrderItem
              key={order.id}
              order={order}
              cellType={cells.historyCard}
              viewReceipt={(orderId) => {
                history.push(Routes.FETCH_ORDER_HISTORY_DETAIL(orderId));
              }}
              reOrder={() => {
                reOrder({
                  deliveryAddress: order.deliveryInfo,
                  items: order.items,
                  orderType: order.orderType,
                  pickUpLocation: order.location,
                });
              }}
            />
          </LazyLoad>
        ))}
      </>
    );
  });

const OrderHistory = ({ backToLocationPage, style, orderContext = {} }) => {
  const [locations, setLocations] = React.useState([]);
  const { cells, labels } = style;
  const { addManyItems, changeLocation, setDeliveryAddress } = orderContext;
  const { packingMenu } = useMenuContext();
  const [{ fetching, resource: orders }] = useResource({
    method: "get",
    path: Routes.FETCH_ORDER_HISTORY,
  });

  React.useEffect(() => {
    FETCH_LOCATIONS()
      .then(({ data }) => {
        setLocations(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const reOrder = ({ deliveryAddress, items, orderType, pickUpLocation }) => {
    const location = findLocationByLocationId(locations, pickUpLocation.id)[0];

    const locationSlug = isMarketPlace
      ? location.locations[0].slug
      : location.slug;

    const resGroupId = location.locations?.length
      ? location.locations[0].restaurantGroup
      : location.id;

    //get all packing items ids
    const packingItemIds = (packingMenu?.items?.array || []).map(
      (item) => item.id,
    );
    // only add items to cart that are not packing items
    addManyItems(
      formatItems(items.filter((item) => !packingItemIds.includes(item.item))),
    );
    if (orderType === PICKUP && !isMarketPlace) {
      changeLocation(pickUpLocation);
    } else if (orderType === DELIVERY) {
      setDeliveryAddress(deliveryAddress);
    }
    backToLocationPage({
      orderType,
      slug: locationSlug,
      resGroupId,
    });
  };

  const onClickBack = (memory) => () =>
    memory.location.pathname !== "/" && memory.history.goBack();

  return (
    <MemoryRouter initialEntries={["/"]} initialIndex={0}>
      <Route
        render={({ history, location }) => (
          <div data-pl="order-history" className={styles.orderHistory}>
            {location.pathname !== "/" && (
              <div className={styles["orderHistory-navigation"]}>
                <BackButton
                  imgSrc={images?.button_back_profile}
                  onClick={onClickBack({ history, location })}
                />
              </div>
            )}
            <Switch>
              <RouteWithProps
                exact
                path={Routes.ROOT}
                render={() =>
                  fetching ? (
                    <Loader />
                  ) : (
                    <div className={styles["orderHistory-content"]}>
                      {orders.length ? (
                        generateHistoryCards({
                          cells,
                          history,
                          labels,
                          ordersMap: formatOrderHistoryByDate(orders),
                          reOrder,
                        })
                      ) : (
                        <Empty imgSrc={images?.art_empty}>
                          <Text type={labels.error}>
                            {Copy.PROFILE_STATIC.EMPTY_MESSAGE}
                          </Text>
                        </Empty>
                      )}
                    </div>
                  )
                }
              />
              <RouteWithProps
                path={Routes.ORDER_HISTORY_DETAIL}
                render={({ match }) => (
                  <ReceiptDetail
                    type={cells}
                    orderId={match.params.id}
                    reorder={({
                      deliveryAddress,
                      items,
                      orderType,
                      pickUpLocation,
                    }) =>
                      reOrder({
                        deliveryAddress,
                        items,
                        orderType,
                        pickUpLocation,
                      })
                    }
                  />
                )}
              />
            </Switch>
          </div>
        )}
      />
    </MemoryRouter>
  );
};

export default OrderHistory;
