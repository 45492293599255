import * as React from "react";
import { useTemplateContext as useTemplate } from "./providers/Template";
import { View, Button } from "./elementsThemed";

export interface DrawerButtonProps {
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  children?: React.ReactNode;
  containerProps?: React.HTMLAttributes<HTMLElement>;
  label?: string;
}

/**
 * If you don't like the way this looks then you likely shouldn't be using it.
 */
export default function DrawerButton({
  buttonProps,
  children,
  containerProps,
  label,
}: DrawerButtonProps) {
  const {
    theme: {
      elements: { cells },
    },
  } = useTemplate();

  return (
    <View
      {...containerProps} // Do not move
      data-testid="footerbtn"
      type={cells.footerButton.views.background}
      style={{
        display: "flex",
        padding: "1rem",
        width: "100%",
        bottom: 0,
        boxShadow: "0px -4px 4px rgba(95, 95, 95, 0.1)",
        background: "var(--drawer-background)",
      }}
    >
      <Button
        {...buttonProps} // Do not move
        type={cells.footerButton.buttons.primary}
        style={{
          padding: "1rem",
          transition: "background 0.3s ease-in-out, border 0.3s ease-in-out",
        }}
        block
      >
        {label || children}
      </Button>
    </View>
  );
}

DrawerButton.defaultProps = {
  buttonProps: {},
  children: null,
  containerProps: {},
  label: "",
};
