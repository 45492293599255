import * as React from "react";
import { format, addMinutes } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { analytics, useTrackingInfo } from "hooks";
import { config, constants, Copy } from "utils";
import Type1 from "./Type1";
import Type2 from "./Type2";
import Type3 from "./Type3";
import Type4 from "./Type4";
import Type5 from "./Type5";
import Type6 from "./Type6";
import { CompleteType7 } from "./Type7";

const {
  ORDER_TYPES: { KIOSK },
} = constants;

const timeOptions = { dateStyle: "short", timeStyle: "short" };

const Types = {
  Type1,
  Type2,
  Type3,
  Type4,
  Type5,
  Type6,
  Type7: CompleteType7,
};

const mapOrderTimesToProps = (orderInformation) => {
  const {
    orderType,
    serviceEstimate,
    orderDate,
    scheduledAt,
    timeZone,
    estimatedDropoffTime = null,
  } = orderInformation;

  const orderDateAsJSDate = new Date(orderDate);
  const orderTime = format(orderDateAsJSDate, "EEE, M/d/yyyy");
  const [date, time] = orderDateAsJSDate
    .toLocaleString("en-US", timeOptions)
    .split(",");
  const eta =
    orderInformation.estimatedPrepTime ??
    (orderInformation.prepEstimate
      ? `${orderInformation.prepEstimate} min`
      : null);

  const scheduleAtTimeZone = utcToZonedTime(new Date(scheduledAt), timeZone);
  const formattedScheduleAtTimeZone =
    format(scheduleAtTimeZone, "EEE, M/d/yyyy h:mm aaaa") ?? null;

  const formatTimeRangeAsString = (start, range) =>
    `${format(start, "h:mm aaaa")} - ${format(
      addMinutes(start, range),
      "h:mm aaaa",
    )}`;
  const estScheduledTimeRange = scheduledAt
    ? {
        date: format(scheduleAtTimeZone, "EEE, M/d/yyyy"),
        estTimeRangeAsString: formatTimeRangeAsString(scheduleAtTimeZone, 15),
      }
    : null;

  let appropriateTime = new Date(scheduledAt ?? orderDate);
  let appropriateServiceEstimate = serviceEstimate ?? 0;

  /**
   * If the order has estimatedDropoffTime from useTrackingInfo hook update the deliveryTime
   * to be the time sent from /orders/:id/tracking as it will have the most up to
   * date expected delivery time.
   */
  if (estimatedDropoffTime) {
    appropriateTime = new Date(estimatedDropoffTime);
    appropriateServiceEstimate = 0;
  }
  const deliveryTime = format(
    addMinutes(appropriateTime, appropriateServiceEstimate),
    "h:mm a",
  );

  // serviceEstimate from Backend's response now accounts the extra time for both pickup & delivery
  const estTime = addMinutes(appropriateTime, serviceEstimate ?? 0);
  const estTimeRangeAsString =
    orderType === KIOSK ? "" : formatTimeRangeAsString(estTime, 15);

  return {
    date,
    deliveryTime,
    estScheduledTimeRange,
    estTimeRangeAsString,
    eta,
    orderTime,
    orderType,
    scheduleTime: formattedScheduleAtTimeZone,
    time,
  };
};

const Selector = ({ setHeader, order, ticketInformation, ...props }) => {
  const Component = Types[config.theme.checkout.confirmation] || Type1;
  const {
    id,
    finalAmount,
    taxAmount,
    items,
    orderType,
    code,
    appliedCredit,
    patron,
  } = ticketInformation;

  React.useEffect(() => {
    analytics.checkout.completed({
      appliedCredit,
      code,
      email: patron?.email,
      finalAmount,
      id,
      items,
      orderType,
      phoneNumber: patron?.phone,
      taxAmount,
    });
  }, []);

  const [trackingData] = useTrackingInfo({
    id: ticketInformation?.id,
    init: {
      restaurant: {
        latitude: order?.location?.address?.geo?.lat,
        longitude: order?.location?.address?.geo?.long,
      },
    },
    orderType,
  });

  React.useEffect(() => {
    if (setHeader) setHeader(Copy?.CART_STATIC?.ORDER_HEADER_TEXT);
  }, []);

  const timeZone = order?.location?.timeZone;

  return (
    <>
      <div className="purchase-confirmation" />
      <Component
        {...props}
        trackingData={trackingData}
        ticketInformation={ticketInformation}
        {...mapOrderTimesToProps({
          ...ticketInformation,
          estimatedDropoffTime: trackingData?.estimatedDropoffTime,
          timeZone,
        })}
      />
    </>
  );
};

Selector.displayName = "CompleteSelector";
export default Selector;
