/*
 * Cart and Checkout Header
 * Author: Isaiah Sanchez <isanchez@lunchbox.io>
 *
 * This component is rendered by pages/Cart and pages/Checkout.
 * This component renders a back button with appropriate text.
 */

import * as React from "react";
import { VisuallyHidden } from "@react-aria/visually-hidden";

import { useCell } from "hooks";
import { images, constants, Copy, Routes } from "utils";
import { formatAddress } from "utils/helpers/geo";
import { View, Text } from "components/elementsThemed";
import { withTemplate } from "components/hocs";

import BackButton from "../../../components/BackButton/BackButton";

import styles from "../style.module.scss";

const {
  ORDER_TYPES: { PICKUP, DELIVERY, KIOSK },
} = constants;

const getOrderTypeText = (orderType) => {
  switch (orderType) {
    case DELIVERY:
      return Copy.CART_STATIC.DELIVERY_FROM_TEXT;
    case PICKUP:
      return Copy.CART_STATIC.PICKUP_FROM_TEXT;
    case KIOSK:
      return Copy.CART_STATIC.KIOSK_FROM_TEXT;
    default:
      return Copy.CART_STATIC.ORDERING_FROM_TEXT;
  }
};

const OrderHeader = React.memo(
  ({ style, header, order, location, history, close, ...props }) => {
    // Constants
    const { cells, labels, views } = style;
    const locationName = order.location.name;
    const { diningOption } = order.order;
    const orderAddress = order.order.address;
    const isDelivery = diningOption === DELIVERY;

    // Variables
    let labelText = getOrderTypeText(diningOption);
    labelText = `${labelText} ${locationName}`;

    // Hooks
    const cell = useCell(cells.header);

    // Actions
    const onBack = (memory) => () => {
      if (
        [Routes.ROOT, Routes.PURCHASE_COMPLETE].includes(
          memory.location.pathname,
        )
      ) {
        close();
      } else {
        memory.history.goBack();
      }
    };

    return (
      <View type={views.header} className={styles["order-header"]} {...props}>
        <BackButton
          alt="go back"
          imgSrc={images?.button_back_checkout}
          onClick={onBack({ history, location })}
        />
        {[Routes.PURCHASE_COMPLETE, Routes.CHECK_OUT, Routes.PACKING].includes(
          location.pathname,
        ) ? (
          <Text role="heading" aria-level="2" type={labels.primary}>
            {header}
          </Text>
        ) : (
          <View type={cell.views.background}>
            <div className={styles.label}>
              <VisuallyHidden>
                <h2>Cart</h2>
              </VisuallyHidden>
              <Text type={cell.labelTextStyles.primary}>{labelText}</Text>
            </div>
            {isDelivery && (
              <div className={styles.value}>
                <Text
                  type={cell.labelTextStyles.secondary}
                >{`to ${formatAddress(orderAddress)}`}</Text>
              </div>
            )}
          </View>
        )}
      </View>
    );
  },
);

export default withTemplate(OrderHeader, "cart");
