/*
 * This HOC is shared logic between itemDetail Layouts and Groups.
 * Passes necessary props for price calculation, scrolling, images,
 * add to cart and modifiers.
 */
// TODO: Move this one directory up when moving /Group to ../Group/Layout
import * as React from "react";
import { useMenuContext } from "../../../../components/providers/Menu/MenuContext";
import { analytics, useScrollPosition } from "../../../../hooks";
import { stringReplacer, formatPrice } from "../../../../utils/helpers/string";
import { config, Copy, images as configImages } from "../../../../utils";
import { getItemsFormattedPrice } from "../../../../utils/menu";
import {
  getTotalPrice,
  mapItemTabs,
  mapModifications,
  nestedModifierState,
} from "../utils";
import { modsReducer, ModsActionType } from "../modsReducer";
import { validateOptions, qtyPropSum } from "./utils";

const artMiscImage = configImages?.art_misc_1;

export const parseModsArray = (modsArray = []) => {
  // Isolate uniquie modifier identifiers (ids) from given array
  const uniqueMods = modsArray.filter(
    (value, index, self) => self.indexOf(value) === index,
  );

  // Map unique modifier identifiers (ids) array
  return uniqueMods.map((mod) => ({
    item: mod,
    mods: {},
    // Determine quantity through filter of matching identifiers (ids)
    quantity: modsArray.filter((i) => i === mod).length,
  }));
};

const withItemDetails =
  (Component) =>
  ({
    id,
    item,
    mods = {},
    group,
    isGroup: isSubgroup,
    resGroupId,
    isUpsell = false,
    isModifyingItem,
    isRewardItem,
    notes: initNotes,
    ...props
  }) => {
    // Hooks
    const [selectedItem, setSelectedItem] = React.useState(() => {
      // if the "details" being viewed is a subgroup then the selectedItem is null (no selected item)
      // if the "details" is a plain item then we preselect the selectedItem with the item is being viewed
      let returnId = isSubgroup ? null : item ?? id;
      if (isModifyingItem || isRewardItem) returnId = item;
      return returnId;
    });
    const [errors, setErrors] = React.useState([]);
    const [globalError, setGlobalError] = React.useState(null);
    const optionRefs = React.useRef({});
    const scrollingRef = React.useRef();
    const itemImageRef = React.useRef();
    const [nestedDrawer, setNestedDrawer] = React.useState({});

    const closeDrawer = (e) => {
      e.preventDefault();
      setNestedDrawer({});
    };

    // Providers
    const {
      groupsHash,
      optionsHash,
      itemsHash,
      upsellsItemsHash,
      upsellsOptionsHash,
    } = useMenuContext();

    const getOptionHash = () => (isUpsell ? upsellsOptionsHash : optionsHash);
    // Check for Group
    const isGroup = !isUpsell ? isSubgroup : false;

    const itemInfoMap = {
      group: {
        hash: groupsHash,
        info: groupsHash[group ?? id],
        tab: () => [],
      },
      item: {
        hash: itemsHash,
        info: itemsHash[selectedItem] ?? {},
        tab: (options) =>
          selectedItem
            ? mapItemTabs(options, getOptionHash(), config.hardcodes)
            : [],
      },
      upsell: {
        hash: upsellsItemsHash,
        info: upsellsItemsHash[selectedItem] ?? {},
        tab: (options) =>
          selectedItem
            ? mapItemTabs(options, getOptionHash(), config.hardcodes)
            : [],
        type: "upsell",
      },
    };

    const groupInfo = itemInfoMap.group;
    const itemType = itemInfoMap[isUpsell ? "upsell" : "item"];
    const itemInfo = itemType.info;

    // Object
    const { images, name, options, restaurantGroup } = itemInfo;
    const tabs = itemType.tab(options);

    // Image
    const placeholderImage = images?.[0] ?? configImages.art_item_placeholder;

    // Modifiers
    const updateModifiers = (selectedItemId, init = {}) => {
      const Hash = isUpsell ? upsellsItemsHash : itemsHash;
      return Hash[selectedItemId]?.options?.length
        ? Hash[selectedItemId].options.reduce(
            (acc, opt) => ({
              ...acc,
              [opt.id]: parseModsArray(opt.defaultModifiers),
            }),
            init,
          )
        : init;
    };

    let modsTemp =
      !isModifyingItem && !isGroup ? updateModifiers(selectedItem, mods) : mods;

    const [modifications, dispatch] = React.useReducer(modsReducer, modsTemp);

    // Scrolling
    const handleScroll = (optionId) => {
      const refToScrollTo = optionRefs?.current[optionId];
      if (refToScrollTo) {
        const tabs = document.getElementById("itemDetails-tabs-nav-container");
        document.getElementById("itemDetailsContainer").scrollTo({
          behavior: "smooth",
          top:
            refToScrollTo?.node?.offsetTop - tabs.offsetHeight ||
            0 - tabs.offsetHeight ||
            0,
        });
      }
    };

    const addModifier = (payload, toggleDrawer = false) => {
      let isSameItem = false;
      const { option = "" } = payload;

      if (toggleDrawer) {
        return setNestedDrawer(payload);
      }

      setNestedDrawer({});

      const { max = 1, name: optionName } = getOptionHash()?.[option];
      const currentSelectedModifierItemQuantity = qtyPropSum(
        modifications?.[option],
      );

      // Remove first modification for this option user if selecting more than the max allowed
      if (max && currentSelectedModifierItemQuantity >= max) {
        // If the RG is configured to so that the last mod item is not replace return immediately
        if (config?.not_replace_mod) {
          setGlobalError(
            stringReplacer(Copy.ITEM_DETAILS_STATIC.MAX_ITEM_SELECTED, [
              { replaceTarget: "{option}", replaceValue: optionName },
            ]),
          );
          return {};
        }

        if (modifications?.[option]?.[0]?.item === payload.item) {
          isSameItem = true;
        } else {
          dispatch({
            payload: {
              ...payload,
              ...modifications?.[option]?.[0],
            },
            type: ModsActionType.REMOVE_MOD,
          });
        }
      }

      setGlobalError(null);
      if (!isSameItem) {
        dispatch({
          payload,
          type: ModsActionType.ADD_MOD,
        });
      }

      // jump to next section here
      const currentMax = modifications[option]
        ? modifications[option].length
        : 0;
      const newMax = currentMax + 1;
      if (optionRefs.current[option] && newMax === max) {
        const addedOptionsNode = optionRefs.current[option];
        const { tabIndex } = addedOptionsNode;

        const currentOptionIndex = Object.keys(optionRefs.current).findIndex(
          (key) => key === option,
        );
        let nextOption = Object.keys(optionRefs.current)[
          currentOptionIndex + 1
        ];
        if (nextOption) {
          handleScroll(nextOption);
        } else if (
          tabs &&
          tabs.length &&
          tabIndex !== tabs.length - 1 &&
          !!tabIndex
        ) {
          nextOption = tabs[tabIndex + 1]?.[0];
          if (nextOption) {
            handleScroll(nextOption.id);
          }
        }
      }
      return {};
    };

    const removeModifier = (payload) => {
      setGlobalError(null);
      dispatch({
        payload,
        type: ModsActionType.REMOVE_MOD,
      });
    };

    /**
     * validateMods - cross check the itemHash options with the modifier state to validate quantities (min and max).
     *
     * @param {string} optionId - if the validation is nested, the option id to check
     * @param {string} modId - if the validation is nested, the item id to check
     */
    const validateMods = ({ optionId, modId } = {}) => {
      if (!selectedItem) {
        // No selected item in the itemDetails state, an item is always required to validate modifiers
        const selectOneError = [
          { message: "Select 1", optionId: null, type: "item" },
        ];
        // Update the error state with any new errors
        setErrors(selectOneError);
        // Also return the errors in case it's needed
        return selectOneError;
      }

      const optionsHashByType = getOptionHash();

      // Validate modifier state against options from nested item id within itemsHash
      let reductionObj = itemsHash?.[modId]?.options ?? [];

      // If it's not nested with an option and item id, use sorted tab options or selected itemInfo options
      if (!optionId && !modId) {
        const sortedTabs =
          tabs &&
          (tabs.length === 1
            ? tabs[0].entities
            : tabs.reduce((accu, i) => [...accu, ...i.entities], []));
        const sortedOptions = itemInfo?.options ?? [];
        reductionObj = sortedTabs ?? sortedOptions;
      }

      // Get modifiers to validate from modifier state, recursively search for nesting
      const nestedModsState = nestedModifierState({
        modId,
        modsState: modifications,
        optionId,
      });

      const invalidOptions = validateOptions({
        id: optionId,
        options: reductionObj,
        optionsHash: optionsHashByType,
        state: nestedModsState,
      });

      const errors = invalidOptions.map(
        ({ id, isExcessive, isInsufficient, max, min }) => ({
          message: stringReplacer(
            isExcessive
              ? Copy.ITEM_DETAILS_STATIC.SELECTION_MAX_ERROR
              : isInsufficient
              ? Copy.ITEM_DETAILS_STATIC.SELECTION_MIN_ERROR
              : null,
            [
              {
                replaceTarget: "{option}",
                replaceValue: isExcessive ? max : isInsufficient ? min : null,
              },
            ],
          ),
          optionId: id,
          type: "option",
        }),
      );

      // Update the error state with any new errors
      setErrors(errors);

      // Also return the errors in case it's needed
      return errors;
    };

    const reset = () => {
      dispatch({
        type: ModsActionType.CLEAR_MOD,
      });
    };

    // Notes
    const [notes, setNotes] = React.useState(initNotes);
    const onChangeNotes = (e) => setNotes(e.target.value);

    // Quantity
    const [quantity, setQuantity] = React.useState(1);
    const incQuantity = () => setQuantity(quantity + 1);
    const decQuantity = () =>
      setQuantity(quantity > 1 ? quantity - 1 : quantity);

    // Selected Items
    const onSelect = (selectedItemId) => {
      reset();
      setGlobalError(null);
      setSelectedItem(selectedItemId);
      optionRefs.current = {};
      dispatch({
        payload: updateModifiers(selectedItemId, {}),
        type: ModsActionType.SET_MOD,
      });
    };

    const totalPrice = getTotalPrice({
      itemInfo: itemInfo,
      itemHash: itemType.hash,
      selectedItem: selectedItem,
      modifications: modifications,
      quantity: quantity,
    });

    const addToCart = () => {
      if (!Object.keys(errors).length) {
        let item = {
          image: placeholderImage,
          isUpsell,
          item: selectedItem,
          mods: mapModifications(modifications),
          name,
          notes,
          price: totalPrice.unformatted,
          resGroupId: restaurantGroup,
          state: modifications,
          totalPrice: totalPrice.formatted,
          origin: "hello",
        };
        // If it's a reward item, we need to manually add groupId and redeemAmount
        if (isRewardItem) {
          item = {
            ...item,
            redeemAmount: props.rewardItem.redeemAmount,
            group: props.rewardItem.group,
          };
        }

        props.onConfirm(item, quantity);
        reset();
        props.close();
      }
    };

    React.useEffect(() => {
      setErrors([]);
    }, [selectedItem, modifications]);

    React.useEffect(() => {
      optionRefs.current = {};
    }, [selectedItem]);

    const buttonText = () => {
      if (errors.length) return Copy.ITEM_DETAILS_STATIC.SELECTION_ERROR;
      if (!totalPrice.formatted) return <span>{props.buttonText}</span>;
      return (
        <span>
          <div className="footerButtonContent">
            {props.buttonText && (
              <>
                <span className="footerButtonText">{props.buttonText}</span>
                <span>&nbsp;</span>
              </>
            )}
            <span className="footerButtonPrice">
              {isRewardItem
                ? `. ${props.rewardItem.redeemAmount} ${Copy.MENU_STATIC.LOYALTY_UNIT}`
                : totalPrice.formatted}
            </span>
          </div>
        </span>
      );
    };

    // Sticky Option Tabs Menu
    if (config.theme.item_details.fixable) {
      const handleFix = (prevPos, currPos) => {
        const el = scrollingRef.current;
        if (!el) return;
        const imageHeight = itemImageRef.current.clientHeight;
        const isFixed = el.dataset.fixed === "true" ? 1 : 0;

        if (currPos.y + imageHeight < 0 && prevPos.y > currPos.y && !isFixed) {
          el.dataset.fixed = false;
        } else if (
          currPos.y + imageHeight > 0 &&
          prevPos.y < currPos.y &&
          isFixed
        ) {
          el.dataset.fixed = false;
        }
      };

      useScrollPosition(
        ({ prevPos, currPos }) => {
          handleFix(prevPos, currPos);
        },
        [],
        itemImageRef,
        false,
        "#itemDetailsContainer",
        100,
      );
    }

    const getPrice = () => {
      let formattedPrice;
      if (itemInfo?.price) formattedPrice = formatPrice(itemInfo.price);
      else if (groupInfo?.info?.items) {
        formattedPrice = getItemsFormattedPrice(groupInfo.info.items);
      }
      return formattedPrice;
    };

    const onSubmit = () => {
      const errorList = validateMods();
      if (!errorList.length) {
        addToCart();
        const eventPayload = {
          category: id,
          item: {
            item: id,
            name,
            price: totalPrice.unformatted,
          },
          quantity,
        };
        analytics.product.added(eventPayload);
        props.close();
      }
    };

    const returnProps = {
      addModifier,
      artMiscImage,
      buttonText,
      closeDrawer,
      decQuantity,
      errors,
      globalError,
      group: groupInfo.info,
      handleScroll,
      hasQuantityLabel: config.theme.item_details?.hasQuantityLabel,
      incQuantity,
      isRewardItem,
      item: itemInfo,
      itemImageRef,
      itemType,
      modifications,
      mods,
      nestedDrawer,
      notes,
      onChangeNotes,
      onSelect,
      onSubmit,
      optionRefs,
      optionsHash: getOptionHash(),
      placeholderImage,
      price: getPrice(),
      quantity,
      removeModifier,
      reset,
      scrollingRef,
      selectedItem,
      setGlobalError,
      tabs,
    };

    return <Component {...props} {...returnProps} />;
  };

withItemDetails.defaultProps = {
  mods: {},
  showClose: false,
};

export default withItemDetails;
