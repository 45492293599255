import { createGlobalStyle } from "styled-components";
import type { Font } from "./@types/theme";

const getFontNames = (fonts: Record<string, Font>) =>
  Object.keys(fonts).reduce<string[]>((accu, font) => {
    if (accu.includes(fonts[font]?.name)) {
      return accu;
    }

    return [fonts[font]?.name, ...accu];
  }, []);

const GlobalStyles = createGlobalStyle`
:root {
  ${(props) =>
    Object.keys(props?.theme?.colors).map(
      (name) => `--color-${name}: ${props?.theme?.colors[name]};`,
    )}
  ${(props) =>
    getFontNames(props?.theme?.fonts).map(
      (name, i) => `--font-family-${i}: "${name}";`,
    )}
}

a:hover {
  cursor: pointer;
}
`;

export { GlobalStyles };
