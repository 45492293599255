import * as React from "react";
import { Condition as If } from "components/elements/Condition";
import { View, Text } from "components/elementsThemed";
import { useCell } from "hooks";
import css from "./packingGroupType1.module.scss";
import PackingItem from "../../Item";

const PackingGroupType1 = ({
  errors,
  type,
  itemType,
  onAddPackingItem,
  onRemovePackingItem,
  isSingleSelectable,
  group,
  selectedItems,
}) => {
  const { views, labelTextStyles } = useCell(type);

  return (
    <View type={views.background} className={css.packingGroup}>
      <View type={views.background} className={css["packingGroup-title"]}>
        <Text type={labelTextStyles.primary}>{group.name}</Text>
        <If is={errors.length}>
          {errors.map((i, index) => (
            <div>
              {/* eslint-disable-next-line react/no-array-index-key */}
              <Text key={index} type={labelTextStyles.secondary}>
                {i.message}
              </Text>
            </div>
          ))}
        </If>
      </View>
      <ul className={css["packingGroup-items"]}>
        {group.items.map((i) => (
          <PackingItem
            selectedItems={selectedItems}
            key={i.id}
            id={i.id}
            isSingleSelectable={isSingleSelectable}
            type={itemType}
            onAddItem={onAddPackingItem}
            onRemoveItem={onRemovePackingItem}
          />
        ))}
      </ul>
    </View>
  );
};

export default PackingGroupType1;
