import * as React from "react";
import { config } from "utils";
import { useMenuContext } from "components/providers/Menu/MenuContext";
import ItemDetailsLayout from "./Layout";

const ItemDetailsSelector = (props) => {
  const { groups } = useMenuContext();
  const { group, isUpsell, id } = props;

  const checkGroup =
    groups.findIndex(
      (i) => i.subgroups.filter((x) => x.id === group).length,
    ) !== -1 && !config.flatten_subgroups;
  const isGroup = !isUpsell ? checkGroup : false;
  return (
    <ItemDetailsLayout id={isGroup ? group : id} isGroup={isGroup} {...props} />
  );
};

export default ItemDetailsSelector;
