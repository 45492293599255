import * as React from "react";

/**
 * Fallback component if none of the Beam widgets works
 *
 * @memberof Fragments.Fragments/Beam
 * @author Htin Linn Aung
 * @returns {React.Element} - Fallback component if none of the Beam widgets works
 */
const Fallback = () => <div id="beam-widget-container" />;

export default Fallback;
