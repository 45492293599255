export const DELIVERY_INFORMATION_MESSAGE = "";
export const LOCATION_PAGE_HEADER_TEXT = "";
export const LOCATION_TEXT = "";
export const PICKUP_INFORMATION_MESSAGE = "";
export const RESTAURANT_DESCRIPTION = "";
export const RESTAURANT_NAME = "";
export const SERVICE_FEES_MESSAGE = "Service Fees";
export const SERVICE_FEES_MESSAGE_DELIVERY = "Delivery Fees";
export const TIP_INFORMATION_MESSAGE = "";
export const WEBSITE_NAME = "";

export default {
  DELIVERY_INFORMATION_MESSAGE,
  LOCATION_PAGE_HEADER_TEXT,
  LOCATION_TEXT,
  PICKUP_INFORMATION_MESSAGE,
  RESTAURANT_DESCRIPTION,
  RESTAURANT_NAME,
  SERVICE_FEES_MESSAGE,
  SERVICE_FEES_MESSAGE_DELIVERY,
  TIP_INFORMATION_MESSAGE,
  WEBSITE_NAME,
};
