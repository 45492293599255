const getAddress = (currentAddress, patronAddresses) => {
  let address = currentAddress;
  if (patronAddresses.length) {
    const lastAddress = patronAddresses[patronAddresses.length - 1];
    if (lastAddress?.geo && lastAddress?.geo?.lat && lastAddress?.geo?.long) {
      address = lastAddress;
      address.lat = lastAddress.geo.lat;
      address.long = lastAddress.geo.long;
    }
  }
  return address;
};

const getLocationsWithRestaurantByIds = ({ locations, restaurantIds }) =>
  locations.filter((location) => {
    const resGroupIds = location.restaurantGroups.map(
      (restaurantGroup) => restaurantGroup.id,
    );
    return restaurantIds.every((restaurant) =>
      resGroupIds.includes(restaurant),
    );
  });

const getRestaurants = (locations) =>
  locations.reduce((acc, cur) => {
    const newRes = cur.restaurantGroups;
    const temp = [];
    newRes.forEach((res) => {
      if (!acc.some((group) => group.id === res.id)) {
        temp.push(res);
      }
    });
    return [...acc, ...temp];
  }, []);

const mapLocations = (locations) =>
  locations.map((location) => {
    if (location?.slug) {
      return location;
    }

    console.error(`${location?.name} (${location?.id}) is missing a slug.`);

    return {
      ...location,
      isOpen: false,
    };
  });

const setRestaurantGroupMediaToHttps = (resGroupObj) => {
  const newObj = resGroupObj || {};
  if (newObj?.media) {
    Object.keys(newObj.media).forEach((key) => {
      newObj.media[key] = newObj.media[key].replace(/^http:/, "https:");
    });
    return newObj;
  }
  return {};
};

const sortLocationByDistance = (locations) =>
  locations.sort((a, b) => a.distanceInMiles - b.distanceInMiles);

const sortLocationByState = (locations, state) => {
  const lowerCaseState = state.toLowerCase();
  return locations.sort((a, b) => {
    let aValue = 0;
    let bValue = 0;
    if (a.address && a.address.state.toLowerCase() === lowerCaseState)
      aValue = 1;
    if (b.address && b.address.state.toLowerCase() === lowerCaseState)
      bValue = 1;
    return bValue - aValue;
  });
};

export {
  getAddress,
  getLocationsWithRestaurantByIds,
  getRestaurants,
  mapLocations,
  setRestaurantGroupMediaToHttps,
  sortLocationByDistance,
  sortLocationByState,
};
