import { constants } from "utils";
import { eventHandler } from "../tools";

const {
  ANALYTICS: { BRAND_SELECTED, CLIENT_IDENTIFIED, LOCATION_SELECTED },
} = constants;

export default {
  brandSelected: eventHandler(BRAND_SELECTED),
  clientIdentified: eventHandler(CLIENT_IDENTIFIED),
  locationSelected: eventHandler(LOCATION_SELECTED),
};
