import { constants } from "utils";

const { DEBUG_TOOLS } = process.env;

const {
  ANALYTICS: {
    CART_CLEARED,
    CART_VIEWED,
    CHECKOUT_STARTED,
    PRODUCT_ADDED,
    PRODUCT_CLICKED,
    PRODUCT_MODIFIED,
    PRODUCT_REMOVED,
    PURCHASE,
    SIGNED_IN,
    UNNAMED_EVENT,
  },
} = constants;

const mapSegmentAddToCart = (payload) => ({
  brand: payload?.brand,
  category: payload?.category,
  image_url: payload?.item?.image,
  name: payload?.item?.name,
  price: payload?.item?.price,
  product_id: payload?.item?.item,
  quantity: payload?.quantity,
  value: payload?.item?.totalPrice || 0 * payload?.quantity || 1,
});

const mapSegmentProducts = (items = {}) =>
  items.reduce((accu, curr) => {
    const itemObj = {
      image_url: curr?.image,
      name: curr?.name,
      price: curr?.price,
      product_id: curr?.item,
      quantity: 1,
    };
    return [...accu, itemObj];
  }, []);

const mapSegmentCheckoutStarted = (payload) => {
  const products = mapSegmentProducts(payload?.items);
  return {
    // affiliation, // TODO : add name of the location
    discount: payload?.resource?.appliedDiscounts,
    products,
    revenue: payload?.resource?.preDiscountPrice,
    shipping: payload?.resource?.delivery,
    tax: payload?.resource?.taxAmount,
    value: payload?.resource?.totalAmount, // finalAmount + appliedStoredValue
    // coupon, // TODO: add promotional code applied (not the ID, the code itself)
  };
};

const segmentEvent = (eventType, payload) => {
  const segmentInit = typeof window !== "undefined" && !!window.analytics;

  if (!segmentInit) {
    console.warn(
      "[lbx-tools]",
      `Segment event was fired but not initialized. (${eventType})`,
    );
    return null;
  }

  if (eventType === SIGNED_IN) {
    if (window.analytics?.identify) {
      window.analytics.identify(payload?.userId, {
        email: payload?.email,
        firstName: payload?.firstName,
        lastName: payload?.lastName,
        phone: payload?.phone,
      });
    }
  } else if (window.analytics?.track) {
    window.analytics.track(eventType, payload);
  }

  if (DEBUG_TOOLS) {
    console.info("[lbx-tools]", `Segment event was fired. (${eventType})`);
  }

  return null;
};

export default (event, payload) => {
  switch (event) {
    case PRODUCT_ADDED:
      return segmentEvent(event, mapSegmentAddToCart(payload));
    case CART_VIEWED:
      return segmentEvent(event, {
        products: mapSegmentProducts(payload?.items || {}),
      });
    case CHECKOUT_STARTED:
      return segmentEvent(event, mapSegmentCheckoutStarted(payload));
    case CART_CLEARED:
    case PRODUCT_MODIFIED:
    case PRODUCT_REMOVED:
    case PRODUCT_CLICKED:
    case PURCHASE:
      // Skipping purchases, backend will send...
      return () => {
        return null;
      };
    case SIGNED_IN:
      return segmentEvent(event, payload);
    case UNNAMED_EVENT:
    default:
      return segmentEvent(event, payload);
  }
};
