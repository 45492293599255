import { Schemas } from "utils";

const { AddressSchema } = Schemas;

export const parseAddress = ({ addressComps: address, lat, long, id }) => {
  const addressValidation = AddressSchema.isValidSync(address);
  return {
    ...(addressValidation
      ? {
          ...address,
          id,
          lat,
          long,
        }
      : {}),
    isValid: addressValidation,
  };
};

export default (state, { type, payload }) => {
  let newState = state;
  switch (type) {
    case "SET_ADDRESS": {
      const addressValidation = AddressSchema.isValidSync(payload.addressComps);
      newState = {
        ...state,
        ...(addressValidation
          ? {
              ...payload.addressComps,
              lat: payload.lat,
              long: payload.long,
            }
          : {}),
        isValid: addressValidation,
      };
      break;
    }
    case "SET_ADDRESS_2": {
      newState = {
        ...state,
        street2: payload.value,
      };
      break;
    }
    case "SEARCH": {
      newState = {
        ...state,
        placeId: payload.placeId,
        text: payload.text,
      };
      break;
    }
    case "CHANGE": {
      if (payload.text !== state.text) {
        newState = {
          city: "",
          isValid: undefined,
          lat: "",
          long: "",
          state: "",
          street1: "",
          text: payload.text,
          zip: "",
        };
      }
      break;
    }
    default:
      newState = state;
  }

  return newState;
};
