import { handleError } from "utils/axios";
import { BREAKPOINTS } from "utils/constants";

export const determineBreakPoint = (width: number) => {
  const widthAsEm = width / 16;
  const initial = {
    name: "xs",
    width: 0,
  };

  return BREAKPOINTS.reduce(
    (accu, i) => (widthAsEm < i?.width ? accu : i),
    initial,
  );
};

export const transformRequestError = (error: any) => {
  const e: {
    data: any;
    raw?: any;
    status: number;
    type: string;
    message?: string;
  } = handleError(error);

  if (e.type === "response") {
    if (e.status === 401) {
      e.message = "You must be logged in to place an order.";
    } else if (e.status === 400) {
      e.message = e.data;
    } else if (e.status === 500) {
      e.message = e.data;
    }
  }

  return e;
};

export const uuidv4 = (): string =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });

export const debounce = (fn: (p?: any) => void): ((p?: any) => void) => {
  let frame: any;

  return (...params: any) => {
    if (frame) {
      cancelAnimationFrame(frame);
    }

    frame = requestAnimationFrame(() => {
      fn(...params);
    });
  };
};

// use for obsecure destructuring where we don't know if the nested object key will be the same
export const getPropValue = (obj: any, key: string): object =>
  key.split(".").reduce((o, x) => (o === undefined ? o : o[x]), obj);

export const toObjectByKey = (arr: any, key: any) => {
  if (typeof key !== "string") {
    throw new Error(`Key must be a string, received ${typeof key}`);
  }
  return arr.reduce((accu: any, i: any) => {
    const accuTemp = { ...accu };
    accuTemp[i[key]] = i;
    return accuTemp;
  }, {});
};

export const getDisplayName = (WrappedComponent: any) =>
  WrappedComponent.displayName ?? WrappedComponent.name ?? "Component";
