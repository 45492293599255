import * as React from "react";
import debounce from "lodash/debounce";
import { FETCH_ADDRESS } from "utils/api";

const Context = React.createContext();

const autocomplete = (searchValue, orderType) =>
  FETCH_ADDRESS({ address: searchValue, orderType }).then(({ data }) => data);

const Provider = ({ children, orderType, address }) => {
  const [text, setText] = React.useState(address);
  const [items, setItems] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  const debouncedfetch = React.useRef(
    debounce(async (searchValue) => {
      setFetching(false);
      try {
        const data = await autocomplete(searchValue, orderType);
        setItems(data);
      } catch (error) {
        console.error(error);
      } finally {
        setFetching(false);
      }
    }, 300),
  );

  React.useEffect(() => {
    if (text.trim().length) {
      debouncedfetch.current(text.trim());
    }
  }, [text]);

  const contextValues = {
    fetching,
    items,
    setFetching,
    setItems,
    setText,
    setVisible,
    text,
    visible,
  };

  return (
    <Context.Provider value={contextValues}>
      {typeof children === "function"
        ? React.Children.only(children(contextValues))
        : React.Children.only(children)}
    </Context.Provider>
  );
};

const useGoogleAutocompleContext = () => {
  const contextValues = React.useContext(Context);
  if (!contextValues) {
    throw new Error(
      "useGoogleAutocompleContext is being accessed outside a GoogleAutocompleContext",
    );
  }
  return contextValues;
};

export { useGoogleAutocompleContext };
export default {
  Context,
  Provider,
};
