import * as React from "react";
import Styled from "styled-components";
import csx from "classnames";
import { useTemplateContext } from "components/providers/Template";
import { Condition } from "components/elements";
import { Text, View, Radio, Button } from "components/elementsThemed";
import { useCell } from "hooks";
import { formatPrice } from "utils/helpers/string";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import css from "./item.module.scss";

const { Condition: If } = Condition;

const RadioWrapper = Styled.div`
    border-radius: 100%;
    box-shadow: 0 0 0 0.25rem ${(props) => props.color || "transparent"};
`;

const Item = Styled(
  ({
    className,
    decrement,
    increment,
    item,
    multiSelect,
    onClick,
    quantity,
    type,
  }) => {
    const { isNested = false, name = "", price = 0 } = item;
    const {
      buttons: { primary },
      labelTextStyles,
      views,
    } = useCell(type);
    const {
      parsedTheme: { buttons },
    } = useTemplateContext();

    /** will fix this later */
    const buttonBorderColor = quantity
      ? buttons?.[primary]?.button?.selected?.["border-color"]
      : buttons?.[primary]?.button?.unselected?.["border-color"];

    return multiSelect ? (
      <View
        Component="li"
        className={`${css.modifierItem} ${className}`}
        data-nested={isNested}
        datatest="modifier-add-button"
        type={views.background}
      >
        <button
          aria-label={`${name}${price > 0 ? ` $${price}` : ""}`}
          aria-pressed={quantity === 0 ? "false" : "true"}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            height: "100%",
            pointerEvents: quantity === 0 ? "auto" : "none",
            position: "absolute",
            width: "100%",
            top: "0",
            left: "0",
          }}
          onClick={onClick}
        />
        <div className={css["modifierItem-left"]}>
          <Text
            type={labelTextStyles.primary}
            className={css["modifierItem-name"]}
            aria-hidden="true"
          >
            {isNested && quantity > 1 && `${quantity} x `}
            {name}
          </Text>
        </div>
        <If is={price}>
          <Text
            aria-hidden="true"
            type={labelTextStyles.secondary}
            className={css["modifierItem-price"]}
          >
            {formatPrice(price)}
          </Text>
        </If>
        <div
          className={csx(css.selectors, quantity && css["modifierItem-qty"])}
        >
          <If is={quantity}>
            <Button
              aria-label={`Decrease ${name} quantity`}
              type={primary}
              className={csx(css["modifierItem-qty--minus"])}
              onClick={decrement}
            />
            <Text
              aria-live="polite"
              className={csx(css["modifierItem-qty--text"])}
              type={labelTextStyles.tertiary}
            >
              <VisuallyHidden>{name} quantity: </VisuallyHidden>
              {quantity}
            </Text>
          </If>
          <Button
            aria-label={`Increase ${name} quantity`}
            type={primary}
            className={csx(css["modifierItem-qty--plus"])}
            onClick={quantity > 0 ? increment : onClick}
            tabindex={quantity > 0 ? "0" : "-1"}
            aria-hidden={quantity > 0 ? "false" : "true"}
          />
        </div>
      </View>
    ) : (
      <View
        Component="li"
        className={`${css.modifierItem} ${className}`}
        data-nested={isNested}
        data-cy="modifier-add-button"
        onClick={onClick}
        type={views.background}
      >
        <div className={css["modifierItem-left"]}>
          <Text
            aria-hidden="true"
            type={labelTextStyles.primary}
            className={css["modifierItem-name"]}
          >
            {isNested && quantity > 1 && `${quantity} x `}
            {name}
          </Text>
        </div>
        <If is={price}>
          <Text
            aria-hidden="true"
            type={labelTextStyles.secondary}
            className={css["modifierItem-price"]}
          >
            {formatPrice(price)}
          </Text>
        </If>
        <If is={isNested}>
          <button
            aria-label={`${name}${price > 0 ? ` $${price}` : ""}`}
            aria-pressed={quantity ? true : false}
            className={css["modifierItem-selectors--nested"]}
            onClick={quantity ? decrement : onClick}
            active={quantity > 0 ? "1" : "0"}
            style={{
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              height: "100%",
              pointerEvents: quantity === 0 ? "auto" : "none",
              position: "absolute",
              width: "100%",
              top: "0",
              left: "0",
            }}
          />
        </If>
        <If is={!isNested}>
          <RadioWrapper
            tabIndex="-1"
            onClick={onClick}
            color={buttonBorderColor}
            className={css["modifierItem-radio-wrapper"]}
          >
            <Radio
              type={primary}
              className={css["modifierItem-radio"]}
              value={quantity}
            >
              <VisuallyHidden>
                {name} <If is={price}>{formatPrice(price)}</If>
              </VisuallyHidden>
            </Radio>
          </RadioWrapper>
        </If>
      </View>
    );
  },
)`
  border-bottom-color: ${(props) => props.theme.colors.alternateGray}
`;

export default React.memo(Item);
