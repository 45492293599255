import * as React from "react";
import { getDisplayName } from "utils/helpers/other";

/**
 * @param Component
 * @author Mohammad Afzal
 * @memberof Templates.Templates/Loyalty
 */
const withRewardCard = (Component) => {
  // eslint-disable-next-line
  const withRewardCard = (props) => {
    const { id, promotion, type, onClick } = props;
    const { name, description, callToAction } = promotion;
    const onClickRewardCard = () =>
      onClick({ promotionCodeId: id, promotionId: promotion.id });

    return (
      <Component
        type={type}
        name={name}
        description={description}
        callToAction={callToAction}
        onClick={onClick ? onClickRewardCard : null}
      />
    );
  };
  withRewardCard.displayName = `withRewardCard(${getDisplayName(Component)})`;
  return withRewardCard;
};

export default withRewardCard;
