import * as React from "react";
import { config } from "utils";

type LogoProps = {
  src?: string;
  style?: React.CSSProperties;
};

function Logo({ src, style }: LogoProps) {
  return <img style={style} src={src} alt={`${config.restaurant} Logo`} />;
}

Logo.defaultProps = {
  src: "",
  style: {},
};

export { Logo };
export default Logo;
