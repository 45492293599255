import * as React from "react";
import { Text, View } from "components/elementsThemed";
import { images } from "utils";
import { Image } from "components/elements";
import { useCell } from "hooks";
import css from "./BankBased.module.scss";

const BankPoints = ({ title, text, iconText = "" }) => {
  const { labelTextStyles, views } = useCell("bankCard");
  const iconTextCopy = iconText?.split(" ");

  return (
    <View className={css["reward-container"]}>
      {!iconTextCopy ? (
        <View className={css["reward-image--container"]}>
          <Image
            className={css["reward-image--img"]}
            src={images?.icon_bank}
            alt="rewards-logo"
          />
        </View>
      ) : (
        <View>
          <View type={views.primary} className={css["reward-icon"]}>
            <Text
              className={css["reward-icon--points"]}
              type={labelTextStyles.iconPoints}
            >
              {iconTextCopy[0]} {<br />} {iconTextCopy[1]}
            </Text>
          </View>
        </View>
      )}
      <View className={css["reward-points"]}>
        <Text
          type={labelTextStyles.points}
          className={css["reward-points--points"]}
        >
          {title} POINTS
        </Text>
        <div>
          <Text type={labelTextStyles.tertiary}>{text}</Text>
        </div>
      </View>
    </View>
  );
};

export default BankPoints;
