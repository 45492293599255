import * as React from "react";
import { isNull } from "lodash";

/**
 * @param {Event} e - an event produced by an EventTarget
 */
function preventDefault(e: Event) {
  e.preventDefault();
}

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;

try {
  window.addEventListener(
    "test",
    () => undefined,
    Object.defineProperty({}, "passive", {
      get() {
        supportsPassive = true;
      },
    }),
  );
} catch (error) {
  console.error(error);
}

const wheelOpt = supportsPassive ? { passive: false } : false;
const wheelEvent =
  "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

// call this to Disable
/**
 * Shim the existing mouse scroll and wheel events with a default preventing event
 */
function disableScroll() {
  window.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
}

// call this to Enable
/**
 * Remove the shim
 */
function enableScroll() {
  window.removeEventListener("DOMMouseScroll", preventDefault, false);
  // NB: wheelOpt is type coerced to "any" here, because behavior for removeEventListener
  // is inconsistent across browsers and it's best to always use the same arguments
  // for addEventListener as removeEventListener.
  // More info here: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/removeEventListener
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt as any);
}

// onWheel event will use scrollingRef to scroll referenced DOM element
/**
 * @param {React.WheelEvent<HTMLElement>} e - The scroll wheel event captured by React
 * @param {React.MutableRefObject<HTMLElement | null>} scrollingRef - The ref to the container capturing the event
 */
function onWheelHandler(
  e: React.WheelEvent<HTMLElement>,
  scrollingRef: React.MutableRefObject<HTMLElement | null>,
) {
  const container = scrollingRef.current;

  if (!isNull(container)) {
    const containerScrollPosition = container.scrollLeft;
    let delta = e.deltaY;

    if (navigator.userAgent.indexOf("Firefox") !== -1) {
      delta *= 100;
    } else {
      delta *= 2;
    }

    container.scrollTo({
      behavior: "smooth",
      left: containerScrollPosition + delta,
      top: 0,
    });
  }
}

export { disableScroll, enableScroll, onWheelHandler };
