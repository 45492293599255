import * as Yup from "yup";

// custom method to allow for null value in required field
// eslint-disable-next-line func-names
Yup.addMethod(Yup.mixed, "defaultNull", function () {
  // eslint-disable-next-line func-names
  return this.transform((value) => value || "http://default.com");
});

const nullableUrl = Yup.string()
  .url()
  .min(0)
  .defaultNull()
  .defined()
  .required();
const requiredUrl = Yup.string().url().defined().required();

const ImageSchema = Yup.object().shape({
  app_store_download: requiredUrl,
  art_empty: requiredUrl,
  art_empty_cart: requiredUrl,
  art_empty_locations: requiredUrl,
  art_error: requiredUrl,
  art_footer_rewards: requiredUrl,
  art_gift_card: requiredUrl,
  art_gift_card_logo: nullableUrl,
  art_item_placeholder: requiredUrl,
  art_location: requiredUrl,
  art_logo: requiredUrl,
  art_logo_2: requiredUrl,
  art_menu_header: nullableUrl,
  art_misc: nullableUrl,
  art_misc_1: nullableUrl,
  art_misc_loyalty: nullableUrl,
  art_order_confirmation: nullableUrl,
  art_order_confirmation_kiosk: nullableUrl,
  art_search: nullableUrl,
  button_arrow: nullableUrl,
  button_back: requiredUrl,
  button_back_checkout: requiredUrl,
  button_back_item_details: requiredUrl,
  button_back_login: requiredUrl,
  button_back_main: requiredUrl,
  button_back_main_secondary: nullableUrl,
  button_back_profile: requiredUrl,
  button_price_clear: requiredUrl,
  google_play_store_download: requiredUrl,
  guest_checkout: nullableUrl,
  guests: nullableUrl,
  icon_bank: nullableUrl,
  icon_burger_menu: nullableUrl,
  icon_cart: requiredUrl,
  icon_check: nullableUrl,
  icon_empty_cart: requiredUrl,
  icon_geo: requiredUrl,
  icon_login: requiredUrl,
  icon_login_1: nullableUrl,
  icon_logout: requiredUrl,
  icon_profile: requiredUrl,
  indicator_pin_delivery: requiredUrl,
  indicator_pin_driver: requiredUrl,
  indicator_pin_location: requiredUrl,
  loyalty_rewards_stub_icon: requiredUrl,
  lunchbox_logo_footer: requiredUrl,
  signup_confirmation: nullableUrl,
  signup_header: nullableUrl,
  signup_loyalty_header: nullableUrl,
  table_number: nullableUrl,
  web_banner: nullableUrl,
  modifier_item_remove: nullableUrl,
});

export { ImageSchema };

export default ImageSchema;
