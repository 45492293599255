import * as React from "react";
import debounce from "lodash/debounce";
import { Button } from "components/elementsThemed/Button";
import { Input } from "components/elementsThemed/Field";
import { useCachedAddresses } from "../../../contexts/CachedAdresses";
import { Copy } from "utils";
import { FETCH_ADDRESS } from "utils/api";
import styles from "./liveSearch.module.scss";

const SearchResult = ({ name, wrapperRef, ...props }) => {
  return (
    <div ref={wrapperRef}>
      <Button
        Component="div"
        className={[styles["search-result"], styles[props?.type]]}
        {...props}
      >
        {name}
      </Button>
      <hr />
    </div>
  );
};

const LiveSearch = ({ inputProps, onSelect, orderType, resultType, type }) => {
  const inputRef = React.useRef(null);
  const itemsRef = React.useRef([]);
  const [address, setAddress] = React.useState("");
  const [addressItems, setAddressItems] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);

  // Recent Addresses (cached) from context (local storage)
  const { recentAddresses = [] } = useCachedAddresses();

  const formattedRecentAddresses = recentAddresses
    .map(({ id, text }) => {
      return id && { id, text };
    })
    .filter((a) => !!a);

  const [focused, setFocused] = React.useState(false);

  const debouncedfetch = React.useRef(
    debounce(async (searchValue) => {
      setFetching(false);
      try {
        const { data } = await FETCH_ADDRESS({
          address: searchValue,
          orderType,
        });

        setAddressItems(data);
      } catch (error) {
        console.error(error);
      } finally {
        setFetching(false);
      }
    }, 300),
  );

  React.useEffect(() => {
    // Update itemsRef (array) size to match updated address items
    itemsRef.current = Array(addressItems.length)
      .fill()
      .map((_, index) => itemsRef.current[index] || React.createRef());
  }, [addressItems]);

  React.useEffect(() => {
    // Only handle blur when field is focused
    if (!focused) {
      return () => {
        return null;
      };
    }

    const handleOnBlur = (e) => {
      if (!inputRef?.current?.contains(e?.target)) {
        // Click occured outside of input field,
        // therefore we are no longer focused
        setFocused(false);

        // Check if click target was an address item using itemsRef
        const checkButtons =
          itemsRef?.current?.filter(({ current }) =>
            current?.contains(e?.target),
          ) || [];
        if (!checkButtons.length) {
          // If click was not an address item,
          // unpopulate address items to hide list
          setAddressItems([]);
        }
      }
    };

    document.addEventListener("mousedown", handleOnBlur);

    return () => {
      document.removeEventListener("mousedown", handleOnBlur);
    };
  }, [focused, itemsRef, inputRef]);

  React.useEffect(() => {
    // Only populate address items when field is focused
    if (!focused) return;

    if (!address.trim().length) {
      // If there is no address entered,
      // populate address items with cached addresses
      setAddressItems(formattedRecentAddresses);
    } else {
      // Populate address items with fetched addresses
      debouncedfetch.current(address.trim());
    }
  }, [focused, address]);

  return (
    <div className={styles["seach-container"]}>
      <div className={styles["search-dropdown"]}>
        <Input
          className={[styles.input, inputProps?.className]}
          icon={inputProps?.icon}
          inputRef={inputRef}
          onChange={(e) => setAddress(e?.target?.value)}
          onFocus={() => setFocused(true)}
          // We need to check when the field was erased
          onKeyDown={(e) => e.keyCode === 8 && setAddress(e?.target?.value)}
          placeholder={
            Copy.PICKUP_FORM_STATIC.FORM_SEARCH_FORM_ADDRESS_PLACEHOLDER
          }
          type={type}
          value={address}
        />

        <div className={styles["search-results"]}>
          {fetching ? (
            <p>{Copy.LIVE_SEARCH_STATIC.MESSAGE}</p>
          ) : (
            !!addressItems.length &&
            addressItems.map((addressItem, idx) => (
              <SearchResult
                data-cy={`address-${idx + 1}`}
                key={addressItem.text}
                name={addressItem.text}
                onClick={(e) => {
                  e.preventDefault();
                  setAddress(addressItem?.text);
                  setAddressItems([]);
                  onSelect(addressItem);
                }}
                type={resultType}
                wrapperRef={itemsRef.current[idx]}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default LiveSearch;
