import * as Sentry from "@sentry/browser";
import { config, constants } from "utils";
// eslint-disable-next-line import/no-unresolved
import { version } from "PackageData";

const {
  ENVIRONMENTS: { STAGE, PRODUCTION },
} = constants;

export default (() => {
  if (![STAGE, PRODUCTION].includes(process.env.BUILD_ENV)) return;

  /** An array of strings. If any are included in an error message, that error won't be passed to Sentry. */
  const keywordsToFilter = [
    "CSS Chunk",
    "instantSearchSDKJSBridgeClearHighlight",
    "adroll",
  ];

  const client = config?.id;
  Sentry.init({
    beforeSend: (event) => {
      const shouldBeFiltered = keywordsToFilter.some((keyword) =>
        event?.message?.includes(keyword),
      );
      if (shouldBeFiltered) {
        return null;
      }
      return event;
    },
    dsn: "https://e4d74117eef74c6b86cd08a1db26f853@sentry.io/3606912",
    release: `Lunchbox-web-theme-1@${version}`,
  });
  Sentry.configureScope((scope) => {
    scope.setTag("client", client);
    scope.setTag("version", version);
    scope.setTag("sessionId", constants.SESSION_ID);
  });
})();
