/*
 * ItemDetails Layout Subgroup Type1
 * Author: Isaiah Sanchez <isanchez@lunchbox.io>
 *
 * This component renders the ItemDetails using groups/subgroups.
 * An item can have groups of different modifiers.
 *
 * This type uses the HeaderSelector and matches ItemDetails/Layout/Type1.
 *
 * Prominently used in Wingsover and Upper Crust Pizzeria.
 */

import SubgroupsType1 from "./subgroupsType1";
import withItemDetails from "../../withItemDetails";

export default withItemDetails(SubgroupsType1);
