import * as React from "react";
import csx from "classnames";
import { useTemplateContext } from "components/providers/Template";
import { View } from "components/elementsThemed";
import { useCell } from "hooks";
import { ReactComponent as PoweredByLunchbox } from "../../../../assets/poweredByLunchbox.svg";

import css from "./fixed.module.scss";

const Fixed = ({ type, style, className = "" }) => {
  const { views, labelTextStyles } = useCell(type);
  const {
    parsedTheme: { labels },
  } = useTemplateContext();
  return (
    <View
      type={views.background}
      className={csx(css.footer, className)}
      style={style}
    >
      <a href="https://lunchbox.io" className={css["footer-link"]}>
        <PoweredByLunchbox
          title="Powered by Lunchbox"
          fill={labels[labelTextStyles.primary].color}
        />
      </a>
    </View>
  );
};

export { Fixed };
