import * as React from "react";
import { config } from "utils";
import { useCell } from "hooks";
import { Condition } from "components/elements";
import { View, Text } from "components/elementsThemed";
import { Copy } from "utils";
import classnames from "classnames";
import ClampLines from "react-clamp-lines";
import css from "./restaurantsFilter.module.scss";

const { Condition: If } = Condition;
const RestaurantsFilter = (props) => {
  const { restaurants, type, selectedItems, setSelectedItems } = props;
  const { views, labelTextStyles } = useCell(type);
  const ContainerRef = React.useRef();
  const handleItemSelect = (id) => {
    setSelectedItems((item) => {
      if (item.includes(id)) {
        return item.filter((x) => x !== id);
      }
      return [...item, id];
    });
  };

  const handleScroll = (direction) => {
    const scrollDistance = document.querySelector(
      ".restaurantsFilter-card",
    ).clientWidth;
    ContainerRef.current.scrollBy({
      behavior: "smooth",
      left: direction === "next" ? +scrollDistance : -scrollDistance,
      top: 0,
    });
  };

  return (
    <div className={css.restaurantsFilter}>
      <If is={restaurants?.length}>
        <Text
          type={labelTextStyles.primary}
          className={css["restaurantsFilter-title"]}
        >
          {Copy.LOCATIONS_STATIC.LOCATION_FILTER_TITLE}
        </Text>
        <View
          type={views.secondary}
          layout="horizontal"
          innerRef={ContainerRef}
          className={css["restaurantsFilter-container"]}
          showArrow={config.theme.location?.restaurant_filter_scroll_arrow}
          handleScroll={handleScroll}
        >
          {restaurants.map((restaurant) => {
            const isSelected = selectedItems.includes(restaurant.id);
            return (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <div
                key={restaurant.id}
                className={classnames(
                  css["restaurantsFilter-card"],
                  isSelected && css["restaurantsFilter-card-selected"],
                  "restaurantsFilter-card",
                )}
                onClick={() => handleItemSelect(restaurant.id)}
                onKeyDown={() => handleItemSelect(restaurant.id)}
                role="listitem"
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex="0"
              >
                <div
                  className={css["restaurantsFilter-card-image"]}
                  style={{
                    backgroundImage: `url(${restaurant.media.restaurantThumbnail})`,
                  }}
                />
                <Text
                  type={labelTextStyles.tertiary}
                  className={css["restaurantsFilter-card-name"]}
                >
                  {restaurant.name}
                </Text>

                <Text
                  type={labelTextStyles.secondary}
                  className={css["restaurantsFilter-card-desc"]}
                >
                  <ClampLines
                    id={restaurant.id}
                    buttons={false}
                    text={restaurant.description}
                    lines={2}
                    ellipsis="..."
                  />
                </Text>
              </div>
            );
          })}
        </View>
      </If>
    </div>
  );
};

export default RestaurantsFilter;
