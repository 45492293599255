import { useWindowSize } from "hooks";
import { determineBreakPoint } from "utils/helpers/other";

function Media({ breakpoints, children }) {
  const { width } = useWindowSize({});

  const { name: breakPoint } = determineBreakPoint(width);

  return breakpoints.includes(breakPoint) ? children : null;
}

export default Media;
