import * as React from "react";
import { Image, Layout } from "components/elements";
import css from "./empty.module.scss";

const { Flex } = Layout;

const Empty = ({ imgSrc, children }) => (
  <Flex
    direction="col"
    align="center"
    justify="center"
    grow={1}
    className={css.empty}
  >
    <div className={css.content}>
      <div className={css.message}>{children}</div>
      <Image className={css.artImg} src={imgSrc} />
    </div>
    <div className={css.bg} />
  </Flex>
);

export default Empty;
