import * as React from "react";
import { withRouter } from "react-router-dom";
import styles from "./routerNav.module.css";

const Nav = ({ children, component = "div" }) =>
  React.createElement(
    component,
    {
      className: styles.nav,
    },
    children,
  );

const NavItem = withRouter(({ children, to, location, history }) => (
  <div
    className={styles["nav-item"]}
    onClick={() => {
      if (location.pathname !== to) history.replace(to);
    }}
    onKeyDown={() => {
      if (location.pathname !== to) history.replace(to);
    }}
    role="button"
    tabIndex="0"
  >
    {children}
  </div>
));

export { Nav, NavItem };
export default Nav;
