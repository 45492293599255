import * as React from "react";
import qs, { StringifyOptions } from "query-string";
import { merge } from "lodash";

// Only use URL query functions below when isBrowser is true
import { isBrowser } from "../helpers";

/**
 * Validate and parse string with new URL Constructor
 * Returns false when invalid instead of throwing error
 */
export function parseUrl(string?: string) {
  let url;

  try {
    url = new URL(string);
  } catch (err) {
    return false;
  }

  return url;
}

/**
 *
 * @param qsValue
 */
export function setQueryWithoutReload(qsValue: string) {
  const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${qsValue}`;

  window.history.pushState({ path: newurl }, "", newurl);
}

/**
 * Add a key and value to the URI using query-string
 */
export function parseQuery(
  key: string,
  value: any,
  queryString?: string,
  options?: StringifyOptions,
) {
  const url = parseUrl(queryString);
  const query = url?.search || queryString || window?.location?.search || "";
  const values = qs.parse(query, {
    arrayFormat: "comma",
  });
  const newQsValue = qs.stringify(merge(values, key ? { [key]: value } : {}), {
    arrayFormat: "comma",
    skipNull: true,
    skipEmptyString: true,
    ...options,
  });

  return newQsValue ? `?${newQsValue}` : "";
}

/**
 * Parse a query value using query-string
 * @param key
 * @param queryString
 * @returns
 */
export function getQuery(
  key: string,
  queryString?: string,
  options?: {
    parseNumbers?: boolean;
    pareBooleans?: boolean;
  },
) {
  const url = parseUrl(queryString);
  const query = url?.search || queryString || window?.location?.search || "";
  const values = qs.parse(query, {
    arrayFormat: "comma",
    parseNumbers: true,
    parseBooleans: true,
    ...options,
  });

  return values[key];
}

/**
 * Custom hook that gets and sets URL query as React state
 * @param key
 * @param initialValue
 * @returns
 */
export function useQueryState<S = any>(key: string, initialValue: S) {
  // Create React State from URL query or initial value
  const [state, setState] = React.useState<string | (string | unknown)[] | S>(
    () => {
      const setArray = (i) => (Array.isArray(initialValue) ? [i] : i);

      // Enforce array type bc array with 1 item is confused as string on reload
      return setArray(isBrowser ? getQuery(key) : initialValue);
    },
  );

  const onSetState = React.useCallback(
    (updatedState: S) => {
      setState((prev) => {
        const newState =
          typeof updatedState === "function"
            ? updatedState(prev)
            : updatedState;

        // Only update URL query with new state in browser
        if (isBrowser) {
          const newQuery = parseQuery(key, newState);
          setQueryWithoutReload(newQuery);
        }

        return newState;
      });
    },
    [key],
  );

  // Mimic same return as React.useState
  return [state, onSetState];
}
