// TODO: Some of these type definitions should be global
// TODO: Some of these type definitions need further definitions

interface ItemNode {
  description: string;
  images: Array<string>;
  options: Array<unknown>;
  price: number;
  externalId: string;
  name: string;
  restaurantGroup: string;
  id: string;
  additionalDescriptions: Array<string>;
  priceRange: number;
  isNested: boolean;
}

interface OptionNode {
  isSingleSelectionOnly: boolean;
  items: Array<ItemNode>;
  max: number;
  min: number;
  externalId: string;
  name: string;
  restaurantGroup: string;
  id: string;
  priority: number;
  defaultModifiers: Array<unknown>;
}

type OptionState = Record<string, OptionNode>;

interface ModifierNode {
  item: string;
  modifiers: Record<string, Array<ModifierNode>>;
  option: string;
  quantity: number;
}

type ModifierState = Record<string, Array<ModifierNode>>;

interface ValidateOptionsProperties {
  id: string;
  optionsHash: OptionState;
  options: Array<OptionNode>;
  state: ModifierState;
}

type ValidateOptionsReturn = Array<{
  isExcessive: boolean;
  isInsufficient: boolean;
  max: number;
  min: number;
  id: string;
  quantity: number;
}>;

/**
 * State Quantity - Reduces the state of an option to calculate total selected quantity
 */
export function qtyPropSum(optionState: Array<{ quantity: number }> = []) {
  return optionState.reduce((sum, { quantity = 0 }) => sum + quantity, 0);
}

/**
 * Validate Options - cross check the itemHash options with the modifier state to validate quantities (min and max).
 */
export function validateOptions({
  optionsHash,
  id: optionId,
  options,
  state,
}: ValidateOptionsProperties) {
  // TODO: Create an ItemDetails Context/Provider, use it here to avoid prop drilling and manage options hash and state
  return options.reduce<ValidateOptionsReturn>((accu, curr) => {
    // Use the optionId for nesting, otherwise use the current id
    const id = optionId || curr?.id;
    // Find this option in the optionsHash
    const option = optionsHash?.[id];

    if (!option) return accu;

    const { max = 0, min = 0 } = option;

    // How many modifiers were selected in the modifiers state
    const selectedQty = qtyPropSum(state?.[id]);

    // If there is a minimum (greater than 0) and nothing selected in the modifiers state
    // Or if there is a minimum and the length of our selected modifiers state of this option does not meet the minimum
    const isInsufficient = Boolean(
      (min && !selectedQty) || (min && selectedQty < min),
    ); // Minimum was not met for this modifier
    // If there is a maximum (greater than 0) and the length of our selected modifiers state is greater than the maximum
    const isExcessive = Boolean(max && selectedQty > max); // Too many modifiers were chosen

    if (isInsufficient || isExcessive) {
      return [
        {
          id,
          isExcessive,
          isInsufficient,
          max,
          min,
          quantity: selectedQty,
        },
        ...accu,
      ];
    }

    return accu;
  }, []);
}
