import * as React from "react";
import { useActive } from "hooks";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { Input } from "components/elementsThemed/Field";
import { Button } from "components/elements/Button";
import { ReactComponent as Eye } from "../../../assets/eye.svg";
import { ReactComponent as EyeClosed } from "../../../assets/close-eyes.svg";
import css from "../form.module.scss";

function PasswordPreview({
  label,
  placeholder,
  name,
  errors,
  type,
  values,
  inputRef,
  onChange,
  ...props
}) {
  const { isActive, toggle } = useActive(false);
  return (
    <div className={css["password-wrapper"]}>
      <Input
        {...props}
        label={label}
        name={name}
        htmlType={isActive ? "text" : "password"}
        inputRef={inputRef}
        type={type}
        error={errors}
        value={values}
        placeholder={placeholder}
        onChange={onChange}
      />
      <Button
        aria-checked={isActive ? "true" : "false"}
        onClick={toggle}
        className={css.eye}
        role="switch"
        type="button"
      >
        <VisuallyHidden>Show Password</VisuallyHidden>
        {isActive ? (
          <Eye aria-hidden="true" />
        ) : (
          <EyeClosed aria-hidden="true" />
        )}
      </Button>
    </div>
  );
}

export { PasswordPreview };
