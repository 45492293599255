export const ORDER_BUTTON_TEXT = "Order";
export const RESTAURANTS_UNAVAILABLE = "No restaurants available at this time.";
export const MENU_DISCOVERY_FEATURED_TITLE = "Featured Restaurant";
export const MENU_DISCOVERY_EXPLORE_TITLE = "Explore our Restaurants";
export const MENU_DISCOVERY_SEE_ALL = "see all";
export const MENU_DISCOVERY_LIST_TITLE =
  "Mix & match dishes from {restaurantsCount} restaurants";

export default {
  ORDER_BUTTON_TEXT,
  RESTAURANTS_UNAVAILABLE,
  MENU_DISCOVERY_FEATURED_TITLE,
  MENU_DISCOVERY_EXPLORE_TITLE,
  MENU_DISCOVERY_SEE_ALL,
  MENU_DISCOVERY_LIST_TITLE,
};
