import * as React from "react";
import { withTheme } from "styled-components";
import csx from "classnames";

import Flex from "components/elements/Layout/flex";
import { Text } from "components/elementsThemed/Text/text";
import withTemplate from "components/hocs/withTemplate";
import DrawerButton from "../../../DrawerButton";
import { Loader } from "components/fragments/Loader/loader";
import useResource from "hooks/useResource";
import useScript from "hooks/useScript";
import { Copy } from "utils";
import { PATRON_ADD_CARD, HANDLE_ERROR } from "utils/api";
import {
  mapGeneralError,
  mapHostedFieldsError,
  mapValidationError,
  options,
  initializationOptions,
  emptyNameFieldError,
} from "./utils";

import css from "./seamless.module.scss";

function ScriptHandler(props) {
  const { loaded: clientLoaded, error: clientErr } = useScript(
    "https://client-sdk.seamlesspay.com/3.0.5/js/client.min.js",
  );
  const { loaded: hostedLoaded, error: hostedErr } = useScript(
    "https://client-sdk.seamlesspay.com/3.0.5/js/hosted-fields.min.js",
  );

  if (!clientLoaded || !hostedLoaded) {
    return null;
  }

  if (clientErr || hostedErr) {
    console.error("Unable to load Payment Form");
    return <></>;
  }
  return <SeamlessThemed {...props} />;
}

function Seamless({ style: { cells, labels }, locationId, onSuccess }) {
  const [field, setField] = React.useState({ hostedFields: null });
  const [err, setErr] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const [cardHolderName, setCardHolderName] = React.useState("");
  const [
    { resource: locationCreds, fetching: fetchingCreds, error: errorCreds },
  ] = useResource({ path: "/location/credentials" });

  const handleError = (errorObject) => {
    setErr(mapHostedFieldsError(errorObject));
  };

  React.useEffect(() => {
    const initSDK = async () => {
      const { seamlesspay } = window;
      let clientInstance = null;
      let hostedFieldsInstance = null;
      try {
        clientInstance = await seamlesspay.client.create(
          initializationOptions(locationCreds),
        );
      } catch (initError) {
        if (initError) handleError(initError);
        setLoading(false);
        return;
      }

      try {
        hostedFieldsInstance = await seamlesspay.hostedFields.create(
          options(clientInstance),
        );
        setField(hostedFieldsInstance);
      } catch (fieldsError) {
        if (fieldsError) handleError(fieldsError);
      } finally {
        setLoading(false);
      }
    };

    if (
      window.seamlesspay &&
      window.seamlesspay.client &&
      locationCreds.publicKey
    ) {
      initSDK();
    }
  }, [window.seamlesspay, locationCreds.publicKey]);

  const handleSuccess = () => {
    if (onSuccess) onSuccess();
  };

  const handleSubmit = async (event) => {
    setSubmitting(true);
    setErr(null);
    event.preventDefault();

    if (!cardHolderName) {
      setErr(emptyNameFieldError);
      setSubmitting(false);
      return;
    }

    let tokenData = null;
    try {
      tokenData = await field.tokenize({
        name: cardHolderName,
        verification: true,
      });
    } catch (tokenizeError) {
      handleError(tokenizeError);
      setSubmitting(false);
      return;
    }

    const hasValidationError = mapValidationError(tokenData);
    if (hasValidationError) {
      setErr(hasValidationError);
      setSubmitting(false);
      return;
    }

    let expDate = null;

    if (tokenData.expDate) {
      if (tokenData.expDate.length === 4) {
        expDate = `${tokenData.expDate.substring(
          0,
          2,
        )}/${tokenData.expDate.substring(2, 4)}`;
      } else if (tokenData.expDate.length === 7) {
        expDate = `${tokenData.expDate.substring(
          0,
          2,
        )}/${tokenData.expDate.substring(5, 7)}`;
      } else {
        setErr("Please enter expiration date as MM/YY or MM/YYYY");
        setSubmitting(false);
        return;
      }
    }

    const data = {
      brand: `${tokenData.cardBrand}`,
      cardNonce: `${tokenData.token}`,
      expDate: `${expDate}`,
      last4: `${tokenData.lastfour}`,
    };
    try {
      await PATRON_ADD_CARD(data, { locationId });
      handleSuccess();
    } catch (e) {
      const responseError = HANDLE_ERROR(e);
      setErr(responseError.data || "An error occurred");
    } finally {
      setSubmitting(false);
    }
  };

  if (fetchingCreds) {
    return (
      <Flex
        grow="1"
        direction="col"
        align="center"
        justify="center"
        className={css.loader}
      >
        <Loader />
      </Flex>
    );
  }
  if (Object.keys(errorCreds).length) {
    return (
      <Flex
        grow="1"
        direction="col"
        align="center"
        justify="center"
        className={css.loader}
      >
        {mapGeneralError(errorCreds)}
      </Flex>
    );
  }

  return (
    <>
      {loading && (
        <Flex
          grow="1"
          direction="col"
          align="center"
          justify="center"
          className={css.loader}
        >
          <Loader />
        </Flex>
      )}
      <form
        onSubmit={handleSubmit}
        className={css.seamlessForm}
        style={{ visibility: loading ? "hidden" : "initial" }}
      >
        <div className={csx(css["seamlessForm-fields"], "payment-form")}>
          <input type="hidden" name="payment_method_id" />
          <div>
            <label htmlFor="card-holder-name">
              {Copy.PAYMENT_FORM_STATIC.SEAMLESS_CARD_HOLDER_NAME}
            </label>
            <input
              id="card-holder-name"
              className={css["seamlessForm-fields-item"]}
              placeholder={Copy.PAYMENT_FORM_STATIC.SEAMLESS_CARD_HOLDER_NAME}
              onChange={(e) => setCardHolderName(e.target.value)}
              value={cardHolderName}
            />
          </div>
          <div>
            <label htmlFor="account-number" autoComplete="cc-number">
              {Copy.PAYMENT_FORM_STATIC.SEAMLESS_CARD_NUMBER_LABEL}
            </label>
            <div
              id="account-number"
              className={css["seamlessForm-fields-item"]}
              autoComplete="cc-number"
            />
          </div>
          <div>
            <label htmlFor="exp-date" autoComplete="cc-exp">
              {Copy.PAYMENT_FORM_STATIC.SEAMLESS_CARD_EXPIRATION_DATE_LABEL}
            </label>
            <div
              id="exp-date"
              className={css["seamlessForm-fields-item"]}
              autoComplete="cc-exp"
            />
          </div>
          <div>
            <label htmlFor="cvv" autoComplete="cc-csc">
              {Copy.PAYMENT_FORM_STATIC.SEAMLESS_CARD_CVV_NUMBER_LABEL}
            </label>
            <div
              id="cvv"
              className={css["seamlessForm-fields-item"]}
              autoComplete="cc-csc"
            />
          </div>
          <div>
            <label htmlFor="billingZip">
              {Copy.PAYMENT_FORM_STATIC.SEAMLESS_CARD_ZIPCODE_LABEL}
            </label>
            <div id="billingZip" className={css["seamlessForm-fields-item"]} />
          </div>
          <Text type={labels.error}>{err}</Text>
        </div>
        <div className={css["seamlessForm-footer-button"]}>
          <DrawerButton
            buttonProps={{
              id: "my-submit",
              htmlType: "submit",
              disabled: submitting,
            }}
          >
            {submitting
              ? Copy.PAYMENT_FORM_STATIC.BUTTON_TEXT_SAVING
              : Copy.PAYMENT_FORM_STATIC.PAYMENT_FORM_SAVE_BUTTON_TEXT}
          </DrawerButton>
        </div>
      </form>
    </>
  );
}

const SeamlessThemed = withTheme(withTemplate(Seamless, "payment"));
export default ScriptHandler;
