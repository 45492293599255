import * as React from "react";

const LinebreakText = ({ text, Component = "span" }) =>
  text.split("\n").map((i, idx) => (
    // eslint-disable-next-line react/no-array-index-key
    <Component key={`${i}-${idx}`} style={{ display: "block" }}>
      {i}
    </Component>
  ));

export default LinebreakText;
