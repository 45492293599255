import * as React from "react";
import { Col } from "@lunchboxinc/lunchbox-components";

import { images } from "../../../../utils/Images";
import { Copy } from "../../../../utils/Copy";
import { Image } from "../../../../components/elements/Image";
import { Condition as If } from "../../../../components/elements/Condition";
import { View } from "../../../../components/elementsThemed/View";
import { Text } from "../../../../components/elementsThemed/Text";

import css from "./completeType7.module.scss";

/**
 * Renders a patron's order information, specifically the timing of their order
 *
 * TODO - Fix scheduledAt rendered value
 *
 * @author Mohammad Afzal
 * @param {object} props - React props
 * @param {string} props.labels - theme labels from the theme file
 * @param {string} props.orderStatusDescription - status text to show the guest based on where the order is in the process
 * @param {string} props.views - theme views from the theme file
 * @param {string|null} props.scheduledAt - UTV time future order date from backend after placing an order if the user placed a future order.
 * @param {string} props.scheduleTime - formatted scheduledAt time computed by taking timezones into account
 * @param {string} props.prepTimeText - Copy to show the user next to the order times based on the orderType
 * @param {string} props.deliveryTime - Delivery time in h:mm a which is the scheduleAt or orderTime (depending on future order or not) + prepTime
 * @returns {React.Element} - Returns JSX of Of order information shown at the top of completeType7
 */

interface OrderInformationProps {
  deliveryTime: string;
  labels: {
    time?: string;
    primary?: string;
    tertiary?: string;
    secondary?: string;
  };
  orderStatusDescription: string;
  prepTimeText: string;
  scheduledAt: string;
  scheduleTime: string;
  views: {
    background?: string;
    secondary?: string;
    tertiary?: string;
    primary?: string;
  };
}

function OrderInformation({
  deliveryTime,
  labels,
  orderStatusDescription,
  prepTimeText,
  scheduledAt,
  scheduleTime,
  views,
}: OrderInformationProps) {
  return scheduledAt ? (
    <View
      type={views.primary}
      className={css["complete-content-timeContainer"]}
    >
      <Col xs="1">
        <Text type={labels.tertiary}>
          {Copy.CHECKOUT_STATIC.COMPLETE_SCHEDULE_DATE_HEADER_TEXT}
        </Text>
      </Col>
      <Col xs="1">
        <Text type={labels.time}>{scheduleTime}</Text>
      </Col>
    </View>
  ) : (
    <View
      type={views.primary}
      className={css["complete-content-timeContainer"]}
    >
      <div className={css["complete-content-timeContainer--top"]}>
        <div className={css["complete-content-timeContainer--info"]}>
          <div>
            <Text type={labels.tertiary}>{prepTimeText}</Text>
          </div>
          <div>
            <Text type={labels.time}>{deliveryTime}</Text>
          </div>
          <div
            className={css["complete-timeContainer--info"]}
            style={{ marginTop: "auto" }}
          >
            <Text type={labels.secondary}>{orderStatusDescription}</Text>
          </div>
        </div>
        <div className={css["complete-content-timeContainer--img"]}>
          <If is={images?.art_order_confirmation}>
            <Image
              src={images.art_order_confirmation}
              className={css["complete-content-img"]}
              alt="confirmation Image"
            />
          </If>
        </div>
      </div>
    </View>
  );
}

export { OrderInformation };
