import * as React from "react";
import { getDisplayName } from "utils/helpers/other";
import { ErrorBoundary } from "components/elements";

function withErrorBoundary<P>(
  Component: React.ComponentType<P>,
  Fallback: string | React.ReactNode | JSX.Element,
) {
  const returnedComponent = (props: P) => (
    <ErrorBoundary error={Fallback}>
      <Component {...props} />
    </ErrorBoundary>
  );

  returnedComponent.displayName = `withErrorBoundary(${getDisplayName(
    Component,
  )})`;

  return returnedComponent;
}

export default withErrorBoundary;
