import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { Layout } from "components/elements";
import { Text, View, Button } from "components/elementsThemed";
import { useTemplateContext } from "components/providers/Template";
import useSessionStorage from "hooks/useSessionStorage";

const { Flex } = Layout;

const Theme = () => {
  const { parsedTheme, theme } = useTemplateContext();
  const { labels, buttons, views } = parsedTheme;
  // eslint-disable-next-line no-unused-vars
  const [_, setInputSession] = useSessionStorage("lb-version", "");

  const storageSessionInput = React.useRef(null);

  const submitButton = (e) => {
    e.preventDefault();
    setInputSession(storageSessionInput.current.value);
    window.location.href = "/";
  };

  return (
    <Flex direction="col" justify="space" style={{ marginTop: "100px" }}>
      <Row>
        <input
          name="sessionStorageValue"
          ref={storageSessionInput}
          type="text"
        />
        <button onClick={submitButton} type="submit">
          Submit
        </button>
      </Row>

      <Row gutter={15} spacing={15}>
        {Object.entries(theme.colors).map(([key, value]) => (
          <Col sm="1-12" key={key} style={{ padding: "1rem" }}>
            <Flex align="center" justify="center">
              <div>{key}</div>
              <div
                style={{
                  backgroundColor: value,
                  height: "3rem",
                  width: "3rem",
                }}
              />
            </Flex>
          </Col>
        ))}
      </Row>

      <Row gutter={15}>
        {Object.entries(labels).map(([key, value]) => (
          <Col>
            <Text title={JSON.stringify(value, null, 2)} type={key}>
              {key}
            </Text>
          </Col>
        ))}
      </Row>

      <br />

      <Row gutter={15}>
        {Object.entries(buttons).map(([key]) => (
          <Col>
            <Button type={key}>{key}</Button>
          </Col>
        ))}
      </Row>

      <br />

      <Row gutter={15} spacing={15}>
        {Object.entries(views).map(([key]) => (
          <Col xs="1" sm="1-2" md="1-3" lg="1-4" style={{ height: "250px" }}>
            <View
              style={{ height: "100%" }}
              Component={Flex}
              type={key}
              grow={1}
              align="center"
              justify="center"
            >
              {key}
            </View>
          </Col>
        ))}
      </Row>
    </Flex>
  );
};

export default Theme;
