import * as React from "react";
import withNestedModifiers from "./withNestedModifiers";
import Type1 from "./Type1";

function NestedModifiers({ ...props }) {
  const Component = Type1;
  return <Component {...props} />;
}

export default React.memo(withNestedModifiers(NestedModifiers));
