import * as React from "react";
import { useMenuContext } from "components/providers/Menu/MenuContext";
import { populateMods } from "./utils";

const withConfirmItem = (Component) => (props) => {
  const { itemId, modifications, group } = props;
  const { name } = props;
  let { isGroup } = props;
  const { itemsHash, groupsHash, groups } = useMenuContext();
  const populatedMods = populateMods(modifications);

  if (isGroup === undefined) {
    isGroup = groups.findIndex((i) => i.subgroups.includes(group)) !== -1;
  }

  const entityInformation = isGroup ? groupsHash[group] : itemsHash[itemId];
  const entityName =
    typeof entityInformation !== "undefined" && entityInformation
      ? entityInformation.name
      : name || "";
  return (
    <Component
      {...props}
      name={entityName}
      isGroup={isGroup}
      populatedMods={populatedMods}
    />
  );
};

export default withConfirmItem;
