import * as React from "react";
import { config } from "utils";
import Type1 from "./Type1";
import { TabsType2 } from "./Type2/TabsType2";
import Type3 from "./Type3";

const Selector = (props) => {
  let Component = null;
  switch (config.components.tab) {
    case "Type1":
      Component = Type1;
      break;
    case "Type2":
      Component = TabsType2;
      break;
    case "Type3":
      Component = Type3;
      break;
    default:
      Component = Type1;
  }
  return <Component {...props} />;
};

export default Selector;
