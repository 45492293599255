/*
 * Cart Mini Page
 * Author: Isaiah Sanchez <isanchez@lunchbox.io>
 *
 * This component is rendered by pages/Checkout.
 * This component renders a minimal cart with no routes.
 */

import * as React from "react";
import { Condition, Layout } from "components/elements";
import { Empty, Loader } from "components/fragments";
import { Text, View, Button } from "components/elementsThemed";
import { withTemplate } from "components/hocs";
import { OrderItem as orderItemComponent } from "@lunchboxinc/lunchbox-components-v2/dist/templateComponents";
import { config, images, constants, Copy, Routes } from "utils";
import withCartItem from "pages/Cart/Layout/withCartItem";
import styles from "pages/Cart/cart.module.scss";
import OrderSummary from "../../Order/components/OrderSummary";
import Field from "../../Order/Field";
import ScheduleField from "../../Order/components/ScheduleField";

const OrderItem = withCartItem(orderItemComponent);
const { Condition: If } = Condition;
const { Flex } = Layout;
const {
  ORDER_TYPES: { KIOSK },
} = constants;

// Backend only support invalidItems noe
// invalidGroups and invalidItems are always empty for order/validate
const isValid = ({
  mods,
  group,
  item,
  invalidModifiers = [],
  invalidGroups = [],
  invalidItems = [],
}) => {
  return !(
    invalidModifiers.some((x) => mods.includes(x)) ||
    invalidGroups.includes(group) ||
    invalidItems.includes(item)
  );
};

const CartTab = ({
  style,
  history,
  orderContext,
  validateOrder,
  subtotal,
  ...props
}) => {
  // Constants
  const { cells, labels, buttons } = style;
  const {
    order: { diningOption, table },
    items,
  } = orderContext;

  // Resources
  const { fetching, resource } = validateOrder;

  // Actions
  const toScheduleDate = () => history.push(Routes.FETCH_SCHEDULE_DATE);
  const toEditTableNumber = () => history.push(Routes.SET_TABLE_NUMBER);

  if (!items?.length) {
    return (
      <View Component={Flex} justify="center" className={styles.cart}>
        <Empty imgSrc={images?.art_empty_cart} className={styles["cart-empty"]}>
          <Text type={labels.emptyCart}>{Copy.CART_STATIC.EMPTY_MESSAGE}</Text>
        </Empty>
      </View>
    );
  }

  if (fetching) {
    return (
      <View
        Component={Flex}
        className={styles.cart}
        direction="col"
        justify="center"
      >
        <Loader />
      </View>
    );
  }

  return (
    <>
      {diningOption === KIOSK ? (
        <Field
          type={cells.secondary}
          label={Copy.KIOSK_STATIC.CHECKOUT_TEXT}
          value={table.number || ""}
          buttonProps={{
            children: "Edit",
            onClick: toEditTableNumber,
          }}
        />
      ) : (
        <If is={config.ordering.scheduled}>
          <ScheduleField
            className={styles["cart-content--schedule"]}
            onClick={toScheduleDate}
            type={cells.secondary}
            validateOrder={validateOrder}
          />
        </If>
      )}

      <Button
        className={styles["cart-content--clear"]}
        type={buttons.clear}
        onClick={() => history.push("/delete/")}
      >
        {Copy.CART_STATIC.CLEAR_CART}
      </Button>

      <ul className={styles["cart-content--items"]}>
        {items.map((i, index) => (
          <OrderItem
            // eslint-disable-next-line
            key={`${i.item}${index}`}
            cellType={cells.primary}
            item={i.item}
            mods={i.mods}
            group={i.group}
            price={i.price}
            name={i.name}
            notes={i.notes}
            isGroup={i.isGroup}
            editable
            edit={() =>
              history.push({
                pathname: `/item/${i.item}/${index}`,
                state: { isGroup: i.isGroup || false },
              })
            }
            remove={() => history.push(`/delete/${index}`)}
            isValid={isValid({
              group: i.group,
              invalidGroups: resource.invalidGroups,
              invalidItems: resource.invalidItems,
              invalidModifiers: resource.invalidModifiers,
              item: i.item,
              mods: i.mods,
            })}
          />
        ))}
      </ul>
      <OrderSummary
        orderContext={orderContext}
        showDiscount={false}
        showTip={false}
        showTax={false}
        showTotal={false}
        {...props}
      />
    </>
  );
};

export default withTemplate(CartTab, "cart");
