export const DEFAULT_TIER_DATA = {
  hasAchieved: false,
  name: "",
  threshold: 0,
};
export const DEFAULT_PROGRESS_DATA = {
  tier: {
    availableCredit: 0,
    expirationDate: "",
    lifetimePoints: 0,
    progress: 0,
    threshold: 100,
  },
};
export const DEFAULT_WALLET_DATA = {
  expirationDate: "",
  id: "",
  promotion: null,
};
export const INITIAL_DATA = {
  availableCredit: 0,
  loyaltyDivisor: 100,
  loyaltyMultiplier: 0,
  progress: {},
  tier: [],
  totalSpent: 0,
  wallet: [],
};

export default INITIAL_DATA;
