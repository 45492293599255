import * as React from "react";
import { QuantitySelector } from "components/fragments";
import { View } from "components/elementsThemed";
import { useCell } from "hooks";
import DrawerButton from "../../../../DrawerButton";

function Footer({
  showQuantity,
  quantityType,
  quantity,
  hasQuantityLabel,
  incQuantity,
  decQuantity,
  onClick,
  buttonText,
  name,
  "data-cy": dataCy,
  isDisabled,
}) {
  const { views, buttons, labelTextStyles } = useCell(quantityType);
  return (
    <View type={views.background} style={{ position: "relative" }}>
      {showQuantity && (
        <QuantitySelector
          name={name}
          hasQuantityLabel={hasQuantityLabel}
          textLabelType={labelTextStyles.secondary}
          viewType={views.secondary}
          buttonType={buttons.primary}
          labelType={labelTextStyles.primary}
          quantity={quantity}
          inc={incQuantity}
          dec={decQuantity}
        />
      )}
      <DrawerButton buttonProps={{ onClick, disabled: isDisabled, dataCy }}>
        {buttonText}
      </DrawerButton>
    </View>
  );
}

export default Footer;
