import * as React from "react";
import { useId } from "@react-aria/utils";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Text } from "../../Text";
import FieldItem from "../fieldItem";
import withTemplateInput from "../withTemplateInput";
import css from "./textarea.module.scss";

function Textarea({
  input,
  label,
  name,
  className,
  icon,
  style,
  onChange,
  value,
  inputRef,
  disabled,
  error,
  htmlType,
  resizable,
  ...props
}) {
  const classes = classnames(
    css[props.type],
    css.textarea,
    !resizable && css["resize-none"],
    className,
  );
  const attributeId = useId(props.id);
  const valueLength = value.length;

  return (
    <FieldItem
      type={input.title}
      label={label}
      htmlFor={props.id || attributeId}
    >
      <div className={css["textarea-container"]}>
        <textarea
          id={attributeId}
          {...props}
          name={name || ""}
          type={htmlType}
          className={valueLength >= 1000 ? css["textarea-exceedArea"] : classes}
          value={value}
          style={style}
          onChange={onChange}
          placeholder={props.placeholder}
          ref={inputRef}
          disabled={disabled}
        />
        {valueLength >= 1000 && (
          <div className={css["textarea-exceedText"]}>
            This note exceeds the 1000 character limit.
          </div>
        )}
        {icon && <div className={css["textarea-suffix"]}>{icon}</div>}
      </div>
      {error && <Text type={input.error}>{error}</Text>}
    </FieldItem>
  );
}

Textarea.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  resizable: PropTypes.bool,
  value: PropTypes.string,
};
Textarea.defaultProps = {
  name: "",
  onChange: () => null,
  resizable: true,
  value: "",
};

export default React.memo(withTemplateInput(Textarea));
