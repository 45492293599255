import * as React from "react";
import classnames from "classnames";
import { Formik, Form } from "formik";
import { ElementsThemed } from "components";
import { HANDLE_ERROR, VALIDATE_GIFTCARD } from "utils/api";
import { constants, Copy } from "utils";
import Field from "pages/Order/Field";
import {
  GiftCardWithPasswordSchema,
  GiftCardWithoutPasswordSchema,
} from "utils/Schemas/giftCardSchema";
import { useCell } from "hooks/useTemplate";
import commonStyles from "../../../index.module.scss";
import styles from "./giftCardField.module.scss";
import type { GiftCard, GiftCardComponentProps } from "../util";
import { getAppliedGiftCardText } from "../util";

const {
  View,
  Text,
  Field: { Input },
  Button,
} = ElementsThemed;

const { GOOGLE_RECAPTCHA_KEY } = constants;

interface GiftCardFieldProps extends GiftCardComponentProps {
  onGiftCardRemoved(): void;
  showCheckGiftCardBalance?: boolean;
  giftCard?: GiftCard;
}
function GiftCardField({
  onGiftCardAdded,
  onGiftCardRemoved,
  giftCard,
  isPinCodeRequired,
  showCheckGiftCardBalance,
}: GiftCardFieldProps) {
  const formRef = React.useRef(null);
  const isGiftCardAdded = giftCard?.giftCardNumber;
  const { buttons, views, labelTextStyles: labels } = useCell("giftCardField");

  const validateCode = async (
    { giftCardNumber, giftCardPassword },
    actions,
  ) => {
    actions.setStatus("");

    try {
      const userToken = await new Promise((resolve) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(GOOGLE_RECAPTCHA_KEY, { action: "submit" })
            .then((token) => resolve(token));
        });
      });

      const payload = {
        userToken,
        ...(isPinCodeRequired ? { pinCode: giftCardPassword } : {}),
      };
      const { data } = await VALIDATE_GIFTCARD(giftCardNumber, payload);

      if (data?.isValid) {
        onGiftCardAdded({
          balance: data?.balance,
          giftCardNumber,
          ...(isPinCodeRequired ? { pinCode: giftCardPassword } : {}),
        });
      } else {
        throw Error(Copy.CHECKOUT_STATIC.INVALID_GIFT_CARD_RESPONSE_TEXT);
      }
    } catch (error) {
      const e = HANDLE_ERROR(error);
      actions.setStatus(e.data);
    } finally {
      actions.setSubmitting(false);
    }
  };

  React.useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_KEY}`;
    document.body.appendChild(script);
    // add g-recaptcha class to whichever element you want to track for bot activity
    const buttonNode = document.querySelector(".g-recaptcha");
    if (buttonNode) {
      buttonNode.addEventListener("onClick", () => {
        validateCode(
          {
            giftCardNumber: formRef.current.values.giftCardNumber,
            giftCardPassword: formRef.current.values.giftCardPassword,
          },
          {
            setStatus: formRef.current.setStatus,
            setSubmitting: formRef.current.setSubmitting,
          },
        );
      });
    }
  }, []);

  return (
    <Formik
      initialValues={{
        giftCardNumber: "",
        giftCardPassword: "",
      }}
      validationSchema={
        isPinCodeRequired
          ? GiftCardWithPasswordSchema
          : GiftCardWithoutPasswordSchema
      }
      validateOnChange={false}
      onSubmit={validateCode}
      innerRef={formRef}
    >
      {({ errors, values, status, setFieldValue, isSubmitting, setStatus }) => (
        <View
          type={views.background}
          className={classnames(
            commonStyles.container,
            styles.giftCardFieldWrapper,
          )}
          Component={Form}
        >
          {isGiftCardAdded ? (
            <Field
              type="giftCardField"
              label={Copy.CHECKOUT_STATIC.GIFT_CARD_INPUT_LABEL}
              value={getAppliedGiftCardText(giftCard)}
              buttonProps={{
                children: Copy.CHECKOUT_STATIC.GIFT_CARD_REMOVE_BUTTON_TEXT,
                onClick: onGiftCardRemoved,
              }}
            />
          ) : (
            <>
              <Input
                className={styles["fields-input"]}
                label={Copy.CHECKOUT_STATIC.GIFT_CARD_INPUT_LABEL}
                name="giftCardNumber"
                type="input_giftCardField"
                error={errors.giftCardNumber}
                value={values.giftCardNumber}
                placeholder={Copy.CHECKOUT_STATIC.GIFT_CARD_INPUT_PLACEHOLDER}
                onChange={(e) => {
                  setStatus("");
                  setFieldValue(e.target.name, e.target.value);
                }}
                icon={
                  !isPinCodeRequired && (
                    <Button
                      className={classnames(
                        "g-recaptcha",
                        styles["fields-button"],
                      )}
                      type={buttons.primary}
                      htmlType="submit"
                      disabled={isSubmitting}
                    >
                      {Copy.CHECKOUT_STATIC.GIFT_CARD_APPLY_BUTTON_TEXT}
                    </Button>
                  )
                }
              />
              {isPinCodeRequired && !isGiftCardAdded && (
                <Input
                  className={styles["fields-input"]}
                  label={Copy.CHECKOUT_STATIC.GIFT_CARD_PINCODE_INPUT_LABEL}
                  name="giftCardPassword"
                  type="input_giftCardField"
                  error={errors.giftCardPassword}
                  value={values.giftCardPassword}
                  placeholder={
                    Copy.CHECKOUT_STATIC.GIFT_CARD_PINCODE_INPUT_PLACEHOLDER
                  }
                  onChange={(e) => {
                    setStatus("");
                    setFieldValue(e.target.name, e.target.value);
                  }}
                  icon={
                    <Button
                      className={classnames(
                        "g-recaptcha",
                        styles["fields-button"],
                      )}
                      type={buttons.primary}
                      htmlType="submit"
                      disabled={isSubmitting}
                    >
                      {Copy.CHECKOUT_STATIC.GIFT_CARD_APPLY_BUTTON_TEXT}
                    </Button>
                  }
                />
              )}
              {status && <Text type={labels.tertiary}>{status}</Text>}
            </>
          )}
          {isPinCodeRequired && showCheckGiftCardBalance && (
            // TODO: Fix this logic to handle balance checking link for different service providers instead of using isPinCodeRequired as indicator
            <Text type={labels.secondary}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.storecard.com/VT"
              >
                Check gift card balance
              </a>
            </Text>
          )}
        </View>
      )}
    </Formik>
  );
}

GiftCardField.defaultProps = {
  showCheckGiftCardBalance: false,
  giftCard: undefined,
};

export default GiftCardField;
