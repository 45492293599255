import * as React from "react";
import { useHistory } from "react-router-dom";

import DrawerButton from "../../DrawerButton";
import { Text, View } from "../../elementsThemed";
import { withTemplate } from "../../hocs";
import { Copy } from "../../../utils";

import styles from "./ForgotPassword.module.scss";

const BackToLogin = ({ style }) => {
  const history = useHistory();

  return (
    <View type={style.views.background} className={styles.forgetPassword}>
      <div className={styles["forgetPassword-status"]}>
        <Text type={style.labels.confirm}>
          {Copy.FORGOT_PASSWORD_STATIC.CONFIRM_PIN_STATUS_TEXT}
        </Text>
      </div>

      <DrawerButton
        buttonProps={{ onClick: () => history.push("/"), htmlType: "button" }}
      >
        {Copy.FORGOT_PASSWORD_STATIC.BACK_TO_LOGIN_BUTTON_TEXT}
      </DrawerButton>
    </View>
  );
};

export default withTemplate(BackToLogin, "password");
