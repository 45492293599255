import { constants } from "utils";
import { eventHandler } from "../tools";

const {
  ANALYTICS: { CHECKOUT_STARTED, PURCHASE },
} = constants;

export default {
  completed: eventHandler(PURCHASE),
  started: eventHandler(CHECKOUT_STARTED),
};
