import * as React from "react";
import { Field, Text } from "components/elementsThemed";
import { useId } from "@react-aria/utils";
import { useTemplate } from "hooks/template";

const { Textarea } = Field;

type NotesProps = {
  label?: string;
  description?: string;
  placeholder?: string;
  onChange?: () => void;
  value?: string;
};

function Notes({ label, description, placeholder, ...props }: NotesProps) {
  const textAreaId = useId();
  const descriptionId = useId();
  const template = useTemplate("itemDetails");

  return (
    <Text type={template.inputs.standard}>
      <label htmlFor={textAreaId}>{label}</label>
      <p id={descriptionId}>{description}</p>
      <Textarea
        id={textAreaId}
        aria-describedby={descriptionId}
        placeholder={placeholder}
        name="notes"
        resizable={false}
        rows={4}
        {...props}
      />
    </Text>
  );
}

Notes.defaultProps = {
  label: "",
  description: "",
  placeholder: "",
  onChange: null,
  value: "",
};

export default Notes;
