import * as React from "react";
import { config } from "utils";
import Type1 from "./Type1";
import Type2 from "./Type2";
import Type3 from "./Type3";
import Type4 from "./Type4";

const Selector = (props) => {
  let configValue;
  switch (props.type) {
    case "card":
      configValue = config.theme.profile.empty;
      break;
    default:
      configValue = config.components.empty;
  }

  let Component = Type1;
  switch (configValue) {
    case "Type2":
      Component = Type2;
      break;
    case "Type3":
      Component = Type3;
      break;
    case "Type4":
      Component = Type4;
      break;
    default:
      Component = Type1;
  }

  return <Component {...props} />;
};

export default Selector;
