import { Copy } from "utils";

const validationError =
  (dispatch) =>
  ({ message, buttonText }) => {
    dispatch({
      payload: {
        buttonText,
        message,
      },
      type: "VALIDATION_ERROR",
    });
  };

const clearErrors = (dispatch) => () => {
  dispatch({
    type: "CLEAR_ERROR",
  });
};

const serverError = (dispatch) => () => {
  dispatch({
    payload: {
      buttonText: null,
      message: Copy.CART_STATIC.SYSTEM_ERROR_MESSAGE,
    },
    type: "SERVER_ERROR",
  });
};

export default (dispatch) => ({
  clearErrors: clearErrors(dispatch),
  serverError: serverError(dispatch),
  validationError: validationError(dispatch),
});
