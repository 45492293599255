import * as React from "react";
import csx from "classnames";
import { Condition } from "components/elements";
import { Text, View, Radio, Button } from "components/elementsThemed";
import { useCell } from "hooks";
import { formatPrice } from "utils/helpers/string";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import css from "./item.module.scss";

const { Condition: If } = Condition;

const Item = ({
  className,
  decrement,
  increment,
  item,
  multiSelect,
  nestedItems,
  onClick,
  quantity,
  type,
}) => {
  const { isNested = false, name = "", price = 0 } = item;
  const { buttons, labelTextStyles, views } = useCell(type);

  return multiSelect ? (
    <View
      Component="li"
      className={`${css.modifierItem} ${className}`}
      data-test={name}
      data-cy="modifier-add-button"
      type={views.background}
    >
      <div className={css["modifierItem-container"]}>
        <button
          aria-label={`${name}${price > 0 ? ` $${price}` : ""}`}
          aria-pressed={quantity === 0 ? "false" : "true"}
          type="button"
          onClick={onClick}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            height: "100%",
            pointerEvents: quantity === 0 ? "auto" : "none",
            position: "absolute",
            width: "100%",
          }}
          id={name}
        />
        <div className={css["modifierItem-name"]}>
          <Text
            aria-hidden="true"
            className={css.name}
            type={labelTextStyles.primary}
          >
            {isNested && quantity > 1 && `${quantity} x `}
            {name}
          </Text>
        </div>
        <div className={css["modifierItem-content"]}>
          <If is={price}>
            <Text
              aria-hidden="true"
              className={css["modifierItem-content--price"]}
              type={labelTextStyles.secondary}
            >
              {formatPrice(price)}
            </Text>
          </If>
          <If is={!isNested}>
            <div
              className={csx(
                css["modifierItem-selectors"],
                quantity && css["modifierItem-selected"],
              )}
            >
              <If is={quantity !== 0}>
                <Button
                  aria-label={`Decrease ${name} quantity`}
                  type={buttons.unselected}
                  className={css["modifierItem-selectors-quantityBtn"]}
                  onClick={decrement}
                >
                  <div aria-hidden="true">-</div>
                </Button>
                <VisuallyHidden>
                  <span aria-live="polite">
                    {name} quantity: {quantity}
                  </span>
                </VisuallyHidden>
              </If>
              <Button
                aria-label={`Increase ${name} quantity`}
                type={quantity ? buttons.selected : buttons.unselected}
                className={csx(css["modifierItem-selectors-quantityBtn"], {
                  [css["modifierItem-selectors-quantityBtn--plus"]]:
                    quantity === 0,
                })}
                onClick={quantity > 0 ? increment : onClick}
                tabindex={quantity > 0 ? "0" : "-1"}
                aria-hidden={!(quantity > 0)}
              >
                <div>
                  {quantity > 0 && <div aria-hidden="true">{quantity}</div>}
                </div>
              </Button>
            </div>
          </If>
          <If is={isNested}>
            <button
              aria-pressed={quantity > 0}
              type="button"
              className={css["modifierItem-selectors--nested"]}
              onClick={quantity ? decrement : onClick}
              active={quantity > 0 ? "1" : "0"}
              style={{
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                height: "100%",
                pointerEvents: quantity === 0 ? "auto" : "none",
                position: "absolute",
                width: "100%",
              }}
            >
              <VisuallyHidden>
                {name}
                <If is={isNested && quantity > 0}>
                  <ul>
                    {Object.values(nestedItems).map((i, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={`${i}-${index}`}>
                        {i?.quantity} x {i?.name}
                      </li>
                    ))}
                  </ul>
                </If>
              </VisuallyHidden>
            </button>
          </If>
          <If is={isNested && quantity > 0}>
            <ul className={css["modifierItem-nestedItems"]}>
              {Object.values(nestedItems).map((i, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`${i}-${index}`}>
                  <Text type={labelTextStyles?.secondary}>
                    {i?.quantity} x {i?.name}
                  </Text>
                </li>
              ))}
            </ul>
          </If>
        </div>
      </div>
    </View>
  ) : (
    <View
      Component="li"
      className={`${css.modifierItem} ${className}`}
      data-test={name}
      datatest="modifier-add-button"
      onClick={onClick}
      type={views.background}
    >
      <div className={css["modifierItem-container"]}>
        <div className={css["modifierItem-name"]}>
          <Text
            aria-hidden="true"
            className={css.name}
            type={labelTextStyles.primary}
          >
            {isNested && quantity > 1 && `${quantity} x `}
            {name}
          </Text>
        </div>
        <div className={css["modifierItem-content"]}>
          <If is={price}>
            <Text
              className={css["modifierItem-content--price"]}
              type={labelTextStyles.secondary}
            >
              {formatPrice(price)}
            </Text>
          </If>
          <If is={!isNested}>
            <Radio
              type={buttons.unselectedSolid}
              className={css["modifierItem-selectors-quantityBtn--radio"]}
              value={quantity}
              onClick={onClick}
            >
              <VisuallyHidden>
                {isNested && quantity > 1 && `${quantity} x `}
                {name}
              </VisuallyHidden>
            </Radio>
          </If>
        </div>
        <If is={isNested}>
          <button
            type="button"
            aria-pressed={quantity > 0}
            className={css["modifierItem-selectors--nested"]}
            onClick={quantity ? decrement : onClick}
            active={quantity > 0 ? "1" : "0"}
            style={{
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              height: "100%",
              pointerEvents: quantity === 0 ? "auto" : "none",
              position: "absolute",
              width: "100%",
            }}
          >
            <VisuallyHidden>
              {name}
              <If is={isNested && quantity > 0}>
                <ul>
                  {Object.values(nestedItems).map((i, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`${i}-${index}`}>
                      {i?.quantity} x {i?.name}
                    </li>
                  ))}
                </ul>
              </If>
            </VisuallyHidden>
          </button>
        </If>
      </div>
      <If is={isNested && quantity > 0}>
        <ul className={css["modifierItem-nestedItems"]}>
          {Object.values(nestedItems).map((i, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`${i}-${index}`}>
              <Text type={labelTextStyles?.secondary}>
                {i?.quantity} x {i?.name}
              </Text>
            </li>
          ))}
        </ul>
      </If>
    </View>
  );
};

export default React.memo(Item);
