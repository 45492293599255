/* eslint-disable react/require-default-props */
import * as React from "react";
import classnames from "classnames";
import { Button as BaseButton } from "../../elements";
import styles from "./Button.module.scss";

interface ThemeButtonProps {
  "aria-checked"?: boolean | "false" | "mixed" | "true";
  block?: boolean;
  button?: any; // FIXME: Uncertain of button property.
  children?: React.ReactNode;
  className?: string;
  Component?: React.ComponentType<any> | string;
  htmlType?: "button" | "submit";
  disabled?: boolean;
  style?: React.CSSProperties;
  innerRef?: React.RefObject<HTMLLIElement>;
  onClick?: (e: React.MouseEvent) => void;
  role?: string;
  size?: string; // FIXME: Should be specific sizes.
  type?: string;
}

const UnmemoizedButton: React.FC<ThemeButtonProps> = ({
  type,
  children,
  className,
  innerRef,
  button,
  Component = BaseButton,
  ...props
}: ThemeButtonProps) => {
  const classes = classnames(styles.base, className);

  return (
    <Component className={classes} {...props} ref={innerRef} type={type}>
      <span>{children}</span>
    </Component>
  );
};

const Button = React.memo(UnmemoizedButton);

export { Button };
export default Button;
