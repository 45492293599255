import * as React from "react";
import { Text, View } from "components/elementsThemed";
import { useCell } from "hooks";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import css from "./option.module.scss";

function Option({
  error,
  forwardRef,
  optionRef,
  id,
  modifierItems,
  name,
  type,
}) {
  const { labelTextStyles, views } = useCell(type);

  return (
    <View type={views} Component="ul" className={css.option} data-id={id}>
      <div className={css["option-title"]} ref={optionRef}>
        <Text type={labelTextStyles.primary}>{name}</Text>
        <div aria-live="polite">
          {error && (
            <div className="error" ref={forwardRef}>
              <Text type={labelTextStyles.tertiary}>
                <VisuallyHidden>Error: </VisuallyHidden>
                {error}
              </Text>
            </div>
          )}
        </div>
      </div>
      {modifierItems}
    </View>
  );
}

Option.displayName = "OptionType1";

export default React.memo(Option);
