export const isObjEmpty = (obj: object = {}) => JSON.stringify(obj) === "{}";

export const addToObj = (x: string): { x: string } | false =>
  typeof x === "string" && { x };

/**
 * This is transforming each of the Types into testable regex like
 * /(baseBlack\b)|(baseWhite\b)|(baseGray\b)|(alternateGray\b)|(backgroundGray\b)|(baseWarning\b)|(accentLight\b)|(baseLink\b)/
 * for example. Where \b allows us to match the entire word
 *
 * @param obj
 * @returns RegExp
 */
export const objToRegex = (obj: object): RegExp =>
  new RegExp([...Object.keys(obj)].map((key) => `(^${key}\\b)`).join("|"));
