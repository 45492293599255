import { DefaultTheme } from "styled-components";
import type { ThemeFile } from "../@types/theme";

const theme = {
  fonts: {
    title1: {
      name: "Gotham-Black",
      size: 32,
    },
    title2: {
      name: "Gotham-Black",
      size: 22,
    },
    title3: {
      name: "Gotham-Ultra",
      weight: 700,
      size: 24,
    },
    title4: {
      name: "Gotham-Black",
      weight: 700,
      size: 18,
    },
    title5: {
      name: "Gotham-Black",
      weight: 600,
      size: 16,
    },
    title6: {
      name: "Gotham-Black",
      weight: 600,
      size: 14,
    },
    subheader1: {
      name: "Gotham-Medium",
      weight: 400,
      size: 14,
    },
    subtitle1: {
      name: "Gotham-Medium",
      weight: 600,
      size: 14,
    },
    subtitle2: {
      name: "Gotham-Medium",
      weight: 600,
      size: 20,
    },
    subtitle3: {
      name: "Gotham-Medium",
      weight: 600,
      size: 28,
    },
    body1: {
      name: "Gotham-Medium",
      weight: 400,
      size: 16,
    },
    body2: {
      name: "Gotham-Medium",
      weight: 400,
      size: 18,
    },
    body3: {
      name: "Gotham-Medium",
      weight: 400,
      size: 14,
    },
  },
  colors: {
    baseBlack: "172B29",
    baseWhite: "FFFFFF",
    baseGray: "7A7A7A",
    lightGray: "F5F5F5",
    alternateGray: "b3b3b3",
    backgroundGray: "F4F4F4",
    baseWarning: "FF2A00",
    accentDark: "FF00A5",
    accentLight: "FF96D1",
    baseLink: "4A90E2",
  },
  elements: {
    buttons: {
      buttonPrimaryStandard: {
        stateTextStyles: {
          unselected: "title5_baseWhite",
          selected: "title5_baseWhite",
          disabled: "title5_baseGray",
        },
        stateBackgroundColors: {
          unselected: "accentDark",
          selected: null,
          disabled: "lightGray",
        },
        view: "roundedBase",
      },
      buttonSecondaryStandard: {
        stateTextStyles: {
          unselected: "title5_baseGray",
          selected: "title5_accentDark",
          disabled: "title5_baseGray",
        },
        stateBackgroundColors: {
          unselected: "lightGray",
          selected: null,
          disabled: "lightGray",
        },
        view: "roundedBaseBlack",
      },
      buttonSecondaryWhite: {
        stateTextStyles: {
          unselected: "title3_accentDark",
          selected: "title3_baseWhite",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: "baseWhite",
          selected: "accentDark",
          disabled: null,
        },
        view: null,
      },
      buttonNavDropDown: {
        stateTextStyles: {
          unselected: "subheader1_accentDark",
          selected: "subheader1_baseWhite",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: "baseWhite",
          selected: "accentDark",
          disabled: null,
        },
        view: null,
      },
      buttonSecondaryGray: {
        stateTextStyles: {
          unselected: "body1_baseGray",
          selected: "body1_baseWhite",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: "baseWhite",
          selected: "accentLight",
          disabled: null,
        },
        view: null,
      },
      buttonPrimaryWhite: {
        stateTextStyles: {
          unselected: "title3_accentDark",
          selected: "title3_baseWhite",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: "baseWhite",
          selected: "baseBlack",
          disabled: null,
        },
        view: null,
      },
      buttonSecondaryBorderless: {
        stateTextStyles: {
          unselected: "title4_accentDark",
          selected: null,
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: null,
          disabled: null,
        },
        view: "baseGrayBordered",
      },
      buttonSecondaryAccentDark: {
        stateTextStyles: {
          unselected: "subtitle1_accentDark",
          selected: "subtitle1_baseWhite",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: "accentDark",
          disabled: null,
        },
        view: "baseAccentDarkBordered",
      },
      buttonTertiaryGray: {
        stateTextStyles: {
          unselected: "body1_baseGray",
          selected: "body1_baseWhite",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: "baseGray",
          disabled: null,
        },
        view: "roundedBaseGrayBordered",
      },
      buttonTertiaryAccent: {
        stateTextStyles: {
          unselected: "body1_accentDark",
          selected: "body1_baseWhite",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: "accentDark",
          disabled: null,
        },
        view: "roundedAccentClearBackground",
      },
      buttonTertiaryBorderless: {
        stateTextStyles: {
          unselected: "body1_baseGray",
          selected: null,
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: null,
          disabled: null,
        },
        view: null,
      },
      buttonTertiaryBorderlessAccent: {
        stateTextStyles: {
          unselected: "body1_accentDark",
          selected: null,
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: null,
          disabled: null,
        },
        view: null,
      },
      buttonTertiaryBorderlessAccentLight: {
        stateTextStyles: {
          unselected: "body1_accentLight",
          selected: null,
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: null,
          disabled: null,
        },
        view: null,
      },
      buttonTertiaryLink: {
        stateTextStyles: {
          unselected: "body1_baseLink",
          selected: null,
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: null,
          disabled: null,
        },
        view: null,
      },
      buttonUnaryClear: {
        stateTextStyles: {
          unselected: "title3_accentDark",
          selected: "title3_baseWhite",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: "baseBlack",
          disabled: null,
        },
        view: "roundedBlackClearBackground",
      },
      buttonUnaryClearRound: {
        stateTextStyles: {
          unselected: "title3_baseBlack",
          selected: "title3_baseWhite",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: "baseBlack",
          disabled: null,
        },
        view: "circleBlackClearBackground",
      },
      buttonUnaryClearSmall: {
        stateTextStyles: {
          unselected: "body1_accentDark",
          selected: "body1_baseWhite",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: "baseBlack",
          disabled: null,
        },
        view: "roundedBlackClearBackground",
      },
      buttonUnaryDark: {
        stateTextStyles: {
          unselected: "title3_baseWhite",
          selected: "title3_accentDark",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: "baseBlack",
          selected: "baseWhite",
          disabled: null,
        },
        view: "roundedBlackClearBackground",
      },
      buttonUnaryAccent: {
        stateTextStyles: {
          unselected: "title3_baseWhite",
          selected: "title3_baseWhite",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: "accentDark",
          selected: "accentDark",
          disabled: null,
        },
        view: "roundedAccentDark",
      },
      buttonUnaryAccentCircle: {
        stateTextStyles: {
          unselected: "title3_baseWhite",
          selected: "title3_accentLight",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: "accentDark",
          selected: "baseWhite",
          disabled: null,
        },
        view: "unaryAccentDarkCircle",
      },
      buttonUnaryLightCircle: {
        stateTextStyles: {
          unselected: "title3_accentLight",
          selected: "title3_baseWhite",
          disabled: "title3_alternateGray",
        },
        stateBackgroundColors: {
          unselected: "baseWhite",
          selected: "accentLight",
          disabled: "baseWhite",
        },
        view: "unaryAccentLightCircle",
      },
      buttonUnaryWarning: {
        stateTextStyles: {
          unselected: "title3_baseWarning",
          selected: "title3_baseWhite",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: "baseWhite",
          selected: "baseWarning",
          disabled: null,
        },
        view: "roundedWarningClearBackground",
      },
      buttonUnaryGray: {
        stateTextStyles: {
          unselected: "title5_baseGray",
          selected: "title5_accentDark",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: "baseWhite",
          selected: "backgroundGray",
          disabled: null,
        },
        view: "circleGray",
      },
      buttonToggleStandard: {
        stateTextStyles: {
          unselected: "title2_baseGray",
          selected: "title2_baseGray",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: "accentDark",
          disabled: null,
        },
        view: "circleToggleStandard",
      },
      buttonNavStandard: {
        stateTextStyles: {
          unselected: "title4_baseWhite",
          selected: "title4_baseWhite",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: "accentDark",
          disabled: null,
        },
        view: "circleAccentLight",
      },
      buttonItemNavStandard: {
        stateTextStyles: {
          unselected: "title2_baseGray",
          selected: "title2_baseGray",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: null,
          disabled: null,
        },
        view: null,
      },
      buttonWhite: {
        stateTextStyles: {
          unselected: "title2_baseWhite",
          selected: "title2_baseWhite",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: null,
          disabled: null,
        },
        view: null,
      },
      buttonGrayClear: {
        stateTextStyles: {
          unselected: "subheader1_alternateGray",
          selected: "subheader1_alternateGray",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: null,
          disabled: null,
        },
        view: "base",
      },
      buttonBlackClear: {
        stateTextStyles: {
          unselected: "title4_baseGray",
          selected: "title4_baseGray",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: null,
          disabled: null,
        },
        view: "baseBackgroundGray",
      },
      buttonAccentClear: {
        stateTextStyles: {
          unselected: "title4_accentDark",
          selected: "title4_accentDark",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: null,
          disabled: null,
        },
        view: "baseBackgroundGray",
      },
      buttonAccentDarkRound: {
        stateTextStyles: {
          unselected: "subtitle1_baseWhite",
          selected: "subtitle1_baseWhite",
          disabled: "subtitle1_baseWhite",
        },
        stateBackgroundColors: {
          unselected: "accentLight",
          selected: "accentDark",
          disabled: null,
        },
        view: "circleAccentDarkBackground",
      },
      buttonAccentDarkClear: {
        stateTextStyles: {
          unselected: "title6_accentDark",
          selected: "title6_accentDark",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: null,
          selected: null,
          disabled: null,
        },
        view: "base",
      },
    },
    cells: {
      accountDrawer: {
        labelTextStyles: {
          primary: "title3_baseGray",
          secondary: null,
          tertiary: null,
        },
        buttons: {
          primary: "buttonPrimaryStandard",
        },
        views: {
          background: "base",
        },
      },
      location: {
        labelTextStyles: {
          primary: "title3_accentDark",
          secondary: "body1_baseGray",
          tertiary: "title5_baseGray",
          quaternary: "body1_baseGray",
          quinary: "body1_baseBlack",
        },
        buttons: {
          primary: "buttonUnaryAccent",
        },
        views: {
          background: "roundedBase",
          primary: "roundedBase",
          secondary: null,
        },
      },
      locationInfo: {
        labelTextStyles: {
          primary: "title4_accentDark",
          secondary: "body1_baseBlack",
          tertiary: null,
        },
        buttons: null,
        views: {
          background: "base",
          secondary: null,
        },
      },
      searchResults: {
        labelTextStyles: {
          primary: "title2_accentDark",
          secondary: "title2_accentDark",
          tertiary: null,
        },
        buttons: null,
        views: {
          background: "base",
          secondary: null,
        },
      },
      mapInformation: {
        labelTextStyles: {
          primary: "title1_accentDark",
          secondary: "subheader1_accentDark",
          tertiary: "body1_baseGray",
        },
        buttons: null,
        views: {
          background: "base",
          secondary: null,
        },
      },
      orderHeader: {
        labelTextStyles: {
          primary: "title2_accentDark",
          secondary: null,
          tertiary: "body1_baseWarning",
        },
        buttons: {
          primary: "buttonNavStandard",
        },
        views: {
          background: "accentLight",
          secondary: null,
        },
      },
      orderItem: {
        labelTextStyles: {
          primary: "title3_accentDark",
          secondary: "body1_baseGray",
          tertiary: "body1_baseGray",
        },
        buttons: null,
        views: {
          background: "roundedBase",
          secondary: null,
        },
      },
      orderItemMobile: {
        labelTextStyles: {
          primary: "title3_accentDark",
          secondary: "body1_baseGray",
          tertiary: "body1_baseGray",
        },
        buttons: null,
        views: {
          background: "roundedBase",
          secondary: null,
        },
      },
      menuGroup: {
        labelTextStyles: {
          primary: "title3_accentDark",
          secondary: "body2_baseGray",
          tertiary: null,
        },
        buttons: null,
        views: {
          background: "lightGray",
          secondary: null,
        },
      },
      cartItem: {
        labelTextStyles: {
          primary: "title3_baseGray",
          secondary: "body3_baseGray",
          tertiary: "body3_baseGray",
        },
        buttons: {
          primary: "buttonAccentDarkClear",
          secondary: "buttonGrayClear",
        },
        views: {
          background: "roundedBase",
          secondary: null,
        },
      },
      upsellItem: {
        labelTextStyles: {
          primary: "title4_baseGray",
          secondary: "body1_baseGray",
          tertiary: "body3_baseGray",
        },
        buttons: {
          primary: "buttonSecondaryBorderless",
        },
        views: {
          background: "roundedBase",
          secondary: null,
        },
      },
      history: {
        labelTextStyles: {
          primary: "body1_baseGray",
          secondary: "subtitle1_accentDark",
          tertiary: null,
        },
        buttons: {
          primary: "buttonSecondaryBorderless",
        },
        views: {
          background: "base",
          secondary: null,
        },
      },
      optionItem: {
        labelTextStyles: {
          primary: "title6_baseGray",
          secondary: "body1_baseGray",
          tertiary: "body1_baseGray",
        },
        buttons: {
          primary: "buttonToggleStandard",
        },
        views: {
          background: "base",
          secondary: "circleAccentDark",
        },
      },
      optionItemSelected: {
        labelTextStyles: {
          primary: "title6_baseGray",
          secondary: "body1_accentDark",
          tertiary: "title2_baseWhite",
        },
        buttons: {
          primary: "buttonItemNavStandard",
        },
        views: {
          background: "baseAccentBordered",
          secondary: "circleAccentDark",
        },
      },
      itemDetail: {
        labelTextStyles: {
          primary: "title1_accentDark",
          secondary: "body1_baseGray",
          tertiary: "body1_baseGray",
        },
        buttons: {
          primary: "buttonAccentDarkRound",
        },
        views: {
          background: null,
          secondary: null,
        },
      },
      optionHeader: {
        labelTextStyles: {
          primary: "title2_accentDark",
          secondary: "title3_accentDark",
          tertiary: "subtitle1_baseWarning",
        },
        buttons: null,
        views: {
          background: null,
          secondary: null,
        },
      },
      cartHeader: {
        labelTextStyles: {
          primary: "title3_baseGray",
          secondary: "body1_accentDark",
          tertiary: "subtitle2_baseBlack",
        },
        buttons: null,
        views: {
          background: null,
          secondary: null,
        },
      },
      form: {
        labelTextStyles: {
          primary: "title6_accentDark",
          secondary: "body1_baseGray",
          tertiary: null,
        },
        buttons: {
          primary: "buttonTertiaryBorderless",
        },
        views: {
          background: null,
          secondary: null,
        },
      },
      formUnaryAccent: {
        labelTextStyles: {
          primary: "body1_accentDark",
          secondary: "body1_baseGray",
          tertiary: null,
        },
        buttons: {
          primary: "buttonUnaryAccent",
        },
        views: {
          background: null,
          secondary: null,
        },
      },
      formAccent: {
        labelTextStyles: {
          primary: "title6_accentDark",
          secondary: "subtitle3_baseGray",
          tertiary: null,
        },
        buttons: {
          primary: "buttonTertiaryAccent",
        },
        views: {
          background: null,
          secondary: null,
        },
      },
      quantitySelector: {
        labelTextStyles: {
          primary: "title2_baseBlack",
          secondary: null,
          tertiary: null,
        },
        buttons: {
          primary: "buttonUnaryGray",
        },
        views: {
          background: "base",
          secondary: "circleGray",
        },
      },
      footerButton: {
        labelTextStyles: {
          primary: null,
          secondary: null,
          tertiary: null,
        },
        buttons: {
          primary: "buttonUnaryAccentCircle",
        },
        views: {
          background: "base",
          secondary: null,
        },
      },
      itemDetailFooterButton: {
        labelTextStyles: {
          primary: null,
          secondary: null,
          tertiary: null,
        },
        buttons: {
          primary: "buttonPrimaryStandard",
        },
        views: {
          background: "baseBackgroundGray",
          secondary: null,
        },
      },
      menuFooter: {
        labelTextStyles: {
          primary: "title3_baseWhite",
          secondary: null,
          tertiary: null,
        },
        buttons: null,
        views: {
          background: "accentDark",
          secondary: null,
        },
      },
      cardItem: {
        labelTextStyles: {
          primary: "title3_accentDark",
          secondary: "body1_baseGray",
          tertiary: "title6_accentDark",
        },
        buttons: {
          primary: "buttonTertiaryGray",
          secondary: "buttonToggleStandard",
        },
        views: {
          background: "base",
          secondary: null,
        },
      },
      historyCard: {
        labelTextStyles: {
          primary: "title3_baseGray",
          secondary: "body1_baseGray",
          tertiary: "body1_baseGray",
        },
        buttons: {
          primary: "buttonBlackClear",
          secondary: "buttonAccentClear",
        },
        views: {
          background: "baseBackgroundGray",
          secondary: null,
        },
      },
      giftCardField: {
        labelTextStyles: {
          primary: "title3_baseGray",
          secondary: "body1_baseGray",
          tertiary: "body1_baseGray",
        },
        buttons: {
          primary: "buttonBlackClear",
          secondary: "buttonAccentClear",
        },
        views: {
          background: "baseBackgroundGray",
          secondary: null,
        },
      },
      receiptDetail: {
        labelTextStyles: {
          primary: "title2_accentDark",
          secondary: "body2_baseGray",
          tertiary: "title5_accentDark",
          quaternary: "title2_accentDark",
        },
        buttons: {
          primary: "buttonTertiaryGray",
          secondary: "buttonTertiaryGray",
        },
        views: {
          background: "base",
          secondary: "roundedBaseClear",
        },
      },
      dropdown: {
        labelTextStyles: {
          primary: "subtitle1_accentDark",
          secondary: null,
          tertiary: null,
        },
        buttons: null,
        views: {
          background: "base",
          secondary: null,
        },
      },
      profileHeader: {
        labelTextStyles: {
          primary: "title1_baseGray",
          secondary: null,
          tertiary: null,
        },
        buttons: {
          primary: "buttonTertiaryBorderlessAccent",
        },
        views: {
          background: "lightGray",
          secondary: null,
        },
      },
      packingGroup: {
        labelTextStyles: {
          primary: "title5_accentDark",
          secondary: "body1_baseWarning",
          tertiary: null,
        },
        buttons: null,
        views: {
          background: "base",
          secondary: null,
        },
      },
      packingItem: {
        labelTextStyles: {
          primary: "body1_baseGray",
          secondary: "body1_accentDark",
          tertiary: "body1_baseGray",
        },
        buttons: {
          primary: "buttonToggleStandard",
        },
        views: {
          background: "baseGrayBordered",
          secondary: null,
        },
      },
      loyaltyHeader: {
        labelTextStyles: {
          primary: "title4_accentDark",
          secondary: "body3_accentDark",
          tertiary: null,
        },
        buttons: null,
        views: {
          background: "base",
          primary: null,
          secondary: null,
          tertiary: null,
        },
      },
      walletCard: {
        labelTextStyles: {
          primary: "title4_accentDark",
          secondary: "body1_accentDark",
          tertiary: "body1_baseGray",
        },
        buttons: null,
        views: {
          background: "baseBackgroundGray",
          primary: "accentDark",
          secondary: "baseBackgroundGray",
          tertiary: null,
        },
      },
      loyaltyCartBanner: {
        labelTextStyles: {
          primary: "body1_baseGray",
          secondary: null,
          tertiary: null,
        },
        buttons: "buttonTertiaryBorderlessAccent",
        views: {
          background: null,
        },
      },
      guestCheckoutSignup: {
        labelTextStyles: {
          primary: "title2_accentDark",
          secondary: "body2_baseGray",
          link: "title5_accentDark",
          error: "title2_accentDark",
        },
        buttons: {
          primary: "buttonBlackClear",
        },
        views: {
          background: "base",
        },
      },
    },
    views: {
      base: {
        cornerRadius: 0,
        border: null,
        backgroundColor: "baseWhite",
      },
      lightGray: {
        cornerRadius: 8,
        border: null,
        backgroundColor: "lightGray",
      },
      lightGrayBordered: {
        cornerRadius: 8,
        border: {
          width: 1,
          colors: {
            unselected: "accentDark",
            selected: null,
            disabled: null,
          },
        },
        backgroundColor: "lightGray",
      },
      accentDark: {
        cornerRadius: 0,
        border: null,
        backgroundColor: "accentDark",
      },
      accentLight: {
        cornerRadius: 0,
        border: null,
        backgroundColor: "accentLight",
      },
      baseBackgroundGray: {
        cornerRadius: null,
        border: null,
        backgroundColor: "backgroundGray",
      },
      baseGrayBordered: {
        cornerRadius: 0,
        border: {
          width: 1,
          colors: {
            unselected: "baseGray",
            selected: null,
            disabled: null,
          },
        },
        backgroundColor: "baseWhite",
      },
      baseGrayBorderedHeavy: {
        cornerRadius: 0,
        border: {
          width: 3,
          colors: {
            unselected: "baseGray",
            selected: null,
            disabled: null,
          },
        },
        backgroundColor: "baseWhite",
      },
      roundedBaseAltClear: {
        cornerRadius: 4,
        border: {
          width: 0,
          colors: {
            unselected: null,
            selected: null,
            disabled: null,
          },
        },
        backgroundColor: null,
      },
      roundedBaseClear: {
        cornerRadius: 4,
        border: {
          width: 4,
          colors: {
            unselected: "accentDark",
            selected: "accentDark",
            disabled: "accentDark",
          },
        },
        backgroundColor: null,
      },
      roundedBase: {
        cornerRadius: 5,
        border: {
          width: 0,
          colors: {
            unselected: null,
            selected: null,
            disabled: null,
          },
        },
        backgroundColor: "baseWhite",
      },
      roundedBaseBlack: {
        cornerRadius: 5,
        border: {
          width: 3,
          colors: {
            unselected: "baseBlack",
            selected: null,
            disabled: "alternateGray",
          },
        },
        backgroundColor: "baseBlack",
      },
      roundedBaseGrayBordered: {
        cornerRadius: 5,
        border: {
          width: 1,
          colors: {
            unselected: "baseGray",
            selected: null,
            disabled: null,
          },
        },
        backgroundColor: null,
      },
      roundedBlackClearBackground: {
        cornerRadius: 5,
        border: {
          width: 3,
          colors: {
            unselected: "baseBlack",
            selected: null,
            disabled: "alternateGray",
          },
        },
        backgroundColor: null,
      },
      circleBlackClearBackground: {
        cornerRadius: 50,
        border: {
          width: 3,
          colors: {
            unselected: "baseBlack",
            selected: null,
            disabled: "alternateGray",
          },
        },
        backgroundColor: null,
      },
      roundedWarningClearBackground: {
        cornerRadius: 5,
        border: {
          width: 3,
          colors: {
            unselected: "baseWarning",
            selected: null,
            disabled: "alternateGray",
          },
        },
        backgroundColor: null,
      },
      roundedAccentDark: {
        cornerRadius: 5,
        border: {
          width: 3,
          colors: {
            unselected: "accentDark",
            selected: null,
            disabled: null,
          },
        },
        backgroundColor: "accentDark",
      },
      unaryAccentDarkCircle: {
        cornerRadius: 50,
        border: {
          width: 3,
          colors: {
            unselected: "accentDark",
            selected: null,
            disabled: null,
          },
        },
        backgroundColor: "accentDark",
      },
      unaryAccentLightCircle: {
        cornerRadius: 50,
        border: {
          width: 3,
          colors: {
            unselected: "accentLight",
            selected: null,
            disabled: null,
          },
        },
        backgroundColor: "accentDark",
      },
      roundedAccentClearBackground: {
        cornerRadius: 5,
        border: {
          width: 3,
          colors: {
            unselected: "accentDark",
            selected: null,
            disabled: null,
          },
        },
        backgroundColor: null,
      },
      baseAccentBordered: {
        cornerRadius: 5,
        border: {
          width: 2,
          colors: {
            unselected: "accentDark",
            selected: "accentDark",
            disabled: null,
          },
        },
        backgroundColor: "baseWhite",
      },
      circleGray: {
        cornerRadius: 25,
        border: {
          width: 1,
          colors: {
            unselected: "baseGray",
            selected: null,
            disabled: null,
          },
        },
        backgroundColor: "baseWhite",
      },
      circleAccentDark: {
        cornerRadius: 100,
        border: null,
        backgroundColor: "accentDark",
      },
      circleAccentLight: {
        cornerRadius: 100,
        border: null,
        backgroundColor: "accentLight",
      },
      circleAccentDarkBackground: {
        cornerRadius: 100,
        border: {
          width: 3,
          colors: {
            unselected: "accentDark",
            selected: "accentDark",
            disabled: null,
          },
        },
        backgroundColor: "accentDark",
      },
      circleToggleStandard: {
        cornerRadius: 100,
        border: {
          width: 3,
          colors: {
            unselected: "baseGray",
            selected: "accentDark",
            disabled: null,
          },
        },
        backgroundColor: null,
      },
      clearBaseGrayBordered: {
        cornerRadius: 0,
        border: {
          width: 1,
          colors: {
            unselected: "baseGray",
            selected: null,
            disabled: null,
          },
        },
        backgroundColor: null,
      },
      baseAccentDarkBordered: {
        cornerRadius: 5,
        border: {
          width: 2,
          colors: {
            unselected: "accentDark",
            selected: null,
            disabled: null,
          },
        },
        backgroundColor: "alternateGray",
      },
      baseAccentDark: {
        cornerRadius: 5,
        border: {
          width: 0,
          colors: {
            unselected: null,
            selected: null,
            disabled: null,
          },
        },
        backgroundColor: "accentDark",
      },
    },
    labels: {
      title1_baseBlack: {
        isUppercase: true,
        font: "title1",
        textColor: "baseBlack",
        view: null,
      },
      title1_baseWhite: {
        isUppercase: true,
        font: "title1",
        textColor: "baseWhite",
        view: null,
      },
      title1_accentDark: {
        isUppercase: true,
        font: "title1",
        textColor: "accentDark",
        view: null,
      },
      title1_baseGray: {
        isUppercase: true,
        font: "title1",
        textColor: "baseGray",
        view: null,
      },
      title2_baseBlack: {
        isUppercase: true,
        font: "title2",
        textColor: "baseBlack",
        view: null,
      },
      title2_baseGray: {
        isUppercase: true,
        font: "title2",
        textColor: "baseGray",
        view: null,
      },
      title2_baseWhite: {
        isUppercase: true,
        font: "title2",
        textColor: "baseWhite",
        view: null,
      },
      title2_baseWhite_tab: {
        isUppercase: true,
        font: "title2",
        textColor: "baseWhite",
        view: "circleAccentDark",
      },
      title2_accentDark: {
        isUppercase: true,
        font: "title2",
        textColor: "accentDark",
        view: null,
      },
      title2_baseWarning: {
        isUppercase: true,
        font: "title2",
        textColor: "baseWarning",
        view: null,
      },
      title3_baseBlack: {
        isUppercase: true,
        font: "title3",
        textColor: "baseBlack",
        view: null,
      },
      title3_baseGray: {
        isUppercase: true,
        font: "title3",
        textColor: "baseGray",
        view: null,
      },
      title3_baseWhite: {
        isUppercase: true,
        font: "title3",
        textColor: "baseWhite",
        view: null,
      },
      title3_baseWhiteAlt: {
        isUppercase: false,
        font: "title3",
        textColor: "baseWhite",
        view: null,
      },
      title3_baseWarning: {
        isUppercase: true,
        font: "title3",
        textColor: "baseWarning",
        view: null,
      },
      title3_alternateGray: {
        isUppercase: true,
        font: "title3",
        textColor: "alternateGray",
        view: null,
      },
      title3_accentDark: {
        isUppercase: true,
        font: "title3",
        textColor: "accentDark",
        view: null,
      },
      title3_accentLight: {
        isUppercase: true,
        font: "title3",
        textColor: "accentLight",
        view: null,
      },
      title3_accentDarkAlt: {
        isUppercase: false,
        font: "title3",
        textColor: "accentDark",
        view: null,
      },
      title4_accentDark: {
        isUppercase: true,
        font: "title4",
        textColor: "accentDark",
        view: null,
      },
      title4_baseBlack: {
        isUppercase: true,
        font: "title4",
        textColor: "baseBlack",
        view: null,
      },
      title4_baseGray: {
        isUppercase: true,
        font: "title4",
        textColor: "baseGray",
        view: null,
      },
      title4_baseWhite: {
        isUppercase: true,
        font: "title4",
        textColor: "baseWhite",
        view: null,
      },
      title5_baseBlack: {
        isUppercase: true,
        font: "title5",
        textColor: "baseBlack",
        view: null,
      },
      title5_baseWhite: {
        isUppercase: true,
        font: "title5",
        textColor: "baseWhite",
        view: null,
      },
      title5_baseGray: {
        isUppercase: true,
        font: "title5",
        textColor: "baseGray",
        view: null,
      },
      title5_baseWarning: {
        isUppercase: true,
        font: "title5",
        textColor: "baseWarning",
        view: null,
      },
      title5_accentDark: {
        isUppercase: true,
        font: "title5",
        textColor: "accentDark",
        view: null,
      },
      title6_baseGray: {
        isUppercase: true,
        font: "title6",
        textColor: "baseGray",
        view: null,
      },
      title6_accentDark: {
        isUppercase: true,
        font: "title6",
        textColor: "accentDark",
        view: null,
      },
      subheader1_accentDark: {
        isUppercase: true,
        font: "subheader1",
        textColor: "baseBlack",
        view: null,
      },
      subheader1_baseGray: {
        isUppercase: true,
        font: "subheader1",
        textColor: "baseBlack",
        view: null,
      },
      subheader1_baseWhite: {
        isUppercase: true,
        font: "subheader1",
        textColor: "baseWhite",
        view: null,
      },
      subheader1_alternateGray: {
        isUppercase: true,
        font: "subheader1",
        textColor: "alternateGray",
        view: null,
      },
      subtitle1_accentDark: {
        isUppercase: false,
        font: "subtitle1",
        textColor: "accentDark",
        view: null,
      },
      subtitle1_baseBlack: {
        isUppercase: false,
        font: "subtitle1",
        textColor: "baseBlack",
        view: null,
      },
      subtitle1_baseGray: {
        isUppercase: false,
        font: "subtitle1",
        textColor: "baseGray",
        view: "base",
      },
      subtitle1_alternateGray: {
        isUppercase: false,
        font: "subtitle1",
        textColor: "alternateGray",
        view: "base",
      },
      subtitle1_baseWhite: {
        isUppercase: true,
        font: "subtitle1",
        textColor: "baseWhite",
        view: null,
      },
      subtitle1_baseWarning: {
        isUppercase: false,
        font: "subtitle1",
        textColor: "baseWarning",
        view: null,
      },
      subtitle2_accentDark: {
        isUppercase: true,
        font: "subtitle2",
        textColor: "baseBlack",
        view: null,
      },
      subtitle2_baseGray: {
        isUppercase: true,
        font: "subtitle2",
        textColor: "baseGray",
        view: null,
      },
      subtitle2_baseBlack: {
        isUppercase: true,
        font: "subtitle2",
        textColor: "baseBlack",
        view: null,
      },
      subtitle3_accentDark: {
        isUppercase: true,
        font: "subtitle3",
        textColor: "accentDark",
        view: null,
      },
      subtitle3_baseGray: {
        isUppercase: true,
        font: "subtitle3",
        textColor: "baseGray",
        view: null,
      },
      subtitle3_baseBlack: {
        isUppercase: true,
        font: "subtitle3",
        textColor: "baseBlack",
        view: null,
      },
      body1_baseBlack: {
        isUppercase: false,
        font: "body1",
        textColor: "baseBlack",
        view: null,
      },
      body1_baseGray: {
        isUppercase: false,
        font: "body1",
        textColor: "baseGray",
        view: null,
      },
      body1_baseGray_cap: {
        isUppercase: true,
        font: "body1",
        textColor: "baseGray",
        view: null,
      },
      body1_accentDark_cap: {
        isUppercase: true,
        font: "body1",
        textColor: "baseBlack",
        view: null,
      },
      body1_baseWhite: {
        isUppercase: false,
        font: "body1",
        textColor: "baseWhite",
        view: null,
      },
      body1_accentDark: {
        isUppercase: false,
        font: "body1",
        textColor: "accentDark",
        view: null,
      },
      body1_accentLight: {
        isUppercase: false,
        font: "body1",
        textColor: "accentLight",
        view: null,
      },
      body1_baseWarning: {
        isUppercase: false,
        font: "body1",
        textColor: "baseWarning",
        view: null,
      },
      body1_alternateGray: {
        isUppercase: false,
        font: "body1",
        textColor: "alternateGray",
        view: null,
      },
      body1_baseLink: {
        isUppercase: false,
        font: "body1",
        textColor: "baseLink",
        view: null,
      },
      body2_accentDark: {
        isUppercase: false,
        font: "body2",
        textColor: "accentDark",
        view: null,
      },
      body2_baseGray: {
        isUppercase: false,
        font: "body2",
        textColor: "baseGray",
        view: null,
      },
      body2_baseWarning: {
        isUppercase: false,
        font: "body2",
        textColor: "baseWarning",
        view: null,
      },
      body3_baseGray: {
        isUppercase: false,
        font: "body3",
        textColor: "baseGray",
        view: null,
      },
      body3_accentDark: {
        isUppercase: false,
        font: "body3",
        textColor: "accentDark",
        view: null,
      },
      title3_altGray: {
        isUppercase: false,
        font: "title3",
        textColor: "baseBlack",
        view: null,
      },
    },
    segmentViews: {
      primary: {
        stateTextStyles: {
          unselected: "title2_baseWhite",
          selected: "title2_baseWhite_tab",
          disabled: null,
        },
        stateBackgroundColors: {
          unselected: "accentLight",
          selected: "accentDark",
          disabled: null,
        },
        view: "circleAccentDark",
      },
    },
    inputs: {
      primary: {
        title: "title6_accentDark",
        field: "body1_baseGray",
        placeholder: "body1_baseGray",
        error: "body2_baseWarning",
      },
      secondary: {
        title: "body1_accentDark",
        field: "body1_baseGray",
        placeholder: "body1_baseGray",
        error: "body1_baseWarning",
      },
      tertiary: {
        title: "body1_baseGray",
        field: "body1_baseGray",
        placeholder: "body1_baseGray",
        error: "body1_baseWarning",
      },
      input_giftCardField: {
        title: "title6_accentDark",
        field: "body1_baseGray",
        placeholder: "body1_baseGray",
        error: "body2_baseWarning",
      },
      input_guestCheckoutSignup: {
        title: "title6_accentDark",
        field: "body1_baseGray",
        placeholder: "body1_baseGray",
        error: "body2_baseWarning",
      },
    },
    dialogues: {
      primary: {
        labelTextStyles: {
          primary: "title3_accentDark",
          secondary: "body1_accentDark",
          tertiary: null,
        },
        buttons: {
          deny: "buttonSecondaryWhite",
          approve: "buttonSecondaryWhite",
        },
        view: "base",
      },
    },
    pageControls: null,
    navigationViews: null,
  },
  styles: {
    normal: {
      template: "StandardWeb",
      version: "1.0.0",
      main: {
        buttons: {
          dropdown: "buttonNavDropDown",
        },
        cells: null,
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          primary: "subtitle1_baseGray",
          secondary: "title5_baseGray",
          tertiary: "body1_baseGray",
        },
        inputs: null,
        views: {
          background: "base",
        },
      },
      payment: {
        buttons: null,
        cells: {
          bottom: "footerButton",
          cards: "cardItem",
        },
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          primary: "body1_baseGray",
          error: "body1_baseWarning",
        },
        inputs: null,
        views: {
          background: "base",
        },
      },
      menu: {
        buttons: {
          locationNotFound: "buttonUnaryDark",
        },
        cells: {
          group: "menuGroup",
          item: "orderItem",
          itemMobile: "orderItemMobile",
          location: "locationInfo",
          nav: "orderHeader",
          footer: "menuFooter",
        },
        dialogues: {
          addItem: "primary",
        },
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          locationNotFound: "subtitle3_accentDark",
          unavailable: "subtitle2_accentDark",
        },
        inputs: null,
        views: {
          background: "base",
        },
      },
      location: {
        buttons: null,
        cells: {
          primary: "location",
          secondary: "mapInformation",
          tertiary: "searchResults",
          footer: "menuFooter",
        },
        dialogues: {
          confirmation: "primary",
        },
        navigationViews: null,
        pageControls: null,
        segmentViews: {
          standard: "primary",
        },
        labels: {
          primary: "title3_accentDark",
          secondary: "title2_accentDark",
          tertiary: null,
        },
        inputs: null,
        views: {
          background: "base",
        },
      },
      cart: {
        buttons: {
          clear: "buttonTertiaryBorderlessAccentLight",
        },
        cells: {
          primary: "cartItem",
          secondary: "form",
          header: "cartHeader",
          bottom: "footerButton",
        },
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          primary: "title3_baseGray",
          secondary: "title1_accentDark",
          emptyCart: "title2_accentDark",
          priceSubtotals: "subtitle1_baseGray",
          priceTotals: "subtitle1_accentDark",
          error: "body1_baseWarning",
        },
        inputs: {
          standard: "primary",
        },
        views: {
          background: "base",
          header: "base",
        },
      },
      upsells: {
        buttons: null,
        cells: {
          primary: "upsellItem",
          bottom: "footerButton",
        },
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          primary: "title2_accentDark",
          error: "body2_baseWarning",
        },
        inputs: null,
        views: {
          background: "base",
          header: "base",
        },
      },
      link: {
        buttons: {
          link: "buttonTertiaryLink",
          clear: "buttonTertiaryBorderless",
        },
        cells: {
          link: "form",
          bottom: "footerButton",
        },
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          primary: "title2_accentDark",
          secondary: "title2_baseGray",
          error: "body1_baseWarning",
        },
        inputs: {
          primary: "primary",
        },
        views: {
          background: "base",
          header: "base",
        },
      },
      checkout: {
        buttons: {
          promo: "buttonTertiaryBorderlessAccent",
          tip: "buttonTertiaryAccent",
          cash: "buttonTertiaryGray",
          mobile: "buttonTertiaryGray",
        },
        cells: {
          primary: "form",
          bottom: "footerButton",
        },
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          priceSubtotals: "body1_baseGray",
          priceTotals: "title4_baseGray",
          discount: "body1_accentDark",
          error: "body1_baseWarning",
          disclaimer: "body1_baseGray",
          cash: "body1_baseGray",
          mobile: "body1_baseGray",
        },
        inputs: {
          standard: "tertiary",
        },
        views: {
          background: "base",
        },
      },
      confirm: {
        buttons: {
          confirm: "buttonUnaryClearRound",
          negConfirm: "buttonUnaryClearRound",
          cancel: "buttonUnaryAccentCircle",
        },
        cells: null,
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          title: "title2_accentDark",
        },
        inputs: null,
        views: {
          background: "base",
        },
      },
      itemDetails: {
        buttons: {
          nav: "buttonItemNavStandard",
        },
        cells: {
          header: "itemDetail",
          group: "optionHeader",
          primary: "optionItem",
          primarySelected: "optionItemSelected",
          quantity: "quantitySelector",
          bottom: "itemDetailFooterButton",
        },
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: {
          standard: "primary",
        },
        labels: {
          warning: "subtitle1_baseWarning",
        },
        inputs: {
          standard: "tertiary",
        },
        views: {
          background: "baseBackgroundGray",
        },
      },
      phone: {
        buttons: null,
        cells: {
          bottom: "footerButton",
        },
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          error: "body1_baseWarning",
        },
        inputs: {
          standard: "primary",
        },
        views: {
          background: "base",
        },
      },
      email: {
        buttons: {
          alternate: "buttonUnaryClearSmall",
        },
        cells: {
          bottom: "footerButton",
        },
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          error: "body1_baseWarning",
        },
        inputs: {
          standard: "primary",
        },
        views: {
          background: "base",
        },
      },
      pincode: {
        buttons: {
          alternate: "buttonUnaryClearSmall",
        },
        cells: {
          bottom: "footerButton",
          form: "form",
        },
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          error: "body1_baseWarning",
        },
        inputs: {
          standard: "primary",
        },
        views: {
          background: "base",
        },
      },
      password: {
        buttons: null,
        cells: {
          bottom: "footerButton",
        },
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          error: "body1_baseWarning",
        },
        inputs: {
          standard: "primary",
          secondary: "primary",
        },
        views: {
          background: "base",
        },
      },
      discount: {
        buttons: {
          primary: "buttonUnaryLightCircle",
        },
        cells: {
          bottom: "footerButton",
        },
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          error: "body1_baseWarning",
        },
        inputs: {
          standard: "primary",
        },
        views: {
          background: "base",
        },
      },
      scheduleDate: {
        buttons: {
          alternate: "buttonUnaryLightCircle",
          footer: "buttonUnaryAccentCircle",
        },
        cells: {
          bottom: "footerButton",
        },
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          description: "body1_baseGray",
        },
        inputs: {
          standard: "primary",
        },
        views: {
          background: "base",
        },
      },
      confirmation: {
        buttons: null,
        cells: {
          primary: "cartItem",
          bottom: "footerButton",
        },
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          time: "title3_accentDark",
          primary: "title4_baseGray",
          secondary: "body1_baseGray",
          tertiary: "body2_baseGray",
        },
        inputs: null,
        views: {
          background: "base",
          secondary: "base",
          tertiary: "baseBackgroundGray",
          bar: "roundedAccentDark",
        },
      },
      loyalty: {
        buttons: {
          alternate: "buttonTertiaryBorderless",
        },
        cells: {
          walletCard: "walletCard",
          bottom: "footerButton",
        },
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          title: "title3_accentDark",
          subtitle: "title3_accentDark",
          description: "title2_accentDark",
          subDescription: "body1_accentDark",
          smallDescription: "body1_baseGray",
          smallDescription2: "body2_baseGray",
          empty: "body1_accentDark",
          error: "body1_baseWarning",
          rulesHeader: "body1_baseBlack",
          rulesTierName: "body1_baseBlack",
        },
        inputs: {
          standard: "primary",
        },
        views: {
          background: "base",
          progress: "accentDark",
          borderedBackground: "baseGrayBordered",
          secondary: "base",
          bar: "accentDark",
          trail: "baseBackgroundGray",
          rulesBg: "base",
          rulesHeader: "base",
          rulesTierName: "base",
        },
      },
      signin: {
        buttons: {
          link: "buttonTertiaryLink",
        },
        cells: {
          form: "form",
          bottom: "footerButton",
          header: "loyaltyHeader",
        },
        dialogues: {
          success: "primary",
        },
        navigationViews: null,
        pageControls: null,
        segmentViews: {
          standard: "primary",
        },
        labels: {
          headerTitle: "title4_accentDark",
          headerSubTitle: "body3_accentDark",
          title: "title3_accentDark",
          terms: "body1_baseGray",
          error: "body1_baseWarning",
        },
        inputs: {
          standard: "primary",
        },
        views: {
          background: "base",
        },
      },
      signUpConfirmation: {
        buttons: null,
        cells: {
          bottom: "footerButton",
        },
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          title: "title4_accentDark",
          subtitle: "body3_accentDark",
        },
        inputs: null,
        views: {
          background: "base",
        },
      },
      logout: {
        buttons: {
          confirm: "buttonUnaryClearRound",
          cancel: "buttonUnaryAccentCircle",
        },
        cells: null,
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          title: "title2_accentDark",
        },
        inputs: null,
        views: {
          background: "base",
        },
      },
      address: {
        buttons: {
          bottom: "buttonUnaryDark",
          searchResults: "buttonSecondaryGray",
        },
        cells: null,
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          label: "body1_accentDark",
          error: "body1_baseWarning",
        },
        inputs: {
          standard: "secondary",
        },
        views: {
          background: "base",
        },
      },
      empty: {
        buttons: null,
        cells: null,
        dialogues: null,
        navigationViews: {
          standard: "primary",
        },
        pageControls: null,
        segmentViews: null,
        labels: {
          description: "title1_accentDark",
        },
        inputs: null,
        views: {
          background: "base",
        },
      },
      profile: {
        buttons: null,
        cells: {
          form: "form",
          cards: "cardItem",
          header: "profileHeader",
          bottom: "footerButton",
          historyCard: "historyCard",
          receiptDetail: "receiptDetail",
        },
        dialogues: {
          confirm: "primary",
          success: "primary",
        },
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          section: "title3_baseGray",
          error: "title4_accentDark",
        },
        inputs: {
          standard: "primary",
        },
        views: {
          background: "base",
          secondary: "base",
        },
      },
      packingInstructions: {
        buttons: null,
        cells: {
          group: "packingGroup",
          item: "packingItem",
          bottom: "footerButton",
        },
        dialogues: null,
        navigationViews: null,
        pageControls: null,
        segmentViews: null,
        labels: {
          primary: "title2_accentDark",
        },
        views: {
          background: "base",
          secondary: null,
        },
      },
    },
  },
} as unknown as ThemeFile;

export const styledTheme: DefaultTheme = {
  buttons: theme?.elements?.buttons,
  colors: theme?.colors,
  fonts: theme?.fonts,
};

export default theme;
