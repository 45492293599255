const DELIVERY_TIME_TEXT = "Delivery time: {deliveryEstimate}";
const DINING_OPTION_DELIVERY_LABEL = "Delivery Hours";
const DINING_OPTION_PICKUP_LABEL = "Pickup Hours";
const ORDER_BUTTON_TEXT = "Order";
const ORDER_BUTTON_TEXT_DELIVERY = ORDER_BUTTON_TEXT;
const ORDER_BUTTON_TEXT_KIOSK = ORDER_BUTTON_TEXT;
const ORDER_BUTTON_TEXT_PICKUP = ORDER_BUTTON_TEXT;
const PICK_UP_TIME_TEXT = "Pickup time: {pickupEstimate}";
const PICKING_UP_TIME = "Picking up at";
const PREP_TIME_TEXT = "Prep time: {PrepEstimate}";
const STORE_CLOSED = "Closed";
const STORE_HOURS_LABEL = "Store Hours";
const PREORDER_BUTTON_TEXT = "Preorder";

export {
  DELIVERY_TIME_TEXT,
  DINING_OPTION_DELIVERY_LABEL,
  DINING_OPTION_PICKUP_LABEL,
  ORDER_BUTTON_TEXT_DELIVERY,
  ORDER_BUTTON_TEXT_KIOSK,
  ORDER_BUTTON_TEXT,
  ORDER_BUTTON_TEXT_PICKUP,
  PICK_UP_TIME_TEXT,
  PICKING_UP_TIME,
  PREP_TIME_TEXT,
  PREORDER_BUTTON_TEXT,
  STORE_CLOSED,
  STORE_HOURS_LABEL,
};

export default {
  DELIVERY_TIME_TEXT,
  DINING_OPTION_DELIVERY_LABEL,
  DINING_OPTION_PICKUP_LABEL,
  ORDER_BUTTON_TEXT,
  ORDER_BUTTON_TEXT_DELIVERY,
  ORDER_BUTTON_TEXT_KIOSK,
  ORDER_BUTTON_TEXT_PICKUP,
  PICK_UP_TIME_TEXT,
  PICKING_UP_TIME,
  PREORDER_BUTTON_TEXT,
  PREP_TIME_TEXT,
  STORE_CLOSED,
  STORE_HOURS_LABEL,
};
