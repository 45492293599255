import * as Yup from "yup";

const AuthorizeCC = Yup.object().shape({
  cardNumber: Yup.string().required("Card number Required"),
  cvv: Yup.string().required("CVV is required"),
  month: Yup.string().required("Expiration Month required"),
  street1: Yup.string().required("Billing Street Address required"),
  year: Yup.string().required("Expiration Year required"),
  zip: Yup.string().required("Zip code is required"),
});

export default AuthorizeCC;
