import * as Yup from "yup";
import { phoneRegExp } from "utils/helpers/regexps";

export const PASSWORD_TOO_SHORT_MESSAGE =
  "Password must be at least 8 characters";
export const PASSWORD_REQUIRED_MESSAGE = "Password is Required";
const passwordSchema = Yup.string()
  .min(8, PASSWORD_TOO_SHORT_MESSAGE)
  .required(PASSWORD_REQUIRED_MESSAGE);

const SignUpSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is Required"),
  firstName: Yup.string().required("First Name is Required"),
  lastName: Yup.string().required("Last Name is Required"),
  password: passwordSchema,
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone is Required"),
  birthday: Yup.date().max(new Date(), "Please enter a valid birthday"),
});

export const GuestCheckoutSignupSchema = Yup.object().shape({
  password: passwordSchema,
});

export default SignUpSchema;
