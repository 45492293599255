import * as React from "react";
import { config } from "utils";
import { formatPrice } from "utils/helpers/string";

const withHistoryItem =
  (Component) =>
  ({ mods, name, description, cellType, price }) => {
    const modifiers = mods.map((mod) => ({
      name: mod.itemName,
      price: mod.price || "",
    }));

    return (
      <Component
        themeType={config.theme.order_history.order_item || "Type1"}
        actionButtons={[]}
        title={name}
        price={formatPrice(price)}
        description={description}
        items={modifiers}
        cardImage=""
        cellType={cellType}
      />
    );
  };

export default withHistoryItem;
