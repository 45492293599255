/*
 * ItemDetails Groups
 *
 * This component toggles the modifiers below.
 * Rendered in ItemDetails/Layout/Subgroups.
 * Select a group to switch the modifiers in ItemDetails.
 */

import Selector from "./selector";

export default Selector;
