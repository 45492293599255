import { formatPrice } from "utils/helpers/string";

export const populateMods = (mods) => {
  const t = mods
    .reduce((accu, i) => {
      const accuTemp = [...accu];
      const hasItemIndex = accuTemp.findIndex((x) => x.itemName === i.itemName);
      if (hasItemIndex !== -1) {
        accuTemp[hasItemIndex].quantity += i.quantity || 1;
      } else {
        accuTemp.push({ ...i, quantity: i.quantity || 1 });
      }
      return accuTemp;
    }, [])
    .filter((i) => i);
  return t;
};

export const formatModifierPrice = (price, quantity = 1) => {
  let priceString = price ? `${formatPrice(price)}` : "";
  const sign = price && price < 0 ? "-" : "+";
  const quantityString = quantity > 1 ? ` x ${quantity}` : "";
  priceString = `${sign}${priceString}`;
  if (price) {
    return `(${priceString}${quantityString})`;
  }
  return quantityString;
};
