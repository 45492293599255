import * as React from "react";
import { config } from "utils";
import loadable from "@loadable/component";

const AsyncPage = loadable((props) => import(`./${props.page}`));

const HeaderSelector = (props) => {
  return (
    <AsyncPage
      // type2: loyalty
      page={config?.theme?.login_signup?.header || "Type1"}
      {...props}
    />
  );
};

export default HeaderSelector;
