import * as React from "react";
import { usePackingContext } from "../packingContext";

const isSingleSelect = (min, max) =>
  max === 1 || (min === null && max === null);

const withPackingGroup =
  (Component) =>
  ({ id, onUpdateSelectedItems, packingItems, ...rest }) => {
    const { getEntityById } = usePackingContext();
    const { errors, ...group } = getEntityById(id, "group");

    const isSingleSelectable =
      group.isSingleSelectionOnly && isSingleSelect(group.min, group.max);
    const maxSelection = isSingleSelectable ? 1 : group.max ?? 100;

    // First filter to find the matching group id, then add the items
    const [selectedItems, setSelectedItems] = React.useState(
      packingItems.array
        .filter((packingItem) => packingItem.group === group.id)
        .map((packingItem) => packingItem.item),
    );

    const firstRun = React.useRef(true);
    const onAddPackingItem = (itemId) => {
      if (selectedItems.length < maxSelection) {
        setSelectedItems((prevItems) => [...prevItems, itemId]);
      } else {
        setSelectedItems((prevItems) => [...prevItems, itemId].slice(1));
      }
    };
    const onRemovePackingItem = (itemId) => {
      setSelectedItems((prevItems) => {
        const itemIdx = prevItems.indexOf(itemId);
        if (itemIdx !== -1) {
          return [
            ...prevItems.slice(0, itemIdx),
            ...prevItems.slice(itemIdx + 1),
          ];
        }
        return [...prevItems];
      });
    };

    React.useEffect(() => {
      if (!firstRun.current) {
        onUpdateSelectedItems(id, selectedItems);
      }
      firstRun.current = false;
    }, [selectedItems]);

    const returnProps = {
      errors,
      group,
      isSingleSelectable,
      onAddPackingItem,
      onRemovePackingItem,
      selectedItems,
    };

    return <Component {...rest} {...returnProps} />;
  };

export default withPackingGroup;
