import * as React from "react";

import { ElementsThemed } from "components";
import { Copy } from "utils";
import styles from "./giftCardSuccessPopup.module.scss";

const { Text, Button } = ElementsThemed;

interface GiftCardSuccessPopupProps {
  onConfirm(): void;
}

function GiftCardSuccessPopup({ onConfirm }: GiftCardSuccessPopupProps) {
  return (
    <div
      className={styles.giftCardSuccess}
      aria-label="Success"
      role="dialog"
      aria-modal="true"
      aria-describedby="gift_card_success_description"
      aria-labelledby="gift_card_success_title"
    >
      <Text id="gift_card_success_title" tabIndex={-1}>
        {Copy.CHECKOUT_STATIC.GIFT_CARD_SUCCESS_POPUP_TITLE}
      </Text>
      <br />
      <span id="gift_card_success_description" className={styles.successText}>
        {Copy.CHECKOUT_STATIC.GIFT_CARD_SUCCESS_POPUP_BODY}
      </span>
      <div className={styles.giftCardButtonDiv}>
        <Button className={styles.giftCardSuccessButton} onClick={onConfirm}>
          {Copy.CHECKOUT_STATIC.GIFT_CARD_SUCCESS_POPUP_BUTTON_TEXT}
        </Button>
      </div>
    </div>
  );
}

export default GiftCardSuccessPopup;
