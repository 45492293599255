import * as React from "react";
import { getDisplayName } from "utils/helpers/other";

const withTabs = (Component) => {
  const WithTabs = (props) => {
    const {
      addModifier,
      cells,
      handleScroll,
      menuOptions,
      modifications,
      removeModifier,
      errors = [],
      tabs = [],
    } = props;

    const [activeKey, setActiveKey] = React.useState(
      tabs?.[0]?.name ?? undefined,
    );
    const errorRefs = React.useRef(
      [...Array(tabs.length || 10)].map(() => React.createRef()),
    );

    const findTabWithError = (errorArr, tabArr) => {
      if (errorArr.length && tabArr.length) {
        const foremostError = errorArr?.[0]?.optionId;
        return tabArr.filter((t) => t?.optionIds?.includes(foremostError))?.[0];
      }
      return null;
    };

    React.useEffect(() => {
      const tabName = findTabWithError(errors, tabs)?.name;
      if (tabName) {
        setActiveKey(tabName);
      }
    }, [errors, tabs]);

    React.useEffect(() => {
      // Only scroll if there are errors and we are on the correct tab
      const tabName = findTabWithError(errors, tabs)?.name;
      const shouldScroll =
        tabName && activeKey ? tabName === activeKey : errors.length;
      if (shouldScroll) {
        const el = errorRefs?.current.find(
          ({ current }) => current !== null,
        )?.current;
        if (el) {
          el.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
    }, [errors, tabs, errorRefs, activeKey]);

    const forwardProps = {
      addModifier,
      cells,
      errorRefs,
      errors,
      handleScroll,
      menuOptions,
      modifications,
      removeModifier,
    };

    const additionalProps = {
      activeKey,
      errorRefs,
      forwardProps,
      onTabChange: setActiveKey,
    };

    return <Component {...props} {...additionalProps} />;
  };

  WithTabs.displayName = `withTabs(${getDisplayName(Component)})`;

  return WithTabs;
};

export { withTabs };
