import { withTypeSelector } from "components/hocs";
import { config } from "utils";
import withConfirmItem from "./withConfirmItem";
import Type1 from "./Type1";
import Type4 from "./Type4";

export default withTypeSelector(
  {
    Type1: withConfirmItem(Type1),
    Type4: withConfirmItem(Type4),
  },
  config.theme.checkout.confirm_item,
  withConfirmItem(Type4),
);
