import * as React from "react";
import { Formik, Form } from "formik";

import { Field, Text, View } from "components/elementsThemed";
import { usePatronContext } from "components/providers/Patron/PatronContext";
import { config, images, Routes } from "utils";
import { REDEEM_PROMO, HANDLE_ERROR } from "utils/api";
import { RedeemPromoSchema } from "utils/Schemas/redeemPromoSchema";

import BackButton from "../../../../../BackButton/BackButton";
import DrawerButton from "../../../../../DrawerButton";

import css from "./redeemForm.module.scss";

const { Input } = Field;

/**
 * RedeemForm for promotion redeeming in loyalty rewards section
 *
 * @author Htin Linn Aung
 * @memberof Templates.Templates/Loyalty
 * @param {object} props
 * @param {object} props.style - Theme - From Loyalty.jsx
 * @param {object} props.location - Memory Router location - from Loyalty.jsx
 * @param {object} props.history - Memory Router history - from Loyalty.jsx
 * @param {Function} props.onSuccess - Refetch loyaltyData & history.goBack - from Loyalty.jsx
 */
const backButton = config?.images?.button_back;

const RedeemForm = ({ style, location, history, onSuccess }) => {
  const { accessToken } = usePatronContext();
  const { views, inputs, labels, cells } = style;

  /**
   * Verify Promo Code Input from the user
   *
   * @author Htin Linn Aung
   * @memberof Templates.Templates/Loyalty
   * @async
   * @function verifyPromoCode
   * @param {object} values
   * @param {object} actions
   * @returns {Promise<string>} The success/failure from promo redeeming
   */
  const verifyPromoCode = async (values, actions) => {
    actions.setFieldError("promoCode", "");
    actions.setStatus("");

    try {
      await REDEEM_PROMO(
        { promotionCode: values.promoCode },
        accessToken ? { headers: { authorization: accessToken } } : undefined,
      );
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      const e = HANDLE_ERROR(error);
      actions.setStatus(e.data);
    } finally {
      actions.setSubmitting(false);
    }
  };

  /**
   * @author Htin Linn Aung
   * Action on Click Back
   * @function onClickBack
   * @param {object} memory
   */
  const onClickBack = (memory) => () =>
    memory.location.pathname !== Routes.ROOT ? memory.history.goBack() : null;

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        promoCode: "",
      }}
      onSubmit={verifyPromoCode}
      validationSchema={RedeemPromoSchema}
      render={({ errors, values, status, setFieldValue, isSubmitting }) => (
        <View
          type={views.background}
          className={css["redeem-form"]}
          Component={Form}
        >
          <div className={css["redeem-form-navigation"]}>
            <BackButton
              imgSrc={images?.button_back_profile ?? backButton}
              onClick={onClickBack({ history, location })}
            />
          </div>
          <div className={css["redeem-form-fields"]}>
            <Input
              label="Promo Code"
              name="promoCode"
              type={inputs.standard}
              error={errors.promoCode}
              value={values.promoCode}
              placeholder="Enter promo code here"
              onChange={(e) => setFieldValue("promoCode", e.target.value)}
            />
            {status && <Text type={labels.error}>{status}</Text>}
          </div>
          <div className={css["redeem-form-footer-button"]}>
            <DrawerButton
              buttonProps={{ htmlType: "submit", disabled: isSubmitting }}
            >
              Verify Code
            </DrawerButton>
          </div>
        </View>
      )}
    />
  );
};

export default RedeemForm;
