import * as React from "react";
import { Condition } from "components/elements";
import { Text, View, Radio, Button } from "components/elementsThemed";
import { useCell } from "hooks";
import { formatPrice } from "utils/helpers/string";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import css from "./item.module.scss";

const { Condition: If } = Condition;
// className is needed for styled components
function Item({
  className,
  decrement,
  increment,
  item,
  multiSelect,
  onClick,
  quantity,
  type,
}) {
  const { isNested = false, name = "", price = 0 } = item;
  const { buttons, labelTextStyles, views } = useCell(type);

  return multiSelect ? (
    <View
      type={views.background}
      Component="li"
      active={quantity > 0 ? "1" : "0"}
      className={`${css.modifierItem} ${className}`}
      data-cy="modifier-add-button"
      hoverable
    >
      <button
        aria-label={`${name}${price > 0 ? ` $${price}` : ""}`}
        aria-pressed={quantity === 0 ? "false" : "true"}
        type="button"
        style={{
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
          height: "100%",
          pointerEvents: quantity === 0 ? "auto" : "none",
          position: "absolute",
          width: "100%",
          top: "0",
          left: "0",
        }}
        onClick={onClick}
      />

      <div className={css["modifierItem-content--name"]}>
        <Text aria-hidden="true" type={labelTextStyles.primary}>
          {name}
        </Text>
      </div>
      <div className={css["modifierItem-content--price"]}>
        <If is={price}>
          <Text
            aria-hidden="true"
            type={labelTextStyles.secondary}
            className={css["modifierItem-content--price-value"]}
          >
            {formatPrice(price)}
          </Text>
        </If>

        <If is={!isNested}>
          <div className={css["modifierItem-selectors"]}>
            <If is={quantity !== 0}>
              <Button
                aria-label={`Decrease ${name} quantity`}
                type={buttons.primary}
                className={css["modifierItem-btn"]}
                onClick={decrement}
              >
                <span aria-hidden="true">-</span>
              </Button>
            </If>
            <If is={quantity !== 0}>
              <Text aria-live="polite" type={labelTextStyles.tertiary}>
                <VisuallyHidden>{name} quantity: </VisuallyHidden>
                {quantity}
              </Text>
            </If>
            <Button
              aria-label={`Increase ${name} quantity`}
              type={buttons.primary}
              className={css["modifierItem-btn"]}
              onClick={quantity > 0 ? increment : onClick}
              tabindex={quantity > 0 ? "0" : "-1"}
              aria-hidden={quantity > 0 ? "false" : "true"}
            >
              <span aria-hidden="true"> + </span>
            </Button>
          </div>
        </If>
      </div>
      <If is={isNested}>
        <Radio
          type={buttons.primary}
          className={css["modifierItem-btn"]}
          value={quantity}
          onChange={onClick}
        >
          <VisuallyHidden>
            {name}
            <If is={price}>{formatPrice(price)}</If>
          </VisuallyHidden>
        </Radio>
        <div
          aria-hidden="true"
          className={css["modifierItem-nestedSelector"]}
          onClick={quantity ? decrement : onClick}
          active={quantity > 0 ? "1" : "0"}
          role="button"
          tabIndex="-1"
        />
      </If>
    </View>
  ) : (
    <View
      type={views.background}
      Component="li"
      className={`${css.modifierItem} ${className}`}
      datatest="modifier-add-button"
      hoverable
    >
      <div
        aria-hidden="true"
        tabIndex="-1"
        role="button"
        className={css["modifierItem-overlay"]}
        onClick={onClick}
      />

      <div className={css["modifierItem-content--name"]}>
        <Text type={labelTextStyles.primary}>{name}</Text>
      </div>
      <div className={css["modifierItem-content--price"]}>
        <If is={price}>
          <Text
            type={labelTextStyles.secondary}
            className={css["modifierItem-content--price-value"]}
          >
            {formatPrice(price)}
          </Text>
        </If>

        <If is={!isNested}>
          <Radio
            type={buttons.primary}
            className={css["modifierItem-btn"]}
            value={quantity}
            onChange={onClick}
          >
            <VisuallyHidden>
              {name}
              <If is={price}>{formatPrice(price)}</If>
            </VisuallyHidden>
          </Radio>
        </If>
      </div>
      <If is={isNested}>
        <Radio
          type={buttons.primary}
          className={css["modifierItem-btn"]}
          value={quantity}
          onChange={onClick}
        >
          <VisuallyHidden>
            {name}
            <If is={price}>{formatPrice(price)}</If>
          </VisuallyHidden>
        </Radio>

        <div
          aria-hidden="true"
          className={css["modifierItem-nestedSelector"]}
          onClick={quantity ? decrement : onClick}
          active={quantity > 0 ? "1" : "0"}
          role="button"
          tabIndex="-1"
        />
      </If>
    </View>
  );
}

export default React.memo(Item);
