import * as React from "react";
import { useTemplateContext } from "components/providers/Template";

const withTemplateInput = (Component) => (props) => {
  const {
    parsedTheme: { labels },
    theme: {
      elements: { inputs },
    },
  } = useTemplateContext();
  const { type } = props;
  const inputType = inputs[type];
  const textLabel = labels[inputType?.field];
  const placeholderLabel = labels[inputType?.placeholder];

  return (
    <Component
      {...props}
      input={{ ...inputType, field: textLabel, placeholder: placeholderLabel }}
    />
  );
};

export default withTemplateInput;
