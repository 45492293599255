import * as React from "react";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { Col } from "@lunchboxinc/lunchbox-components";
import ClampLines from "react-clamp-lines";
import { Text, View, Button } from "components/elementsThemed";
import { Condition as Conditional } from "components/elements";
import { useCell } from "hooks";
import { images as imgSrc } from "utils";
import { formatPrice } from "utils/helpers/string";

import { ReactComponent as CheckMark } from "./check.svg";

import styles from "./item.module.scss";

const { Condition: If } = Conditional;

const Item = ({
  decrement,
  increment,
  item,
  multiSelect,
  onClick,
  quantity,
  type,
}) => {
  const { images = [], isNested = false, name = "", price = 0 } = item;
  const { labelTextStyles, views, buttons } = useCell(type);
  const nameWithoutSpace = name.toLowerCase().split(" ").join("-");
  let image = null;

  if (images?.[0]) {
    image = images[0].replace("http://", "https://");
  }

  return multiSelect ? (
    <React.Fragment>
      <Col xs="1-2" sm="1-2" md="1-3" className="itemContainer">
        <View
          type={views.background}
          className={styles.item}
          data-cy="modifier-add-button"
        >
          <button
            aria-label={`${name}${price > 0 ? ` $${price}` : ""}`}
            aria-pressed={quantity === 0 ? "false" : "true"}
            type="button"
            onClick={onClick}
            style={{
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              height: "100%",
              pointerEvents: quantity === 0 ? "auto" : "none",
              position: "absolute",
              width: "100%",
            }}
            id={nameWithoutSpace}
          />
          <div className={styles["item-content"]}>
            {image && quantity === 0 ? (
              <img className={styles["item-image"]} src={image} alt="" />
            ) : (
              quantity === 0 && (
                <div className={styles["item-placeholder"]}>
                  <div className={styles["item-placeholder-content"]} />
                </div>
              )
            )}
            {quantity !== 0 && (
              <div className={styles["item-qty"]}>
                <Button
                  aria-label={`Decrease ${name} quantity`}
                  type={buttons.primary}
                  className={styles["item-qty--btn"]}
                  onClick={() => {
                    const previousQuantity = quantity;
                    decrement();
                    if (previousQuantity === 1) {
                      document
                        .getElementById(
                          `${name.toLowerCase().split(" ").join("-")}`,
                        )
                        .focus();
                    }
                  }}
                >
                  <div aria-hidden="true">-</div>
                </Button>

                <View
                  type={views.secondary}
                  className={styles["item-qty--number"]}
                >
                  <Text type={labelTextStyles.tertiary} aria-live="polite">
                    <VisuallyHidden>{name} quantity: </VisuallyHidden>
                    {quantity}
                  </Text>
                </View>
                <Button
                  aria-label={`Increase ${name} quantity`}
                  type={buttons.primary}
                  className={styles["item-qty--btn"]}
                  onClick={increment}
                >
                  <div aria-hidden="true">+</div>
                </Button>
              </div>
            )}
            <Text
              type={labelTextStyles.primary}
              className={styles["item-title"]}
              aria-hidden="true"
            >
              <ClampLines
                id={1}
                buttons={false}
                text={name}
                lines={2}
                ellipsis="..."
              />
            </Text>
            <If is={price}>
              <Text
                type={labelTextStyles.secondary}
                className={styles["item-price"]}
                aria-hidden="true"
              >
                {`${price ? formatPrice(price) : ""}`}
              </Text>
            </If>
          </div>
          <If is={isNested}>
            <View
              type={views?.quaternary || views?.background}
              className={styles["item-nestedSelector"]}
            >
              <Text
                type={labelTextStyles?.quaternary || labelTextStyles?.primary}
              >
                MODIFY
              </Text>
            </View>
          </If>
        </View>
      </Col>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Col xs="1-2" sm="1-2" md="1-3" className="itemContainer">
        <View
          type={views.background}
          className={styles.item}
          data-cy="modifier-add-button"
        >
          <button
            aria-label={`${name}${price > 0 ? ` $${price}` : ""}`}
            aria-pressed={quantity === 0 ? "false" : "true"}
            type="button"
            onClick={onClick}
            style={{
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              height: "100%",
              width: "100%",
            }}
          >
            <div className={styles["item-content"]}>
              {image && quantity === 0 ? (
                <img className={styles["item-image"]} src={image} alt={name} />
              ) : (
                quantity === 0 && (
                  <div className={styles["item-placeholder"]}>
                    <div className={styles["item-placeholder-content"]} />
                  </div>
                )
              )}
              {quantity !== 0 && (
                <div className={styles["item-check"]}>
                  <div className={styles["item-check-overlay"]} />
                  {imgSrc?.icon_check ? (
                    <div className={styles["item-check-icon"]}>
                      <img src={imgSrc.icon_check} alt="" />
                    </div>
                  ) : (
                    <CheckMark
                      className={styles["item-check-icon"]}
                      role="button"
                    />
                  )}
                </div>
              )}
              <Text
                type={labelTextStyles.primary}
                className={styles["item-title"]}
                aria-hidden="true"
              >
                <ClampLines
                  id={1}
                  buttons={false}
                  text={name}
                  lines={2}
                  ellipsis="..."
                />
              </Text>
              <If is={price}>
                <Text
                  type={labelTextStyles.secondary}
                  className={styles["item-price"]}
                  aria-hidden="true"
                >
                  {`${price ? formatPrice(price) : ""}`}
                </Text>
              </If>
            </div>
            <If is={isNested}>
              <View
                type={views?.quaternary || views?.background}
                className={styles["item-nestedSelector"]}
              >
                <Text
                  type={labelTextStyles?.quaternary || labelTextStyles?.primary}
                >
                  MODIFY
                </Text>
              </View>
            </If>
          </button>
        </View>
      </Col>
    </React.Fragment>
  );
};

Item.displayName = "ModifierItemType10";

export default React.memo(Item);
