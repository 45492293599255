import * as React from "react";
import { useId } from "@react-aria/utils";
import classnames from "classnames";
import { Text } from "../../Text";
import FieldItem from "../fieldItem";
import withTemplateInput from "../withTemplateInput";
import css from "./input.module.scss";

const Input = ({
  input,
  label,
  name,
  className,
  icon,
  style,
  onChange,
  value,
  inputRef,
  disabled,
  error,
  htmlType,
  fieldClass,
  ...props
}) => {
  const inputClasses = classnames(css.input, css[props.type], className);
  const attributeId = useId(props.id);
  const errorId = useId();
  return (
    <FieldItem
      type={input.title}
      label={label}
      className={fieldClass}
      htmlFor={props.id || attributeId}
    >
      <div className={css["input-container"]}>
        <input
          id={attributeId}
          {...props}
          name={name || ""}
          type={htmlType}
          className={inputClasses}
          value={value}
          style={style}
          onChange={onChange}
          placeholder={props.placeholder}
          ref={inputRef}
          disabled={disabled}
          aria-describedby={error ? errorId : undefined}
          aria-invalid={error ? "true" : "false"}
        />
        {icon && <div className={css["input-suffix"]}>{icon}</div>}
      </div>
      {error && (
        <Text id={errorId} type={input.error}>
          {error}
        </Text>
      )}
    </FieldItem>
  );
};

export default withTemplateInput(Input);
