import * as React from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";

import { Schemas, Copy } from "utils";
import { PATRON_SIGN_IN, HANDLE_ERROR } from "utils/api";
import DrawerButton from "../../DrawerButton";
import { Field, Text, Button, View } from "components/elementsThemed";
import { withTemplate } from "components/hocs";

import styles from "../form.module.scss";

const { Input } = Field;

const Login = ({ onSuccess, onUpdatePassword, style, children }) => {
  const login = async (values, actions) => {
    actions.setStatus("");
    try {
      const res = await PATRON_SIGN_IN(values);
      if (onSuccess) onSuccess(res.data);
    } catch (error) {
      const e = HANDLE_ERROR(error);
      if (e.status === 422) {
        if (onUpdatePassword) onUpdatePassword(values.email, e.data);
      } else {
        actions.setStatus(e.data);
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      validateOnChange={false}
      initialValues={{ email: "", password: "" }}
      validationSchema={Schemas.LoginSchema}
      onSubmit={login}
      render={({ errors, values, status, setFieldValue, isSubmitting }) => (
        <View
          type={style.views.background}
          className={styles.form}
          Component={Form}
        >
          <div className={styles["fields-container"]}>
            <Input
              label={Copy.LOGIN_STATIC.EMAIL_INPUT_LABEL}
              name="email"
              type={style.inputs.standard}
              error={errors.email}
              value={values.email}
              placeholder={Copy.LOGIN_STATIC.EMAIL_INPUT_PLACEHOLDER}
              onChange={(e) => setFieldValue("email", e.target.value)}
              inputMode="email"
            />

            <Input
              label={Copy.LOGIN_STATIC.INPUT_LABEL}
              className="fs-mask"
              name="password"
              htmlType="password"
              type={style.inputs.standard}
              error={errors.password}
              value={values.password}
              placeholder={Copy.LOGIN_STATIC.INPUT_PLACEHOLDER}
              onChange={(e) => setFieldValue("password", e.target.value)}
            />

            <div>
              {status && <Text type={style.labels.error}>{status}</Text>}
            </div>
            <div>
              <Button
                type={style.buttons.link}
                Component={Link}
                to="/forgot-password"
                htmlType="button"
                data-text="forgot-password-button"
              >
                {Copy.LOGIN_STATIC.FORGOT_PASSWORD_TEXT}
              </Button>
            </div>
            {process.env.CLIENT === "bareburger" && (
              <Text type={style.labels.terms}>
                {Copy.LOGIN_STATIC.ALREADY_HAVE_APP_LOGIN_TEXT}
              </Text>
            )}
            {children && children}
          </div>

          <div className={styles.terms}>
            <Text type={style.labels.terms}>
              By logging in, you agree to Lunchbox's
              <br />
              <Button
                type={style.buttons.link}
                Component="a"
                href="https://www.lunchbox.io/terms-privacy"
                data-text="terms-of-service-button"
              >
                Terms of Service & Privacy Policy
              </Button>
            </Text>
          </div>
          <DrawerButton
            buttonProps={{ htmlType: "submit", disabled: isSubmitting }}
          >
            Login
          </DrawerButton>
        </View>
      )}
    />
  );
};

export default withTemplate(Login, "signin");
