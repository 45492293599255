import { toObject, populate, sortGroups, updateItems } from "../utils";

const mergeStateAndPayload = (state, payload) => ({
  array: [...state.array, ...payload],
  hash: { ...state.hash, ...toObject(payload) },
});

export const upsellsMenuReducer = (state, { type, payload }) => {
  switch (type) {
    case "ADD": {
      const dedupedPayload = Object.entries(payload).reduce(
        (accu, [key, value]) => ({
          ...accu,
          [key]: value.filter(({ id }) => !state[key].hash[id]),
        }),
        {
          groups: [],
          items: [],
          menus: [],
          options: [],
        },
      );

      const menusMapped = populate(dedupedPayload, "menus", "groups");
      const groupsMapped = sortGroups(
        populate(dedupedPayload, "groups", "items"),
      );
      const itemsMapped = populate(dedupedPayload, "items", "options");
      const optionsMapped = populate(dedupedPayload, "options", "items");
      const updatedItems = updateItems(itemsMapped, true);

      return {
        ...state,
        groups: mergeStateAndPayload(state.groups, groupsMapped),
        items: mergeStateAndPayload(state.items, updatedItems),
        menus: mergeStateAndPayload(state.menus, menusMapped),
        options: mergeStateAndPayload(state.options, optionsMapped),
      };
    }
    case "CLEAR": {
      return {
        groups: {
          array: [],
          hash: {},
        },
        items: {
          array: [],
          hash: {},
        },
        menus: {
          array: [],
          hash: {},
        },
        options: {
          array: [],
          hash: {},
        },
      };
    }
    default:
      return state;
  }
};
