import { useEffect, useState, useRef, useCallback } from "react";

const useSelfDestructState = <T>(initState: Partial<T> = {}, time: number) => {
  const [value, setValue] = useState<Partial<T> | undefined>(initState);
  const timeoutHandle = useRef<number>();

  const resetTimer = useCallback(() => {
    window.clearTimeout(timeoutHandle.current);
    timeoutHandle.current = window.setTimeout(() => {
      setValue(undefined);
      resetTimer();
    }, time);
  }, [time]);

  const setState = (_value?: Partial<T>) => {
    setValue(_value);
    resetTimer();
  };

  useEffect(() => {
    if (!window) return;

    resetTimer();
  }, []);

  return [value, setState];
};

export default useSelfDestructState;
