import * as React from "react";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import csx from "classnames";

import { InlineLoader } from "components/elements";
import { Copy } from "utils";
import { PATRON_ADD_CARD, HANDLE_ERROR } from "utils/api";
import DrawerButton from "../../../DrawerButton";
import { Text } from "components/elementsThemed";
import { useTemplateContext } from "components/providers/Template";

import { mapTokenizeError } from "./utils";

import css from "./stripe.module.scss";

const CheckoutForm = (props) => {
  const {
    style: { labels },
    locationId,
    onSuccess,
  } = props;
  const [err, setErr] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const templateContext = useTemplateContext();
  const { colors } = templateContext.theme;
  const textColor = colors[labels.primary.split("_")?.[1]];

  const createOptions = {
    style: {
      base: {
        "::placeholder": {
          color: "#aab7c4",
        },
        color: textColor,
        fontFamily: "Open Sans, sans-serif",
        fontSize: "20px",
        letterSpacing: "0.025em",
      },
      invalid: {
        color: "#c23d4b",
      },
    },
  };

  const handleFocus = () => setErr("");

  const submit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const cardNumberElement = elements.getElement(CardNumberElement);

    // e.g. createToken - https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement
    const { token, error: tokenizeError } = await stripe.createToken(
      cardNumberElement,
    );

    if (tokenizeError && Object.keys(tokenizeError).length) {
      const tokenizeErrorMessage = mapTokenizeError(tokenizeError);
      setErr(tokenizeErrorMessage);
      setSubmitting(false);
      return;
    }

    const data = {
      cardNonce: `${token.id}`,
    };
    try {
      await PATRON_ADD_CARD(data, { locationId });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      const mappedError = HANDLE_ERROR(error);
      setErr(
        mappedError.data ||
          "Processing a payment with the card you have provided was declined, please make sure you've provided the correct card information.",
      );
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className={csx(css["stripeForm-container"], "payment-form")}>
        <div className={css["stripeForm-items"]}>
          <div data-pl="card-number" className={css["stripeForm-items-row"]}>
            <CardNumberElement onFocus={handleFocus} options={createOptions} />
          </div>
          <div className={css["stripeForm-items-row"]}>
            <div data-pl="exp-date" className={css["stripeForm-items-column"]}>
              <CardExpiryElement
                onFocus={handleFocus}
                options={createOptions}
              />
            </div>
            <div data-pl="cvc" className={css["stripeForm-items-column"]}>
              <CardCvcElement onFocus={handleFocus} options={createOptions} />
            </div>
          </div>
        </div>

        {err && <Text type={labels.error}>{err}</Text>}
      </div>
      <DrawerButton buttonProps={{ id: "my-submit", onClick: submit }}>
        {submitting ? (
          <InlineLoader size={24} />
        ) : (
          Copy.PAYMENT_FORM_STATIC.PAYMENT_FORM_SAVE_BUTTON_TEXT
        )}
      </DrawerButton>
    </>
  );
};

export default ({ apiKey, ...props }) => {
  const ref = React.useRef(loadStripe(apiKey));
  return (
    <Elements stripe={ref.current}>
      <CheckoutForm {...props} />
    </Elements>
  );
};
