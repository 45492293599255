import * as React from "react";
import { motion } from "framer-motion";
import { useCell } from "hooks";
import { View, Text } from "components/elementsThemed";
import { FocusScope } from "@react-aria/focus";
import Option from "../../Options";
import FooterSelector from "../../Footer";
import NestedModifier from "../index";
import styles from "./nestedModifiersType1.module.scss";

const findError = (errors, id) => {
  const match = errors.find((i) => i.optionId === id);
  if (match) {
    return match.message;
  }
  return null;
};

const getScrollOffset = (node, additionalOffset) => {
  if (!node) return 0;
  return node.offsetTop - additionalOffset;
};

const NestedModifiersType1 = ({
  addNestedModifier,
  closeDrawer,
  decrement,
  errors,
  increment,
  key,
  modifications,
  name,
  nestedDrawer,
  onClose,
  onSubmit,
  options,
  optionsHash,
  quantity,
  removeNestedModifier,
  selectedMods = [],
  showQuantity,
  style,
}) => {
  const errorRefs = React.useRef({});
  const { cells, views } = style;
  const { labelTextStyles } = useCell(cells.group);

  return (
    <>
      <div className={styles.nestedModifier}>
        <motion.div
          role="button"
          tabIndex="-1"
          onClick={onClose}
          className={styles["nestedModifier-tint"]}
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.2 }}
          exit={{ opacity: 0 }}
        />
        <motion.div
          key={key}
          className={styles["nestedModifier-wrapper"]}
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "-100%" }}
          transition={{ ease: "linear" }}
        >
          <FocusScope contain restoreFocus autoFocus>
            <View
              role="dialog"
              aria-modal="true"
              aria-label={`Modify ${name}`}
              onKeyDown={(e) => {
                if (e.key === "Escape") onClose(e);
              }}
              type={views.background}
              className={styles["nestedModifier-container"]}
            >
              <div className={styles["nestedModifier-content"]}>
                <div className={styles["nestedModifier-header"]}>
                  <Text type={labelTextStyles.primary}>{name}</Text>
                </div>
                <div className={styles["nestedModifier-innerContent"]}>
                  {options.map((option, index) => {
                    return (
                      <Option
                        key={option.id}
                        errorRef={(node) => {
                          errorRefs.current[option.id] = {
                            index,
                            node,
                            optionId: option.id,
                            scrollToPosition: getScrollOffset(node),
                          };
                        }}
                        type={cells.group}
                        optionItemType={cells.primary}
                        optionItemTypes={{
                          default: cells.primary,
                          selected: cells.primarySelected,
                        }}
                        {...optionsHash[option.id]}
                        error={findError(errors, option.id)}
                        selectedMods={modifications[option.id] || []}
                        addModifier={addNestedModifier}
                        removeModifier={removeNestedModifier}
                      />
                    );
                  })}
                </div>
              </div>
              <FooterSelector
                buttonText="Save"
                buttonType={cells.bottom}
                decQuantity={decrement}
                incQuantity={increment}
                onClick={onSubmit}
                quantity={quantity}
                quantityType={cells.quantity}
                showQuantity={showQuantity}
                data-cy="nested-atc-btn"
              />
            </View>
          </FocusScope>
        </motion.div>
      </div>

      {!!Object.keys(nestedDrawer).length && (
        <NestedModifier
          addModifier={addNestedModifier}
          errors={errors}
          onClose={closeDrawer}
          parent={{
            item: nestedDrawer?.item,
            modifiers: nestedDrawer?.modifiers,
            option: nestedDrawer?.option,
            quantity: nestedDrawer?.quantity,
          }}
          removeModifier={removeNestedModifier}
          showQuantity={nestedDrawer?.multiSelect}
          style={style}
        />
      )}
    </>
  );
};

export default NestedModifiersType1;
