import * as React from "react";
import { withTemplate } from "components/hocs";
import { Routes } from "utils";
import Login from "../../../Login";

const GuestCheckout = ({ history }) => {
  return (
    <Login
      history={history}
      onGuestCheckout={() => history.replace(Routes.CHECK_OUT)}
      isCart
    />
  );
};

export default withTemplate(GuestCheckout, "order");
