import * as React from "react";
import { Col } from "@lunchboxinc/lunchbox-components";
import csx from "classnames";
import { Text, View, Button } from "components/elementsThemed";
import { Condition as Conditional } from "components/elements";
import { useCell } from "hooks";
import { formatPrice } from "utils/helpers/string";

import { ReactComponent as Arrow } from "./arrow.svg";
import { ReactComponent as Ex } from "./ex.svg";
import { ReactComponent as Minus } from "./minus.svg";
import { ReactComponent as Plus } from "./plus.svg";
import css from "./item.module.scss";

const { Condition: If } = Conditional;

const Item = ({
  decrement,
  increment,
  item,
  multiSelect,
  onClick,
  quantity,
  type,
}) => {
  const {
    description = "",
    images = [],
    isNested = false,
    name = "",
    price = 0,
  } = item;
  const { labelTextStyles, views, buttons } = useCell(type);
  const style = {};

  if (images[0]) {
    style.backgroundImage = `url(${images[0].replace("http://", "https://")})`;
  }

  const incrementClass = csx(
    css.increment,
    css["modifierItem-col"],
    quantity && multiSelect
      ? css["modifierItem-col--active"]
      : css["modifierItem-col--inactive"],
  );
  const decrementClass = csx(
    css.decrement,
    css["modifierItem-col"],
    css[`modifierItem-col--${quantity ? "active" : "inactive"}`],
  );

  return (
    <Col xs="1-2" sm="1-3" md="1-3" className={css.modifierItem}>
      <View
        type={views.background}
        className={css["modifierItem-wrapper"]}
        onClick={!multiSelect || (multiSelect && !quantity) ? onClick : null}
        data-cy="modifier-add-button"
        style={style}
      >
        <div
          className={`${css["modifierItem-content"]} ${
            css[`modifierItem-content--${quantity ? "active" : "inactive"}`]
          }`}
        >
          <div className={description ? css["modifierItem-content--name"] : ""}>
            <Text type={labelTextStyles.primary}>{name}</Text>

            <If is={isNested}>
              <div className={css["modifierItem-nestedSelector"]}>
                <Arrow fill="#fff" />
              </div>
            </If>
          </div>
        </div>
        {description && (
          <div className={css["modifierItem-content--desc"]}>
            <Text type={labelTextStyles.secondary}>{description}</Text>
          </div>
        )}
        <If is={price}>
          <div className={css["modifierItem-content--price"]}>
            <Text type={labelTextStyles.secondary}>{formatPrice(price)}</Text>
          </div>
        </If>
        <If is={!isNested}>
          <div className={css["modifierItem-action"]}>
            <div className={decrementClass}>
              <Button
                className={css["modifierItem-btn"]}
                type={buttons.primary}
                onClick={multiSelect ? decrement : onClick}
              >
                {multiSelect ? <Minus fill="#fff" /> : <Ex fill="#fff" />}
              </Button>
            </div>

            <div className={incrementClass}>
              <Button
                className={css["modifierItem-btn"]}
                type={buttons.primary}
                onClick={increment}
              >
                <Plus fill="#fff" />
              </Button>
            </div>
            {quantity > 0 && multiSelect && (
              <div
                className={`${css.qty} ${css["modifierItem-col"]} ${
                  css[`modifierItem-col--active`]
                }`}
              >
                <Text type={labelTextStyles.primary}>{quantity}</Text>
              </div>
            )}
          </div>
        </If>
      </View>
    </Col>
  );
};

export default React.memo(Item);
