import * as React from "react";
import { Layout } from "components/elements";
import { View } from "components/elementsThemed";
import { QuantitySelector } from "components/fragments";
import { useCell } from "hooks";
import DrawerButton from "../../../../DrawerButton";
import css from "./footerType2.module.css";

const { Flex } = Layout;

function Footer({
  showQuantity,
  quantityType,
  quantity,
  incQuantity,
  decQuantity,
  onClick,
  buttonText,
  name,
  "data-cy": dataCy,
  isDisabled,
}) {
  const { views, buttons, labelTextStyles } = useCell(quantityType);

  return (
    <Flex className={css.container} direction="row" align="end">
      {showQuantity && (
        <View Component={Flex} grow="1" className={css.quantity}>
          <QuantitySelector
            name={name}
            viewType={views.secondary}
            buttonType={buttons.primary}
            labelType={labelTextStyles.primary}
            quantity={quantity}
            inc={incQuantity}
            dec={decQuantity}
          />
        </View>
      )}
      <Flex grow="3" className={css.button}>
        <DrawerButton
          buttonProps={{ onClick, disabled: isDisabled, dataCy }}
          containerProps={{ containerClass: css["add-to-cart"] }}
        >
          {buttonText}
        </DrawerButton>
      </Flex>
    </Flex>
  );
}

export default Footer;
