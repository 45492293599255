import * as React from "react";
import csx from "classnames";
import ClampLines from "react-clamp-lines";
import { Text, View } from "components/elementsThemed";
import { Image } from "components/fragments";
import { useCell } from "hooks";
import { config } from "utils";
import css from "./rewardCardType1.module.scss";

const { images } = config;

/**
 * Render rewards stubs
 *
 * @author Htin Linn Aung
 * @memberof Templates.Templates/Loyalty
 * @param {object} props
 * @param {object} props.type - Cell Type used to style the card
 * @param {string} props.name - Name of the Reward
 * @param {string} props.description - Reward description
 * @param {string} props.callToAction - CTA provided by the backend
 * @param {Function} props.onClick - Callback fired when a reward card is clicked
 * @function RewardCardType1
 */
const RewardCardType1 = ({
  type = "",
  name = "",
  description = "",
  callToAction = "",
  onClick,
}) => {
  const { labelTextStyles, views } = useCell(type);

  return (
    <View
      type={views.background}
      className={csx(css.rewardCard, onClick && css["rewardCard--click"])}
      onClick={onClick}
    >
      <View type={views.primary} className={css["rewardCard-img--container"]}>
        <Image
          className={css["rewardCard-img--image"]}
          src={images?.loyalty_rewards_stub_icon}
          alt="rewards-logo"
        />
      </View>
      <View
        type={views.secondary}
        xs="4-5"
        className={css["rewardCard--content"]}
      >
        <div>
          <Text type={labelTextStyles.primary}>
            <ClampLines
              id={1}
              buttons={false}
              text={name || ""}
              lines={1}
              ellipsis="..."
            />
          </Text>
        </div>
        <div>
          <Text type={labelTextStyles.secondary}>
            <ClampLines
              id={1}
              buttons={false}
              text={description || ""}
              lines={2}
              ellipsis="..."
            />
          </Text>
        </div>
        <div>
          <Text type={labelTextStyles.tertiary}>
            <ClampLines
              id={1}
              buttons={false}
              text={callToAction || ""}
              lines={2}
              ellipsis="..."
            />
          </Text>
        </div>
      </View>
    </View>
  );
};

export { RewardCardType1 };
export default RewardCardType1;
