import uuidv4 from "uuid/v4";

const { BUILD_ENV } = process.env;

const LOCAL = "local";
const STAGE = "stage";
const PRODUCTION = "production";
const DEVELOPMENT = "development";

const EMAIL = "email";
const PHONE = "phone";

const APPLE = "apple";
const GOOGLE = "google";
const FACEBOOK = "facebook";

const ENVIRONMENTS = {
  DEVELOPMENT,
  LOCAL,
  PRODUCTION,
  STAGE,
};

const GOOGLE_RECAPTCHA_KEY = "6LdTcTYaAAAAAAQ5nZDmGwRRDN_MS2R_MqsVl6F2";
const SESSION_ID = uuidv4();
const SIFT_BEACON_KEY = BUILD_ENV === PRODUCTION ? "9c10cdbac9" : "a066987159";
const BEAM_BASE_URL = [STAGE, PRODUCTION].includes(BUILD_ENV)
  ? "https://wprod.sdk.beamimpact.com"
  : "https://webstaging.sdk.beamimpact.com";

// Order types that the website can support

// dine in and kiosk must be separate items short term due to a routing bug -- route looks at the URL which uses dine-in slug, but other order logic uses orderType of kiosk. This is a short term solution to this to allow market kiosk to work as intended.
const ORDER_TYPES = {
  DELIVERY: "delivery",
  DINEIN: "dine-in",
  KIOSK: "kiosk",
  PICKUP: "pickup",
};

const MODIFIER_TYPES = {
  PLACEMENT: "placement",
  OPTION: "option",
  INLINE: "inline",
  AMOUNT: "amount",
};

const ACCOUNT_TYPES = {
  EMAIL,
  PHONE,
};

const SOCIAL_PLATFORMS = {
  APPLE,
  FACEBOOK,
  GOOGLE,
};

const STATES = [
  {
    abbreviation: "AL",
    name: "Alabama",
  },
  {
    abbreviation: "AK",
    name: "Alaska",
  },
  {
    abbreviation: "AZ",
    name: "Arizona",
  },
  {
    abbreviation: "AR",
    name: "Arkansas",
  },
  {
    abbreviation: "CA",
    name: "California",
  },
  {
    abbreviation: "CO",
    name: "Colorado",
  },
  {
    abbreviation: "CT",
    name: "Connecticut",
  },
  {
    abbreviation: "DE",
    name: "Delaware",
  },
  {
    abbreviation: "DC",
    name: "District Of Columbia",
  },
  {
    abbreviation: "FL",
    name: "Florida",
  },
  {
    abbreviation: "GA",
    name: "Georgia",
  },
  {
    abbreviation: "HI",
    name: "Hawaii",
  },
  {
    abbreviation: "ID",
    name: "Idaho",
  },
  {
    abbreviation: "IL",
    name: "Illinois",
  },
  {
    abbreviation: "IN",
    name: "Indiana",
  },
  {
    abbreviation: "IA",
    name: "Iowa",
  },
  {
    abbreviation: "KS",
    name: "Kansas",
  },
  {
    abbreviation: "KY",
    name: "Kentucky",
  },
  {
    abbreviation: "LA",
    name: "Louisiana",
  },
  {
    abbreviation: "ME",
    name: "Maine",
  },
  {
    abbreviation: "MD",
    name: "Maryland",
  },
  {
    abbreviation: "MA",
    name: "Massachusetts",
  },
  {
    abbreviation: "MI",
    name: "Michigan",
  },
  {
    abbreviation: "MN",
    name: "Minnesota",
  },
  {
    abbreviation: "MS",
    name: "Mississippi",
  },
  {
    abbreviation: "MO",
    name: "Missouri",
  },
  {
    abbreviation: "MT",
    name: "Montana",
  },
  {
    abbreviation: "NE",
    name: "Nebraska",
  },
  {
    abbreviation: "NV",
    name: "Nevada",
  },
  {
    abbreviation: "NH",
    name: "New Hampshire",
  },
  {
    abbreviation: "NJ",
    name: "New Jersey",
  },
  {
    abbreviation: "NM",
    name: "New Mexico",
  },
  {
    abbreviation: "NY",
    name: "New York",
  },
  {
    abbreviation: "NC",
    name: "North Carolina",
  },
  {
    abbreviation: "ND",
    name: "North Dakota",
  },
  {
    abbreviation: "OH",
    name: "Ohio",
  },
  {
    abbreviation: "OK",
    name: "Oklahoma",
  },
  {
    abbreviation: "OR",
    name: "Oregon",
  },
  {
    abbreviation: "PA",
    name: "Pennsylvania",
  },
  {
    abbreviation: "RI",
    name: "Rhode Island",
  },
  {
    abbreviation: "SC",
    name: "South Carolina",
  },
  {
    abbreviation: "SD",
    name: "South Dakota",
  },
  {
    abbreviation: "TN",
    name: "Tennessee",
  },
  {
    abbreviation: "TX",
    name: "Texas",
  },
  {
    abbreviation: "UT",
    name: "Utah",
  },
  {
    abbreviation: "VT",
    name: "Vermont",
  },
  {
    abbreviation: "VA",
    name: "Virginia",
  },
  {
    abbreviation: "WA",
    name: "Washington",
  },
  {
    abbreviation: "WV",
    name: "West Virginia",
  },
  {
    abbreviation: "WI",
    name: "Wisconsin",
  },
  {
    abbreviation: "WY",
    name: "Wyoming",
  },
];

const PLATFORM = (() => {
  let browser;
  let version;
  let mobile;
  let os;
  let osversion;
  let bit;
  // const ua = window.navigator.userAgent;
  // const platform = window.navigator.platform;

  // if (/MSIE/.test(ua)) {
  //   browser = 'Internet Explorer';
  //   if (/IEMobile/.test(ua)) {
  //     mobile = 1;
  //   }
  //
  //   version = /MSIE \d+[.]\d+/.exec(ua)[0].split(' ')[1];
  // } else if (/Chrome/.test(ua)) {
  //   // Platform override for Chromebooks
  //   if (/CrOS/.test(ua)) {
  //     platform = 'CrOS';
  //   }
  //
  //   browser = 'Chrome';
  //   version = /Chrome\/[\d\.]+/.exec(ua)[0].split('/')[1];
  // } else if (/Opera/.test(ua)) {
  //   browser = 'Opera';
  //
  //   if (/mini/.test(ua) || /Mobile/.test(ua)) {
  //     mobile = 1;
  //   }
  // } else if (/Android/.test(ua)) {
  //   browser = 'Android Webkit Browser';
  //   mobile = 1;
  //   os = /Android\s[\.\d]+/.exec(ua)[0];
  // } else if (/Firefox/.test(ua)) {
  //   browser = 'Firefox';
  //
  //   if (/Fennec/.test(ua)) {
  //     mobile = 1;
  //   }
  //   version = /Firefox\/[\.\d]+/.exec(ua)[0].split('/')[1];
  // } else if (/Safari/.test(ua)) {
  //   browser = 'Safari';
  //
  //   if ((/iPhone/.test(ua)) || (/iPad/.test(ua)) || (/iPod/.test(ua))) {
  //     os = 'iOS';
  //     mobile = 1;
  //   }
  // }
  //
  // if (!version) {
  //   version = /Version\/[\.\d]+/.exec(ua);
  //
  //   if (version) {
  //     version = version[0].split('/')[1];
  //   } else {
  //     version = /Opera\/[\.\d]+/.exec(ua)[0].split('/')[1];
  //   }
  // }
  //
  // if (platform === 'MacIntel' || platform === 'MacPPC') {
  //   os = 'Mac OS X';
  //   osversion = /10[\.\_\d]+/.exec(ua)[0];
  //   if (/[\_]/.test(osversion)) {
  //     osversion = osversion.split('_').join('.');
  //   }
  // } else if (platform === 'CrOS') {
  //   os = 'ChromeOS';
  // } else if (platform === 'Win32' || platform == 'Win64') {
  //   os = 'Windows';
  //   bit = platform.replace(/[^0-9]+/, '');
  // } else if (!os && /Android/.test(ua)) {
  //   os = 'Android';
  // } else if (!os && /Linux/.test(platform)) {
  //   os = 'Linux';
  // } else if (!os && /Windows/.test(ua)) {
  //   os = 'Windows';
  // }

  return {
    bit,
    browser,
    mobile,
    os,
    osversion,
    version,
  };
})();

const IS_INTERSECTION_OBSERVER_SUPPORTED = (() => {
  if (
    !window ||
    !("IntersectionObserver" in window) ||
    !("IntersectionObserverEntry" in window) ||
    !("intersectionRatio" in window?.IntersectionObserverEntry?.prototype)
  ) {
    return false;
  }
  return true;
})();

export const AM = "AM";

export const PM = "PM";

const TIMEZONES = {
  "America/Adak": "Alaska Standard Time (AKST)",
  "America/Anchorage": "Alaska Standard Time (AKST)",
  "America/Chicago": "Central Standard Time (CST)",
  "America/Denver": "Mountain Standard Time (MST)",
  "America/Los_Angeles": "Pacific Standard Time (PST)",
  "America/New_York": "Eastern Standard Time (EST)",
  "America/Phoenix": "Mountain Standard Time (MST)",
  "Pacific/Honolulu": "Standard Time (HST)",
};

const TIMEZONES_DST = {
  "America/Adak": "Alaska Daylight Time (AKDT)",
  "America/Anchorage": "Alaska Daylight Time (AKDT)",
  "America/Chicago": "Central Standard Time (CST)",
  "America/Denver": "Mountain Daylight Time (MDT)",
  "America/Los_Angeles": "Pacific Daylight Time (PDT)",
  "America/New_York": "Eastern Daylight Time (EDT)",
  "America/Phoenix": "Mountain Daylight Time (MDT)",
  "Pacific/Honolulu": "Hawaii Standard Time (HST)",
};

export const DAYS_SHORT = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];

// Loyalty Systems
const LOYALTY_SYSTEMS = {
  BANK: "bank",
  CREDIT: "credit",
  DEAL: "deal",
  TIER: "tier",
  VISIT: "visit",
  WALLET: "wallet",
};

const DATA_TEST = {
  CART_FOOTER_CHECK_OUT: "cart-footer-check-out",
  CART_FOOTER_CONTINUE_CHECKOUT: "cart-footer-continue-checkout",
  CART_FOOTER_CREATE_LINK: "cart-footer-create-link",
  CART_FOOTER_LOG_IN: "cart-footer-log-in",
  CART_FOOTER_NEXT: "cart-footer-next",
  CART_FOOTER_PLACE_ORDER: "cart-footer-place-order",
};

/**
 * TODO ANALYTIC EVENT CONSTANTS SHOULD BE SEPARATE FROM ANALYTIC PROVIDERS (GOOGLE FACEBOOK ETC)
 */
const ANALYTICS = {
  APPSFLYER: "appsflyer",
  BRAND_SELECTED: "Brand Selected",
  CART_CLEARED: "Cleared Cart",
  CART_VIEWED: "Cart Viewed",
  CHECKOUT_STARTED: "Checkout Started",
  CLIENT_IDENTIFIED: "Client Identified",
  FACEBOOK_PIXEL: "facebook_pixel",
  FULLSTORY: "fullstory",
  GOOGLE_ANALYTICS: "google_analytics",
  GOOGLE_TAG_MANAGER: "google_tag_manager",
  LOCATION_SELECTED: "Location Selected",
  PRODUCT_ADDED: "Product Added",
  PRODUCT_CLICKED: "Product Clicked",
  PRODUCT_MODIFIED: "Product Modified",
  PRODUCT_REMOVED: "Product Removed",
  PURCHASE: "Purchase",
  SEGMENT: "segment",
  SIGNED_IN: "Signed In",
  UNNAMED_EVENT: "Unnamed Event",
};

const TRACKING_STATUSES = {
  ORDER_PICKUP: "order pickup",
  ORDER_RECEIVED: "order received",
  OUT_FOR_DELIVERY: "out for delivery",
};

/**
 * Possible res.discount.statuses from POST /v0/order/validate endpoint
 * (Purposely not exporting from default exports as we are moving away from that)
 * */
const VALIDATE_ORDER_DISCOUNT_STATUSES = {
  ORDER_MINIMUM: "order-minimum",
};

export const BREAKPOINTS = [
  {
    name: "sm",
    width: 48,
  },
  {
    name: "md",
    width: 60,
  },
  {
    name: "lg",
    width: 72,
  },
  {
    name: "xl",
    width: 84,
  },
  {
    name: "xxl",
    width: 96,
  },
];

export {
  VALIDATE_ORDER_DISCOUNT_STATUSES,
  ACCOUNT_TYPES,
  ANALYTICS,
  BEAM_BASE_URL,
  DATA_TEST,
  ENVIRONMENTS,
  GOOGLE_RECAPTCHA_KEY,
  IS_INTERSECTION_OBSERVER_SUPPORTED,
  LOYALTY_SYSTEMS,
  MODIFIER_TYPES,
  ORDER_TYPES,
  PLATFORM,
  SESSION_ID,
  SIFT_BEACON_KEY,
  SOCIAL_PLATFORMS,
  STATES,
  TIMEZONES,
  TIMEZONES_DST,
  TRACKING_STATUSES,
};

export default {
  ACCOUNT_TYPES,
  ANALYTICS,
  BEAM_BASE_URL,
  DATA_TEST,
  ENVIRONMENTS,
  GOOGLE_RECAPTCHA_KEY,
  IS_INTERSECTION_OBSERVER_SUPPORTED,
  LOYALTY_SYSTEMS,
  MODIFIER_TYPES,
  ORDER_TYPES,
  PLATFORM,
  SESSION_ID,
  SIFT_BEACON_KEY,
  SOCIAL_PLATFORMS,
  STATES,
  TIMEZONES,
  TIMEZONES_DST,
};
