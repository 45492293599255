import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { Text, View } from "components/elementsThemed";
import { useCell } from "hooks";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import styles from "./option.module.css";

function Option({ error, forwardRef, id, modifierItems, name, type }) {
  const { views, labelTextStyles } = useCell(type);

  return (
    <>
      <View type={views.background} className={styles.option} data-id={id}>
        <Row spacing={15}>
          <Col>
            <Text type={labelTextStyles.secondary} className={styles.name}>
              {name}
            </Text>
          </Col>

          <div aria-live="polite">
            {error && (
              <div ref={forwardRef}>
                <Text type={labelTextStyles.tertiary}>
                  <VisuallyHidden>Error: </VisuallyHidden>
                  {error}
                </Text>
              </div>
            )}
          </div>
        </Row>
      </View>

      <Row flex sxpacing={10} gutter={20}>
        {modifierItems}
      </Row>
    </>
  );
}

Option.displayName = "OptionType2";

export default React.memo(Option);
