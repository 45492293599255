import * as React from "react";
import { getDisplayName } from "utils/helpers/other";

export default function withTypeSelector<P>(
  typesObject: Record<string, React.ComponentType<P>>,
  envKey: string,
  defaultValue: React.ComponentType<P>,
) {
  const Component = typesObject[envKey] ? typesObject[envKey] : defaultValue;

  const withTypeSelectorComponent = (props: P) => (
    <Component {...(props as P)} />
  );

  withTypeSelectorComponent.displayName = `withTypeSelector(${getDisplayName(
    Component,
  )})`;

  return withTypeSelectorComponent;
}

export { withTypeSelector };
