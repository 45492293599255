import * as React from "react";

import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { ElementsThemed, HOCs } from "components";
import styles from "./index.module.scss";

const { Text, Button, View } = ElementsThemed;
const { withTemplate } = HOCs;

const RemoveDiscount = ({ onConfirm, onCancel, style }) => (
  <>
    <View className={styles.background} type={style.views.background} />
    <div className={styles.confirm}>
      <div className={styles.dialogue}>
        <Text type={style.labels.title}>
          Are you sure you want to clear promos
        </Text>
      </div>
      <Col
        xs={{ offset: "1-12", span: "5-6" }}
        sm={{ offset: "1-8", span: "3-4" }}
        style={{
          marginLeft: 0,
        }}
      >
        <Row gutter={60}>
          <Col xs="1-2">
            <Button
              block
              size="md"
              type={style.buttons.negConfirm}
              onClick={onConfirm}
            >
              Remove
            </Button>
          </Col>
          <Col xs="1-2">
            <Button
              block
              size="md"
              type={style.buttons.cancel}
              onClick={onCancel}
            >
              Go Back
            </Button>
          </Col>
        </Row>
      </Col>
    </div>
  </>
);

export default withTemplate(RemoveDiscount, "confirm");
