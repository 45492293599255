import * as React from "react";
import { isJSON } from "utils/helpers/json";
import { Text } from "components/elementsThemed";
import Jsx from "../Jsx";

const JSONToComponent = ({ json }) => {
  if (typeof json === "string" && isJSON(json)) {
    // eslint-disable-next-line no-param-reassign
    json = JSON.parse(json);
  }
  return json.map((i) => (
    <>
      <Jsx key={i.idx} {...i} tag={i.tag === "Text" ? Text : i.tag} />
      {i.tag !== "br" ? (
        // eslint-disable-next-line react/no-danger
        <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />
      ) : (
        ""
      )}
    </>
  ));
};

export default JSONToComponent;
