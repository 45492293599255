import * as React from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "hooks";
import { parseHash, parseURL } from "./utils";

const Context = React.createContext();
const Provider = ({ children }) => {
  const [lastAppleLocation, setLastAppleLocation] =
    useLocalStorage("lastAppleLocation");
  const [lastFacebookLocation, setLastFacebookLocation] = useLocalStorage(
    "lastFacebookLocation",
  );
  const [urlState] = React.useState(() => {
    const { href } = window.location;
    const urlComponents = parseURL(href);
    return {
      ...urlComponents,
      appleCredentials: parseHash(urlComponents.hash),
      facebookCredentials: parseHash(urlComponents.hash),
    };
  });

  const [isLocationModalOpen, setIsLocationModalOpen] = React.useState(false);
  const routerLocation = useLocation();

  React.useEffect(() => {
    const searchQuery = new URLSearchParams(routerLocation.search);
    const isLocationModalOpen = Boolean(searchQuery.get("isLocationModalOpen"));

    if (isLocationModalOpen) {
      setIsLocationModalOpen(true);
      return;
    }
    setIsLocationModalOpen(false);
  }, [routerLocation]);

  const unsetLastAppleLocation = () => {
    setLastAppleLocation(undefined);
  };
  const unsetLastFacebookLocation = () => {
    setLastFacebookLocation(undefined);
  };

  const isAppleSignUp =
    urlState?.appleCredentials?.query?.state && lastAppleLocation?.date;
  const isFacebookSignUp =
    urlState?.facebookCredentials?.query?.state && lastFacebookLocation?.date;

  const appleCredentials = isAppleSignUp
    ? {
        code: urlState?.appleCredentials?.query?.code,
        id_token: urlState?.appleCredentials?.query?.id_token,
      }
    : null;
  const facebookCredentials = isFacebookSignUp
    ? {
        accessToken: urlState?.facebookCredentials?.query?.access_token,
      }
    : null;

  let lastLocation = null;
  if (isAppleSignUp || lastAppleLocation?.source) {
    lastLocation = lastAppleLocation.source;
  } else if (isFacebookSignUp || lastFacebookLocation?.source) {
    lastLocation = lastFacebookLocation.source;
  }

  const contextValues = {
    appleCredentials,
    facebookCredentials,
    isAppleSignUp,
    isFacebookSignUp,
    isLocationModalOpen,
    lastLocation,
    unsetLastAppleLocation,
    unsetLastFacebookLocation,
    urlState,
  };

  return <Context.Provider value={contextValues}>{children}</Context.Provider>;
};

const useGlobalConfig = () => {
  const contextValues = React.useContext(Context);
  if (!contextValues) {
    throw new Error("Context is being accessed outside a Provider");
  }
  return contextValues;
};

export { useGlobalConfig };
export default {
  GlobalConfigContext: Context,
  Provider,
};
