import Axios from "axios";
import Qs from "qs";
import * as Sentry from "@sentry/browser";
// eslint-disable-next-line import/no-unresolved
import { version } from "PackageData";
import { merge } from "lodash";
import config from "./config";
import { getSchemaFromResponseUrl } from "./helpers/responseSchemas";
import { SESSION_ID } from "./constants";

const {
  LBX_PATRON_URL,
  LBX_PATRON_VERSION,
  LBX_API_URL: LBX_AUTOMATION_URL,
} = process.env;
/**
 * Given an Axios configuration, return an Axios instance with interceptors required by the application.
 *
 * @param
 * @param {object} customConfig - Pass in the custom configurations (e.g: different baseURL)
 * @returns {any} Returns custom defaults in the header that is required for lunchbox API calls.
 */
function createAxiosInstance(customConfig) {
  // ("https://patron.lunchbox.io");

  const url = `${LBX_PATRON_URL}${
    LBX_PATRON_VERSION ? `/${LBX_PATRON_VERSION}` : ""
  }`;
  const tokenKey = config.local_storage_key;
  const isMarketPlaceEnabled = config?.market_place?.enabled;
  /**
   * If the build environment is not production and  FORCE_APP_ID node env is provided use that value
   * FORCE_APP_ID lets us bypass the ID inside of common.json. Used for CI E2E testing.
   * Fallback to common.json ID if FORCE APP ID is not provided but also no production
   */
  let client = config.id;
  if (process.env.BUILD_ENV !== "production" && process.env.FORCE_APP_ID) {
    client = process.env.FORCE_APP_ID;
  }

  const universalHeader = {
    baseURL: url,
    headers: {
      Client: client,
      OS: "Web",
      SessionId: SESSION_ID,
      Version: version,
      ...(isMarketPlaceEnabled ? { platform: "marketplace" } : {}),
    },
  };

  const instance = Axios.create(merge(universalHeader, customConfig));
  const lbVersion = window.sessionStorage.getItem("lb-version");
  if (lbVersion) {
    instance.defaults.headers.sessionStorage = lbVersion;
  }

  instance.defaults.paramsSerializer = (params) =>
    Qs.stringify(params, {
      arrayFormat: "brackets",
    });

  // On each request we need to send auth headers
  instance.interceptors.request.use(
    (config) => {
      const token = localStorage !== null && localStorage.getItem(tokenKey);
      const location =
        localStorage !== null && localStorage.getItem("location");
      const { id = undefined } = JSON.parse(location) || {};

      const additionalHeaders = {};
      if (id) {
        additionalHeaders.locationId = id;
      }
      if (token) {
        additionalHeaders.authorization = token;
      }
      config.headers.common = {
        ...additionalHeaders,
        ...config.headers.common,
      };
      return config;
    },
    (error) => Promise.reject(error),
  );

  // On each response we need to grab the auth headers
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response &&
        error.response.status &&
        error.response.status === 503
      ) {
        const event = new CustomEvent("alert", {
          detail: {
            message: error.response.data.message,
          },
        });
        document.dispatchEvent(event);
      }
      return Promise.reject(error);
    },
  );

  // Applies Yup validation against responses.
  instance.interceptors.response.use((response) => {
    // Get a Yup schema to validate a response with.
    const schema = getSchemaFromResponseUrl(response.config.url);

    // If a schema was found, validate it.
    if (schema) {
      try {
        schema.validateSync(response.data);
      } catch (err) {
        const message = `API ERROR: response validation failed for ${response.config.url}\n\n${err.message}`;
        if (process.env.NODE_ENV === "production") {
          Sentry.addBreadcrumb({
            category: "api",
            message,
          });
        } else {
          console.warn(message);
        }
      }
    }

    return response;
  });

  return instance;
}

// Create custom axios configurations and export here

// This is the instance we're already creating – should be invisible to rest of app
const AxiosInstance = createAxiosInstance();
// This is a new, custom instance
const AutomationInstance = createAxiosInstance({ baseURL: LBX_AUTOMATION_URL });

/**
 * helper method to perform an api requests
 *
 * @param path.method
 * @param path
 * @param method
 * @param data
 * @param headers
 * @param path.path
 * @param path.data
 * @param path.config
 * @returns {Promise<*>}
 */
// eslint-disable-next-line no-shadow
const axiosRequest = async ({
  method = "GET",
  path,
  data = {},
  config = {},
}) => {
  try {
    // eslint-disable-next-line no-param-reassign
    method = method.toUpperCase();
    return AxiosInstance({
      method,
      url: path,
      [["GET"].includes(method) ? "params" : "data"]: data,
      ...config,
    });
  } catch (error) {
    throw error;
  }
};

// Provide aliases for supported request methods
const methods = [
  "delete",
  "get",
  "head",
  "options",
  "post",
  "put",
  "patch",
].reduce((accu, method) => {
  // eslint-disable-next-line no-param-reassign
  accu[method] =
    // eslint-disable-next-line no-shadow
    (path, data, config) =>
      axiosRequest({
        config,
        data,
        method,
        path,
      });
  return accu;
}, {});

const handleError = (error) => {
  if (error.response) {
    const { data, status } = error.response;
    return { data: data.message, raw: data, status, type: "response" };
  }
  if (error.request) {
    return { data: "No Response Received", status: 408, type: "timeout" };
  }
  return { data: error.message, status: 0, type: "request" };
};

export {
  AxiosInstance,
  AutomationInstance,
  axiosRequest as axios,
  methods,
  handleError,
};

export default axiosRequest;
