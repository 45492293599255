/*
 * ItemDetails Layout Subgroup Type2
 * Author: Isaiah Sanchez <isanchez@lunchbox.io>
 *
 * This component renders the ItemDetails using groups/subgroups.
 * An item can have groups of different modifiers.
 *
 * This type uses a sticky header instead of HeaderSelector.
 * The rest matches ItemDetails/Layout/Type1.
 *
 * Prominently used in Sticky's.
 */

import SubgroupsType2 from "./subgroupsType2";
import withItemDetails from "../../withItemDetails";

export default withItemDetails(SubgroupsType2);
