import { useContext } from "react";
import { TemplateContext } from "./Template/TemplateProvider";

/**
 * @deprecated
 */
const useTemplate = () => {
  const contextValues = useContext(TemplateContext);
  if (!contextValues) {
    throw new Error("Context is being accessed outside a Provider");
  }
  return contextValues;
};

export { useTemplate };
