import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { useUserAgent } from "@oieduardorabelo/use-user-agent";

import { useResource } from "hooks";
import { config, images, Copy } from "utils";
import { View } from "../../../components/elementsThemed/View";
import { Text } from "../../../components/elementsThemed/Text";
import { Button } from "../../../components/elementsThemed/Button";
import { Input } from "../../../components/elementsThemed/Field/Input";
import { Loader } from "../../../components/fragments/Loader";
import { Empty } from "../../../components/fragments/Empty";
import { withStyle } from "../../../components/hocs/withTemplate";
import { mapCartOrder } from "../utils";
import DrawerButton from "../../../components/DrawerButton";

import css from "./index.module.scss";
import commonCSS from "../../index.module.scss";

const createLink = (id) => `${window.location.origin}/checkout/${id}`;
const emailSubject = encodeURIComponent(
  `Your ${config.restaurant} order is waiting`,
);
const body = (id) => `Complete this order by going to ${createLink(id)}`;

const smsLink = ({ name, version }, id) => {
  const text = body(id);
  switch (name) {
    case "iOS": {
      if (parseFloat(version) >= 8) {
        return `sms:&body=${text}`;
      }
      return `sms:;body=${text}`;
    }
    case "Android":
    default:
      return `sms:?body=${text}`;
  }
};

const GenerateLink = ({
  style: { cells, views, buttons, inputs, labels },
  onSuccess,
  order,
}) => {
  const userAgent = useUserAgent();
  const [showCopy, setShowCopy] = React.useState(false);
  const idRef = React.useRef();
  const ref = React.useRef();
  const [{ fetching, resource, error }] = useResource({
    data: mapCartOrder(order),
    method: "post",
    path: "/cart",
  });

  const copyToClipboard = (e) => {
    ref.current.select();
    document.execCommand("copy");
    e.target.focus();
    setShowCopy(true);
  };

  const onComplete = () => {
    onSuccess();
    order.clearItems();
    order.clearRewardItems();
    order.setScheduledAt(null);
  };

  const onClickDown = (e) => {
    if (e.keyCode) {
      copyToClipboard(e);
    }
  };

  React.useEffect(() => {
    if (showCopy) {
      clearTimeout(idRef.current);
      idRef.current = setTimeout(() => {
        setShowCopy(false);
      }, 3000);
    }
    return () => clearTimeout(idRef.current);
  }, [showCopy]);

  let content = null;

  if (fetching) {
    content = (
      <div className={commonCSS.loader}>
        <Loader />
      </div>
    );
  } else if (Object.keys(error).length) {
    content = (
      <div className={commonCSS.loader}>
        <Empty imgSrc={images?.art_empty}>
          <Text type={labels.error}>
            {
              Copy.CHECKOUT_STATIC
                .GENERATE_LINK_ERROR_WHILE_CREATING_LINK_MESSAGE
            }
          </Text>
        </Empty>
      </div>
    );
  } else {
    const { publicIdentifier } = resource;
    content = (
      <>
        <div className={css.content}>
          <Input
            inputRef={ref}
            type={inputs.primary}
            label={Copy.CHECKOUT_STATIC.GENERATE_LINK_INPUT_LINK_LABEL}
            placeholder=""
            value={resource ? createLink(publicIdentifier) : ""}
            readOnly
            icon={
              showCopy ? (
                <span>
                  {Copy.CHECKOUT_STATIC.GENERATE_COPIED_LINK_SPAN_TEXT}
                </span>
              ) : (
                <span
                  tabIndex="0"
                  role="button"
                  onClick={copyToClipboard}
                  onKeyDown={onClickDown}
                >
                  {Copy.CHECKOUT_STATIC.GENERATE_COPY_LINK_SPAN_TEXT}
                </span>
              )
            }
          />

          <Row flex>
            <Col xs="1-2">
              <Button
                type={buttons.link}
                Component="a"
                href={`mailto:?subject=${emailSubject}&body=${body(
                  publicIdentifier,
                )}`}
              >
                {
                  Copy.CHECKOUT_STATIC
                    .GENERATE_LINK_SHARE_LINK_EMAIL_BUTTON_TEXT
                }
              </Button>
            </Col>
            <Col xs="1-2">
              <Button
                type={buttons.link}
                Component="a"
                href={smsLink(userAgent?.os, publicIdentifier)}
              >
                {Copy.CHECKOUT_STATIC.GENERATE_LINK_SHARE_LINK_TEXT_BUTTON_TEXT}
              </Button>
            </Col>
          </Row>
        </div>
        <DrawerButton buttonProps={{ onClick: onComplete }}>
          {Copy.CHECKOUT_STATIC.GENERATE_LINK_START_NEW_CART_BUTTON_TEXT}
        </DrawerButton>
      </>
    );
  }

  return (
    <View type={views.background} className={commonCSS.container}>
      {content}
    </View>
  );
};

export default withStyle(GenerateLink, "link");
