import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Routes } from "utils";

type RouteParams = {
  slug: string;
  orderType: OrderType;
  resGroupId: string;
  itemId: string;
};

interface DeepLink {
  params: RouteParams;
  goBack: (b: boolean) => void;
  prePush: () => void;
  goForward: (_itemId: string, groupId: string) => void;
}

const useDeepLink = ({ page }: { page: string }): [DeepLink] => {
  const history = useHistory();
  const [link, setLink] = useState<Partial<DeepLink>>({});
  const { slug, orderType, resGroupId, itemId } = useParams<RouteParams>();

  useEffect(() => {
    if (page === "menu") {
      if (orderType === "delivery") {
        const orderInfo = JSON.parse(localStorage.getItem("order") || "{}");

        if (orderInfo?.diningOption !== "delivery") {
          history.replace(Routes.ROOT);
        }
      }

      setLink({
        goBack: (isMarketPlaceEnabled: boolean) =>
          isMarketPlaceEnabled
            ? history.replace(Routes.MARKET_PLACE(slug, orderType))
            : history.replace(Routes.ROOT),
      });
    }
  }, []);

  useEffect(() => {
    if (page === "itemDetails") {
      setLink({
        goBack: () =>
          history.replace(
            Routes.LOCATION_INFO({
              orderType,
              resGroupId,
              slug,
            }),
          ),
        goForward: (_itemId: string, groupId: string) =>
          history.push(
            Routes.ITEM_DETAILS({
              itemId: _itemId,
              orderType,
              resGroupId: groupId || resGroupId,
              slug,
            }),
          ),
        params: {
          itemId,
          orderType,
          resGroupId,
          slug,
        },
        prePush: () =>
          history.push(
            Routes.ITEM_DETAILS({
              itemId,
              orderType,
              resGroupId,
              slug,
            }),
          ),
      });
    }
  }, []);

  return [link as DeepLink];
};

export default useDeepLink;
