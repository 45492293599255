export const CART_NOT_FOUND_MESSAGE = "Cart Not Found";
export const CANCEL_CART = "Cancel";
export const COMPLETE_ADDITIONAL_MESSAGE = "";
export const COMPLETE_BACK_TO_MENU_BUTTON_TEXT = "Back to Menu";
export const COMPLETE_CHECK_LABEL = "Check #";
export const COMPLETE_CLOSE_BUTTON_TEXT = "Close";
export const COMPLETE_DELIVERING_FROM_TEXT = "Delivering From:";
export const COMPLETE_DINING_AT_TEXT = "Dining At:";
export const COMPLETE_ESTIMATED_DELIVERY_AT = "Estimated Delivery:";
export const COMPLETE_ESTIMATED_PICKUP_AT = "Estimated Pickup:";
export const COMPLETE_ORDER_DATE_LABEL = "Order Date";
export const COMPLETE_ORDER_DETAILS_LABEL = "Order Details";
export const COMPLETE_ORDER_IN_KITCHEN_MESSAGE = "We're preparing your order";
export const COMPLETE_ORDER_INFORMATION_MESSAGE = "Your Order is {text}";
export const COMPLETE_ORDER_SMS_INFO =
  "Please refer to SMS notifications for the most accurate arrival time";
export const COMPLETE_ORDER_MESSAGE = "Complete Your Order";
export const COMPLETE_ORDER_TIME_LABEL = "Order Time";
export const COMPLETE_PICKUP_FROM_TEXT = "Pickup From:";
export const COMPLETE_PREP_TIME_MESSAGE = "Prep Time:";
export const COMPLETE_SCHEDULE_DATE_HEADER_TEXT = "Schedule Date:";
export const COMPLETE_TICKET_INFORMATION_PREPARING_MESSAGE = "in the kitchen";
export const COMPLETE_TICKET_INFORMATION_SCHEDULE_MESSAGE = "scheduled";
export const COMPLETE_TYPE3_ORDER_INFORMATION_MESSAGE = "Your Order is ";
export const COMPLETE_TYPE3_TICKET_INFORMATION_PREPARING_MESSAGE =
  "in \n the kitchen!";
export const COMPLETE_TYPE3_TICKET_INFORMATION_SCHEDULE_MESSAGE = "\nscheduled";
export const COMPLETE_TYPE4_DELIVERING_FROM_TEXT = "Delivering Address ";
export const COMPLETE_TYPE4_ESTIMATED_DELIVERING_FROM_TEXT =
  "Estimated Delivery Time: ";
export const COMPLETE_TYPE4_ORDER_IN_THE_KITCHEN_MESSAGE =
  "Your Order Is In the Kitchen";
export const COMPLETE_TYPE4_ORDERED_TIME_HEADER = "Ordered on: {orderTime}";
export const COMPLETE_TYPE4_PICKUP_FROM_TEXT = "Pickup From ";
export const CONFIRM_GO_TO_CART_BUTTON_TEXT = "Go to Cart";
export const CONFIRM_REMOVE_ITEM_APPROVAL_MESSAGE =
  "\nAre you sure you want to remove \n{itemName} from your cart?";
export const CONFIRM_REMOVE_ITEM_BUTTON_TEXT = "Remove";
export const DISCOUNT_APPLY_BUTTON_TEXT = "Apply";
export const DISCOUNT_PROMO_CODE_INPUT_LABEL = "Promo Code";
export const DISCOUNT_PROMO_CODE_INPUT_PLACEHOLDER = "Enter Promo Code Here";
export const EDIT_CART = "Edit Cart";
export const GENERATE_COPIED_LINK_SPAN_TEXT = "Copied";
export const GENERATE_COPY_LINK_SPAN_TEXT = "Copy";
export const GENERATE_LINK_ERROR_WHILE_CREATING_LINK_MESSAGE =
  "An Error occurred";
export const GENERATE_LINK_INPUT_LINK_LABEL = "Shareable Link";
export const GENERATE_LINK_SHARE_LINK_EMAIL_BUTTON_TEXT = "Email Link";
export const GENERATE_LINK_SHARE_LINK_TEXT_BUTTON_TEXT = "Text Link";
export const GENERATE_LINK_START_NEW_CART_BUTTON_TEXT = "Start New Cart";
export const GIFT_CARD_APPLY_BUTTON_TEXT = "Add To Order";
export const GIFT_CARD_REMOVE_BUTTON_TEXT = "Remove";
export const GIFT_CARD_INPUT_LABEL = "Enter Gift Card Number";
export const GIFT_CARD_INPUT_PLACEHOLDER = "Enter Your Gift Card Number";
export const GIFT_CARD_PINCODE_INPUT_LABEL = "Enter Pin Code";
export const GIFT_CARD_PINCODE_INPUT_PLACEHOLDER =
  "Enter Your Gift Card Pin Code";
export const INVALID_GIFT_CARD_RESPONSE_TEXT =
  "The gift card number or pin you entered is invalid. Please try again.";
export const GIFT_CARD_SUCCESS_POPUP_TITLE = "Success!";
export const GIFT_CARD_SUCCESS_POPUP_BODY =
  "Funds from your gift card have been applied to your order.";
export const GIFT_CARD_SUCCESS_POPUP_BUTTON_TEXT = "OKAY";
export const ITEM_ACCEPT_DETAILS_BUTTON_TEXT = "Accept Edits";
export const ITEM_ADD_TO_CART_BUTTON_TEXT = "Add To Cart";
export const ORDER_STATUS_COMPLETED = "Order Process Completed";
export const ORDER_STATUS_IN_PROGRESS = "In Progress";
export const ORDER_STATUS_ORDER_PROCESSED = "Order Processed";
export const ORDER_STATUS_PROCESSING_PAYMENT = "Processing Payment";
export const PAYMENT_FAILED_ERROR_TEXT =
  "Payment failed, please try a new payment method.";
export const REVIEW_SECTION_HEADER_OPEN = "REVIEW";
export const REVIEW_SECTION_HEADER_CLOSE = "REVIEW";
export const CHECKOUT_INFO = "";

export const GUEST_CHECKOUT_SIGNUP_POINTS_TEXT =
  "You’ve earned {points} points!";
export const GUEST_CHECKOUT_REGISTER_ACCOUNT_TEXT =
  "Register an account ({email}) and save up to redeem exclusive rewards.";
export const GUEST_CHEKOUT_PASSWORD_FIELD_LABEL = "Create a password";
export const GUEST_CHEKOUT_PASSWORD_FIELD_PLACEHOLDER = "Password";
export const GUEST_CHEKOUT_CREATE_ACCOUNT_BUTTON_LABEL = "Create Account";

export default {
  CANCEL_CART,
  CART_NOT_FOUND_MESSAGE,
  COMPLETE_ADDITIONAL_MESSAGE,
  COMPLETE_BACK_TO_MENU_BUTTON_TEXT,
  COMPLETE_CHECK_LABEL,
  COMPLETE_CLOSE_BUTTON_TEXT,
  COMPLETE_DELIVERING_FROM_TEXT,
  COMPLETE_DINING_AT_TEXT,
  COMPLETE_ESTIMATED_DELIVERY_AT,
  COMPLETE_ESTIMATED_PICKUP_AT,
  COMPLETE_ORDER_DATE_LABEL,
  COMPLETE_ORDER_DETAILS_LABEL,
  COMPLETE_ORDER_IN_KITCHEN_MESSAGE,
  COMPLETE_ORDER_INFORMATION_MESSAGE,
  COMPLETE_ORDER_MESSAGE,
  COMPLETE_ORDER_SMS_INFO,
  COMPLETE_ORDER_TIME_LABEL,
  COMPLETE_PICKUP_FROM_TEXT,
  COMPLETE_PREP_TIME_MESSAGE,
  COMPLETE_SCHEDULE_DATE_HEADER_TEXT,
  COMPLETE_TICKET_INFORMATION_PREPARING_MESSAGE,
  COMPLETE_TICKET_INFORMATION_SCHEDULE_MESSAGE,
  COMPLETE_TYPE3_ORDER_INFORMATION_MESSAGE,
  COMPLETE_TYPE3_TICKET_INFORMATION_PREPARING_MESSAGE,
  COMPLETE_TYPE3_TICKET_INFORMATION_SCHEDULE_MESSAGE,
  COMPLETE_TYPE4_DELIVERING_FROM_TEXT,
  COMPLETE_TYPE4_ESTIMATED_DELIVERING_FROM_TEXT,
  COMPLETE_TYPE4_ORDER_IN_THE_KITCHEN_MESSAGE,
  COMPLETE_TYPE4_ORDERED_TIME_HEADER,
  COMPLETE_TYPE4_PICKUP_FROM_TEXT,
  CONFIRM_GO_TO_CART_BUTTON_TEXT,
  CONFIRM_REMOVE_ITEM_APPROVAL_MESSAGE,
  CONFIRM_REMOVE_ITEM_BUTTON_TEXT,
  DISCOUNT_APPLY_BUTTON_TEXT,
  DISCOUNT_PROMO_CODE_INPUT_LABEL,
  DISCOUNT_PROMO_CODE_INPUT_PLACEHOLDER,
  EDIT_CART,
  GENERATE_COPIED_LINK_SPAN_TEXT,
  GENERATE_COPY_LINK_SPAN_TEXT,
  GENERATE_LINK_ERROR_WHILE_CREATING_LINK_MESSAGE,
  GENERATE_LINK_INPUT_LINK_LABEL,
  GENERATE_LINK_SHARE_LINK_EMAIL_BUTTON_TEXT,
  GENERATE_LINK_SHARE_LINK_TEXT_BUTTON_TEXT,
  GENERATE_LINK_START_NEW_CART_BUTTON_TEXT,
  GIFT_CARD_INPUT_LABEL,
  GIFT_CARD_INPUT_PLACEHOLDER,
  GIFT_CARD_PINCODE_INPUT_LABEL,
  GIFT_CARD_PINCODE_INPUT_PLACEHOLDER,
  GIFT_CARD_APPLY_BUTTON_TEXT,
  GIFT_CARD_REMOVE_BUTTON_TEXT,
  INVALID_GIFT_CARD_RESPONSE_TEXT,
  GIFT_CARD_SUCCESS_POPUP_TITLE,
  GIFT_CARD_SUCCESS_POPUP_BODY,
  GIFT_CARD_SUCCESS_POPUP_BUTTON_TEXT,
  ITEM_ACCEPT_DETAILS_BUTTON_TEXT,
  ITEM_ADD_TO_CART_BUTTON_TEXT,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_IN_PROGRESS,
  ORDER_STATUS_ORDER_PROCESSED,
  ORDER_STATUS_PROCESSING_PAYMENT,
  PAYMENT_FAILED_ERROR_TEXT,
  CHECKOUT_INFO,
  REVIEW_SECTION_HEADER_OPEN,
  REVIEW_SECTION_HEADER_CLOSE,
  GUEST_CHECKOUT_SIGNUP_POINTS_TEXT,
  GUEST_CHECKOUT_REGISTER_ACCOUNT_TEXT,
  GUEST_CHEKOUT_PASSWORD_FIELD_LABEL,
  GUEST_CHEKOUT_PASSWORD_FIELD_PLACEHOLDER,
  GUEST_CHEKOUT_CREATE_ACCOUNT_BUTTON_LABEL,
};
