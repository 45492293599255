import { DEFAULT_ADDRESS } from "__fixtures__/contexts";
import { addComma } from "./string";

export const parseAddress = (inputAddress: Partial<Address> = {}): Address => ({
  ...DEFAULT_ADDRESS,
  ...inputAddress,
});

export const formatAddress = (inputAddress: Partial<Address> = {}): string => {
  const address = parseAddress(inputAddress);

  return `${addComma(address.street1)}${addComma(address.street2)} ${addComma(
    address.city,
  )} ${address.state} ${address.zip}`;
};

export const formatAddressArray = (
  inputAddress: Partial<Address> = {},
): string[] => {
  const address = parseAddress(inputAddress);
  const address1 = `${addComma(address.street1)} ${addComma(address.street2)}`;
  const address2 = `${addComma(address.city)} ${address.state} ${address.zip}`;

  return [address1, address2];
};

export const getPosition = (
  options?: PositionOptions,
): Promise<GeolocationPosition> =>
  new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options),
  );

export const mapGoogleAddressComponents = (
  addressComponents: AddressComponent[],
): Address => {
  /**
   * A lookup map between a component of addresses in our data model
   * and the types that possibly represent that component within
   * Google Address data model. Order matters here – the first value
   * listed will take precedene of others if both are present.
   *
   * https://developers.google.com/places/supported_types#table3
   */
  const lookupDict = {
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4",
      "administrative_area_level_3",
    ],
    country: ["country"],
    home: ["street_number"],
    state: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_4",
      "administrative_area_level_5",
    ],
    street1: ["street_address", "route"],
    zip: ["postal_code"],
  };

  const address = {
    city: "",
    country: "",
    home: "",
    state: "",
    street1: "",
    street2: "",
    zip: "",
  };

  Object.entries(lookupDict).forEach(([addressComponent, lookupKeys]) => {
    // For loops are gross, buuuut we need to be able to break
    // after finding a match.
    // eslint-disable-next-line
    for (let i = 0; i < lookupKeys.length; i++) {
      const lookupKey = lookupKeys[i];
      const match = addressComponents.find(({ types }) =>
        types.includes(lookupKey),
      );

      if (match) {
        const keysThatShouldUseShortName = ["country", "state"];
        const longOrShortName = keysThatShouldUseShortName.includes(
          addressComponent,
        )
          ? "short_name"
          : "long_name";
        address[addressComponent as "country" | "state"] =
          match[longOrShortName];
        break;
      }
    }
  });

  return {
    ...address,
    home: undefined,
    street1: `${address.home} ${address.street1}`.trim(),
  };
};
