import { config } from "utils";
import {
  populate,
  populateSubgroups,
  sortGroups,
  sortNestedEntityByPriority,
  toObject,
  updateItems,
} from "../utils";

export const menuReducer = (state, { type, payload }) => {
  const payloadTemp = { ...payload };
  switch (type) {
    case "ADD": {
      payloadTemp.menus = populate(payloadTemp, "menus", "groups");
      payloadTemp.groups = sortGroups(populate(payloadTemp, "groups", "items"));
      payloadTemp.groups = populateSubgroups(payloadTemp.groups);
      payloadTemp.items = updateItems(
        populate(payloadTemp, "items", "options"),
      );
      payloadTemp.options = populate(payloadTemp, "options", "items");

      const groupsAsObject = toObject(payloadTemp.groups);
      const itemsAsObject = toObject(payloadTemp.items);
      // Filtering out any default mods for all options that are not in the items array returned by the backend
      payloadTemp.options = payloadTemp.options.map(
        ({ defaultModifiers = [], ...option }) => {
          const filteredDefaultModifiers = defaultModifiers.filter(
            (modItemId) => itemsAsObject[modItemId],
          );
          return {
            ...option,
            defaultModifiers: filteredDefaultModifiers,
          };
        },
      );

      const optionsAsObject = toObject(payloadTemp.options);

      if (config.flatten_subgroups) {
        payloadTemp.menus = payloadTemp.menus.map((i) => {
          const flattenSubgroups = i.groups
            .reduce((accu, x) => x.subgroups, [])
            .map((x) => groupsAsObject[x])
            .filter((x) => x);
          let groups = [...i.groups, ...flattenSubgroups];
          groups = groups.filter((x) => {
            if (!x.items || !x.items.length) {
              return false;
            }
            return true;
          });
          return {
            ...i,
            groups,
          };
        });
      }

      payloadTemp.menus = sortNestedEntityByPriority(payloadTemp.menus).map(
        (i) => ({
          ...i,
          groups: sortNestedEntityByPriority(i.groups, groupsAsObject),
        }),
      );

      payloadTemp.groups = payloadTemp.groups.map((i) => ({
        ...i,
        items: sortNestedEntityByPriority(i.items, itemsAsObject),
        subgroups: sortNestedEntityByPriority(i.subgroups, groupsAsObject),
      }));

      payloadTemp.items = payloadTemp.items.map((i) => ({
        ...i,
        options: sortNestedEntityByPriority(i.options, optionsAsObject),
      }));

      payloadTemp.options = payloadTemp.options.map((i) => ({
        ...i,
        items: sortNestedEntityByPriority(i.items, itemsAsObject),
      }));

      const t = {
        ...state,
        groups: {
          array: payloadTemp.groups,
          hash: toObject(payloadTemp.groups),
        },
        items: {
          array: payloadTemp.items,
          hash: toObject(payloadTemp.items),
        },
        menus: {
          array: payloadTemp.menus,
          hash: toObject(payloadTemp.menus),
        },
        options: {
          array: payloadTemp.options,
          hash: toObject(payloadTemp.options),
        },
      };

      return t;
    }
    default:
      return state;
  }
};
