import * as Yup from "yup";
import { config } from "../config";
import { phoneRegExp } from "../helpers/regexps";

const validationShape = {};
const hasFirstName =
  config.theme.checkout.guest_checkout_fields.includes("firstName");
const hasLastName =
  config.theme.checkout.guest_checkout_fields.includes("lastName");
const hasEmail = config.theme.checkout.guest_checkout_fields.includes("email");
const hasPhone = config.theme.checkout.guest_checkout_fields.includes("phone");

if (hasEmail)
  validationShape.email = Yup.string()
    .email("Invalid email")
    .required("Email is Required");
if (hasFirstName)
  validationShape.firstName = Yup.string().required("First Name is Required");
if (hasLastName)
  validationShape.lastName = Yup.string().required("Last Name is Required");
if (hasPhone)
  validationShape.phone = Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is Required");

const GuestCheckoutSchema = Yup.object().shape(validationShape);
export { GuestCheckoutSchema };
