import * as React from "react";
import Styled from "styled-components";
import styles from "./toggle.module.css";

const Toggle = Styled(({ value, onChange, className, htmlFor }) => (
  <label className={`${styles.switch} ${className}`} htmlFor={htmlFor}>
    <input
      type="checkbox"
      checked={value}
      id={htmlFor}
      onChange={() => {
        onChange(!value);
      }}
    />
    <span className={`${styles.slider} ${styles.round}`} />
  </label>
))`
  .${styles.slider}:before {
    background-color: ${(props) => props.theme.colors.secondaryDark};
  }
`;

export default Toggle;
