import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import cx from "classnames";

import Image from "components/elements/Image/image";
import Flex from "components/elements/Layout/flex";
import { Condition } from "components/elements/Condition/condition";
import View from "components/elementsThemed/View/View";
import Text from "components/elementsThemed/Text/text";
import DrawerButton from "../../../../components/DrawerButton";
import BeamImpact from "components/fragments/BeamImpact/BeamImpact";
import withTemplate from "components/hocs/withTemplate";
import { usePatronContext } from "components/providers/Patron/PatronContext";
import { config, images, Copy, constants } from "utils";
import { stringReplacer } from "utils/helpers/string";
import CartItem from "../ConfirmItem";
import CompleteDineIn from "./completeDineIn";

import css from "./complete.module.scss";
import commonCSS from "../../../index.module.scss";

const {
  ORDER_TYPES: { KIOSK, PICKUP },
} = constants;

const If = Condition;

function Complete({
  style: { views, cells, labels },
  onSuccess,
  onSeeMoreImpact,
  ticketInformation,
  scheduleTime,
  orderTime,
  deliveryTime,
  eta,
  beam,
}) {
  const patron = usePatronContext();

  const isPickupOrder =
    ticketInformation.orderType === PICKUP ||
    ticketInformation.orderType === KIOSK;
  const titleText = isPickupOrder
    ? Copy.CHECKOUT_STATIC.COMPLETE_TYPE4_PICKUP_FROM_TEXT
    : Copy.CHECKOUT_STATIC.COMPLETE_TYPE4_DELIVERING_FROM_TEXT;
  const orderTypeText = isPickupOrder ? (
    <Text type={labels.primary}>
      {ticketInformation.location.name}
      <br />
      {ticketInformation.location.address}
    </Text>
  ) : (
    <Text type={labels.primary}>{ticketInformation.deliveryInfo}</Text>
  );
  const prepTimeText = isPickupOrder
    ? "Estimated Pickup Time: "
    : "Estimated Delivery Time:";

  const info = ticketInformation.scheduledAt ? (
    <div className={css["complete-content-timeContainer"]}>
      <Col xs="1">
        <Text type={labels.tertiary}>
          {Copy.CHECKOUT_STATIC.COMPLETE_SCHEDULE_DATE_HEADER_TEXT}
        </Text>
      </Col>
      <Col xs="1">
        <Text type={labels.time}>{scheduleTime}</Text>
      </Col>
    </div>
  ) : (
    <div className={css["complete-content-timeContainer"]}>
      <Col xs="1">
        <Text role="heading" aria-level={3} type={labels.tertiary}>
          {prepTimeText}
        </Text>
      </Col>
      <Col xs="1">
        <Text type={labels.time}>{deliveryTime}</Text>
        {ticketInformation.scheduledAt && (
          <Text type={labels.tertiary}>{eta}</Text>
        )}
        <div className={css["complete-content-barCon"]}>
          <View
            type={views.bar}
            role="progressbar"
            className={cx("bar", css["complete-content-bar"])}
            data-percent="70"
            aria-label="Order"
            aria-valuenow={40}
            aria-valuemin={0}
            aria-valuemax={100}
          />
        </div>
      </Col>
      <Col xs="1">
        <Text type={labels.secondary}>
          {Copy.CHECKOUT_STATIC.COMPLETE_TYPE4_ORDER_IN_THE_KITCHEN_MESSAGE}
        </Text>
      </Col>
    </div>
  );

  const AddressDetail = (
    <div className={css["complete-content-title"]}>
      <Text role="heading" aria-level={3} type={labels.tertiary}>
        {titleText}
      </Text>
      <Row>{orderTypeText}</Row>
    </div>
  );

  if (ticketInformation.orderType === KIOSK) {
    return (
      <CompleteDineIn
        ticketInformation={ticketInformation}
        onSuccess={onSuccess}
      />
    );
  }

  // "purchase-confirmation" is gtm Ecommerce-anchor, please do not remove
  return (
    <View
      type={views.background}
      grow={1}
      Component={Flex}
      className={`${commonCSS.container} ${css.complete}`}
    >
      <div className={cx(css["complete-content"], "purchase-confirmation")}>
        <View
          type={views.secondary}
          Component={Flex}
          grow={0}
          className={css["complete-content-information"]}
          direction="col"
        >
          {info}
          <View
            type={views.tertiary}
            className={css["complete-content-divider"]}
          />
          {AddressDetail}
          <View
            type={views.tertiary}
            className={css["complete-content-divider"]}
          />
          {images?.art_order_confirmation && (
            <Image
              src={images?.art_order_confirmation}
              className={css["complete-content-img"]}
              alt={
                process.env.CLIENT === "nightowlcookieco"
                  ? ""
                  : "confirmation Image"
              }
            />
          )}
          <div className={css["complete-content-details"]}>
            <Row spacing={20} />
            <Text role="heading" aria-level={3} type={labels.tertiary}>
              {Copy.CHECKOUT_STATIC.COMPLETE_ORDER_DETAILS_LABEL}
            </Text>
            <Row spacing={20}>
              <Col xs="1-3">
                <Text type={labels.secondary}>
                  {Copy.CHECKOUT_STATIC.COMPLETE_CHECK_LABEL}
                  {ticketInformation.displayNumber}
                </Text>
              </Col>
              <Col xs="2-3">
                <Text type={labels.secondary}>
                  {stringReplacer(
                    Copy.CHECKOUT_STATIC.COMPLETE_TYPE4_ORDERED_TIME_HEADER,
                    [
                      {
                        replaceTarget: "{orderTime}",
                        replaceValue: orderTime,
                      },
                    ],
                  )}
                </Text>
              </Col>
            </Row>

            <Row spacing={20}>
              <If condition={config?.apps?.beam_impact}>
                <BeamImpact
                  widgetId={config.apps.beam_impact}
                  patronEmail={patron?.patron?.email?.value}
                  beam={beam}
                  onSeeMoreImpact={onSeeMoreImpact}
                  step="complete"
                />
              </If>
              <ul className={css["complete-content-items"]}>
                {ticketInformation.items.map((i) => (
                  <CartItem key={i.id} type={cells.primary} {...i} />
                ))}
              </ul>
            </Row>
          </div>
        </View>
      </div>
      <div className={css["complete-footer"]}>
        <DrawerButton buttonProps={{ onClick: onSuccess }}>
          {Copy.CHECKOUT_STATIC.COMPLETE_BACK_TO_MENU_BUTTON_TEXT}
        </DrawerButton>
      </div>
    </View>
  );
}

const ThemedComplete = withTemplate(Complete, "confirmation");
ThemedComplete.displayName = "CompleteType4";

export default ThemedComplete;
