import * as React from "react";
import Option from "../../Options";

import styles from "./index.module.scss";

const findError = (errors, id) => {
  const match = errors.find((i) => i.optionId === id);
  if (match) {
    return match.message;
  }
  return null;
};

const Type6 = ({
  tabs,
  cells,
  errors,
  errorRefs,
  addModifier,
  removeModifier,
  menuOptions,
  modifications,
}) => (
  <div className={styles.container} data-cy="itemDetail-tabs">
    {tabs.map((i, index) =>
      i.entities.map((option) => (
        <Option
          key={option.id}
          forwardRef={errorRefs.current[index]}
          type={cells.group}
          optionItemTypes={{
            default: cells.primary,
            selected: cells.primarySelected,
          }}
          parentOption={menuOptions[option.id]}
          {...menuOptions[option.id]}
          error={findError(errors, option.id)}
          selectedMods={modifications[option.id] || []}
          addModifier={addModifier}
          removeModifier={removeModifier}
        />
      )),
    )}
  </div>
);

Type6.displayName = "TabType6";

export default Type6;
