import * as React from "react";
import styles from "./index.module.css";
import Option from "../../Options";

const findError = (errors, id) => {
  const match = errors.find((i) => i.optionId === id);
  if (match) {
    return match.message;
  }
  return null;
};

const Type3 = ({
  tabs,
  cells,
  errors,
  errorRefs,
  addModifier,
  removeModifier,
  menuOptions,
  modifications,
}) => {
  const counter = React.useRef([0]);

  return (
    <div className={styles.container} data-cy="itemDetail-tabs">
      {tabs.map((i, tabIndex) => {
        counter.current[tabIndex + 1] = i.entities.length;
        return i.entities.map((option, index) => (
          <Option
            key={option.id}
            forwardRef={errorRefs.current[index + counter.current[tabIndex]]}
            type={cells.group}
            optionItemTypes={{
              default: cells.primary,
              selected: cells.primarySelected,
            }}
            parentOption={menuOptions[option.id]}
            {...menuOptions[option.id]}
            error={findError(errors, option.id)}
            selectedMods={modifications[option.id] || []}
            addModifier={addModifier}
            removeModifier={removeModifier}
          />
        ));
      })}
    </div>
  );
};

Type3.displayName = "TabType3";

export default Type3;
