import * as React from "react";
import { PaymentRequestButtonElement } from "@stripe/react-stripe-js";

import { Copy, constants } from "utils";
import { InlineLoader } from "components/elements";
import DrawerButton from "../../../components/DrawerButton";
import { useCheckout } from "components/providers/CheckoutType2";

import css from "../checkoutType2.module.scss";

/**
 * Returns the checkout footer button with a loader when ordering or static text based on errors
 *
 * @param {object} props
 * @param {object} props.error - object with error information
 * @param {boolean} props.ordering - if we are actively submitting an order
 * @param {Function} props.onClick - for checkout v2 - to take precedence over placeOrder
 * @param {Function} props.placeOrder - function to validate and submit order
 * @param {string} props.buttonText - optional override for the button text
 */

const {
  DATA_TEST: { CART_FOOTER_PLACE_ORDER },
} = constants;

// Middleware for stripe payment request button
const CheckoutButton = (props) => {
  const { mobilePayEnabled } = props;
  const { paymentRequest } = useCheckout();
  // Check client config, payment processor is stripe and mobile pay is enabled
  if (paymentRequest && mobilePayEnabled) {
    return (
      <div className={`checkout-confirmation ${css.paymentRequestButton}`}>
        <PaymentRequestButtonElement
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                height: "55px",
                theme: "dark",
                type: "default",
              },
            },
          }}
        />
      </div>
    );
  }

  return <FallbackCheckoutButton {...props} />;
};

const FallbackCheckoutButton = ({
  error,
  ordering,
  onClick,
  disabled,
  placeOrder,
  buttonText,
  ...props
}) => {
  let parseButtonText = buttonText;
  if (!parseButtonText) {
    parseButtonText =
      (error?.type === 1 && error?.buttonText) ||
      Copy.CART_STATIC.CHECKOUT_PLACE_ORDER_BUTTON_TEXT;
  }
  return (
    <DrawerButton
      buttonProps={{
        "data-cy": CART_FOOTER_PLACE_ORDER,
        onClick: onClick || placeOrder,
        className: `checkout-confirmation ${CART_FOOTER_PLACE_ORDER}`,
        disabled: disabled || ordering || error?.type === 1,
        trackingId: "checkout-confirmation",
        ...props,
      }}
    >
      {ordering ? <InlineLoader size={24} /> : parseButtonText}
    </DrawerButton>
  );
};

export { CheckoutButton };
