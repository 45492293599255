/**
 * Recursively sum price modifications to account for nested modifications.
 *
 * @param {Array} modifications - An array of objects each containing price and a potential array of other modifications
 * @returns {number} - The total of all price modifications
 */
function sumNestedModifications(modifications = []) {
  let sum = 0;
  // Iterate over modifications and recursively sum their price modifications
  modifications.forEach((modification) => {
    sum +=
      modification.price + sumNestedModifications(modification.modifications);
  });

  // Return our total
  return sum;
}

/**
 * Add unit price and the added price of modifications to the total and return it.
 *
 * @param {Object} item - An orderItem object from the backend
 * @returns {number} - The total price of the item based on unit price and modifications
 */
export function getTotalItemPrice(item) {
  return item.unitPrice + sumNestedModifications(item.modifications);
}
