import { useState, useEffect } from "react";
import { GET_TRACKING_INFO } from "../utils/api";
import { config } from "../utils/config";
import { ORDER_TYPES } from "../utils/constants";

export type TrackingData = {
  restaurant: {
    latitude: number;
    longitude: number;
  };
  estimatedDropoffTime: number;
  patron: {
    latitude: number;
    longitude: number;
  };
  driverName: string;
  serviceName: string;
  driverPhoneNumber: string;
  status: string;
};

type TrackingInfoArgs = {
  init: Partial<TrackingData>;
  orderType: OrderType;
  id: string;
};

const useTrackingInfo = ({ init, orderType, id }: TrackingInfoArgs) => {
  const [trackingData, setTrackingData] =
    useState<null | Partial<TrackingData>>(init);

  useEffect(() => {
    if (orderType === ORDER_TYPES.DELIVERY && id) {
      const getTrackingInfo = async () => {
        try {
          const data: { data: null | TrackingData } = await GET_TRACKING_INFO(
            id,
          );
          if (data.data) {
            /**
             * Casting status to lowercase cause this status is an enum dont want to worry about case sensitivity
             * Checking for isOlo b/c the olo integration sends string value for statuses.
             * TODO: do this validation and casting at the api level. And remove isOlo check as integration specific conditions are a code smell
             */
            if (data?.data?.status && config.isOlo) {
              data.data.status = (data.data.status ?? "").toLowerCase();
            }
            setTrackingData(data.data);
          }
        } catch (e) {
          console.log(e);
        }
      };
      getTrackingInfo();

      const fetchTracking = setInterval(() => {
        getTrackingInfo();
      }, 10000);

      return () => clearInterval(fetchTracking);
    }
  }, [id]);

  return [trackingData, setTrackingData];
};

export default useTrackingInfo;
