import * as React from "react";
import { View } from "../../elementsThemed/View";
import { progressMap, isProgressSegmentComplete } from "./utils";
import { ProgressSegment } from "./progressSegment";
import { DriverInformation } from "./driverInformation";
import css from "./trackingProgress.module.scss";

const TrackingProgress = ({ style, trackingData }) => {
  const { cells, views } = style;
  const { status = "" } = trackingData;
  return (
    <View type={views.background} className={css["trackingProgress-container"]}>
      {progressMap.map((i) => (
        <ProgressSegment
          type={cells.tracker}
          title={i.title}
          description={i.description}
          isComplete={isProgressSegmentComplete(status, i.requiredStatus)}
        />
      ))}

      <DriverInformation
        type={cells.driverInfo}
        driverName={trackingData.driverName}
        serviceName={trackingData.serviceName}
        driverContact={trackingData.driverPhoneNumber}
      />
    </View>
  );
};

export { TrackingProgress };
