import * as React from "react";
import { Elements, ElementsThemed } from "components";
import { formatPrice } from "utils/helpers/string";
import { useCell } from "hooks";
import css from "./confirmItemType1.module.scss";
import { formatModifierPrice } from "../utils";

const {
  Condition: { Condition },
} = Elements;

const { View, Text } = ElementsThemed;

const ConfirmItem = ({ price, type, notes, populatedMods, name }) => {
  const { labelTextStyles, views } = useCell(type);

  const y = populatedMods.map((modItem, idx) => {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Text key={idx} type={labelTextStyles.tertiary}>
        {modItem.itemName}
        {formatModifierPrice(modItem.price, modItem.quantity)}
      </Text>
    );
  });

  return (
    <View type={views.background} Component="li" className={css.card}>
      <div className={css.info}>
        <div className={css.title}>
          <Text type={labelTextStyles.primary} className={css.name}>
            {name}
          </Text>
          <Text type={labelTextStyles.secondary} className={css.price}>
            {formatPrice(price)}
          </Text>
        </div>
        <View type={views.secondary} className={css["cartItem-desc"]}>
          {y}
          <Condition is={notes}>
            <Text
              className={css["cartItem-desc--notes"]}
              type={labelTextStyles.tertiary}
            >
              {`Note: ${notes}`}
            </Text>
          </Condition>
        </View>
      </div>
    </View>
  );
};

ConfirmItem.displayName = "ConfirmItemType1";

export default ConfirmItem;
