import * as React from "react";
import { usePackingContext } from "../packingContext";

const withPackingItem =
  (Component) =>
  ({
    id,
    onAddItem,
    onRemoveItem,
    selectedItems,
    isSingleSelectable,
    ...restProps
  }) => {
    const { getEntityById } = usePackingContext();
    const { index, isSelected, name, price } = getEntityById(id, "item");

    const itemQuantity = selectedItems.filter((item) => item === id).length;

    const returnProps = {
      id,
      index,
      isSelected,
      isSingleSelectable,
      itemQuantity,
      name,
      onAddPackingItem: () => onAddItem(id),
      onRemovePackingItem: () => onRemoveItem(id),
      price,
    };

    return <Component {...restProps} {...returnProps} />;
  };

export default withPackingItem;
