import * as React from "react";
import cs from "classnames";
import { useCell } from "hooks";
import { View, Button } from "components/elementsThemed";
import css from "./footerButton.module.scss";

/**
 *
 * @deprecated Use "components/DrawerButton.tsx" instead.
 */
function FooterButton({ type, containerClass, trackingId = "", ...props }) {
  const { views, buttons } = useCell(type);
  const viewClass = cs(css.footerButton, containerClass);
  return (
    <View type={views.background} className={viewClass}>
      <Button
        block
        type={buttons.primary}
        className={css.button}
        id={trackingId}
        {...props}
      />
    </View>
  );
}

export default FooterButton;
