import * as React from "react";
import { Layout } from "components/elements";
import { useCell } from "hooks";
import View from "../../../../elementsThemed/View/View";
import QuantitySelector from "../../../../fragments/QuantitySelector/QuantitySelector";
import DrawerButton from "../../../../DrawerButton";
import css from "./footerType3.module.scss";

const { Flex } = Layout;

function Footer({
  showQuantity,
  quantityType,
  quantity,
  incQuantity,
  decQuantity,
  onClick,
  buttonText,
  name,
  isDisabled,
  "data-cy": dataCy,
}) {
  const { views, buttons, labelTextStyles } = useCell(quantityType);
  return (
    <View type={views.background} className={css.itemDetailFooter}>
      {showQuantity && (
        <View
          Component={Flex}
          grow="1"
          className={css["itemDetailFooter-quantity"]}
        >
          <QuantitySelector
            name={name}
            viewType={views.secondary}
            buttonType={buttons.primary}
            labelType={labelTextStyles.primary}
            quantity={quantity}
            inc={incQuantity}
            dec={decQuantity}
          />
        </View>
      )}
      <Flex grow="3" className={css["itemDetailFooter-button"]}>
        <DrawerButton
          buttonProps={{ onClick, disabled: isDisabled, dataCy }}
          containerProps={{
            containerClass: css["itemDetailFooter-button-content"],
          }}
        >
          {buttonText}
        </DrawerButton>
      </Flex>
    </View>
  );
}

export default Footer;
