import * as React from "react";
import csx from "classnames";
import { useOnClickOutside } from "hooks";
import css from "./dropdown.module.css";
import { useId } from "@react-aria/utils";

const Dropdown = ({ trigger, children, className }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef();
  const toggle = () => [setIsOpen(!isOpen)];
  const onClose = () => setIsOpen(false);
  const itemsId = useId();
  const triggerId = useId();

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div ref={ref} style={{ position: "relative" }}>
      <div
        aria-expanded={isOpen}
        aria-controls={isOpen ? itemsId : undefined}
        role="button"
        id={triggerId}
        tabIndex="0"
        onKeyPress={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            toggle();
          }
        }}
      >
        {React.cloneElement(trigger, { onClick: toggle })}
      </div>
      {isOpen && (
        <div
          className={csx(css.container, className)}
          role="region"
          id={itemsId}
          aria-labelledby={triggerId}
        >
          {typeof children === "function"
            ? React.Children.only(children({ onClose }))
            : React.Children.map(children, (child) =>
                React.cloneElement(child, {
                  onClick: () => {
                    child.props.onClick();
                    onClose();
                  },
                }),
              )}
        </div>
      )}
    </div>
  );
};

export { Dropdown };
export default Dropdown;
