export const ACCOUNT_TEXT = "ACCOUNT";
export const CART_TEXT = "CART";
export const CHECKOUT_TEXT = "CHECKOUT";
export const GO_BACK_TO_MENU_TEXT = "Go back to Menu";
export const GO_TO_LOCATIONS_TEXT = "Go to locations";
export const LOGIN_TEXT = "LOGIN";
export const LOGOUT_TEXT = "Logout";
export const ORDER_FROM_DELIVERY_TEXT = "Delivering";
export const ORDER_FROM_KIOSK_TEXT = "Table Ordering";
export const ORDER_FROM_PICKUP_TEXT = "Picking up";
export const START_A_NEW_ORDER_TEXT = "Start A New Order";

export default {
  ACCOUNT_TEXT,
  CART_TEXT,
  CHECKOUT_TEXT,
  GO_BACK_TO_MENU_TEXT,
  GO_TO_LOCATIONS_TEXT,
  LOGIN_TEXT,
  LOGOUT_TEXT,
  ORDER_FROM_DELIVERY_TEXT,
  ORDER_FROM_KIOSK_TEXT,
  ORDER_FROM_PICKUP_TEXT,
  START_A_NEW_ORDER_TEXT,
};
