import * as React from "react";
import { Formik, Form } from "formik";

import { config, axios, Schemas, Copy } from "utils";
import { PATRON_FORGOT_PASSWORD, PATRON_VERIFY_PINCODE } from "utils/api";
import { Condition, Layout } from "../../elements";
import { Field, Text, View, Button } from "../../elementsThemed";
import { withTemplate } from "../../hocs";
import DrawerButton from "../../DrawerButton";

import styles from "../form.module.scss";

const { Input } = Field;
const { Condition: If } = Condition;
const { Flex } = Layout;

function VerifyEmailForm({ onSuccess, email, style, message }) {
  const [isSending, setIsSending] = React.useState(false);

  const verifyEmail = async ({ code }, actions) => {
    actions.setFieldError("code", "");
    actions.setStatus("");
    try {
      const { data } = await PATRON_VERIFY_PINCODE({ email, pinCode: code });
      if (onSuccess) onSuccess(data);
    } catch (error) {
      const e = axios.handleError(error);
      actions.setStatus(e.data);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const resendPin = async (setStatus) => {
    try {
      setIsSending(true);
      const { data } = await PATRON_FORGOT_PASSWORD({ email });
      setStatus(data.success);
    } catch (error) {
      const e = axios.handleError(error);
      setStatus(e.data);
      console.error(e);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        code: "",
      }}
      validationSchema={Schemas.EmailCodeSchema}
      initialStatus={message}
      onSubmit={verifyEmail}
      render={({
        errors,
        values,
        status,
        setFieldValue,
        setStatus,
        isSubmitting,
      }) => (
        <View
          type={style.views.background}
          className={styles.form}
          Component={Form}
        >
          <div className={styles["fields-container"]}>
            <Input
              label={Copy.FORGOT_PASSWORD_STATIC.VERIFY_EMAIL_INPUT_LABEL}
              name="code"
              type={style.inputs.standard}
              error={errors.code}
              value={values.code}
              placeholder={
                Copy.FORGOT_PASSWORD_STATIC.VERIFY_EMAIL_INPUT_PLACEHOLDER
              }
              onChange={(e) => setFieldValue("code", e.target.value)}
            />
            <div>
              {status ? (
                <Text type={style.labels.error}>{status}</Text>
              ) : (
                <Text type={style.labels.information}>
                  {Copy.FORGOT_PASSWORD_STATIC.VERIFY_EMAIL_INSTRUCTION_TEXT}
                </Text>
              )}
            </div>
            <Button
              type={style.buttons.alternate}
              htmlType="submit"
              style={{ float: "right" }}
              disabled={isSending}
              onClick={() => resendPin(setStatus)}
              data-cy="button-resendPin"
            >
              {isSending ? "Resending" : "Resend Pin"}
            </Button>
          </div>

          <If is={["tacombi", "juicyplatters"].includes(config.id)}>
            <Flex direction="col" align="center" justify="center">
              <Text type={style.labels.primary}>
                {"Haven't received your PIN? Please contact "}
                <Button
                  type={style.buttons.link}
                  Component="a"
                  href="mailto:help@lunchbox.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-cy="button-support"
                >
                  support
                </Button>
              </Text>
            </Flex>
          </If>
          <DrawerButton
            buttonProps={{
              htmlType: "submit",
              disabled: isSubmitting,
              "data-cy": "submit-confirmCode",
            }}
          >
            {Copy.FORGOT_PASSWORD_STATIC.VERIFY_EMAIL_BUTTON_TEXT}
          </DrawerButton>
        </View>
      )}
    />
  );
}

export default withTemplate(VerifyEmailForm, "email");
