import * as React from "react";
import { Col } from "@lunchboxinc/lunchbox-components";
import ClampLines from "react-clamp-lines";
import { Text, View, Button } from "components/elementsThemed";
import { Condition as Conditional } from "components/elements";
import { useCell } from "hooks";
import { formatPrice } from "utils/helpers/string";
import css from "./item.module.scss";

const { Condition } = Conditional;

const Item = ({
  className,
  decrement,
  increment,
  isOptionalSingle,
  item,
  multiSelect,
  onClick,
  quantity,
  type,
}) => {
  const { description = "", name = "", price = 0 } = item;
  const { labelTextStyles, views, buttons } = useCell(type);
  return (
    <Col xs="1-1" sm="1-2" md="1-2" xl="1-2">
      <View
        data-cy="modifier-add-button"
        type={views.background}
        className={`${css.modifierItem} ${className}`}
        onClick={onClick}
      >
        <div className={css["modifierItem-info"]}>
          <div className={css["modifierItem-name"]}>
            <Text type={labelTextStyles.primary}>
              <ClampLines
                id={1}
                buttons={false}
                text={name}
                lines={2}
                ellipsis="..."
              />
            </Text>
            <Condition is={quantity === 0 || !multiSelect}>
              <div className={css["modifierItem-desc"]}>
                <Text
                  maxHeight={57}
                  type={labelTextStyles.secondary}
                  className={css["modifierItem-desc-text"]}
                >
                  {description}
                </Text>
              </div>
            </Condition>
          </div>
          <Condition is={quantity !== 0}>
            <Condition is={multiSelect}>
              <div className={css["modifierItem-selectors"]}>
                <Button
                  type={buttons.primary}
                  className={css["modifierItem-quantity-btn"]}
                  onClick={decrement}
                >
                  -
                </Button>
                <View
                  type={views.secondary}
                  className={css["modifierItem-quantity--text"]}
                  onClick={increment}
                >
                  <Text type={labelTextStyles.tertiary}>{quantity}</Text>
                </View>
              </div>
            </Condition>
            <Condition is={isOptionalSingle}>
              <div className={css["modifierItem-remove"]}>
                <Button type={buttons.primary} onClick={onClick}>
                  X
                </Button>
              </div>
            </Condition>
          </Condition>
        </div>
        <Condition is={price && quantity === 0}>
          <div className={css["modifierItem-price"]}>
            <Text type={labelTextStyles.secondary}>{`${formatPrice(
              price,
            )}`}</Text>
          </div>
        </Condition>
      </View>
    </Col>
  );
};

Item.displayName = "ModifierItemType9";

export default React.memo(Item);
