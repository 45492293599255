export default (state, { type, payload }) => {
  switch (type) {
    case "VALIDATION_ERROR":
      return { ...state, type: 1, ...payload };
    case "SERVER_ERROR":
      return { ...state, type: 2, ...payload };
    case "CLEAR_ERROR":
      return {
        ...state,
        buttonText: "",
        message: "",
        type: null,
      };
    default:
      return state;
  }
};
