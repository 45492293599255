import * as React from "react";
import Styled from "styled-components";
import { Text, View, Cell, Radio } from "components/elementsThemed";
import { formatPrice } from "utils/helpers/string";
import styles from "./item.module.css";

const Item = Styled((props) => {
  const onClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (props.checked) {
      props.onDeselect(props.id);
    } else {
      props.onSelect(props.id, props.price);
    }
  };

  return (
    <Cell
      type={props.type}
      render={({ buttons, labelTextStyles, view }) => (
        <View
          type={view}
          Component="li"
          className={`${styles.item} ${props.className}`}
        >
          <div
            className={styles.name}
            onClick={onClick}
            onKeyDown={onclick}
            role="button"
            tabIndex="0"
          >
            <Text type={labelTextStyles.primary}>{props.name}</Text>
          </div>
          <div className={styles.price}>
            {props.price ? (
              <Text type={labelTextStyles.secondary}>
                {formatPrice(props.price)}
              </Text>
            ) : null}
            &nbsp; &nbsp;
            <Radio
              type={buttons.primary}
              className={styles.radio}
              value={props.checked}
              onChange={onClick}
            />
          </div>
        </View>
      )}
    />
  );
})`
  border-bottom-color: ${(props) => props.theme.colors.alternateGray}
`;

export default React.memo(Item);
