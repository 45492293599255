import _isEmpty from "lodash/isEmpty";
import { constants, config } from "utils";

const {
  ORDER_TYPES: { PICKUP, DELIVERY, KIOSK },
} = constants;

const mapModifiers = (mods) => {
  if (_isEmpty(mods) || !mods.reduce) {
    return [];
  }
  return mods.reduce((acc, curMods) => {
    if (curMods.item) {
      return [
        ...acc,
        {
          item: curMods.item,
          modifiers: mapModifiers(curMods.modifiers),
          option: curMods.option,
        },
      ];
    }
    return [...acc, ...mapModifiers(curMods.modifiers)];
  }, []);
};

const mapItems = (items) => {
  return items.map((i) => ({
    group: i.group,
    item: i.item,
    modifiers: mapModifiers(i.mods),
    notes: i.notes || "",
    restaurantGroup: i.resGroupId || i.restaurantGroup,
  }));
};

const mapDeliveryInfo = (orderContext) => ({
  city: orderContext.order.address.city,
  lat: orderContext.order.address.lat,
  long: orderContext.order.address.long,
  state: orderContext.order.address.state,
  street1: orderContext.order.address.street1,
  street2: orderContext.order.address.street2,
  zip: orderContext.order.address.zip,
});

const mapOrder = (orderContext) => ({
  code: orderContext.order.code || undefined,
  deliveryNotes: orderContext.order.deliveryNotes || undefined,
  discount: orderContext.order.discount || undefined,
  items: [
    ...mapItems(orderContext.items),
    ...mapItems(orderContext.packingItems),
  ],
  note: orderContext.order.note || undefined,
  orderType: orderContext.order.diningOption,
  scheduledAt: orderContext.order.scheduledAt || undefined,
  ...(orderContext.order.diningOption === DELIVERY && {
    deliveryInfo: mapDeliveryInfo(orderContext),
  }),
  storedValueCard: orderContext?.order?.giftCard?.card || undefined,
  tableNumber: orderContext?.order?.table?.id || undefined,

  ...(orderContext.rewardItems.length > 0
    ? { rewards: [orderContext.rewardItems[0].rewardId] }
    : {
        promotionCodeId: orderContext.order.promotionCodeId || undefined,
        promotionId: orderContext.order.promotionId || undefined,
      }),
});

const mapCartOrder = mapOrder;

const mapOrderValidation = (orderContext) => mapOrder(orderContext);

const mapCheckPriceOrder = (orderContext) => {
  const toReturn = {
    ...mapOrder(orderContext),
    giftCardNumber: orderContext?.order?.giftCard?.giftCardNumber,
    giftCardPinCode: orderContext?.order?.giftCard?.pinCode,
  };
  return toReturn;
};

const getAvailableTipByOrderType = (orderType) => {
  const orderTypeToTipAvailableOptions = {
    [DELIVERY]: config?.delivery?.tip_available_options,
    [KIOSK]: config?.kiosk?.tip_available_options,
    [PICKUP]: config?.pickup?.tip_available_options,
  };
  return (
    orderTypeToTipAvailableOptions[orderType] ??
    config?.pickup?.tip_available_options ??
    []
  );
};

export {
  mapItems,
  mapDeliveryInfo,
  mapOrder,
  mapCartOrder,
  mapOrderValidation,
  mapCheckPriceOrder,
  getAvailableTipByOrderType,
};
