import * as React from "react";
import {
  useOverlay,
  usePreventScroll,
  useModal,
  OverlayContainer,
} from "@react-aria/overlays";
import { useDialog } from "@react-aria/dialog";
import { FocusScope } from "@react-aria/focus";

import ModalButton from "./ModalButton";

function Dialog({ title, children, ...props }) {
  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const ref = React.useRef();
  const { overlayProps, underlayProps } = useOverlay(props, ref);

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(props, ref);

  return (
    <div {...underlayProps}>
      <FocusScope contain restoreFocus autoFocus>
        <div {...overlayProps} {...dialogProps} {...modalProps} ref={ref}>
          {title && (
            <h3 {...titleProps} style={{ marginTop: 0 }}>
              {title}
            </h3>
          )}
          {children}
        </div>
      </FocusScope>
    </div>
  );
}

export default function Modal({
  children,
  toggle,
  onClick,
  buttonLabel,
  modalTitle,
}: any) {
  return (
    <>
      <ModalButton type="open" buttonLabel={buttonLabel} onClick={onClick} />
      {toggle && (
        <OverlayContainer>
          <Dialog title={modalTitle} isOpen onClose={onClick} isDismissable>
            {children}
          </Dialog>
        </OverlayContainer>
      )}
    </>
  );
}
