/*
 * ItemDetails Layout Subgroups
 * Author: Isaiah Sanchez <isanchez@lunchbox.io>
 *
 * This component renders the ItemDetails using groups/subgroups.
 * An item can have groups of different modifiers.
 */

import SubgroupsLayout from "./selector";

export default SubgroupsLayout;
