/*
 * ItemDetails Tab Type6
 * Author: Isaiah Sanchez <isanchez@lunchbox.io>
 *
 * This component groups ModifierItems into "tabs" or sections.
 * Rendered from ItemDetails/Group or ItemDetails/Layout.
 * Copied from Type2. Currently used in Upper Crust Pizzeria.
 *
 * TODO:
 * During refactor, likely can combine with Type2
 *
 */

import TabType6 from "./tabType6";

export default TabType6;
