import * as React from "react";
import loadable from "@loadable/component";
import { config } from "utils";

const AsyncPage = loadable((props) =>
  import(`./${props.page}/RestaurantsList`),
);

export const RestaurantsList = (props) => {
  return (
    <AsyncPage
      page={config.theme.market.restaurants_list || "Type1"}
      {...props}
    />
  );
};
