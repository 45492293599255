import _isEmpty from "lodash/isEmpty";

export enum ModsActionType {
  ADD_MOD = "ADD_MOD",
  REMOVE_MOD = "REMOVE_MOD",
  CLEAR_MOD = "CLEAR_MOD",
  SET_MOD = "SET_MOD",
}

export type Modifiers = {
  item: string;
  modifiers: ModifiersState;
  option: string;
  quantity: number;
  mods;
};

export type ModifiersState = {
  [optionId: string]: [Modifiers];
};

export type ModsReducerPayload = {
  isNested: boolean;
  item: string;
  modifiers: ModifiersState;
  multiSelect: boolean;
  option: string;
  price: number;
  quantity: number;
};

export type ModsAction = {
  type: ModsActionType;
  payload?: ModsReducerPayload;
};

export const updateModifierState = (
  {
    state = {},
    payload,
  }: { state: ModifiersState; payload: ModsReducerPayload },
  quantity: number,
) => {
  // Empty modifications state, skip
  if (_isEmpty(state)) {
    return {};
  }

  // Add item to current modifications state
  if (payload?.option) {
    const existingItems = state?.[payload?.option];
    if (existingItems) {
      const otherItems = existingItems?.filter(
        (items) => items?.item !== payload?.item,
      );
      const updatedItem = {
        item: payload?.item,
        modifiers: payload?.modifiers,
        option: payload?.option,
        quantity: payload?.quantity + quantity,
      };
      const shouldRemove = updatedItem.quantity === 0;

      return {
        ...state,
        [payload?.option]: [
          ...otherItems,
          ...(shouldRemove ? [] : [updatedItem]),
        ],
      };
    }
  }

  // Loop current modifications state to find the option
  return Object.entries(state).reduce((acc, [id, items = []]) => {
    // Skip empty items
    if (!items.length) {
      return acc;
    }

    // Move deeper into each item and it's modifiers
    const updatedItems = items?.reduce(
      (accuItems, { item, modifiers, option }) => {
        // Skip items without modifiers (not nested)
        if (_isEmpty(modifiers)) {
          return accuItems;
        }

        // Add item to nested item modifiers
        const updatedModifiers = updateModifierState(
          {
            payload,
            state: modifiers,
          },
          quantity,
        );

        return [...accuItems, { item, modifiers: updatedModifiers, option }];
      },
      items,
    );

    return { ...acc, [id]: updatedItems };
  }, state);
};

export const modsReducer = (state, action: ModsAction) => {
  const { type, payload } = action;
  switch (type) {
    case ModsActionType.ADD_MOD: {
      return updateModifierState(
        {
          payload,
          state,
        },
        // Nested quantities can be chosen in a drawer,
        // respect the payload quantity.
        payload?.isNested ? 0 : 1,
      );
    }
    case ModsActionType.REMOVE_MOD: {
      return updateModifierState(
        {
          payload,
          state,
        },
        -1,
      );
    }
    case ModsActionType.CLEAR_MOD: {
      return {};
    }
    case ModsActionType.SET_MOD: {
      return payload;
    }
    default: {
      return state;
    }
  }
};
