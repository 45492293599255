import * as React from "react";
import PropTypes from "prop-types";
import { Image } from "../../elements";
import { useLazyLoad, usePreloadImage } from "../../../hooks";

/**
 * Component will keep track of if the element is in view and load the src image only if it comes into view
 * Accept a Component prop and will use styles attribute if the image is not and img tag
 *
 * @author Mohammad Afzal
 * @memberof Fragments.Fragments/LazyImage
 * @param {object} props - see key names below for for information on specific props.
 * @param {object} props.src - any valid img src to display/load when in view.
 * @param {object} props.style - a normal style prop one would pass to any html element in react.
 * @param {object} props.placeholder - placeholder image to put in place of the actual image while it loads.
 * @param {React.elementType|string} props.Component - The react component/html element to inject the src prop depending on if its view.
 * @returns {React.Element} Either an Image tag with src props applied or the component with the image in the styles.
 */
const LazyImage = ({
  Component = "img",
  src,
  placeholder,
  style,
  ...props
}) => {
  const nodeRef = React.useRef();
  const isOnScreen = useLazyLoad(nodeRef);
  const isLoaded = usePreloadImage(src, isOnScreen);
  const isBackgroundImage = !["img", Image].includes(Component);
  const imageSrc = isOnScreen && isLoaded ? src ?? placeholder : placeholder;

  if (isBackgroundImage) {
    return (
      <Component
        ref={nodeRef}
        {...props}
        style={{ ...style, backgroundImage: `url('${imageSrc}')` }}
      />
    );
  }
  return <Image ref={nodeRef} {...props} src={imageSrc} />;
};

LazyImage.defaultProps = {
  Component: "img",
  placeholder: "",
  style: {},
};
LazyImage.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  placeholder: PropTypes.string,
  src: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.object),
};
export default LazyImage;
