import * as React from "react";
import { config } from "utils";
import { Square } from "./Square";
import Authorize from "./Authorize.net";
import { Seamless } from "./Seamless";
import { Stripe } from "./Stripe";
import Spreedly from "./Spreedly/spreedly";

function PaymentProcessorSelector(props) {
  let Component = null;
  if (!config.payment_processor) {
    console.error("No payment Processor!");
    return <></>;
  }
  switch (config.payment_processor) {
    case "square":
      Component = Square;
      break;
    case "authorize.net":
      Component = Authorize;
      break;
    case "seamless":
      Component = Seamless;
      break;
    case "spreedly":
      Component = Spreedly;
      break;
    case "stripe":
      Component = Stripe;
      break;
    default:
  }

  return <Component {...props} />;
}

export default PaymentProcessorSelector;
