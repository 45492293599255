import * as React from "react";
import { withErrorBoundary } from "components/hocs";
import Fallback from "./Fallback";

/**
 * Component for Beam PersonalImpact widget
 *
 * @author Htin Linn Aung
 * @memberof Fragments.Fragments/Beam
 * @param {object} props
 * @param {object} props.widgetColors - From BeamImpact
 * @param {string} props.widgetId - Provided by Beam. It is in config
 * @param {object} props.beam - State in pages/Cart/routes.jsx
 * @returns {React.Element} - Component for Beam PersonalImpact widget
 */
const BeamPersonalImpact = ({ widgetColors, widgetId, beam }) => {
  const {
    explainerTextColor,
    defaultExplainerTextColor,
    impactProgressBarColor,
  } = widgetColors;
  const explainerColor = explainerTextColor || defaultExplainerTextColor;
  const user = beam?.widget?.transactionData?.user;
  const [beamAppsLoading, setBeamAppsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!widgetId) {
      console.error("[Beam Impact] Widget ID is required.");
      return;
    }

    setBeamAppsLoading(true);
    const initializeWidget = async () => {
      // Need to add mechanism to handle duplicate instantiation

      const widget = new window.beamApps.PersonalImpactWidget({
        containerId: "beam-personal-impact-widget-container",
        forceMobileView: "true",
        loadingScreenContent: "",
        textColor: explainerColor,
        themeConfig: {
          id: "lunchbox-nonprofit",
          progressBarColors: [
            {
              color: impactProgressBarColor,
              offset: "100%",
            },
          ],
          textColor: explainerColor,
        },
        widgetId,
      });

      if (user) await widget.render(user);
    };

    if (window.beamApps) {
      setBeamAppsLoading(false);
      initializeWidget();
    }
  }, [beamAppsLoading, window.beamApps]);

  return <div id="beam-personal-impact-widget-container" />;
};

export default withErrorBoundary(BeamPersonalImpact, Fallback);
