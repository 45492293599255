import AccountSchema from "./accountSchema";
import AddressSchema from "./addressSchema";
import DiscountSchema from "./discountSchema";
import EmailCodeSchema from "./emailCodeSchema";
import EmailSchema from "./emailSchema";
import { ImageSchema } from "./imageSchema";
import {
  GiftCardWithPasswordSchema,
  GiftCardWithoutPasswordSchema,
} from "./giftCardSchema";
import LoginSchema from "./loginSchema";
import PatronNameSchema from "./patronNameSchema";
import PaymentProcessor from "./paymentProcessor";
import PhoneCodeSchema from "./phoneCodeSchema";
import PhoneSchema from "./phoneSchema";
import SignUpSchema from "./signUpSchema";
import SignUpSocialSchema from "./signUpSocialSchema";
import TableNumberSchema from "./tableNumberSchema";
import UpdatePasswordSchema from "./updatePasswordSchema";
import { GuestCheckoutSchema } from "./orderSchema";

export * from "./paymentProcessor";

export {
  AccountSchema,
  AddressSchema,
  DiscountSchema,
  EmailCodeSchema,
  EmailSchema,
  GiftCardWithPasswordSchema,
  GiftCardWithoutPasswordSchema,
  GuestCheckoutSchema,
  ImageSchema,
  LoginSchema,
  PatronNameSchema,
  PhoneCodeSchema,
  PhoneSchema,
  SignUpSchema,
  SignUpSocialSchema,
  UpdatePasswordSchema,
};

export default {
  AccountSchema,
  AddressSchema,
  DiscountSchema,
  EmailCodeSchema,
  EmailSchema,
  GiftCardWithoutPasswordSchema,
  GiftCardWithPasswordSchema,
  GuestCheckoutSchema,
  ImageSchema,
  LoginSchema,
  PatronNameSchema,
  PaymentProcessor,
  PhoneCodeSchema,
  PhoneSchema,
  SignUpSchema,
  SignUpSocialSchema,
  TableNumberSchema,
  UpdatePasswordSchema,
};
