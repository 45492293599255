import * as React from "react";
import { AriaListBoxOptions, useListBox, useOption } from "@react-aria/listbox";
import { DismissButton, useOverlay } from "@react-aria/overlays";
import classnames from "classnames";
import { useTemplate } from "hooks/template";
import { FocusScope } from "@react-aria/focus";
import { ComboBoxState, useComboBoxState } from "@react-stately/combobox";
import { Button } from "components/elementsThemed";
import { useCachedAddresses } from "../../../contexts/CachedAdresses";
import { Item } from "@react-stately/collections";
import { Copy } from "utils";
import { useComboBox } from "@react-aria/combobox";
import debounce from "lodash/debounce";

import { FETCH_ADDRESS } from "../../../utils/api";

import css from "./AddressSearch.module.scss";

const MIN_SEARCHABLE_LENGTH = 3;

/** A search result from /addresses?address= */
type AddressResult = {
  id: string;
  text: string;
};

type OrderType = "pickup" | "kiosk" | "delivery";

type AddressSearchArgs = {
  onFocus?: () => void;
  onError?: () => void;
  onSelect(value: AddressResult): void | Promise<void>;
  orderType: OrderType;
  fetch: typeof FETCH_ADDRESS;
};

/**
 * Renders focus management for search results.
 */
const Popover: React.FC<{
  popoverRef: React.RefObject<HTMLDivElement>;
  isOpen: boolean;
  onClose(): void;
  children: React.ReactNode;
}> = (props) => {
  const { popoverRef, isOpen, onClose, children } = props;
  const { overlayProps } = useOverlay(
    {
      isOpen,
      onClose,
      shouldCloseOnBlur: true,
      isDismissable: true,
    },
    popoverRef,
  );

  return (
    <FocusScope restoreFocus>
      <div {...overlayProps} ref={popoverRef}>
        {children}
        <DismissButton onDismiss={onClose} />
      </div>
    </FocusScope>
  );
};

/**
 * Renders a wrapper for search results.
 */
type ListBoxProps<T> = {
  listBoxRef: React.RefObject<HTMLDivElement>;
  state: ComboBoxState<T>;
} & AriaListBoxOptions<T>;

function ListBox<T>(props: ListBoxProps<T>) {
  const { listBoxRef, state } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <div {...listBoxProps} ref={listBoxRef}>
      {Array.from(state.collection).map((item) => (
        <Option key={item.key} item={item} state={state} />
      ))}
    </div>
  );
}

/**
 * Renders a single address option.
 */
type OptionProps<T> = {
  item: any;
  state: ComboBoxState<T>;
};

function Option<T>({ item, state }: OptionProps<T>) {
  const ref = React.useRef<HTMLLIElement>(null);
  const { optionProps, isFocused } = useOption({ key: item.key }, state, ref);
  const template = useTemplate("address");
  const style = template.buttons.searchResults;

  return (
    <>
      <Button
        type={style}
        // TODO: Need to fix type definition of this prop.
        // @ts-ignore
        Component="div"
        {...optionProps}
        innerRef={ref}
        className={classnames(
          css["search-result"],
          css[style],
          isFocused && css["search-result-has-focus"],
        )}
      >
        {item.rendered}
      </Button>
      <hr aria-hidden="true" />
    </>
  );
}

/**
 * Provides shared logic for address searching used by
 * delivery and kiosk/pickup screens.
 *
 * Behaviors:
 * - Recent addresses are stored in localStorage and used as the
 *   default set of suggestions.
 * - Focus events are used to trigger the combobox opening instead
 *   of input entry, so that stored addresses are immediately available.
 * - Network requests are made in a 300ms debounce after user input.
 *
 * TODO: network requests should be managed by `react-query` so that
 * they can be cached and further optimized.
 */
function useAddressSearch({
  onFocus = () => {},
  onError = () => {},
  onSelect,
  orderType,
  fetch,
}: AddressSearchArgs) {
  const [fetching, setFetching] = React.useState(false);
  const [err, setErr] = React.useState<any>(null);
  const { recentAddresses = [], updateRecentAddressesState } =
    useCachedAddresses();
  const [addressItems, setAddressItems] =
    React.useState<AddressResult[]>(recentAddresses);
  const filteredAddressItems = addressItems.filter((address) =>
    Boolean(address.id),
  );
  const children = filteredAddressItems.map(({ id, text }) => (
    <Item key={id}>{text}</Item>
  ));
  const inputRef = React.useRef<HTMLInputElement>(null);
  const listBoxRef = React.useRef<HTMLDivElement>(null);
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const debouncedFetch = React.useRef(
    debounce(async (address) => {
      const { data } = await fetch({ orderType, address });
      setAddressItems(data);
    }, 300),
  );

  const comboBoxState = useComboBoxState({
    children,
    items: filteredAddressItems,
    menuTrigger: "focus",
    onFocus,
    onSelectionChange: async (id: React.Key) => {
      const address = filteredAddressItems.find((item) => item.id === id);
      if (address) {
        try {
          await onSelect(address);
          if (err !== null) setErr(null);
          updateRecentAddressesState(address);
        } catch (err) {
          setErr("Unable to deliver to the address you've provided.");
          onError();
        }
      }
    },
    onInputChange: async (address) => {
      const value = address.trim();
      if (value.length >= MIN_SEARCHABLE_LENGTH) {
        try {
          setFetching(true);
          await debouncedFetch.current(value);
          setFetching(false);
        } catch (err) {
          console.error(err);
          onError();
        }
      }
    },
  });
  const comboBoxProps = useComboBox(
    {
      label: <span>{Copy.RG_INFO_STATIC.LOCATION_TEXT}</span>,
      children,
      inputRef,
      listBoxRef,
      popoverRef,
    },
    comboBoxState,
  );

  return {
    err,
    comboBoxProps,
    comboBoxState,
    inputRef,
    listBoxRef,
    popoverRef,
    fetching,
  };
}

export { Popover, ListBox, Option, useAddressSearch };
export type { AddressResult };
