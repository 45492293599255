import { Copy } from "utils/Copy";

export const mapValidateResponseCopy = (reason) => {
  switch (reason) {
    case "pickup-closed":
    case "delivery-closed":
      return Copy.CART_STATIC.SCHEDULED_DATE_EMPTY;
    case "dinein-closed":
    case "kiosk-closed":
      return Copy.CART_STATIC.SCHEDULED_DATE_LOCATION_DINEIN_UNAVAILABLE;
    case "pickup-unavailable":
      return Copy.CART_STATIC.SCHEDULED_DATE_LOCATION_PICKUP_UNAVAILABLE;
    case "delivery-unavailable":
      return Copy.CART_STATIC.SCHEDULED_DATE_LOCATION_DELIVERY_UNAVAILABLE;
    case "dinein-unavailable":
    case "kiosk-unavailable":
      return Copy.CART_STATIC.SCHEDULED_DATE_LOCATION_DINEIN_UNAVAILABLE;
    case "closed":
    case "unavailable":
      return Copy.CART_STATIC.SCHEDULED_DATE_LOCATION_CLOSED;
    default:
      return "ASAP";
  }
};
