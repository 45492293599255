// TODO seperate stripe logic from route logic
import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { withTheme } from "styled-components";

import { withTemplate } from "components/hocs";
import { Condition } from "components/elements";
import { useResource } from "hooks";
import { Loader, FooterButton, Confirm } from "components/fragments";
import { Copy, Routes } from "utils";
import csx from "classnames";
import { RouteWithProps } from "components/fragments/Routes";
import { PATRON_DELETE_CARD } from "utils/api";
import SpreedlyPaymentForm from "./SpreedlyPaymentForm";
import { CARD_ROUTES } from "../../../../pages/Profile/Cards/utils";
import { CheckoutCardList } from "../Stripe/CheckoutCardList";

import css from "../Stripe/stripe.module.scss";

const { Condition: If } = Condition;

function Spreedly(props) {
  const {
    style: { cells },
    onSuccess,
    history,
  } = props;

  const [{ resource: locationCreds, fetching }] = useResource({
    data: {},
    method: "get",
    path: "/location/credentials",
  });

  const [{ resource = [] }] = useResource({
    path: Routes.FETCH_CARDS,
  });

  const toAddCard = () => history.push(CARD_ROUTES.ADD_CARD);

  const deleteCard = async (id) => {
    try {
      await PATRON_DELETE_CARD(id);
      history.goBack();
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Route>
      <Switch>
        <RouteWithProps
          path={CARD_ROUTES.ADD_CARD}
          render={() => (
            <>
              <If is={fetching}>
                <Loader />
              </If>
              <If is={locationCreds.publicKey}>
                <SpreedlyPaymentForm
                  locationCreds={locationCreds}
                  fetching={fetching}
                />
              </If>
            </>
          )}
          order={{}}
          onSuccess={history.goBack}
        />
        <RouteWithProps
          path={CARD_ROUTES.DELETE_CARD}
          render={({ match }) => (
            <Confirm
              isNegativeConfirm
              message={Copy.PROFILE_STATIC.DELETE_CARD_CONFIRM_MESSAGE}
              confirmText={Copy.PROFILE_STATIC.CONFIRM_BUTTON_TEXT}
              cancelText={Copy.PROFILE_STATIC.CANCEL_BUTTON_TEXT}
              onConfirm={() => deleteCard(match.params.cardId)}
              onCancel={history.goBack}
            />
          )}
        />
        <RouteWithProps
          path={Routes.FETCH_CARDS}
          render={() => {
            if (resource.length) {
              return (
                <div className={css.stripeForm}>
                  <div
                    className={csx(css["stripeForm-container"], "payment-form")}
                  >
                    <CheckoutCardList
                      resource={resource}
                      cells={cells}
                      onSuccess={onSuccess}
                      history={history}
                    />
                  </div>
                  <FooterButton
                    id="my-submit"
                    onClick={toAddCard}
                    type={cells.bottom}
                  >
                    {Copy.PAYMENT_FORM_STATIC.ADD_NEW_CARD}
                  </FooterButton>
                </div>
              );
            }
            return (
              <>
                <If is={fetching}>
                  <Loader />
                </If>
                <If is={locationCreds.publicKey}>
                  <SpreedlyPaymentForm
                    locationCreds={locationCreds}
                    fetching={fetching}
                  />
                </If>
              </>
            );
          }}
        />
      </Switch>
    </Route>
  );
}

export default withTheme(withTemplate(Spreedly, "payment"));
