import * as React from "react";
import styles from "./index.module.css";
import Option from "../../Options";
import { config } from "utils";

const findError = (errors, id) => {
  const match = errors.find((i) => i.optionId === id);
  if (match) {
    return match.message;
  }
  return null;
};

const Type2 = ({
  tabs,
  cells,
  errors,
  errorRefs,
  addModifier,
  removeModifier,
  menuOptions,
  modifications,
}) => {
  const entities = tabs.reduce((accu, dt) => [...accu, ...dt.entities], []);

  entities.sort((a, b) => b.priority - a.priority);

  return (
    <div className={styles.container} data-cy="itemDetail-tabs">
      {entities.map((option, index) => (
        <Option
          key={option.id}
          forwardRef={errorRefs.current[index]}
          type={cells.group}
          optionItemTypes={{
            default: cells.primary,
            selected: cells.primarySelected,
          }}
          parentOption={menuOptions[option.id]}
          {...menuOptions[option.id]}
          error={findError(errors, option.id)}
          selectedMods={modifications[option.id] || []}
          addModifier={addModifier}
          removeModifier={removeModifier}
        />
      ))}
    </div>
  );
};

Type2.displayName = "TabType2";

export default Type2;
