import * as React from "react";
import { MemoryRouter, Route } from "react-router-dom";
import { AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { Fragments, Elements, HOCs, ElementsThemed } from "components";
import { useGlobalConfig } from "components/providers/GlobalConfig/globalConfig";
import { Copy, Routes } from "utils";
import { OrderHeader } from "./components";
import OrderRoutes from "./OrderRoutes";
import styles from "./style.module.scss";
import { useMenuContext } from "components/providers/Menu/MenuContext";

const {
  Layout: { Flex },
} = Elements;
const { Text } = ElementsThemed;
const { Errors } = Fragments;
const { withTemplate } = HOCs;

const Order = ({
  style = null,
  cart = null,
  location: browserLocation,
  orderContext,
  patronContext,
  showHeader = true,
  close,
  history,
  isLoggedIn,
  isEmployee,
}) => {
  const { packingMenu } = useMenuContext();
  const { removeFromOrder, items } = orderContext;
  // Hooks
  const [header, setHeader] = React.useState("");
  const globalConfigContext = useGlobalConfig();

  // Constants
  const { labels } = style;

  let memoryRouterProps = {
    initialEntries: [Routes.ROOT],
    initialIndex: 0,
  };
  if (
    globalConfigContext?.isAppleSignUp ||
    globalConfigContext?.isFacebookSignUp
  ) {
    const isRestored =
      globalConfigContext?.lastLocation?.search("/location/") !== -1;
    if (isRestored) {
      const entries = [Routes.ROOT, Routes.LOG_IN];
      memoryRouterProps = {
        initialEntries: entries,
        initialIndex: entries.length,
      };
    }
  }
  // use effect to clear parking items from items if a re-order i called.
  React.useEffect(() => {
    const packingItemIds = (packingMenu?.items?.array || []).map(
      (item) => item.id,
    );
    //find packing items
    const packingItemInCart =
      items.filter((item) => packingItemIds.includes(item.item)) || [];
    // only add items to cart that are not packing items
    if (packingItemInCart && packingItemInCart.length) {
      // packingItemsIncart could be > 1
      packingItemInCart.forEach((itemInCart) => {
        removeFromOrder(items.findIndex((item) => item._id === itemInCart?.id));
      });
    }
  }, [packingMenu]);

  return (
    <AnimateSharedLayout type="crossfade">
      <MemoryRouter {...memoryRouterProps}>
        <Route
          render={({ location, history }) => (
            <Flex className={styles.order} direction="col" grow="1">
              {showHeader && (
                <OrderHeader
                  header={header}
                  order={orderContext}
                  history={history}
                  location={location}
                  close={close}
                />
              )}

              <Errors
                message={
                  <Text type={labels.secondary}>
                    {Copy.CART_STATIC.HOME_ERROR_MESSAGE}
                  </Text>
                }
              >
                <AnimatePresence>
                  <OrderRoutes
                    browserLocation={browserLocation}
                    location={location}
                    history={history}
                    cart={cart}
                    orderContext={orderContext}
                    patronContext={patronContext}
                    setHeader={setHeader}
                    onClose={close}
                  />
                </AnimatePresence>
              </Errors>
            </Flex>
          )}
        />
      </MemoryRouter>
    </AnimateSharedLayout>
  );
};

export default withTemplate(Order, "cart");
