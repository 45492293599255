import * as React from "react";
import { withRouter } from "react-router-dom";

const Nav = ({ children, component: Component = "div" }) => (
  <Component style={{ display: "flex" }}>{children}</Component>
);
const NavItem = withRouter(
  ({ children, route, location, history, ...props }) => (
    <div
      style={{
        cursor: "pointer",
        flexGrow: "1",
        padding: "10px",
        textAlign: "center",
      }}
      onClick={() => {
        history.replace(route);
        if (props.onChange) props.onChange(route);
      }}
      onKeyDown={(e) => {
        if (e.which === 13) {
          history.replace(route);
          if (props.onChange) props.onChange(route);
        }
      }}
      role="button"
      tabIndex="-1"
    />
  ),
);

export { Nav, NavItem };
export default Nav;
