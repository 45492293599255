import * as React from "react";
import { useTabListState } from "@react-stately/tabs";
import { useTab, useTabList, useTabPanel } from "@react-aria/tabs";
import { Item } from "@react-stately/collections";
import { useFocusVisible } from "@react-aria/interactions";
import { Text, View } from "components/elementsThemed";
import { useTemplateContext } from "components/providers/Template";
import { createGlobalStyle } from "styled-components";
import classNames from "classnames";
import { useSegment, useTemplate } from "hooks/template";
import "./rc-tab-overrides.scss";

interface TabsType2Props {
  children: React.ReactElement<{ title: string; children: React.ReactNode }>[];
  onTabChange(activeKey: OrderType): void;
  activeKey?: OrderType;
  scrolling?: boolean;
}

/**
 * Renders a group of tabs.
 */
const TabsType2: React.FC<TabsType2Props> = ({
  onTabChange,
  activeKey = null,
  scrolling = false,
  ...props
}) => {
  const tabListChildren = props.children.map((child) => (
    <Item key={child.key} title={child.props.title}>
      {child.props.children}
    </Item>
  ));

  const tabListState = useTabListState({
    selectedKey: activeKey as string,
    children: tabListChildren,
    onSelectionChange: (tab) => onTabChange(tab as OrderType),
  });
  const tabListRef = React.useRef<HTMLDivElement>(null);
  const { tabListProps } = useTabList(
    {
      children: tabListChildren,
      orientation: "horizontal",
    },
    tabListState,
    tabListRef,
  );

  return (
    <div className="rc-tabs rc-tabs-top">
      <GlobalStyle />
      <div {...tabListProps} ref={tabListRef} className="rc-tabs-bar">
        <div>
          {Array.from(tabListState.collection).map((item) => (
            <Tab key={item.key} item={item} state={tabListState} />
          ))}
        </div>
      </div>

      <TabPanel key={tabListState.selectedItem?.key} state={tabListState} />
    </div>
  );
};

const GlobalStyle = createGlobalStyle`
  .rc-tabs-tab {
    padding: 0px
  }
`;

/**
 * Renders a single tab.
 */
const Tab: React.FC<any> = ({ item, state }) => {
  const { key, rendered } = item;
  const ref = React.useRef<HTMLDivElement>(null);
  const { tabProps } = useTab({ key }, state, ref);
  const { theme } = useTemplateContext();
  const { segmentViews } = useTemplate("location");
  const { segment } = useSegment(segmentViews.standard);
  const { isFocusVisible } = useFocusVisible();

  const { stateTextStyles, stateBackgroundColors, view } = segment;
  const isSelected = state.selectedKey === key;
  const color = isSelected
    ? stateBackgroundColors.selected
    : stateBackgroundColors.unselected;
  const hash = theme.colors[color];
  const className = classNames(
    "rc-tabs-tab",
    isSelected && "rc-tabs-tab-active",
    !isFocusVisible && "rc-tabs-tab-nofocus",
  );

  return (
    <div {...tabProps} ref={ref} className={className}>
      <View
        type={view}
        style={{
          backgroundColor: hash || "transparent",
        }}
      >
        <Text
          type={
            isSelected ? stateTextStyles.selected : stateTextStyles.unselected
          }
          data-test={`tab-${key}-button`}
        >
          {rendered}
        </Text>
      </View>
    </div>
  );
};

/**
 * Renders the children of the selected tab.
 */
const TabPanel: React.FC<any> = ({ state, ...props }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { tabPanelProps } = useTabPanel(props, state, ref);

  return (
    <div {...tabPanelProps} ref={ref}>
      {state.selectedItem?.props.children}
    </div>
  );
};

export { TabsType2 };
