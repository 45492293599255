import * as React from "react";
import { boolean } from "yup";
import { Text } from "components/elementsThemed";
import { formatPrice } from "utils/helpers/string";
import styles from "../style.module.scss";

interface PriceFieldProps {
  name: string;
  value: number | number[];
  neg?: boolean;
  type: string;
  info?: React.ReactNode;
  error?: string;
  clear?: React.ReactNode;
}

const PriceField: React.FC<PriceFieldProps> = ({
  name,
  value,
  neg,
  type,
  info,
  error,
  clear,
  ...props
}) => (
  <div className={styles["checkout-summary--row"]} {...props}>
    <span>
      <Text type={type}>{name}</Text>
      {info && (
        <>
          &nbsp;
          {info}
        </>
      )}
    </span>
    <Text type={type}>
      {error}
      {!error && (neg ? `(-${formatPrice(value)})` : formatPrice(value))}
      {clear}
    </Text>
  </div>
);

PriceField.defaultProps = {
  neg: false,
  info: "",
  error: "",
  clear: "",
};

export default PriceField;
