export type GiftCard = {
  balance: string;
  giftCardNumber: string;
};

export interface GiftCardComponentProps {
  onGiftCardAdded(e: GiftCardSuccessPayload): void;
  isPinCodeRequired: boolean;
}

export type GiftCardSuccessPayload = {
  balance: number;
  giftCardNumber: string;
  pinCode?: string;
};

export function getAppliedGiftCardText(giftCard: GiftCard) {
  const { giftCardNumber, balance } = giftCard;
  return `****${giftCardNumber.slice(
    giftCardNumber.length - 4,
  )} ($${balance} available)`;
}

export const MOCK_BALANCE = "$20.00";
export const MOCK_VALUE = "1234";
export const MOCK_PINCODE_URL = `/stored-value-cards/${MOCK_VALUE}`;

export const MOCK_GIFT_CARD = {
  balance: MOCK_BALANCE,
  giftCardNumber: MOCK_VALUE,
};
