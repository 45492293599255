import * as React from "react";
import styles from "./customTipping.module.scss";
import { customAmountToPercentage } from "./helpers";
import { CustomBtnProps } from "./types";

const CustomTipButton: React.FC<CustomBtnProps> = ({
  onOpen,
  isCustomized,
  activeBorder,
  customAmount,
  totalAmount,
  customTipActiveStyle,
}) => {
  // reset the percentage to 0 if the input is cleared
  const percentage = !customAmountToPercentage(totalAmount, customAmount)
    ? 0
    : customAmountToPercentage(totalAmount, customAmount);
  // reset the amount if the customAmount is undefined
  const amount = !customAmount ? "0.00" : customAmount.toFixed(2);

  return (
    <fieldset
      style={activeBorder}
      className={styles["tipsContainer-wrapper--singleTip"]}
    >
      <legend
        data-testid="custom-tip-percent"
        className={styles["tipsContainer-wrapper--legend"]}
        style={{
          padding: isCustomized ? "0rem 0.625rem" : "0.40rem 0rem",
        }}
      >
        {isCustomized && customAmount ? `${percentage}%` : null}
      </legend>
      <button
        className={styles["tipsContainer-wrapper--amount"]}
        onClick={onOpen}
        aria-pressed={isCustomized && customAmount ? "true" : "false"}
      >
        <span
          className={
            isCustomized
              ? styles["tipsContainer-wrapper--customAmountSelected"]
              : null
          }
          style={isCustomized ? customTipActiveStyle : null}
        >
          {isCustomized && customAmount ? `$${amount}` : "Custom"}
        </span>
      </button>
    </fieldset>
  );
};

export { CustomTipButton };
