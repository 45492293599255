/*
 * ItemDetails Group Type1
 * Author: Isaiah Sanchez <isanchez@lunchbox.io>
 *
 * Items can consist of groups which contain different modifiers.
 * This component is rendered by ItemDetails/Layout/Group.
 * This component renders each group title, followed by selectable items (the groups).
 * Select an item to change the corresponding group of modifiers below.
 * The container for the modifiers is rendered in ItemDetails/Tabs.
 * Styled for a circle selector on the right with the price.
 * Currently used in Upper Crust Pizzeria.
 *
 */

import GroupType1 from "./groupType1";

export default GroupType1;
