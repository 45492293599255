/* eslint-disable no-nested-ternary */
import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { ElementsThemed, Fragments, Providers } from "components";
import { PaymentForm } from "components/templates";
import { Copy } from "utils";
import { PATRON_UPDATE_CARD, PATRON_DELETE_CARD } from "utils/api";

import { CARD_ROUTES } from "./utils";
import CardList from "./cardList";

const { Text, View, Dialogue } = ElementsThemed;
const {
  Routes: { RouteWithProps },
  Confirm,
} = Fragments;

const {
  Notifications: { useNotification },
} = Providers;

const renderNotification = (type, title, message) => (
  <Dialogue type={type}>
    {({ labelTextStyles, view }) => (
      <View type={view} style={{ padding: "10px" }}>
        <div>
          <Text type={labelTextStyles.secondary}>{title}</Text>
        </div>
        <div>
          <Text type={labelTextStyles.primary}>{message}</Text>
        </div>
      </View>
    )}
  </Dialogue>
);

const Cards = ({ style, history }) => {
  const { add } = useNotification();
  const notice = (title, message) => {
    add(renderNotification(style.dialogues.confirm, title, message));
  };

  const deleteCard = async (id) => {
    try {
      await PATRON_DELETE_CARD(id);
      notice(
        Copy.PROFILE_STATIC.SUCCESS_HEADER,
        Copy.PROFILE_STATIC.CARD_DELETED_MESSAGE,
      );
      history.goBack();
    } catch (error) {
      console.error(error);
      notice(
        Copy.PROFILE_STATIC.ERROR_HEADER,
        Copy.PROFILE_STATIC.ERROR_MESSAGE,
      );
    }
  };

  const updateCard = async (id, data) => {
    try {
      await PATRON_UPDATE_CARD(id, data);
      notice(
        Copy.PROFILE_STATIC.SUCCESS_HEADER,
        Copy.PROFILE_STATIC.PRIMARY_CARD_UPDATED,
      );
      history.goBack();
    } catch (error) {
      console.error(error);
      notice(
        Copy.PROFILE_STATIC.ERROR_HEADER,
        Copy.PROFILE_STATIC.ERROR_MESSAGE,
      );
    }
  };

  return (
    <Route path={CARD_ROUTES.CARD_ROOT}>
      <Switch>
        <RouteWithProps
          path={CARD_ROUTES.ADD_CARD}
          component={PaymentForm}
          order={{}}
          onSuccess={history.goBack}
        />
        <RouteWithProps
          path={CARD_ROUTES.UPDATE_CARD}
          render={({ match }) => (
            <Confirm
              message={Copy.PROFILE_STATIC.SET_AS_PRIMARY_PAYMENT}
              confirmText={Copy.PROFILE_STATIC.CONFIRM_BUTTON_TEXT}
              cancelText={Copy.PROFILE_STATIC.CANCEL_BUTTON_TEXT}
              onConfirm={async () => {
                await updateCard(match.params.cardId, { isPrimary: true });
              }}
              onCancel={history.goBack}
            />
          )}
        />
        <RouteWithProps
          path={CARD_ROUTES.DELETE_CARD}
          render={({ match }) => (
            <Confirm
              isNegativeConfirm
              message={Copy.PROFILE_STATIC.DELETE_CARD_CONFIRM_MESSAGE}
              confirmText={Copy.PROFILE_STATIC.CONFIRM_BUTTON_TEXT}
              cancelText={Copy.PROFILE_STATIC.CANCEL_BUTTON_TEXT}
              onConfirm={async () => {
                await deleteCard(match.params.cardId);
              }}
              onCancel={history.goBack}
            />
          )}
        />
        <RouteWithProps style={style} component={CardList} />
      </Switch>
    </Route>
  );
};

export default Cards;
