import * as React from "react";
import csx from "classnames";
import ClampLines from "react-clamp-lines";
import { Button } from "../../../components/elementsThemed/Button";
import { Text } from "../../../components/elementsThemed/Text";
import { View } from "../../../components/elementsThemed/View";
import { useCell } from "../../../hooks";

import css from "./field.module.scss";

function Content({
  className,
  background,
  primaryText,
  secondaryText,
  label,
  value,
  button,
  disabled,
}) {
  const classes = csx(
    css.orderField,
    className,
    disabled ? css.disabled : undefined,
  );

  return (
    <View type={background} className={classes}>
      <div className={css["orderField-label"]}>
        <Text type={primaryText}>{label}</Text>
      </div>
      <div className={css["orderField-value"]}>
        <Text type={secondaryText}>
          <ClampLines
            id={value}
            buttons={false}
            text={value}
            lines={1.5}
            ellipsis="..."
          />
        </Text>
        {button && button}
      </div>
    </View>
  );
}

Content.defaultProps = {
  disabled: false,
};

interface FieldProps {
  type: string;
  label: string;
  value: string;
  className: string;
  disabled?: boolean;
  buttonProps: {
    children: React.ReactNode;
    onClick: () => void;
  };
  style?: {
    button: string;
    primary: string;
    background: string;
    secondary: string;
  };
}

function Field({
  type,
  label,
  value,
  disabled,
  buttonProps,
  style,
  className,
}: FieldProps) {
  if (type) {
    const { views: cellViews, labelTextStyles, buttons } = useCell(type);
    return (
      <Content
        {...{
          background: cellViews.background,
          button: buttonProps && (
            <Button type={buttons.primary} {...buttonProps} />
          ),
          className,
          disabled,
          label,
          primaryText: labelTextStyles.primary,
          secondaryText: labelTextStyles.secondary,
          value,
        }}
      />
    );
  }
  return (
    <Content
      {...{
        background: style.background,
        button: buttonProps && <Button type={style.button} {...buttonProps} />,
        className,
        label,
        primaryText: style.primary,
        secondaryText: style.secondary,
        value,
      }}
    />
  );
}

Field.defaultProps = {
  disabled: false,
  style: {},
};

export default Field;
