import * as React from "react";
import { config } from "utils";
import Type1 from "./Type1";
import Type2 from "./Type2";
import Type3 from "./Type3";
import Type4 from "./Type4";
import Type5 from "./Type5";

const Selector = (props) => {
  let Component = null;

  switch (config.theme.item_details.group) {
    case "Type1":
      Component = Type1;
      break;
    case "Type2":
      Component = Type2;
      break;
    case "Type3":
      Component = Type3;
      break;
    case "Type4":
      Component = Type4;
      break;
    case "Type5":
      Component = Type5;
      break;
    default:
      Component = Type1;
  }
  return <Component {...props} />;
};
export default Selector;
