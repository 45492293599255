import * as React from "react";
import { Formik, Form } from "formik";

import { PATRON_SEND_PINCODE, HANDLE_ERROR } from "utils/api";
import { Schemas, Copy } from "../../../utils";
import DrawerButton from "../../DrawerButton";
import { Field, Text, View } from "../../elementsThemed";
import { withTemplate } from "../../hocs";

import css from "../form.module.scss";

const { Input } = Field;

const PhoneForm = ({ onSuccess, token, style, phone, message }) => {
  const sendCode = async (values, actions) => {
    actions.setFieldError("phone", "");
    actions.setStatus("");
    try {
      await PATRON_SEND_PINCODE(values, { headers: { authorization: token } });
      if (onSuccess) onSuccess(values.phone);
      actions.resetForm();
    } catch (error) {
      const e = HANDLE_ERROR(error);
      actions.setStatus(e.data);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        phone,
      }}
      validationSchema={Schemas.PhoneSchema}
      initialStatus={message}
      onSubmit={sendCode}
      render={({ errors, values, status, setFieldValue, isSubmitting }) => (
        <View
          type={style.views.secondary}
          className={css.form}
          Component={Form}
        >
          <div className={css["fields-container"]}>
            <Input
              data-pl="phone-field"
              label={Copy.UPDATE_PHONE_STATIC.INPUT_LABEL}
              name="phone"
              type={style.inputs.standard}
              error={errors.phone}
              value={values.phone}
              placeholder={Copy.UPDATE_PHONE_STATIC.INPUT_PLACEHOLDER}
              onChange={(e) => setFieldValue("phone", e.target.value)}
              inputMode="tel"
            />

            <div>
              {status && <Text type={style.labels.error}>{status}</Text>}
            </div>
          </div>
          <DrawerButton
            buttonProps={{ htmlType: "submit", disabled: isSubmitting }}
          >
            {Copy.UPDATE_PHONE_STATIC.SEND_PIN_BUTTON_TEXT}
          </DrawerButton>
        </View>
      )}
    />
  );
};

export default withTemplate(PhoneForm, "phone");
