/**
 * Google Tag Manager Helpers
 * To read more about how this works: https://developers.google.com/tag-manager/devguide
 * @author Isaiah Sanchez <isanchez@lunchbox.io>
 */
import { constants } from "utils";

const { DEBUG_TOOLS } = process.env;

const {
  ANALYTICS: {
    CART_CLEARED,
    CART_VIEWED,
    CHECKOUT_STARTED,
    PRODUCT_ADDED,
    PRODUCT_MODIFIED,
    PRODUCT_REMOVED,
    PRODUCT_CLICKED,
    PURCHASE,
    UNNAMED_EVENT,
  },
} = constants;

const mapItemsForECommerce = (items = {}) =>
  items.map((i) => ({
    category: i?.group?.name,
    guid: i?.itemExternalId,
    id: i?.itemId,
    name: i?.name,
    price: (i?.price || 0).toFixed(2),
    quantity: i?.quantity || 1,
    restaurantGroup: i?.resGroupId,
  }));

const mapGTMPurchaseEvent = (payload) => ({
  ecommerce: {
    purchase: {
      actionField: {
        appliedCredit: payload?.appliedCredit,
        coupon: payload?.code,
        email: payload?.email,
        id: payload?.id,
        orderType: payload?.orderType,
        phoneNumber: payload?.phoneNumber,
        revenue: payload?.finalAmount,
        tax: payload?.taxAmount,
      },
      products: mapItemsForECommerce(payload?.items || {}),
    },
  },
  event: "transaction",
});

const gtmEvent = (eventType, payload) => {
  const dataLayer = window.dataLayer || [];

  dataLayer.push({
    ...(eventType ? { event: eventType } : {}),
    ...payload,
  });

  if (DEBUG_TOOLS) {
    console.info(
      "[lbx-tools]",
      `Google tag manager event was fired. (${eventType})`,
    );
  }

  return null;
};

export default (event, payload) => {
  switch (event) {
    case PURCHASE:
      return gtmEvent("", mapGTMPurchaseEvent(payload));
    case PRODUCT_ADDED:
      return gtmEvent("addToCart", payload);
    case CART_CLEARED:
    case CART_VIEWED:
    case CHECKOUT_STARTED:
      return gtmEvent("checkout", payload);
    case PRODUCT_MODIFIED:
    case PRODUCT_REMOVED:
    case PRODUCT_CLICKED:
      return gtmEvent("productClick", payload);
    case UNNAMED_EVENT:
    default:
      return gtmEvent(event, payload);
  }
};
