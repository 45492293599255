import * as React from "react";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import { Condition } from "components/elements";
import { config } from "utils";

const isUsingMapStyle = config.theme.location.map_style;
const mapOption = isUsingMapStyle
  ? { disableDefaultUI: true, styles: require("GeoConfig") }
  : {};

const { Condition: If } = Condition;

// Todo: fallback when undefined? Is there a default?
const destinationMarkerSrc = config?.images?.indicator_pin_delivery;
const originMarkerSrc = config?.images?.indicator_pin_location;
const driverMarkerSrc = config?.images?.indicator_pin_driver;

const TrackingMap = ({ destination, origin, driver, googleMapKey }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: googleMapKey,
  });

  if (loadError) return <div>Error loading</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <>
      <GoogleMap
        zoom={14}
        center={{ lat: origin?.latitude, lng: origin?.longitude }}
        options={mapOption}
      >
        <If is={destination}>
          <Marker
            position={{
              lat: destination?.latitude,
              lng: destination?.longitude,
            }}
            icon={{
              scaledSize: new window.google.maps.Size(31, 43),
              url: destinationMarkerSrc,
            }}
          />
        </If>
        <If is={origin}>
          <Marker
            position={{ lat: origin?.latitude, lng: origin?.longitude }}
            icon={{
              scaledSize: new window.google.maps.Size(31, 43),
              url: originMarkerSrc,
            }}
          />
        </If>
        <If is={driver}>
          <Marker
            position={{ lat: driver?.latitude, lng: driver?.longitude }}
            icon={{
              scaledSize: new window.google.maps.Size(31, 43),
              url: driverMarkerSrc,
            }}
          />
        </If>
      </GoogleMap>
    </>
  );
};

TrackingMap.defaultProps = {
  driver: {},
};

export default TrackingMap;
