import { useEffect, useState, useRef } from "react";
import { useOrderContext } from "components/providers/Order/OrderContext";
import { usePatronContext } from "components/providers/Patron/PatronContext";
import {
  getAddress,
  getRestaurants,
  mapLocations,
  setRestaurantGroupMediaToHttps,
  sortLocationByDistance,
  sortLocationByState,
} from "pages/Locations/utils";
import { FETCH_LOCATIONS } from "utils/api";
import { Schemas, config, constants } from "utils";

const {
  ORDER_TYPES: { DELIVERY },
} = constants;

const useLocationState = (diningOption) => {
  const orderContext = useOrderContext();
  const patronContext = usePatronContext();
  const { order } = orderContext;
  const {
    patron: { addresses = [] },
  } = patronContext;
  const deliveryFetchCount = useRef(0);
  const [fetching, setFetching] = useState(false);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const isMarketPlace = config?.market_place?.enabled;
  const [restaurants, setRestaurants] = useState([]);
  const isDelivery = diningOption === DELIVERY;

  const [locationState, setLocationState] = useState(() => {
    const address = getAddress(
      {
        ...order.address,
        lat: order.lat,
        long: order.long,
      },
      addresses,
    );
    return {
      address,
      addressComps: {},
      lat: isDelivery ? address.lat : order.lat,
      long: isDelivery ? address.long : order.long,
    };
  });

  /**
   * updates `locationState[streetType]` with `newValue`
   * @param{('street1'|'street2')} streetType The type of the street value to update
   * @param{string} newValue The new value of streetType
   */
  const handleStreetChange = (streetType, newValue) => {
    setLocationState({
      ...locationState,
      address: { ...locationState.address, [streetType]: newValue },
    });
  };

  const deliveryChange = (address) => {
    const newAddress = {
      city: address.city,
      id: address.id,
      lat: address.lat,
      long: address.long,
      state: address.state,
      street1: address.street1,
      zip: address.zip,
    };

    setLocationState({
      ...locationState,
      address: {
        ...address,
        ...newAddress,
      },
      lat: address.lat,
      long: address.long,
    });
  };

  useEffect(() => {
    if (order.address) {
      setLocationState({
        ...locationState,
        address: { ...order.address },
      });
    }
  }, [order.address]);

  const clearLocations = () => {
    deliveryFetchCount.current = 0;
    setLocations([]);
  };

  useEffect(() => {
    const getLocations = async () => {
      const { address, lat, long, addressComps } = locationState;
      setFetching(true);
      try {
        let body = {
          diningOption,
          lat: lat || undefined,
          long: long || undefined,
        };
        if (isDelivery) {
          body = { address, diningOption };
        }
        const { data = [] } = await FETCH_LOCATIONS(body);

        // mobile browsers won't allow geolocation unless all content is served from HTTPS. This fixes the error.
        data.forEach((obj) => {
          if (obj.restaurantGroups) {
            obj?.restaurantGroups.map((restaurantGroup) =>
              setRestaurantGroupMediaToHttps(restaurantGroup),
            );
          }
        });

        const mappedLocations = mapLocations(data);
        let sortedLocations = sortLocationByDistance(mappedLocations);

        if (
          Object.keys(addressComps).length &&
          !isDelivery &&
          addressComps.state &&
          !addressComps.zip
        ) {
          sortedLocations = sortLocationByState(
            sortedLocations,
            addressComps.state,
          );
        }

        if (isDelivery) {
          deliveryFetchCount.current += 1;
        }

        setLocations(sortedLocations);
        setFilteredLocations(sortedLocations);

        if (isMarketPlace) {
          setRestaurants(getRestaurants(sortedLocations));
        }
      } catch (error) {
        console.error(error);
        clearLocations();
      } finally {
        setFetching(false);
      }
    };

    const isValidDelivery =
      diningOption && Schemas.AddressSchema.isValidSync(locationState.address);
    if (isValidDelivery || !isDelivery) {
      getLocations();
    }
    if (!isDelivery) {
      deliveryFetchCount.current = 0;
    }
  }, [
    diningOption,
    locationState.address,
    locationState.addressComps,
    locationState.lat,
    locationState.long,
  ]);

  return {
    clearLocations,
    deliveryChange,
    deliveryFetchCount,
    fetching,
    filteredLocations,
    locations,
    locationState,
    restaurants,
    setFetching,
    setFilteredLocations,
    setLocations,
    setLocationState,
    handleStreetChange,
  };
};

export default useLocationState;
