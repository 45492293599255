import * as React from "react";
import classnames from "classnames";
import styles from "./fieldItem.module.scss";
import { Text } from "../Text";

function FieldItem({ type, label = undefined, className, children, htmlFor }) {
  const classes = classnames(styles.fieldItem, className);
  return (
    <div className={classes}>
      {label && (
        <label htmlFor={htmlFor}>
          <Text type={type}>{label}</Text>
        </label>
      )}
      {children}
    </div>
  );
}

export default FieldItem;
