import * as React from "react";
import { parseISO, format } from "date-fns";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import cx from "classnames";

import { config, Copy } from "utils";
import { Layout, Condition } from "components/elements";
import View from "../../../../components/elementsThemed/View/View";
import Text from "../../../../components/elementsThemed/Text/text";
import DrawerButton from "../../../../components/DrawerButton";
import withTemplate from "../../../../components/hocs/withTemplate";
import { TrackingMap } from "components/templates";
import { ORDER_TYPES } from "../../../../utils/constants";
import CartItem from "../ConfirmItem";

import css from "./complete.module.scss";
import commonCSS from "../../../index.module.scss";

import type { CompleteProps } from "../complete";

const If = Condition.Condition;

const { Flex } = Layout;

const googleMapKey =
  config.apps.google_maps || "AIzaSyDDp__7dinJ-_hqnj9iBjFD6acYRrY46I4";

const Complete = ({
  deliveryTime,
  eta,
  onSuccess,
  scheduleTime,
  style: { cells, labels, views },
  ticketInformation,
  trackingData,
}: CompleteProps) => {
  const isPickupOrder = ticketInformation.orderType === ORDER_TYPES.PICKUP;
  const isKioskOrder = ticketInformation.orderType === ORDER_TYPES.KIOSK;
  const isDeliveryOrder = ticketInformation.orderType === ORDER_TYPES.DELIVERY;

  const titleText = isPickupOrder
    ? Copy.CHECKOUT_STATIC.COMPLETE_TYPE4_PICKUP_FROM_TEXT
    : isKioskOrder
    ? Copy.CHECKOUT_STATIC.COMPLETE_DINING_AT_TEXT
    : Copy.CHECKOUT_STATIC.COMPLETE_TYPE4_DELIVERING_FROM_TEXT;
  const orderTypeText = isPickupOrder
    ? ticketInformation.location.name
    : ticketInformation.deliveryInfo;
  const orderType = isPickupOrder ? `Pickup` : `Delivery`;
  const prepTimeText = `Estimated ${isKioskOrder ? `Prep` : orderType} Time`;

  const estimateTime = isPickupOrder
    ? eta
    : trackingData?.estimatedDropoffTime
    ? format(parseISO(trackingData?.estimatedDropoffTime), "HH:mm")
    : eta;

  const info = ticketInformation.scheduledAt ? (
    <div className={css["complete-content-timeContainer"]}>
      <Col xs="1">
        <Text type={labels.tertiary}>
          {Copy.CHECKOUT_STATIC.COMPLETE_SCHEDULE_DATE_HEADER_TEXT}
        </Text>
      </Col>
      <Col xs="1">
        <Text type={labels.time}>{scheduleTime}</Text>
      </Col>
    </div>
  ) : (
    <div className={css["complete-content-timeContainer"]}>
      <Col xs="1">
        <Text type={labels.tertiary}>{prepTimeText}</Text>
      </Col>
      <Col xs="1">
        <Text type={labels.time}>{deliveryTime}</Text>
        {trackingData?.estimatedDropoffTime && (
          <Text type={labels.tertiary}>{estimateTime}</Text>
        )}
        <div className={css["complete-content-barCon"]}>
          <View
            type={views?.bar}
            role="progressbar"
            className={cx("bar", css["complete-content-bar"])}
            data-percent="70"
            aria-label="Order"
            aria-valuenow={40}
            aria-valuemin={0}
            aria-valuemax={100}
          />
        </div>
      </Col>
      <Col xs="1">
        <Text type={labels.secondary}>
          {Copy.CHECKOUT_STATIC.COMPLETE_TYPE4_ORDER_IN_THE_KITCHEN_MESSAGE}
        </Text>
      </Col>
    </div>
  );

  const AddressDetail = (
    <div className={css["complete-content-title"]}>
      <Text type={labels.tertiary}>{titleText}</Text>
      <Row>
        <Text type={labels.primary}>{orderTypeText}</Text>
      </Row>
    </div>
  );

  // "purchase-confirmation" is gtm Ecommerce-anchor, please do not remove
  return (
    <View
      type={views.background}
      grow={1}
      Component={Flex}
      className={`${commonCSS.container} ${css.complete}`}
    >
      <div className={cx(css["complete-content"], "purchase-confirmation")}>
        <View
          type={views.secondary}
          Component={Flex}
          grow={0}
          className={css["complete-content-information"]}
          direction="col"
        >
          {info}
          <View
            type={views.tertiary}
            className={css["complete-content-divider"]}
          />
          {isDeliveryOrder && (
            <>
              <Text type={labels.secondary}>
                {Copy.CHECKOUT_STATIC.COMPLETE_ORDER_SMS_INFO}
              </Text>
              <View
                type={views.tertiary}
                className={css["complete-content-divider"]}
              />
            </>
          )}
          {AddressDetail}
          <View
            type={views.tertiary}
            className={css["complete-content-divider"]}
          />
          <If is={trackingData}>
            <div className={css["complete-content-map"]}>
              <TrackingMap
                googleMapKey={googleMapKey}
                origin={trackingData?.restaurant}
                destination={trackingData?.patron}
              />
            </div>
          </If>
          <div className={css["complete-content-details"]}>
            <ul className={css["complete-content-items"]}>
              {ticketInformation.items.map((i) => (
                <CartItem key={`${i.id}`} type={cells.primary} {...i} />
              ))}
            </ul>
          </div>
        </View>
      </div>
      <div className={css["complete-footer"]}>
        <DrawerButton buttonProps={{ onClick: onSuccess }}>
          {Copy.CHECKOUT_STATIC.COMPLETE_BACK_TO_MENU_BUTTON_TEXT}
        </DrawerButton>
      </div>
    </View>
  );
};

const ThemedComplete = withTemplate(Complete, "confirmation");
ThemedComplete.displayName = "CompleteType4";

export default ThemedComplete;
