import * as Yup from "yup";

export const GIFT_CARD_NUMBER_REQUIRED = "Please enter a gift card number.";
export const GIFT_CARD_PIN_CODE_REQUIRED = "Please enter a gift card pin code.";

export const GiftCardWithPasswordSchema = Yup.object().shape({
  giftCardNumber: Yup.string().required(GIFT_CARD_NUMBER_REQUIRED),
  giftCardPassword: Yup.string().required(GIFT_CARD_PIN_CODE_REQUIRED),
});

export const GiftCardWithoutPasswordSchema = Yup.object().shape({
  giftCardNumber: Yup.string().required(GIFT_CARD_NUMBER_REQUIRED),
});
