export const ADD_TO_CART_BUTTON_TEXT = "Add To Cart";
export const FIND_NEAREST_LOCATION = "Find The Nearest Location";
export const ITEM_UNAVAILABLE = "Item Unavailable";
export const ITEM_WAS_ADDED_TEXT = "was added to your cart";
export const LOCATION_NOT_EXIST = "uh oh! This location doesn't exist";
export const MENU_UNAVAILABLE = "The menu is currently unavailable";
export const LOYALTY_MENU_BANNER_TEXT =
  "Earn X points for every $1 you spend at restaurantName. Limited to one reward per order.";
export const LOYALTY_UNIT = "points";
export const LOYALTY_HEADER_TEXT = "available to use";
export const LOYALTY_SEE_REWARDS_BUTTON_TEXT = "See your available rewards!";
export const LOYALTY_HAS_PROMO_WARNING =
  "You must remove your promo code in cart before you can add a reward item";
export const LOYALTY_MORN_THAN_ONE_ITEM_WARNING =
  " You already have a reward item in your cart. You can either modify your existing reward, or remove it and add a new one.";
export const LOYALTY_MODIFY_BUTTON_TEXT = "Modify";
export const LOYALTY_REMOVE_BUTTON_TEXT = "Remove";

export default {
  ADD_TO_CART_BUTTON_TEXT,
  FIND_NEAREST_LOCATION,
  ITEM_UNAVAILABLE,
  ITEM_WAS_ADDED_TEXT,
  LOCATION_NOT_EXIST,
  MENU_UNAVAILABLE,
  LOYALTY_MENU_BANNER_TEXT,
  LOYALTY_UNIT,
  LOYALTY_HEADER_TEXT,
  LOYALTY_SEE_REWARDS_BUTTON_TEXT,
  LOYALTY_HAS_PROMO_WARNING,
  LOYALTY_MORN_THAN_ONE_ITEM_WARNING,
  LOYALTY_MODIFY_BUTTON_TEXT,
  LOYALTY_REMOVE_BUTTON_TEXT,
};
