import { useState, useEffect } from "react";

const usePreloadImage = (src: string, preload: boolean) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded && preload) {
      let preloaderImg: HTMLImageElement | null = document.createElement("img");

      preloaderImg.src = src;

      preloaderImg.addEventListener("load", () => {
        preloaderImg = null;
        setIsLoaded(true);
      });
    }
  }, [preload]);

  return isLoaded;
};

export default usePreloadImage;
