import * as React from "react";
import { LinebreakText } from "components/elements";
import { config } from "utils";
import loadable from "@loadable/component";

const AsyncPage = loadable((props) => import(`./${props.page}`));

const HeaderSelector = (props) => {
  const { description } = props;
  const adjustedDescription = React.useMemo(
    () => <LinebreakText text={description} />,
    [description],
  );
  return (
    <AsyncPage
      page={config?.theme?.item_details?.header || "Type1"}
      description={adjustedDescription}
      {...props}
    />
  );
};

export default HeaderSelector;
