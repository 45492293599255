import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import {
  ModalProvider,
  OverlayContainer,
  useModal,
  useOverlay,
  // usePreventScroll,
} from "@react-aria/overlays";
import { useDialog } from "@react-aria/dialog";
import { FocusScope } from "@react-aria/focus";

import { useWindowSize } from "hooks";
import { determineBreakPoint } from "utils/helpers/other";

// Responsive drawer container
// Please refrain from customizing the drawer styles
const DrawerWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 2999;
  ${({ placement }) => placement}: 0;
  width: 100%;
  height: 100%;
  ${({ breakpoints }) => `max-width: ${breakpoints?.xs}}`}

  @media screen and (min-width: 48em) {
    ${({ breakpoints }) => `max-width: ${breakpoints?.sm}}`}
  }

  @media screen and (min-width: 60em) {
    ${({ breakpoints }) => `max-width: ${breakpoints?.md}}`}
  }

  @media screen and (min-width: 72em) {
    ${({ breakpoints }) => `max-width: ${breakpoints?.lg}}`}
  }

  @media screen and (min-width: 84em) {
    ${({ breakpoints }) => `max-width: ${breakpoints?.xl}}`}
  }

  @media screen and (min-width: 96em) {
    ${({ breakpoints }) => `max-width: ${breakpoints?.xxl}}`}
  }
`;

const Backdrop = (props) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 0.3 }}
    exit={{ opacity: 0 }}
    // We choose to use style prop to avoid style changes to the drawer
    style={{
      background: "var(--drawer-mask-background)",
      bottom: 0,
      left: 0,
      overflow: "hidden",
      position: "fixed",
      right: 0,
      top: 0,
      // TODO: zIndex values using css variables
      zIndex: 999,
    }}
    {...props}
  />
);

function AccessibleDrawer({
  maskable,
  maskClosable,
  close,
  breakpoints,
  placement,
  ariaLabel,
  children,
  ...props
}) {
  const ref = React.useRef();
  const { overlayProps, underlayProps } = useOverlay(props, ref);
  const { modalProps } = useModal();
  const { dialogProps } = useDialog(props, ref);

  // prevented BB mobile scrolling - see ENG-4331
  // usePreventScroll();

  return (
    <OverlayContainer>
      {maskable && (
        <Backdrop {...underlayProps} onClick={maskClosable && close} />
      )}
      <FocusScope contain restoreFocus autoFocus>
        <DrawerWrapper {...{ breakpoints, placement }}>
          <motion.div
            {...overlayProps}
            {...dialogProps}
            {...modalProps}
            ref={ref}
            aria-label={ariaLabel}
            transition="linear"
            initial={{
              translateX: placement === "right" ? "100%" : "-100%",
            }}
            animate={{ translateX: "0%" }}
            exit={{ translateX: placement === "right" ? "100%" : "-100%" }}
            // We choose to use style prop to avoid style changes to the drawer
            style={{
              background: "var(--drawer-background)",
              boxShadow: "var(--drawer-boxShadow)",
              overflowX: "hidden",
              width: "100%",
              height: "100%",
              outline: "none",
            }}
          >
            {children}
          </motion.div>
        </DrawerWrapper>
      </FocusScope>
    </OverlayContainer>
  );
}

/**
 * Depending on the step, returns an appropriate widget
 *
 * @deprecated Do not use the drawers in fragments. Use "components/Drawer.tsx".
 * @param {object} props
 * @param {JSX.Element} props.children - The contents of the drawer.
 * @param {boolean} props.destroyOnClose - Destroy the content of the drawer when its not visible,
 * @param {boolean} props.isOpen - control the open state of the drawer,
 * @param {boolean} props.maskable - render the page mask when its open,
 * @param {boolean} props.maskClosable - Toggle whether clicking on the mask closes the drawer
 * @param {Function} props.onChange - Callback to run when the open state of the drawer changes
 * @param {string} props.placement - Enum to render the draw on the right or left of the screen
 * @param {Function | JSX.Element} props.trigger - The react element or a function that is invoked with trigger class breakpoint and that will have onclick bound to it to toggle the drawer open state
 * @param props.breakpoints
 */
const Drawer = ({ breakpoints, ...props }) => {
  const {
    children,
    isOpen,
    maskable,
    maskClosable,
    onChange,
    placement,
    trigger,
    ariaLabel = "",
    drawerProps = {},
  } = props;
  const { width } = useWindowSize({ debounce: 200 });

  const { name: breakPoint } = determineBreakPoint(width);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(isOpen || false);
  const toggle = () => setIsDrawerOpen(!isDrawerOpen);
  const close = () => {
    setIsDrawerOpen(false);
    if (drawerProps.onClose) {
      drawerProps.onClose();
    }
  };

  React.useEffect(() => {
    setIsDrawerOpen(isOpen);
  }, [isOpen]);

  React.useEffect(() => {
    if (isDrawerOpen !== isOpen && onChange) {
      onChange(isDrawerOpen);
    }
    if (!isDrawerOpen) {
      const scrollY = document.body.style.top;
      document.body.style.overflow = "";
      document.body.style.touchAction = "";
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      // eslint-disable-next-line
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
    if (isDrawerOpen) {
      const currentTop = window.scrollY;
      const header = document.querySelector("#header");
      if (header) {
        header.style.top = 0;
      }
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none";
      document.body.style.position = "fixed";
      document.body.style.width = "100vw";
      document.body.style.top = `-${currentTop}px`;
    }
  }, [isDrawerOpen]);

  const render =
    typeof children === "function"
      ? React.Children.only(children({ close }))
      : children;
  let renderTrigger = null;

  if (trigger) {
    if (typeof trigger === "function") {
      renderTrigger = trigger({ breakPoint, triggerClass: "" });
    } else {
      renderTrigger = trigger;
    }
  }

  return (
    <AnimatePresence>
      {renderTrigger && React.cloneElement(renderTrigger, { onClick: toggle })}
      {isDrawerOpen && (
        <ModalProvider>
          <AccessibleDrawer
            {...{
              maskable,
              maskClosable,
              close,
              breakpoints,
              placement,
              ariaLabel,
            }}
          >
            {render}
          </AccessibleDrawer>
        </ModalProvider>
      )}
    </AnimatePresence>
  );
};

Drawer.propTypes = {
  breakpoints: PropTypes.shape({
    lg: PropTypes.string,
    md: PropTypes.string,
    sm: PropTypes.string,
    xl: PropTypes.string,
    xs: PropTypes.string,
    xxl: PropTypes.string,
  }),
  destroyOnClose: PropTypes.bool,
  isOpen: PropTypes.bool,
  maskable: PropTypes.bool,
  maskClosable: PropTypes.bool,
  placement: PropTypes.oneOf(["top", "right", "left", "right"]),
};
Drawer.defaultProps = {
  breakpoints: {
    lg: "50vw",
    md: "50vw",
    sm: "80vw",
    xl: "50vw",
    xs: "100vw",
    xxl: "40vw",
  },
  destroyOnClose: true,
  isOpen: false,
  maskable: true,
  maskClosable: true,
  placement: "right",
  drawerProps: {
    onClose: null,
  },
  children: "",
  onChange: null,
};

Drawer.displayName = "Drawer";

export { Drawer };
export default Drawer;
