import * as React from "react";
import { Condition as If } from "components/elements/Condition";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { View, Text, Radio } from "components/elementsThemed";
import { useCell } from "hooks";
import { config } from "utils";
import { formatPrice } from "utils/helpers/string";
import { QuantitySelector } from "components/fragments";
import { ReactComponent as Checkmark } from "./checkmark.svg";
import css from "./packingItemType1.module.scss";

const PackingItemType1 = ({
  isSingleSelectable,
  name,
  price,
  type,
  itemQuantity,
  onAddPackingItem,
  onRemovePackingItem,
}) => {
  const { views, labelTextStyles, buttons } = useCell(type);
  const configType = config.theme.packing_instructions.item;

  return (
    <View type={views.background} Component="li" className={css.packingItem}>
      <div className={css["packingItem-name"]}>
        <Text type={labelTextStyles.primary} aria-hidden={true}>
          {name}
        </Text>
      </div>
      <div className={css["packingItem-price"]}>
        {price ? (
          <Text
            type={labelTextStyles.secondary}
            className={css["packingItem-price-value"]}
            aria-hidden={true}
          >
            {formatPrice(price)}
          </Text>
        ) : null}
        <If is={isSingleSelectable}>
          <div
            className={css["packingItem-overlay"]}
            onClick={
              itemQuantity === 0 ? onAddPackingItem : onRemovePackingItem
            }
          />
          <If is={itemQuantity === 0 && configType === "Type1A"}>
            <div className={css["packingItem-checkmark"]}>
              <Checkmark stroke="black" display={onAddPackingItem} />
            </div>
          </If>
          <Radio
            type={buttons.primary}
            className={css["packingItem-radio"]}
            onChange={
              itemQuantity === 0 ? onAddPackingItem : onRemovePackingItem
            }
            value={itemQuantity > 0}
          >
            <VisuallyHidden>
              {name} {price ? formatPrice(price) : null}
            </VisuallyHidden>
          </Radio>
        </If>
        <If is={!isSingleSelectable}>
          <If is={itemQuantity === 0}>
            <div
              className={css["packingItem-overlay"]}
              onClick={onAddPackingItem}
            />
            <Radio
              type={buttons.primary}
              className={css["packingItem-radio"]}
              onChange={onAddPackingItem}
              value={false}
            >
              <VisuallyHidden>
                {name} {price ? formatPrice(price) : null}
              </VisuallyHidden>
            </Radio>
          </If>
          <If is={itemQuantity > 0}>
            <QuantitySelector
              viewType={views.secondary}
              buttonType={buttons.primary}
              labelType={labelTextStyles.primary}
              quantity={itemQuantity}
              inc={onAddPackingItem}
              dec={onRemovePackingItem}
              name={name}
            />
          </If>
        </If>
      </div>
    </View>
  );
};

export default PackingItemType1;
