import * as React from "react";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { useMenuContext } from "../../components/providers/Menu/MenuContext";
import { useTemplateContext as useTemplate } from "../../components/providers/Template";
import { useOrderContext } from "../../components/providers/Order/OrderContext";
import { Text, View, Button } from "../../components/elementsThemed";
import { Loader } from "../../components/fragments/Loader";
import { useCell } from "../../hooks";
import { config } from "../../utils/config";
import { MENU_STATIC } from "../../utils/Copy";
import BackButton from "../../components/BackButton/BackButton";
import Modal from "../../components/Dialog";
import { RewardItem } from "../../@types/item";
import css from "./loyalty.module.scss";

const regexpsContainProfile = new RegExp("^/profile");

interface RewardItemCardProps {
  name: string;
  points: string;
  image: string;
  isItemSelected: boolean;
  isItemUnavailable: boolean;
  onClick: () => void;
  handleRemove: () => void;
}

interface LoyaltyProps {
  onClose: () => void;
  handleRewardItemSelected: (
    rewardItem: RewardItem,
    isModifyingItem: boolean,
  ) => void;
}

const RewardItemCard = ({
  name,
  points,
  image,
  isItemSelected,
  handleRemove,
  isItemUnavailable,
  onClick,
}: RewardItemCardProps) => (
  <div className={css.rewardItem}>
    {isItemSelected && (
      <button className={css["rewardItem-remove"]} onClick={handleRemove}>
        <img src={config?.images?.icon_close} />
      </button>
    )}
    <div
      onClick={onClick}
      className={classNames(
        css["rewardItem-container"],
        isItemUnavailable && css["rewardItem-unavailable"],
      )}
    >
      <img className={css["rewardItem-image"]} src={image} />

      <div className={css["rewardItem-content"]}>
        <h3 className={css["rewardItem-name"]}>{name}</h3>
        <span className={css["rewardItem-points"]}>{`${points} pts`}</span>
      </div>
    </div>
  </div>
);

export const Loyalty = React.memo(
  ({ onClose, handleRewardItemSelected }: LoyaltyProps) => {
    const {
      theme: {
        elements: { cells },
      },
    } = useTemplate();
    if (!cells?.loyaltyMenu) {
      throw new Error("Missing accountDrawer Cell in Client Theme.");
    }
    const { labelTextStyles, buttons, views } = useCell("loyaltyMenu");

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const locationSelected =
      window?.sessionStorage?.getItem("locationSelected");
    const location = JSON.parse(locationSelected);

    const history = useHistory();
    const routerLocation = useLocation();
    const isProfilePage = regexpsContainProfile.test(routerLocation.pathname);

    const { rewardItems, clearRewardItems, order } = useOrderContext();
    const hasItemSelected = Boolean(rewardItems.length);
    const hasPromoCode = Boolean(order?.promotionCodeId);

    const { itemsHash, rewardsMenu } = useMenuContext();
    if (Object.keys(rewardsMenu).length === 0) {
      return <Loader />;
    }

    const { rewards, pointsBalance } = rewardsMenu;
    if (rewards.length === 0) return <div>no rewards</div>;

    const pointsBalanceInt = Math.floor(pointsBalance);
    const availableRewardsPoints =
      pointsBalanceInt - (hasItemSelected ? rewardItems[0].redeemAmount : 0);

    const onItemSelect = (rewardItem: RewardItem) => {
      if (hasPromoCode) {
        setIsModalOpen(true);
        return;
      }

      if (rewardItems.length === 0) {
        handleRewardItemSelected(rewardItem, false);
        return;
      }
      setIsModalOpen(true);
    };

    const backToMenu = () => {
      return onClose();
    };

    const handleModify = () => {
      setIsModalOpen(false);
      const selectedItem = rewardItems[0];
      handleRewardItemSelected(
        {
          redeemAmount: selectedItem.redeemAmount,
          rewardId: selectedItem.rewardId,
          ...itemsHash[selectedItem.item],
        },
        true,
      );
    };

    const handleRemove = () => {
      setIsModalOpen(false);
      clearRewardItems();
    };

    return (
      <div>
        {!isProfilePage && (
          <div className={css["loyalty-back"]}>
            <BackButton
              imgSrc={config?.images?.button_back_checkout}
              onClick={backToMenu}
            />
            <Text type={labelTextStyles.tertiary}>BACK</Text>
          </div>
        )}

        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          {hasPromoCode ? (
            <div className={css["loyalty-dialog"]}>
              <p>{MENU_STATIC.LOYALTY_HAS_PROMO_WARNING}</p>
              <div className={css["loyalty-dialog-actions"]}>
                <Button
                  onClick={() =>
                    history.push(
                      `/location/${location?.slug}/${order.diningOption}/`,
                    )
                  }
                  type={buttons.primary}
                >
                  Go To Menu
                </Button>
                <Button
                  onClick={() => setIsModalOpen(false)}
                  type={buttons.secondary}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <div className={css["loyalty-dialog"]}>
              <p>{MENU_STATIC.LOYALTY_MORN_THAN_ONE_ITEM_WARNING}</p>
              <div className={css["loyalty-dialog-actions"]}>
                <Button onClick={handleModify} type={buttons.primary}>
                  {MENU_STATIC.LOYALTY_MODIFY_BUTTON_TEXT}
                </Button>
                <Button onClick={handleRemove} type={buttons.secondary}>
                  {MENU_STATIC.LOYALTY_REMOVE_BUTTON_TEXT}
                </Button>
              </div>
            </div>
          )}
        </Modal>

        <View type={views.background} className={css["loyalty-banner"]}>
          <span>{MENU_STATIC.LOYALTY_MENU_BANNER_TEXT}</span>
        </View>
        <div className={css["loyalty-header"]}>
          <div className={css["loyalty-header-icon"]}>
            <img src={config?.images?.icon_loyalty_header} alt="rewards-logo" />
          </div>
          <div className={css["loyalty-header-text"]}>
            <Text type={labelTextStyles.primary}>
              {availableRewardsPoints} {MENU_STATIC.LOYALTY_UNIT}
            </Text>
            <Text type={labelTextStyles.tertiary}>
              {MENU_STATIC.LOYALTY_HEADER_TEXT}
            </Text>
          </div>
        </div>

        {Boolean(locationSelected) ? (
          rewards.map((reward) => {
            const { id, items, redeemAmount } = reward;
            const isAmountSufficient = Boolean(
              parseInt(redeemAmount) <= parseInt(pointsBalance),
            );

            if (items.length === 0) return null;

            return (
              <div id={id} key={id} className={css["loyalty-body-section"]}>
                <Text as="h2" type={labelTextStyles.secondary}>
                  {redeemAmount} POINTS
                </Text>
                <hr className={css.divider} />
                <div
                  className={classNames(
                    css["loyalty-body-section-items"],
                    !isAmountSufficient &&
                      css["loyalty-body-section-items-disabled"],
                  )}
                >
                  {items.map((item) => {
                    const itemInfo = itemsHash[item];
                    const isItemInfoAvailable = Boolean(itemInfo);
                    if (!isItemInfoAvailable) return null;

                    let isItemUnavailable = false;
                    let isItemSelected = false;

                    if (!hasItemSelected) {
                      isItemUnavailable = false;
                    } else {
                      isItemSelected = rewardItems[0]?.item === item;
                      isItemUnavailable =
                        !isAmountSufficient || !isItemSelected;
                    }

                    const { name, images } = itemInfo;
                    const imageSrc = images[0]
                      ? images[0].replace("http://", "https://")
                      : config?.images?.art_item_placeholder;

                    return (
                      <RewardItemCard
                        {...{
                          name,
                          isItemUnavailable,
                          isItemSelected,
                          image: imageSrc,
                          points: redeemAmount,
                          handleRemove,
                          rewardId: reward.id,
                          onClick: () => {
                            const formattedRewardItem = {
                              redeemAmount: redeemAmount,
                              rewardId: reward.id,
                              ...itemInfo,
                            };
                            return onItemSelect(formattedRewardItem);
                          },
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <Button
            className={css["loyalty-selectLocationButton"]}
            type={buttons.primary}
            onClick={() => history.push("/")}
          >
            Choose Location To See Rewards
          </Button>
        )}
      </div>
    );
  },
);
