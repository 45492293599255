import * as React from "react";
import classnames from "classnames";
import styles from "./button.module.scss";

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  block?: boolean;
  disabled?: boolean;
  // We use the type prop for other means (not ideal)
  // The button "type" is htmlType for now
  htmlType?: "submit" | "reset" | "button" | undefined;
  round?: boolean;
  size?: string;
}

const Button = React.forwardRef(
  ({
    type,
    htmlType = "button",
    ...props
  }: ButtonProps & {
    type?: string;
  }) => {
    const buttonClasses = classnames(
      styles.btn,
      type && styles[type],
      props.block ? styles.block : undefined,
      props.disabled ? styles.disabled : undefined,
      props.block ? styles.block : undefined,
      props.round ? styles.round : undefined,
      props.size ? styles[`btn-${props.size}`] : undefined,
      props.className,
    );

    const buttonProps = {
      ...props,
      className: buttonClasses,
    };

    // The button type should not be dynamic
    // <https://github.com/yannickcr/eslint-plugin-react/issues/1555>
    if (htmlType === "submit") {
      return <button type="submit" {...buttonProps} />;
    }

    return <button type="button" {...buttonProps} />;
  },
);

Button.defaultProps = {
  // Type is not used in this way - only to satisfy lint error
  type: "button",
};

export default Button;
