import * as React from "react";
import ClampLines from "react-clamp-lines";
import csx from "classnames";
import { View } from "../../View";
import { Text } from "../../Text";
import { useCell } from "../../../../hooks";
import { Button } from "../../Button";
import css from "./field.module.scss";

const Field = ({
  className,
  type,
  label,
  value,
  isClampLines = true,
  buttonProps,
}) => {
  const { views: cellViews, labelTextStyles, buttons } = useCell(type);
  return (
    <View type={cellViews.background} className={csx(css.field, className)}>
      <div className={css["field-label"]}>
        <Text type={labelTextStyles.primary}>{label}</Text>
      </div>
      <div className={css["field-value"]}>
        <Text type={labelTextStyles.secondary}>
          {isClampLines ? (
            <ClampLines
              id={value}
              buttons={false}
              text={value}
              lines={1.5}
              ellipsis="..."
            />
          ) : (
            value
          )}
        </Text>
        {buttons.primary && <Button type={buttons.primary} {...buttonProps} />}
      </div>
    </View>
  );
};

export default Field;
