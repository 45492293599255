import { constants } from "utils";

const { DEBUG_TOOLS } = process.env;

const {
  ANALYTICS: {
    BRAND_SELECTED,
    CLIENT_IDENTIFIED,
    LOCATION_SELECTED,
    SIGNED_IN,
  },
} = constants;

/**
 * TODO MOVE THIS TO UTIL HELPERS
 */
const toProperCase = (str = "") => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const fullstoryEvent = (eventType, payload) => {
  const fullstoryInit = typeof window !== "undefined" && !!window.FS;

  if (!fullstoryInit) {
    console.warn(
      "[lbx-tools]",
      `Fullstory event was fired but not initialized. (${eventType})`,
    );
    return null;
  }

  if (eventType === SIGNED_IN) {
    const displayName = toProperCase(
      `${payload?.firstName} ${payload?.lastName}`,
    );

    const userVars = window.FS.setUserVars({
      displayName,
      email: payload?.email,
    });

    window.FS.identify(payload?.userId, userVars);
  }

  if (eventType === CLIENT_IDENTIFIED) {
    const client = toProperCase(payload.client);

    window.FS.event("Client", {
      client_str: client,
    });
  }

  if (eventType === LOCATION_SELECTED) {
    const location = toProperCase(payload?.location);

    window.FS.event("Location", {
      location_str: location,
    });
  }

  // Only for marketplaces
  if (eventType === BRAND_SELECTED) {
    const brand = toProperCase(payload?.brand);

    window.FS.event("Brand", {
      brand_str: brand,
    });
  }

  if (DEBUG_TOOLS) {
    console.info("[lbx-tools]", `Full Story event was fired. (${eventType})`);
  }

  return null;
};

export default (event, payload) => {
  switch (event) {
    case SIGNED_IN:
      return fullstoryEvent(event, payload);
    case CLIENT_IDENTIFIED:
      return fullstoryEvent(event, payload);
    case LOCATION_SELECTED:
      return fullstoryEvent(event, payload);
    case BRAND_SELECTED:
      return fullstoryEvent(event, payload);
    default:
      return fullstoryEvent(event, payload);
  }
};
