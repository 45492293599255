import * as React from "react";
import Type1 from "./Type1";

const Selector = (props) => {
  const Component = Type1;

  return <Component {...props} />;
};

export default Selector;
