import * as React from "react";

import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { Elements, ElementsThemed, HOCs } from "components";
import { Copy } from "utils";
import { stringReplacer } from "utils/helpers/string";
import styles from "./prompt.module.scss";

const {
  Layout: { Flex },
} = Elements;
const { Text, Button, View } = ElementsThemed;
const { withTemplate } = HOCs;

const viewProps = {
  align: "center",
  className: styles.background,
  Component: Flex,
  grow: "1",
  justify: "center",
};

const Confirm = ({ name, onConfirm, onCancel, style }) => (
  <View type={style.views.background} {...viewProps}>
    <div className={styles.confirm}>
      <div className={styles.dialogue}>
        <Text type={style.labels.title}>
          {stringReplacer(
            Copy.CHECKOUT_STATIC.CONFIRM_REMOVE_ITEM_APPROVAL_MESSAGE,
            [{ replaceTarget: "{itemName}", replaceValue: name }],
          )}
        </Text>
      </div>
      <Col
        xs={{ offset: "1-12", span: "5-6" }}
        sm={{ offset: "1-8", span: "3-4" }}
      >
        <Row gutter={10}>
          <Col xs="1-2">
            <Button
              block
              className="remove-confirmation"
              size="md"
              type={style.buttons.confirm}
              onClick={onConfirm}
            >
              {Copy.CHECKOUT_STATIC.CONFIRM_REMOVE_ITEM_BUTTON_TEXT}
            </Button>
          </Col>
          <Col xs="1-2">
            <Button
              block
              size="md"
              type={style.buttons.cancel}
              onClick={onCancel}
            >
              {Copy.CHECKOUT_STATIC.CONFIRM_GO_TO_CART_BUTTON_TEXT}
            </Button>
          </Col>
        </Row>
      </Col>
    </div>
  </View>
);

export default withTemplate(Confirm, "confirm");
