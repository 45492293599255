import * as React from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { GET_ORDER_STATUS, PLACE_ORDER_POLLING } from "utils";
import { ORDER_TYPES } from "../../../utils/constants";
import { mapDeliveryInfo, mapOrder } from "../../../pages/Order/utils/helpers";
import { roundFloat } from "utils/helpers/math";
import { CheckoutContext } from "./CheckoutContext";
import { useOrderContext } from "../Order/OrderContext";

type CanMakePaymentResult = Record<string, boolean>;

type PlaceOrderArgs = {
  transactionId?: string;
};
interface CheckoutProviderProps {
  children: React.ReactNode;
  finalAmount?: number;
  onPaymentFailed?: (err: string) => void;
  placeOrder: ({ transactionId }: PlaceOrderArgs) => void;
  setAvailablePaymentTypes?: (req: CanMakePaymentResult) => void;
  setOrdering: (status: boolean) => void;
  tipAmount?: number;
  usePaymentRequest?: boolean;
}

function CheckoutProvider({
  children,
  finalAmount,
  onPaymentFailed,
  placeOrder,
  setAvailablePaymentTypes,
  setOrdering,
  tipAmount,
  usePaymentRequest,
}: CheckoutProviderProps) {
  const stripe = useStripe();
  const orderContext = useOrderContext();
  const [paymentRequest, setPaymentRequest] = React.useState(null);

  React.useEffect(() => {
    if (!usePaymentRequest || !stripe || paymentRequest) return;

    // For full documentation of the available paymentRequest options, see:
    // https://stripe.com/docs/stripe.js#the-payment-request-object
    const stripePaymentRequest = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        amount: roundFloat(finalAmount * 100),
        label: "Order total",
      },
      disableWallets: ['link']
      // Requesting the payer’s name, email, or phone is optional, but recommended.
      // It also results in collecting their billing address for Apple Pay.
    });
    // Check the availability of the Payment Request API.
    stripePaymentRequest.canMakePayment().then((result) => {
      /**
       * @todo Separate and check each mobile payment option individually, compare to stripe accepted payments
       */
      // Only set the paymentRequest and availablePaymentTypes if the order location has mobile pay enabled
      if (
        result &&
        (orderContext.location.isApplePayAccepted ||
          orderContext.location.isGooglePayAccepted)
      ) {
        setPaymentRequest(stripePaymentRequest);
        setAvailablePaymentTypes(result);
      }
    });
  }, [stripe, finalAmount]);
  // If the finalAmount changes, update the PaymentRequest and remount the paymentMethod
  /* TODO: paymentRequest.on adds event listeners which are not cleaned up. Causing multiple listeners being applied per useEffect run.
   ** We need to find a way to keep track of when the payement sheet is open to avoid trying to update the charge amount while its open
   */
  React.useEffect(() => {
    if (!usePaymentRequest || !stripe || !paymentRequest) return;
    // Only attempt to update paymentRequest when stripe paymentSheet is NOT open
    paymentRequest.update({
      total: {
        amount: roundFloat(finalAmount * 100),
        label: "Order total",
      },
    });
  }, [finalAmount]);

  React.useEffect(() => {
    if (!usePaymentRequest || !stripe || !paymentRequest) return;

    paymentRequest.off("paymentmethod");
  }, [finalAmount, placeOrder]);

  React.useEffect(() => {
    if (!usePaymentRequest || !stripe || !paymentRequest) return;
    const handlePaymentMethod = async (event) => {
      const placeOrderData = await PLACE_ORDER_POLLING({
        ...mapOrder(orderContext),
        cardNonce: event.paymentMethod.id,
        paymentMethod: event.walletName.toLowerCase(),
        idempotencyKey: orderContext?.order?.idempotencyKey,
        tip: tipAmount,
        giftCardNumber: orderContext?.order?.giftCard?.giftCardNumber || "",
        giftCardPinCode: orderContext?.order?.giftCard?.pinCode,
      });

      const hasOrderMessage = Boolean(placeOrderData?.data?.message);

      if (!hasOrderMessage) {
        // payment failed when there is no message
        event.complete("fail");
        onPaymentFailed("orderID not found");
        return;
      }

      if (hasOrderMessage && Boolean(placeOrderData?.data?.reason)) {
        // if order has message but has a reason, payment failed
        // we are not using isValid from response because success order is not retuning it, and it will always be false
        event.complete("fail");
        onPaymentFailed(placeOrderData?.data?.message);
        return;
      }

      let clientSecret;
      const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
      const getOrderStatus = async () => {
        try {
          const retries = 20; // amount of retries we're willing to do
          for (let i = 0; i < retries; i++) {
            try {
              const { data } = await GET_ORDER_STATUS(
                orderContext?.order?.idempotencyKey,
                { timeout: 50 },
              );
              if (data.statusCode === 200) {
                clientSecret = data.paymentInfo.clientSecret;
                return data;
              } else {
                await sleep(1000);
              }
            } catch (error) {
              console.error("cannot fetch order status");
              await sleep(1000);
            }
          }
        } catch (e) {
          console.error(e);
        }
      };
      const orderStatue = await getOrderStatus();
      const confirmData = await stripe.confirmCardPayment(clientSecret);
      const { paymentIntent, error: confirmError } = confirmData;

      if (confirmError) {
        event.complete("fail");
        onPaymentFailed("Client Secret Id is invalid.");
        return;
      }

      if (paymentIntent?.id && typeof placeOrder === "function") {
        placeOrder({
          transactionId: paymentIntent?.id,
        });
        event.complete("success");
        return;
      } else {
        onPaymentFailed("paymentIntent ID is invalid.");
      }
    };
    paymentRequest.on("paymentmethod", handlePaymentMethod);
  }, [stripe, paymentRequest, placeOrder]);

  const contextValues = React.useMemo(
    () => ({
      paymentRequest,
      usePaymentRequest,
    }),
    [paymentRequest],
  );

  return (
    <CheckoutContext.Provider value={contextValues}>
      {children}
    </CheckoutContext.Provider>
  );
}

CheckoutProvider.defaultProps = {
  finalAmount: 0,
  onPaymentFailed: () => null,
  setAvailablePaymentTypes: () => null,
  tipAmount: 0,
  usePaymentRequest: false,
};

export default CheckoutProvider;
