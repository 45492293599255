import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import csx from "classnames";
import { withTheme } from "styled-components";

import { Layout } from "components/elements";
import { usePatronContext } from "components/providers/Patron/PatronContext";
import DrawerButton from "../../../DrawerButton";
import { Loader } from "components/fragments";
import { withTemplate } from "components/hocs";
import { Text, View, Field } from "components/elementsThemed";
import { axios, Copy } from "utils";

import { useScript } from "hooks";
import styles from "./square.module.css";
import formStyle from "../../form.module.scss";

const { FieldItem } = Field;
const { Flex } = Layout;

const { handleError } = axios;

const applicationId = ["stage", "production"].includes(process.env.BUILD_ENV)
  ? "sq0idp-HFZAr1QIfIFtihBDVFRQUQ"
  : "sandbox-sq0idp-HFZAr1QIfIFtihBDVFRQUQ";

const ScriptHandler = (props) => {
  const { loaded, error } = useScript("https://js.squareup.com/v2/paymentform");

  if (!loaded) {
    return null;
  }

  if (error) {
    console.error("Unable to load Payment Form");
    return <></>;
  }

  return <SquareThemed {...props} />;
};

// Create and initialize a payment form object
const sqForm = ({ locationId, onLoad, onComplete, onInput, inputStyles }) =>
  new window.SqPaymentForm({
    applicationId,
    autoBuild: false,
    callbacks: {
      cardNonceResponseReceived: (errors, nonce, cardData) => {
        onComplete({
          cardData,
          errors,
          nonce,
        });
      },
      onInput: (inputEvent) => {
        switch (inputEvent.eventType) {
          case "focusClassAdded":
            onInput({
              payload: [],
              type: "error",
            });
            break;

          case "focusClassRemoved":
            break;

          case "errorClassAdded":
            onInput({
              payload: [
                Copy.PAYMENT_FORM_STATIC.SQUARE_CARD_INFORMATION_ERROR_MESSAGE,
              ],
              type: "error",
            });
            break;

          case "errorClassRemoved":
            onInput({
              payload: [],
              type: "error",
            });
            break;

          case "cardBrandChanged":
            break;

          case "postalCodeChanged":
            break;

          default:
        }
      },
      paymentFormLoaded: () => {
        onLoad();
      },
      unsupportedBrowserDetected: () => {
        return null;
      },
    },
    cardNumber: {
      elementId: "sq-card-number",
      placeholder: Copy.PAYMENT_FORM_STATIC.SQUARE_CARD_NUMBER_PLACEHOLDER,
    },
    cvv: {
      elementId: "sq-cvv",
      placeholder: Copy.PAYMENT_FORM_STATIC.SQUARE_CARD_CVV_NUMBER_PLACEHOLDER,
    },
    expirationDate: {
      elementId: "sq-expiration-date",
      placeholder:
        Copy.PAYMENT_FORM_STATIC.SQUARE_CARD_EXPIRATION_DATE_PLACEHOLDER,
    },
    inputClass: "sq-input",
    inputStyles: [inputStyles],
    locationId,
    postalCode: {
      elementId: "sq-postal-code",
      placeholder: Copy.PAYMENT_FORM_STATIC.SQUARE_CARD_ZIPCODE_PLACEHOLDER,
    },
  });

const loaderStyle = {
  overflow: "hidden",
  width: "100%",
};

const pluckErrors = (errors, field) =>
  errors
    .reduce((accu, i) => (i.field === field ? [...accu, i.message] : accu), [])
    .join("");

const PaymentForm = ({ style, onSuccess, theme, locationId, order }) => {
  const { cells, labels, views } = style;

  const [font, color] = labels.primary.split("_");

  const { updateCard } = usePatronContext();
  const [isSupported] = React.useState(
    window.SqPaymentForm.isSupportedBrowser() || false,
  );
  const [errors, setErrors] = React.useState([]);
  const [fetching, setFetching] = React.useState(true);
  const [saving, setSaving] = React.useState(false);

  const [squarePaymentForm] = React.useState(() =>
    sqForm({
      applicationId,
      inputStyles: {
        color: `${theme.colors[color]}`,
        fontFamily: "Helvetica",
        fontSize: `${theme.fonts[font].size}px`,
        lineHeight: `${theme.fonts[font].size}px`,
        placeholderColor: `${theme.colors.alternateGray}`,
      },
      onComplete: (data) => {
        if (data.errors) {
          setErrors(data.errors);
        } else {
          setErrors([]);
          // eslint-disable-next-line no-use-before-define
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          saveCard(data.nonce);
        }
      },
      onInput: (data) => {
        if (data.type === "error") {
          setErrors([data.payload]);
        }
      },
      onLoad: () => {
        setFetching(false);
      },
    }),
  );

  const requestCardNonce = (event) => {
    event.preventDefault();
    squarePaymentForm.requestCardNonce();
  };

  const saveCard = async (cardNonce) => {
    setSaving(true);
    try {
      const res = await axios.methods.post(
        "/patron/add-card",
        { cardNonce },
        locationId
          ? {
              headers: {
                locationId,
              },
            }
          : {},
      );
      updateCard(res.data);
      if (order.resetIdempotency) order.resetIdempotency();
      if (onSuccess) onSuccess();
    } catch (error) {
      console.log(error);
      setErrors([handleError(error).data]);
    } finally {
      setSaving(false);
    }
  };

  React.useEffect(() => {
    if (isSupported) {
      squarePaymentForm.build();
    }
    return () => {
      squarePaymentForm.destroy();
    };
  }, []);

  return isSupported ? (
    <View type={views.background} Component="form" className={formStyle.form}>
      {fetching && (
        <Flex
          grow="1"
          direction="col"
          align="center"
          justify="center"
          style={loaderStyle}
        >
          <Loader />
        </Flex>
      )}
      <div className={csx(formStyle["fields-container"], "payment-form")}>
        <div style={{ visibility: fetching ? "hidden" : "initial" }}>
          <Row spacing={15}>
            <Col>
              <FieldItem
                type={labels.primary}
                label={Copy.PAYMENT_FORM_STATIC.SQUARE_CARD_NUMBER_LABEL}
              >
                <div id="sq-card-number" className={styles["sq-card-number"]} />
                <hr className={styles.hr} />
                <Text type={labels.error}>
                  {pluckErrors(errors, "cardNumber")}
                </Text>
              </FieldItem>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col xs="1-3">
              <FieldItem
                type={labels.primary}
                label={
                  Copy.PAYMENT_FORM_STATIC.SQUARE_CARD_EXPIRATION_DATE_LABEL
                }
              >
                <div
                  id="sq-expiration-date"
                  className={styles["sq-expiration-date"]}
                />
                <hr className={styles.hr} />
                <Text type={labels.error}>
                  {pluckErrors(errors, "expirationDate")}
                </Text>
              </FieldItem>
            </Col>

            <Col xs="1-3">
              <FieldItem
                type={labels.primary}
                label={Copy.PAYMENT_FORM_STATIC.SQUARE_CARD_CVV_NUMBER_LABEL}
              >
                <div id="sq-cvv" className={styles["sq-cvv"]} />
                <hr className={styles.hr} />
                <Text type={labels.error}>{pluckErrors(errors, "cvv")}</Text>
              </FieldItem>
            </Col>
            <Col xs="1-3">
              <FieldItem
                type={labels.primary}
                label={Copy.PAYMENT_FORM_STATIC.SQUARE_CARD_ZIPCODE_LABEL}
              >
                <div id="sq-postal-code" className={styles["sq-postal-code"]} />
                <hr className={styles.hr} />
                <Text type={labels.error}>
                  {pluckErrors(errors, "postalCode")}
                </Text>
              </FieldItem>
            </Col>
          </Row>

          {errors
            .filter((i) => i.type === undefined)
            .map((i, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Text key={`${i}-${index}`} type={labels.error}>
                {i}
              </Text>
            ))}
        </div>
      </div>
      {!fetching && (
        <DrawerButton
          buttonProps={{ disabled: saving, onClick: requestCardNonce }}
        >
          {saving
            ? Copy.PAYMENT_FORM_STATIC.BUTTON_TEXT_SAVING
            : Copy.PAYMENT_FORM_STATIC.PAYMENT_FORM_SAVE_BUTTON_TEXT}
        </DrawerButton>
      )}
    </View>
  ) : (
    <Text type={labels.error}>
      {Copy.PAYMENT_FORM_STATIC.SQUARE_PAYMENT_FORM_UNSUPPORTED_BROWSER}
    </Text>
  );
};

const SquareThemed = withTheme(withTemplate(PaymentForm, "payment"));
export default ScriptHandler;
