import { parseView } from "./views";

const parseLabel = (value, theme) => ({
  color: theme.colors[value.textColor],
  "font-family": theme.fonts[value.font].name,
  "font-size": `${theme.fonts[value.font].size / 16}rem`,
  "font-weight": `${theme.fonts[value.font].weight || 400}`,
  "letter-spacing": theme.fonts[value.font].letterSpacing
    ? `${theme.fonts[value.font].letterSpacing / 16}rem`
    : "initial",
  "text-transform": value.isUppercase ? "uppercase" : "initial",
  view: value.view ? parseView(theme.elements.views[value.view], theme) : null,
});

export default (labels, theme) => {
  const parsedValues = Object.entries(labels).reduce((accu, [name, value]) => {
    const accuTemp = { ...accu };
    if (!value) return accuTemp;
    accuTemp[name] = parseLabel(value, theme);
    return accuTemp;
  }, {});

  return parsedValues;
};
