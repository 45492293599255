import {withTemplate, withTypeSelector} from "components/hocs";
import {config} from "utils";
import withTableNumber from "./withTableNumber";
import Type1 from "./Type1";

export default withTemplate(
  withTableNumber(
    withTypeSelector(
      {
        Type1,
      },
      config?.theme?.checkout?.table_number,
      Type1,
    ),
  ),
  "tableNumber",
);
