// UNUSED COMPONENT
import * as React from "react";
import { debounce } from "lodash";

import css from "./index.module.css";

interface ScrollableProps {
  Component: React.ElementType;
  className: string;
}

interface ScrollRef {
  scrollHeight: number;
  scrollTop: number;
  clientHeight: number;
  dataset: {
    hasScroll: number;
    scroll: number;
    atMax: number;
  };
  addEventListener: (string, Function, { passive }) => void;
  removeEventListener: (string, Function) => void;
}
//passive: boolean in addEventListener reads as object destructuring. Needs to be { passive }: { passive: boolean }
// temporarily commented out to run build

export function Scrollable({
  Component = "div",
  className,
  ...props
}: ScrollableProps) {
  const containerRef = React.useRef<ScrollRef>();

  React.useEffect(() => {
    const el = containerRef.current;
    if (!el)
      return function () {
        return null;
      };

    const storeScroll = debounce(() => {
      const { clientHeight, scrollHeight, scrollTop, dataset } = el;
      dataset.hasScroll = clientHeight < scrollHeight ? 1 : 0;
      dataset.scroll = scrollTop;
      dataset.atMax = scrollHeight - clientHeight === scrollTop ? 1 : 0;
    }, 100);

    el.addEventListener("scroll", storeScroll, { passive: true });
    window.addEventListener("resize", storeScroll, { passive: true });

    return function cleanup() {
      el.removeEventListener("scroll", storeScroll);
      window.removeEventListener("resize", storeScroll);
    };
  }, [containerRef.current]);

  return (
    <Component
      ref={containerRef}
      className={`${css.scrollable} ${className}`}
      {...props}
    />
  );
}

export default Scrollable;
