import { Card } from "./Card";
import { Diet } from "./Diet";
import Tabs from "./Tabs";
import Tabs2 from "./Tabs2";
import { Input } from "./Input";
import BottomSheet from "./BottomSheet";
import GoogleAnalytics from "./GoogleAnalytics";
import BeamImpact from "./BeamImpact";
// this rule disabling could be removed as long as we totally switch to named exports from default exports
// eslint-disable-next-line import/no-named-as-default
import Drawer from "./Drawer";
import { Loader } from "./Loader";
import { FooterButton } from "./FooterButton";
import { Collapsable } from "./Collapsable";
import { Toggle } from "./Toggle";
import Routes from "./Routes";
import Symbols from "./Symbols";
import RouterTabs from "./RouterTabs";
import { Image } from "./Image";
import Media from "./Media";
import { Empty } from "./Empty";
import Errors from "./Errors";
import Modal from "./Modal";
import Confirm from "./Confirm";
import Dropdown from "./Dropdown";
import Jsx from "./Jsx";
import Footer from "./Footer";
import { QuantitySelector } from "./QuantitySelector";
import { JSONToComponent } from "./JSONToComponent";
import Sift from "./Sift";
import AttentiveMobile from "./AttentiveMobile";
import ProgressBar from "./ProgressBar";
import { LazyImage } from "./LazyImage";
import { LocationModal } from "./LocationModal";
import Pill from "./Pill";

/**
 * @namespace Fragments
 */

export {
  AttentiveMobile,
  BeamImpact,
  BottomSheet,
  Card,
  Collapsable,
  Confirm,
  Diet,
  Drawer,
  Dropdown,
  Empty,
  Errors,
  Footer,
  FooterButton,
  GoogleAnalytics,
  Image,
  Input,
  JSONToComponent,
  Jsx,
  LazyImage,
  Loader,
  LocationModal,
  Media,
  Modal,
  Pill,
  ProgressBar,
  QuantitySelector,
  RouterTabs,
  Routes,
  Sift,
  Symbols,
  Tabs,
  Tabs2,
  Toggle,
};
export default {
  AttentiveMobile,
  BeamImpact,
  BottomSheet,
  Card,
  Collapsable,
  Confirm,
  Diet,
  Drawer,
  Dropdown,
  Empty,
  Errors,
  Footer,
  FooterButton,
  GoogleAnalytics,
  Image,
  Input,
  JSONToComponent,
  Jsx,
  LazyImage,
  Loader,
  LocationModal,
  Media,
  Modal,
  Pill,
  ProgressBar,
  QuantitySelector,
  RouterTabs,
  Routes,
  Sift,
  Symbols,
  Tabs,
  Tabs2,
  Toggle,
};
