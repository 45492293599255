/*
 * Checkout Footer Subtotals
 * Author: Isaiah Sanchez <isanchez@lunchbox.io>
 *
 * This component is rendered by pages/Checkout.
 * This component renders an order summary with prices and no buttons.
 */

import * as React from "react";
import { useId } from "@react-aria/utils";
import { Elements, ElementsThemed, Fragments } from "components";
import { constants, Copy, images } from "utils";
import { Condition } from "components/elements/Condition";
import { roundFloat } from "utils/helpers/math";
import { CustomTipping } from "pages/Checkout/components/custom-tipping/CustomTipping";
import {
  getFinalAmount,
  getFinalAppliedStoreValue,
} from "pages/Checkout/utils/helpers";
import PriceField from "./PriceField";
import { getAvailableTipByOrderType } from "../utils/helpers";

import defaultStyles from "../style.module.scss";

const {
  ORDER_TYPES: { DELIVERY },
} = constants;

const { Tooltip } = Elements;
const { Text, Button } = ElementsThemed;
const { Image } = Fragments;

const OrderSummary = ({
  theme,
  styles,
  resource,
  orderContext,
  toDiscount,
  clearDiscount,
  invalidDiscount,
  showDiscount = true,
  showTip = true,
  setTip,
  setShowCustomTipModal,
  tipAmount = 0,
  showTax = true,
  showTotal = true,
}) => {
  // Constants
  const { labels, buttons } = theme;
  const css = styles || defaultStyles;
  const elementId = useId();

  // Constants
  const {
    delivery = 0,
    appliedDiscounts = 0,
    taxAmount = 0,
    appliedCredit = 0,
    appliedStoredValue = 0,
    totalAmount = 0,
    preDiscountPrice = 0,
    serviceCharges = 0,
  } = resource;
  const isDelivery = orderContext.order.diningOption === DELIVERY;
  const { rewardItems } = orderContext;
  const hasRewardItems = rewardItems.length > 0;

  const serviceFeeInformation = isDelivery
    ? Copy.RG_INFO_STATIC.DELIVERY_INFORMATION_MESSAGE
    : Copy.RG_INFO_STATIC.PICKUP_INFORMATION_MESSAGE;

  const priceFieldName = isDelivery
    ? Copy.RG_INFO_STATIC.SERVICE_FEES_MESSAGE_DELIVERY
    : Copy.RG_INFO_STATIC.SERVICE_FEES_MESSAGE;

  let tipLabel = null;
  if (isDelivery && Copy.CART_STATIC.DELIVERY_TIP_HEADER_TEXT) {
    tipLabel = Copy.CART_STATIC.DELIVERY_TIP_HEADER_TEXT;
  } else if (Copy.CART_STATIC.PICKUP_TIP_HEADER_TEXT) {
    tipLabel = Copy.CART_STATIC.PICKUP_TIP_HEADER_TEXT;
  } else {
    tipLabel = Copy.CART_STATIC.TIP_HEADER_TEXT;
  }

  const giftCardBalance = orderContext?.order?.giftCard?.balance;
  const isGiftCardAdded = orderContext?.order?.giftCard?.giftCardNumber;

  const finalTip = Number.isNaN(parseFloat(tipAmount))
    ? 0
    : parseFloat(tipAmount);

  const finalAmount = getFinalAmount({
    appliedStoredValue,
    delivery,
    finalTip,
    giftCardBalance,
    totalAmount,
  });

  const finalAppliedStoreValue = getFinalAppliedStoreValue({
    appliedStoredValue,
    delivery,
    finalTip,
    giftCardBalance,
  });

  const tipsAvailableOptions = getAvailableTipByOrderType(
    orderContext?.order?.diningOption,
  );

  return (
    <div className={css["checkout-summary"]}>
      <PriceField
        type={labels.priceSubtotals}
        name="Subtotal"
        value={roundFloat(preDiscountPrice - appliedDiscounts)}
      />
      <Condition is={!!delivery}>
        <PriceField
          type={labels.priceSubtotals}
          name={priceFieldName}
          info={
            serviceFeeInformation && (
              <Tooltip
                ariaLabel="service fee information"
                ariaDescribedby={elementId}
                message={serviceFeeInformation}
                direction="right"
                className={css["checkout-summary--tooltip"]}
              >
                <Text
                  type={labels.disclaimer}
                  className={css["checkout-summary--information"]}
                >
                  ?
                </Text>
              </Tooltip>
            )
          }
          value={`${delivery}`}
        />
      </Condition>
      <Condition is={serviceCharges > 0}>
        <PriceField
          type={labels.priceSubtotals}
          name="Service Fee"
          value={serviceCharges}
        />
      </Condition>
      <Condition is={!hasRewardItems}>
        {orderContext.order.discount ? (
          <div className={css["checkout-summary--row"]}>
            <Text type={labels.priceSubtotals}>
              {Copy.CART_STATIC.PROMOS_DISCOUNTS_HEADER_TEXT}
            </Text>
            <span className={css["checkout-summary--discount"]}>
              <Text type={labels.discount}>
                {invalidDiscount
                  ? Copy.CART_STATIC.INVALID_PROMO_CODE
                  : `-$${appliedDiscounts.toFixed(2)}`}
              </Text>
              <Image
                src={images?.button_price_clear}
                className={css["checkout-summary--clear"]}
                onClick={clearDiscount}
                mediaName="button_price_clear"
                mediaType="svg"
                alt="close"
              />
            </span>
          </div>
        ) : (
          showDiscount && (
            <div className={css["checkout-summary--row"]}>
              <Text
                type={labels.priceSubtotals}
                className={css["checkout-summary--left"]}
              >
                {Copy.CART_STATIC.PROMOS_DISCOUNTS_HEADER_TEXT}
              </Text>
              <Button
                type={buttons.promo}
                onClick={toDiscount}
                className={css["checkout-summary--right"]}
              >
                {Copy.CART_STATIC.ADD_PROMO_CODE_BUTTON_TEXT}
              </Button>
            </div>
          )
        )}
      </Condition>

      <Condition is={appliedCredit > 0}>
        <PriceField
          type={labels.priceSubtotals}
          name="Loyalty Credit"
          value={appliedCredit}
          neg
        />
      </Condition>

      {showTax && (
        <PriceField type={labels.priceSubtotals} name="Tax" value={taxAmount} />
      )}

      <Condition is={appliedStoredValue >= 0 && isGiftCardAdded}>
        <PriceField
          type={labels.priceSubtotals}
          name="Gift Card"
          value={finalAppliedStoreValue}
          neg
        />
      </Condition>

      {showTip && (
        <CustomTipping
          tipsAvailableOptions={tipsAvailableOptions}
          tip={tipAmount}
          labels={labels}
          setShowCustomTipModal={setShowCustomTipModal}
          setTip={setTip}
          totalAmount={resource.preDiscountPrice}
          diningOption={orderContext?.order?.diningOption}
        />
      )}
      {showTotal && (
        <PriceField
          data-ga="total_price"
          type={labels.priceTotals}
          name="Total"
          value={finalAmount}
        />
      )}
    </div>
  );
};

export default OrderSummary;
