import * as React from "react";
import { ErrorBoundary } from "components/elements";
import { images } from "utils";
import { Empty } from "../../Empty";

const Error = ({ img, message, ...props }) => (
  <ErrorBoundary
    {...props}
    error={() => <Empty imgSrc={images?.art_error}>{message}</Empty>}
  />
);

export default Error;
