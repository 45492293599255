/* eslint-disable  no-unused-vars */
import * as React from "react";
import { PATRON_SEND_PINCODE } from "utils/api";
import VerifyCodeForm from "./verifyCodeForm";
import PhoneForm from "./phoneForm";

const UpdatePhone = ({
  onSuccess,
  phone: initPhone = "",
  message = "",
  token,
}) => {
  const [step, setStep] = React.useState(initPhone?.value ? 1 : 0);
  const [phone, setPhone] = React.useState(initPhone?.value || "");
  // eslint-disable-next-line no-unused-vars
  const [requesting, setRequesting] = React.useState(false);

  const onPhoneFormSuccess = (newPhone) => {
    setStep(1);
    setPhone(newPhone);
  };

  const onUpdatePhone = () => {
    setStep(2);
  };

  const onVerifyPhoneSuccess = (data) => {
    if (onSuccess) onSuccess({ phone: data.phone });
  };

  React.useEffect(() => {
    const sendPincode = async () => {
      setRequesting(true);
      try {
        await PATRON_SEND_PINCODE(
          { phone },
          { headers: { authorization: token } },
        );
      } finally {
        setRequesting(false);
      }
    };

    if (step === 1) {
      sendPincode();
    }
  }, []);

  switch (step) {
    case 1:
      return (
        <VerifyCodeForm
          onSuccess={onVerifyPhoneSuccess}
          onUpdatePhone={onUpdatePhone}
          phone={phone}
          message={message}
          token={token}
        />
      );
    default:
      return (
        <PhoneForm
          onSuccess={onPhoneFormSuccess}
          phone={phone}
          message={message}
          token={token}
        />
      );
  }
};

export default UpdatePhone;
