import * as React from "react";
import { getDisplayName } from "utils/helpers/other";

export interface WithCSSThemeProps {
  css: Record<string, string>;
}

export default function withCSSTheme<P>(
  Component: React.ComponentType<P>,
  theme: Record<string, string>,
): React.ComponentType<P & WithCSSThemeProps> {
  const withCSSThemeComponent = (props: P) => (
    <Component css={theme} {...(props as P)} />
  );

  withCSSThemeComponent.displayName = `withCSSTheme(${getDisplayName(
    Component,
  )})`;

  return withCSSThemeComponent;
}

export { withCSSTheme };
