import * as React from "react";
import { Row } from "@lunchboxinc/lunchbox-components";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { ConditionalWrapper } from "components/elements";
import { Text, View } from "components/elementsThemed";
import { useTemplateContext } from "components/providers/Template";
import _kebabCase from "lodash/kebabCase";
import { useCell } from "hooks";
import { config } from "utils";
import { ReactComponent as Arrow } from "./arrow.svg";
import css from "./option.module.scss";

const isTiled = (() => {
  switch (config.theme.item_details.modifier_items) {
    case "Type11":
      return true;
    default:
      return false;
  }
})();

function Option({ error, forwardRef, id, modifierItems, name, type }) {
  const {
    parsedTheme: { labels },
  } = useTemplateContext();

  const [isOpen, setIsOpen] = React.useState(false);
  const [contentHeight, setContentHeight] = React.useState(0);
  const drawer = React.useRef();
  const { views, labelTextStyles } = useCell(type);

  const headingId = `${_kebabCase(name).toLowerCase()}-options-heading`;

  const toggle = (e) => {
    e.preventDefault();
    const newStatus = !isOpen;
    setIsOpen(newStatus);
    setContentHeight(newStatus ? drawer.current.scrollHeight : 0);
  };

  const dataTestOpenAttribute = `modifier-option-${isOpen ? "open" : "closed"}`;
  const dataTestOpenModifiersAttribute = `modifier-items-${
    isOpen ? "open" : "closed"
  }`;

  return (
    <>
      <h3 id={headingId}>
        <View
          aria-expanded={isOpen ? "true" : "false"}
          type={views.background}
          className={css.option}
          onClick={toggle}
          onKeyPress={(e) => {
            if (e.key === " " || e.key === "Enter") {
              toggle(e);
            }
          }}
          role="button"
          tabIndex="0"
          data-id={id}
          data-cy={dataTestOpenAttribute}
        >
          <div className={css["option-left"]}>
            <Text
              type={labelTextStyles.secondary}
              className={css["option-name"]}
            >
              {name}
            </Text>
            <div aria-live="polite">
              {error && (
                <Text type={labelTextStyles.tertiary} ref={forwardRef}>
                  <VisuallyHidden>Error: </VisuallyHidden>
                  {error}
                </Text>
              )}
            </div>
          </div>
          <Arrow
            aria-hidden="true"
            className={`${css["option-arrow"]} ${
              isOpen
                ? css["option-arrow--active"]
                : css["option-arrow--inactive"]
            }`}
            fill={labels[labelTextStyles.secondary].color}
          />
        </View>
      </h3>
      <div
        aria-labelledby={headingId}
        data-id={id}
        data-cy={dataTestOpenModifiersAttribute}
        className={`${css["option-drawer"]} ${
          isOpen ? css["option-drawer--visible"] : css["option-drawer--hidden"]
        }`}
        role="region"
        style={{ height: contentHeight }}
        aria-hidden={isOpen ? "false" : "true"}
      >
        <ul
          style={{ listStyleType: "none", margin: "0", padding: "0" }}
          ref={drawer}
        >
          <ConditionalWrapper
            condition={isTiled}
            wrapper={(children) => (
              <Row flex spacing={16} gutter={0}>
                {children}
              </Row>
            )}
          >
            {modifierItems}
          </ConditionalWrapper>
        </ul>
      </div>
    </>
  );
}

Option.displayName = "OptionType6";

export default React.memo(Option);
