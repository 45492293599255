export const fullName = (firstName: string, lastName: string): string =>
  `${firstName} ${lastName}`;

export const addComma = (value: string): string =>
  `${value ? `${value},` : ""}`;

export const matchesRegex = (regexpArray: RegExp[], stringToTest: string) =>
  regexpArray.some((i) => i.test(stringToTest));

export const parseStringFloat = (number = 0, showSign = true) =>
  `${number < 0 ? "-" : ""}${showSign ? "$" : ""}${Math.abs(number).toFixed(
    2,
  )}`;

export const stringReplacer = (
  str = "",
  arr: { replaceTarget: string; replaceValue: string }[] = [],
) => {
  let newStr = str;

  arr.forEach((targetString) => {
    newStr = newStr.replace(
      targetString.replaceTarget,
      targetString.replaceValue,
    );
  });

  return newStr;
};

export const formatPhoneNumber = (phoneNumberString = "") => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (!match) return "";

  return `${match[1]}-${match[2]}-${match[3]}`;
};

export const formatPrice = (price: number | number[] = 0, showSign = true) =>
  Array.isArray(price)
    ? `${parseStringFloat(price[0], showSign)} - ${parseStringFloat(
        price[1],
        showSign,
      )}`
    : parseStringFloat(price, showSign);

export const formatCalories = ({ maxCalories = 0, minCalories = 0 }) => {
  if (minCalories === 0 && maxCalories === 0) {
    return null;
  }

  return minCalories === maxCalories
    ? `${minCalories} cal`
    : minCalories > maxCalories
    ? `${maxCalories} - ${minCalories} cal`
    : `${minCalories} - ${maxCalories} cal`;
};

export const truncateText = (text: string, length: number): string => {
  if (text.length > length) {
    return text.substring(0, length) + "...";
  }
  return text;
};

export const parseAndReplaceDigitInString = (i) => i.replace(/\d/g, "");
export const getValue = (e) => e.target.value.trim().replace(/。/g, ".");
