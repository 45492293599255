import _values from "lodash/values";
import { VALIDATE_ORDER_DISCOUNT_STATUSES } from "../../../utils/constants";
import { formatPrice } from "../../../utils/helpers/string";
import { isObjEmpty } from "../../../utils/helpers/objects";
import config from "../../../utils/config";
import Copy from "../../../utils/Copy";

const DISCOUNT_STATUSES = _values(VALIDATE_ORDER_DISCOUNT_STATUSES);
export const populateMods = (mods, itemsHash) =>
  mods
    .map((i) => ({
      ...i,
      items: i.modifiers.reduce((accu, { item }) => {
        const accuTemp = [...accu];
        if (itemsHash[item]) {
          const hasItemIndex = accuTemp.findIndex((x) => x.id === item);

          if (hasItemIndex !== -1) {
            accuTemp[hasItemIndex].quantity += 1;
          } else {
            accuTemp.push({ ...itemsHash[item], quantity: 1 });
          }
          return accuTemp;
        }
        return accuTemp;
      }, []),
    }))
    .filter((i) => i);

export const formatModifierPrice = (price, quantity = 1) => {
  let priceString = price ? `${formatPrice(price)}` : "";
  const sign = price && price < 0 ? "-" : "+";
  const quantityString = quantity > 1 ? ` x ${quantity}` : "";
  priceString = `${sign}${priceString}`;
  if (price) {
    return `(${priceString}${quantityString})`;
  }
  return quantityString;
};

/**
 *
 * @param {string} errorMessage
 * @param {string} errorReason
 * @returns {string} - Returns replaced error message. Same message if no conditions met
 */
export const changeErrorMessage = (errorMessage, errorReason) => {
  switch (errorReason) {
    // checking for kiosk error message
    case errorReason === "kiosk-closed":
      // Wings Over requested to have `Park & Order` instead of `dine in` in error message
      if (config.restaurant === "Wings Over") {
        errorMessage.replace("dine in", Copy.KIOSK_STATIC.ERROR_MESSAGE);
      }
      return errorMessage;
    default:
      return errorMessage;
  }
};

/**
 * Determines if the response or error from POST /v0/order/validate or /v0/order/check-price is a valid response for
 * or not to allow the user to proceed or show an error
 *
 * @param {object} params - resoruce and error keys from useResource hook
 * @param {object} params.resource - response payload from useResource hook for POST /order/validate/ or POST /order/check-price
 * @param {object} params.error - error from useResource hook for POST /order/validate/ or POST /order/check-price
 * @returns {boolean} - Returns true or false if the response matches known invalid states
 */
export const isResponseValid = ({ resource = {}, error = {} }) => {
  const isResourceEmpty = isObjEmpty(resource);
  const isErrorEmpty = isObjEmpty(error);

  if (isResourceEmpty) {
    return false;
  }
  if (!isErrorEmpty) {
    return false;
  }
  if (resource.isValid === false) {
    return false;
  }
  if (resource.reason) {
    return false;
  }
  return true;
};

/**
 * Determines if the response from validate order in the form that requires the UI to show an invalid promo,
 * allow the user to remove promo, and block them from proceeding to checkout
 *
 * @param {object} resource - response payload from useResource hook for POST /order/validate/ or POST /order/check-price
 * @returns {boolean} - whether the conditions that are met to show the user the their promo is not valid anymore
 */
export const isPromotionError = (resource = {}) => {
  if (DISCOUNT_STATUSES.includes(resource?.discount?.status)) {
    return true;
  }
  if (resource.discountStatus && resource.discountStatus !== "valid") {
    return true;
  }
  return false;
};

/**
 * get items array and generate [id]:items key value pair to show items of each restaurant
 * @param {array} items - response payload from useResource hook for POST /order/validate/ or POST /order/check-price
 * @returns {Map<string:object>} - whether the conditions that are met to show the user the their promo is not valid anymore
 */

export const generateItemMap = (items) => {
  let map = new Map();
  items.forEach((item, index) => {
    if (map.has(item.resGroupId)) {
      map.set(item.resGroupId, [
        ...map.get(item.resGroupId),
        { ...item, index },
      ]);
    } else {
      map.set(item.resGroupId, [{ ...item, index }]);
    }
  });
  return map;
};

export function getRedeemAmount(promotionId, walletInfo, bankInfo) {
  return walletInfo.reduce((acc, i) => {
    if (i.id === promotionId) {
      bankInfo.forEach((reward) => {
        if (reward.name === i.promotion.name) {
          return (acc += reward.redeemAmount);
        }
      });
    }
    return acc;
  }, 0);
}
