import * as React from "react";
import { config } from "utils";
import { Sticky } from "./Sticky";
import { Fixed } from "./Fixed";

const Selector = ({ type, page, ...props }) => {
  const footerLayout = config.theme?.[page]?.footer || "sticky";

  let Component = Sticky;

  switch (footerLayout) {
    case "fixed":
      Component = Fixed;
      break;
    case "sticky":
      Component = Sticky;
      break;
    case "hide":
      Component = null;
      break;
    default:
      Component = Sticky;
      break;
  }
  if (!Component) return null;
  return <Component type={type} {...props} />;
};

export default Selector;
