import * as React from "react";
import classnames from "classnames";
import { Row, Col } from "@lunchboxinc/lunchbox-components";

import { constants, config } from "utils";
import withTemplate from "../../../../components/hocs/withTemplate";
import View from "../../../../components/elementsThemed/View/View";
import PointsEarned from "../../components/PointsEarned";
import DrawerButton from "../../../../components/DrawerButton";
import Text from "../../../../components/elementsThemed/Text/text";

import commonCSS from "../../../index.module.scss";
import css from "./complete.module.scss";

import type { CompleteProps } from "../complete";

const {
  ORDER_TYPES: { PICKUP, DELIVERY, KIOSK },
} = constants;

function Complete({
  style,
  onSuccess,
  ticketInformation,
  date,
  time,
  scheduleTime,
  eta,
}: CompleteProps) {
  const { views, labels } = style;

  const text = ticketInformation.scheduledAt ? "scheduled" : "in the kitchen";
  const prepTimeText =
    ticketInformation.orderType === PICKUP
      ? "Estimated Prep Time: "
      : "Estimated Delivery Time:";

  let info = null;

  if (ticketInformation.scheduledAt) {
    info = (
      <>
        <Col xs="1">
          <Text type={labels.tertiary}>Schedule Date:</Text>
        </Col>
        <Col xs="1">
          <Text type={labels.tertiary}>{scheduleTime}</Text>
        </Col>
      </>
    );
  } else if (eta) {
    info = (
      <>
        <Col xs="1">
          <Text type={labels.tertiary}>{prepTimeText}</Text>
        </Col>
        <Col xs="1">
          <Text type={labels.tertiary}>{eta}</Text>
          {ticketInformation.scheduledAt && (
            <Text type={labels.tertiary}>{scheduleTime}</Text>
          )}
        </Col>
      </>
    );
  }

  let fromTitle = null;
  switch (ticketInformation.orderType) {
    case PICKUP:
      fromTitle = "Pickup From:";
      break;
    case DELIVERY:
      fromTitle = "Delivering From:";
      break;
    case KIOSK:
      fromTitle = "Dining At:";
      break;
    default:
      fromTitle = "";
      break;
  }

  return (
    // "purchase-confirmation" is gtm Ecommerce-anchor, please fo not remove
    <View type={views.background} className={commonCSS.container}>
      <div className={classnames(css.content, "purchase-confirmation")}>
        <View type={views.body}>
          <View type={views.title} className={css.title}>
            <Text type={labels.primary}>{`Your Order is ${text}`}</Text>
          </View>
          <div className={css.info}>
            <Row spacing={20} />
            <Row spacing={20}>
              <Col xs="1">
                <Text type={labels.secondary}>{fromTitle}</Text>
              </Col>
            </Row>
            <Row spacing={20}>
              <Col xs="1">
                <Text type={labels.tertiary}>
                  {ticketInformation.location.name}
                </Text>
              </Col>
            </Row>
            <hr className={css.divider} />
            <Row spacing={20}>
              <Col xs="1-3">
                <Text type={labels.tertiary}>{date}</Text>
              </Col>
              <Col xs="1-3">
                <Text type={labels.tertiary}>
                  Check #{ticketInformation.displayNumber}
                </Text>
              </Col>
              <Col xs="1-3">
                <Text type={labels.tertiary}>{time}</Text>
              </Col>
            </Row>
            <hr className={css.divider} />
            <Row spacing={20}>{info}</Row>
          </div>
        </View>
      </div>
      <div className={css.footer}>
        <DrawerButton buttonProps={{ onClick: onSuccess }}>
          Back to Menu
        </DrawerButton>
      </div>
    </View>
  );
}

export default withTemplate(Complete, "confirmation");
