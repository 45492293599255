import { Schemas } from "utils";
import { formatAddress } from "utils/helpers/geo";

const { AddressSchema } = Schemas;

export default ({
  city = "",
  lat = "",
  long = "",
  state = "",
  street1 = "",
  zip = "",
}) => {
  const toReturn = {
    city,
    isValid:
      street1 && city && state && zip
        ? AddressSchema.isValidSync({
            city,
            state,
            street1,
            zip,
          })
        : undefined,
    lat,
    long,
    placeId: "",
    prevPlaceId: "",
    state,
    street1,
    text: formatAddress({
      city,
      state,
      street1,
      zip,
    }).trim(),
    zip,
  };
  return toReturn;
};
