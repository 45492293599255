/* eslint-disable react/forbid-prop-types */
import * as React from "react";
import PropTypes from "prop-types";

import { config } from "utils";

import styles from "./icon.module.css";

function Icon({ symbol, src, style }) {
  return symbol &&
    config.theme.icons &&
    config.theme.icons.source === "symbols" ? (
    <svg className={styles.icon} style={style}>
      <use xlinkHref={`#${symbol}`} />
    </svg>
  ) : (
    <img className={styles.icon} style={style} src={src} alt="" />
  );
}

Icon.propTypes = {
  src: PropTypes.string.isRequired,
  symbol: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.object),
};

Icon.defaultProps = {
  symbol: "",
  style: {},
};

export { Icon };
export default Icon;
