export const CONFIRM_BUTTON_TEXT = "Confirm";
export const EMAIL_INPUT_LABEL = "Email";
export const EMAIL_INPUT_PLACEHOLDER = "Enter Email Here";
export const VERIFY_CODE_BUTTON_TEXT = "Confirm Code";
export const VERIFY_CODE_INPUT_LABEL = "Code";
export const VERIFY_CODE_INPUT_PLACEHOLDER = "PIN sent to email address";

export default {
  CONFIRM_BUTTON_TEXT,
  EMAIL_INPUT_LABEL,
  EMAIL_INPUT_PLACEHOLDER,
  VERIFY_CODE_BUTTON_TEXT,
  VERIFY_CODE_INPUT_LABEL,
  VERIFY_CODE_INPUT_PLACEHOLDER,
};
