import * as React from "react";
import { config, constants } from "utils";
import withRewardCard from "./withRewardCard";

import { RewardCardType1 } from "./Type1";

const {
  LOYALTY_SYSTEMS: { WALLET },
} = constants;

const Type1 = withRewardCard(RewardCardType1);

const walletLoyaltyIndex = config?.theme?.loyalty?.findIndex(
  (i) => i.system === WALLET,
);
const hasWalletLoyalty = walletLoyaltyIndex !== -1;

/**
 * @param props
 * @author Mohammad Afzal
 * @memberof Templates.Templates/Loyalty
 */
const RewardCardSelector = (props) => {
  if (!hasWalletLoyalty) {
    return null;
  }
  let Component = null;
  switch (config?.theme?.loyalty[walletLoyaltyIndex]?.card) {
    case "Type1":
      Component = Type1;
      break;
    default:
      Component = Type1;
  }
  return <Component {...props} />;
};

export { RewardCardSelector };
export default RewardCardSelector;
