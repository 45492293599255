import { useRef, useEffect } from "react";
import { Copy, axios, Routes } from "utils";
import { transformRequestError } from "utils/helpers/other";

// This is duplicated from pages/Checkout/utils in order to
// avoid importing that entire module into this one.
const checkOrderStatus = (idempotencyKey) =>
  axios.methods.get(Routes.CHECK_ORDER_STATUS(idempotencyKey));

// order status
const {
  CHECKOUT_STATIC: {
    ORDER_STATUS_IN_PROGRESS,
    ORDER_STATUS_COMPLETED,
    ORDER_STATUS_PROCESSING_PAYMENT,
    ORDER_STATUS_ORDER_PROCESSED,
  },
} = Copy;

const usePollingCheckout = (
  idempotencyKey,
  resetOrder,
  onSuccess,
  setOrdering,
  serverError,
  validationError,
) => {
  // keep track of the timer
  const intervalRef = useRef(null);
  const pollingCheckout = async () => {
    try {
      const result = await checkOrderStatus(idempotencyKey);
      const source = result.data;
      const { status, statusCode } = source;
      if (status === ORDER_STATUS_COMPLETED && statusCode === 200) {
        // send the user to the confirmation page
        resetOrder();
        onSuccess(source);
      } else if (
        status === ORDER_STATUS_IN_PROGRESS ||
        status === ORDER_STATUS_PROCESSING_PAYMENT ||
        status === ORDER_STATUS_ORDER_PROCESSED
      ) {
        // Try again in a second, if the Source is still `In progress, Processing Payment or Order processed`:
        const timerId = setTimeout(pollingCheckout, 1000);
        intervalRef.current = timerId;
      }
    } catch (error) {
      // handle errors
      console.error(error);
      const e = transformRequestError(error);
      if (e.message) {
        validationError(e);
      } else {
        serverError();
      }
      setOrdering(false);
    }
  };

  // clean up the timer
  useEffect(
    () => () => {
      if (intervalRef.current) {
        clearTimeout(intervalRef.current);
        intervalRef.current = null;
      }
    },
    [],
  );

  return { pollingCheckout };
};

export { usePollingCheckout };
