import {
  fromUnixTime,
  format,
  addMinutes,
  startOfDay,
  getUnixTime,
  startOfMinute,
  startOfWeek,
  differenceInHours,
  add as addToDate,
  sub as subtractFromDate,
} from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import {
  AM,
  PM,
  TIMEZONES,
  TIMEZONES_DST,
  DAYS_SHORT,
  ORDER_TYPES,
} from "utils/constants";
import { minZero } from "./math";

export const formatHour = (hour: string) => hour.trim().substring(0, 5);

export const toCivilianTime = (time: string): string => {
  if (typeof time !== "string" || time.length === 0) return "";
  let [hour, minute]: number[] = time.split(":").map((i) => parseInt(i, 10));

  if (hour >= 24) hour %= 24;

  if (minute >= 60) minute %= 60;

  const suffix = hour >= 12 ? PM : AM;
  return `${((hour + 11) % 12) + 1}:${minute
    .toString()
    .padStart(2, "0")} ${suffix}`;
};

export const toDayOfWeek = (number: number): string =>
  DAYS_SHORT[minZero(minZero(number) % 7)];

export const openHourStringToOpenHoursRange = (
  rangeString: string,
  currentTime: Date,
): [Date, Date] | "Closed" => {
  if (
    typeof rangeString !== "string" ||
    rangeString.length === 0 ||
    rangeString.toLowerCase() === "closed"
  ) {
    return "Closed";
  }

  // matches time x:xx or xx:xx
  const timeRegex = /^([0-2][\d]|[\d]):?[0-5]\d/;
  const meridianRegex = /(AM|PM)/;
  const timeStrings = rangeString.split(" ");
  const times = timeStrings.filter((str) => timeRegex.test(str));
  const [openMeridian, closeMeridian] = timeStrings.filter((str) =>
    meridianRegex.test(str),
  );

  // Define initial open and close hours
  const [openHour, closeHour] = times.map((x) => parseInt(x, 10));
  const openTime =
    openHour + (openMeridian === "AM" || openHour === 12 ? 0 : 12);
  let closeTime = closeHour;

  // During standard operating hours that go from AM to PM or PM to PM, match normally
  if (
    (openMeridian === "AM" && closeMeridian === "PM") ||
    (openMeridian === "PM" && closeMeridian === "PM")
  ) {
    closeTime += 12;
  }

  // Account for weird open/close times
  else if (
    // If we are AM to AM, we need to check if it's a late night open or not
    (openMeridian === "AM" && closeMeridian === "AM") ||
    // Also account for PM opening times that end in the next morning
    (openMeridian === "PM" && closeMeridian === "AM")
  ) {
    const closesAt12 = closeMeridian === "AM" && closeTime === 12;

    // If our close time is earlier than our open time, it's a safe bet we're open late
    if (openTime > closeTime) {
      // add 24 hours to the previously AM close time
      closeTime += 24;
    }
    // edge guard for 12AM
    else if (closesAt12) {
      closeTime = 24;
    }
  }

  // Return a time range of operating hours for in date values
  // Add to date sequentially just in case we add more than 24 hours
  return [
    addToDate(currentTime, { hours: openTime }),
    addToDate(currentTime, { hours: closeTime }),
  ];
};

// Two little helper utilities for calculating prev and next day
const getPrevDay = (dayNum: number): number => {
  let prev = (dayNum % 7) - 1;
  if (prev < 0) prev = 6;

  return prev;
};

const getNextDay = (dayNum: number): number => {
  let next = (dayNum % 7) + 1;
  if (next > 6) next = 0;

  return next;
};

export const getHoursUntilOpen = (
  obj: Record<string, Array<number>> = {},
  currentTime = new Date(),
): number | "Open" | "Closed" => {
  // Short circuit the calculation if the location is always closed
  if (obj.Closed && obj.Closed.length === 7) {
    return "Closed";
  }

  // Get the necessary variables to decide if we are currently open today, or if we are still open from a late night opening last night
  const startOfTheWeek = startOfWeek(currentTime);
  const currentDateNumber = currentTime.getDay();
  const previousDateNumber = getPrevDay(currentDateNumber);
  const currentDate = addToDate(startOfTheWeek, { days: currentDateNumber });

  // Calculate yesterday's date while wrapping to the previous week if necessary
  let yesterdaysDate: Date;
  if (previousDateNumber === 6) {
    yesterdaysDate = subtractFromDate(startOfTheWeek, { days: 1 });
  } else {
    yesterdaysDate = addToDate(startOfTheWeek, { days: previousDateNumber });
  }

  // Create a derived map of days of the week to order hours
  const initMap = Array.from(Array(7).keys()).reduce(
    (acc, num) => ({ ...acc, [num]: [] }),
    {},
  );
  const hoursByDay: Record<number, string> = Object.keys(obj).reduce(
    (acc: Record<number, string>, hourKey: string) => {
      const newResult = { ...acc };
      // eslint-disable-next-line no-restricted-syntax
      for (const dayNum of obj[hourKey]) {
        newResult[dayNum] = hourKey;
      }

      return newResult;
    },
    initMap,
  );

  // Calculate Range from yesterday and today
  const hoursFromYesterday = openHourStringToOpenHoursRange(
    hoursByDay[previousDateNumber],
    yesterdaysDate,
  );
  const hoursFromToday = openHourStringToOpenHoursRange(
    hoursByDay[currentDateNumber],
    currentDate,
  );

  // Check against yesterday's open and close hours. If they're still open from a late night opening,
  // We can return that the location is open without checking against current opening hours
  if (hoursFromYesterday !== "Closed") {
    const [openTime, closeTime] = hoursFromYesterday;

    if (
      currentTime.getTime() >= openTime.getTime() &&
      currentTime.getTime() < closeTime.getTime()
    ) {
      return "Open";
    }
  }

  // Check against today's current opening hours, and test if we're in range
  if (hoursFromToday !== "Closed") {
    const [openTime, closeTime] = hoursFromToday;

    // If we're within the time range, return Open
    if (
      currentTime.getTime() >= openTime.getTime() &&
      currentTime.getTime() < closeTime.getTime()
    ) {
      return "Open";
    }

    // If not, calculate the hours until next open from the start of the day to see if we haven't opened yet
    if (currentTime.getTime() < openTime.getTime()) {
      return differenceInHours(openTime, currentTime, {
        roundingMethod: "ceil",
      });
    }
  }

  // At this point we are certainly past close time, however, we need to find the next open day, and calculate against their next open hours
  // Create a list of the 6 next days to loop through
  const dayList = Array.from(Array(6).keys()).map((idx) =>
    getNextDay(idx + currentDateNumber),
  );

  // Find the next open day, and the number of days to add on to our calculation for the next open date,
  // In the case that the two will be different because the next open date is next week
  let nextOpenDay = dayList.find((day) => hoursByDay[day] !== "Closed");
  let daysToAddToStartOfWeek = nextOpenDay;

  // Adjust for the next open date being the current day but next week
  if (typeof nextOpenDay === "undefined") {
    nextOpenDay = currentDateNumber;
    daysToAddToStartOfWeek = currentDateNumber + 7;
  }

  // Get the next open range of time
  const nextOpenDate = addToDate(startOfTheWeek, {
    days: daysToAddToStartOfWeek,
  });
  const nextOpenRange = openHourStringToOpenHoursRange(
    hoursByDay[nextOpenDay],
    nextOpenDate,
  );

  // Again, just exhaust all possible code paths
  if (nextOpenRange === "Closed") {
    return "Closed";
  }

  // Finally, calculate the hours from the current time to the next open time
  const [nextOpenTime] = nextOpenRange;
  return differenceInHours(nextOpenTime, currentTime, {
    roundingMethod: "ceil",
  });
};

export const sortAndSplitHours = (obj: Record<string, Array<number>> = {}) => {
  const splitBySequence = (items = [], differences: number[] = []) =>
    items.reduce(
      (memo, item) => {
        const lastArray = memo[memo.length - 1];
        const lastItem = lastArray[lastArray.length - 1];

        if (!lastItem || differences.includes(item - lastItem)) {
          lastArray.push(item);
        } else {
          memo.push([item]);
        }

        return memo;
      },
      [[]],
    );

  const getFlankDays = (k = []) => {
    if (k.length > 1) {
      return [toDayOfWeek(k[0]), toDayOfWeek(k[k.length - 1])].join("-");
    }

    return toDayOfWeek(k[0]);
  };

  // We only want to calculate this once, only if we actually get hours information
  let hoursUntilOpen: number | "Open" | "Closed" = "Closed";
  if (Object.keys(obj).length) {
    hoursUntilOpen = getHoursUntilOpen(obj);
  }

  const x = Object.entries(obj)
    .reduce((accu: any, i: any) => {
      const daysInSequence = splitBySequence(i[1], [1, -6]);

      return [
        ...accu,
        ...daysInSequence.map((y) => ({
          days: y,
          hours: i[0],
          // Note, this value `hoursUntilOpen` is only true for Date.now()'s particular current
          // moment in time, however, the legacy structure of the data packing as it flows
          // into the components it uses needs access to it on every single chunked set of
          // processed hours data. If you're here wondering why every single `hoursUntilOpen`
          // value is the same for every time range, wonder no more.
          hoursUntilOpen,
          order: y[0] ? y[0] : 7,
          text: getFlankDays(y),
        })),
      ];
    }, [])
    .sort((a: { order: number }, b: { order: number }) => a.order - b.order);

  return x;
};

export const combineHours = (hours: any) => {
  const hoursObject = hours.reduce(
    (accu: any, i: any) => {
      if (!i.deliveryOpen && !i.deliveryClose) {
        accu.delivery.Closed = [...(accu.delivery.Closed || []), i.day];
      } else {
        const deliveryOpen = toCivilianTime(formatHour(i.deliveryOpen));
        const deliveryClose = toCivilianTime(formatHour(i.deliveryClose));

        if (accu.delivery[`${deliveryOpen} - ${deliveryClose}`]) {
          accu.delivery[`${deliveryOpen} - ${deliveryClose}`].push(i.day);
        } else {
          accu.delivery[`${deliveryOpen} - ${deliveryClose}`] = [i.day];
        }
      }

      if (!i.pickupOpen && !i.pickupClose) {
        accu.pickup.Closed = [...(accu.pickup.Closed || []), i.day];
      } else {
        const pickupOpen = toCivilianTime(formatHour(i.pickupOpen));
        const pickupClose = toCivilianTime(formatHour(i.pickupClose));

        if (accu.pickup[`${pickupOpen} - ${pickupClose}`]) {
          accu.pickup[`${pickupOpen} - ${pickupClose}`].push(i.day);
        } else {
          accu.pickup[`${pickupOpen} - ${pickupClose}`] = [i.day];
        }
      }

      if (!i.dineInOpen && !i.dineInClose) {
        accu.kiosk.Closed = [...(accu.kiosk.Closed || []), i.day];
      } else {
        const dineInOpen = toCivilianTime(formatHour(i.dineInOpen));
        const dineInClose = toCivilianTime(formatHour(i.dineInClose));

        if (accu.kiosk[`${dineInOpen} - ${dineInClose}`]) {
          accu.kiosk[`${dineInOpen} - ${dineInClose}`].push(i.day);
        } else {
          accu.kiosk[`${dineInOpen} - ${dineInClose}`] = [i.day];
        }
      }
      return accu;
    },
    {
      delivery: {},
      kiosk: {},
      pickup: {},
    },
  );

  return {
    delivery: sortAndSplitHours(hoursObject.delivery),
    kiosk: sortAndSplitHours(hoursObject.kiosk),
    pickup: sortAndSplitHours(hoursObject.pickup),
  };
};

export const combineValues = (
  { delivery = 0, prep = 0, pickup = 0 },
  orderType: OrderType,
) => {
  const safePickup = pickup;
  const safeDelivery = delivery;
  const safePrep = prep;

  const baseValue =
    orderType === ORDER_TYPES.DELIVERY ? safeDelivery : safePickup;

  return safePrep + baseValue;
};

export const formatTime = (min: number): string => {
  if (min === 0) {
    return "0 minutes";
  }

  const hours = min ? Math.floor(min / 60) : 0;
  const minutes = min ? min % 60 : 0;
  const values = [];

  if (hours) {
    values.push(`${hours} hour${hours > 1 ? "s" : ""}`);
  }

  if (minutes) {
    values.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
  }

  return values.join(" and ");
};

export const stdTimezoneOffset = (): number => {
  const baseDate = new Date();
  const jan = new Date(baseDate.getFullYear(), 0, 1);
  const jul = new Date(baseDate.getFullYear(), 6, 1);

  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

export const IS_DST_OBSERVED: boolean =
  new Date().getTimezoneOffset() < stdTimezoneOffset();

export const mapTimeZone = (
  timeZone: keyof typeof TIMEZONES,
  isDstObserved: boolean = IS_DST_OBSERVED,
): string => {
  const timeZoneMapping = isDstObserved ? TIMEZONES_DST : TIMEZONES;

  return timeZoneMapping[timeZone] || "";
};

export const displayScheduledAtTime = (i: number, timeZone: string): string => {
  const timeStart = fromUnixTime(i);
  const zoneTime = utcToZonedTime(timeStart, timeZone);

  return format(zoneTime, "h:mm a");
};

export const getHoursOverride = (currentHoursDisplay: string) => {
  if (currentHoursDisplay === "12:00 AM - 11:59 PM") {
    return "Open 24 hours";
  }
  if (currentHoursDisplay === "12:00 AM - 12:00 AM") {
    return "CLOSED";
  }
  return currentHoursDisplay;
};

type Hours = {
  days: Array<number>;
  hours: string;
  order: number;
  text: string;
};

export const displayedHoursOverride = (
  hours: Array<Hours>,
  isRemapped: boolean,
) => {
  if (isRemapped) {
    const remappedHours = hours.map((hour) => {
      const mappedHour = { ...hour };
      mappedHour.hours = getHoursOverride(mappedHour.hours);
      return mappedHour;
    });
    return remappedHours;
  }

  return hours;
};

export const formatDisplayHours = (open: string, close: string) =>
  `${toCivilianTime(open)} - ${toCivilianTime(close)}`;

export const displayScheduledAtTimeDelivery = (
  i: number,
  timeZone: string,
): string => {
  const timeStart = fromUnixTime(i);
  const timeEnd = addMinutes(timeStart, 15);

  return `${format(utcToZonedTime(timeStart, timeZone), "h:mm a")} - ${format(
    utcToZonedTime(timeEnd, timeZone),
    "h:mm a",
  )}`;
};

export const hoursToMs = (hours: number): number => hours * 60 * 60 * 1000;

// TODO: Automatically countdown from 24
export const hours = {
  24: hoursToMs(24),
};

export const initDate = (unixTimestamp: number, timeZone: string) => {
  const parsed = fromUnixTime(unixTimestamp);
  const toStartOfDay = startOfDay(parsed);
  const zoneTime = zonedTimeToUtc(toStartOfDay, timeZone);

  return getUnixTime(zoneTime);
};

export const setUnixTimestampDownToMinute = (unixTimestamp: number) => {
  const parsed = fromUnixTime(unixTimestamp);
  const toStartOfMinute = startOfMinute(parsed);

  return getUnixTime(toStartOfMinute);
};
