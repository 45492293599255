export const BACK_MAX_SELECT_ITEM = "You cannot select more than {option}";
export const BACK_MIN_SELECT_ITEM = "You must select at least {option}";
export const BACK_SELECTION_ERROR = "Oops! Please check your selections!";
export const GROUP_SELECTION_ERROR = "Oops! Please check your selections!";
export const GROUP_SELECTION_MAX_SELECT_ITEM =
  "You cannot select more than {groupMax}";
export const GROUP_SELECTION_MIN_SELECT_ITEM =
  "You must select at least {groupMin}";
export const GROUP_SELECTION_TXTAREA_LABEL = "Notes";
export const GROUP_SELECTION_TXTAREA_PLACEHOLDER =
  "Add additional information to this item";
export const GROUP_SELECTION_TXTAREA_DESCRIPTION =
  "Changes in this section that require price adjustments cannot be honored (i.e. protein add ons, cheese add ons)";
export const MAX_ITEM_SELECTED = "Maximum modifications for {option} selected";
export const NOTES_DESCRIPTION =
  "Changes in this section that require price adjustments cannot be honored (i.e. protein add ons, cheese add ons)";
export const NOTES_LABEL = "Notes";
export const NOTES_PLACEHOLDER =
  "Item notes (i.e. allergies, sauce on the side)...";
export const QUANTITY_TEXT_LABEL = "Quantity";
export const SELECT_TEXT = "Select one";
export const SELECTION_EMPTY_ERROR = "You must select an item";
export const SELECTION_ERROR = "Oops! Please check your selections!";
export const SELECTION_MAX_ERROR = "You cannot select more than {option}";
export const SELECTION_MIN_ERROR = "You must select at least {option}";
export const TYPE1_MAX_SELECT_ITEM = "You cannot select more than {option}";
export const TYPE1_MIN_SELECT_ITEM = "You must select at least {option}";
export const TYPE1_SELECTION_ERROR = "Oops! Please check your selections!";
export const TYPE2__ITEM_SELECT_ERROR_TEXT = "You must select an item";
export const TYPE2_ITEM_SELECT_TEXT = "Select one";
export const TYPE2_MAX_SELECT_ITEM = "You cannot select more than {option}";
export const TYPE2_MIN_SELECT_ITEM = "You must select at least {option}";
export const TYPE2_SELECTION_ERROR = "Oops! Please check your selections!";

export default {
  BACK_MAX_SELECT_ITEM,
  BACK_MIN_SELECT_ITEM,
  BACK_SELECTION_ERROR,
  GROUP_SELECTION_ERROR,
  GROUP_SELECTION_MAX_SELECT_ITEM,
  GROUP_SELECTION_MIN_SELECT_ITEM,
  GROUP_SELECTION_TXTAREA_LABEL,
  GROUP_SELECTION_TXTAREA_PLACEHOLDER,
  GROUP_SELECTION_TXTAREA_DESCRIPTION,
  MAX_ITEM_SELECTED,
  NOTES_DESCRIPTION,
  NOTES_LABEL,
  NOTES_PLACEHOLDER,
  QUANTITY_TEXT_LABEL,
  SELECT_TEXT,
  SELECTION_EMPTY_ERROR,
  SELECTION_ERROR,
  SELECTION_MAX_ERROR,
  SELECTION_MIN_ERROR,
  TYPE1_MAX_SELECT_ITEM,
  TYPE1_MIN_SELECT_ITEM,
  TYPE1_SELECTION_ERROR,
  TYPE2__ITEM_SELECT_ERROR_TEXT,
  TYPE2_ITEM_SELECT_TEXT,
  TYPE2_MAX_SELECT_ITEM,
  TYPE2_MIN_SELECT_ITEM,
  TYPE2_SELECTION_ERROR,
};
