import * as React from "react";

import csx from "classnames";
import { Elements, ElementsThemed, Fragments } from "components";
import { useResource, useCell } from "hooks";
import { Copy, config, images, Routes } from "utils";
import {
  cardFormat,
  CARD_ROUTES,
  GET_DELETE_CARD_ROUTE,
  GET_UPDATE_CARD_ROUTE,
} from "./utils";
import DrawerButton from "../../../components/DrawerButton";

import css from "./cards.module.scss";

const {
  Condition: { Condition },
  Layout: { Flex },
} = Elements;
const { Text, View, Button } = ElementsThemed;
const { Loader, Empty } = Fragments;

const CardList = ({ style: { cells, views, labels }, history }) => {
  const [{ resource = [], fetching, error }] = useResource({
    path: Routes.FETCH_CARDS,
  });
  const { views: cellViews, labelTextStyles, buttons } = useCell(cells.cards);

  if (Object.keys(error).length) {
    throw error;
  }

  if (fetching) {
    return <Loader />;
  }

  const toUpdateCard = (cardId) => () =>
    history.push(GET_UPDATE_CARD_ROUTE(cardId));
  const toDeleteCard = (cardId) => () =>
    history.push(GET_DELETE_CARD_ROUTE(cardId));
  const toAddCard = () => history.push(CARD_ROUTES.ADD_CARD);

  let render = null;
  if (!resource.length) {
    render = (
      <Empty type="card" imgSrc={images?.art_error}>
        <Text type={labels.error}>{Copy.PROFILE_STATIC.NO_CARDS}</Text>
      </Empty>
    );
  } else {
    render = resource.map((i) => {
      const { mappedLast4, mappedBrand } = cardFormat(
        i.brand,
        i.last4,
        i.expMonth,
        i.expYear,
      );
      return (
        <View
          key={i.id}
          type={cellViews.background}
          className={css["cardsOnFile-item"]}
          Component={Flex}
          direction="row"
          justify="between"
        >
          <Flex grow="1" justify="between" data-pl="cards-on-file">
            <Text type={labelTextStyles.primary} className={css.brand}>
              {mappedBrand}
            </Text>
            <Text type={labelTextStyles.secondary} className={css.digits}>
              {mappedLast4}
            </Text>
          </Flex>
          <Flex direction="row" grow="1" justify="end" align="center">
            <Condition is={!i.primaryCard}>
              <Button
                type={buttons.primary}
                onClick={toUpdateCard(i.id)}
                className={css.makePrimary}
              >
                {Copy.PROFILE_STATIC.MAKE_PRIMARY_BUTTON_TEXT}
              </Button>
            </Condition>
            <Condition is={i.primaryCard}>
              <Text type={labelTextStyles.tertiary}>
                {Copy.PROFILE_STATIC.PRIMARY_SELECTION_TEXT}
              </Text>
            </Condition>
            &nbsp;
            <Button type={buttons.primary} onClick={toDeleteCard(i.id)}>
              {Copy.PROFILE_STATIC.DELETE_CARD_BUTTON_TEXT}
            </Button>
          </Flex>
        </View>
      );
    });
  }

  const hasSelectedLocation =
    Object.entries(JSON.parse(localStorage.getItem("location"))).length > 0;
  return (
    <View
      type={views.secondary}
      Component={Flex}
      grow="1"
      className={css.cardsOnFile}
    >
      <Flex
        grow="1"
        className={csx(css["cardsOnFile-content"], "payment-form")}
      >
        {render}
      </Flex>
      <DrawerButton
        buttonProps={{
          disabled:
            !hasSelectedLocation &&
            config.payment_processor === "authorize.net",
          onClick: toAddCard,
        }}
      >
        {!hasSelectedLocation && config.payment_processor === "authorize.net"
          ? Copy.PROFILE_STATIC.CHOOSE_RESTAURANT_LOCATION_TO_ADD_CARD_TEXT
          : Copy.PROFILE_STATIC.ADD_CARD_BUTTON_TEXT}
      </DrawerButton>
    </View>
  );
};

export default CardList;
