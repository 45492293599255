import * as React from "react";
import { withErrorBoundary } from "components/hocs";
import Fallback from "./Fallback";

/**
 * Component for Beam CommunityImpact widget
 *
 * @author Htin Linn Aung
 * @memberof Fragments.Fragments/Beam
 * @param {object} props
 * @param {object} props.widgetColors - From BeamImpact component
 * @param {string} props.widgetId - Provided by Beam. It is in config
 * @returns {React.Element} - Component for Beam CommunityImpact widget
 */
function BeamCommunityImpact({ widgetColors, widgetId }) {
  const {
    explainerTextColor,
    defaultExplainerTextColor,
    impactProgressBarColor,
  } = widgetColors;
  const explainerColor = explainerTextColor || defaultExplainerTextColor;
  const [beamAppsLoading, setBeamAppsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!widgetId) {
      console.error("[Beam Impact] Widget ID is required.");
      return;
    }

    setBeamAppsLoading(true);
    const initializeWidget = async () => {
      // Need to add mechanism to handle duplicate instantiation

      const widget = new window.beamApps.CommunityImpactWidget({
        containerId: "beam-community-impact-widget-container",
        forceMobileView: "true",
        loadingScreenContent: "",
        themeConfig: {
          gradientColors: [explainerColor],
          id: "lunchbox-nonprofit",
          progressBarColors: [
            {
              color: impactProgressBarColor,
              offset: "100%",
            },
          ],
          textColor: explainerColor,
        },
        widgetId,
      });
      await widget.render();
    };

    if (window.beamApps) {
      setBeamAppsLoading(false);
      initializeWidget();
    }
  }, [beamAppsLoading, window.beamApps]);

  return <div id="beam-community-impact-widget-container" />;
}

export default withErrorBoundary(BeamCommunityImpact, Fallback);
