const hoursToMs = (hours) => hours * 60 * 60 * 1000;

// TODO: Automatically countdown from 24
const hours = {
  24: hoursToMs(24),
};

// To use: Time.hours[24], etc.
const Time = {
  hours,
};

export default Time;
