import * as React from "react";
import { useDrawers } from "../contexts/Drawers";
import { Loyalty } from "../pages/Loyalty/loyalty";
import { ItemDetails } from "../components/templates";
import { useOrderContext } from "../components/providers/Order/OrderContext";
import { useMenuContext } from "../components/providers/Menu/MenuContext";
import { Copy } from "../utils";

const getGroupIdByItemId = (itemId, groupArr) => {
  const group = groupArr.find((group) => {
    const ItemsIds = group.items.map((item) => item.id);
    return ItemsIds.includes(itemId);
  });
  return group?.id;
};

export const useRewardsMenuDrawer = ({
  closeDrawerOnAddingItem = false,
}: {
  closeDrawerOnAddingItem?: boolean;
}) => {
  const { toggleDrawer, isOpen, closeDrawer } = useDrawers();
  const { editRewardItemAtIndex, rewardItems } = useOrderContext();
  const { groups } = useMenuContext();

  const handleRewardItemSelected = (rewardItem, isModifyingItem) =>
    toggleDrawer({
      render: () => (
        <ItemDetails
          showClose
          item={rewardItem.id}
          isModifyingItem={isModifyingItem}
          mods={isModifyingItem ? rewardItems[0]?.state : {}}
          buttonText={Copy.MENU_STATIC.ADD_TO_CART_BUTTON_TEXT}
          isRewardItem={true}
          rewardItem={rewardItem}
          close={
            Boolean(closeDrawerOnAddingItem)
              ? closeDrawer
              : handleRewardMenuClick
          }
          onConfirm={(item) => {
            const groupId = getGroupIdByItemId(rewardItem.id, groups);
            editRewardItemAtIndex(
              {
                ...item,
                group: groupId,
                rewardId: rewardItem.rewardId,
                itemPrice: rewardItem.price,
              },
              0,
            );
          }}
        />
      ),
      hideHeader: true,
    });

  const handleRewardMenuClick = () =>
    toggleDrawer({
      render: () => (
        <Loyalty
          handleRewardItemSelected={handleRewardItemSelected}
          onClose={closeDrawer}
        />
      ),
      hideHeader: true,
    });

  return {
    handleRewardMenuClick,
    handleRewardItemSelected,
    closeDrawer,
    toggleDrawer,
  };
};
