import * as React from "react";
import { Field } from "components/elementsThemed";
import { Copy } from "utils";
import styles from "./search.module.scss";
import { useGoogleAutocompleContext } from "./googleAutoComplete";

const { Input } = Field;

function Search({ type, disabled, inputRef = React.useRef(null) }) {
  const { text, setVisible, setText } = useGoogleAutocompleContext();

  return (
    <Input
      type={type}
      className={[styles.input, "input-delivery"]}
      value={text}
      inputRef={inputRef}
      htmlType="text"
      placeholder={
        Copy.DELIVERY_FORM_STATIC.FORM_SEARCH_FORM_ADDRESS_PLACEHOLDER
      }
      label={Copy.DELIVERY_FORM_STATIC.FORM_SEARCH_FORM_ADDRESS_LABEL}
      disabled={disabled}
      onChange={({ target: { value } }) => {
        setText(value);
        setVisible(true);
      }}
      onFocus={() => {
        setVisible(true);
      }}
    />
  );
}

export default Search;
