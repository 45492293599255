import * as React from "react";
import { Portal } from "components/elements";
import { AnimatePresence, motion } from "framer-motion";
import { images } from "utils";
import { Empty } from "../Empty";
import styles from "./modal.module.css";

const Modal = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [content, setContent] = React.useState(null);

  React.useEffect(() => {
    const handler = (modalContent) => {
      setContent(modalContent.detail.message);
      setIsVisible(true);
    };
    document.addEventListener("alert", handler);
    return () => document.removeEventListener("alert", handler);
  }, []);

  return (
    <Portal className={isVisible ? styles.wrapper : ""}>
      <AnimatePresence>
        {isVisible && [
          <motion.div
            key="shade"
            className={styles.shade}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
          />,
          <motion.div
            key="modal"
            className={styles.modal}
            animate={{
              delay: 300,
              opacity: 1,
              transition: {
                default: {
                  duration: 300,
                },
                y: {
                  damping: 15,
                  stiffness: 1000,
                  type: "spring",
                },
              },
              y: 0,
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 150,
              },
              y: 50,
            }}
          >
            <div style={{ padding: "50px" }}>
              <Empty imgSrc={images?.art_empty}>{content}</Empty>
            </div>
          </motion.div>,
        ]}
      </AnimatePresence>
    </Portal>
  );
};

export default Modal;
