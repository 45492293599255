import { useTemplateContext } from "components/providers/Template";
import type { SegmentView, Cell } from "../@types/theme";

const useTemplate = (source: "cell" | "segmentView", type: string) => {
  const {
    theme: {
      elements: { cells, segmentViews },
    },
    parsedTheme: { views },
  } = useTemplateContext();

  switch (source) {
    case "cell": {
      const cellType = { ...cells[type] };
      if (cellType.views === null) {
        cellType.views = {};
      }
      return cellType;
    }
    case "segmentView": {
      const segment = segmentViews[type];
      const view = views[segment.view];
      return { segment, view };
    }
    default: {
      throw new Error("Please specify a source");
    }
  }
};

const useCell = (type: string) => useTemplate("cell", type) as Cell;
/** @deprecated */
const useSegment = (type: string) =>
  useTemplate("segmentView", type) as {
    segment: SegmentView;
    view: unknown;
  };

export { useCell, useSegment, useTemplate };
