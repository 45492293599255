export const CONFIRMATION_FOOTER = "Start My Order";
export const CONFIRMATION_HEADER = "Thanks for signing up!";
export const CONFIRMATION_SUBHEADER = "";
export const HEADER = "";
export const SUBHEADER = "";
export const TYPE1_ACCOUNT_VERIFICATION_MESSAGE =
  "Before we continue, you'll need to verify your email/phone. We've sent a pincode to the information entered.";
export const TYPE1_NO_EMAIL_MESSAGE =
  "You need to add an email to your account to continue.";
export const TYPE1_PASSWORD_UPDATE_NOTICE_MESSAGE =
  "You need to update your password to continue.";
export const TYPE1_UNVERIFIED_PHONE_MESSAGE =
  "You need to verify your phone to continue.";

export default {
  CONFIRMATION_FOOTER,
  CONFIRMATION_HEADER,
  CONFIRMATION_SUBHEADER,
  HEADER,
  SUBHEADER,
  TYPE1_ACCOUNT_VERIFICATION_MESSAGE,
  TYPE1_NO_EMAIL_MESSAGE,
  TYPE1_PASSWORD_UPDATE_NOTICE_MESSAGE,
  TYPE1_UNVERIFIED_PHONE_MESSAGE,
};
