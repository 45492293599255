import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { MemoryRouter, Route, Switch } from "react-router-dom";
import { Fragments, Templates } from "components";
import { Routes } from "utils";
import { FETCH_LOCATIONS, FETCH_PLACES } from "utils/api";
import { Nav, NavItem } from "./nav";
import styles from "./locations.module.scss";

const {
  Card: { CardBody },
  Loader,
} = Fragments;

const { DeliveryForm, PickupForm, LocationList } = Templates;

export default function Locations({
  orderContext: { order, orderContext },
  ...props
}) {
  const [diningOption, setOrderType] = React.useState(order.diningOption);
  const [fetching, setFetching] = React.useState(true);
  const [locations, setLocation] = React.useState([]);

  const handleClick = (i) => {
    props.history.goBack();
    orderContext.setDelivery(diningOption);
    orderContext.setLocation(i);
  };

  const getGeo = (geo) => {
    if (geo.lat !== order.lat || geo.long !== order.long) {
      orderContext.setGeo(geo);
    }
  };

  const getLocations = async () => {
    setFetching(true);
    try {
      const res = await FETCH_LOCATIONS({ ...order, diningOption });
      setFetching(false);
      setLocation(
        res.data.sort((a, b) => a.distanceInMiles - b.distanceInMiles),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getPickupGeo = async (placeId) => {
    try {
      const res = await FETCH_PLACES(placeId, { orderType: "pickup" });
      orderContext.setGeo(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (order.lat && order.long) {
      getLocations();
    } else {
      setLocation([]);
      setFetching(false);
    }
  }, [order.lat, order.long]);

  React.useEffect(() => {
    setLocation([]);
    setFetching(false);
  }, [diningOption]);

  return (
    <div className={styles.container}>
      <MemoryRouter
        initialEntries={[diningOption === 3 ? Routes.DELIVERY : Routes.PICK_UP]}
        initialIndex={0}
      >
        <Route
          render={({ location }) => (
            <>
              <Nav component={CardBody}>
                <NavItem
                  route={Routes.PICK_UP}
                  onChange={() => setOrderType(2)}
                >
                  Pickup
                </NavItem>
                <NavItem
                  route={Routes.DELIVERY}
                  onChange={() => setOrderType(3)}
                >
                  Delivery
                </NavItem>
              </Nav>
              <Switch location={location}>
                <Route
                  path={Routes.PICK_UP}
                  render={() => (
                    <>
                      <PickupForm onGeo={getGeo} onSelect={getPickupGeo} />
                      <Row>
                        <Col
                          xs={{ offset: "1-12", span: "5-6" }}
                          sm={{ offset: "1-8", span: "3-4" }}
                        >
                          {fetching ? (
                            <Loader />
                          ) : (
                            <LocationList
                              locations={locations}
                              onClick={handleClick}
                            />
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                />
                <Route
                  path={Routes.DELIVERY}
                  render={(routeProps) => (
                    <>
                      <DeliveryForm
                        {...routeProps}
                        address={order.address}
                        onSearch={orderContext.setDeliveryAddress}
                      />
                      <Row>
                        <Col
                          xs={{ offset: "1-12", span: "5-6" }}
                          sm={{ offset: "1-8", span: "3-4" }}
                        >
                          {fetching ? (
                            <Loader />
                          ) : (
                            <LocationList
                              locations={locations}
                              onClick={handleClick}
                            />
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                />
              </Switch>
            </>
          )}
        />
      </MemoryRouter>
    </div>
  );
}
