import * as React from "react";
import { config, images } from "utils";
import { Layout } from "components";
import { LoginSignup, LoyaltyInfo } from "components/templates";
import { Layout as LayoutElement, Logo } from "components/elements";
import { View } from "components/elementsThemed";
import { withTemplate } from "components/hocs";

import css from "./signup.module.scss";

const { Flex } = LayoutElement;

const signupHeader = images?.signup_header;

/**
 * Render a standalone sign up/in page where the user can either log in and be redirected to the profile page
 * or sign up and directed to place an order
 *
 * @param {object} props
 * @param {object} props.history - React router history object
 * @param {boolean} props.isLoggedIn - Is the patron logged in. used on the first render to redirect the user if theyre logged in
 * @param {object} props.style - Theme file value provided by withTemplate HOC
 * @param props.style.views
 * @param props.style.cells
 */
const SignupPage = ({
  isLoggedIn,
  history: browserHistory,
  style: { views, cells },
}) => {
  const [showLoyalty, setShowLoyalty] = React.useState(true);

  React.useEffect(() => {
    // Using the use effect here b/c if the user is new and signs up, they will be logged in afterwards.
    // This will only do something if the user is logged in and tries to come to this page the first time
    if (isLoggedIn) {
      browserHistory.replace("/profile");
    }
  }, []);

  return (
    <Layout>
      <View type={views.background} Component={Flex} className={css.content}>
        {signupHeader && (
          <div className={css.logo}>
            <Logo src={signupHeader} />
          </div>
        )}
        {showLoyalty && (
          <LoyaltyInfo.LoyaltyHeader
            type={cells.header}
            center={config?.theme?.login_signup?.header === "loyalty"}
          />
        )}
        <LoginSignup
          data-pl="signup-form"
          component={LoginSignup}
          routeToLocationPage
          onLoginComplete={() => browserHistory.replace("/profile")}
          lastLocation="/sign-up"
          onSignupComplete={() => setShowLoyalty(false)}
        />
      </View>
    </Layout>
  );
};

export default withTemplate(SignupPage, "signin");
