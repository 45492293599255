import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Text, View } from "components/elementsThemed";
import { Fragments } from "components";
import { Copy, Routes } from "utils";
import { useCell } from "hooks";
import { History } from "history";
import styles from "./menuDiscovery.module.scss";

const { Drawer } = Fragments;

// Types
interface MenuDiscoveryProps extends RouteComponentProps<any> {
  order: any;
  history: History;
}

interface Restaurant {
  name: string;
  media: {
    restaurantIcon: string;
  };
  // eslint-disable-next-line react/no-unused-prop-types
  id: string;
}

type RandomRestaurant = {
  name: string;
  cuisine: string;
  description: string;
  media: {
    restaurantBanner: string;
  };
};

const MenuDiscovery: React.FC<MenuDiscoveryProps> = ({ order, history }) => {
  // hooks
  const { labelTextStyles, views } = useCell("menuDiscovery");
  const [randomRestaurant, setRandomRestaurant] = React.useState<
    RandomRestaurant | undefined
  >(undefined);

  const [isDrawerOpened, setIsDrawerOpened] = React.useState(false);

  // variables
  const { slug, restaurantGroups } = order.location;
  const { diningOption } = order.order;

  // Display a random restaurant on each render
  const randomRestaurantGroup = () => {
    const randomIndex = Math.floor(Math.random() * restaurantGroups.length);
    const randomRG = restaurantGroups[randomIndex];
    setRandomRestaurant({
      cuisine: randomRG?.cuisine,
      description: randomRG?.description,
      media: {
        restaurantBanner: randomRG?.media?.restaurantBanner,
      },
      name: randomRG?.name,
    });
  };

  React.useEffect(() => {
    randomRestaurantGroup();
  }, []);

  // Open drawer

  const toggleDrawer = () => {
    setIsDrawerOpened(!isDrawerOpened);
  };

  return (
    <View type={views.background} className={styles.menuDiscovery}>
      <div className={styles["menuDiscovery-hero"]}>
        <Text
          type={labelTextStyles.primary}
          className={styles["menuDiscovery-hero--title"]}
        >
          {Copy.MARKETPLACE_STATIC.MENU_DISCOVERY_FEATURED_TITLE}
        </Text>

        <Text
          type={labelTextStyles.secondary}
          className={styles["menuDiscovery-hero--description"]}
        >
          {randomRestaurant?.description ||
            `${randomRestaurant?.name} has the winning combo of ${randomRestaurant?.cuisine} in a colorful space`}
        </Text>
      </div>

      <div className={styles["menuDiscovery-heroImage"]}>
        <img
          src={randomRestaurant?.media?.restaurantBanner}
          alt={randomRestaurant?.name}
        />
        <div className={styles["menuDiscovery-heroImage--textContainer"]}>
          <Text
            type={labelTextStyles.secondary}
            className={styles["menuDiscovery-heroImage--textContainer--RgName"]}
          >
            {randomRestaurant?.name}
          </Text>

          <Text
            type={labelTextStyles.tertiary}
            className={
              styles["menuDiscovery-heroImage--textContainer--cuisine"]
            }
          >
            {randomRestaurant?.cuisine}
          </Text>
        </div>
      </div>

      <div className={styles["menuDiscovery-explore"]}>
        <div className={styles["menuDiscovery-explore--textContainer"]}>
          <Text
            type={labelTextStyles.primary}
            className={styles["menuDiscovery-explore--textContainer--title"]}
          >
            {Copy.MARKETPLACE_STATIC.MENU_DISCOVERY_EXPLORE_TITLE}
          </Text>

          {/* // TODO Mo added button around text for better a11y. this button may need to be styled */}
          <button type="button" onClick={toggleDrawer}>
            <Text
              type={labelTextStyles.secondary}
              className={styles["menuDiscovery-explore--textContainer--seeAll"]}
            >
              {Copy.MARKETPLACE_STATIC.MENU_DISCOVERY_SEE_ALL}
              {restaurantGroups.length}
            </Text>
          </button>
        </div>

        <div className={styles["menuDiscovery-explore--RgListContainer"]}>
          <div
            className={
              styles[
                "menuDiscovery-explore--RgListContainer--singleRgContainer"
              ]
            }
          >
            {restaurantGroups.map(({ name, media, id }: Restaurant) => (
              <div
                className={
                  styles[
                    "menuDiscovery-explore--RgListContainer--singleRgContainer--singleRg"
                  ]
                }
                onClick={() =>
                  history.replace(
                    `${Routes.LOCATION_INFO({
                      orderType: diningOption,
                      resGroupId: id,
                      slug,
                    })}`,
                  )
                }
              >
                <img src={media?.restaurantIcon} alt={name} />
                <Text
                  type={labelTextStyles.tertiary}
                  className={
                    styles[
                      "menuDiscovery-explore--RgListContainer--singleRgContainer--singleRg--name"
                    ]
                  }
                >
                  {name}
                </Text>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Drawer.ResponsiveDrawer
        isOpen={isDrawerOpened}
        className={styles["menuDiscovery--drawer"]}
        trigger={null}
        drawerProps={null}
      >
        <div>Just a placeholder for the next ticket </div>
      </Drawer.ResponsiveDrawer>
    </View>
  );
};

export { MenuDiscovery };
