/*
 * Pill component
 * Author:Mbaye Faye
 * This component renders a Pickup or Delivery Time field.
 */

import * as React from "react";
import classNames from "classnames";
import { fromUnixTime, format, isToday } from "date-fns";
import { useCell } from "hooks";
import { Text, View } from "components/elementsThemed";
import { constants, images } from "utils";
import { displayScheduledAtTime } from "utils/helpers/time";
import { Image } from "../Image/image";
import Caret from "./Caret";
import styles from "./pill.module.scss";

const {
  ORDER_TYPES: { DELIVERY },
} = constants;

const Pill = ({
  className = "",
  diningOption,
  location,
  scheduleAt,
  streetName,
  toScheduleDate,
}) => {
  const { labelTextStyles, views } = useCell("pill");
  const isDelivery = diningOption === DELIVERY;

  const { timeZone, name } = location;

  const [locationName, setLocationName] = React.useState(name);

  React.useEffect(() => {
    if (localStorage.getItem("locationName")) {
      setLocationName(localStorage.getItem("locationName"));
    }
  }, []);

  // by default we show ASAP
  let diningOptionLabel = "ASAP";

  const dayAsJSDate = new Date(scheduleAt * 1000);
  const isDayToday = isToday(dayAsJSDate);

  /**
   * if schedule at is true , change label to the time that the user selects
   */
  if (scheduleAt) {
    const formattedDate = format(fromUnixTime(scheduleAt), "M/d");
    const formatDate = `${formattedDate} ${displayScheduledAtTime(
      scheduleAt,
      timeZone,
    )}`;

    const formatDateWithoutDay = `${displayScheduledAtTime(
      scheduleAt,
      timeZone,
    )}`;

    diningOptionLabel = formatDate;
    if (isDayToday) {
      diningOptionLabel = `Today ${formatDateWithoutDay}`;
    }
  }

  // change text based on the diningoption
  const displayText = () => {
    if (isDelivery) {
      return (
        <Text type={labelTextStyles.primary}>
          <Text type={labelTextStyles.primary}>{diningOptionLabel}</Text>
          <Text
            type={labelTextStyles.secondary}
          >{` ${diningOption} to  `}</Text>
          <Text type={labelTextStyles.primary}>{streetName}</Text>
        </Text>
      );
    }
    return (
      <Text>
        <Text type={labelTextStyles.primary}>{diningOptionLabel}</Text>
        <Text
          type={labelTextStyles.secondary}
        >{` ${diningOption} from  `}</Text>
        <Text type={labelTextStyles.primary}>{locationName}</Text>
      </Text>
    );
  };

  const pillClasses = classNames(styles["pillContainer"], className);

  return (
    <View
      type={views.background}
      className={pillClasses}
      onClick={toScheduleDate}
    >
      <View className={styles["pillContainer--pillDeliver"]}>
        {isDelivery ? (
          <Image src={images?.icon_pill_delivery} alt="car icon for delivery" />
        ) : (
          <Image
            src={images?.icon_pill_pickup}
            alt="walking man icon for pickup"
          />
        )}
      </View>
      {displayText()}
      <View className={styles["pillContainer--pillCaret"]}>
        <Caret />
      </View>
    </View>
  );
};

export default Pill;
