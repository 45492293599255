import * as React from "react";
import csx from "classnames";
import { useCell } from "../../../hooks/useTemplate";
import { View } from "../../elementsThemed/View";
import { Text } from "../../elementsThemed/Text";
import { images } from "../../../utils/Images";
import { Image } from "../../elements/Image";
import css from "./trackingProgress.module.scss";

const ProgressSegment = ({
  type,
  className,
  isComplete,
  title,
  description,
}) => {
  const { labelTextStyles, views } = useCell(type);
  return (
    <View
      type={views.background}
      className={csx(css["segment-container"], className)}
    >
      <Image
        className={css["segment-container--icon"]}
        src={
          isComplete
            ? images.icon_tracker_progress_complete
            : images.icon_tracker_progress
        }
      />
      <Text
        type={labelTextStyles.primary}
        className={css["segment-container--title"]}
      >
        {title}
      </Text>
      <Text
        type={labelTextStyles.secondary}
        className={css["segment-container--description"]}
      >
        {description}
      </Text>
    </View>
  );
};

export { ProgressSegment };
