export const ORDER_OUT_FOR_DELIVERY_DESCRIPTION = "Your order is on the way!";
export const ORDER_OUT_FOR_DELIVERY_TITLE = "OUT FOR DELIVERY";
export const ORDER_PICKED_UP_DESCRIPTION =
  "Your order is ready for the driver to pick up";
export const ORDER_PICKED_UP_TITLE = "ORDER PICKUP";
export const ORDER_RECEIVED_DESCRIPTION = "We have your order in our system";
export const ORDER_RECEIVED_TITLE = "ORDER RECEIVED";

export const DRIVER_INFO_SERVICE_LABEL = "Delivery By:";
export const DRIVER_INFO_DRIVER_LABEL = "Driver:";
export const DRIVER_INFO_DRIVER_CONTACT_LABEL = "Phone number:";

export default {
  DRIVER_INFO_DRIVER_CONTACT_LABEL,
  DRIVER_INFO_DRIVER_LABEL,
  DRIVER_INFO_SERVICE_LABEL,
  ORDER_OUT_FOR_DELIVERY_DESCRIPTION,
  ORDER_OUT_FOR_DELIVERY_TITLE,
  ORDER_PICKED_UP_DESCRIPTION,
  ORDER_PICKED_UP_TITLE,
  ORDER_RECEIVED_DESCRIPTION,
  ORDER_RECEIVED_TITLE,
};
