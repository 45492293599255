import * as React from "react";
import { usePackingContext } from "../../packingContext";
import PackingGroup from "../../Group";

const PackingMenuType1 = ({
  id,
  groupType,
  itemType,
  onUpdateSelectedItems,
  packingItems,
  setHeader,
}) => {
  const { getEntityById } = usePackingContext();
  const menu = getEntityById(id, "menu");

  React.useEffect(() => {
    if (setHeader) {
      setHeader(menu.name);
    }
  }, []);

  return (
    <>
      {menu.groups.map((i) => (
        <PackingGroup
          key={i.id}
          type={groupType}
          id={i.id}
          itemType={itemType}
          onUpdateSelectedItems={onUpdateSelectedItems}
          packingItems={packingItems}
        />
      ))}
    </>
  );
};

export default PackingMenuType1;
