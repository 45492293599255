import * as React from "react";
import { useId } from "@react-aria/utils";
import Styled from "styled-components";
import classnames from "classnames";
import withButton from "../withButton";
import { styleObjectToCSSString, radioSelectorGenerator } from "../utils";
import css from "./radio.module.css";

const Radio = Styled(
  ({ className, type, button, label, children, ...props }) => {
    const classes = classnames(css.checkbox, className);
    const uniqueID = useId(props.id);
    return (
      <span className={classes}>
        <input
          className={css.radio}
          type="checkbox"
          checked={!!props.value}
          {...props}
          id={uniqueID}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={css.label} htmlFor={uniqueID}>
          {children}
        </label>
        {label && <div className={css.label}>{label}</div>}
      </span>
    );
  },
)`
  ${({ button }) => {
    const CSSString = Object.entries(button?.button ?? {}).reduce(
      (accu, [key, value]) => {
        if (!Object.entries(value).length) return accu;
        const selector = `${radioSelectorGenerator(key, "input")} ~ label`;
        if (selector) {
          accu.push(styleObjectToCSSString(selector, value));
        }
        return accu;
      },
      [],
    );
    const t = CSSString.join("");
    return t;
  }}
`;

export default withButton(Radio);
