import * as React from "react";

const PackingContext = React.createContext();

export const PackingMenuProvider = ({
  menus = {},
  groups = {},
  items = {},
  options = {},
  packingItems = {
    array: [],
    hash: {},
  },
  errors = [],
  children,
}) => {
  const getEntityById = (id, entityType) => {
    let value = {};
    switch (entityType) {
      case "menu": {
        value = menus[id];
        break;
      }
      case "group": {
        value = groups[id];
        value.errors = errors.filter((i) => i.groupId === id);
        break;
      }
      case "item": {
        const isSelectedIndex = packingItems.hash[id];
        value = items[id];
        if (value) {
          value.isSelected = isSelectedIndex !== undefined;
          value.index = isSelectedIndex;
        } else {
          value = {};
        }
        break;
      }
      case "option": {
        value = options[id];
        break;
      }
      default:
    }
    return value;
  };
  const contextProps = {
    getEntityById,
    groups,
    items,
    menus,
    options,
  };
  return (
    <PackingContext.Provider value={contextProps}>
      {children}
    </PackingContext.Provider>
  );
};

const usePackingContext = () => {
  const contextValues = React.useContext(PackingContext);
  if (!contextValues) {
    throw new Error(
      "usePackingContext must be use within PackingContextProvider",
    );
  }
  return contextValues;
};

export { usePackingContext };
export default {
  Context: PackingContext,
  Provider: PackingMenuProvider,
};
