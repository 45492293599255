import * as React from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import styles from "../checkoutType2.module.scss";

const CheckoutForm = ({
  innerRef,
  setErrorMessage,
  setButtonDisabled,
  placeOrder,
  ...props
}) => {
  const stripe = useStripe();
  const elements = useElements();

  // Actions
  const handleChange = ({ error }) => {
    if (error) {
      setButtonDisabled(true);
      setErrorMessage(error.message);
    } else {
      setButtonDisabled(false);
      setErrorMessage(null);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    // const {error, paymentMethod} = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: cardElement,
    // });

    // Using Tokens Instead...
    // e.g. createToken - https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement
    const { token, error: tokenizeError } = await stripe.createToken(
      cardElement,
    );

    if (tokenizeError?.message) {
      setButtonDisabled(true);
      setErrorMessage(tokenizeError.message);
      return;
    }

    if (typeof placeOrder === "function") {
      await placeOrder({
        cardNonce: `${token.id}`,
      });
    }
  };

  // Render
  return (
    <form ref={innerRef} onSubmit={handleSubmit} {...props}>
      <div className={styles["checkout-stripeField"]}>
        <CardElement
          onChange={handleChange}
          // onFocus={handleFocus}
          options={{
            style: {
              base: {
                "::placeholder": {
                  color: "#aab7c4",
                },
                color: "#424770",
                fontFamily:
                  '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                fontSize: "16px",
                letterSpacing: "0.025em",
              },
              invalid: {
                color: "#c23d4b",
              },
            },
          }}
        />
      </div>
    </form>
  );
};

export default CheckoutForm;
