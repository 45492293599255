import overrideData from "ImagesConfig";
import { DefaultImages } from "./DefaultImages";

const usedDefaults = [];

export const images = Object.entries(DefaultImages).reduce((accu, [key]) => {
  if (overrideData[key] === undefined || overrideData[key] === null) {
    usedDefaults.push(key);
    return accu;
  }

  // Allow empty string to override the defaults
  return {
    ...accu,
    [key]: overrideData[key],
  };
}, DefaultImages);

export default images;
