import * as React from "react";
import { useTemplateContext } from "components/providers/Template";

const withButton =
  (Component) =>
  ({ ...props }) => {
    const {
      parsedTheme: { buttons },
    } = useTemplateContext();
    const buttonType = buttons[props.type];
    return <Component {...props} button={buttonType} />;
  };

export default withButton;
