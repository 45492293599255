import uuidv4 from "uuid/v4";

const setDeliveryAddress = (dispatch) => (address) => {
  dispatch({
    payload: {
      address,
      lat: address.lat,
      long: address.long,
    },
    type: "CHANGE_ADDRESS",
  });
};
const setOrderType = (dispatch) => (diningOption) => {
  dispatch({
    payload: {
      diningOption,
    },
    type: "CHANGE_DINING_OPTIONS",
  });
};
const setScheduledAt = (dispatch) => (scheduledAt) => {
  dispatch({
    payload: {
      scheduledAt,
    },
    type: "UPDATE_DETAILS",
  });
};
const setTableNumber = (dispatch) => (data) => {
  dispatch({
    payload: {
      table: data,
    },
    type: "UPDATE_DETAILS",
  });
};
const setDiscount = (dispatch) => (discount) => {
  dispatch({
    payload: discount,
    type: "UPDATE_DETAILS",
  });
};

const setDeliveryNotes = (dispatch) => (note) => {
  dispatch({
    payload: {
      deliveryNotes: note,
    },
    type: "UPDATE_DETAILS",
  });
};

const resetIdempotency = (dispatch) => () => {
  dispatch({
    payload: {
      idempotencyKey: uuidv4(),
    },
    type: "UPDATE_IDEMPOTENCY",
  });
};

export default (dispatch) => [
  setDeliveryAddress(dispatch),
  setDeliveryNotes(dispatch),
  setOrderType(dispatch),
  setScheduledAt(dispatch),
  setTableNumber(dispatch),
  setDiscount(dispatch),
  resetIdempotency(dispatch),
];
