import * as React from "react";
import { config } from "utils";
import loadable from "@loadable/component";

const AsyncPage = loadable((props) => import(`./${props.page}`));

const UpsellLayoutSelector = (props) => {
  return (
    <AsyncPage
      page={config?.theme?.checkout?.upsell_layout || "Type1"}
      {...props}
    />
  );
};

export default UpsellLayoutSelector;
