import * as Yup from "yup";
import { phoneRegExp } from "utils/helpers/regexps";

const SignUpSocialSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is Required"),
  firstName: Yup.string().required("First Name is Required"),
  lastName: Yup.string().required("Last Name is Required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone is Required"),
});

export default SignUpSocialSchema;
