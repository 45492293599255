import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { Copy } from "utils";
import { withStyle, WithStyleProps } from "../../components/hocs/withTemplate";
import { Text } from "../../components/elementsThemed/Text";
import { Button } from "../../components/elementsThemed/Button";
import { View } from "../../components/elementsThemed/View";
import styles from "./logout.module.scss";

interface LogoutProps extends WithStyleProps {
  onConfirm: () => void;
  onCancel: () => void;
}

function Logout({ style, onConfirm, onCancel }: LogoutProps) {
  return (
    <>
      <View className={styles.background} type={style.views.background} />
      <div className={styles.container}>
        <div className={styles.dialogue}>
          <Text type={style.labels.title}>
            {Copy.LOGOUT_STATIC.LOGOUT_CONFIRM_MESSAGE}
          </Text>
        </div>
        <Col
          xs={{ offset: "1-12", span: "5-6" }}
          sm={{ offset: "1-8", span: "3-4" }}
          style={{
            marginLeft: 0,
          }}
        >
          <Row gutter={60}>
            <Col xs="1-2">
              <Button
                className=""
                block
                type={style.buttons.confirm}
                onClick={onConfirm}
              >
                Logout
              </Button>
            </Col>
            <Col xs="1-2">
              <Button
                className=""
                block
                type={style.buttons.cancel}
                onClick={onCancel}
              >
                {Copy.LOGOUT_STATIC.CANCEL_BUTTON_TEXT}
              </Button>
            </Col>
          </Row>
        </Col>
      </div>
    </>
  );
}

export default withStyle(Logout, "logout");
