import * as React from "react";
import { uuidv4 } from "utils/helpers/other";

const GlobalMessage = ({ error, duration, render, afterQueueSuccess }) => {
  const [errors, setErrors] = React.useState([]);

  React.useEffect(() => {
    if (!error) {
      return;
    }

    const removeRef = (id) => {
      const x = errors.filter((i) => i.id !== id);
      setErrors(x);
    };
    const doesExist = errors.findIndex((i) => i.value === error) !== -1;
    if (!doesExist) {
      const uniqueId = uuidv4();

      setErrors([
        ...errors,
        {
          id: uniqueId,
          interval: setTimeout(() => {
            removeRef(uniqueId);
          }, duration),
          value: error,
        },
      ]);
    }
    afterQueueSuccess();
  }, [error]);

  return errors.map((i) => (
    <React.Fragment key={i.id}>{render(i.value)}</React.Fragment>
  ));
};

GlobalMessage.defaultProps = {
  duration: 5000,
};

export default GlobalMessage;
