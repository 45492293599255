import { withTypeSelector } from "components/hocs";
import { config } from "utils";
import Type1 from "./checkout";
import Type2 from "./checkoutType2";

export default withTypeSelector(
  {
    Type1,
    Type2,
  },
  config.theme.checkout.version,
  Type1,
);
