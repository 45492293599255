import * as React from "react";

const MenuContext = React.createContext();

const useMenuContext = () => {
  const contextValues = React.useContext(MenuContext);
  if (!contextValues) {
    throw new Error(
      "useMenuContext is being accessed outside a MenuContextProvider",
    );
  }
  return contextValues;
};

export { useMenuContext, MenuContext };
export default MenuContext;
