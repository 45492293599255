import Axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import * as axios from "./axios";
import {
  addDefaultTransformToDataFetcher,
  normalizeLocations,
} from "./helpers/responseTransforms";
import { giftCardLabels } from "../Header/AccountDrawer";
import type {
  PatronSignInPayload,
  PatronSignInResponse,
  PatronSignUpPayload,
  PatronSignUpResponse,
} from "../@types/patron";

export type AxiosMethodType = <T = never, R = AxiosResponse<T>>(
  path: string,
  data?: any,
  config?: AxiosRequestConfig<T>,
) => Promise<R>;

interface AxiosMethods {
  get: AxiosMethodType;
  put: AxiosMethodType;
  post: AxiosMethodType;
  delete: AxiosMethodType;
}

export const {
  get,
  put,
  post,
  delete: destroy,
} = axios.methods as AxiosMethods;
const { AutomationInstance: getLocationCardStructure } = axios;

/**
 * Use following type here as
 * API: AxiosRequestFn<DataType, ResponseType> = (data?: DataType, config?: AxiosRequestConfig<ResponseType>) => [AxiosMethod](...)
 *
 * Goal is to change T = any to T = never
 */
type AxiosRequestFn<D = any, T = any, R = AxiosResponse<T>> = (
  data: D,
  config?: AxiosRequestConfig<T>,
) => Promise<R>;

// TODO: Complete type definitions for all request responses below

export const HANDLE_ERROR = (e) => axios.handleError(e);

export const FETCH_PATRON: AxiosRequestFn = () => get<any>("/patron");

export const FETCH_LOYALTY: AxiosRequestFn = () => get("/loyalty");

export const REDEEM_PROMO: AxiosRequestFn = (data, config) =>
  post("/promotions", data, config);

export const REDEEM_BANK_REWARD = (data) => post("/banks/redeem", data);

export const VALIDATE_PROMO: AxiosRequestFn = (data, config) =>
  post("/promotions/validate", data, config);

export const UPDATE_PATRON: AxiosRequestFn = (data, config) =>
  put("/patron", data, config);

export const PATRON_SOCIAL_SIGN_IN: AxiosRequestFn = (data, config) =>
  post("/patron/sign-in/social", data, config);

export const PATRON_SIGN_IN: AxiosRequestFn<
  PatronSignInPayload,
  PatronSignInResponse
> = (data, config) => post("/patron/sign-in", data, config);

export const PATRON_SIGN_UP_URL = "/patron/sign-up";
export const PATRON_SIGN_UP: AxiosRequestFn<
  PatronSignUpPayload,
  PatronSignUpResponse
> = (data, config) => post(PATRON_SIGN_UP_URL, data, config);

export const PATRON_SIGN_OUT: AxiosRequestFn = (data, config) =>
  post("/patron/sign-out", data, config);

export const PATRON_OLO_SIGN_UP: AxiosRequestFn = (data, config) =>
  post("/patron/sign-in/social", data, config);

export const REDEEM_LOYALTY_REWARD: AxiosRequestFn = (data) =>
  post("/patron/redeem", data);

export const PATRON_VALIDATE: AxiosRequestFn = (data, config) =>
  post("/patron/validate", data, config);

export const PATRON_FORGOT_PASSWORD: AxiosRequestFn = (data, config) =>
  post("/patron/forgot-password", data, config);

export const PATRON_ADD_CARD: AxiosRequestFn<
  {
    brand?: string;
    cardNonce: string;
    expDate?: string;
    last4?: string;
  },
  { brand?: string; last4?: string; expMonth?: string; expYear?: string }
> = (data, config) => post("/patron/add-card", data, config);

export const PATRON_UPDATE_CARD = (id, data, config) =>
  put(`/cards/${id}`, data, config);

export const PATRON_DELETE_CARD = (id, data, config) =>
  destroy(`/cards/${id}`, data, config);

export const PATRON_UPDATE_CART: AxiosRequestFn = (data, config) =>
  post("/patron/add-card", data, config);

export const FETCH_PATRON_ADDRESS: AxiosRequestFn = (data, config) =>
  post("/patron/address", data, config);

export const FETCH_LOCATIONS = addDefaultTransformToDataFetcher(
  (data, config) => post("/locations", data, config),
  normalizeLocations,
);

// fetch runtime data from backend (Otomeshon) using custom axios instance
export const FETCH_LOCATION_CARD_STRUCTURE = () =>
  getLocationCardStructure("automation/components?type=LocationCard");

export const SET_SCHEDULE_DATE = ({ date, data, config }) =>
  get(`locations/schedule-dates/${date}`, data, config);

export type FetchAddressResponse = Array<{
  id: string;
  text: string;
}>;

export const FETCH_ADDRESS: AxiosRequestFn<
  {
    address: string;
    orderType: OrderType;
  },
  FetchAddressResponse
> = (data, config) => get("/address", data, config);

export const FETCH_PLACES = (placeId, query) =>
  get(`/places/${placeId}`, query);

export const FETCH_MENU: AxiosRequestFn = (data, config) =>
  get("/menus", data, config);

export const FETCH_UPSELLS_MENU: AxiosRequestFn = (data, config) =>
  get("/upsells", data, config);

export const PATRON_SEND_PINCODE_BY_ACCOUNT: AxiosRequestFn = (
  account,
  config,
) => post("/pincode", { account }, config);

export const PATRON_SEND_PINCODE: AxiosRequestFn = (data, config) =>
  post("/pincode/send", data, config);

export const PATRON_AUTH_PINCODE: AxiosRequestFn = (data, config) =>
  post("/pincode/auth", data, config);

export const PATRON_VERIFY_PINCODE: AxiosRequestFn = (data, config) =>
  post("/pincode/verify", data, config);

export const VALIDATE_TABLE_NUMBER: AxiosRequestFn = (data, config) =>
  post("/tables/validate", data, config);

export const VALIDATE_DISCOUNT: AxiosRequestFn = (data, config) =>
  post("/discount/validate", data, config);

export const VALIDATE_GIFTCARD = (code?: string, token?: any) =>
  post<any>(`/stored-value-cards/${code}`, token);

export const GET_CREDENTIALS: AxiosRequestFn = (data, config) =>
  get("/location/credentials", data, config);

export const CREATE_PAYMENT_INTENT: AxiosRequestFn = (data, config) =>
  post("/order/create-payment-intent", data, config);

export const PLACE_ORDER_POLLING: AxiosRequestFn = (data) =>
  post("/place-order", data);

export const GET_ORDER_STATUS: AxiosRequestFn<string> = (idempotencyKey) =>
  get(`/order/${idempotencyKey}/status`);

export const VALIDATE_ORDER: AxiosRequestFn = (data, config) =>
  post("/order/validate", data, config);

export const CHECK_PRICE: AxiosRequestFn = (data, config) =>
  post("/order/check-price", data, config);

export const GET_TRACKING_INFO: AxiosRequestFn<string> = (orderId, config) =>
  get(`/order/${orderId}/tracking`, config);

export const GET_ORDER_INFO: AxiosRequestFn<string> = (orderId, config) =>
  get(`/orders/${orderId}`, config);

export type GiftCardConfigResponse = Array<{
  type?: "offSite";
  provider?: string;
  config?: Record<
    keyof typeof giftCardLabels,
    {
      url?: string;
      enabled?: boolean;
    }
  >;
}>;

export const GET_GIFTCARD_CONFIGS = () =>
  get<GiftCardConfigResponse>("/configs/giftcard");

export const GET_FACEBOOK_USER_ID = (token) =>
  Axios({
    params: {
      access_token: token,
      fields: "name,email",
      locale: "en_US",
      method: "get",
      pretty: 0,
      sdk: "joey",
      suppress_http_code: 1,
    },
    url: "https://graph.facebook.com/v2.3/me",
  }).then(({ data }) => ({
    userID: data.id,
  }));
