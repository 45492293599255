import { constants } from "utils";
import { eventHandler } from "../tools";

const {
  ANALYTICS: { SIGNED_IN },
} = constants;

export default {
  signedin: eventHandler(SIGNED_IN),
};
