import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { useTemplate } from "components/providers";
import { Condition, Image } from "components/elements";
import { Loader } from "components/fragments";
import { View, Button, Text } from "components/elementsThemed";
import { withTemplate } from "components/hocs";
import { Copy, config } from "utils";
import { formatPrice, stringReplacer } from "utils/helpers/string";
import { useCell, useResource } from "hooks";
import MobileAppDownload from "components/templates/MobileAppDownload";

import { ReactComponent as Checkmark } from "./check.svg";
import css from "./giftcard.module.scss";

const { Condition: If } = Condition;

const { images } = config;

const GiftCardComponent = ({ cells, resource }) => {
  const [success, setSuccess] = React.useState(false);
  const ref = React.useRef(null);
  const left = useCell(cells.left);
  const right = useCell(cells.right);
  const templateContext = useTemplate();
  const { buttons } = templateContext.parsedTheme;

  const copyButtonTheme = left?.buttons?.secondary;
  const copyButtonUnselectedTextColor =
    buttons[copyButtonTheme]?.text?.unselected?.color ?? "#fff";

  const { cardNumber } = resource;
  const numbers = cardNumber?.match(/.{1,4}/g);

  const copyToClipboard = (e) => {
    ref.current.select();
    document.execCommand("copy");
    e.target.focus();
    setSuccess(true);
  };

  return (
    <div className={css.giftCard}>
      <If is={images.art_gift_card_logo}>
        <Row spacing={15} style={{ width: "100%" }}>
          <Col sm="1" md="1-2">
            <Row spacing={15}>
              <Col
                className={css["giftCard--logoContainer"]}
                xs={{ offset: "1-3", span: "1-3" }}
                md={{ offset: "1-4", span: "1-2" }}
              >
                <Image
                  className={css["giftCard--logo"]}
                  src={images.art_gift_card_logo}
                  alt="Gift card Logo"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </If>
      <Row>
        <Col sm="1" md="1-2">
          <View type={left.views.background} className={css["giftCard-left"]}>
            <div className={css["giftCard-left--container"]}>
              <Text
                className={css["giftCard-left--header"]}
                type={left.labelTextStyles.primary}
              >
                {Copy.GIFT_CARD_STATIC.PRIMARY_TEXT}
              </Text>
              <div className={css["giftCard-left--image"]}>
                <Image src={images.art_gift_card} alt="Gift card art work" />
              </div>
              <div className={css["giftCard-left--value"]}>
                <Button type={left?.buttons?.primary}>
                  {Copy.GIFT_CARD_STATIC.PRIMARY_VALUE_TEXT}
                  {formatPrice(resource.amount)}
                </Button>
              </div>
              <Text
                className={css["giftCard-left--subheader"]}
                type={left.labelTextStyles.secondary}
              >
                {Copy.GIFT_CARD_STATIC.PRIMARY_LABEL}
              </Text>
              <View
                type={left.views.secondary}
                className={css["giftCard-left--number"]}
              >
                <Text
                  type={left.labelTextStyles.tertiary}
                  className={css["giftCard-left--input"]}
                >
                  {numbers?.map((i) => (
                    <span>{i}</span>
                  ))}
                </Text>
                <form
                  style={{
                    height: 0,
                    position: "absolute",
                    width: 0,
                    zIndex: -1,
                  }}
                >
                  <textarea
                    ref={ref}
                    value={resource.cardNumber}
                    contentEditable
                    readOnly={false}
                    style={{
                      height: 0,
                      padding: 0,
                      position: "absolute",
                      width: 0,
                      zIndex: -1,
                    }}
                  />
                </form>
              </View>
              <Text
                className={css["giftCard-left--message"]}
                type={left.labelTextStyles.quaternary}
              >
                {stringReplacer(Copy.GIFT_CARD_STATIC.PRIMARY_MESSAGE, [
                  {
                    replaceTarget: "{restaurant}",
                    replaceValue: config.restaurant,
                  },
                ])}
              </Text>
              {document.queryCommandSupported("copy") && (
                <div className={css["giftCard-left--button"]}>
                  <Button
                    className={success ? css.success : ""}
                    type={left?.buttons?.secondary}
                    onClick={copyToClipboard}
                  >
                    <span className={css.text}>
                      {Copy.GIFT_CARD_STATIC.PRIMARY_BUTTON_TEXT}
                    </span>
                    <span className={css.icon}>
                      <Checkmark fill={copyButtonUnselectedTextColor} />
                    </span>
                  </Button>
                </div>
              )}
            </div>
          </View>
        </Col>
        <Col sm="1" md="1-2" className={css["giftCard-top"]}>
          <View type={right.views.background} className={css["giftCard-right"]}>
            <div className={css["giftCard-right--container"]}>
              <View
                type={right.views.primary}
                className={css["giftCard-right--header"]}
              >
                <Text type={right.labelTextStyles.primary}>
                  {Copy.GIFT_CARD_STATIC.SECONDARY_TEXT}
                </Text>
              </View>
              <If
                is={resource.receivingPatron && resource.receivingPatron.name}
              >
                <View
                  type={right.views.secondary}
                  className={css["giftCard-right--field"]}
                >
                  <Text type={right.labelTextStyles.secondary}>
                    {Copy.GIFT_CARD_STATIC.SECONDARY_TO_INPUT_LABEL}
                  </Text>
                  <Text type={right.labelTextStyles.tertiary}>
                    {resource.receivingPatron && resource.receivingPatron.name}
                  </Text>
                </View>
              </If>
              <If is={resource.sendingPatron && resource.sendingPatron.name}>
                <View
                  type={right.views.secondary}
                  className={css["giftCard-right--field"]}
                >
                  <Text type={right.labelTextStyles.secondary}>
                    {Copy.GIFT_CARD_STATIC.SECONDARY_FROM_INPUT_LABEL}
                  </Text>
                  <Text type={right.labelTextStyles.tertiary}>
                    {resource.sendingPatron && resource.sendingPatron.name}
                  </Text>
                </View>
              </If>
              <If is={resource.message}>
                <Text type={right.labelTextStyles.secondary}>
                  {Copy.GIFT_CARD_STATIC.SECONDARY_MESSAGE_INPUT_LABEL}
                </Text>
                <View
                  type={right.views.tertiary}
                  className={css["giftCard-right--message"]}
                >
                  <Text type={right.labelTextStyles.tertiary}>
                    {resource.message}
                  </Text>
                </View>
                <If
                  is={
                    config.apple_app_store_link || config.google_play_store_link
                  }
                >
                  <View
                    type={right.views.primary}
                    className={css["giftCard-right--app"]}
                  >
                    <Text
                      type={right.labelTextStyles.quaternary}
                      dangerouslySetInnerHTML={{
                        __html: Copy.GIFT_CARD_STATIC.SECONDARY_MESSAGE.replace(
                          "\n",
                          "</br>",
                        ),
                      }}
                    />
                  </View>
                  <Row spacing={30}>
                    <MobileAppDownload />
                  </Row>
                </If>
              </If>
            </div>
          </View>
        </Col>
      </Row>
    </div>
  );
};

const GiftCard = ({ style: { cells, views }, match }) => {
  const [message, setMessage] = React.useState("-");

  const { id } = match.params;
  const unknownErrorMessage = Copy.GIFT_CARD_STATIC.UNKNOWN_ERROR_MESSAGE;

  const [{ fetching, resource, error }] = useResource({
    headers: {
      "lb-version": "ec2",
    },
    method: "get",
    path: `/stored-value-cards/${id}`,
  });

  React.useEffect(() => {
    if (fetching) {
      return;
    }
    let errorMessage = "";
    if (error && error.status) {
      // Error was caught attempting connection
      switch (error.status) {
        case 404:
          errorMessage = Copy.GIFT_CARD_STATIC.NOT_FOUND_ERROR_MESSAGE;
          break;
        default:
          errorMessage = error.data || unknownErrorMessage;
      }
    }
    if (resource && resource.message && resource.status) {
      // Response was an error
      errorMessage = resource.message || unknownErrorMessage;
    }
    setMessage(errorMessage);
  }, [fetching]);

  if (fetching) {
    return (
      <View
        type={views.background}
        className={css.container}
        direction="col"
        justify="center"
      >
        <Loader />
      </View>
    );
  }

  return (
    <View type={views.background} className={css.container}>
      <If is={!fetching && message}>
        <Row className={css.giftCard}>
          <Col>{message}</Col>
        </Row>
      </If>
      <If is={!fetching && !message}>
        <GiftCardComponent cells={cells} resource={resource} />
      </If>
    </View>
  );
};

export default withTemplate(GiftCard, "giftCard");
