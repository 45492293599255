import { Reducer, useReducer } from "react";

export type AsyncState<T> = {
  error: Error | null;
  fetching: boolean;
  state: null | T;
};

export type AsyncStateAction<T> = {
  type: "update";
  payload: Partial<AsyncState<T>>;
};

const initializeState = <T>(initState = {}): AsyncState<T> => ({
  error: null,
  fetching: true,
  state: null,
  ...initState,
});

const reducer = <T>(state: AsyncState<T>, action: AsyncStateAction<T>) => {
  switch (action.type) {
    case "update":
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

export type AsyncStateMethods<T> = {
  clearError: () => void;
  clearState: () => void;
  reset: () => void;
  setError: (error: Error) => void;
  setIsFetching: () => void;
  setNotFetching: () => void;
  setState: (nextState: T) => void;
  toggleFetching: () => void;
};

export const useAsyncState = <T>(
  initState: Partial<AsyncState<T>> = {},
): [AsyncState<T>, AsyncStateMethods<T>] => {
  const [state, dispatch] = useReducer<
    Reducer<AsyncState<T>, AsyncStateAction<T>>
  >(reducer, initializeState(initState));

  const updater = (nextState: Partial<AsyncState<T>>) => {
    dispatch({
      payload: nextState,
      type: "update",
    });
  };
  const toggleFetching = () => {
    updater({ fetching: !state.fetching });
  };
  const setIsFetching = () => {
    updater({ fetching: true });
  };
  const setNotFetching = () => {
    updater({ fetching: false });
  };

  const setError = (error: Error) => {
    updater({ error });
  };
  const clearError = () => {
    updater({ error: null });
  };

  const setState = (nextState: T) => {
    updater({ state: nextState });
  };
  const clearState = () => {
    updater({ state: null });
  };

  const reset = () => {
    updater(initializeState(initState));
  };

  return [
    state,
    {
      clearError,
      clearState,
      reset,
      setError,
      setIsFetching,
      setNotFetching,
      setState,
      toggleFetching,
    },
  ];
};

export default useAsyncState;
