import * as React from "react";
import csx from "classnames";
import { useTemplateContext } from "components/providers/Template";
import { View } from "components/elementsThemed";
import { useCell } from "hooks";
import { config } from "utils";

import { ReactComponent as PoweredByLunchbox } from "../../../../assets/poweredByLunchbox.svg";
import css from "./sticky.module.css";

const Sticky = ({ type, style, className = "" }) => {
  const { views, labelTextStyles } = useCell(type);
  const {
    parsedTheme: { labels },
  } = useTemplateContext();

  const toggleDelayStyle = config?.theme?.footer?.toggleDelay
    ? {
        ...style,
        animation: `${css.stickySlide} 0.6s`,
        animationDelay: `${config?.theme?.footer?.toggleDelay}s`,
        animationFillMode: "forwards",
      }
    : style;
  return (
    <View
      type={views.background}
      className={csx(css.footer, className)}
      style={toggleDelayStyle}
    >
      <a href="https://lunchbox.io" className={css["footer-link"]}>
        <PoweredByLunchbox
          role="img"
          aria-label="Powered by Lunchbox"
          focusable="false"
          title="Powered by Lunchbox"
          fill={labels[labelTextStyles.primary].color}
        />
      </a>
    </View>
  );
};

export { Sticky };
