import * as React from "react";
import { Field } from "components/elementsThemed";
import { WebApis, images, Copy } from "utils";
import { useCachedAddresses } from "../../../contexts/CachedAdresses";
import { Image } from "../../fragments";
import { withTemplate } from "../../hocs";
import css from "./pickupForm.module.scss";
import { LiveSearch } from "../LiveSearch";

const { FieldItem } = Field;

const PickupForm = ({ style, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const [address, setAddress] = React.useState("");
  const [fetching, setFetching] = React.useState(false);
  const { recentAddresses, updateRecentAddressesState } = useCachedAddresses();

  const getGeo = async () => {
    setFetching(true);
    try {
      const coords = await WebApis.Geo.getPosition();
      props.onGeo({
        lat: coords.coords.latitude,
        long: coords.coords.longitude,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setFetching(false);
    }
  };

  const checkGeo = async () => {
    if (navigator.permissions) {
      // Permission API is implemented
      await navigator.permissions
        .query({
          name: "geolocation",
        })
        .then((permission) => {
          if (permission.state === "granted") {
            return getGeo();
          }
          return () => {};
        });
    }
  };

  React.useEffect(() => {
    checkGeo();
  }, []);

  return (
    <FieldItem
      type={style.labels.label}
      className={css.field}
      label={Copy.RG_INFO_STATIC.LOCATION_TEXT}
    >
      <LiveSearch
        type={style.inputs.standard}
        resultType={style.buttons.searchResults}
        recentAddresses={recentAddresses}
        onSelect={(i) => {
          setAddress(i.text);
          updateRecentAddressesState(i);
          props.onSelect(i.id);
        }}
        inputProps={{
          className: "input-pickup",
          icon: (
            <Image
              src={images?.icon_geo}
              className={`${css["geo-icon"]} ${fetching ? css.blink : ""}`}
              mediaName="icon_geo"
              mediaType="svg"
              onClick={getGeo}
              alt="Location Icon"
            />
          ),
        }}
      />
    </FieldItem>
  );
};

export default withTemplate(PickupForm, "address");
