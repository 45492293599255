import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

import { determineBreakPoint } from "utils/helpers/other";
import useWindowSize from "hooks/useWindowSize";
import { Drawer } from "./drawer2";

type DrawerProp = {
  destroyOnClose: boolean;
  onClose: () => void;
};

type ResponsiveDrawerProps = {
  children?: any;
  drawerProps?: DrawerProp | null;
  isOpen?: boolean;
  className?: string;
  trigger?: React.ReactElement;
  ariaLabel?: string;
  history?: RouteComponentProps;
  onChange?: (bool) => void;
};

const newWidth = (breakPoint) => {
  switch (breakPoint) {
    case "sm":
      return "80vw";
    case "md":
      return "50vw";
    case "lg":
    case "xl":
    case "xxl":
      return "40vw";
    default:
      return "100vw";
  }
};

function ResponsiveDrawer({
  children,
  trigger,
  drawerProps,
  ...props
}: ResponsiveDrawerProps) {
  const { width } = useWindowSize({ debounce: 200 });
  const [drawerWidth, setWidth] = React.useState(null);
  const { name: breakPoint } = determineBreakPoint(width);

  React.useEffect(() => setWidth(newWidth(breakPoint)), [width]);

  return (
    <Drawer
      {...props}
      drawerProps={{
        width: drawerWidth,
        ...drawerProps,
      }}
      trigger={trigger}
    >
      {children}
    </Drawer>
  );
}

ResponsiveDrawer.defaultProps = {
  children: "",
  trigger: "",
  drawerProps: null,
  isOpen: false,
  className: "",
  ariaLabel: "",
  history: {},
  onChange: Function,
};

export { ResponsiveDrawer };
export default ResponsiveDrawer;
