import * as React from "react";
import { Text, View } from "components/elementsThemed";
import { useCell } from "hooks";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import css from "./option.module.scss";

function Option({
  error,
  errorRef,
  id,
  modifierItems,
  name,
  navIndex,
  optionRef,
  type,
}) {
  const { views, labelTextStyles } = useCell(type);
  return (
    <div className={css.option} data-id={id}>
      <View
        type={views.background}
        Component="ul"
        className={css["option-content"]}
      >
        <div ref={optionRef}>
          <div className={css["option-line"]} data-id={navIndex}>
            <Text
              type={labelTextStyles.secondary}
              className={css["option-lineText"]}
            >
              {name}
            </Text>
          </div>
        </div>

        <div aria-live="polite" ref={errorRef} className={css["option-error"]}>
          {error && (
            <Text type={labelTextStyles.tertiary}>
              <VisuallyHidden>Error: </VisuallyHidden>
              {error}
            </Text>
          )}
        </div>
      </View>

      {modifierItems}
    </div>
  );
}

Option.displayName = "OptionType7";

export default React.memo(Option);
