import * as React from "react";
import { config } from "utils";

import Type1 from "./Type1";
import Type2 from "./Type2";

function Selector({ ...props }) {
  let Component = Type1;

  switch (config.theme.item_details.subgroups) {
    case "Type2":
      Component = Type2;
      break;
    default:
      Component = Type1;
  }

  return <Component {...props} />;
}

export default Selector;
