import * as React from "react";
import { ReactComponent as CloseButton } from "assets/close.svg";
import {
  animate,
  motion,
  useDragControls,
  useMotionValue,
} from "framer-motion";
import { Text } from "components/elementsThemed";
import styles from "./style.module.scss";

type BottomSheetProps = {
  children: React.ReactNode;
  labelTextStyles?: any;
  fixedHeight?: number;
  onClose?: () => void;
  title?: string;
};

function BottomSheet({
  labelTextStyles,
  children,
  fixedHeight = 0,
  onClose,
  title,
}: BottomSheetProps) {
  // enable user to choose a fixed height bottom sheet or dynamically adjust the height by content
  const [sheetHeight, setSheetHeight] = React.useState(fixedHeight);

  const sheetY = useMotionValue(0);
  const controls = useDragControls();

  React.useLayoutEffect(() => {
    const updateSheetHeight = () => {
      const bottomSheetContainerNode = document.querySelector(
        ".bottomSheetContainer",
      );
      if (!fixedHeight && bottomSheetContainerNode !== null) {
        const contentHeight = bottomSheetContainerNode.clientHeight;
        setSheetHeight(contentHeight);
      }
    };

    window.addEventListener("resize", updateSheetHeight);
    updateSheetHeight();
    return () => window.removeEventListener("resize", updateSheetHeight);
  }, []);

  // controls transition
  const spring = {
    type: "spring",
    damping: 50,
    stiffness: 300,
  };
  return (
    <motion.div
      style={{
        bottom: "0",
        left: "0",
        position: "absolute",
        right: "0",
        top: "0",
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{
          background: "rgba(0, 0, 0, 0.25)",
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
        }}
      />
      <motion.div
        style={{
          bottom: 0,
          height: sheetHeight,
          left: 0,
          position: "fixed",
          right: 0,
          zIndex: 5,
          y: sheetY,
        }}
        drag="y"
        dragControls={controls}
        dragConstraints={{ bottom: 200, top: 0 }}
        dragElastic={false}
        onDragEnd={() => {
          if (sheetY.get() < 20) {
            animate(sheetY, 0, { type: "spring" });
          } else if (onClose) {
            onClose();
          }
        }}
      >
        <motion.div
          initial={{ y: "50%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={spring}
          style={{
            background: "#fff",
            borderRadius: "30px 30px 0px 0px",
            bottom: 0,
            display: "flex",
            flexDirection: "row",
            left: 0,
            position: "absolute",
            right: 0,
            top: 0,
          }}
          role="dialog"
          aria-modal="true"
          aria-labelledby={`${title} Modal`}
        >
          <div className={styles.body}>
            <div className={styles.header}>
              {onClose && (
                <button
                  aria-label={`Close ${title} Modal Box`}
                  className={styles.closeTip}
                  data-testid="closeBtn"
                  onClick={onClose}
                  type="button"
                >
                  <CloseButton />
                </button>
              )}
              {title && (
                <Text type={labelTextStyles?.tertiary} className={styles.title}>
                  {title}
                </Text>
              )}
            </div>
            <div className="bottomSheetContainer">{children}</div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default BottomSheet;
