import * as React from "react";
import { Col } from "@lunchboxinc/lunchbox-components";
import ClampLines from "react-clamp-lines";
import csx from "classnames";
import { Text, View, Button } from "components/elementsThemed";
import { Condition as Conditional } from "components/elements";
import { QuantitySelector } from "components/fragments";
import { useCell } from "hooks";
import { formatPrice } from "utils/helpers/string";
import { images as clientImages } from "utils/Images";
import { designConfig } from "./constants";

const { Condition: If } = Conditional;

const Item = ({
  configType,
  decrement,
  handleRemove,
  increment,
  item = {},
  multiSelect,
  nestedItems = [],
  onClick,
  quantity = 0,
  type,
}) => {
  const {
    description = "",
    images = [],
    isNested = false,
    name = "",
    price = 0,
  } = item;

  const { labelTextStyles, views, buttons } = useCell(type);

  // TODO: Change styles to conditional import with @loadable/component
  const {
    clampName,
    colProps,
    hideDescription,
    maxHeight,
    showBackgroundImage,
    showImage,
    styles,
  } = designConfig(configType);
  // Text maximum height
  const textMaxHeight = maxHeight ? { maxHeight } : {};

  return multiSelect ? (
    <Col {...colProps}>
      <View
        type={views.background}
        active={quantity > 0 ? "1" : "0"}
        className={csx(styles.modifierItem, showImage && styles.row)}
        data-cy="modifier-add-button"
      >
        <button
          aria-label={`${name}${price > 0 ? ` $${price}` : ""}`}
          aria-pressed={quantity === 0 ? "false" : "true"}
          type="button"
          onClick={onClick}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            height: "100%",
            pointerEvents: quantity === 0 ? "auto" : "none",
            position: "absolute",
            width: "100%",
          }}
          id={name}
        />
        <If is={handleRemove}>
          <div className={styles["modifierItem-remove"]}>
            <Button type={buttons.primary} onClick={onClick}>
              {clientImages.modifier_item_remove ? (
                <img src={clientImages.modifier_item_remove} />
              ) : (
                "X"
              )}
            </Button>
          </div>
        </If>
        {showImage && images?.[0] && (
          <img
            className={styles["modifierItem-image"]}
            src={images?.[0]}
            alt={name}
          />
        )}
        <div className={styles["modifierItem-content"]}>
          <div
            aria-hidden="true"
            className={styles["modifierItem-content--name"]}
          >
            <Text type={labelTextStyles.primary}>
              {clampName ? (
                <ClampLines
                  id={1}
                  buttons={false}
                  text={name}
                  lines={2}
                  ellipsis="..."
                />
              ) : (
                name
              )}
            </Text>
          </div>
          {!hideDescription && !quantity && (
            <If
              is={
                (isNested && nestedItems.length) ||
                (description && configType !== "Type4")
              }
            >
              <Text
                className={styles["modifierItem-content--desc"]}
                type={labelTextStyles?.secondary}
                {...textMaxHeight}
              >
                {isNested && quantity > 0
                  ? Object.values(nestedItems).map(
                      ({ nestedItemsName = "" }) => nestedItemsName,
                    )
                  : description}
              </Text>
            </If>
          )}
          <If is={price}>
            <Text
              aria-hidden="true"
              className={styles["modifierItem-content--price"]}
              type={labelTextStyles.secondary}
            >
              {formatPrice(price)}
            </Text>
          </If>
          <If is={quantity !== 0 && !isNested}>
            <QuantitySelector
              forwardStyles={styles}
              viewType={views.secondary}
              buttonType={buttons.primary}
              labelType={labelTextStyles.tertiary}
              quantity={quantity}
              inc={increment}
              dec={decrement}
              name={name}
            />
          </If>
        </div>
        <If is={isNested}>
          <View
            type={views?.quaternary || views?.background}
            className={styles["modifierItem-nestedSelector"]}
          >
            <Text
              type={labelTextStyles?.quaternary || labelTextStyles?.primary}
            >
              MODIFY
            </Text>
          </View>
        </If>
      </View>
    </Col>
  ) : (
    <Col {...colProps}>
      <View
        type={views.background}
        className={csx(styles.modifierItem, showImage && styles.row)}
        data-cy="modifier-add-button"
      >
        <button
          aria-label={`${name} ${price > 0 ? `$${price}` : ""}`}
          aria-pressed={quantity === 0 ? "false" : "true"}
          type="button"
          onClick={onClick}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            height: "100%",
            position: "absolute",
            width: "100%",
          }}
        />
        <If is={handleRemove}>
          <div className={styles["modifierItem-remove"]}>
            <Button type={buttons.primary} onClick={onClick}>
              {clientImages.modifier_item_remove ? (
                <img src={clientImages.modifier_item_remove} />
              ) : (
                "X"
              )}
            </Button>
          </div>
        </If>
        {showImage && images?.[0] && (
          <img
            className={styles["modifierItem-image"]}
            src={images?.[0]}
            alt={name}
          />
        )}
        <div className={styles["modifierItem-content"]}>
          <div
            aria-hidden="true"
            className={styles["modifierItem-content--name"]}
          >
            <Text type={labelTextStyles.primary}>
              {clampName ? (
                <ClampLines
                  id={1}
                  buttons={false}
                  text={name}
                  lines={2}
                  ellipsis="..."
                />
              ) : (
                name
              )}
            </Text>
          </div>
          {!hideDescription && !quantity && (
            <If
              is={
                (isNested && nestedItems.length) ||
                (description && configType !== "Type4")
              }
            >
              <Text
                className={styles["modifierItem-content--desc"]}
                type={labelTextStyles?.secondary}
                {...textMaxHeight}
              >
                {isNested && quantity > 0
                  ? Object.values(nestedItems).map(
                      ({ nestedItemsName = "" }) => nestedItemsName,
                    )
                  : description}
              </Text>
            </If>
          )}
          <If is={price}>
            <Text
              aria-hidden="true"
              className={styles["modifierItem-content--price"]}
              type={labelTextStyles.secondary}
            >
              {formatPrice(price)}
            </Text>
          </If>
        </div>
        <If is={isNested}>
          <View
            type={views?.quaternary || views?.background}
            className={styles["modifierItem-nestedSelector"]}
          >
            <Text
              type={labelTextStyles?.quaternary || labelTextStyles?.primary}
            >
              MODIFY
            </Text>
          </View>
        </If>
      </View>
    </Col>
  );
};

export default React.memo(Item);
