import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { Condition } from "components/elements";
import { Text } from "components/elementsThemed/Text";
import { Input } from "components/elementsThemed/Field";
import { useTemplate } from "hooks/template";
import { Copy, FETCH_ADDRESS } from "utils";
import { AddressResult, ListBox, Popover, useAddressSearch } from "./shared";
import { fetchPlaceById, parseAddress } from "./helpers";
import css from "./AddressSearch.module.scss";

const { Condition: If } = Condition;

// TODO: Implement Address type definition
type Address = any;

interface DeliverySearchProps {
  address: Address;
  /** Callback when the input receives focus. */
  onFocus(): void;
  /** Callback when the search results or place selection results in error. */
  onError(): void;
  /** Callback when the user selects an address. */
  onSelect(address: Address): void;
  handleStreetChange(streetType: "street1" | "street2", newValue: string): void;
}

/**
 * Renders inputs and suggestions for a user's delivery address.
 *
 * Behaviors:
 * - The "street 1" field is the input for a combobox. When
 *   a value is selected, it should update the global order context
 *   with the user's address.
 * - After a user selects an address, we fetch the Place
 *   from the API to determine if it's an address that the
 *   restaurant group actually delivers to.
 * - The "street 2" field is an input that should connect
 *   directly to global order state – it doesn't determine the
 *   location, but rather is used to provide more detail for the delivery.
 *
 * TODO: Add test coverage.
 */
export function DeliverySearch({
  address: initialAddress,
  onError,
  onFocus,
  onSelect,
  handleStreetChange,
}: DeliverySearchProps) {
  const {
    comboBoxProps,
    comboBoxState,
    err,
    inputRef,
    popoverRef,
    listBoxRef,
    fetching,
  } = useAddressSearch({
    onFocus,
    onError,
    onSelect: async (address) => {
      // TODO: Implement Address type definition
      const data = (await fetchPlaceById(address.id)) as Address;

      // Whenever we have an incomplete physical address e.g. Hammes Mowbray Hall or Cornell University
      // Google API does not return street1 or street2 fields. we will need to take the
      // "street" that the user entered, and pass that to the backend as the street1 param
      const parsedStreetOne = address.text.slice(0, address.text.indexOf(","));
      const parsedAddress = parseAddress({
        ...data,
        addressComps: {
          ...data.addressComps,
          street1: data.addressComps.street1 || parsedStreetOne,
        },
      });

      if (parsedAddress.isValid) {
        onSelect(parsedAddress);
      } else {
        throw new Error("Unable to deliver to the address you've provided.");
      }
    },
    orderType: "delivery",
    fetch: FETCH_ADDRESS,
  });
  const template = useTemplate("address");

  const preventClearOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const preventClearOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) =>
    e.key === "Enter" && e.preventDefault();

  const street1Placeholder = initialAddress.street1
    ? initialAddress.street1
    : Copy.DELIVERY_FORM_STATIC.FORM_SEARCH_FORM_ADDRESS_PLACEHOLDER;
  const street2Placeholder = initialAddress.street2
    ? initialAddress.street2
    : Copy.DELIVERY_FORM_STATIC.APARTMENT_SUIT_INPUT_PLACEHOLDER;

  return (
    <>
      <Row gutter={15} className={css.DeliverySearch}>
        <Col xs="2-3">
          <label htmlFor="street1" {...comboBoxProps.labelProps}>
            <Text type={template.labels.label}>
              {Copy.DELIVERY_FORM_STATIC.FORM_SEARCH_FORM_ADDRESS_LABEL}
            </Text>
          </label>
          <Input
            {...comboBoxProps.inputProps}
            inputRef={inputRef}
            type={template.inputs.standard}
            className={[css.input, "input-delivery"]}
            htmlType="text"
            placeholder={street1Placeholder}
            onBlur={preventClearOnBlur}
            onKeyDown={preventClearOnEnter}
          />
        </Col>
        <Col xs="1-3">
          <label htmlFor="street2">
            <Text type={template.labels.label}>
              {Copy.DELIVERY_FORM_STATIC.APARTMENT_SUIT_INPUT_LABEL}
            </Text>
          </label>
          <Input
            id="street2"
            className={[css.aptInfoInput]}
            type={template.inputs.standard}
            placeholder={street2Placeholder}
            value={initialAddress.street2}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleStreetChange("street2", e.target.value)
            }
          />
        </Col>
      </Row>

      <Row>
        <Col>
          {fetching && <p>{Copy.LIVE_SEARCH_STATIC.MESSAGE}</p>}
          {comboBoxState.isOpen && (
            <Popover
              popoverRef={popoverRef}
              isOpen={comboBoxState.isOpen}
              onClose={comboBoxState.close}
            >
              <ListBox<AddressResult>
                {...comboBoxProps.listBoxProps}
                listBoxRef={listBoxRef}
                state={comboBoxState}
              />
            </Popover>
          )}
        </Col>
      </Row>

      <If is={err}>
        <Row>
          <Col>
            <Text type={template.labels.error}>
              {Copy.DELIVERY_FORM_STATIC.FORM_ADDRESS_UNAVAILABLE_MESSAGE}
            </Text>
          </Col>
        </Row>
      </If>
    </>
  );
}
