import * as React from "react";
import { Formik } from "formik";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";

import { InlineLoader } from "components/elements";
import { PATRON_ADD_CARD } from "utils/api";
import DrawerButton from "../../../DrawerButton";

import "./spreedly.css";

const { Spreedly } = window;

const initialValues = {
  first_name: "",
  last_name: "",
  month: "",
  year: "",
  zip: "",
};

function SpreedlyPaymentForm({ locationCreds, fetching }) {
  const [spreedlyPaymentToken, setSpreedlyPaymentToken] = React.useState("");
  const [spreedlyError, setSpreedlyError] = React.useState("");

  const history = useHistory();

  const { mutate: addCard, isLoading } = useMutation(
    async ({ cardNonce }: { cardNonce: string }) =>
      PATRON_ADD_CARD({ cardNonce }),
    {
      onSuccess: () => history.goBack(),
      onError: (err) => {
        setSpreedlyError(`${err}`);
      },
    },
  );

  React.useEffect(() => {
    if (!spreedlyPaymentToken) return;

    addCard({ cardNonce: spreedlyPaymentToken });
  }, [spreedlyPaymentToken]);

  React.useEffect(() => {
    if (!Spreedly) return;

    Spreedly.init(locationCreds.publicKey, {
      numberEl: "spreedly-number-test",
      cvvEl: "spreedly-cvv-test",
    });

    Spreedly.on("paymentMethod", async (token: string) => {
      setSpreedlyPaymentToken(token);
    });

    Spreedly.on("ready", () => {
      Spreedly.setFieldType("number", "number");
      Spreedly.setFieldType("cvv", "number");
      Spreedly.setNumberFormat("prettyFormat");
      Spreedly.setStyle(
        "number",
        "width: 17rem; border-radius: 3px; border: 1px solid #ccc; padding: 0.75rem 0.5rem; font-size: 1rem;",
      );
      Spreedly.setStyle(
        "cvv",
        "width: 5rem; border-radius: 3px; border: 1px solid #ccc; padding: 0.75rem 0.5rem; font-size: 1rem; margin-left: auto",
      );
      Spreedly.setPlaceholder("cvv", "CVV");
      Spreedly.setPlaceholder("number", "Credit Card");
    });

    Spreedly.on("errors", (errors: { message: string }) => {
      const err = errors[0].message;
      setSpreedlyError(err);
    });
  }, [fetching]);

  const handleSpreedlySubmit = (values) => {
    if (Spreedly) {
      Spreedly.tokenizeCreditCard(values);
    } else {
      console.error("Spreedly is not initialized");
    }
  };

  return (
    <Formik
      id="payment-form"
      onSubmit={handleSpreedlySubmit}
      initialValues={initialValues}
    >
      {({ setFieldValue, submitForm }) => (
        <>
          <fieldset className="spf-fs-name">
            <div className="spf-field spf-field-fname">
              <input
                type="text"
                placeholder="First Name"
                className="spf-input-text"
                id="first_name"
                name="first_name"
                onChange={(e) => setFieldValue("first_name", e.target.value)}
              />
            </div>
            <div className="spf-field spf-field-lname">
              <input
                type="text"
                placeholder="Last Name"
                className="spf-input-text"
                id="last_name"
                onChange={(e) => setFieldValue("last_name", e.target.value)}
              />
            </div>
          </fieldset>
          <fieldset className="spf-fs-cc">
            <div className="spf-field">
              <div
                id="spreedly-number-test"
                className="spf-field-group spf-field-cc"
              />
              <div
                id="spreedly-cvv-test"
                className="spf-field-group spf-field-cc"
              />
            </div>

            <div className="spf-field spf-field-exp">
              <div className="spf-field-group spf-month">
                <input
                  type="text"
                  className="spf-input-text spf-exp"
                  id="month"
                  size={3}
                  maxLength={2}
                  placeholder="MM"
                  onChange={(e) => setFieldValue("month", e.target.value)}
                />
              </div>
              <span className="spf-exp-divider">/</span>
              <div className="spf-field-group spf-year">
                <input
                  type="text"
                  className="spf-input-text spf-exp"
                  id="year"
                  size={5}
                  maxLength={4}
                  placeholder="YYYY"
                  onChange={(e) => setFieldValue("year", e.target.value)}
                />
              </div>

              <div className="spf-field-group spf-zip">
                <input
                  type="text"
                  placeholder="Zipcode"
                  className="spf-input-text"
                  id="zip"
                  onChange={(e) => setFieldValue("zip", e.target.value)}
                />
              </div>
            </div>
          </fieldset>
          {spreedlyError && (
            <span style={{ color: "red", padding: "1rem" }}>
              {spreedlyError}
            </span>
          )}
          <DrawerButton
            buttonProps={{
              id: "submit-button",
              type: "submit",
              onClick: submitForm,
            }}
          >
            {isLoading ? <InlineLoader size={24} color="inherit" /> : "SAVE"}
          </DrawerButton>
        </>
      )}
    </Formik>
  );
}

export default SpreedlyPaymentForm;
