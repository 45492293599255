import * as React from "react";
import { Col } from "@lunchboxinc/lunchbox-components";

import { View } from "components/elementsThemed";
import DrawerButton from "../../../components/DrawerButton";
import { BeamImpact, Tabs } from "components/fragments";
import { withTemplate } from "components/hocs";
import { Copy } from "utils";

import commonStyles from "../../index.module.scss";
import css from "./beamPersonalCommunityImpactTabs.module.scss";

// Helper Setups
const PERSONAL_IMPACT = "personal-impact";
const COMMUNITY_IMPACT = "community-impact";
const tabs = {
  [COMMUNITY_IMPACT]: {
    impactOption: COMMUNITY_IMPACT,
    name: "Community Impact",
    widget: (widgetId, step, beam, setBeam) => (
      <BeamImpact
        widgetId={widgetId}
        step={step}
        beam={beam}
        setBeam={setBeam}
      />
    ),
  },
  [PERSONAL_IMPACT]: {
    impactOption: PERSONAL_IMPACT,
    name: "My Impact",
    widget: (widgetId, step, beam, setBeam) => (
      <BeamImpact
        widgetId={widgetId}
        step={step}
        beam={beam}
        setBeam={setBeam}
      />
    ),
  },
};
const tabBeam = [PERSONAL_IMPACT, COMMUNITY_IMPACT]
  .map((tab) => tabs[tab])
  .filter((tab) => tab);

/**
 * Show Beam PersonalImpact widget & CommunityImpact widget in tabs
 * after onClick for See More Impact in comfirmation page
 *
 * @memberof Pages.Pages/Order
 * @param style.style
 * @param {object} style - From theme file & withTemplate HOC
 * @param {Function} onClose - Bring the user to Menu in pages/Cart/routes.jsx
 * @param {Function} setHeader - Setter method for state in pages/Cart/routes.jsx
 * @param {object} beam - State in pages/Cart/routes.jsx
 * @param style.onClose
 * @param style.setHeader
 * @param style.beam
 * @param style.setBeam
 * @param {Function} setBeam - Setter method for state in pages/Cart/routes.jsx
 */
const BeamPersonalCommunityImpactTabs = ({
  style,
  onClose,
  setHeader,
  beam,
  setBeam,
}) => {
  const { views, cells, segmentViews } = style;
  const showBeam = Boolean(beam);
  const widgetId = beam?.widget?.options?.widgetId;
  const [impactOption, setImpactOption] = React.useState(PERSONAL_IMPACT);

  const onTabChange = (nextTab) => setImpactOption(nextTab);

  React.useEffect(() => {
    if (setHeader) setHeader("My Impact");
    if (!showBeam) onClose();
  }, []);

  if (showBeam) {
    return (
      <View
        type={views.background}
        className={`${commonStyles.content} ${css.impact}`}
      >
        <View
          type={views.background}
          className={`${commonStyles.content} ${css["impact-content"]}`}
        >
          <div className={css.tabs}>
            <Tabs
              destroyInactiveTabPane
              type={segmentViews?.standard}
              activeKey={impactOption}
              onTabChange={onTabChange}
            >
              {tabBeam.map((option) => (
                <div key={option?.impactOption} title={option.name}>
                  <Col xs="1" className={css["tabs-container"]}>
                    {option.widget(
                      widgetId,
                      option.impactOption,
                      beam,
                      setBeam,
                    )}
                  </Col>
                </div>
              ))}
            </Tabs>
          </div>
        </View>
        <div className={css["impact-footer"]}>
          <DrawerButton
            buttonProps={{
              className: "checkout-confirmation",
              onClick: onClose,
            }}
          >
            {Copy.CHECKOUT_STATIC.COMPLETE_CLOSE_BUTTON_TEXT}
          </DrawerButton>
        </div>
      </View>
    );
  }
  return null;
};

export default withTemplate(BeamPersonalCommunityImpactTabs, "checkout");
