import * as React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { View, Text } from "components/elementsThemed";
import { ReactComponent as Arrow } from "../assets/arrow.svg";
import { ReactComponent as CheckMark } from "../assets/complete.svg";

const Content = React.memo(({ children, ...props }) => (
  <motion.div
    layout
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    {...props}
  >
    {children}
  </motion.div>
));

const CheckoutTab = React.memo(
  ({
    theme,
    css,
    isOpen,
    toggleOpen,
    title,
    price = false,
    completed,
    children,
  }) => {
    // Constants
    const { labels } = theme;

    // Render
    return (
      <motion.li
        initial={{ opacity: 0, y: "100%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "100%" }}
        layout
      >
        <View className={css["checkout-tabs--container"]}>
          <motion.div
            className={css["checkout-tabs--header"]}
            onClick={toggleOpen}
            layout
          >
            <Arrow className={`${css.arrow} ${!isOpen ? css.closed : ""}`} />
            <Text type={labels.priceTotals}>{title}</Text>
            {price && (
              <Text type={labels.priceTotals} className={css.price}>
                {price}
              </Text>
            )}
            {completed && <CheckMark className={css.check} />}
          </motion.div>
          <AnimatePresence>
            {isOpen && (
              <Content className={css["checkout-tabs--content"]}>
                {children}
              </Content>
            )}
          </AnimatePresence>
        </View>
      </motion.li>
    );
  },
);

export default CheckoutTab;
