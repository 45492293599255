/* eslint-disable import/no-unresolved */
import merge from "lodash/merge";
import commonConfig from "CommonConfig";
import images from "ImagesConfig";
import environmentConfig from "EnvironmentConfig";

const globalConfig = { apps: { fullstory: process.env.FULLSTORY_ORGID } };
const combinedConfig = { ...commonConfig };
merge(combinedConfig, environmentConfig, globalConfig);

// Order types available to a specific client
const clientTabs = combinedConfig?.tab_order?.filter(
  (orderType: OrderType) => combinedConfig?.[orderType]?.enabled,
);

const id = combinedConfig.id.toLowerCase();
const isOlo = id === "nascar";

const config = {
  ...combinedConfig,
  id,
  isOlo,
  images,
  tabs: clientTabs,
} as PartnerConfig;

export { config, images };

export default config;
