import * as React from "react";
import { useCell } from "../../../../../hooks";
import Button from "../../../../../components/elementsThemed/Button";

import type { OrderItem } from "../../../../../@types/item";
import css from "./toppingAmountModifierItem.module.scss";

export type ToppingAmountModifierItemProps = {
  item: OrderItem;
  onClick: () => void;
  quantity: number;
};

export const ToppingAmountModifierItem = ({
  item,
  onClick: handleModifierSelect,
  quantity = 0,
}: ToppingAmountModifierItemProps) => {
  const isSelected = Boolean(quantity);
  const cellType = quantity ? "optionItemSelected" : "optionItem";
  const { buttons } = useCell(cellType);

  return (
    <Button
      aria-checked={isSelected}
      aria-disabled={isSelected}
      className={css.toppingAmountModifierItem}
      htmlType="button"
      key={item.id}
      onClick={handleModifierSelect}
      role="checkbox"
      type={buttons.secondary}
    >
      {item.name}
    </Button>
  );
};
