import { constants } from "utils";
import { eventHandler } from "../tools";

const {
  ANALYTICS: {
    PRODUCT_ADDED,
    PRODUCT_CLICKED,
    PRODUCT_MODIFIED,
    PRODUCT_REMOVED,
  },
} = constants;

export default {
  added: eventHandler(PRODUCT_ADDED),
  modified: eventHandler(PRODUCT_MODIFIED),
  removed: eventHandler(PRODUCT_REMOVED),
  viewed: eventHandler(PRODUCT_CLICKED),
};
