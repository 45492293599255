import * as React from "react";
import { View, Text, Field, Button } from "components/elementsThemed";
import { Image } from "components/fragments";
import { withTemplate } from "components/hocs";
import { images, Copy, Routes } from "utils";
import { useCell } from "hooks";

import commonStyles from "../../../index.module.scss";
import styles from "./guests.module.scss";

const { Input } = Field;

const GuestCheckout = ({ style, history, patron, updatePatron }) => {
  const { cells, labels, inputs } = style;
  const { labelTextStyles, buttons, views } = useCell(cells.guests);
  const [message, setMessage] = React.useState("");
  const [patronName, setPatronName] = React.useState(
    `${patron?.firstName || ""}${
      patron?.lastName ? ` ${patron?.lastName}` : ""
    }`,
  );

  return (
    <View type={views.background} className={commonStyles.container}>
      <div className={styles["guests-container"]}>
        {images?.guest_checkout && (
          <img
            className={styles["guests-image"]}
            src={images.guest_checkout}
            alt=""
          />
        )}
        <View type={views.primary} className={styles["guests-primary"]}>
          <Text
            type={labelTextStyles.primary}
            className={styles["guests-header"]}
          >
            {Copy.ORDER_STATIC.GUESTS_SECONDARY_HEADER}
          </Text>
          <Text
            type={labelTextStyles.secondary}
            className={styles["guests-title--secondary"]}
          >
            {Copy.ORDER_STATIC.GUESTS_SECONDARY_TITLE}
          </Text>
          <div className={styles["guests-field"]}>
            <Input
              className={styles["guests-field--input"]}
              label=""
              name="patronName"
              type={inputs.guests}
              value={patronName}
              placeholder="Enter Your Full Name"
              onChange={(e) => {
                let { value } = e.target;
                value = value.replace(/\s*[^a-z\s].*$/i, "");
                setPatronName(value);
                const fname = value.substr(0, value.indexOf(" "));
                const lname = value.substr(value.indexOf(" ") + 1);
                updatePatron({
                  firstName: fname,
                  lastName: lname,
                });
              }}
              invalid={message}
              pattern="[A-Za-z]"
              required
            />
            <div className={styles["guests-field--button"]}>
              <Image
                src={images?.button_back}
                mediaName="button_back"
                mediaType="svg"
                role="button"
                alt={
                  (!patron.firstName &&
                    !patron.lastName &&
                    "Full Name is Required") ||
                  ""
                }
                onClick={(e) => {
                  e.preventDefault();
                  if (!patron.firstName || !patron.lastName) {
                    setMessage("Full name is required.");
                  } else {
                    setMessage("");
                    history.replace(Routes.CHECK_OUT);
                  }
                }}
              />
            </div>
          </div>
          {message && (
            <div className={styles["guests-field"]}>
              <Text type={labels.error}>{message}</Text>
            </div>
          )}
        </View>
        <View type={views.secondary} className={styles["guests-secondary"]}>
          <Text
            type={labelTextStyles.primary}
            className={styles["guests-header"]}
          >
            {Copy.ORDER_STATIC.GUESTS_PRIMARY_HEADER}
          </Text>
          <Text
            type={labelTextStyles.secondary}
            className={styles["guests-title"]}
          >
            {Copy.ORDER_STATIC.GUESTS_PRIMARY_TITLE}
          </Text>
          <Button
            block
            type={buttons.primary}
            className={styles["guests-button"]}
            onClick={(e) => {
              e.preventDefault();
              history.push(Routes.LOG_IN);
            }}
          >
            {Copy.ORDER_STATIC.GUESTS_PRIMARY_BUTTON}
          </Button>
          <Text
            type={labelTextStyles.tertiary}
            className={styles["guests-legal"]}
            dangerouslySetInnerHTML={{
              __html: Copy.ORDER_STATIC.GUESTS_LEGAL,
            }}
          />
        </View>
      </div>
    </View>
  );
};

export default withTemplate(GuestCheckout, "order");
