import * as React from "react";
import { Elements, ElementsThemed, HOCs } from "components";

import { Image } from "components/elements";
import classnames from "classnames";
import { images, Copy, config } from "utils";
import { stringReplacer } from "utils/helpers/string";
import DrawerButton from "../../../../components/DrawerButton";

import commonStyles from "../../../index.module.scss";
import css from "./complete.module.scss";

const {
  Layout: { Flex },
} = Elements;
const { View, Text } = ElementsThemed;

const { withTemplate } = HOCs;

const Complete = ({ style = {}, onSuccess, ticketInformation }) => {
  const { views, cells, labels } = style;

  const text = ticketInformation?.scheduledAt
    ? Copy.CHECKOUT_STATIC.COMPLETE_TICKET_INFORMATION_SCHEDULE_MESSAGE
    : Copy.CHECKOUT_STATIC.COMPLETE_TICKET_INFORMATION_PREPARING_MESSAGE;

  return (
    <View
      Component={Flex}
      grow={1}
      type={views.background}
      className={commonStyles.container}
    >
      <Flex
        justify="start"
        grow={0}
        className={classnames(
          css.contentDineInWrapper,
          "purchase-confirmation",
        )}
      >
        <View className={css.contentDineIn} type={views.body}>
          <div className={css.title}>
            <Text type={labels.primary}>
              {stringReplacer(
                Copy.CHECKOUT_STATIC
                  .COMPLETE_TYPE4_ORDER_IN_THE_KITCHEN_MESSAGE,
                [{ replaceTarget: "{text}", replaceValue: text }],
              )}
            </Text>
          </div>
          <br />
          <div className={css.info}>
            <br />
            <Text type={labels.secondary}>
              {Copy.KIOSK_STATIC.CHECKOUT_META_TEXT_1}
            </Text>
            <br />
            <View type={views.tertiary} className={css.dividerDineIn} />
            <div>
              <Text type={labels.tertiary}>
                {Copy.KIOSK_STATIC.CHECKOUT_TEXT}
              </Text>
            </div>
            <br />
            <div>
              <Text type={labels.quaternary}>
                {ticketInformation.tableNumber}
              </Text>
            </div>
            <View type={views.tertiary} className={css.dividerDineIn} />
          </div>
          <Flex className={css.imageDineInWrapper}>
            <View
              type={views.tertiary}
              className={css["complete-content-divider"]}
            />
            {images?.art_order_confirmation_kiosk && (
              <Image
                src={images?.art_order_confirmation_kiosk}
                className={css["complete-content-img"]}
                alt="confirmation Image"
              />
            )}
          </Flex>
          <div className={css.footerDineIn}>
            <DrawerButton buttonProps={{ onClick: onSuccess }}>
              {Copy.CHECKOUT_STATIC.COMPLETE_BACK_TO_MENU_BUTTON_TEXT}
            </DrawerButton>
          </div>
        </View>
      </Flex>
    </View>
  );
};

export default withTemplate(Complete, "confirmation");
