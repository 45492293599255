import * as React from "react";
import { Condition } from "components/elements";
import { View, Text } from "components/elementsThemed";
import { formatPrice } from "utils/helpers/string";
import { useCell } from "hooks";
import ClampLines from "react-clamp-lines";
import { formatModifierPrice } from "../utils";
import css from "./confirmItemType4.module.scss";

const { Condition: If } = Condition;

const ConfirmItem = ({ price, type, notes, populatedMods, name }) => {
  const { labelTextStyles, views } = useCell(type);
  const y = populatedMods.map((modItem, idx) => {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <div key={idx}>
        <Text type={labelTextStyles?.tertiary}>
          {modItem.itemName}
          {formatModifierPrice(modItem.price, modItem.quantity)}
        </Text>
      </div>
    );
  });
  return (
    <View type={views?.background} Component="li" className={css.card}>
      <div className={css.info}>
        <div className={css.title}>
          <Text type={labelTextStyles?.primary} className={css.name}>
            <ClampLines
              id={0}
              buttons={false}
              text={name}
              lines={1}
              ellipsis="..."
            />
          </Text>
          <Text type={labelTextStyles?.secondary} className={css.price}>
            {formatPrice(price)}
          </Text>
        </div>
        <View type={views?.secondary} className={css.desc}>
          <div className={css.mods}>
            {y}
            <If is={notes}>
              <Text type={labelTextStyles?.tertiary}>{`Note: ${notes}`}</Text>
            </If>
          </div>
        </View>
      </div>
    </View>
  );
};

ConfirmItem.displayName = "ConfirmItemType4";

export default ConfirmItem;
