import * as React from "react";
import { useCell } from "hooks";
import { Text, View, Radio } from "components/elementsThemed";
import { Copy } from "utils";
import { formatPrice } from "utils/helpers/string";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import css from "./groupType1.module.scss";

/**
 * Renders UI for items inside of a subgroup for type1 (radio button)
 *
 * @param {object} props
 * @param {number} props.items - From BeamImpact
 * @param {string} props.selected - Checkout Step
 * @param {object} props.error - State in pages/Cart/routes.jsx
 * @param {object} props.types - Theme file cell types
 * @param {object} props.types.group - Theme for container that holds subgroup items
 * @param {object} props.types.default - State in pages/Cart/routes.jsx
 * @param {object} props.types.selected - State in pages/Cart/routes.jsx
 * @param {Function} props.onSelect - When a subgroup item is selected
 */
const GroupType1 = ({ items, selected, types, onSelect, error }) => {
  const { views: groupViews, labelTextStyles: groupLabelTextStyles } = useCell(
    types.group,
  );
  const { buttons, labelTextStyles: defaultLabelTextStyles } = useCell(
    types.default,
  );

  const onClick = (id) => () => {
    onSelect(id);
  };

  return (
    <div className={css.itemDetailsGroups}>
      <View
        type={groupViews.background}
        className={css["itemDetailsGroups-title"]}
      >
        <Text type={groupLabelTextStyles.primary}>
          {Copy.ITEM_DETAILS_STATIC.SELECT_TEXT}
        </Text>
        {error && (
          <div>
            <Text type={groupLabelTextStyles.tertiary}>
              {Copy.ITEM_DETAILS_STATIC.SELECTION_EMPTY_ERROR}
            </Text>
          </div>
        )}
      </View>
      {items.map((i) => (
        <View
          Component="li"
          key={i.id}
          type="button"
          className={css["itemDetailsGroups-item"]}
          onClick={onClick(i.id)}
        >
          <div className={css["itemDetailsGroups-item--left"]}>
            <Text aria-hidden="true" type={defaultLabelTextStyles.primary}>
              {i.name}
            </Text>
            <Text aria-hidden="true" type={defaultLabelTextStyles.secondary}>
              {i.description}
            </Text>
          </div>
          <div className={css["itemDetailsGroups-item--right"]}>
            <Text
              aria-hidden="true"
              type={defaultLabelTextStyles.secondary}
              className={css["itemDetailsGroups-item--price"]}
            >
              {formatPrice(i.price)}
            </Text>
            <Radio
              type={buttons.primary}
              className={css["itemDetailsGroups-item--radio"]}
              value={i.id === selected}
              onChange={onClick}
            >
              <VisuallyHidden>
                {i.name} {i.description} {formatPrice(i.price)}
              </VisuallyHidden>
            </Radio>
          </div>
        </View>
      ))}
    </div>
  );
};

export default React.memo(GroupType1);
