import * as React from "react";
import classnames from "classnames";
import { Formik, Form } from "formik";

import { UPDATE_PATRON } from "utils/api";
import { axios, Schemas, Copy } from "../../../utils";
import DrawerButton from "../../DrawerButton";
import { Text, View } from "../../elementsThemed";
import { withTemplate } from "../../hocs";
import { PasswordPreview } from "../passwordPreview";

import styles from "../form.module.scss";

const setConfig = (token) =>
  token ? { headers: { authorization: token } } : undefined;

const UpdatePasswordForm = ({ onSuccess, patron, message = "", style }) => {
  const verifyEmail = async ({ password }, actions) => {
    actions.setFieldError("password", "");
    actions.setFieldError("passwordConfirm", "");
    actions.setStatus("");
    try {
      const { data } = await UPDATE_PATRON(
        { password },
        setConfig(patron.token),
      );
      if (onSuccess) onSuccess(data);
    } catch (error) {
      const e = axios.handleError(error);
      actions.setStatus(e.data);
    } finally {
      actions.setSubmitting(false);
    }
  };
  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        password: "",
        passwordConfirm: "",
      }}
      validationSchema={Schemas.UpdatePasswordSchema}
      initialStatus={message}
      onSubmit={verifyEmail}
    >
      {({ errors, values, status, setFieldValue, isSubmitting }) => (
        <View
          type={style.views.background}
          className={styles.form}
          Component={Form}
        >
          <div className={classnames(styles["fields-container"], "fs-mask")}>
            <PasswordPreview
              data-pl="password-field"
              label={Copy.FORGOT_PASSWORD_STATIC.INPUT_LABEL}
              name={Copy.FORGOT_PASSWORD_STATIC.PASSWORD_INPUT_NAME}
              placeholder={Copy.FORGOT_PASSWORD_STATIC.INPUT_PLACEHOLDER}
              errors={errors.password}
              values={values.password}
              type={style.inputs.secondary}
              onChange={(e) => setFieldValue("password", e.target.value)}
            />

            <br />

            <PasswordPreview
              data-pl="confirm-password-field"
              label={Copy.FORGOT_PASSWORD_STATIC.CONFIRM_INPUT_LABEL}
              name={Copy.FORGOT_PASSWORD_STATIC.PASSWORD_INPUT_NAME}
              placeholder="Enter Password Here"
              errors={errors.passwordConfirm}
              values={values.passwordConfirm}
              type={style.inputs.secondary}
              onChange={(e) => setFieldValue("passwordConfirm", e.target.value)}
            />
            <div>
              {status && <Text type={style.labels.error}>{status}</Text>}
            </div>
          </div>
          <DrawerButton
            buttonProps={{ htmlType: "submit", disabled: isSubmitting }}
          >
            {Copy.FORGOT_PASSWORD_STATIC.UPDATE_PASSWORD_BUTTON_TEXT}
          </DrawerButton>
        </View>
      )}
    </Formik>
  );
};

export default withTemplate(UpdatePasswordForm, "password");
