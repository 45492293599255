import { useQuery } from "react-query";
import { useOrderContext } from "components/providers/Order/OrderContext";
import { mapCheckPriceOrder } from "pages/Order/utils";
import { post } from "utils/api";

/**
 * useCheckPriceQuery returns an useQueryResult object with value returned by /order/check-price
 * it will trigger the query again when the giftCard.balance is updated
 *
 * @author Shuai Wang
 * @returns {UseQueryResult} - Returns UseQueryResult defined by react-query { data, error, isError, isIdle, isLoading,isLoadingError,isRefetchError,isSuccess,status
 *
 */

const useCheckPriceQuery = () => {
  const orderContext = useOrderContext();
  const { giftCard } = orderContext.order;
  const locationId = orderContext.location.id;

  const checkPriceQuery = useQuery(
    ["checkPrice", giftCard.balance],
    async () => {
      const mappedCheckPriceBody = mapCheckPriceOrder(orderContext);

      const res = await post("/order/check-price", mappedCheckPriceBody, {
        headers: {
          locationId,
        },
      }).catch((err) => {
        // 400-level errors have useful error messages.
        const message = err.response?.data.message;
        if (message) {
          throw new Error(message);
        }
        // TODO: There should be a global "generic error" message
        throw new Error("Something went wrong");
      });

      return res.data;
    },
  );

  return checkPriceQuery;
};

export default useCheckPriceQuery;
