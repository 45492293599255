import _ from "lodash";
import templateParsers from "components/providers/Theme/templateParsers";
import type { ThemeFile, Input } from "../../../@types/theme";

export type TemplateContextVals = {
  style: string;
  parsedTheme: {
    buttons: Record<string, unknown>;
    labels: Record<string, unknown>;
    views: Record<string, unknown>;
    inputs: {
      [key: string]: Input;
    };
  };
  theme: ThemeFile;
};

export const parseTheme = ({
  fonts,
  colors,
  elements,
}: {
  fonts: ThemeFile["fonts"];
  colors: ThemeFile["colors"];
  elements: ThemeFile["elements"];
}) => {
  const views = templateParsers.viewToCSS(elements.views, { colors, fonts });
  const labels = templateParsers.labelToCSS(elements.labels, {
    colors,
    elements,
    fonts,
  });

  const buttons = templateParsers.buttonToCSS(elements.buttons, views, labels, {
    colors,
    fonts,
  });

  return {
    buttons,
    labels,
    views,
    inputs: elements.inputs,
  };
};

export const getThemeData = (themeObj: ThemeFile) => {
  const data = _.cloneDeep(themeObj);
  Object.keys(data.colors).forEach((color) => {
    data.colors[color] = `#${data.colors[color]}`;
  });
  return data;
};
