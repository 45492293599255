import * as React from "react";
import { Tabs2 } from "components/fragments";
import { Text } from "components/elementsThemed";

import Option from "./option";

const { Tabs, Tab } = Tabs2;

const findError = (errors, id) => {
  const match = errors.find((i) => i.optionId === id);
  if (match) {
    return match.message;
  }
  return null;
};

const Type4 = ({
  tabs,
  segment: { stateBackgroundColors, stateTextStyles },
  cells,
  errors,
  addModifier,
  removeModifier,
  menuOptions,
  modifications,
}) => {
  const [activeKey, setActiveKey] = React.useState(tabs?.[0]?.name);
  return (
    <Tabs
      activeKey={activeKey}
      onTabChange={setActiveKey}
      tabBackgrounds={{
        active: stateBackgroundColors.selected,
        default: stateBackgroundColors.unselected,
      }}
    >
      {tabs.map((i) => {
        const type =
          activeKey === i.name
            ? stateTextStyles.selected
            : stateTextStyles.unselected;
        return (
          <Tab key={i.name} tab={<Text type={type}>{i.name}</Text>}>
            {i.entities.map((option) => (
              <Option
                key={option.id}
                type={cells.group}
                optionItemType={cells.primary}
                {...menuOptions[option.id]}
                error={findError(errors, option.id)}
                selectedMods={modifications[option.id] || []}
                addModifier={addModifier}
                removeModifier={removeModifier}
              />
            ))}
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default Type4;
