import * as React from "react";
import { useTemplateContext } from "components/providers/Template";

const Cell = ({ render, children, type }) => {
  const {
    theme: {
      elements: { cells },
    },
  } = useTemplateContext();
  const cellType = { ...cells[type] };

  if (cellType.views === null) {
    cellType.views = {};
  }

  if (typeof render === "function") {
    return render({ ...cellType });
  }

  return typeof children === "function"
    ? React.Children.only(children({ ...cellType }))
    : React.Children.only(
        React.cloneElement(children, { ...cellType, "data-value": type }),
      );
};

export default Cell;
