import * as React from "react";
import Type1 from "./Type1";

/**
 * WalletBased's Rewards section selector for future flexibility
 *
 * @author Htin Linn Aung
 * @memberof Templates.Templates/Loyalty
 * @param {object} props
 * @param {object} props.loyaltyData - fetched data from Loyalty.jsx
 */
const Rewards = ({ loyaltyData, ...props }) => {
  const Component = Type1;

  return <Component loyaltyData={loyaltyData} {...props} />;
};

export default Rewards;
