import * as React from "react";
import { Image } from "components/elements";
import { View, Text } from "components/elementsThemed";
import { useCell } from "hooks";

import { Copy, images } from "utils";

import css from "./loyaltyHeader.module.scss";

const loyaltyHeader = images?.signup_loyalty_header;

/**
 * Return a loyalty header with the attributes set
 *
 * @param {object} props
 * @param {string} props.type - Cell type from theme file
 * @param {string} props.center - whether to center text or not
 */
const LoyaltyHeader = ({ type, center = true }) => {
  const { views, labelTextStyles } = useCell(type);

  return (
    <View type={views.background} className={css.loyaltyHeader}>
      {loyaltyHeader && (
        <Image
          className={css["loyaltyHeader-image"]}
          src={loyaltyHeader}
          alt={
            process.env.CLIENT === "bareburger" ? "" : "Sign up loyalty header"
          }
        />
      )}
      <View
        type={views.background}
        className={css["loyaltyHeader-description"]}
      >
        <Text
          className={center && css["loyaltyHeader-description-text"]}
          type={labelTextStyles.primary}
          dangerouslySetInnerHTML={{
            __html: Copy.LOGIN_SIGNUP_STATIC.HEADER.replace("\n", "</br>"),
          }}
        />
        <Text
          className={center && css["loyaltyHeader-description-text"]}
          type={labelTextStyles.secondary}
          dangerouslySetInnerHTML={{
            __html: Copy.LOGIN_SIGNUP_STATIC.SUBHEADER.replace("\n", "</br>"),
          }}
        />
      </View>
    </View>
  );
};
export default LoyaltyHeader;
