/*
 * Delivery Notes Field
 * Author: Isaiah Sanchez <isanchez@lunchbox.io>
 *
 * This component is rendered by pages/Cart.
 * This component renders a delivery notes textfield.
 */

import * as React from "react";
import { Copy } from "utils";
import Textarea from "components/elementsThemed/Field/Textarea/textarea";

export interface DeliveryNotesProps {
  isDelivery: boolean;
  type: string;
  value: string;
  onChange: (v: string) => void;
}

const DeliveryNotes = React.memo<DeliveryNotesProps>(
  ({ isDelivery, ...props }) => {
    // Constants
    const label = isDelivery
      ? Copy.CART_STATIC.DELIVERY_ORDER_NOTES_LABEL
      : Copy.CART_STATIC.PICKUP_ORDER_NOTES_LABEL;
    const placeholder = isDelivery
      ? Copy.CART_STATIC.DELIVERY_ORDER_NOTES_PLACEHOLDER
      : Copy.CART_STATIC.PICKUP_ORDER_NOTES_PLACEHOLDER;

    return (
      <Textarea
        label={label}
        placeholder={placeholder}
        resizable={false}
        rows={4}
        {...props}
      />
    );
  },
);

export default DeliveryNotes;
