import * as React from "react";
import { Formik, Form } from "formik";

import { ElementsThemed, HOCs } from "components";
import { Schemas, constants, Copy } from "utils";
import { VALIDATE_DISCOUNT, HANDLE_ERROR } from "utils/api";
import DrawerButton from "../../../components/DrawerButton";
import { mapItems, mapDeliveryInfo } from "../utils";

import styles from "./index.module.scss";
import commonStyles from "../../index.module.scss";

const {
  View,
  Text,
  Field: { Input },
} = ElementsThemed;

const { withTemplate } = HOCs;

const {
  ORDER_TYPES: { DELIVERY },
} = constants;

function Discount({
  style: { views, inputs, cells, labels },
  onSuccess,
  items,
  order,
  scheduledAt,
  orderType,
}) {
  const inputRef = React.useRef();
  const validateCode = async ({ code }, actions) => {
    try {
      const { data } = await VALIDATE_DISCOUNT({
        code,
        items: mapItems(items),
        ...(orderType === DELIVERY && {
          deliveryInfo: mapDeliveryInfo(order),
        }),
        orderType,
        scheduledAt,
      });
      if (data.discountId) {
        onSuccess({
          code,
          discount: data.discountId,
          promotionCodeId: data.promotionCodeId,
          promotionId: data.promotionId,
        });
      }
      actions.setStatus(data.message);
    } catch (error) {
      const e = HANDLE_ERROR(error);
      actions.setStatus(e.data);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        code: "",
      }}
      validationSchema={Schemas.DiscountSchema}
      validateOnChange={false}
      onSubmit={validateCode}
      render={({
        errors,
        values,
        status,
        setFieldValue,
        isSubmitting,
        setStatus,
      }) => (
        <View
          type={views.background}
          className={commonStyles.container}
          Component={Form}
        >
          <div className={styles["fields-container"]}>
            <Input
              inputRef={inputRef}
              label={Copy.CHECKOUT_STATIC.DISCOUNT_PROMO_CODE_INPUT_LABEL}
              name="code"
              type={inputs.standard}
              error={errors.code}
              value={values.code}
              placeholder={
                Copy.CHECKOUT_STATIC.DISCOUNT_PROMO_CODE_INPUT_PLACEHOLDER
              }
              onChange={(e) => {
                setStatus("");
                setFieldValue("code", e.target.value);
              }}
            />
            {status && <Text type={labels.error}>{status}</Text>}
          </div>
          <DrawerButton
            buttonProps={{
              htmlType: "submit",
              disabled: isSubmitting,
            }}
          >
            {Copy.CHECKOUT_STATIC.DISCOUNT_APPLY_BUTTON_TEXT}
          </DrawerButton>
        </View>
      )}
    />
  );
}

export default withTemplate(Discount, "discount");
