import { constants } from "utils";

const {
  ORDER_TYPES: { KIOSK },
} = constants;

export const getElementTopOffsets = (nodes) => {
  return Object.entries(nodes).map(([key, value]) => {
    return {
      id: key,
      offsetBottom: value?.offsetTop + value?.offsetHeight,
      offsetTop: value?.offsetTop,
    };
  });
};

export const getInfoType = (diningOption, isMarketPlace) => {
  let infoType = "location";
  if (diningOption === KIOSK) {
    infoType = "table";
  }
  if (isMarketPlace) {
    infoType = "market";
  }
  return infoType;
};

export const getGroupIdByItemId = (itemId, groupArr) => {
  const group = groupArr.find((group) => {
    const ItemsIds = group.items.map((item) => item.id);
    return ItemsIds.includes(itemId);
  });
  return group?.id;
};
