import * as React from "react";
import { useId } from "@react-aria/utils";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import classnames from "classnames";

import { constants, Copy, config } from "utils";
import withTemplate from "components/hocs/withTemplate";
import View from "../../../../components/elementsThemed/View/View";
import Text from "../../../../components/elementsThemed/Text/text";
import DrawerButton from "../../../../components/DrawerButton";
import Flex from "../../../../components/elements/Layout/flex";

import css from "./complete.module.scss";

import type { CompleteProps } from "../complete";

const {
  ORDER_TYPES: { PICKUP, DELIVERY, KIOSK },
} = constants;

function Complete({
  style,
  onSuccess,
  ticketInformation,
  date,
  time,
  scheduleTime,
  eta,
}: CompleteProps) {
  const { views, labels } = style;

  const textId = useId();

  const text = ticketInformation.scheduledAt
    ? Copy.CHECKOUT_STATIC.COMPLETE_TYPE3_TICKET_INFORMATION_SCHEDULE_MESSAGE
    : Copy.CHECKOUT_STATIC.COMPLETE_TYPE3_TICKET_INFORMATION_PREPARING_MESSAGE;

  let info = null;

  if (ticketInformation.scheduledAt) {
    info = (
      <>
        <Col xs="1">
          <Text type={labels.tertiary}>Schedule Date:</Text>
        </Col>
        <Col xs="1">
          <Text type={labels.tertiary}>{scheduleTime}</Text>
        </Col>
      </>
    );
  } else if (eta) {
    info = (
      <>
        <Col xs="1">
          <Text type={labels.tertiary}>
            {Copy.CHECKOUT_STATIC.COMPLETE_PREP_TIME_MESSAGE}
          </Text>
        </Col>
        <Col xs="1">
          <Text type={labels.tertiary}>{eta}</Text>
          {ticketInformation.scheduledAt && (
            <Text type={labels.tertiary}>{scheduleTime}</Text>
          )}
        </Col>
      </>
    );
  }

  let fromTitle = null;
  switch (ticketInformation.orderType) {
    case PICKUP:
      fromTitle = Copy.CHECKOUT_STATIC.COMPLETE_PICKUP_FROM_TEXT;
      break;
    case DELIVERY:
      fromTitle = Copy.CHECKOUT_STATIC.COMPLETE_DELIVERING_FROM_TEXT;
      break;
    case KIOSK:
      fromTitle = Copy.CHECKOUT_STATIC.COMPLETE_DINING_AT_TEXT;
      break;
    default:
      fromTitle = "";
      break;
  }

  return (
    // "purchase-confirmation" is gtm Ecommerce-anchor, please fo not remove
    <View type={views.background} grow={1} Component={Flex}>
      <div className={classnames(css.content, "purchase-confirmation")}>
        <View
          type={views.secondary}
          Component={Flex}
          grow={0}
          align="center"
          className={css.information}
        >
          <div className={css.title}>
            <Text type={labels.primary}>
              {Copy.CHECKOUT_STATIC.COMPLETE_TYPE3_ORDER_INFORMATION_MESSAGE}
              {text
                .split("\n")
                .map((i, k) => (k === 0 ? i : <div key={textId}>{i}</div>))}
            </Text>
          </div>
          <div className={css.info}>
            <Row spacing={20} />
            <Row spacing={20}>
              <Col xs="1">
                <Text type={labels.tertiary}>{fromTitle}</Text>
              </Col>
            </Row>
            <Row spacing={20}>
              <Col xs="1">
                <Text type={labels.secondary}>
                  {ticketInformation.location.name}
                </Text>
              </Col>
            </Row>
            <View type={views.tertiary} className={css.divider} />
            <Row spacing={20}>
              <Col xs="1-3">
                <Text type={labels.tertiary}>{date}</Text>
              </Col>
              <Col xs="1-3">
                <Text type={labels.tertiary}>
                  Check #{ticketInformation.displayNumber}
                </Text>
              </Col>
              <Col xs="1-3">
                <Text type={labels.tertiary}>{time}</Text>
              </Col>
            </Row>
            <View type={views.tertiary} className={css.divider} />
            {info}
          </div>
        </View>
      </div>
      <div>
        <DrawerButton buttonProps={{ onClick: onSuccess }}>
          {Copy.CHECKOUT_STATIC.COMPLETE_BACK_TO_MENU_BUTTON_TEXT}
        </DrawerButton>
      </div>
    </View>
  );
}

export default withTemplate(Complete, "confirmation");
