import * as React from "react";

export const DEFAULT_CHECKOUT_CONTEXT = {
  paymentRequest: null,
  usePaymentRequest: false,
};

const CheckoutContext = React.createContext(DEFAULT_CHECKOUT_CONTEXT);

const useCheckout = () => {
  const contextValues = React.useContext(CheckoutContext);
  if (!contextValues) {
    throw new Error(
      "useCheckout is being accessed outside a CheckoutContextProvider",
    );
  }
  return contextValues;
};

export { CheckoutContext, useCheckout };
