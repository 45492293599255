import * as React from "react";
import loadable from "@loadable/component";
import { config } from "utils";

const AsyncPage = loadable((props) => import(`./${props.page}`));

const LoginSignupFlowSelector = (props) => {
  return (
    <AsyncPage
      page={config?.theme?.login_signup?.version || "Type1"}
      {...props}
    />
  );
};

export default LoginSignupFlowSelector;
