import * as React from "react";
import { Formik, Form } from "formik";
import { Copy, config } from "utils";
import { FocusScope } from "@react-aria/focus";
import { VisuallyHidden } from "@react-aria/visually-hidden";

import { parseAndReplaceDigitInString, getValue } from "utils/helpers/string";
import { PATRON_SIGN_UP, PATRON_OLO_SIGN_UP } from "utils/api";

import { axios, Schemas, constants } from "../../../utils";
import { Condition, InlineLoader } from "../../elements";
import DrawerButton from "../../DrawerButton";
import { Field, Text, View, Button } from "../../elementsThemed";
import { withTemplate } from "../../hocs";
import { PasswordPreview } from "../passwordPreview";

import styles from "../form.module.scss";

const { Input } = Field;
const { Condition: If } = Condition;
const {
  SOCIAL_PLATFORMS: { FACEBOOK, GOOGLE, APPLE },
} = constants;

const determineSchema = (type) => {
  switch (type) {
    case FACEBOOK:
    case GOOGLE:
    case APPLE:
      return false;
    default:
      return true;
  }
};

const isEmailDisabled = (type) => {
  switch (type) {
    case FACEBOOK:
    case GOOGLE:
    case APPLE:
      return true;
    default:
      return false;
  }
};

const Signup = ({
  onSuccess,
  style,
  patron,
  isCart,
  onGuestCheckout,
  location: { state },
  trackerPath,
}) => {
  const passwordRequired = determineSchema(patron.type);
  const signUp = async (values, actions) => {
    actions.setStatus("");
    const data = { ...values };
    if (!passwordRequired) data.password = undefined;
    data.externalUserId = patron.authToken;
    data.type = patron.type;

    try {
      const res = config.isOlo
        ? await PATRON_OLO_SIGN_UP(data)
        : await PATRON_SIGN_UP(data);
      if (onSuccess) {
        onSuccess(res.data);
      }
      if (config.isOlo && isCart) return onGuestCheckout();
    } catch (error) {
      const e = axios.handleError(error);
      actions.setStatus(e.data);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const trackerClassName = `${trackerPath}-signup`;

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        email: state?.email ?? patron.email ?? "",
        firstName: patron.firstName ?? "",
        lastName: patron.lastName ?? "",
        password: "",
        phone: "",
        birthday: undefined,
      }}
      validationSchema={
        passwordRequired ? Schemas.SignUpSchema : Schemas.SignUpSocialSchema
      }
      onSubmit={signUp}
      render={({ errors, values, ...formProps }) => (
        <View
          data-pl="signup-form"
          type={style.views.background}
          className={styles.form}
          Component={Form}
        >
          <div className={styles["fields-container"]}>
            <FocusScope focusFirst autoFocus>
              <Input
                label={Copy.SIGN_UP_STATIC.FIRST_NAME_LABEL}
                name="firstName"
                type={style.inputs.standard}
                error={errors.firstName}
                value={values.firstName}
                placeholder={Copy.SIGN_UP_STATIC.FIRST_NAME_PLACEHOLDER}
                onChange={(e) =>
                  formProps.setFieldValue(
                    "firstName",
                    parseAndReplaceDigitInString(getValue(e)),
                  )
                }
              />

              <Input
                label={Copy.SIGN_UP_STATIC.LAST_NAME_LABEL}
                name="lastName"
                type={style.inputs.standard}
                error={errors.lastName}
                value={values.lastName}
                placeholder={Copy.SIGN_UP_STATIC.LAST_NAME_PLACEHOLDER}
                onChange={(e) =>
                  formProps.setFieldValue(
                    "lastName",
                    parseAndReplaceDigitInString(getValue(e)),
                  )
                }
              />

              <Input
                label={Copy.SIGN_UP_STATIC.EMAIL_LABEL}
                name="email"
                type={style.inputs.standard}
                error={errors.email}
                value={values.email}
                disabled={isEmailDisabled(patron.type)}
                placeholder={Copy.SIGN_UP_STATIC.EMAIL_PLACEHOLDER}
                onChange={(e) => {
                  if (isEmailDisabled(patron.type)) return;
                  formProps.setFieldValue("email", e.target.value);
                }}
                inputMode="email"
              />

              <Input
                label={Copy.SIGN_UP_STATIC.PHONE_LABEL}
                name="phone"
                type={style.inputs.standard}
                error={errors.phone}
                value={values.phone}
                placeholder={Copy.SIGN_UP_STATIC.PHONE_PLACEHOLDER}
                onChange={(e) =>
                  formProps.setFieldValue("phone", e.target.value)
                }
                inputMode="tel"
              />

              <If is={passwordRequired}>
                <PasswordPreview
                  type={style.inputs.standard}
                  label={Copy.SIGN_UP_STATIC.PASSWORD_LABEL}
                  name="password"
                  errors={errors.password}
                  values={values.password}
                  placeholder={Copy.SIGN_UP_STATIC.PASSWORD_PLACEHOLDER}
                  onChange={(e) =>
                    formProps.setFieldValue("password", e.target.value)
                  }
                />
              </If>

              <Input
                label={Copy.SIGN_UP_STATIC.BIRTHDAY_LABEL}
                name="birthday"
                type={style.inputs.standard}
                error={errors.birthday}
                value={values.birthday}
                onChange={(e) =>
                  formProps.setFieldValue("birthday", e.target.value)
                }
                htmlType="date"
              />
            </FocusScope>
            <div aria-live="polite">
              {formProps.status && (
                <Text type={style.labels.error}>
                  <VisuallyHidden>Error: </VisuallyHidden>
                  {formProps.status}
                </Text>
              )}
            </div>
          </div>
          <div className={styles.terms}>
            <Text type={style.labels.terms}>
              By registering, you agree to Lunchbox's
              <br />
              <Button
                type={style.buttons.link}
                Component="a"
                href="https://www.lunchbox.io/terms-privacy"
                target="_blank"
              >
                Terms of Service & Privacy Policy
              </Button>
            </Text>
          </div>
          <DrawerButton
            buttonProps={{
              htmlType: "submit",
              disabled: formProps.isSubmitting,
              className: trackerClassName,
            }}
          >
            {formProps.isSubmitting ? (
              <InlineLoader size={24} />
            ) : (
              Copy.SIGN_UP_STATIC.SIGN_UP_BUTTON_TEXT
            )}
          </DrawerButton>
        </View>
      )}
    />
  );
};

export default withTemplate(Signup, "signin");
