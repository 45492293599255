import * as React from "react";
import { Row } from "@lunchboxinc/lunchbox-components";

import { Text } from "../../../../components/elementsThemed/Text";
import css from "./completeType7.module.scss";

interface AddressDetailProps {
  labels: {
    tertiary?: string;
    primary?: string;
  };
  titleText: string;
  orderTypeText: string;
}

function AddressDetail({
  labels,
  titleText,
  orderTypeText,
}: AddressDetailProps) {
  return (
    <div className={css["complete-content-title"]}>
      <Text type={labels.tertiary}>{titleText}</Text>
      <Row>
        <Text type={labels.primary}>{orderTypeText}</Text>
      </Row>
    </div>
  );
}

export default AddressDetail;
