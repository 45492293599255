/**
 * New drawer context for improved state management across application.
 * You should never have more than 1 drawer rendered at one time.
 * Use routing within a drawer for navigation.
 *
 * Example use:
 * { toggleDrawer } = useDrawers()
 * handleClick = (e: React.MouseEvent) => toggleDrawer({ ..., children: Component })
 */
import * as React from "react";
import { useHistory } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useOverlayTriggerState } from "@react-stately/overlays";
import {
  OverlayProvider,
  OverlayContainer,
  useOverlay,
} from "@react-aria/overlays";
import { useButton } from "@react-aria/button";
import { useDialog } from "@react-aria/dialog";

import Drawer from "../components/Drawer";
import type { DrawerProps } from "../components/Drawer";

interface DrawersContext {
  closeButtonProps: React.ButtonHTMLAttributes<HTMLButtonElement>;
  closeDrawer: () => void;
  drawerRef: React.MutableRefObject<HTMLDivElement>;
  drawerState: DrawerProps;
  isOpen: boolean;
  navigate(destination: string): void;
  openButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  openDrawer: (state: DrawerProps) => void;
  toggleDrawer: (state: DrawerProps) => void;
}

const DrawersContext = React.createContext<DrawersContext>({
  closeButtonProps: {},
  closeDrawer: () => null,
  drawerRef: { current: null },
  drawerState: {},
  isOpen: false,
  navigate: () => null,
  openButtonProps: {},
  openDrawer: () => null,
  toggleDrawer: () => null,
});

export function DrawersProvider({ children }: { children?: React.ReactNode }) {
  const history = useHistory();
  const drawerRef = React.useRef<HTMLDivElement>(null);
  // Application-wide Drawer State
  const [drawerState, setDrawerState] = React.useState<DrawerProps>({});

  /**
   * Visibility Management via React Aria
   */
  const visibilityState = useOverlayTriggerState({});
  const openButtonRef = React.useRef();
  const closeButtonRef = React.useRef();

  const { buttonProps: openButtonProps } = useButton(
    {
      onPress: () => visibilityState.open(),
    },
    openButtonRef,
  );

  const { buttonProps: closeButtonProps } = useButton(
    {
      onPress: () => visibilityState.close(),
    },
    closeButtonRef,
  );

  // Create a new drawer updating existing state with drawer props
  const openDrawer = (state: DrawerProps) => {
    visibilityState.open();
    setDrawerState(state);
  };

  // Destroy current existing drawer state
  const closeDrawer = (event?: React.MouseEvent) => {
    visibilityState.close();

    if (typeof drawerState?.onClose === "function") {
      drawerState.onClose(event);
    }
  };

  // Either create a new drawer or destroy the existing one
  const toggleDrawer = (state: DrawerProps) =>
    visibilityState.isOpen ? closeDrawer() : openDrawer(state);

  // Helper to automatically close drawer and push to other page
  // Ideally this would exist in another context that handles routing
  const navigate = (destination: string) => {
    closeDrawer();
    history.push(destination);
  };

  const value = React.useMemo(
    () => ({
      closeButtonProps: { ...closeButtonProps, ref: closeButtonRef },
      closeDrawer,
      drawerRef,
      drawerState,
      isOpen: visibilityState.isOpen,
      navigate,
      openButtonProps: { ...openButtonProps, ref: openButtonRef },
      openDrawer,
      toggleDrawer,
    }),
    [drawerRef, visibilityState, drawerState, openButtonRef, closeButtonRef],
  );

  return (
    <OverlayProvider>
      <DrawersContext.Provider value={value}>
        {children}
        <AnimatePresence>
          {visibilityState.isOpen && (
            <OverlayContainer>
              <Drawer
                {...{
                  closeButtonProps,
                }}
                {...drawerState}
              />
            </OverlayContainer>
          )}
        </AnimatePresence>
      </DrawersContext.Provider>
    </OverlayProvider>
  );
}

DrawersProvider.defaultProps = {
  children: null,
};

/**
 * The useDrawers hook acts as a middleware between the context and useOverlay/useDialog from React Aria.
 * If the drawer is used without the context, it will handle props and ref arguments.
 */
export function useDrawers(
  drawerProps?: Partial<DrawerProps>,
  mutableRefObject?: React.MutableRefObject<HTMLDivElement>,
) {
  const contextValues = React.useContext(DrawersContext);
  const { drawerRef, drawerState } = contextValues;

  const props = drawerState || drawerProps;
  const ref = drawerRef || mutableRefObject;

  /**
   * Accessibility Management via React Aria
   */
  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const { overlayProps, underlayProps } = useOverlay(props, ref);
  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(props, ref);

  return {
    dialogProps,
    overlayProps,
    titleProps,
    underlayProps,
    ...contextValues,
  };
}
