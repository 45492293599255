import * as React from "react";
import { Tabs2 } from "components/fragments";
import { Text } from "components/elementsThemed";
import Option from "../../Options";
import { findError } from "../../helpers";

const { Tabs, Tab } = Tabs2;

const mapOptions = (
  items,
  {
    addModifier,
    cells,
    handleScroll,
    errorRefs,
    errors,
    menuOptions,
    modifications,
    removeModifier,
  },
) =>
  items.map((option, index) => {
    return (
      <Option
        key={option.id}
        forwardRef={errorRefs.current[index]}
        type={cells.group}
        optionItemTypes={{
          default: cells?.primary,
          selected: cells?.primarySelected || cells?.primary,
        }}
        parentOption={menuOptions[option.id]}
        {...menuOptions[option.id]}
        error={findError(errors, option.id)}
        selectedMods={modifications[option.id] || []}
        addModifier={addModifier}
        removeModifier={removeModifier}
        handleScroll={handleScroll}
      />
    );
  });

const Type1 = ({
  activeKey,
  forwardProps,
  onTabChange,
  segment: { stateBackgroundColors, stateTextStyles },
  tabs,
}) => {
  return (
    <Tabs
      activeKey={activeKey}
      onTabChange={onTabChange}
      tabBackgrounds={{
        active: stateBackgroundColors.selected,
        default: stateBackgroundColors.unselected,
      }}
    >
      {tabs.map((i) => {
        const type =
          activeKey === i.name
            ? stateTextStyles.selected
            : stateTextStyles.unselected;
        return (
          <Tab key={i.name} scrolling tab={<Text type={type}>{i.name}</Text>}>
            {mapOptions(i.entities, forwardProps)}
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default Type1;
