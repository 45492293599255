import * as React from "react";
import { useId } from "@react-aria/utils";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import csx from "classnames";
import { Formik, Form } from "formik";
import { withTheme } from "styled-components";

import { usePatronContext } from "components/providers/Patron/PatronContext";
import DrawerButton from "../../../DrawerButton";
import { Empty } from "components/fragments";
import { withTemplate } from "components/hocs";
import { Text, View, Field } from "components/elementsThemed";
import { useResource, useScript } from "hooks";
import { axios, Schemas, config, Copy } from "utils";

import "./authorize.module.css";
import formStyle from "../../form.module.scss";

const { BUILD_ENV } = process.env;

const { FieldItem, Input } = Field;

const { handleError } = axios;
const { AuthorizeCC } = Schemas;

const errorMessage = {
  E_WC_05: "Please provide valid credit card number",
  E_WC_06: "Please provide valid expiration month",
  E_WC_07: "Please provide valid expiration year",
  E_WC_08: "Please provide valid expiration month and year",
  E_WC_15: "Please provide valid CVV",
  E_WC_16: "Please provide valid ZIP code",
  E_WC_19: "An error occurred during processing. Please try again",
  E_WC_20: "Please provide valid credit card number",
};

const DEFAULT_ERROR =
  "We are unable to accept payments at this time. We are working on resolving this problem, and we apologize for the inconvenience.";

const url =
  BUILD_ENV === "production"
    ? "https://js.authorize.net/v1/Accept.js"
    : "https://jstest.authorize.net/v1/Accept.js";

const ScriptHandler = (props) => {
  const { loaded, error } = useScript(url);

  if (!loaded) {
    return null;
  }

  if (error) {
    console.error("Unable to load Payment Form");
    return <></>;
  }

  return <AuthorizeThemed {...props} />;
};

const PaymentForm = ({
  style: { cells, labels, views, inputs },
  onSuccess,
  locationId,
  order,
}) => {
  const { updateCard } = usePatronContext();
  const [saving, setSaving] = React.useState(false);

  const location = localStorage !== null && localStorage.getItem("location");
  const locationParsed = JSON.parse(location);
  const isDisabled = !locationParsed.id;

  const [{ resource: locationCreds }] = useResource({
    path: "/location/credentials",
  });

  const saveCard = async (cardNonce, street1) => {
    setSaving(true);
    const res = await axios.methods.post(
      "/patron/add-card",
      { cardNonce, street1 },
      locationId
        ? {
            headers: {
              locationId,
            },
          }
        : {},
    );
    updateCard(res.data);
    if (order.resetIdempotency) order.resetIdempotency();
    setSaving(false);
    if (onSuccess) onSuccess();
  };

  const fetchNonce = (values, actions) => {
    const handleAuthorizeResponse = async (response) => {
      if (response.messages.resultCode === "Error") {
        console.log(response);
        const [{ code }] = response.messages.message;
        if (errorMessage[code]) {
          actions.setStatus(errorMessage[code]);
        } else {
          actions.setStatus(DEFAULT_ERROR);
        }
      } else {
        try {
          await saveCard(response.opaqueData.dataValue, values.street1);
        } catch (error) {
          const e = handleError(error);
          actions.setStatus(e.data);
        } finally {
          actions.setSubmitting(false);
        }
      }
    };

    const secureData = {
      authData: {
        apiLoginID: locationCreds.apiKey || config.apps.authorize.id,
        clientKey: locationCreds.publicKey || config.apps.authorize.client,
      },
      cardData: {
        cardCode: values.cvv,
        cardNumber: values.cardNumber,
        month: values.month,
        year: values.year,
        zip: values.zip,
      },
    };
    try {
      actions.setSubmitting(true);
      window.Accept.dispatchData(secureData, handleAuthorizeResponse);
    } catch (e) {
      console.log(e);
    } finally {
      actions.setSubmitting(false);
    }
  };

  React.useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  if (isDisabled) {
    return (
      <Empty imgSrc={config?.images?.art_empty}>
        <Text type={labels.primary}>
          {Copy.PAYMENT_FORM_STATIC.AUTHORIZE_NET_MISSING_LOCATION_MESSAGE}
        </Text>
      </Empty>
    );
  }

  const cardNumberId = useId();
  const cvvId = useId();
  const expirationMonthId = useId();
  const expirationYearId = useId();
  const zipcodeId = useId();
  const streetAddressId = useId();

  return (
    <Formik
      initialValues={{
        cardNumber: "",
        cvv: "",
        month: "",
        street1: "",
        year: "",
        zip: "",
      }}
      validateOnChange={false}
      onSubmit={fetchNonce}
      validationSchema={AuthorizeCC}
    >
      {({ errors, values, status, setFieldValue, isSubmitting }) => (
        <View
          id="paymentForm"
          type={views.background}
          Component={Form}
          className={formStyle.form}
        >
          <div
            className={csx(
              formStyle["fields-container"],
              "payment-form",
              "fs-mask",
            )}
          >
            <Row gutter={15}>
              <Col xs="2-3">
                <FieldItem
                  type={labels.primary}
                  label="Card Number"
                  htmlFor={cardNumberId}
                >
                  <Input
                    type={inputs.primary}
                    value={values.cardNumber}
                    error={errors.cardNumber}
                    onChange={(e) =>
                      setFieldValue("cardNumber", e.target.value)
                    }
                    name="cardNumber"
                    id={cardNumberId}
                    placeholder={
                      Copy.PAYMENT_FORM_STATIC
                        .AUTHORIZE_NET_CARD_NUMBER_PLACEHOLDER
                    }
                    autoComplete="cc-number"
                  />
                </FieldItem>
              </Col>
              <Col xs="1-3">
                <FieldItem type={labels.primary} label="CVV" htmlFor={cvvId}>
                  <Input
                    type={inputs.primary}
                    value={values.cvv}
                    error={errors.cvv}
                    onChange={(e) => setFieldValue("cvv", e.target.value)}
                    name="cvv"
                    id={cvvId}
                    placeholder={
                      Copy.PAYMENT_FORM_STATIC
                        .AUTHORIZE_NET_CARD_CVV_NUMBER_PLACEHOLDER
                    }
                    autoComplete="cc-cvv"
                  />
                </FieldItem>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col xs="1-3">
                <FieldItem
                  type={labels.primary}
                  label={
                    Copy.PAYMENT_FORM_STATIC
                      .AUTHORIZE_NET_CARD_EXPIRATION_MONTH_LABEL
                  }
                  htmlFor={expirationMonthId}
                >
                  <Input
                    type={inputs.primary}
                    value={values.month}
                    error={errors.month}
                    htmlType="number"
                    min="1"
                    max="12"
                    onChange={(e) => setFieldValue("month", e.target.value)}
                    name="month"
                    id={expirationMonthId}
                    placeholder={
                      Copy.PAYMENT_FORM_STATIC
                        .AUTHORIZE_NET_CARD_EXPIRATION_MONTH_PLACEHOLDER
                    }
                    autoComplete="cc-exp-month"
                  />
                </FieldItem>
              </Col>
              <Col xs="1-3">
                <FieldItem
                  type={labels.primary}
                  label={
                    Copy.PAYMENT_FORM_STATIC
                      .AUTHORIZE_NET_CARD_EXPIRATION_YEAR_LABEL
                  }
                  htmlFor={expirationYearId}
                >
                  <Input
                    type={inputs.primary}
                    value={values.year}
                    error={errors.year}
                    htmlType="number"
                    min={new Date().getFullYear()}
                    max={new Date().getFullYear() + 50}
                    onChange={(e) => setFieldValue("year", e.target.value)}
                    name="year"
                    id={expirationYearId}
                    placeholder={
                      Copy.PAYMENT_FORM_STATIC
                        .AUTHORIZE_NET_CARD_EXPIRATION_YEAR_PLACEHOLDER
                    }
                    autoComplete="cc-exp-year"
                  />
                </FieldItem>
              </Col>
              <Col xs="1-3">
                <FieldItem
                  type={labels.primary}
                  label={
                    Copy.PAYMENT_FORM_STATIC.AUTHORIZE_NET_CARD_ZIPCODE_LABEL
                  }
                  htmlFor={zipcodeId}
                >
                  <Input
                    type={inputs.primary}
                    value={values.zip}
                    error={errors.zip}
                    onChange={(e) => setFieldValue("zip", e.target.value)}
                    name="zip"
                    id={zipcodeId}
                    placeholder={
                      Copy.PAYMENT_FORM_STATIC
                        .AUTHORIZE_NET_CARD_ZIPCODE_PLACEHOLDER
                    }
                    autoComplete="cc-zip"
                  />
                </FieldItem>
              </Col>
            </Row>
            <Row>
              <Col>
                <FieldItem
                  type={labels.primary}
                  label={
                    Copy.PAYMENT_FORM_STATIC
                      .AUTHORIZE_NET_CARD_STREET_ADDRESS_LABEL
                  }
                  htmlFor={streetAddressId}
                >
                  <Input
                    type={inputs.primary}
                    value={values.street1}
                    error={errors.street1}
                    onChange={(e) => setFieldValue("street1", e.target.value)}
                    name="street1"
                    id={streetAddressId}
                    autoComplete="cc-street1"
                  />
                </FieldItem>
              </Col>
            </Row>

            {status && <Text type={labels.error}>{status}</Text>}
          </div>

          <DrawerButton
            buttonProps={{
              disabled: isSubmitting || saving,
              htmlType: "submit",
            }}
          >
            {saving
              ? Copy.PAYMENT_FORM_STATIC.BUTTON_TEXT_SAVING
              : Copy.PAYMENT_FORM_STATIC.PAYMENT_FORM_SAVE_BUTTON_TEXT}
          </DrawerButton>
        </View>
      )}
    </Formik>
  );
};

const AuthorizeThemed = withTheme(withTemplate(PaymentForm, "payment"));
export default ScriptHandler;
