import * as React from "react";
import { DynamicStructureType } from "@lunchboxinc/dynamic-web/dist/types";
import {
  defaultStructure as DefaultLocationCardStructure,
  defaultConfig,
  defaultStyles,
  ComponentConfig,
  ComponentStyle,
} from "@lunchboxinc/dynamic-web/dist/LocationCard";
import StructureData from "StructureData";

export type StructureContextValues = {
  locationCardStructure: DynamicStructureType;
  locationCardConfig: ComponentConfig;
  locationCardStyles: ComponentStyle;
};

const StructureContext = React.createContext<StructureContextValues>({
  locationCardConfig: defaultConfig,
  locationCardStructure: DefaultLocationCardStructure,
  locationCardStyles: defaultStyles,
});

const useStructure = () => {
  const contextValues = React.useContext(StructureContext);
  if (!contextValues) {
    throw new Error(
      "useStructure must be use within StructureContext Provider",
    );
  }
  return contextValues;
};

function StructureProvider({ children }: { children: React.ReactNode }) {
  const {
    showAddress,
    showDeliveryHours,
    showPhoneNumber,
    showPickUpHours,
    showStoreHours,
    showWaitTime,
  } = StructureData.componentConfig || defaultConfig;

  const {
    primaryButtonBackgroundColor,
    primaryButtonTextColor,
    secondaryButtonBackgroundColor,
    secondaryButtonTextColor,
    buttonStyle,
  } = StructureData.componentConfig || defaultStyles;

  const locationCardConfig = {
    showAddress,
    showDeliveryHours,
    showPhoneNumber,
    showPickUpHours,
    showStoreHours,
    showWaitTime,
  };

  const locationCardStyles = {
    primaryButtonBackgroundColor,
    primaryButtonTextColor,
    secondaryButtonBackgroundColor,
    secondaryButtonTextColor,
    buttonStyle,
  };

  const contextValues = React.useMemo(
    () => ({
      locationCardStructure:
        StructureData.LocationCard || DefaultLocationCardStructure,
      locationCardConfig,
      locationCardStyles,
    }),
    [],
  );

  return (
    <StructureContext.Provider value={contextValues}>
      {children}
    </StructureContext.Provider>
  );
}

export { useStructure, StructureContext, StructureProvider };
export default StructureProvider;
