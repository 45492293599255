import "core-js/modules/es.object.from-entries";
import { config, constants } from "utils";
import facebookPixel from "./facebookPixel";
import googleTagManager from "./googleTagManager";
import segment from "./segment";
import fullStory from "./fullStory";

const {
  ANALYTICS: {
    APPSFLYER,
    FACEBOOK_PIXEL,
    FULLSTORY,
    GOOGLE_ANALYTICS,
    GOOGLE_TAG_MANAGER,
    SEGMENT,
  },
} = constants;

// Available tools for clients to use with our platform
const availableTools = [
  APPSFLYER,
  FACEBOOK_PIXEL,
  FULLSTORY,
  GOOGLE_ANALYTICS,
  GOOGLE_TAG_MANAGER,
  SEGMENT,
];

// Check which tools are enabled from client config
const enabledTools = availableTools.filter(
  (thirdPartyApp) => !!config?.apps[thirdPartyApp],
);

const enabledToolsHash = Object.fromEntries(
  enabledTools.map((tool) => [tool, config?.apps[tool]]),
);

/**
 * Lunchbox Tools Execute Event
 * Only calls events for client enabled tools
 *
 * @param root0
 * @param root0.event
 * @param root0.payload
 */
const runEvent = ({ event, payload }) =>
  enabledTools.forEach((tool) => {
    switch (tool) {
      case FACEBOOK_PIXEL:
        return facebookPixel(event, payload);
      case GOOGLE_TAG_MANAGER:
        return googleTagManager(event, payload);
      case SEGMENT:
        return segment(event, payload);
      case FULLSTORY:
        return fullStory(event, payload);
      default:
        return null;
    }
  });

/**
 * Lunchbox Tools Event Handler
 * Execute an event after initialization or add to a window queue
 *
 * @param event
 */
const eventHandler = (event) => (payload) => {
  const data = { event, payload };

  if (!window.lbxTools) {
    window.lbxTools = {
      ...window.lbxTools,
      queue: [],
    };
    return window.lbxTools.queue.push(data);
  }

  runEvent(data);
  return {};
};

/**
 * Lunchbox Tools Initialization Event
 * Execute queued events
 */
window.addEventListener("lbxInit", () => {
  const queue = window.lbxTools?.queue || [];
  queue.forEach((data) => runEvent(data));
  window.lbxTools = {
    initialized: true,
    queue: [],
  };
});

export { enabledTools, enabledToolsHash, eventHandler };
