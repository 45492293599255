import * as React from "react";
import { useVisuallyHidden } from "@react-aria/visually-hidden";
import classnames from "classnames";

import { Image } from "../elements/Image";
import { Button } from "../elements/Button/index";

import styles from "./BackButton.module.scss";

interface BackButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement | SVGElement> {
  imgSrc?: string;
  variant?: "svg";
}

const defaultBackButton =
  "https://assets.lunchbox.io/shared/images/button_back.svg";

/**
 * Drawer Back Button (<)
 * Don't forget to use onKeyDown when variant is "svg".
 */
export default function BackButton({
  imgSrc,
  variant,
  className,
  ...props
}: BackButtonProps) {
  const classes = classnames(styles.back, className, "back-button");
  const { visuallyHiddenProps } = useVisuallyHidden();

  return variant && variant === "svg" ? (
    <svg
      role="button"
      tabIndex={-1}
      className={classes}
      stroke="#333"
      data-testid="back-button"
      {...props}
    >
      <use xlinkHref="#back" />
    </svg>
  ) : (
    <div className={styles["back-container"]} data-testid="back-button">
      <Button style={{ padding: 0 }} {...props}>
        <span {...visuallyHiddenProps}>Go back</span>
        <Image src={imgSrc ?? defaultBackButton} className={classes} alt="" />
      </Button>
    </div>
  );
}

BackButton.defaultProps = {
  imgSrc: "",
  variant: "",
};
