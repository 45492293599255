/* eslint-disable no-shadow */
import * as React from "react";
import { config, Copy } from "utils";
import { stringReplacer } from "utils/helpers/string";
import { useMenuContext } from "components/providers/Menu/MenuContext";
import { modsReducer, ModsActionType } from "../modsReducer";

/**
 * Nested modifier drawer hook. Parse properties of nested modifier drawers and return the same component with additional properties.
 * @alias     withNestedModifiers
 * @memberof! NestedModifiers
 * @type      {function}
 * @param     {ReactElement} Component - The {@link Component} from the parent nested drawer state.
 * @return    {ReactElement} The {@link Return} element (type from the selector).
 */
const withNestedModifiers = (Component) => (props) => {
  const { addModifier, onClose, parent, removeModifier, showQuantity, style } =
    props;
  const { item, option } = parent;

  const { optionsHash, itemsHash } = useMenuContext();

  const [modifications, dispatch] = React.useReducer(
    modsReducer,
    parent?.modifiers,
  );

  const [errors, setErrors] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [globalError, setGlobalError] = React.useState(null);
  const [nestedDrawer, setNestedDrawer] = React.useState({});

  const [quantity, setQuantity] = React.useState(parent?.quantity || 1);
  const increment = () => setQuantity(quantity + 1);
  const decrement = () => setQuantity(quantity > 1 ? quantity - 1 : quantity);

  const getOptionHash = () => optionsHash; // (isUpsell ? upsellsOptionsHash : optionsHash);

  const { items = [] } = optionsHash[option] ?? {};
  const { name, options } = items?.filter((i) => i?.id === item)?.[0] ?? {};
  const payload = {
    ...parent,
    isNested: true,
    modifiers: modifications,
    quantity,
  };

  /**
   * Event Handlers
   * closeDrawer(event)
   * addNestedModifier(payload)
   * removeNestedModifier(payload)
   * onSubmit()
   */
  const closeDrawer = (e) => {
    e.preventDefault();
    setNestedDrawer({});
  };
  const addNestedModifier = (payload, toggleDrawer = false) => {
    const { option } = payload;

    if (toggleDrawer) {
      return setNestedDrawer(payload);
    }

    setNestedDrawer({});

    const { max = 1, name: optionName } = getOptionHash()?.[option];
    // Remove first modification for this option user if selecting more than the max allowed

    if (modifications?.[option]?.length === max) {
      // If the RG is configured to so that the last mod item is not replace return immediately
      if (config?.not_replace_mod) {
        setGlobalError(
          stringReplacer(Copy.ITEM_DETAILS_STATIC.MAX_ITEM_SELECTED, [
            { replaceTarget: "{option}", replaceValue: optionName },
          ]),
        );
        return;
      }

      dispatch({
        payload: {
          ...payload,
          ...modifications?.[option]?.[0],
        },
        type: ModsActionType.REMOVE_MOD,
      });
    }

    setGlobalError(null);

    dispatch({
      payload,
      type: ModsActionType.ADD_MOD,
    });
  };

  const removeNestedModifier = (payload) => {
    setGlobalError(null);
    dispatch({
      payload,
      type: ModsActionType.REMOVE_MOD,
    });
  };

  /**
   * validateMods - cross check the itemHash options with the modifier state to validate quantities (min and max).
   *
   * @param {string} optionId - if the validation is nested, the option id to check
   * @param {string} modId - if the validation is nested, the item id to check
   */
  const validateMods = ({ modId } = {}) => {
    // Start with no errors
    let newErrors = [];

    const optionsHashByType = getOptionHash();
    // Validate modifier state against options from nested item id within itemsHash
    const reductionObj = itemsHash?.[modId]?.options ?? [];

    // Reduce the options from itemsHash, tabs or itemInfo
    newErrors = reductionObj.reduce((accu, i) => {
      // Find this option in the optionsHash
      const optionId = i?.id;
      const option = optionId && optionsHashByType?.[optionId];

      if (!option) return accu;

      const { max = 0, min = 0 } = option;

      // Look for this option in the modifiers state
      const currOptionModifiers = modifications?.[optionId] ?? [];
      // How many modifiers were selected
      const selectedQty = currOptionModifiers?.length;

      // If there is a minimum (greater than 0) and nothing selected in the modifiers state
      // Or if there is a minimum and the length of our selected modifiers state of this option does not meet the minimum
      if ((min && !selectedQty) || (min && selectedQty < min)) {
        // Minimum was not met for this modifier
        accu.push({
          message: stringReplacer(
            Copy.ITEM_DETAILS_STATIC.SELECTION_MIN_ERROR,
            [
              {
                replaceTarget: "{option}",
                replaceValue: min,
              },
            ],
          ),
          optionId,
          type: "option",
        });
      }

      // If there is a maximum (greater than 0) and the length of our selected modifiers state is greater than the maximum
      if (max && selectedQty > max) {
        // Too many modifiers were chosen
        accu.push({
          message: stringReplacer(
            Copy.ITEM_DETAILS_STATIC.SELECTION_MAX_ERROR,
            [
              {
                replaceTarget: "{option}",
                replaceValue: max,
              },
            ],
          ),
          optionId,
          type: "option",
        });
      }

      return accu;
    }, []);

    // Update the error state with any new errors
    setErrors(newErrors);

    // Also return the errors in case it's needed
    return newErrors;
  };

  const onSubmit = () => {
    const errorList = validateMods({
      modId: item,
    });

    if (!errorList.length) {
      // Edit an existing nested modifier
      if (parent?.quantity) {
        // For now, we replace the existing modifier
        removeModifier(payload);
      }

      addModifier(payload);
    }
  };

  const additionalProps = {
    addNestedModifier,
    closeDrawer,
    decrement,
    errors,
    increment,
    modifications,
    name,
    nestedDrawer,
    onClose,
    onSubmit,
    options,
    optionsHash,
    quantity,
    removeNestedModifier,
    showQuantity,
    style,
  };

  return <Component {...additionalProps} />;
};

export default withNestedModifiers;
