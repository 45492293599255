import * as React from "react";
import {
  combineHours,
  combineValues,
  formatTime,
  displayedHoursOverride,
} from "utils/helpers/time";
import { formatAddressArray } from "utils/helpers/geo";
import { formatPhoneNumber, stringReplacer } from "utils/helpers/string";
import { Copy } from "utils/Copy";
import { ORDER_TYPES } from "utils/constants";
import { config } from "utils/config";

const { PICKUP, DELIVERY, KIOSK } = ORDER_TYPES;
const {
  DELIVERY_TIME_TEXT,
  DINING_OPTION_DELIVERY_LABEL,
  DINING_OPTION_PICKUP_LABEL,
  ORDER_BUTTON_TEXT_DELIVERY,
  ORDER_BUTTON_TEXT_KIOSK,
  ORDER_BUTTON_TEXT_PICKUP,
  PICK_UP_TIME_TEXT,
  PREORDER_BUTTON_TEXT,
  PREP_TIME_TEXT,
  STORE_CLOSED,
  STORE_HOURS_LABEL,
} = Copy.LOCATION_LIST_STATIC;

const orderTypeButtonCopyMap = {
  [DELIVERY]: ORDER_BUTTON_TEXT_DELIVERY,
  [KIOSK]: ORDER_BUTTON_TEXT_KIOSK,
  [PICKUP]: ORDER_BUTTON_TEXT_PICKUP,
};
const orderTypeHoursLabelMap = {
  [DELIVERY]: DINING_OPTION_DELIVERY_LABEL,
  [KIOSK]: STORE_HOURS_LABEL,
  [PICKUP]: DINING_OPTION_PICKUP_LABEL,
};

const withLocationCard =
  (Component) =>
  ({
    address,
    cellType,
    diningOption,
    distanceInMiles,
    hours,
    idx,
    imageSrc,
    innerRef,
    isOpen,
    isPreorderRequired = false,
    isAcceptingPreorders,
    name,
    onEnterMenu,
    onEnterMenuScheduleLater,
    onSeeAllRestaurants,
    phone = { value: "" },
    restaurantGroups,
    serviceEstimates,
    themeType,
  }) => {
    let missingHours = [0, 1, 2, 3, 4, 5, 6];
    hours.forEach(({ day }) => {
      const idx = missingHours.findIndex((val) => val === day);
      missingHours.splice(idx, 1);
    });

    const newHours = hours.concat(
      missingHours.map((day) => ({
        day,
        deliveryOpen: undefined,
        deliveryClose: undefined,
        pickupOpen: undefined,
        pickupClose: undefined,
        dineInOpen: undefined,
        dineInClose: undefined,
      })),
    );

    const {
      delivery = [],
      kiosk = [],
      pickup = [],
    } = React.useMemo(() => (hours ? combineHours(newHours) : {}), [newHours]);

    const orderTypeHoursMap = {
      [DELIVERY]: delivery,
      [KIOSK]: kiosk,
      [PICKUP]: pickup,
    };
    const orderTypeHours = (orderType) => {
      const hoursForOrderType = displayedHoursOverride(
        orderTypeHoursMap[orderType],
        config.isOlo,
      );
      return {
        hours: hoursForOrderType,
        label: orderTypeHoursLabelMap[orderType],
      };
    };

    const formattedPhone = formatPhoneNumber(phone.value);
    const formattedDistance =
      typeof distanceInMiles === "number"
        ? `${distanceInMiles.toFixed(2)} miles away`
        : "";
    const formattedDeliveryEstimate = formatTime(
      combineValues(serviceEstimates, diningOption),
    );
    const formattedPrepTimeEstimate = formatTime(
      combineValues(serviceEstimates, diningOption),
    );
    const formattedPickupEstimate = formatTime(
      combineValues(serviceEstimates, diningOption),
    );
    const uniqueId = ["bbscrispychicken1"].includes(config.id);

    const estimateTextMap = {
      [DELIVERY]: formattedDeliveryEstimate
        ? stringReplacer(DELIVERY_TIME_TEXT, [
            {
              replaceTarget: "{deliveryEstimate}",
              replaceValue: formattedDeliveryEstimate,
            },
          ])
        : "",
      [KIOSK]:
        !uniqueId && formattedPrepTimeEstimate
          ? stringReplacer(PREP_TIME_TEXT, [
              {
                replaceTarget: "{PrepEstimate}",
                replaceValue: formattedPrepTimeEstimate,
              },
            ])
          : "",
      [PICKUP]: formattedPickupEstimate
        ? stringReplacer(PICK_UP_TIME_TEXT, [
            {
              replaceTarget: "{pickupEstimate}",
              replaceValue: formattedPickupEstimate,
            },
          ])
        : "",
    };

    const estimateText = estimateTextMap[diningOption];

    let isLocationClosedAndNotAcceptingPreorder =
      isPreorderRequired && !isAcceptingPreorders;

    const getOrderButtonCopy = () => {
      if (!isOpen || isLocationClosedAndNotAcceptingPreorder)
        return STORE_CLOSED;
      if (isPreorderRequired && isAcceptingPreorders)
        return PREORDER_BUTTON_TEXT;
      return orderTypeButtonCopyMap[diningOption];
    };

    const isLocationModalEnabled =
      config?.isEarlySchedulingEnabled && diningOption !== KIOSK;

    return (
      <Component
        address={formatAddressArray(address)}
        cellType={cellType}
        delivery={delivery}
        diningOption={diningOption}
        estimateText={estimateText}
        formattedDistance={formattedDistance}
        formattedPhone={formattedPhone}
        isLocationModalEnabled={isLocationModalEnabled}
        isPreorderRequired={isPreorderRequired}
        idx={idx}
        imageSrc={imageSrc}
        innerRef={innerRef}
        isOpen={isOpen && !isLocationClosedAndNotAcceptingPreorder}
        name={name}
        onEnterMenu={onEnterMenu}
        onEnterMenuScheduleLater={onEnterMenuScheduleLater ?? onEnterMenu}
        onSeeAllRestaurants={onSeeAllRestaurants}
        orderButtonCopy={getOrderButtonCopy()}
        orderTypeHours={orderTypeHours}
        restaurantGroups={restaurantGroups}
        themeType={themeType}
      />
    );
  };

export default withLocationCard;
