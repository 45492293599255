import * as React from "react";
import { parseISO, format } from "date-fns";
import { OrderItem as orderItemComponent } from "@lunchboxinc/lunchbox-components-v2/dist/templateComponents";

import { useResource, useCell, useTrackingInfo } from "hooks";
import { Routes, config } from "utils";
import { formatPrice } from "utils/helpers/string";
import { formatAddress } from "utils/helpers/geo";
import { Condition } from "components/elements";
import { Loader } from "components/fragments";
import { View, Text } from "components/elementsThemed";
import { TrackingMap } from "components/templates";
import withHistoryItem from "./withHistoryItem";
import { getTotalItemPrice } from "./receiptDetailHelper";
import DrawerButton from "../../../../components/DrawerButton";

import css from "./receiptDetail.module.scss";

const OrderItem = withHistoryItem(orderItemComponent);
const googleMapKey =
  config.apps.google_maps || "AIzaSyDDp__7dinJ-_hqnj9iBjFD6acYRrY46I4";
const { Condition: If } = Condition;

const getHeader = ({ deliveryInfo, location, orderType }) => {
  switch (orderType) {
    case "pickup":
      return `PICKUP FROM : ${location.name}`;
    case "delivery":
      return `DELIVERY TO: ${formatAddress(deliveryInfo)}`;
    case "dinein":
    case "kiosk":
      return `DINE IN`;
    default:
      return `ORDER`;
  }
};

const getSubHeader = ({ address, orderType }) => {
  switch (orderType) {
    case "pickup":
      return formatAddress(address);
    case "delivery":
      return ``;
    case "dinein":
    case "kiosk":
      return ``;
    default:
      return ``;
  }
};

const ReceiptDetail = ({ orderId, type, reorder }) => {
  const { labelTextStyles, views } = useCell(type.receiptDetail);

  const [{ fetching, resource: orderDetail }] = useResource({
    method: "get",
    path: Routes.FETCH_ORDER_HISTORY_DETAIL(orderId),
  });

  const {
    id,
    deliveryInfo,
    location,
    orderType,
    discountAmount,
    displayNumber,
    createdAt,
    totalPrice,
    finalAmount,
    serviceCharges,
    tipAmount,
    taxAmount,
  } = orderDetail;

  const [trackingData] = useTrackingInfo({
    id,
    init: {
      restaurant: {
        latitude: orderDetail?.location?.address?.geo?.lat,
        longitude: orderDetail?.location?.address?.geo?.long,
      },
    },
    orderType: orderDetail.orderType,
  });

  const parsedServiceCharges = serviceCharges?.length
    ? serviceCharges
        .map(({ amount }) => amount)
        .reduce((prev, cur) => prev + cur)
    : serviceCharges;

  return (
    <div className={css.receiptDetail}>
      {fetching && <Loader />}

      {orderDetail.items && (
        <div className={css["receiptDetail-content"]}>
          <div className={css["receiptDetail-title"]}>
            <Text
              type={labelTextStyles.primary}
              className={css["receiptDetail-title-header"]}
            >
              {getHeader({ deliveryInfo, location, orderType })}
            </Text>
            <Text
              type={labelTextStyles.secondary}
              className={css["receiptDetail-title-subheader"]}
            >
              {getSubHeader({ address: location.address, orderType })}
            </Text>
            <View
              type={views.secondary}
              Component="div"
              className={css["receiptDetail-title-detail"]}
            >
              <Text
                type={labelTextStyles.tertiary}
                className={css["receiptDetail-title-detail-date"]}
              >
                {format(parseISO(createdAt), "MM/dd/yyyy")}
              </Text>
              <Text
                type={labelTextStyles.tertiary}
                className={css["receiptDetail-title-detail-check"]}
              >
                {`Check #...${displayNumber.slice(-4)}`}
              </Text>
              <Text
                type={labelTextStyles.tertiary}
                className={css["receiptDetail-title-detail-time"]}
              >
                {format(parseISO(createdAt), "HH:mm")}
              </Text>
            </View>
          </div>

          <If is={trackingData?.restaurant?.latitude}>
            <div className={css["receiptDetail-map"]}>
              {trackingData?.estimatedDropoffTime && (
                <div className={css["receiptDetail-map-info"]}>
                  <Text
                    type={labelTextStyles.secondary}
                    className={css["receiptDetail-title-detail-time"]}
                  >
                    {`Status : ${trackingData?.status}`}
                  </Text>

                  <Text
                    type={labelTextStyles.secondary}
                    className={css["receiptDetail-title-detail-time"]}
                  >
                    {`ETD : ${format(
                      parseISO(trackingData?.estimatedDropoffTime),
                      "HH:mm",
                    )}`}
                  </Text>
                </div>
              )}
              <TrackingMap
                googleMapURL={Routes.GOOGLE_MAP(googleMapKey)}
                googleMapKey={googleMapKey}
                loadingElement={<div style={{ height: "100%" }} />}
                containerElement={<div style={{ height: "100%" }} />}
                mapElement={<div style={{ height: "100%" }} />}
                origin={trackingData?.restaurant}
                destination={trackingData?.patron}
              />
            </div>
          </If>

          <div className={css["receiptDetail-items"]}>
            {orderDetail.items.map((i, index) => (
              <OrderItem
                itemType="historyItem"
                // eslint-disable-next-line
                key={`${i.item}${index}`}
                cellType={type.historyCard}
                item={i.item}
                mods={i.modifications}
                group={i.group}
                price={getTotalItemPrice(i)}
                name={i.name}
                notes={i.notes}
                description={i.description}
              />
            ))}
          </div>
          <div className={css["receiptDetail-summary"]}>
            <div className={css["receiptDetail-summary-title"]}>
              <Text type={labelTextStyles.quaternary}>Order Summary</Text>
            </div>

            <div className={css["receiptDetail-summary-detail"]}>
              <div className={css["receiptDetail-summary-detail-content"]}>
                <Text type={labelTextStyles.tertiary}>Subtotal</Text>
                <Text type={labelTextStyles.tertiary}>
                  {formatPrice(totalPrice)}
                </Text>
              </div>
              <If is={discountAmount}>
                <div className={css["receiptDetail-summary-detail-content"]}>
                  <Text type={labelTextStyles.tertiary}>Promo</Text>
                  <Text type={labelTextStyles.tertiary}>
                    {formatPrice(discountAmount)}
                  </Text>
                </div>
              </If>
              <If is={parsedServiceCharges > 0}>
                <div className={css["receiptDetail-summary-detail-content"]}>
                  <Text type={labelTextStyles.tertiary}>Service Fee</Text>
                  <Text type={labelTextStyles.tertiary}>
                    {formatPrice(parsedServiceCharges)}
                  </Text>
                </div>
              </If>
              <div className={css["receiptDetail-summary-detail-content"]}>
                <Text type={labelTextStyles.tertiary}>Tip</Text>
                <Text type={labelTextStyles.tertiary}>
                  {formatPrice(tipAmount)}
                </Text>
              </div>
              <div className={css["receiptDetail-summary-detail-content"]}>
                <Text type={labelTextStyles.tertiary}>Tax</Text>
                <Text type={labelTextStyles.tertiary}>
                  {formatPrice(taxAmount)}
                </Text>
              </div>
              <div className={css["receiptDetail-summary-detail-content"]}>
                <Text type={labelTextStyles.tertiary}>Total</Text>
                <Text type={labelTextStyles.tertiary}>
                  {formatPrice(finalAmount)}
                </Text>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={css["receiptDetail-footerButton"]}>
        <DrawerButton
          buttonProps={{
            onClick: () =>
              reorder({
                deliveryAddress: orderDetail.deliveryInfo,
                items: orderDetail.items,
                orderType: orderDetail.orderType,
                pickUpLocation: orderDetail.location,
              }),
          }}
        >
          Reorder
        </DrawerButton>
      </div>
    </div>
  );
};

export default ReceiptDetail;
