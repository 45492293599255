import * as React from "react";
import { isEmpty } from "lodash";
import { config } from "utils";
import { useMenuContext } from "components/providers/Menu/MenuContext";

/**
 * @typedef   {object}    props
 * @property  {string}    [className] - additional classes for parent container
 * @property  {boolean}   [isOptionalSingle] - if this modifier item is maximum of 1 and is not required
 * @property  {object}    item - itemsHash data from menu context
 * @property  {boolean}   [multiSelect] - if this modifier item has a maximum greater than 1
 * @property  {function}  removeModifier - remove a modifier payload from parent state
 * @property  {function}  addModifier - add a modifier payload to parent state
 * @property  {object}    parent - parent payload (option and item with selected nested modifiers)
 * @property  {number}    [quantity] - current modifier quantity in parent state
 * @property  {string}    type - style cell type from theme
 */

const mapNestedItems = (modsState) => {
  const { itemsHash } = useMenuContext();

  // eslint-disable-next-line no-unused-vars
  return Object.entries(modsState).reduce((accu, [id, items]) => {
    if (!items?.length) {
      return accu;
    }

    const nestedItems = items?.reduce((accumulator, { item, modifiers }) => {
      const { name = "" } = itemsHash?.[item] || {};
      const quantity = items.filter((i) => i?.item === item).length;
      const nestedMods = !isEmpty(modifiers) ? mapNestedItems(modifiers) : {};

      return {
        ...accumulator,
        ...nestedMods,
        [item]: {
          name,
          quantity,
        },
      };
    }, {});

    return {
      ...accu,
      ...nestedItems,
    };
  }, {});
};

/**
 * withModifierItem - HOC for modifier items
 * @param     {*}         Component
 * @param     {props}     Component.props - The {@link props} from the option
 * @return    {*}
 */
const withModifierItem = (Component) => (props) => {
  const {
    className = "",
    isOptionalSingle = true,
    isSingleSelect,
    item = {},
    multiSelect = false,
    removeModifier = () => {
      return null;
    },
    addModifier = () => {
      return null;
    },
    parent = {},
    quantity = 0,
    type = "optionItem",
  } = props;

  const { isNested, options, price } = item;

  const populatedModifiers = !Object.keys(parent?.modifiers).length
    ? options.reduce((acc, cur) => {
        return { ...acc, [cur.id]: [] };
      }, {})
    : parent?.modifiers;

  // payload is the current modifier item data for adding and removing
  const payload = {
    // Add current modifier price to payload
    price,
    // Extend parent payload
    ...parent,
    // Populate modifiers with default options when none are present (adding new nested mod)
    // eslint-disable-next-line sort-keys
    modifiers: isNested ? populatedModifiers : {},
  };

  // handleClick toggles the parent container click action, ignored when parent is nested
  const handleClick = Boolean(isOptionalSingle || !quantity);
  const handleRemove =
    (isNested || isOptionalSingle || isSingleSelect) && quantity;

  // nestedItems is an array of objects with selected nested modifier item data
  const nestedItems =
    (isNested && parent?.quantity && mapNestedItems(parent?.modifiers)) || {};

  /**
   * @function increment - increase the current modifier quantity by 1, onClick of addition button
   */
  const increment = () =>
    addModifier({
      ...payload,
      quantity: isNested ? quantity + 1 : quantity,
    });

  /**
   * @function decrement - decrease the current modifier quantity by 1, onClick of reduction button
   */
  const decrement = () => removeModifier(payload);

  /**
   * @function onClick - parent container click action (adds initial or render first nested drawer)
   * @param {*} e - event
   * @returns {function} - add or remove current modifier from parent state
   */
  const onClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (!isNested && !handleClick) {
      return null;
    }

    if (!quantity || multiSelect) {
      return addModifier(payload, isNested);
    }
    return removeModifier(payload);
  };

  const toggleDrawer = () => addModifier(payload, true);

  /**
   * additionalProps - render inputs for modifier items
   * @constant {object}
   */
  const additionalProps = {
    className,
    configType: config.theme.item_details.modifier_items,
    decrement,
    handleRemove,
    increment,
    item,
    multiSelect,
    nestedItems,
    onClick,
    quantity: isNested && quantity ? parent?.quantity : quantity,
    toggleDrawer,
    type,
  };

  return (
    <Component
      {...props} // TODO: Safely remove unknown prop spreads...
      {...additionalProps}
    />
  );
};

export default withModifierItem;
