import * as React from "react";
import { Text, Cell, View } from "components/elementsThemed";
import Item from "./item";
import styles from "./option.module.css";

const Option = React.memo(
  ({
    addModifier,
    removeModifier,
    selectedMods,
    error,
    type,
    optionItemType,
    ...props
  }) => {
    const onSelect = (modId, price) => {
      addModifier({ modId, optionId: props.id, price });
    };
    const onDeselect = (modId) => {
      removeModifier({ modId, optionId: props.id });
    };

    return (
      <Cell
        type={type}
        render={({ labelTextStyles, views }) => (
          <View type={views} Component="ul" className={styles.option}>
            <div className={styles.title}>
              <Text type={labelTextStyles.primary}>{props.name}</Text>
              {error && (
                <div>
                  <Text type={labelTextStyles.tertiary}>{error}</Text>
                </div>
              )}
            </div>
            {props.items.map((i) => (
              <Item
                key={i.id}
                type={optionItemType}
                {...i}
                checked={selectedMods.includes(i.id)}
                onSelect={onSelect}
                onDeselect={onDeselect}
              />
            ))}
          </View>
        )}
      />
    );
  },
);

export default Option;
