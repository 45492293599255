import { withScope, captureException } from "@sentry/browser";

const { BUILD_ENV = "development" } = process.env;

const SUCCESS = "verification_successful";
// const FAILED = 'verification_failed';

const INCORRECT_AVS = "incorrect_avs";
const INCORRECT_ZIP = "incorrect_zip";
const INCORRECT_STREET = "incorrect_street";
const EXPIRED_CARD = "expired_card";
const INCORRECT_EXPIRATION = "incorrect_expiration";
const INCORRECT_CVC = "incorrect_cvc";
// const GENERIC_DECLINE = 'generic_decline';

// CVV (cvvResult) codes
const M = "M"; // CVV2/CVC2/CID Match
const N = "N"; // CVV2/CVC2/CID No Match
const P = "P"; // Not Processed
const S = "S"; // CVV2 expected, merchant indicates not present
const U = "U"; // Unknown/Issuer does not participate
const X = "X"; // Server provider did not respond (Default)

// AVSresult
const SM = "SM"; // Street Match
const ZM = "ZM"; // Zip Match
const SD = "SD"; // Street declined
const ZD = "ZD"; // Zip declined
const NS = "NS"; // AVS not supported
const SE = "SE"; // system error - retry
const GN = "GN"; // global non-AVS participant

export const emptyNameFieldError = "Please enter the card holder's name";

export const mapInvalidFieldError = (details) => {
  switch (details.invalidFieldKeys[0]) {
    case "expDate": {
      return "Please enter an expiration date";
    }
    case "accountNumber": {
      return "Please enter a credit card number";
    }
    case "cvv": {
      return "Please enter an expiration date";
    }
    default:
      return "Please enter the required fields";
  }
};

export const mapHostedFieldsError = (errorObject) => {
  console.log(JSON.stringify(errorObject, null, 2));

  switch (errorObject.code) {
    case "HOSTED_FIELDS_FIELDS_EMPTY":
      return "All or Some fields empty. Please complete all the fields";
    case "HOSTED_FIELDS_FIELDS_INVALID": {
      return mapInvalidFieldError(errorObject.details);
    }
    case "HOSTED_FIELDS_FAILED_TOKENIZATION": {
      return "Sorry there is an issue processing your card. Please check to make sure you have entered the correct values. If the issue persists, please try a different card";
    }
    case "CLIENT_REQUEST_ERROR":
    default: {
      withScope((scope) => {
        scope.setExtras("paymentFrom", "Seamless Pay");
        captureException(errorObject);
      });
      return "System is temporarily unavailable, please try later";
    }
  }
};

export const mapGeneralError = (error) => {
  console.log(JSON.stringify(error, null, 2));
  switch (error.code) {
    case 408:
    default:
      return "Service is unavailable. Please refresh your page or try again another time";
  }
};

export const mapAVSErrorMessage = (payload) => {
  switch (payload.avsResult) {
    case SM:
    case ZM:
    case NS:
    case GN:
      return null;
    case SD:
    case ZD:
      return "Please check to make sure you have entered the correct ZIP code and try adding your card again.";
    case SE:
    default:
      return "Processing a payment with the card you have provided was declined. Please check values entered. If the problem persist check that your card is valid or try another card.";
  }
};

export const mapCVVError = (payload) => {
  switch (payload.cvvResult) {
    case M:
    case P:
    case U:
      return null;
    case N:
    case S:
      return "Please check to make sure you have entered the correct security/CVV code and/or expiration date and try adding your card again.";
    case X:
    default:
      return "Processing a payment with the card you have provided was declined. Please check values entered. If the problem persist check that your card is valid or try another card.";
  }
};

export const mapStatusCodeError = (payload, key = "verificationResult") => {
  switch (payload[key]) {
    case INCORRECT_AVS:
    case INCORRECT_ZIP:
    case INCORRECT_STREET:
      return "Please check to make sure you have entered the correct ZIP code and try adding your card again.";
    case EXPIRED_CARD:
      return "The card you've entered has expired, please try adding a different card.";
    case INCORRECT_EXPIRATION:
      return "Please check to make sure you have entered the correct expiration date and try adding your card again.";
    case INCORRECT_CVC:
      return "Please check to make sure you have entered the correct security/CVV code and try adding your card again.";
    default:
      return "Processing a payment with the card you have provided was declined. Please check values entered. If the problem persist check that your card is valid or try another card.";
  }
};

export const mapValidationError = (payload, key = "verificationResult") => {
  if (payload.verificationResult !== SUCCESS) {
    const avsError = payload.avsResult ? mapAVSErrorMessage(payload) : null;
    const cvvError = payload.cvvResult ? mapCVVError(payload) : null;
    const statusError = payload[key] ? mapStatusCodeError(payload, key) : null;
    if (avsError) {
      return avsError;
    }
    if (cvvError) {
      return cvvError;
    }
    if (statusError) {
      return statusError;
    }
  }
  return null;
};

export const initializationOptions = ({ publicKey }) => ({
  authorization: publicKey,
  environment: ["production", "stage"].includes(BUILD_ENV)
    ? "production"
    : "sandbox",
  transactionApi: {
    accessToken: window.btoa(publicKey),
  },
});

export const options = (client) => ({
  client,
  fields: {
    accountNumber: {
      autocomplete: true,
      placeholder: "• • • •  • • • •  • • • •  • • • •",
      selector: "#account-number",
    },
    billingZip: {
      placeholder: "12345",
      selector: "#billingZip",
    },
    cvv: {
      autocomplete: true,
      placeholder: "123",
      selector: "#cvv",
    },
    expDate: {
      autocomplete: true,
      placeholder: "MM/YY or MM/YYYY",
      selector: "#exp-date",
    },
  },
  styles: {
    ".valid": {
      color: "green",
    },
    input: {
      color: "#3A3A3A",
      "font-size": "16pt",
    },
  },
  txnType: "CREDIT_CARD",
});

export default {
  initializationOptions,
  mapAVSErrorMessage,
  mapCVVError,
  mapGeneralError,
  mapHostedFieldsError,
  mapInvalidFieldError,
  mapStatusCodeError,
  mapValidationError,
  options,
};
