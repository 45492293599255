import * as React from "react";
import ReactDOM from "react-dom";

const DEFAULT_NODE = document.getElementById("portals");

// Portal -- Using React Class Component
const Portal = ({ container, children, className }) => {
  const el = React.useMemo(() => {
    const element = document.createElement("div");
    element.className = className;
    return element;
  }, []);

  React.useEffect(() => {
    container.appendChild(el);
    return () => {
      container.removeChild(el);
    };
  }, []);

  React.useEffect(() => {
    el.className = className || "";
  }, [className]);
  return ReactDOM.createPortal(children, el);
};

Portal.defaultProps = {
  container: DEFAULT_NODE,
};

export { Portal };
export default Portal;
