export const roundFloat = (value: number, toCeil = false): number => {
  const roundedFigure = Math.round((value + 0.00001) * 100) / 100;
  return !toCeil ? roundedFigure : Math.round(roundedFigure);
};

export const minZero = (n: number): number => (n >= 0 ? n : 0);

export default {
  roundFloat,
};
