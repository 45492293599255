import { formatPrice, formatCalories } from "../helpers/string";

const getItemsFormattedPrice = (itemsArr) => {
  const mappedItemPrices = itemsArr.map((item) => item.price);
  const minPrice = Math.min(...mappedItemPrices);
  const maxPrice = Math.max(...mappedItemPrices);
  const minPriceIsFinite = Number.isFinite(minPrice);
  const maxPriceIsFinite = Number.isFinite(maxPrice);
  let formattedPrice;

  if (minPriceIsFinite && maxPriceIsFinite) {
    formattedPrice =
      minPrice === maxPrice
        ? formatPrice(minPrice)
        : formatPrice([minPrice, maxPrice]);
  } else if (minPriceIsFinite || maxPriceIsFinite) {
    formattedPrice = formatPrice(minPriceIsFinite ? minPrice : maxPrice);
  } else {
    formattedPrice = null;
  }

  return formattedPrice;
};

const getItemsFormattedCalories = (itemsArr) => {
  const mappedItemCalories = itemsArr.map((item) => item.calories);
  const minCalories = Math.min(...mappedItemCalories);
  const maxCalories = Math.max(...mappedItemCalories);
  const minCaloriesIsFinite = Number.isFinite(minCalories);
  const maxCaloriesIsFinite = Number.isFinite(maxCalories);
  let formattedCalories;

  if (minCaloriesIsFinite && maxCaloriesIsFinite) {
    formattedCalories =
      minCalories === maxCalories
        ? `${minCalories} cal`
        : formatCalories({ maxCalories, minCalories });
  } else if (minCaloriesIsFinite || maxCaloriesIsFinite) {
    formattedCalories = minCaloriesIsFinite
      ? `${minCalories} cal`
      : `${maxCaloriesIsFinite} cal`;
  } else {
    formattedCalories = null;
  }

  return formattedCalories;
};

export { getItemsFormattedPrice, getItemsFormattedCalories };
