import * as React from "react";
import { Formik, Form } from "formik";
import { MemoryRouter, Route, Switch } from "react-router-dom";

import { Condition as Conditional, Layout } from "components/elements";
import DrawerButton from "../../components/DrawerButton";
import { Routes as RoutesComps } from "components/fragments";
import BackButton from "../../components/BackButton/BackButton";
import { UpdatePhone } from "components/templates";
import {
  ForgotPassword,
  UpdatePasswordForm,
} from "components/templates/ForgotPassword";
import { Notifications } from "components/providers";
import { usePatronContext } from "components/providers/Patron/PatronContext";
import { withTemplate } from "components/hocs";
import {
  Text,
  View,
  Dialogue,
  Field as FieldComps,
} from "components/elementsThemed";
import { axios, Schemas, Copy, Routes, images, config } from "utils";
import { UPDATE_PATRON } from "utils/api";
import { useResource } from "hooks";
import { getFormattedCardBrand } from "./Cards/utils";
import { Cards } from "./Cards";
import { getValue, parseAndReplaceDigitInString } from "utils/helpers/string";

import css from "./profile.module.scss";

const { Condition } = Conditional;
const { Flex } = Layout;
const { Input, Field } = FieldComps;
const { RouteWithProps } = RoutesComps;
const { useNotification } = Notifications;

const renderNotification = (type, message) => (
  <Dialogue type={type}>
    {({ labelTextStyles, view }) => (
      <View type={view} style={{ padding: "10px" }}>
        <div>
          <Text type={labelTextStyles?.secondary}>
            {Copy.PROFILE_STATIC.SUCCESS_HEADER}
          </Text>
        </div>
        <div>
          <Text type={labelTextStyles?.primary}>{message}</Text>
        </div>
      </View>
    )}
  </Dialogue>
);

const getCardInputText = (cards) =>
  cards?.length
    ? `${getFormattedCardBrand(cards?.[0]?.brand.toUpperCase())} ending in ${
        cards?.[0]?.last4
      }`
    : "NO CARDS ON FILE ";

const AccountDetails = withTemplate(({ style, ...props }) => {
  const { add } = useNotification();
  const scrollingContainerRef = React.useRef();
  const { patron, updatePatron } = usePatronContext();
  const {
    firstName,
    lastName,
    email,
    phone,
    birthday: patronBirthday,
  } = patron;
  const [{ resource = [] }] = useResource({
    path: Routes.FETCH_CARDS,
  });

  const scrollToBottom = () => {
    const node = scrollingContainerRef?.current;
    if (!node) return;
    node.scrollTop = node.scrollHeight;
  };

  const isBirthdayInBackend = !!(
    patronBirthday?.day &&
    patronBirthday?.month &&
    patronBirthday?.year
  );

  let initialBirthday = undefined;
  if (isBirthdayInBackend) {
    const month = `0${patronBirthday?.month}`.slice(-2);
    const day = `0${patronBirthday?.day}`.slice(-2);
    initialBirthday = `${patronBirthday?.year}-${month}-${day}`;
  }

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        email: email.value || email,
        firstName,
        lastName,
        birthday: initialBirthday,
      }}
      validationSchema={Schemas.PatronNameSchema}
      onSubmit={async (values, actions) => {
        actions.setStatus(undefined);
        try {
          const birthdate = new Date(values.birthday);
          // if we have the birthday value in the form, but we DON'T have it in backend, send the data along. Otherwise, make birthday value undefined & disregard user input value. Prevents DOM manipulation for BDay change.
          const birthday =
            values.birthday && !isBirthdayInBackend
              ? {
                  year: birthdate.getUTCFullYear(),
                  month: birthdate.getUTCMonth() + 1,
                  day: birthdate.getUTCDate(),
                }
              : undefined;

          await UPDATE_PATRON({
            email: values.email.trim(),
            firstName: values.firstName,
            lastName: values.lastName,
            birthday,
          });
          updatePatron({
            email: values.email.trim(),
            firstName: values.firstName,
            lastName: values.lastName,
            birthday,
          });
          add(
            renderNotification(
              style.dialogues.success,
              Copy.PROFILE_STATIC.INFORMATION_UPDATED_MESSAGE,
            ),
          );
        } catch (error) {
          console.error(error);
          const e = axios.handleError(error);
          scrollToBottom();
          actions.setStatus(e.data);
        } finally {
          actions.setSubmitting(false);
        }
      }}
      render={({ errors, values, ...formProps }) => (
        <View
          type={style.views.secondary}
          className={css["profile-form"]}
          Component={Form}
        >
          <div
            ref={scrollingContainerRef}
            className={css["profile-fields-container"]}
          >
            <Input
              data-pl="firstName-input"
              label={Copy.PROFILE_STATIC.ACCOUNT_FIRST_NAME_INPUT_LABEL}
              name="firstName"
              type={style.inputs.standard}
              error={errors.firstName}
              value={values.firstName}
              placeholder={
                Copy.PROFILE_STATIC.ACCOUNT_FIRST_NAME_INPUT_PLACEHOLDER
              }
              onChange={(e) =>
                formProps.setFieldValue(
                  "firstName",
                  parseAndReplaceDigitInString(getValue(e)),
                )
              }
            />

            <Input
              data-pl="lastName-input"
              label={Copy.PROFILE_STATIC.ACCOUNT_LAST_NAME_INPUT_LABEL}
              name="lastName"
              type={style.inputs.standard}
              error={errors.lastName}
              value={values.lastName}
              placeholder={
                Copy.PROFILE_STATIC.ACCOUNT_LAST_NAME_INPUT_PLACEHOLDER
              }
              onChange={(e) =>
                formProps.setFieldValue(
                  "lastName",
                  parseAndReplaceDigitInString(getValue(e)),
                )
              }
            />

            <Input
              data-pl="email-input"
              label={Copy.PROFILE_STATIC.ACCOUNT_EMAIL_INPUT_LABEL}
              name="email"
              type={style.inputs.standard}
              error={errors.email}
              value={values.email}
              placeholder={Copy.PROFILE_STATIC.ACCOUNT_EMAIL_INPUT_PLACEHOLDER}
              onChange={(e) => formProps.setFieldValue("email", e.target.value)}
              onFocus={() => formProps.setStatus("")} // Clear the bottom error message for invalid field onFocus
              inputMode="email"
            />

            <Field
              type={style.cells.form}
              label={Copy.PROFILE_STATIC.ACCOUNT_PHONE_INPUT_LABEL}
              value={Boolean(phone) ? phone.value || phone : "Add Your Phone"}
              buttonProps={{
                "data-pl": "edit-phone",
                children: "Edit",
                htmlType: "button",
                onClick: () => props.history.push(Routes.UPDATE_PHONE),
              }}
            />

            <Field
              className="payment-details"
              type={style.cells.form}
              label="Card On File"
              value={getCardInputText(resource)}
              buttonProps={{
                "data-pl": "edit-card",
                children: "Edit",
                htmlType: "button",
                onClick: () => props.history.push(Routes.FETCH_CARDS),
              }}
            />

            <Field
              type={style.cells.form}
              label={Copy.PROFILE_STATIC.ACCOUNT_PASSWORD_INPUT_LABEL}
              value="********"
              buttonProps={{
                "data-pl": "edit-pass",
                children: "Edit",
                htmlType: "button",
                onClick: () => props.history.push(Routes.UPDATE_PASSWORD),
              }}
            />

            <Input
              disabled={isBirthdayInBackend}
              data-pl="birthday-input"
              label={Copy.SIGN_UP_STATIC.BIRTHDAY_LABEL}
              name="birthday"
              type={style.inputs.standard}
              error={errors.birthday}
              value={values.birthday}
              onChange={(e) =>
                formProps.setFieldValue("birthday", e.target.value)
              }
              htmlType="date"
            />
            <Condition is={formProps.status}>
              <Text type={style.labels.error}>{formProps.status}</Text>
            </Condition>
          </div>

          <div className={css["profile-footerButton"]}>
            <DrawerButton
              buttonProps={{
                htmlType: "submit",
                disabled: formProps.isSubmitting,
              }}
            >
              {Copy.PROFILE_STATIC.ACCOUNT_UPDATE_BUTTON_TEXT}
            </DrawerButton>
          </div>
        </View>
      )}
    />
  );
}, "profile");

const AccountInformation = ({ style }) => {
  const { add } = useNotification();
  const { patron, accessToken, updatePatron } = usePatronContext();
  const notice = (message) => {
    add(renderNotification(style.dialogues.confirm, message));
  };
  return (
    <MemoryRouter initialEntries={["/"]} initialIndex={0}>
      <Route
        render={({ location, history }) => (
          <>
            <Condition is={location.pathname !== "/"}>
              <Flex
                style={{ padding: "10px 10px 0px 10px" }}
                direction="row"
                align="center"
              >
                <BackButton
                  imgSrc={images?.button_back_profile}
                  onClick={history.goBack}
                />
              </Flex>
            </Condition>
            <Flex direction="col" grow="1" style={{ "min-height": 0 }}>
              <Switch>
                <RouteWithProps
                  exact
                  path={Routes.ROOT}
                  component={AccountDetails}
                />
                <RouteWithProps
                  path={Routes.UPDATE_PASSWORD}
                  component={config.isOlo ? ForgotPassword : UpdatePasswordForm}
                  patron={patron}
                  onSuccess={() => {
                    history.goBack();
                    notice(Copy.PROFILE_STATIC.PASSWORD_UPDATED_MESSAGE);
                  }}
                />
                <RouteWithProps
                  path={Routes.UPDATE_PHONE}
                  component={UpdatePhone}
                  token={accessToken}
                  onSuccess={(data) => {
                    updatePatron({ phone: data.phone });
                    notice(Copy.PROFILE_STATIC.PHONE_NUMBER_UPDATED_MESSAGE);
                    history.goBack();
                  }}
                />
                <RouteWithProps
                  path={Routes.FETCH_CARDS}
                  component={Cards}
                  token={accessToken}
                  onSuccess={history.goBack}
                  style={style}
                />
              </Switch>
            </Flex>
          </>
        )}
      />
    </MemoryRouter>
  );
};

export default AccountInformation;
