import * as React from "react";
import { config } from "utils";

import Type1 from "./Type1";
import Group from "./Subgroups";

import Theme1 from "./itemDetails.module.scss";
import Theme2 from "./itemDetails-Theme2.module.scss";

const Selector = ({ isGroup, ...props }) => {
  let Component = Type1;
  let Theme = Theme1;

  switch (config.theme.item_details.layout) {
    case "Theme1":
      Theme = Theme1;
      break;
    case "Theme2":
      Theme = Theme2;
      break;
    default:
      Theme = Theme1;
  }

  if (isGroup) {
    // This reassignment is not clean implementation.
    // I think the group id and item id should be separated and distinct props
    // passed from where the item/group is clicked
    const propsTemp = { ...props };
    propsTemp.group = propsTemp.id;
    Component = Group;
  }

  return <Component css={Theme} isGroup={isGroup} {...props} />;
};

export default Selector;
