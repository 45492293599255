// eslint-disable-next-line import/no-unresolved
import overrideData from "LanguageData";
import CART_STATIC from "./cart";
import CHECKOUT_STATIC from "./checkout";
import DELIVERY_FORM_STATIC from "./deliveryForm";
import ELEMENTS_STATIC from "./elements";
import FORGOT_PASSWORD_STATIC from "./forgotPassword";
import GIFT_CARD_STATIC from "./giftCards";
import HEADER_STATIC from "./header";
import ITEM_DETAILS_STATIC from "./itemDetails";
import KIOSK_STATIC from "./kiosk";
import LIVE_SEARCH_STATIC from "./liveSearch";
import LOCATIONS_STATIC from "./locations";
import LOCATION_LIST_STATIC from "./locationList";
import LOGIN_SIGNUP_STATIC from "./loginSignUp";
import LOGIN_STATIC from "./login";
import LOGOUT_STATIC from "./logout";
import MARKETPLACE_STATIC from "./marketplace";
import MENU_STATIC from "./menu";
import ORDER_STATIC from "./order";
import ORDER_TRACKING from "./orderTracking";
import PASSWORD_STATIC from "./password";
import PAYMENT_FORM_STATIC from "./paymentForm";
import PICKUP_FORM_STATIC from "./pickupForm";
import PROFILE_STATIC from "./profile";
import RG_INFO_STATIC from "./info";
import SIGN_UP_STATIC from "./signUp";
import UPDATE_EMAIL_STATIC from "./updateEmail";
import UPDATE_PHONE_STATIC from "./updatePhone";
import VALIDATE_LOGIN_STATIC from "./validaLogin";

const DefaultLang = {
  CART_STATIC,
  CHECKOUT_STATIC,
  DELIVERY_FORM_STATIC,
  ELEMENTS_STATIC,
  FORGOT_PASSWORD_STATIC,
  GIFT_CARD_STATIC,
  HEADER_STATIC,
  ITEM_DETAILS_STATIC,
  KIOSK_STATIC,
  LIVE_SEARCH_STATIC,
  LOCATIONS_STATIC,
  LOCATION_LIST_STATIC,
  LOGIN_SIGNUP_STATIC,
  LOGIN_STATIC,
  LOGOUT_STATIC,
  MARKETPLACE_STATIC,
  MENU_STATIC,
  ORDER_STATIC,
  ORDER_TRACKING,
  PASSWORD_STATIC,
  PAYMENT_FORM_STATIC,
  PICKUP_FORM_STATIC,
  PROFILE_STATIC,
  RG_INFO_STATIC,
  SIGN_UP_STATIC,
  UPDATE_EMAIL_STATIC,
  UPDATE_PHONE_STATIC,
  VALIDATE_LOGIN_STATIC,
};

let usedDefaults = [];

export const Copy = Object.entries(DefaultLang).reduce((accu, [key, value]) => {
  const accuTemp = { ...accu };
  if (overrideData[key]) {
    accuTemp[key] = {
      ...value, // Take all the lang groups from the defaults for the ones that aren't in the fetched lang
      ...Object.entries(overrideData[key]).reduce((accu2, [key2, value2]) => {
        if (value2 === undefined || value2 === null) {
          usedDefaults.push(key2);
          return accu2;
        }
        // If this key-value pair has string values(empty string and other string values)
        // Allow static text to have empty string values
        return {
          ...accu2,
          [key2]: value2,
        };
      }, {}),
    };
    return accuTemp;
  }
  usedDefaults = usedDefaults.concat(Object.keys(accu[key]));
  return accuTemp;
}, DefaultLang);

export {
  CART_STATIC,
  CHECKOUT_STATIC,
  DELIVERY_FORM_STATIC,
  ELEMENTS_STATIC,
  FORGOT_PASSWORD_STATIC,
  GIFT_CARD_STATIC,
  HEADER_STATIC,
  ITEM_DETAILS_STATIC,
  KIOSK_STATIC,
  LIVE_SEARCH_STATIC,
  LOCATIONS_STATIC,
  LOCATION_LIST_STATIC,
  LOGIN_SIGNUP_STATIC,
  LOGIN_STATIC,
  LOGOUT_STATIC,
  MENU_STATIC,
  ORDER_STATIC,
  ORDER_TRACKING,
  PASSWORD_STATIC,
  PAYMENT_FORM_STATIC,
  PICKUP_FORM_STATIC,
  PROFILE_STATIC,
  RG_INFO_STATIC,
  SIGN_UP_STATIC,
  UPDATE_EMAIL_STATIC,
  UPDATE_PHONE_STATIC,
  VALIDATE_LOGIN_STATIC,
};

export default Copy;
