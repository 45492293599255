import * as React from "react";
import classnames from "classnames";
import { Button } from "components/elementsThemed";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useGoogleAutocompleContext } from "./googleAutoComplete";
import css from "./search.module.scss";

const SearchResult = (props) => {
  return (
    <div>
      <Button
        Component="div"
        className={[css["search-result"], "search-result", css[props?.type]]}
        {...props}
      />
      <hr />
    </div>
  );
};

const SearchResults = ({ type, onSelect, recentAddresses }) => {
  const { items, visible, text, setText, setItems, setVisible } =
    useGoogleAutocompleContext();
  const ref = React.useRef(null);

  useOnClickOutside(ref, () => {
    // if user clicks outside and input is empty, set searchItemsArr to an empty arr
    if (!text.trim()) {
      setItems([]);
    }
    setVisible(false);
  });

  const formattedRecentAddresses = recentAddresses
    .map(({ id: addressID, text: addressText }) => {
      return addressID && { id: addressID, text: addressText };
    })
    .filter((a) => !!a);

  const searchResultsClasses = classnames(
    css["search-results"],
    "search-results",
    visible ? css.visible : undefined,
  );

  const addressList =
    !items.length && formattedRecentAddresses.length
      ? formattedRecentAddresses
      : items;

  return (
    <div ref={ref} className={searchResultsClasses}>
      {addressList.map((i, idx) => (
        <SearchResult
          type={type}
          data-cy={`address-${idx + 1}`}
          key={i.text}
          onClick={() => {
            // fetch the address & update recentAddresses
            onSelect(i);
            // set input text adn close the dropdown
            setText(i.text);
            setVisible(false);
          }}
        >
          {i.text}
        </SearchResult>
      ))}
    </div>
  );
};

export default SearchResults;
