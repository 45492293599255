import * as React from "react";
import { Layout, Image } from "components/elements";

import css from "../base.module.scss";

const { Flex } = Layout;

const Empty = ({ imgSrc, children }) => (
  <Flex
    direction="col"
    align="center"
    justify="center"
    grow={1}
    className={css.empty}
  >
    <div className={css.message}>{children}</div>
    <Image src={imgSrc} />
  </Flex>
);

export default Empty;
