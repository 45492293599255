/* eslint-disable react/destructuring-assignment */
import * as React from "react";
import { Condition } from "components/elements";
import { withTemplate } from "components/hocs";
import { View, Segment, Text, Field } from "components/elementsThemed";

import { config, images as imagesUtils, Copy } from "utils";
import BackButton from "../../../../../BackButton/BackButton";
import TabSelector from "../../../Tabs";
import HeaderSelector from "../../../Header";
import GroupSelector from "../../../Group";
import FooterSelector from "../../../Footer";
import GlobalMessage from "../../../globalMessage";
import Notes from "../../../Notes";

import styles from "./subgroupsType1.module.scss";

const { Condition: If } = Condition;
const { Textarea } = Field;

const SubgroupsType1 = React.memo(
  ({
    addModifier,
    artMiscImage,
    buttonText,
    calories,
    decQuantity,
    diet,
    errors,
    globalError,
    group,
    handleScroll,
    incQuantity,
    item,
    itemImageRef,
    modifications,
    mods,
    notes,
    onChangeNotes,
    onSelect,
    onSubmit,
    optionRefs,
    optionsHash,
    placeholderImage,
    price,
    quantity,
    removeModifier,
    reset,
    scrollingRef,
    selectedItem,
    setGlobalError,
    style,
    tabs,
    ...props
  }) => {
    const { segmentViews, views, cells, labels, inputs } = style;

    const {
      name,
      description,
      items,
      images,
      dietaryRestrictions,
      additionalDescriptions = [],
    } = group;

    const image = images?.[0] ?? imagesUtils?.art_item_placeholder;

    const containerRef = React.useRef(null);

    return (
      <View
        type={views.background}
        className={styles.itemDetails}
        innerRef={scrollingRef}
        id="itemDetails"
      >
        <If is={props.showClose}>
          <div className={styles["itemDetails-back"]}>
            <BackButton
              imgSrc={imagesUtils?.button_back_item_details}
              onClick={props.close}
            />
          </div>
        </If>
        <div
          id="itemDetailsContainer"
          className={styles["itemDetails-content"]}
          ref={containerRef}
        >
          <div
            id="itemImage"
            ref={itemImageRef}
            className={styles["itemDetails-image"]}
            style={{
              backgroundImage: `url(${image})`,
            }}
          />

          <div id="header" className={styles["itemDetails-header"]}>
            <div className={styles["itemDetails-header--container"]}>
              <HeaderSelector
                description={description}
                additionalDescriptions={additionalDescriptions}
                dietaryRestrictions={dietaryRestrictions}
                price={price}
                name={name}
                calories={calories}
                cells={cells}
              />
            </div>
          </div>

          <GroupSelector
            items={items}
            types={{
              default: cells.primary,
              group: cells.group,
              selected: cells.primarySelected,
            }}
            error={errors.find((i) => i.type === "item")}
            labelType={labels.warning}
            selected={selectedItem}
            onSelect={onSelect}
          />

          <div className={styles["itemDetails-tabs"]}>
            <If is={selectedItem}>
              <Options
                artMiscImage={artMiscImage}
                cells={cells}
                type={segmentViews.standard}
                item={item}
                tabs={tabs}
                handleScroll={handleScroll}
                optionRefs={optionRefs}
                optionsHash={optionsHash}
                diet={diet}
                modifications={modifications}
                setGlobalError={setGlobalError}
                errors={errors}
                addModifier={addModifier}
                removeModifier={removeModifier}
                onReset={reset}
                containerRef={containerRef}
              />
              <If is={config.ordering.notes}>
                <div className={styles["itemDetails-notes"]}>
                  <Notes
                    label={
                      Copy.ITEM_DETAILS_STATIC.GROUP_SELECTION_TXTAREA_LABEL
                    }
                    value={notes}
                    type={inputs.standard}
                    onChange={onChangeNotes}
                    description={
                      Copy.ITEM_DETAILS_STATIC
                        .GROUP_SELECTION_TXTAREA_DESCRIPTION
                    }
                    placeholder={
                      Copy.ITEM_DETAILS_STATIC
                        .GROUP_SELECTION_TXTAREA_PLACEHOLDER
                    }
                  />
                </div>
              </If>
            </If>
          </div>
        </div>

        <View type={views.footer} className={styles["itemDetails-footer"]}>
          <GlobalMessage
            error={globalError}
            afterQueueSuccess={() => setGlobalError(null)}
            render={(error) => (
              <View
                type={views.background}
                className={styles["itemDetails-error"]}
              >
                <Text type={labels.warning}>{error}</Text>
              </View>
            )}
          />
          <FooterSelector
            showQuantity={props.showQuantity}
            quantityType={cells.quantity}
            quantity={quantity}
            incQuantity={incQuantity}
            decQuantity={decQuantity}
            buttonType={cells.bottom}
            onClick={onSubmit}
            buttonText={buttonText()}
          />
        </View>
      </View>
    );
  },
);

const Options = ({
  addModifier,
  artMiscImage,
  cells,
  containerRef,
  errors,
  handleScroll,
  modifications,
  optionRefs,
  optionsHash,
  removeModifier,
  tabs,
  type,
}) => {
  if (!tabs.length) {
    return (
      <div
        style={{
          backgroundImage: `url(${artMiscImage})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          display: "none",
          height: "100%",
        }}
      />
    );
  }

  return (
    <Segment
      type={type}
      render={(segmentProps) => (
        <TabSelector
          optionRefs={optionRefs}
          handleScroll={handleScroll}
          {...segmentProps}
          tabs={tabs}
          errors={errors}
          cells={cells}
          addModifier={addModifier}
          removeModifier={removeModifier}
          menuOptions={optionsHash}
          modifications={modifications}
          containerRef={containerRef}
        />
      )}
    />
  );
};

export default withTemplate(SubgroupsType1, "itemDetails");
