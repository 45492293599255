import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { Layout, Condition } from "components/elements";
import { Text, View } from "components/elementsThemed";
import { useCell } from "hooks";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import css from "./option.module.css";

const { Condition: If } = Condition;
const { Flex } = Layout;

function Option({ error, errorRef, id, modifierItems, name, optionRef, type }) {
  const { views, labelTextStyles } = useCell(type);

  return (
    <>
      <View type={views.background} className={css.option} data-id={id}>
        <Row>
          <Col>
            <div ref={optionRef}>
              <Text type={labelTextStyles.primary} className={css.name}>
                {name}
              </Text>
            </div>
          </Col>
          <div aria-live="polite" ref={errorRef}>
            <If is={error}>
              <Text type={labelTextStyles.secondary}>
                <VisuallyHidden>Error: </VisuallyHidden>
                {error}
              </Text>
            </If>
          </div>
        </Row>
      </View>
      <Flex>
        <ul className={css.list}>{modifierItems}</ul>
      </Flex>
    </>
  );
}
Option.displayName = "OptionType4";

export default React.memo(Option);
