import * as React from "react";

import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { Layout } from "components/elements";
import { Text, Button, View } from "components/elementsThemed";
import { withTemplate } from "components/hocs";
import css from "./confirm.module.css";

const { Flex } = Layout;

const viewProps = {
  align: "center",
  className: css.background,
  Component: Flex,
  grow: "1",
  justify: "center",
};

const Confirm = ({
  cancelText,
  confirmText,
  isNegativeConfirm,
  message,
  onCancel,
  onConfirm,
  style,
}) => (
  <View type={style.views.background} {...viewProps}>
    <div className={css.confirm}>
      <div className={css.dialogue}>
        <Text type={style.labels.title}>
          <div>{message}</div>
        </Text>
      </div>
      <Col
        xs={{ offset: "1-12", span: "5-6" }}
        sm={{ offset: "1-8", span: "3-4" }}
      >
        <Row gutter={40}>
          <Col xs="1-2">
            <Button
              block
              size="md"
              type={
                isNegativeConfirm
                  ? style.buttons.negConfirm
                  : style.buttons.confirm
              }
              onClick={onConfirm}
            >
              {confirmText}
            </Button>
          </Col>
          <Col xs="1-2">
            <Button
              block
              size="md"
              type={style.buttons.cancel}
              onClick={onCancel}
            >
              {cancelText}
            </Button>
          </Col>
        </Row>
      </Col>
    </div>
  </View>
);

export default withTemplate(Confirm, "confirm");
