import { constants } from "utils";
import { eventHandler } from "../tools";

const {
  ANALYTICS: { UNNAMED_EVENT },
} = constants;

export default {
  viewed: eventHandler(UNNAMED_EVENT),
};
