import * as React from "react";
import loadable from "@loadable/component";
import { config } from "utils";

const AsyncPage = loadable((props) => import(`./${props.page}`));

const Selector = (props) => {
  return (
    <AsyncPage page={config?.theme?.market?.layout || "Type1"} {...props} />
  );
};

export default Selector;
