export const getFormattedCardBrand = (brand) => {
  if (brand === "AMEX") return "AMERICAN EXPRESS";
  if (brand === "MC") return "MASTERCARD";
  if (brand === "Visa") return "VISA";
  return brand;
};

export const cardFormat = (brand, last4, expM, expY) => {
  return {
    mappedBrand: getFormattedCardBrand(brand),
    mappedLast4: `••••-${last4},exp ${expM}/${expY}`,
  };
};

export const CARD_ROOT = "/cards";
export const DELETE_CARD = `${CARD_ROOT}/delete/:cardId`;
export const UPDATE_CARD = `${CARD_ROOT}/update/:cardId`;
export const ADD_CARD = `${CARD_ROOT}/add`;

export const GET_DELETE_CARD_ROUTE = (id) => DELETE_CARD.replace(":cardId", id);
export const GET_UPDATE_CARD_ROUTE = (id) => UPDATE_CARD.replace(":cardId", id);

export const CARD_ROUTES = {
  ADD_CARD,
  CARD_ROOT,
  DELETE_CARD,
  UPDATE_CARD,
};
