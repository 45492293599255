import * as React from "react";
import { useCell } from "hooks";
import { useTemplateContext } from "components/providers/Template";
import { PriceField } from "pages/Order";
import { Copy, config } from "utils";
import { Button } from "components/elementsThemed";
import BottomSheet from "components/fragments/BottomSheet";
import { CustomTipButton } from "./CustomTipButton";
import styles from "./customTipping.module.scss";
import { CustomInput } from "./CustomInput";
import { Tip } from "./Tip";
import {
  convertDecimalToPercentage,
  getDefaultTipPercentFromOrderType,
  mapPercentageToString,
  transformActiveColorToBlack,
} from "./helpers";
import { CTProps as CustomTippingProps, CurrentTip } from "./types";

function CustomTipping({
  tipsAvailableOptions,
  tip,
  labels,
  setTip,
  setShowCustomTipModal,
  totalAmount,
  diningOption,
}: CustomTippingProps) {
  const { defaultTip, percentage } =
    getDefaultTipPercentFromOrderType(diningOption);
  const { theme } = useTemplateContext();

  const { buttons } = useCell("footerButton");
  const { labelTextStyles } = useCell("cartHeader");
  // this state  toggle the buttomsheet
  const [isBottomSheetOpen, setBottomSheetOpen] = React.useState(false);
  // handle custom amount
  const [customAmount, setCustomAmount] = React.useState(0);
  // handle custom button
  const [isCustomized, setCustomize] = React.useState(false);
  const inputRef = React.useRef(null);
  const tipAsNumber = +tip;

  // handle current tip
  const [currenTip, setCurrentTip] = React.useState<CurrentTip>(() => ({
    amount: defaultTip,
    currentTipLabel: percentage,
    selectedIndex: defaultTip,
  }));

  const [firstRenderOccur, setFirstRenderOccur] = React.useState(false);
  /* transformActiveColorToBlack:
   will transform the accentLight to black if
   client's accentLight is white or grey.
  */

  const customTipActiveColor = transformActiveColorToBlack(
    theme?.colors?.accentLight,
  );

  const tipActiveColor = theme?.colors?.accentLight;
  const activeBorder = {
    borderColor: `${tipActiveColor}`,
    borderStyle: "solid",
  };

  // style active tip
  const customTipActiveStyle = {
    background: `${customTipActiveColor}`,
    borderRadius: 4,
    padding: "0.25rem 0.09rem",
    boxSizing: "border-box",
    display: "block",
    margin: "1em auto",
  };

  // reset modal and bottom sheet
  const resetModalAndBs = () => {
    setBottomSheetOpen(false);
    setShowCustomTipModal(false);
  };

  // handle buttom sheet
  const onOpen = () => {
    setBottomSheetOpen(true);
    setCustomize(true);
    setShowCustomTipModal(true);
  };

  const defaultAmount = +mapPercentageToString(totalAmount, defaultTip);
  // close the modal sheet
  const onClose = () => {
    // when the user click the close button
    // select the default tip
    setTip(defaultAmount);
    setCurrentTip({
      amount: defaultAmount,
      currentTipLabel: percentage,
      selectedIndex: defaultTip,
    });
    setCustomize(false);
    resetModalAndBs();
  };

  // handle custom value
  const handleCustomAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(parseFloat(e.target.value))) setCustomAmount(0);
    setCustomAmount(e.target.valueAsNumber);
  };

  // add a custom functionality to auto select custom
  const requireDefaultCustomTipContd = [""].includes(config?.id);

  React.useEffect(() => {
    if (requireDefaultCustomTipContd) {
      setCustomize(true);
      setFirstRenderOccur(true);
    }
  }, []);

  React.useEffect(() => {
    if (requireDefaultCustomTipContd) {
      const firstTip = tipsAvailableOptions[0];
      if (
        firstRenderOccur &&
        !isCustomized &&
        !customAmount &&
        !tipsAvailableOptions.includes(currenTip.selectedIndex)
      ) {
        const percent = convertDecimalToPercentage(firstTip);
        const amount = mapPercentageToString(totalAmount, firstTip);
        setTip(firstTip);
        setCurrentTip({
          amount: +amount,
          selectedIndex: firstTip,
          currentTipLabel: percent,
        });
      }
    }
  }, [isCustomized, customAmount, tipsAvailableOptions, currenTip]);

  // handle submit custom tip
  const onSubmit = () => {
    // select defaultip if user enter incorrect values.
    if (
      Number.isNaN(customAmount) ||
      customAmount === null ||
      customAmount < 0
    ) {
      setTip(defaultAmount);
      setCurrentTip({
        amount: defaultAmount,
        currentTipLabel: percentage,
        selectedIndex: defaultTip,
      });
      setCustomize(false);
    } else {
      setTip(customAmount);
      setCurrentTip({
        amount: customAmount,
      });
    }
    resetModalAndBs();
  };

  // LABEL NAME
  const name = `Tip ${
    isCustomized ? "(Custom)" : `(${currenTip?.currentTipLabel}%)`
  }`;

  const showDeliveryCustomTipMsg = React.useMemo(
    () =>
      diningOption === "delivery" &&
      customAmount > 0 &&
      !!Copy.CART_STATIC.CUSTOM_TIP_ERROR,
    [customAmount],
  );

  return (
    <div className={styles.tipsContainer}>
      <div>
        <PriceField
          name={name}
          value={tipAsNumber}
          type={labels.priceSubtotals}
        />
      </div>
      <div
        className={styles["tipsContainer-wrapper"]}
        role="group"
        aria-labelledby="tip-info"
      >
        {tipsAvailableOptions.map((singleTip: number) => (
          <Tip
            singleTip={singleTip}
            key={singleTip}
            selected={singleTip === currenTip?.selectedIndex}
            totalAmount={totalAmount}
            setTip={setTip}
            activeBorder={activeBorder}
            isCustomized={isCustomized}
            customTipActiveStyle={customTipActiveStyle}
            setCustomize={setCustomize}
            setCurrentTip={setCurrentTip}
          />
        ))}
        <CustomTipButton
          onOpen={onOpen}
          isCustomized={isCustomized}
          activeBorder={activeBorder}
          customAmount={customAmount}
          totalAmount={totalAmount}
          customTipActiveStyle={customTipActiveStyle}
        />
      </div>
      {isBottomSheetOpen && (
        <BottomSheet
          labelTextStyles={labelTextStyles}
          onClose={onClose}
          title="Custom Tipping"
          fixedHeight={260}
        >
          <div className={styles["tipsContainer-wrapper--tipcontent"]}>
            <div
              className={styles["tipsContainer-wrapper--customInputWrapper"]}
            >
              <div className={styles["tipsContainer-wrapper--customInput"]}>
                <CustomInput
                  handleCustomAmount={handleCustomAmount}
                  customAmount={customAmount}
                  setCustomAmount={setCustomAmount}
                  inputRef={inputRef}
                />
              </div>
            </div>
            {showDeliveryCustomTipMsg && (
              <p className={styles["tipsContainer-wrapper--customError"]}>
                {Copy.CART_STATIC.CUSTOM_TIP_ERROR}
              </p>
            )}
            <Button
              onClick={onSubmit}
              className={styles["tipsContainer-wrapper--saveButton"]}
              type={buttons.primary}
            >
              Save
            </Button>
          </div>
        </BottomSheet>
      )}
    </div>
  );
}

export { CustomTipping };
