/**
 * Improved state management across applications with a new CachedAdresses context.
 *
 *
 * Example use:
 * import { useCachedAddresses } from "../contexts/CachedAdresses";
 *
 * { updateRecentAddressesState } = useCachedAddresses()
 * updateRecentAddressesState(address)
 * cache the address that the user input as recent addresses to local storage
 */
import * as React from "react";
import useLocalStorage from "hooks/useLocalStorage";
import uniqBy from "lodash/uniqBy";

export type CachedAddressesNode = {
  id: string;
  text: string;
};

export interface CachedAddressesContext {
  recentAddresses: Array<CachedAddressesNode>;
  updateRecentAddressesState: (newState: CachedAddressesNode) => void;
}
export const defaultValue = {
  recentAddresses: [],
  updateRecentAddressesState: () => null,
};

const CachedAddressContext =
  React.createContext<CachedAddressesContext>(defaultValue);

export function CachedAddressProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [recentAddresses, setRecentAddresses] = useLocalStorage<
    Array<CachedAddressesNode>
  >("recentAddresses", []);

  // only store 4 most recent unique addresses
  const updateRecentAddressesState = (newState: CachedAddressesNode) =>
    setRecentAddresses((prev) => {
      if (prev.length) {
        const addresses = uniqBy([newState, ...recentAddresses], "id").slice(
          0,
          4,
        );
        return addresses;
      }
      return [newState];
    });

  // Memomize context values to avoid unnecessary rerender
  const value = React.useMemo(
    () => ({
      recentAddresses,
      updateRecentAddressesState,
    }),
    [recentAddresses, updateRecentAddressesState],
  );

  return (
    <CachedAddressContext.Provider value={value}>
      {children}
    </CachedAddressContext.Provider>
  );
}

export const useCachedAddresses = () => {
  const contextValues = React.useContext(CachedAddressContext);

  return { ...contextValues };
};
