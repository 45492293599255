import * as React from "react";
import PropTypes from "prop-types";
import RcDrawer from "rc-drawer";
import { Portal } from "../../elements";
import "rc-drawer/assets/index.css";
import "./rc-drawer-overrides.css";

/**
 * Drawer
 * @deprecated Do not use the drawers in fragments. Use "components/Drawer.tsx"
 */
const Drawer = ({
  children,
  placement,
  isOpen,
  trigger,
  drawerProps: { destroyOnClose, ...drawerProps },
  onChange,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(isOpen || false);
  const toggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const close = () => {
    setIsDrawerOpen(false);
  };
  React.useEffect(() => {
    setIsDrawerOpen(isOpen);
  }, [isOpen]);
  React.useEffect(() => {
    if (isDrawerOpen !== isOpen && onChange) {
      onChange(isDrawerOpen);
    }
  }, [isDrawerOpen]);

  return (
    <>
      {trigger && React.cloneElement(trigger, { onClick: toggle })}
      <Portal>
        <RcDrawer
          placement={placement}
          open={isDrawerOpen}
          onMaskClick={close}
          handler={false}
          level={null}
          height="100%"
          onChange={setIsDrawerOpen}
          {...drawerProps}
        >
          {destroyOnClose && !isDrawerOpen
            ? null
            : typeof children === "function"
            ? React.Children.only(children({ close }))
            : children}
        </RcDrawer>
      </Portal>
    </>
  );
};

Drawer.propTypes = {
  //   className: PropTypes.string,
  isOpen: PropTypes.bool,
  placement: PropTypes.oneOf(["top", "right", "left", "right"]),
  // trigger: PropTypes.element.isRequired,
  //   maskable: PropTypes.bool,
  //   style: React.CSSProperties,
  //   maskStyle: React.CSSProperties,
};
Drawer.defaultProps = {
  //   className: '',
  isOpen: false,
  placement: "right",
  //   maskable: true,
  //   style: {},
  //   maskStyle: {},
};

export { Drawer };
export default Drawer;
