import * as React from "react";
import Styled from "styled-components";
import csx from "classnames";
import { Condition } from "components/elements";
import { Text, View, Radio, Button } from "components/elementsThemed";
import { useCell } from "hooks";
import { formatPrice } from "utils/helpers/string";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import css from "./item.module.scss";

const { Condition: If } = Condition;

const Item = Styled(
  ({
    className,
    decrement,
    increment,
    item = {},
    multiSelect,
    onClick,
    toggleDrawer,
    quantity = 0,
    type,
  }) => {
    const { isNested = false, name = "", price = 0 } = item;
    const { buttons, labelTextStyles, views } = useCell(type);
    const itemNameWithoutADD = item.name
      .replace(/\(.*\)/gs, "")
      .replace("ADD", "")
      .replace("EXTRA", "");

    return multiSelect ? (
      <View
        Component="li"
        className={`${css.modifierItem} ${className}`}
        active={quantity > 0 ? "1" : "0"}
        data-test={name}
        data-cy="modifier-add-button"
        type={views.background}
      >
        <div className={css["modifierItem-container"]}>
          <button
            aria-label={`${name}${price > 0 ? ` $${price}` : ""}`}
            aria-pressed={quantity === 0 ? "false" : "true"}
            type="button"
            className={css["modifierItem-multiselect-button"]}
            onClick={onClick}
            style={{
              pointerEvents: quantity === 0 ? "auto" : "none",
            }}
          />
          <div className={css["modifierItem-container"]}>
            <div className={css["modifierItem-container--left"]}>
              <Text aria-hidden="true" type={labelTextStyles.primary}>
                {name}
              </Text>
            </div>
            <div className={css["modifierItem-container--right"]}>
              <If is={price}>
                <Text
                  aria-hidden="true"
                  type={labelTextStyles.secondary}
                  className={css.price}
                >
                  {formatPrice(price)}
                </Text>
              </If>
              <div className={csx(css.selectors, quantity && css.selected)}>
                <If is={quantity !== 0}>
                  <Button
                    aria-label={`Decrease ${itemNameWithoutADD} quantity`}
                    type={buttons.primary}
                    className={csx(css["selectors-quantityBtn"])}
                    onClick={decrement}
                  >
                    <span aria-hidden="true">-</span>
                  </Button>

                  <Text aria-live="polite" type={labelTextStyles.tertiary}>
                    <VisuallyHidden>{name} quantity: </VisuallyHidden>
                    {quantity}
                  </Text>
                </If>
                <Button
                  aria-label={`Increase ${itemNameWithoutADD} quantity`}
                  type={buttons.primary}
                  className={csx(css["selectors-quantityBtn"])}
                  onClick={quantity > 0 ? increment : onClick}
                  tabindex={quantity > 0 ? "0" : "-1"}
                  aria-hidden={quantity > 0 ? "false" : "true"}
                >
                  <span aria-hidden="true">+</span>
                </Button>
              </div>
              <If is={isNested}>
                <Button
                  aria-label={`Modify ${name}`}
                  onClick={toggleDrawer}
                  aria-hidden={quantity > 0 ? "false" : "true"}
                  tabindex={quantity > 0 ? "0" : "-1"}
                >
                  <div
                    className={css["selectors-nested"]}
                    aria-hidden="true"
                    active={quantity > 0 ? "1" : "0"}
                  />
                </Button>
              </If>
            </div>
          </div>
        </div>
      </View>
    ) : (
      <View
        Component="li"
        className={`${css.modifierItem} ${className}`}
        data-test={name}
        data-cy="modifier-add-button"
        type={views.background}
      >
        <div className={css["modifierItem-container-button"]} onClick={onClick}>
          <div className={css["modifierItem-container--left"]}>
            <Text aria-hidden="true" type={labelTextStyles.primary}>
              {name}
            </Text>
          </div>
          <div className={css["modifierItem-container--right"]}>
            <If is={price}>
              <Text
                aria-hidden="true"
                type={labelTextStyles.secondary}
                className={css.price}
              >
                {formatPrice(price)}
              </Text>
            </If>
            <Radio
              type={buttons.primary}
              className={css.radio}
              value={quantity}
              onChange={onClick}
            >
              <VisuallyHidden>
                {name}
                <If is={price}>{formatPrice(price)}</If>
              </VisuallyHidden>
            </Radio>
            <If is={isNested}>
              <Button
                aria-label={`Modify ${name}`}
                onClick={toggleDrawer}
                aria-hidden={quantity > 0 ? "false" : "true"}
                tabindex={quantity > 0 ? "0" : "-1"}
              >
                <div
                  className={css["selectors-nested"]}
                  aria-hidden="true"
                  active={quantity > 0 ? "1" : "0"}
                />
              </Button>
            </If>
          </div>
        </div>
      </View>
    );
  },
)`
  border-bottom-color: ${(props) => props.theme.colors.alternateGray}
`;

export default React.memo(Item);
