/* eslint-disable  no-unused-vars */
import * as React from "react";
import { useMenuContext } from "../../providers/Menu/MenuContext";

const withUpsells =
  (Component) =>
  ({ isMemoryContext = false, ...props }) => {
    const { upsellableItems, upsellsFetching: fetching } = useMenuContext();
    // Filtering the Upsell items by those that have a group to keep modifier items out of the rendered list of upsells
    const filteredUpsells = upsellableItems.filter((i) => i.group);

    const componentProps = {
      fetching,
      upsellItems: filteredUpsells,
    };
    return <Component {...componentProps} {...props} />;
  };

export default withUpsells;
