import * as React from "react";
import { config } from "utils";
import loadable from "@loadable/component";

const AsyncPage = loadable((props) => import(`./${props.page}`));

const CartLayoutSelector = (props) => {
  return (
    <AsyncPage page={config?.theme?.checkout?.layout || "Type1"} {...props} />
  );
};

export default CartLayoutSelector;
