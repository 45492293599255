import * as React from "react";
import { Formik, Form } from "formik";
import { isToday, isTomorrow } from "date-fns";

import { View, Text, Field, Button } from "components/elementsThemed";
import DrawerButton from "../../../../components/DrawerButton";
import { Loader } from "components/fragments";
import { withTemplate } from "components/hocs";
import { Copy } from "utils";
import { stringReplacer } from "utils/helpers/string";
import {
  mapTimeZone,
  displayScheduledAtTime,
  displayScheduledAtTimeDelivery,
} from "utils/helpers/time";
import { useResource } from "hooks";

import commonStyles from "../../../index.module.scss";
import styles from "./index.module.scss";

const { Select } = Field;

const ScheduleDate = ({
  order: {
    location,
    order: { diningOption },
    scheduledAt,
    setScheduledAt,
  },
  style,
  setHeader,
  onSuccess,
}) => {
  const [{ resource = [], fetching }] = useResource({
    data: {
      orderType: diningOption,
    },
    path: "/locations/schedule",
  });

  const { views, inputs, cells, buttons, labels } = style;

  const timeZone = location.timeZone
    ? `${Copy.CART_STATIC.DISPLAYED_TIME_ZONE} ${mapTimeZone(
        location.timeZone,
      )}`
    : "";

  const timeOptions = (orderType) => (i) => {
    const { unixTimestamp, dayOfWeek } = i;
    const dayAsJSDate = new Date(unixTimestamp * 1000);
    const isDayToday = isToday(dayAsJSDate);
    const isDayTomorrow = isTomorrow(dayAsJSDate);
    const formatter =
      orderType === "delivery"
        ? displayScheduledAtTimeDelivery
        : displayScheduledAtTime;
    let prettyDay = dayOfWeek;

    if (isDayToday) {
      prettyDay = "Today";
    } else if (isDayTomorrow) {
      prettyDay = "Tomorrow";
    }
    return (
      <option key={unixTimestamp} value={unixTimestamp}>
        {`${prettyDay} ${formatter(unixTimestamp, location.timeZone)}`}
      </option>
    );
  };

  React.useEffect(() => {
    if (setHeader) {
      setHeader(
        stringReplacer(Copy.CART_STATIC.SCHEDULE_DINING_DATE_HEADER, [
          { replaceTarget: "{diningOption}", replaceValue: diningOption },
        ]),
      );
    }
  }, []);

  if (fetching) {
    return (
      <View type={views.background} className={commonStyles.container}>
        <div className={commonStyles.loader}>
          <Loader />
        </div>
      </View>
    );
  }

  return (
    <Formik
      initialValues={{
        date: scheduledAt || (resource.length ? resource[0].unixTimestamp : ""),
      }}
      validateOnChange={false}
      onSubmit={(values) => {
        setScheduledAt(values.date);
        onSuccess();
      }}
      render={({ errors, values, status, setFieldValue, isSubmitting }) => (
        <View
          type={views.background}
          className={commonStyles.container}
          Component={Form}
        >
          <div className={styles["fields-container"]}>
            <Select
              type={inputs.standard}
              label="Date"
              error={errors.date}
              value={values.date}
              onChange={(e) => setFieldValue("date", e.target.value)}
            >
              {!resource?.[0] && (
                <option key="" value="">
                  Order ahead is unavailable at this time
                </option>
              )}
              {resource.map(timeOptions(diningOption))}
            </Select>
            {status && <Text type={style.labels.error}>{status}</Text>}
            <Text type={labels.description}>{timeZone}</Text>
          </div>
          <div className={styles.footer}>
            <Button
              type={buttons.alternate}
              className={styles["reset-btn"]}
              htmlType="button"
              block
              onClick={() => {
                setScheduledAt(null);
                onSuccess();
              }}
              disabled={isSubmitting}
            >
              {stringReplacer(Copy.CART_STATIC.SET_DINING_TIME, [
                { replaceTarget: "{diningOption}", replaceValue: diningOption },
              ])}
            </Button>
          </div>
          <DrawerButton
            buttonProps={{
              htmlType: "submit",
              disabled: isSubmitting || !resource.length,
            }}
          >
            {stringReplacer(Copy.CART_STATIC.CONFIRM_DINING_TIME, [
              { replaceTarget: "{diningOption}", replaceValue: diningOption },
            ])}
          </DrawerButton>
        </View>
      )}
    />
  );
};

const exported = withTemplate(ScheduleDate, "scheduleDate");
exported.displayName = "ScheduledDateType1";
export default exported;
