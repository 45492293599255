import * as React from "react";
import { config } from "utils";
import { MODIFIER_TYPES } from "../../../../utils/constants";
import withModifierItem from "./withModifierItem";
import ModItemType1 from "./Type1";
import ModItemType2 from "./Type2";
import ModItemType6 from "./Type6";
import ModItemType8 from "./Type8";
import ModItemType9 from "./Type9";
import ModItemType10 from "./Type10";
import ModItemType11 from "./Type11";
import ModItemType12 from "./Type12";
import { PlacementModifierItem } from "./Type13/placementModifierItem";
import { ToppingAmountModifierItem } from "./Type14/toppingAmountModifierItem";

const ModifierTypeMap = {
  Type1: ModItemType1,
  Type2: ModItemType2,
  Type3: ModItemType2,
  Type4: ModItemType2,
  Type5: ModItemType2,
  Type6: ModItemType6,
  Type7: ModItemType2,
  Type8: ModItemType8,
  Type9: ModItemType9,
  Type10: ModItemType10,
  Type11: ModItemType11,
  Type12: ModItemType12,
  Type13: PlacementModifierItem,
  Type14: ToppingAmountModifierItem,
};

const getModifierItemType = ({
  modifierType,
  modifierConfigType = "Type1",
}) => {
  if (modifierType === MODIFIER_TYPES.PLACEMENT) {
    return "Type13";
  }
  if (modifierType === MODIFIER_TYPES.OPTION) {
    return "Type14";
  }
  if (modifierConfigType.includes("Type2")) {
    return "Type2";
  }
  return modifierConfigType;
};

const Selector = (props) => {
  let Component = null;

  const Type = getModifierItemType({
    modifierType: props.modifierType,
    modifierConfigType: config.theme.item_details.modifier_items,
  });

  Component = withModifierItem(ModifierTypeMap[Type]);

  return <Component {...props} />;
};

export default Selector;
