import * as React from "react";
import axios from "axios";
import { merge } from "lodash";
import { DefaultTheme, ThemeProvider } from "styled-components";

import defaultTheme from "__fixtures__/theme";
import themeData from "ThemeData";
import { parseTheme, getThemeData, TemplateContextVals } from "./util";

interface TemplateProviderProps {
  children: React.ReactNode;
  userTheme?: string;
  env: string;
}

export const DEFAULT_TEMPLATE_CONTEXT = {
  style: "normal",
  theme: defaultTheme,
  parsedTheme: parseTheme(defaultTheme),
};

export const TemplateContext = React.createContext<TemplateContextVals>(
  DEFAULT_TEMPLATE_CONTEXT,
);

export const useTemplateContext = () => {
  const contextValues = React.useContext(TemplateContext);
  if (!contextValues) {
    throw new Error(
      "useTemplateContext is being accessed outside a TemplateContextProvider",
    );
  }
  return contextValues;
};

export default function TemplateProvider({
  children,
  userTheme,
  env,
}: TemplateProviderProps) {
  const [theme, setTheme] = React.useState(
    getThemeData(merge(defaultTheme, themeData)),
  );
  const [parsedTheme] = React.useState(parseTheme(theme));
  const [style] = React.useState("normal");

  React.useEffect(() => {
    if (userTheme && env !== "local") {
      const getClientThemeFile = async (clientName: string) => {
        const themeLink = `https://assets.lunchbox.io/${clientName}/data/theme-${env}.json`;
        try {
          const { data } = await axios.get(themeLink);
          setTheme(getThemeData(data));
        } catch (e) {
          console.error("no theme file on server");
        }
      };

      getClientThemeFile(userTheme);
    }
  }, [userTheme, env]);

  const themeValues: DefaultTheme = {
    buttons: theme?.elements?.buttons,
    colors: theme?.colors,
    fonts: theme?.fonts,
  };

  const contextValues = React.useMemo(
    () => ({
      parsedTheme,
      style,
      theme,
    }),
    [theme, parsedTheme, style],
  );

  return (
    <TemplateContext.Provider value={contextValues}>
      <ThemeProvider theme={themeValues}>{children}</ThemeProvider>
    </TemplateContext.Provider>
  );
}

TemplateProvider.defaultProps = {
  userTheme: "",
};

export { parseTheme };
