import * as React from "react";
import loadable from "@loadable/component";

const AsyncPage = loadable((props) => import(`./${props.page}`));

/**
 * CreditBased loyalty system section selector for future flexibility - work in progress
 *
 * @author Htin Linn Aung
 * @param props.loyaltyData.loyaltyData
 * @param props.loyaltyData.loyaltyLayout
 * @param props.loyaltyData.loyaltyData.loyaltyData
 * @param props.loyaltyData.loyaltyData.loyaltyLayout
 * @memberof Templates.Templates/Loyalty
 * @param {object} props.loyaltyData - fetched data from Loyalty component
 */
const CreditBased = ({ loyaltyData, loyaltyLayout }) => {
  return (
    <AsyncPage page={loyaltyLayout || "Type1"} loyaltyData={loyaltyData} />
  );
};

export default CreditBased;
