import { withTypeSelector } from "components/hocs";
import { config } from "utils";
import Type1 from "./Type1";

export default withTypeSelector(
  {
    Type1,
  },
  config.theme.packing_instructions.group,
  Type1
);
