import { constants } from "utils";
import { eventHandler } from "../tools";

const {
  ANALYTICS: { CART_CLEARED, CART_VIEWED },
} = constants;

export default {
  cleared: eventHandler(CART_CLEARED),
  viewed: eventHandler(CART_VIEWED),
};
