import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { Text, View, Cell } from "components/elementsThemed";
import { Copy } from "utils";
import { formatPrice } from "utils/helpers/string";
import styles from "./groupType2.module.scss";

const Group = ({ items, selected, types, onSelect, error }) => {
  const onClick = (id) => () => {
    onSelect(id);
  };

  return (
    <div className={styles.container}>
      <Cell
        type={types.group}
        render={({ views, labelTextStyles }) => (
          <View type={views.background} className={styles.option}>
            <Row spacing={15}>
              <Col>
                <Text type={labelTextStyles.secondary}>
                  {Copy.ITEM_DETAILS_STATIC.SELECT_TEXT}
                </Text>
              </Col>
              {error && (
                <div>
                  <Text type={labelTextStyles.tertiary}>
                    {Copy.ITEM_DETAILS_STATIC.SELECTION_EMPTY_ERROR}
                  </Text>
                </div>
              )}
            </Row>
          </View>
        )}
      />
      <Row gutter={15}>
        {items.map((i) => (
          <Col key={i.id} xs="1-2" sm="1-3">
            <Cell
              type={i.id === selected ? types.selected : types.default}
              render={({ labelTextStyles, views }) => (
                <View
                  aria-pressed={i.id === selected ? "true" : "false"}
                  Component="button"
                  type={views.background}
                  className={styles.item}
                  onClick={onClick(i.id)}
                >
                  <div className={styles.name}>
                    <Text type={labelTextStyles.primary}>{i.name}</Text>
                  </div>
                  <div className={styles.desc}>
                    <Text type={labelTextStyles.secondary}>
                      {i.description}
                    </Text>
                  </div>
                  <div className={styles.price}>
                    <Text type={labelTextStyles.secondary}>
                      {formatPrice(i.price)}
                    </Text>
                  </div>
                </View>
              )}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

const GroupType2 = React.memo(Group);
export default GroupType2;
