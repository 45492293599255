import * as React from "react";
import loadable from "@loadable/component";
import { config } from "utils";

const AsyncPage = loadable((props) => import(`./${props.pages}`));

const Selector = (props) => {
  return (
    <AsyncPage pages={config?.theme?.menu?.layout || "Type1"} {...props} />
  );
};

export default Selector;
