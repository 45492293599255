/* eslint-disable @typescript-eslint/no-inferrable-types */
import { config, constants } from "utils";

const {
  ORDER_TYPES: { PICKUP, DELIVERY, KIOSK },
} = constants;

/**
 * It converts a decimal number into percent
 *
 * @param decimal
 * @returns
 */
export const convertDecimalToPercentage = (decimal: number) => {
  const res = decimal * 100;
  return res.toString();
};

/**
 * It converts a decimal number into percent
 *
 * @param total
 * @param percentage
 * @returns
 */
export const mapPercentageToString = (total: number, percentage: number) => {
  if (!Number.isNaN(total) && !Number.isNaN(percentage)) {
    const result = (total * percentage).toFixed(2);
    return result;
  }
  return 0;
};

/**
 * it returns the defaultTip and the tipPercent (defaultTip:0.15, tipPercent:15%)
 *
 * @param orderType
 * @returns
 */
export const getDefaultTipPercentFromOrderType = (orderType: string) => {
  let tipPercent = 0;
  let tip = 0;
  switch (orderType) {
    case DELIVERY:
      tipPercent = config.delivery.tip_default;
      tip = config.delivery.tip_default;
      break;
    case PICKUP:
      tipPercent = config.pickup.tip_default;
      tip = config.pickup.tip_default;
      break;
    case KIOSK:
      tipPercent = config.kiosk.tip_default;
      tip = config.kiosk.tip_default;
      break;
    default:
      tipPercent = 0;
      tip = 0;
  }
  const percentage = convertDecimalToPercentage(tipPercent);
  const defaultTip = tip;

  return {
    percentage,
    defaultTip,
  };
};

/**
 * it returns the percentage of the custom tip
 *
 * @param total
 * @param customAmount
 * @returns
 */
export const customAmountToPercentage = (
  total: number,
  customAmount: number,
) => {
  if (!Number.isNaN(total) && total === 0) {
    return 0;
  }
  if (!Number.isNaN(total) && !Number.isNaN(customAmount)) {
    return Math.round((customAmount * 100) / total);
  }
};

// if client accentLight is white return  black.
export const transformActiveColorToBlack = (hexcolor: string = "") => {
  //  remove #
  if (hexcolor.slice(0, 1) === "#") {
    hexcolor = hexcolor.slice(1);
  }
  // Convert to RGB value
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);

  if (r >= 245 && g >= 245 && b >= 245) {
    return "#000000";
  }
  return `#${hexcolor}`;
};

export const parseTipInput = (value: string) => {
  let tip = +Number(value).toFixed(2);
  tip = Math.min(tip, 1000);
  return tip;
};
