import { Login } from "./Login";
import { Signup } from "./Signup";
import { UpdatePhone } from "./UpdatePhone";
import ItemDetails from "./ItemDetails";
import { DeliveryForm } from "./DeliveryForm";

import PaymentForm from "./PaymentForm";
import { Loyalty } from "./Loyalty";
import { LocationList } from "./LocationList";
import { KioskForm } from "./KioskForm";
import { PickupForm } from "./PickupForm";
import LoginSignup from "./LoginSignup";
import { UpdateEmail } from "./UpdateEmail";
import LoyaltyInfo from "./LoyaltyInfo";
import * as ForgotPassword from "./ForgotPassword";
import { SignUpConfirmation } from "./SignUpConfirmation";
import { RestaurantsFilter } from "./RestaurantsFilter";
import { RestaurantsList } from "./RestaurantsList/RestaurantListSelector";
import { TrackingMap } from "./TrackingMap";

/**
 * @namespace Templates
 */

export {
  DeliveryForm,
  ForgotPassword,
  ItemDetails,
  KioskForm,
  LocationList,
  Login,
  LoginSignup,
  Loyalty,
  LoyaltyInfo,
  PaymentForm,
  PickupForm,
  RestaurantsFilter,
  RestaurantsList,
  Signup,
  SignUpConfirmation,
  TrackingMap,
  UpdateEmail,
  UpdatePhone,
};

export default {
  DeliveryForm,
  ForgotPassword,
  ItemDetails,
  KioskForm,
  LocationList,
  Login,
  LoginSignup,
  Loyalty,
  LoyaltyInfo,
  PaymentForm,
  PickupForm,
  RestaurantsFilter,
  RestaurantsList,
  Signup,
  SignUpConfirmation,
  TrackingMap,
  UpdateEmail,
  UpdatePhone,
};
