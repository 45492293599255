import * as React from "react";
import { AnimatePresence } from "framer-motion";
import { FocusScope } from "@react-aria/focus";

import BottomSheet from "components/fragments/BottomSheet";
import { Condition } from "components/elements";
import GiftCardModal from "../../Order/GiftCard/GiftCardModal/GiftCardModal";
import GiftCardField from "../../Order/GiftCard/GiftCardField/GiftCardField";
import GiftCardSuccessPopup from "../../Order/GiftCard/GiftCardSuccessPopup/GiftCardSuccessPopup";
import { getAppliedGiftCardText } from "../../Order/GiftCard/util";
import { Text, Radio } from "components/elementsThemed";
import { constants, Copy, config, Routes } from "utils";
import { useCell } from "hooks/useTemplate";

import OrderSummary from "../../Order/components/OrderSummary";
import Field from "../../Order/Field";
import CheckoutForm from "./CheckoutForm";
import styles from "../checkoutType2.module.scss";

const { Condition: If } = Condition;

const {
  ORDER_TYPES: { DELIVERY },
} = constants;

const PaymentTab = ({
  availablePaymentTypes,
  clearDiscount,
  error,
  history,
  invalidDiscount,
  isCash,
  isMobilePay,
  mobilePayEnabled,
  orderContext,
  patronContext,
  paymentFormRef,
  placeOrder,
  resource,
  setButtonDisabled,
  setErrorMessage,
  setIsCash,
  setIsMobilePay,
  setTip,
  stripePromise,
  theme,
  tip,
  toDiscount,
  setShowCustomTipModal,
  ...props
}) => {
  // Constants
  const { labelTextStyles } = useCell("cartHeader");
  const { labels, cells, buttons } = theme;
  const { customerCard } = resource;
  const { diningOption } = orderContext.order;
  const { isLoggedIn } = patronContext;
  const toFetchPayment = (e) => {
    e.preventDefault();
    history.push(Routes.FETCH_CARDS);
  };

  let tipAmount = tip;
  let showTip = true;
  let tipLabel = null;
  let ccText = "No Card";

  const [isGiftCardModalOpen, setGiftCardModalOpen] = React.useState(false);
  const [isGiftCardSuccessPopupOpen, setGiftCardSuccessPopupOpen] =
    React.useState(false);

  const isGiftCardAdded = orderContext?.order?.giftCard?.giftCardNumber;
  const giftCardFieldValue = isGiftCardAdded
    ? getAppliedGiftCardText(orderContext?.order?.giftCard)
    : "Redeem A Gift Card";
  // Possible giftCardType values are "modal" and "inline"
  const giftCardType = config?.theme?.checkout?.gift_card_type ?? "modal";

  if (isCash) {
    ccText = "Paying with Cash";
    showTip = false;
  } else if (customerCard && customerCard.last4) {
    ccText = `${customerCard.brand} ending in ${customerCard.last4}`;
  }
  if (!config[diningOption].tip) {
    showTip = false;
    tipAmount = 0;
  }
  if (diningOption === DELIVERY && Copy.CART_STATIC.DELIVERY_TIP_HEADER_TEXT) {
    tipLabel = Copy.CART_STATIC.DELIVERY_TIP_HEADER_TEXT;
  } else if (Copy.CART_STATIC.PICKUP_TIP_HEADER_TEXT) {
    tipLabel = Copy.CART_STATIC.PICKUP_TIP_HEADER_TEXT;
  } else {
    tipLabel = Copy.CART_STATIC.TIP_HEADER_TEXT;
  }

  // Render
  return (
    <>
      {/**
       * @todo seperate into mobilePayField and cardPayField
       * */}
      {(isMobilePay || (isLoggedIn && !isCash)) && (
        <div
          className={styles["checkout-field"]}
          role="button"
          tabIndex="-1"
          onClick={!mobilePayEnabled && toFetchPayment}
          onKeyDown={!mobilePayEnabled && toFetchPayment}
        >
          <Field
            className="payment-details"
            type={cells.primary}
            label={
              mobilePayEnabled
                ? "Payment Method"
                : Copy.PAYMENT_FORM_STATIC.PAYMENT_TAB_CARD_LABEL
            }
            value={
              mobilePayEnabled
                ? availablePaymentTypes?.applePay
                  ? "Apple Pay"
                  : "Mobile Payment"
                : ccText
            }
            disabled={isCash}
            buttonProps={
              !mobilePayEnabled
                ? {
                    children: "Edit",
                  }
                : {}
            }
          />
        </div>
      )}

      {config?.theme?.checkout?.gift_card === true && giftCardType === "modal" && (
        <Field
          type={cells.primary}
          label="Gift Card"
          value={giftCardFieldValue}
          buttonProps={{
            onClick: isGiftCardAdded
              ? orderContext.resetGiftCard
              : () => setGiftCardModalOpen(true),
            children: isGiftCardAdded ? "Remove" : "Add",
          }}
        />
      )}

      {config?.theme?.checkout?.gift_card === true &&
        giftCardType === "inline" && (
          <GiftCardField
            onGiftCardAdded={({ balance, giftCardNumber, pinCode }) => {
              orderContext.setGiftCard({
                balance,
                giftCardNumber,
                pinCode,
              });
              setGiftCardSuccessPopupOpen(true);
            }}
            onGiftCardRemoved={orderContext.resetGiftCard}
            isPinCodeRequired={orderContext?.location?.isPinCodeRequired}
            giftCard={orderContext?.order?.giftCard}
          />
        )}

      {isGiftCardSuccessPopupOpen && (
        <>
          <div className={styles.bottomSheetOverlay} style={{ top: 0 }} />
          <FocusScope contain restoreFocus autoFocus>
            <GiftCardSuccessPopup
              onConfirm={() => setGiftCardSuccessPopupOpen(false)}
            />
          </FocusScope>
        </>
      )}

      {stripePromise && !isLoggedIn && !isMobilePay && !isCash && (
        <CheckoutForm
          innerRef={paymentFormRef}
          orderContext={orderContext}
          patronContext={patronContext}
          setErrorMessage={setErrorMessage}
          setButtonDisabled={setButtonDisabled}
          placeOrder={placeOrder}
          history={history}
          {...props}
        />
      )}

      {error && (
        <Text type={labels.error} className={styles["checkout-error"]}>
          {error}
        </Text>
      )}

      {config.ordering.cash && (
        <Radio
          type={buttons.cash}
          label={
            <Text type={labels.cash}>
              {Copy.CART_STATIC.PAY_WITH_CASH_OPTION_TEXT}
            </Text>
          }
          className={styles["checkout-radio"]}
          value={isCash}
          onChange={() => setIsCash(!isCash)}
        />
      )}

      <If is={Object.keys(availablePaymentTypes).length}>
        <Radio
          type={buttons.mobile}
          label={
            <Text type={labels.mobile}>
              {availablePaymentTypes?.applePay
                ? "Pay with Apple Pay"
                : "Use Mobile Payment"}
            </Text>
          }
          className={styles["checkout-radio"]}
          value={isMobilePay}
          onChange={() => {
            /**
             * @todo use the functional form here to avoid update anomalies: https://reactjs.org/docs/hooks-reference.html#functional-updates
             */
            setIsMobilePay((prevState) => !prevState);
          }}
        />
      </If>

      <OrderSummary
        theme={theme}
        styles={styles}
        resource={resource}
        orderContext={orderContext}
        toDiscount={toDiscount}
        clearDiscount={clearDiscount}
        invalidDiscount={invalidDiscount}
        showTip={showTip}
        setTip={setTip}
        setShowCustomTipModal={setShowCustomTipModal}
        tipAmount={tipAmount}
      />
      <AnimatePresence>
        {isGiftCardModalOpen && (
          <>
            <div className={styles.bottomSheetOverlay} />
            <FocusScope contain restoreFocus autoFocus>
              <BottomSheet
                labelTextStyles={labelTextStyles}
                onClose={() => setGiftCardModalOpen(false)}
                title="Gift Card"
              >
                <GiftCardModal
                  onGiftCardAdded={({ balance, giftCardNumber, pinCode }) => {
                    orderContext.setGiftCard({
                      balance,
                      giftCardNumber,
                      pinCode,
                    });
                    setGiftCardModalOpen(false);
                    setGiftCardSuccessPopupOpen(true);
                  }}
                  isPinCodeRequired={orderContext?.location?.isPinCodeRequired}
                />
              </BottomSheet>
            </FocusScope>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default PaymentTab;
