import * as React from "react";
import { Formik, Form } from "formik";

import { PATRON_FORGOT_PASSWORD } from "utils/api";
import classNames from "classnames";
import { axios, Schemas, Copy } from "../../../utils";
import DrawerButton from "../../DrawerButton";
import { Text, View } from "../../elementsThemed";
import Input from "../../elementsThemed/Field/Input/input";
import { withTemplate } from "../../hocs";

import styles from "../form.module.scss";
import additionalStyles from "./ForgotPassword.module.scss";

const ForgotPassword = ({ email = "", onSuccess, style }) => {
  const sendCode = async (values, actions) => {
    actions.setFieldError("email", "");
    actions.setStatus("");
    const valuesTemp = { ...values };
    valuesTemp.email = valuesTemp.email.trim();
    try {
      await PATRON_FORGOT_PASSWORD(valuesTemp);
      if (onSuccess) onSuccess(valuesTemp.email);
      actions.resetForm();
    } catch (error) {
      const e = axios.handleError(error);
      actions.setStatus(e.data);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        email,
      }}
      initialStatus={
        Copy.FORGOT_PASSWORD_STATIC?.INITIAL_FORGET_PASSWORD_STATUS
      }
      validationSchema={Schemas.EmailSchema}
      onSubmit={sendCode}
      render={({ errors, values, status, setFieldValue, isSubmitting }) => (
        <View
          type={style.views.background}
          className={classNames(styles.form, additionalStyles.forgetPassword)}
          Component={Form}
        >
          <div
            className={classNames(
              styles["fields-container"],
              additionalStyles["forgetPassword-container"],
            )}
          >
            <Input
              label={Copy.FORGOT_PASSWORD_STATIC.EMAIL_INPUT_LABEL}
              name="email"
              type={style.inputs.standard}
              error={errors.email}
              value={values.email}
              placeholder={Copy.FORGOT_PASSWORD_STATIC.EMAIL_INPUT_PLACEHOLDER}
              onChange={(e) => setFieldValue("email", e.target.value)}
              inputMode="email"
            />

            <div>
              {status && (
                <Text
                  className={additionalStyles["forgetPassword-status"]}
                  type={style.labels.error}
                >
                  {status}
                </Text>
              )}
            </div>
          </div>
          <DrawerButton
            buttonProps={{ htmlType: "submit", disabled: isSubmitting }}
          >
            {Copy.FORGOT_PASSWORD_STATIC.SEND_PIN_BUTTON_TEXT}
          </DrawerButton>
        </View>
      )}
    />
  );
};

export default withTemplate(ForgotPassword, "password");
