import * as React from "react";
import classnames from "classnames";
import Styled from "styled-components";
import { useTemplateContext } from "components/providers/Template";
import { images } from "utils";
import Flex from "components/elements/Layout/flex";
import css from "./view.module.scss";

const BUTTON_ARROW = images?.button_arrow;
const withTemplate = (Component: any) =>
  function (props: any) {
    const { type, layout } = props;
    const {
      parsedTheme: { views },
    } = useTemplateContext();
    return (
      <Component
        {...props}
        view={views[type] || undefined}
        layout={layout ?? "vertical"}
      />
    );
  };

const UnmemoizedView = withTemplate(Styled(
  ({
    children,
    className,
    Component = "div",
    handleScroll,
    innerRef,
    showArrow = false,
    type,
    view,
    ...props
  }) => {
    const classes = classnames(css[type], className);

    return (
      <Component
        className={classes}
        ref={innerRef}
        {...props}
        data-value={type}
      >
        {showArrow && (
          <>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img
              className={css.buttonBack}
              src={BUTTON_ARROW}
              alt="back"
              onClick={() => handleScroll("back")}
              onKeyDown={() => handleScroll("back")}
            />
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img
              className={css.buttonNext}
              src={BUTTON_ARROW}
              alt="next"
              onClick={() => handleScroll("next")}
              onKeyDown={() => handleScroll("next")}
            />
          </>
        )}
        {children}
      </Component>
    );
  },
)`
  ${({ layout }) => {
    let t;
    if (layout === "horizontal") {
      t = `
          display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          `;
    }
    return [t].join(";");
  }}
`);

const View = React.memo(UnmemoizedView);

export { View };
export default View;
