import * as React from "react";
import { Order } from "components/providers";
import { useMenuContext } from "components/providers/Menu/MenuContext";
import { stringReplacer } from "utils/helpers/string";
import { Copy } from "utils";

const typeOfValidationError = (group, itemsWithGroupLength) => {
  if (
    (group.min && !itemsWithGroupLength) ||
    itemsWithGroupLength < group.min
  ) {
    return 1;
  }
  if (group.max && itemsWithGroupLength > group.max) {
    return 2;
  }
  return 0;
};

const validationErrors = (groupsHash, groupsArray, selectedItems) => {
  return groupsArray.reduce((accu, groupValue) => {
    const groupId = groupValue.id;
    const group = groupId && groupsHash?.[groupId];

    if (!group) return accu;

    const type = typeOfValidationError(group, selectedItems?.[groupId]?.length);

    switch (type) {
      // Minimum was not met for this group
      case 1:
        accu.push({
          groupId,
          message: stringReplacer(
            Copy.ITEM_DETAILS_STATIC.GROUP_SELECTION_MIN_SELECT_ITEM,
            [{ replaceTarget: "{groupMin}", replaceValue: group.min }],
          ),
        });
        break;
      // Maximum was not met for this group
      case 2:
        accu.push({
          groupId,
          message: stringReplacer(
            Copy.ITEM_DETAILS_STATIC.GROUP_SELECTION_MAX_SELECT_ITEM,
            [{ replaceTarget: "{groupMax}", replaceValue: group.max }],
          ),
        });
        break;
      default:
    }
    return accu;
  }, []);
};

const withPackingInstructions =
  (Component) =>
  ({ isMemoryContext = false, ...props }) => {
    const { packingMenu } = useMenuContext();

    const {
      packingItems,
      addPackingItemToOrder,
      removePackingItemFromOrder,
      clearPackingItems,
    } = React.useContext(
      isMemoryContext ? Order.MemoryOrder.Context : Order.OrderContext,
    );

    const [errors, setErrors] = React.useState([]);

    // Initialize with existing packingItems from context and structure appropriately
    const [selectedItems, setSelectedItems] = React.useState(
      packingItems.reduce(
        (accu, i) => ({
          ...accu,
          [i.group]: accu[i.group] ? accu[i.group].concat([i.item]) : [i.item],
        }),
        {},
      ) || {},
    );

    const packingItemsHash = packingItems.reduce(
      (accu, i, index) => ({ ...accu, [i.item]: index }),
      {},
    );

    const validatePackingItems = () => {
      const {
        groups: { hash: groupsHash, array: groupsArray },
      } = packingMenu;

      const newErrors = validationErrors(
        groupsHash,
        groupsArray,
        selectedItems,
      );

      setErrors(newErrors);

      return newErrors;
    };

    const onUpdateSelectedItems = (groupId, items) =>
      setSelectedItems((oldItems) => {
        return { ...oldItems, [groupId]: items };
      });

    const onRemoveItem = (index) => {
      removePackingItemFromOrder(index);
    };

    const onAddItem = (groupId, itemId, quantity) => {
      const {
        groups: { hash: groupsHash },
        items: { hash: itemsHash },
      } = packingMenu;
      const item = itemsHash[itemId];
      const group = groupsHash[groupId];
      if (!item || !group) return;
      // Adding 1 to account for the item that will be added
      const itemsWithGroup =
        packingItems.filter((i) => i.group === groupId).length + 1;
      const type = typeOfValidationError(group, itemsWithGroup);
      if (type && type === 2 && itemsWithGroup) {
        const firstItemWithGroupIndex = packingItems.findIndex(
          (i) => i.group === groupId,
        );
        onRemoveItem(firstItemWithGroupIndex);
      }
      addPackingItemToOrder(
        {
          group: groupId,
          isGroup: false,
          item: itemId,
          mods: [],
          name: item.name,
          price: item.price,
          resGroupId: packingMenu?.menus?.array?.[0]?.restaurantGroup,
        },
        quantity,
      );
    };

    const onAddGroupItems = (groupId, itemIds) => {
      itemIds.forEach((itemId) => onAddItem(groupId, itemId));
    };

    const onAddItems = () => {
      Object.entries(selectedItems).map(([key, value]) =>
        onAddGroupItems(key, value),
      );
    };

    React.useEffect(() => {
      validatePackingItems();
    }, [selectedItems]);

    const componentProps = {
      clearPackingItems,
      errors,
      onAddItem,
      onAddItems,
      onRemoveItem,
      onUpdateSelectedItems,
      packingItems: {
        array: packingItems,
        hash: packingItemsHash,
      },
      packingMenu,
    };

    return <Component {...componentProps} {...props} />;
  };

export default withPackingInstructions;

export { typeOfValidationError, validationErrors, withPackingInstructions };
