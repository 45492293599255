import * as React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { GoogleAnalytics, AttentiveMobile } from "./components/fragments";
import { usePatronContext } from "./components/providers/Patron/PatronContext";
import Header from "./Header";
import { config, constants } from "./utils";
import {
  Signup,
  GiftCard,
  Locations,
  Menu,
  Profile,
  Theme,
  MarketPlace,
} from "./pages";
import { OrderPage } from "./pages/Order";
import { MenuDiscovery } from "./pages/MarketPlace/MenuDiscovery/MenuDiscovery";
import styles from "./Routes.module.scss";

const { BUILD_ENV } = process.env;

const {
  ENVIRONMENTS: { PRODUCTION },
} = constants;

const ConditionalRoute = ({ isEnabled, ...props }) =>
  isEnabled ? <Route {...props} /> : null;

const Routes = ({ order, menu, diet, match, location, history, ...props }) => {
  // TODO: Avoid Prop Drilling
  const patron = usePatronContext();

  const renderProps = {
    menu,
    order,
    patron,
    selectedDiet: diet,
    ...props,
  };
  const isGiftCardEnabled = config?.routes?.gift_cards;
  const isOrderingEnabled = config?.routes?.ordering;
  const isMarketPlaceEnabled = config?.routes?.market;
  // BUG: Causing a redirect issue, trapping in locations...
  // useEffect(() => {
  //   if (globalConfig?.lastLocation) {
  //     history.push(globalConfig?.lastLocation);
  //   }
  // }, []);

  // TODO: Change <Locations /> to use order prop instead of spreading or avoid prop drilling
  return (
    <div className={styles.page}>
      {!(
        ["Type2"].includes(config.theme.location.layout) &&
        ["/"].includes(location.pathname)
      ) && <Header />}
      {[PRODUCTION].includes(BUILD_ENV) && config.apps.google_analytics && (
        <GoogleAnalytics id={config.apps.google_analytics} />
      )}
      {[PRODUCTION].includes(BUILD_ENV) && config.apps.attentive_mobile && (
        <AttentiveMobile id={config.apps.attentive_mobile} />
      )}
      <div className={styles["page-wrapper"]}>
        <Switch>
          <Route
            exact
            path="/"
            render={(routerProps) =>
              isOrderingEnabled ? (
                <Locations
                  {...routerProps}
                  {...order}
                  menu={menu}
                  patron={patron}
                />
              ) : null
            }
          />
          <Route
            exact
            path="/sign-up"
            render={(routerProps) => (
              <Signup
                isLoggedIn={patron.isLoggedIn}
                {...routerProps}
                {...renderProps}
              />
            )}
          />
          <ConditionalRoute
            isEnabled={BUILD_ENV !== PRODUCTION}
            path="/theme"
            component={Theme}
          />
          <ConditionalRoute
            exact
            isEnabled={isOrderingEnabled}
            path="/profile"
            render={(routerProps) => (
              <Profile {...routerProps} {...renderProps} />
            )}
          />
          <ConditionalRoute
            exact
            isEnabled={isMarketPlaceEnabled}
            path="/market/:slug/:orderType?"
            render={(routerProps) => {
              return <MarketPlace {...routerProps} {...renderProps} />;
            }}
          />
          <ConditionalRoute
            isEnabled={isMarketPlaceEnabled}
            exact
            path="/market/:slug/:orderType/menu-discovery"
            render={(routerProps) => (
              <MenuDiscovery {...routerProps} {...renderProps} />
            )}
          />
          ,
          <ConditionalRoute
            isEnabled={isOrderingEnabled}
            path="/location/:slug/:orderType?/:resGroupId?/:itemId?"
            render={(routerProps) =>
              !routerProps?.match?.params?.slug ? (
                <Redirect to="/" />
              ) : (
                <Menu
                  {...routerProps}
                  {...renderProps}
                  infoType={
                    isMarketPlaceEnabled &&
                    routerProps.match.params.orderType === "kiosk"
                      ? "location"
                      : undefined
                  }
                  hasFooter
                  bannerInfo={{
                    bannerLayout: config?.theme?.menu?.banner || "Type1",
                  }}
                />
              )
            }
          />
          <ConditionalRoute
            isEnabled={isOrderingEnabled}
            path="/checkout/:id?"
            render={(routerProps) =>
              !routerProps?.match?.params?.id ? (
                <Redirect to="/" />
              ) : (
                <OrderPage {...routerProps} {...renderProps} />
              )
            }
          />
          <ConditionalRoute
            isEnabled={isGiftCardEnabled}
            path="/card/:id?"
            render={(routerProps) =>
              !routerProps?.match?.params?.id ? (
                <Redirect to="/" />
              ) : (
                <GiftCard {...routerProps} {...renderProps} />
              )
            }
          />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(Routes);
