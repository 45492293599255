import * as React from "react";
import classnames from "classnames";
import styles from "./flex.module.css";

type FlexProps = {
  component?: React.ComponentType<any> | undefined;
  direction?: string;
  reverse?: boolean;
  justify?: string;
  align?: string;
  grow?: string | number;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

const directionClass = (direction, reverse) => {
  switch (direction) {
    case "row":
      return reverse ? styles["row-rev"] : styles.row;
    case "col":
      return reverse ? styles["col-rev"] : styles.col;
    default:
      return "";
  }
};
const alignClass = (align) => {
  switch (align) {
    case "start":
      return styles.start;
    case "end":
      return styles.end;
    case "center":
      return styles.center;
    case "baseline":
      return styles.baseline;
    default:
      return "";
  }
};
const justifyClass = (justify) => {
  switch (justify) {
    case "center":
      return styles["j-center"];
    case "start":
      return styles["j-start"];
    case "end":
      return styles["j-end"];
    case "left":
      return styles["j-left"];
    case "right":
      return styles["j-right"];
    case "between":
      return styles["j-between"];
    case "even":
      return styles["j-even"];
    default:
      return "";
  }
};

function Flex({
  component: Component,
  direction,
  reverse,
  justify,
  align,
  grow,
  className,
  style = {},
  ...props
}: FlexProps) {
  const innerRef = React.useRef();
  const classes = classnames(
    styles.base,
    directionClass(direction, reverse),
    alignClass(align),
    justifyClass(justify),
    className,
  );
  const inlineStyles = {
    ...(grow !== undefined ? { flexGrow: grow } : {}),
    ...style,
  };

  return (
    <Component
      ref={innerRef}
      className={classes}
      {...props}
      style={inlineStyles}
    />
  );
}

Flex.defaultProps = {
  component: "div",
  justify: "",
  align: "",
  grow: "",
  className: "",
  style: {},
  direction: "col",
  reverse: false,
  children: undefined,
};

export default Flex;
