export const CONFIRM_INPUT_LABEL = "Confirm Password";
export const CONFIRM_PASSWORD_INPUT_NAME = "password";
export const EMAIL_INPUT_LABEL = "Email";
export const EMAIL_INPUT_PLACEHOLDER = "Enter Email Here";
export const INPUT_LABEL = "Password";
export const INPUT_PLACEHOLDER = "Enter Password Here";
export const PASSWORD_INPUT_NAME = "password";
export const SEND_PIN_BUTTON_TEXT = "Send PIN Code";
export const UPDATE_PASSWORD_BUTTON_TEXT = " Update Password";
export const VERIFY_EMAIL_BUTTON_TEXT = "Confirm Code";
export const VERIFY_EMAIL_INPUT_LABEL = "Code";
export const VERIFY_EMAIL_INPUT_PLACEHOLDER = "PIN sent to email";
export const CONFIRM_PIN_STATUS_TEXT =
  "WE'LL SEND YOU A LINK VIA EMAIL IF THE EMAIL ADDRESS YOU PROVIDED IS IN OUR SYSTEM";
export const BACK_TO_LOGIN_BUTTON_TEXT = "BACK TO LOGIN";
export const INITIAL_FORGET_PASSWORD_STATUS = "";
export const VERIFY_EMAIL_INSTRUCTION_TEXT = "";

export default {
  BACK_TO_LOGIN_BUTTON_TEXT,
  CONFIRM_INPUT_LABEL,
  CONFIRM_PASSWORD_INPUT_NAME,
  CONFIRM_PIN_STATUS_TEXT,
  EMAIL_INPUT_LABEL,
  EMAIL_INPUT_PLACEHOLDER,
  INITIAL_FORGET_PASSWORD_STATUS,
  INPUT_LABEL,
  INPUT_PLACEHOLDER,
  PASSWORD_INPUT_NAME,
  SEND_PIN_BUTTON_TEXT,
  UPDATE_PASSWORD_BUTTON_TEXT,
  VERIFY_EMAIL_BUTTON_TEXT,
  VERIFY_EMAIL_INPUT_LABEL,
  VERIFY_EMAIL_INPUT_PLACEHOLDER,
  VERIFY_EMAIL_INSTRUCTION_TEXT,
};
