import * as React from "react";
import { Text, View } from "components/elementsThemed";
import { Card as CardComps, Errors } from "components/fragments";
import { Copy } from "utils";

import css from "./profile.module.scss";

type ProfileCardProps = {
  className?: string;
  viewType?: string;
  textType?: string;
  title?: string;
  titleStyle?: string;
  cardStyle?: string;
  children: React.ReactElement;
  componentStyle?: string;
};

const { Card } = CardComps;

const Error = ({ children }) => (
  <Errors message={<p>{Copy.PROFILE_STATIC.PROFILE_ERROR_MESSAGE}</p>}>
    {children}
  </Errors>
);

const ProfileCard = ({
  className,
  viewType,
  textType,
  title,
  titleStyle = css["profile-section-title"],
  cardStyle = css["profile-section"],
  children,
  componentStyle = css["profile-section-content"],
}: ProfileCardProps) => {
  return (
    <Card className={[className, cardStyle]}>
      <View type={viewType} className={titleStyle}>
        <Text as="h2" type={textType}>
          {title}
        </Text>
      </View>
      <Error>
        <View className={componentStyle}>{children}</View>
      </Error>
    </Card>
  );
};

export default ProfileCard;
