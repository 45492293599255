import * as React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import isEqual from "fast-deep-equal";
import useDeepCompareEffect from "use-deep-compare-effect";

import { OrderContext } from "./OrderContext";
import { orderReducer, itemReducer } from "./reducers";
import { orderActions, itemActions } from "./actions";
import {
  initialLocation,
  initialOrder,
  initialItems,
  initialPackingItems,
  initialRewardItems,
  DEFAULT_GIFT_CARD,
} from "./initializers";
import { constants, config } from "../../../utils";
import { useQueryState } from "../../../hooks/useQueryState";

const {
  ORDER_TYPES: { DELIVERY, KIOSK, PICKUP },
} = constants;

const defaultDiscount = {
  code: null,
  discount: null,
  promotionCodeId: null,
  promotionId: null,
};

const OrderProvider = ({ children, ...props }) => {
  const [giftCard, setGiftCard] = React.useState(DEFAULT_GIFT_CARD);
  const [location, setLocation] = React.useState(initialLocation);
  const [items, dispatchItems] = React.useReducer(itemReducer, initialItems());
  const [firstTime, setFirstTime] = React.useState(true);
  const [kioskInfoVisibility, setKioskInfoVisibility] = React.useState(true);

  const [packingItems, dispatchPackingItems] = React.useReducer(
    itemReducer,
    initialPackingItems(),
  );
  const [rewardItems, dispatchRewardItems] = React.useReducer(
    itemReducer,
    initialRewardItems(),
  );
  const [order, dispatchOrder] = React.useReducer(
    orderReducer,
    initialOrder(props?.location?.pathname, location),
  );

  const hasPromotion = !!(
    order?.discountId ??
    order?.promotionCodeId ??
    order?.promotionId ??
    false
  );

  const isDelivery = order.diningOption === DELIVERY;
  const isPickup = order.diningOption === PICKUP;
  const isKiosk = order.diningOption === KIOSK;

  const [
    setDeliveryAddress,
    setDeliveryNotes,
    setOrderType,
    setScheduledAt,
    setTableNumber,
    setDiscount,
    resetIdempotency,
  ] = orderActions(dispatchOrder);

  const [
    addToOrder,
    addManyItems,
    removeFromOrder,
    editItemAtIndex,
    clearItems,
  ] = itemActions(dispatchItems);

  const [
    addPackingItemToOrder,
    addManyPackingItemsToOrder,
    removePackingItemFromOrder,
    editPackingItemAtIndex,
    clearPackingItems,
  ] = itemActions(dispatchPackingItems);

  const [
    addRewardItemToOrder,
    addManyRewardItemsToOrder,
    removeRewardItemFromOrder,
    editRewardItemAtIndex,
    clearRewardItems,
  ] = itemActions(dispatchRewardItems);

  const kioskInfoToggle = (status) => {
    setFirstTime(false);
    setKioskInfoVisibility((visibility) => status || !visibility);
  };

  const kioskInfoOnSuccess = (data) => {
    setFirstTime(false);
    setKioskInfoVisibility(false);
    setTableNumber(data);
  };

  // Discount from URL
  const [discountUrlQuery, setDiscountUrlQuery] = useQueryState("discount", "");

  React.useEffect(() => {
    if (!discountUrlQuery) return;

    // Store the discount url query in local storage for use across application
    if (localStorage) {
      localStorage.setItem("discountCode", discountUrlQuery);
    }
    // Clear the discount from the url
    setDiscountUrlQuery("");
  }, [discountUrlQuery]);

  const resetDiscount = () => {
    // Do not clear the local storage discount code here
    // We only want to hard full reset in the CLEAR_DISCOUNT route
    setDiscount(defaultDiscount);
  };

  const resetGiftCard = () => {
    setGiftCard(DEFAULT_GIFT_CARD);
  };

  const resetOrderDetails = () => {
    clearPackingItems();
    resetDiscount();
    resetIdempotency();
    setDeliveryNotes("");
    setScheduledAt(null);
  };

  const resetOrder = () => {
    clearItems();
    clearRewardItems();
    resetGiftCard();
    resetOrderDetails();
  };

  const changeLocation = (nextLocation) => {
    // Removing distanceInMiles from comparison check b/c even a small difference will result in isEqual being false
    const currentLocationClone = { ...location, distanceInMiles: undefined };
    const nextLocationClone = { ...nextLocation, distanceInMiles: undefined };
    if (!isEqual(nextLocationClone, currentLocationClone)) {
      setLocation(nextLocation);
      resetOrderDetails();
      if (!config.theme.checkout.persistCartBetweenLocations) resetOrder();
    }
  };

  const changeOrderType = (nextOrderType) => {
    const currentOrderType = order.diningOption;
    if (nextOrderType !== currentOrderType) {
      resetOrderDetails();
    }
    setOrderType(nextOrderType);
  };

  React.useEffect(() => {
    if (localStorage !== null) {
      localStorage.setItem("location", JSON.stringify(location));
    }
  }, [location]);

  React.useEffect(() => {
    resetIdempotency();
    if (localStorage !== null) {
      localStorage.setItem("items", JSON.stringify(items));
    }
  }, [items]);

  React.useEffect(() => {
    resetIdempotency();
    if (localStorage !== null) {
      localStorage.setItem("packingItems", JSON.stringify(packingItems));
    }
  }, [packingItems]);

  React.useEffect(() => {
    resetIdempotency();
    if (localStorage !== null) {
      localStorage.setItem("rewardItems", JSON.stringify(rewardItems));
    }
  }, [rewardItems]);

  useDeepCompareEffect(() => {
    const { idempotencyKey, ...rest } = order;
    if (localStorage !== null) {
      localStorage.setItem(
        "order",
        JSON.stringify({
          ...rest,
        }),
      );
    }
  }, [order]);

  const orderData = {
    ...order,
    giftCard,
  };

  const contextValues = {
    addManyItems,
    addManyPackingItemsToOrder,
    // Packing item methods
    addPackingItemToOrder,
    addRewardItemToOrder,
    // Item methods
    addToOrder,
    // Location methods
    changeLocation,
    clearItems,
    clearPackingItems,
    clearRewardItems,
    editItemAtIndex,
    editPackingItemAtIndex,
    editRewardItemAtIndex,
    firstTime,
    // Computed values
    hasPromotion,
    isDelivery,
    isKiosk,
    isPickup,
    items: [...items, ...rewardItems],
    kioskInfoToggle,
    kioskInfoOnSuccess,
    kioskInfoVisibility,
    location,
    order: orderData,
    packingItems,
    removeFromOrder,
    removePackingItemFromOrder,
    resetDiscount,
    resetGiftCard,
    resetIdempotency,
    resetOrder,
    rewardItems,
    setDeliveryAddress,
    setDeliveryNotes,
    setDiscount,
    setGiftCard,
    // Order Details methods
    setOrderType: changeOrderType,
    setScheduledAt,
    setTableNumber,
  };

  return (
    <OrderContext.Provider value={contextValues}>
      {children(contextValues)}
    </OrderContext.Provider>
  );
};

OrderProvider.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(OrderProvider);
