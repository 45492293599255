import * as React from "react";
import { IS_INTERSECTION_OBSERVER_SUPPORTED } from "../utils/constants";

const useInView = (
  ref: React.MutableRefObject<HTMLElement>,
  rootMargin = "50px",
) => {
  if (!IS_INTERSECTION_OBSERVER_SUPPORTED) return true;

  const [isIntersecting, setIntersecting] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.unobserve(ref.current);
    };
  }, []);

  return isIntersecting;
};

export default useInView;
