import * as React from "react";
import isEqual from "fast-deep-equal";
import { constants } from "utils";

import { orderReducer, itemReducer } from "./reducers";
import { orderActions, itemActions } from "./actions";
import { INIT_ORDER } from "./initializers";

const {
  ORDER_TYPES: { DELIVERY, KIOSK, PICKUP },
} = constants;

const Context = React.createContext();

const DEFAULT_GIFT_CARD = {
  balance: 0,
  giftCardNumber: null,
};

const Provider = ({
  items: initialItems = [],
  location: initialLocation,
  order: initialOrder = {},
  orderType,
  ...props
}) => {
  // eslint-disable-next-line no-unused-vars
  const [giftCard, setGiftCard] = React.useState(DEFAULT_GIFT_CARD);
  const [order, dispatchOrder] = React.useReducer(
    orderReducer,
    INIT_ORDER({ diningOption: orderType, ...initialOrder }),
  );
  const [items, dispatchItems] = React.useReducer(itemReducer, initialItems);
  const [packingItems, dispatchPackingItems] = React.useReducer(
    itemReducer,
    [],
  );
  const [location, setLocation] = React.useState(initialLocation);

  const hasPromotion = !!(
    order?.discountId ??
    order?.promotionCodeId ??
    order?.promotionId ??
    false
  );
  const isDelivery = order.diningOption === DELIVERY;
  const isPickup = order.diningOption === PICKUP;
  const isKiosk = order.diningOption === KIOSK;

  const [
    setDeliveryAddress,
    // eslint-disable-next-line no-unused-vars
    setDeliveryNotes,
    setOrderType,
    setScheduledAt,
    setTableNumber,
    setDiscount,
    resetIdempotency,
  ] = orderActions(dispatchOrder);

  const [
    addToOrder,
    addManyItems,
    removeFromOrder,
    editItemAtIndex,
    clearItems,
  ] = itemActions(dispatchItems);

  const [
    addPackingItemToOrder,
    addManyPackingItemsToOrder,
    removePackingItemFromOrder,
    editPackingItemAtIndex,
    clearPackingItem,
  ] = itemActions(dispatchPackingItems);

  const resetDiscount = () => {
    setDiscount({
      code: null,
      discount: null,
      promotionCodeId: null,
      promotionId: null,
    });
  };
  const resetGiftCard = () => {
    setGiftCard(DEFAULT_GIFT_CARD);
  };
  const changeLocation = (nextLocation) => {
    if (!isEqual(nextLocation, location)) {
      setLocation(nextLocation);
      clearItems();
    }
  };
  const resetOrder = () => {
    clearItems();
    setScheduledAt(null);
    resetDiscount();
    resetIdempotency();
  };

  const contextValues = {
    addManyItems,
    addManyPackingItemsToOrder,
    addPackingItemToOrder,
    addToOrder,
    changeLocation,
    clearItems,
    clearPackingItem,
    editItemAtIndex,
    editPackingItemAtIndex,
    hasPromotion,
    isDelivery,
    isKiosk,
    isPickup,
    items,
    location,
    order,
    packingItems,
    removeFromOrder,
    removePackingItemFromOrder,
    resetIdempotency,
    resetOrder,
    setDeliveryAddress,
    setDiscount,
    setGiftCard,
    setOrderType,
    setScheduledAt,
    setTableNumber,
  };

  return (
    <Context.Provider value={contextValues}>
      {props.children(contextValues)}
    </Context.Provider>
  );
};

const useMemoirContext = () => {
  const contextValues = React.useContext(Context);
  if (!contextValues) {
    throw new Error(
      "useMemoirContext is being accessed outside a MemoirContextProvider",
    );
  }
  return contextValues;
};
export { useMemoirContext };
export default {
  Context,
  Provider,
};
