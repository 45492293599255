import * as React from "react";
import Rewards from "./Rewards";

/**
 * WalletBased loyalty system section selector for future flexibility - work in progress
 *
 * @author Htin Linn Aung
 * @memberof Templates.Templates/Loyalty
 * @param {object} props
 */
const WalletBased = (props) => {
  const Component = Rewards;
  return <Component {...props} />;
};

export default WalletBased;
