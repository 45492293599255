/* eslint-disable react/destructuring-assignment */
import * as React from "react";
import { useResource } from "hooks";

const Context = React.createContext();

const mapMods = (mods) => {
  const uniqueOptions = mods.reduce((accu, i) => {
    const accuTemp = { ...accu };
    if (accuTemp[i.option]) {
      accuTemp[i.option].push(i.item);
    } else {
      accuTemp[i.option] = [i.item];
    }
    return accuTemp;
  }, {});

  return Object.entries(uniqueOptions).map(([key, value]) => ({
    items: value,
    option: key,
  }));
};

const Provider = ({ children, cartId }) => {
  const [
    { resource, fetching, error },
    // doFetch
  ] = useResource({
    path: `/cart/${cartId}`,
  });

  if (fetching) {
    return null;
  }
  const contextValues = {
    ...resource,
    error,
    fetching,
    items: (resource ? resource.items : []).map((i) => {
      const mods = mapMods(i.modifications);
      return {
        group: i.group,
        item: i.item.id,
        mods,
      };
    }),
  };
  return (
    <Context.Provider value={contextValues}>
      {typeof children === "function"
        ? React.Children.only(children(contextValues))
        : React.Children.only(children)}
    </Context.Provider>
  );
};

const useCheckoutContext = () => {
  const contextValues = React.useContext(Context);
  if (!contextValues) {
    throw new Error(
      "useCheckoutContext must be use within CheckoutContextProvider",
    );
  }
  return contextValues;
};

export { useCheckoutContext };
export default {
  Context,
  Provider,
};
