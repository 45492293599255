import * as React from "react";
import { flatten } from "lodash";
import styles from "./index.module.css";
import Option from "../../Options";

const additionalOffset = 55;

const findError = (errors, id) => {
  const match = errors.find((i) => i.optionId === id);
  if (match) {
    return match.message;
  }
  return null;
};

const getScrollOffset = (node, addedOffset) => {
  if (!node) return 0;
  return node.offsetTop - addedOffset;
};

const Type4 = ({
  tabs,
  cells,
  errors,
  addModifier,
  removeModifier,
  menuOptions,
  modifications,
  optionRefs,
}) => {
  const errorRefs = React.useRef({});

  React.useEffect(() => {
    const el = document.getElementById("itemDetailsContainer");
    const handler = () => {
      // const scrollPos = el.scrollTop;
      // const isScrolledToBottom = el.scrollHeight - scrollPos === el.clientHeight;
    };

    if (el) el.addEventListener("scroll", handler);
    return () => {
      if (el) el.removeEventListener("scroll", handler);
    };
  }, []);

  React.useEffect(() => {
    if (errors?.length > 0) {
      const errorOptionId = errors[0].optionId;
      const firstErrorRef = optionRefs.current[errorOptionId];
      const el = document.getElementById("itemDetailsContainer");
      const triggerPoint = additionalOffset;

      if (firstErrorRef?.node !== null) {
        el.scrollTo({
          behavior: "smooth",
          top: (firstErrorRef?.node?.offsetTop ?? 0) - triggerPoint,
        });
      }
    }
  }, [errors.length]);

  // eslint-disable-next-line no-shadow
  const sortTabs = (tabs) => {
    const flattenedEntities = tabs.reduce(
      (acc, cur) => [...acc, ...flatten(cur.entities)],
      [],
    );
    const sortedEntities = flattenedEntities.sort(
      (a, b) => (b.priority || 0) - (a.priority || 0),
    );
    return sortedEntities;
  };

  return (
    <div className={styles.container} data-cy="itemDetail-tabs">
      {sortTabs(tabs).map((option, index) => (
        <Option
          key={option.id}
          navIndex={option.id}
          optionRef={(node) => {
            const optionRefsTemp = { ...optionRefs };
            optionRefsTemp.current[option.id] = {
              index,
              node,
              optionId: option.id,
              scrollToPosition: getScrollOffset(node, additionalOffset),
            };
          }}
          errorRef={(node) => {
            errorRefs.current[option.id] = {
              index,
              node,
              optionId: option.id,
              scrollToPosition: getScrollOffset(node, additionalOffset),
            };
          }}
          type={cells.group}
          optionItemTypes={{
            default: cells.primary,
            selected: cells.primarySelected,
          }}
          parentOption={menuOptions[option.id]}
          {...menuOptions[option.id]}
          error={findError(errors, option.id)}
          selectedMods={modifications[option.id] || []}
          addModifier={addModifier}
          removeModifier={removeModifier}
        />
      ))}
    </div>
  );
};

export default Type4;
