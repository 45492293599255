import * as React from "react";
import smoothscroll from "smoothscroll-polyfill";

import { analytics } from "hooks";
// eslint-disable-next-line import/no-unresolved
import { version } from "PackageData";
import { config, constants, Time } from "utils";
import { ThemeStore } from "@lunchboxinc/lunchbox-components-v2/dist/configProvider";
import StructureProvider from "./components/providers/Structure/StructureContext";
import { DrawersProvider } from "./contexts/Drawers";
import { CachedAddressProvider } from "./contexts/CachedAdresses";
import {
  Template,
  Patron,
  Order,
  Menu,
  Notifications,
  GlobalConfig,
} from "./components/providers";
import { GlobalStyles } from "./GlobalStyles";
import { Symbols, Modal, Sift } from "./components/fragments";
// import themeData from "ThemeData";

import Routes from "./Routes";
// example of importing theme data to run locally for onboarding purposes

const { BUILD_ENV, DEBUG_TOOLS = false } = process.env;
const themeEnv = BUILD_ENV === "local" ? "development" : BUILD_ENV;
const GroupedProviders = ({ children }) => (
  <>
    <GlobalStyles />
    <Symbols />
    <Modal />
    <Notifications.Provider>{children}</Notifications.Provider>
  </>
);

const App = () => {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    // Smooth Scroll behavior polyfill
    // Natively supported in Chrome & Firefox
    // Required for Safari, IE, Edge & Opera
    smoothscroll.polyfill();

    // Start analytical tools such as gtm, fb pixel and segment
    if (BUILD_ENV === "production" || DEBUG_TOOLS) {
      analytics.initialize();
    }
  }, []);

  React.useEffect(() => {
    if (localStorage !== null) {
      const dateExpiration = localStorage.getItem("dateExpiration");
      const dateNow = +Date.now();
      if (dateNow >= dateExpiration) {
        localStorage.removeItem("order");
        localStorage.removeItem("items");
        localStorage.removeItem("packingItems");
      }
      localStorage.setItem("dateExpiration", dateNow + Time.hours[24]); // 24 hours expiration

      const currentVersion = localStorage.getItem("version");
      const newVersion = version;

      if (currentVersion !== newVersion) {
        localStorage.removeItem("order");
        localStorage.removeItem("items");
        localStorage.removeItem("packingItems");
      }
      const currentConfigId = localStorage.getItem("configId");
      const newConfigId = config.id;
      if (currentConfigId && newConfigId !== currentConfigId) {
        localStorage.clear();
      }
      localStorage.setItem("configId", newConfigId);

      localStorage.setItem("version", version);
      let order = localStorage.getItem("order") || "{}";
      order = JSON.parse(order);
      if (order?.address?.addressComponents) {
        localStorage.clear();
      }
    }
    setIsLoading(false);

    analytics.location.clientIdentified({
      client: localStorage.getItem("configId"),
    });
  }, []);

  if (isLoading) return null;

  return (
    <GlobalConfig.Provider>
      <Template.TemplateProvider userTheme={config.directory} env={BUILD_ENV}>
        <ThemeStore
          userTheme={config.directory}
          project="web"
          env={themeEnv}
          // readLocal
          // localData={themeData}
          // use local theme when in developing mode
        >
          <GroupedProviders>
            {/* TODO: No callback children, no prop drilling - use context... */}
            {({ selectedDiet }) => (
              <Patron.PatronProvider>
                <Sift
                  siftBeaconKey={constants.SIFT_BEACON_KEY}
                  sessionId={constants.SESSION_ID}
                />
                <Order.OrderProvider>
                  {/* TODO: No callback children, no prop drilling - use context... */}
                  {(orderContext) => (
                    // TODO: revisit this b/c cached addresses doesnt need to wrap the application
                    <CachedAddressProvider>
                      <StructureProvider>
                        <Menu.MenuProvider
                          location={orderContext.location.id}
                          orderType={orderContext.order.diningOption}
                        >
                          {/* TODO: No callback children, no prop drilling - use context... */}
                          {(menuContext) => (
                            <DrawersProvider>
                              <Routes
                                order={orderContext}
                                menu={menuContext}
                                diet={selectedDiet}
                              />
                            </DrawersProvider>
                          )}
                        </Menu.MenuProvider>
                      </StructureProvider>
                    </CachedAddressProvider>
                  )}
                </Order.OrderProvider>
              </Patron.PatronProvider>
            )}
          </GroupedProviders>
        </ThemeStore>
      </Template.TemplateProvider>
    </GlobalConfig.Provider>
  );
};

export default App;
