import * as React from "react";

const Carot = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 11.8761V15.0011H3.125L12.3417 5.78444L9.21667 2.65944L0 11.8761ZM14.7583 3.36777C15.0833 3.04277 15.0833 2.51777 14.7583 2.19277L12.8083 0.242773C12.4833 -0.0822266 11.9583 -0.0822266 11.6333 0.242773L10.1083 1.76777L13.2333 4.89277L14.7583 3.36777Z"
        fill="#1A1A1A"
      />
    </svg>
  );
};

export default Carot;
