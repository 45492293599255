import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import classnames from "classnames";

import { Copy, constants, config } from "utils";
import { stringReplacer } from "utils/helpers/string";
import withTemplate from "../../../../components/hocs/withTemplate";
import View from "../../../../components/elementsThemed/View/View";
import Text from "../../../../components/elementsThemed/Text/text";
import DrawerButton from "../../../../components/DrawerButton";

import css from "./complete.module.scss";
import commonCSS from "../../../index.module.scss";

import type { CompleteProps } from "../complete";

const {
  ORDER_TYPES: { PICKUP, DELIVERY, KIOSK },
} = constants;

function Complete({
  style,
  onSuccess,
  ticketInformation,
  date,
  time,
  scheduleTime,
  eta,
  estScheduledTimeRange,
  estTimeRangeAsString,
  orderType,
}: CompleteProps) {
  const { views, labels } = style;

  const text = ticketInformation.scheduledAt
    ? Copy.CHECKOUT_STATIC.COMPLETE_TICKET_INFORMATION_SCHEDULE_MESSAGE
    : Copy.CHECKOUT_STATIC.COMPLETE_TICKET_INFORMATION_PREPARING_MESSAGE;

  let info = null;
  let additionalInfo = null;

  const assignScheduledDateInfo = () => {
    info = (
      <>
        <Col xs="1">
          <Text type={labels.tertiary}>
            {Copy.CHECKOUT_STATIC.COMPLETE_SCHEDULE_DATE_HEADER_TEXT}
          </Text>
        </Col>
        <Col xs="1">
          <Text type={labels.tertiary}>{estScheduledTimeRange.date}</Text>
        </Col>
        {estTimeRangeAsString && (
          <Col xs="1">
            <Text type={labels.tertiary}>
              {estScheduledTimeRange.estTimeRangeAsString}
            </Text>
          </Col>
        )}
      </>
    );
  };

  const assignEstimateTimeInfo = () => {
    info = (
      <>
        <Col xs="1">
          <Text type={labels.tertiary}>
            {orderType === "delivery"
              ? Copy.CHECKOUT_STATIC.COMPLETE_ESTIMATED_DELIVERY_AT
              : Copy.CHECKOUT_STATIC.COMPLETE_ESTIMATED_PICKUP_AT}
          </Text>
        </Col>
        <Col xs="1">
          <Text type={labels.tertiary}>{estTimeRangeAsString}</Text>
          {ticketInformation.scheduledAt && (
            <Text type={labels.tertiary}>{scheduleTime}</Text>
          )}
        </Col>
      </>
    );
  };

  if (ticketInformation.scheduledAt) {
    assignScheduledDateInfo();
  } else if (eta) {
    assignEstimateTimeInfo();
  }

  if (Copy.CHECKOUT_STATIC.COMPLETE_ADDITIONAL_MESSAGE) {
    additionalInfo = (
      <>
        <hr className={css.divider} />
        <div className={css.divider}>
          <Text type={labels?.quaternary ?? labels.tertiary}>
            {Copy.CHECKOUT_STATIC.COMPLETE_ADDITIONAL_MESSAGE}
          </Text>
        </div>
      </>
    );
  }

  let fromTitle = null;
  switch (ticketInformation.orderType) {
    case PICKUP:
      fromTitle = Copy.CHECKOUT_STATIC.COMPLETE_PICKUP_FROM_TEXT;
      break;
    case DELIVERY:
      fromTitle = Copy.CHECKOUT_STATIC.COMPLETE_DELIVERING_FROM_TEXT;
      break;
    case KIOSK:
      fromTitle = Copy.CHECKOUT_STATIC.COMPLETE_DINING_AT_TEXT;
      break;
    default:
      fromTitle = "";
      break;
  }

  return (
    // "purchase-confirmation" is gtm Ecommerce-anchor, please fo not remove
    <View type={views.background} className={commonCSS.container}>
      <div className={classnames(css.content, "purchase-confirmation")}>
        <View type={views.body}>
          <View type={views.title} className={css.title}>
            <Text type={labels.primary}>
              {stringReplacer(
                Copy.CHECKOUT_STATIC.COMPLETE_ORDER_INFORMATION_MESSAGE,
                [{ replaceTarget: "{text}", replaceValue: text }],
              )}
            </Text>
          </View>
          <div className={css.info}>
            <Row spacing={20} />
            <Row spacing={20}>
              <Col xs="1">
                <Text type={labels.secondary}>
                  {fromTitle} {ticketInformation.location.name}
                </Text>
              </Col>
            </Row>
            {ticketInformation.orderType === KIOSK && (
              <Row spacing={20}>
                <Col xs="1">
                  <Text type={labels.tertiary}>
                    {Copy.KIOSK_STATIC.CHECKOUT_LABEL}
                    {ticketInformation.tableNumber}
                  </Text>
                </Col>
              </Row>
            )}
            <hr className={css.divider} />
            <Row spacing={20}>
              <Col xs="1-3">
                <Text type={labels.tertiary}>{date}</Text>
              </Col>
              <Col xs="1-3">
                <Text type={labels.tertiary}>
                  Check #{ticketInformation.displayNumber}
                </Text>
              </Col>
              <Col xs="1-3">
                <Text type={labels.tertiary}>{time}</Text>
              </Col>
            </Row>
            {ticketInformation.orderType !== KIOSK && (
              <>
                <hr className={css.divider} />
                <Row spacing={20}>{info}</Row>
              </>
            )}
            {additionalInfo}
          </div>
        </View>
      </div>
      <div className={css.footer}>
        <DrawerButton buttonProps={{ onClick: onSuccess }}>
          {Copy.CHECKOUT_STATIC.COMPLETE_BACK_TO_MENU_BUTTON_TEXT}
        </DrawerButton>
      </div>
    </View>
  );
}

export default withTemplate(Complete, "confirmation");
