import * as React from "react";

import { View } from "components/elementsThemed";
import { BeamImpact } from "components/fragments";
import { withTemplate } from "components/hocs";
import { Copy } from "utils";
import DrawerButton from "../../../components/DrawerButton";

import commonStyles from "../../index.module.scss";
import css from "./interceptCheckoutComplete.module.scss";

/**
 * Interceptor between checkout and purchase-complete confirmation
 * If the patron(user) is:
 *  - new to Beam, show Beam widget in the interceptor before going to purchase-complete confirmation
 *  - not new, bring the user straight to purchase-complete confirmation
 *
 * @param {object} props
 * @param {object} props.style - From theme file & withTemplate HOC
 * @param {string} props.patronEmail - From BeamImpact
 * @param {object} props.beam - State in pages/Cart/routes.jsx
 * @param {Function} props.setBeam - Setter method for state in pages/Cart/routes.jsx
 * @param {Function} props.onComplete - Func to bring the user to Routes.PURCHASE_COMPLETE - from home.jsx
 * @param {Fuction} props.onSkipBeamSelection - Alternate Func to bring the user to Routes.PURCHASE_COMPLETE - from home.jsx
 * @param {Function} props.setHeader - Func to set the header of the cart - from home.jsx
 */
const InterceptCheckoutComplete = ({
  style,
  patronEmail,
  beam,
  setBeam,
  onComplete,
  setHeader,
  onSkipBeamSelection,
}) => {
  const { views, cells } = style;

  const hasBeam = Boolean(beam);
  const isNewBeamUser = Boolean(beam?.widget?.isNewUser);
  const showBeam = hasBeam && isNewBeamUser;

  React.useEffect(() => {
    if (setHeader) setHeader(Copy.CART_STATIC.CHECKOUT_HEADER_TEXT);
    if (!showBeam) onComplete();
  }, []);

  if (showBeam) {
    return (
      <View type={views.background} className={commonStyles.content}>
        <View
          type={views.background}
          className={`${commonStyles.content} ${css.content}`}
        >
          <BeamImpact
            widgetId={beam.widget.options.widgetId}
            patronEmail={patronEmail}
            cartTotal={beam.widget.cartTotal}
            step="post-checkout"
            beam={beam}
            setBeam={setBeam}
            onComplete={onComplete}
            onSkipBeamSelection={onSkipBeamSelection}
          />
        </View>
        <div className={css.footer}>
          <DrawerButton
            buttonProps={{
              className: "checkout-confirmation",
              onClick: onComplete,
            }}
          >
            {Copy.CART_STATIC.SEE_ORDER_CONFIRMATION}
          </DrawerButton>
        </div>
      </View>
    );
  }
  return null;
};

export default withTemplate(InterceptCheckoutComplete, "checkout");
