import { useTemplateContext } from "components/providers/Template";

/** Given the name of a theme style, return the template. */
export function useTemplate(name: string) {
  const { style, theme } = useTemplateContext();
  return theme.styles[style][name];
}

/** Given the name of a segment, return theming values. */
export function useSegment(type: string) {
  const {
    parsedTheme: { views },
    theme: {
      elements: { segmentViews },
    },
  } = useTemplateContext();
  const segment = segmentViews[type];
  const view = views[segment.view];
  return { segment, view };
}
