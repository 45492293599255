import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import ClampLines from "react-clamp-lines";
import { Condition } from "components/elements";
import { Text, View, Cell } from "components/elementsThemed";
import { Copy } from "utils";
import { formatPrice } from "utils/helpers/string";
import css from "./groupType5.module.scss";

const { Condition: If } = Condition;

const Group = ({ items, selected, types, onSelect, error }) => {
  const onClick = (id) => () => {
    onSelect(id);
  };

  return (
    <div className={css.itemDetailsGroups}>
      <Cell
        type={types.group}
        render={({ views, labelTextStyles }) => (
          <View
            type={views.background}
            className={css["itemDetailsGroups-title"]}
          >
            <Text type={labelTextStyles.secondary}>
              {Copy.ITEM_DETAILS_STATIC.SELECT_TEXT}
            </Text>
            {error && (
              <div>
                <Text type={labelTextStyles.tertiary}>
                  {Copy.ITEM_DETAILS_STATIC.SELECTION_EMPTY_ERROR}
                </Text>
              </div>
            )}
          </View>
        )}
      />
      <div style={{ padding: "0px 20px" }}>
        <Row
          flex
          spacing={10}
          gutter={20}
          className={css["itemDetailsGroups-items"]}
        >
          {items.map((i) => {
            const { images = [], name, description, price, calories } = i;
            let image = null;

            if (images && images.length && images[0]) {
              image = images[0].replace("http://", "https://");
            }

            return (
              <Col xs="1-1" sm="1-2" md="1-2" xl="1-2">
                <Cell
                  key={i.id}
                  type={i.id === selected ? types.selected : types.default}
                  render={({ labelTextStyles, views }) => (
                    <View
                      type={views.background}
                      className={css["itemDetailsGroups-item"]}
                      onClick={onClick(i.id)}
                    >
                      <If is={image}>
                        <div className={css["image-container"]}>
                          <img className={css.image} src={image} alt={name} />
                        </div>
                      </If>
                      <div className={css.info}>
                        <div className={css.name}>
                          <Text type={labelTextStyles.primary}>
                            <ClampLines
                              id={1}
                              buttons={false}
                              text={name}
                              lines={2}
                              ellipsis="..."
                            />
                          </Text>
                          <div className={css.desc}>
                            <Text
                              maxHeight={57}
                              type={labelTextStyles.secondary}
                              className={css.desc}
                            >
                              {description}
                            </Text>
                          </div>
                          <If is={calories}>
                            <div className={css.desc}>
                              <Text
                                maxHeight={57}
                                type={labelTextStyles.secondary}
                                className={css.desc}
                              >
                                {calories} cal
                              </Text>
                            </div>
                          </If>
                        </div>
                      </div>
                      <If is={price}>
                        <div className={css.price}>
                          <Text
                            type={labelTextStyles.secondary}
                            className={css["itemDetailsGroups-item--price"]}
                          >
                            {formatPrice(i.price)}
                          </Text>
                        </div>
                      </If>
                    </View>
                  )}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

const GroupType5 = React.memo(Group);
export default GroupType5;
