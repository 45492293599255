import { methods } from "utils/axios";
import { mapGoogleAddressComponents } from "utils/helpers/geo";
import { AddressSchema } from "utils/Schemas";

/**
 * Given address components from API response, parse
 * into the shape of internal data.
 *
 * TODO: Better type definitions.
 */
const parseAddress = ({ addressComps: address, lat, long, id }: any) => {
  const addressValidation = AddressSchema.isValidSync(address);
  return {
    ...(addressValidation
      ? {
          ...address,
          id,
          lat,
          long,
        }
      : {}),
    isValid: addressValidation,
  };
};

/**
 * Given a Places API of a location, fetch
 * an Address.
 */
const fetchPlaceById = async (placeId: string) => {
  try {
    // @ts-ignore
    const { data } = await methods.get(`/places/${placeId}`, {
      orderType: "delivery",
    });
    return {
      addressComps: mapGoogleAddressComponents(data.addressComponents),
      id: placeId,
      lat: data.lat,
      long: data.long,
    };
  } catch (error) {
    console.error(error);
  }
};

export { parseAddress, fetchPlaceById };
