/* eslint-disable  no-unused-vars */
import _cloneDeep from "lodash/cloneDeep";
import _isEmpty from "lodash/isEmpty";
import { config } from "../../../utils";
import { formatPrice } from "../../../utils/helpers/string";

// Return all modifications in the state as an array

const mapModifications = (mods) => {
  if (_isEmpty(mods) || !mods) {
    return [];
  }
  return Object.entries(mods).reduce((accu, [parentOption, items]) => {
    const newItems =
      items?.length &&
      items?.reduce(
        (accumulator, { item, quantity = 1, option, modifiers = {} }) => {
          if (quantity < 1) {
            // If quantity is < 1, we assume it was removed and should be skipped.
            return accumulator;
          }

          const itemObj = {
            item,
            modifiers: mapModifications(modifiers),
            option: option || parentOption,
          };
          const newItemsLength = Math.max(1, quantity);
          // eslint-disable-next-line no-shadow
          const newItems = new Array(newItemsLength).fill(0).map(() => itemObj);

          return [...accumulator, ...newItems];
        },
        [],
      );

    return [
      ...accu,
      {
        modifiers: newItems || [],
        option: parentOption,
      },
    ];
  }, []);
};

const sortOptions = (optionA = {}, optionB = {}) => {
  let optionATemp = { ...optionA };
  let optionBTemp = { ...optionB };
  if (optionATemp === null) optionATemp = {};
  if (optionBTemp === null) optionBTemp = {};
  let weightA = 0;
  let weightB = 0;

  if (optionATemp.min) {
    weightA -= 1;
  } else {
    weightA += 1;
  }

  if (optionBTemp.min) {
    weightB -= 1;
  } else {
    weightB += 1;
  }

  if (optionATemp.min && optionBTemp.min) {
    if (optionBTemp.min < optionATemp.min) {
      weightB += 1;
    } else if (optionBTemp.min > optionATemp.min) {
      weightA += 1;
    }
  }

  if (optionATemp.max) {
    weightA -= 1;
  } else {
    weightA += 1;
  }
  if (optionBTemp.max) {
    weightB -= 1;
  } else {
    weightB += 1;
  }

  if (optionATemp.max && optionBTemp.max) {
    if (optionBTemp.max < optionATemp.max) {
      weightB += 1;
    } else if (optionBTemp.max > optionATemp.max) {
      weightA += 1;
    }
  }
  return {
    weightA,
    weightB,
  };
};

const mapItemTabs = (options, optionsHash, HARDCODES) => {
  let tabs = options.map((i) => ({ entities: [i], name: i.name }));

  if (HARDCODES.optionsTabs && HARDCODES.optionsTabs.length) {
    tabs = HARDCODES.optionsTabs
      .map((tab) => {
        const entities = options
          .reduce((accu, option) => {
            const formatedName = option.name.toLowerCase().trim();
            // Find all options that match the hardcodes defined by the client
            const isAnOption = tab.optionNames.filter((optionName) =>
              formatedName.includes(optionName.toLowerCase()),
            ).length;
            if (tab.type === 0) {
              // eslint-disable-next-line no-unused-expressions
              !isAnOption && accu.push(option.id);
            } else {
              // eslint-disable-next-line no-unused-expressions
              isAnOption && accu.push(option.id);
            }
            return accu;
          }, [])
          .reduce((accu, optionId) => {
            // Filter out all optionItems that match diet if diet is selected
            const newOptions = _cloneDeep(optionsHash[optionId]);

            return [...accu, newOptions];
          }, []);

        return {
          entities: entities.filter((i) => i.items.length),
          name: tab.tabName,
          optionIds: entities.map((i) => i?.id).filter(Boolean),
        };
      })
      .filter((i) => i.entities.length);
  }

  tabs = tabs.map((i) => ({
    ...i,
    entities: config.option_weighted_sort
      ? i.entities.sort((optionA, optionB) => {
          const { weightA = 0, weightB = 0 } = sortOptions(optionA, optionB);
          return weightA - weightB;
        })
      : i.entities,
  }));

  return tabs;
};

/**
 * nestedModifierState - recursively search modifiers state for the modifiers of an option. Read {@link https://www.notion.so/Item-Details-9bfe384406a64e71a2f23ac59afc50a1} first.
 *
 * @param {string} optionId - the option to search
 * @param {string} modId - the item id to search
 * @param {object} modsState - the modifier state
 * @todo Test using multiple nested tiers...
 * @todo Move this to the mods context
 */
const nestedModifierState = ({ optionId, modId, modsState }) => {
  if (optionId && modId && !_isEmpty(modsState)) {
    // When we are validating a nested item...
    return Object.entries(modsState).reduce((accu, [id, options]) => {
      // No options were found
      if (!options?.length) return accu;

      const modifiers = options.reduce((accumulator, itemObj) => {
        if (id === optionId && itemObj?.item === modId) {
          // The nested item was found, now include all of it's modifiers
          return {
            ...accumulator,
            ...(itemObj?.modifiers || {}),
          };
        }

        // Continue recursion when there are modifiers in search of a option & item match
        if (Object.entries(itemObj?.modifiers ?? {}).length) {
          const nestedModifiers = nestedModifierState({
            modId,
            modsState: itemObj?.modifiers,
            optionId,
          });

          // Include all of it's modifiers if the nested item was found
          return {
            ...accu,
            ...(nestedModifiers || {}),
          };
        }

        return accu;
      }, {});

      // Only include the modifiers of the nested items
      return {
        ...accu,
        ...modifiers,
      };
    }, {});
  }

  // Validating ordinary modifiers with nothing nested
  return modsState;
};

const flattenModifications = (state = {}) => {
  if (_isEmpty(state)) {
    return [];
  }

  return Object.entries(state).reduce((acc, [id, items = []]) => {
    if (!items?.length) {
      return acc;
    }

    // Move deeper into each item and it's modifiers
    const nestedItems = items.reduce(
      (accuItems, { item, modifiers, quantity }) => {
        return [
          ...accuItems,
          { item, modifiers: flattenModifications(modifiers), quantity },
        ];
      },
      [],
    );

    return [...acc, ...nestedItems];
  }, []);
};

const nestedTotals = (modifiers = [], itemHash) => {
  return modifiers.reduce(
    (
      accuMods,
      { item: modItem, modifiers: modModifiers, quantity: modQuantity },
    ) => {
      if (modModifiers?.length) {
        return (
          accuMods +
          nestedTotals(modModifiers, itemHash) +
          itemHash[modItem]?.price * modQuantity
        );
      }
      return accuMods + itemHash[modItem]?.price * modQuantity;
    },
    0,
  );
};

const getTotalPrice = ({
  itemInfo,
  itemHash,
  selectedItem,
  modifications,
  quantity,
}) => {
  let itemPrice = parseFloat(itemInfo?.price ?? 0);
  if (selectedItem && itemPrice >= 0) {
    const total = flattenModifications(modifications).reduce(
      (accu, { item: nestedItem, modifiers, quantity: qty }) => {
        return (
          accu +
            (nestedTotals(modifiers, itemHash) + itemHash[nestedItem]?.price) *
              qty || 0
        );
      },
      itemPrice,
    );
    return {
      formatted: formatPrice(total * quantity),
      unformatted: total,
    };
  }
  return {
    formatted: "",
    unformatted: "",
  };
};

export {
  mapItemTabs,
  mapModifications,
  nestedModifierState,
  sortOptions,
  getTotalPrice,
};
