import * as React from "react";

const PatronContext = React.createContext();

const usePatronContext = () => {
  const contextValues = React.useContext(PatronContext);
  if (!contextValues) {
    throw new Error(
      "usePatronContext must be use within PatronContextProvider",
    );
  }
  return contextValues;
};

export { usePatronContext };
export default PatronContext;
