import * as React from "react";
import { config } from "utils";
import EmailForm from "./emailForm";
import VerifyEmailForm from "./verifyEmailForm";
import UpdatePasswordForm from "./updatePasswordForm";
import AutoSendPin from "./autoSendPin";
import BackToLogin from "./backToLogin";

const ForgotPassword = ({
  email: prefillEmail,
  onSuccess,
  onBackToLogin,
  location,
  onMount,
}) => {
  const {
    autoSend = false,
    message = "",
    email: initEmail = "",
  } = location.state ?? {};
  const [step, setStep] = React.useState(0);
  const [patronData, setPatronData] = React.useState("");
  const [email, setEmail] = React.useState(prefillEmail ?? initEmail);

  const onEmailSuccess = (enteredEmail) => {
    setEmail(enteredEmail);
    setStep(1);
  };

  const onOloEmailSuccess = (enteredEmail) => {
    setStep(3);
  };

  const onVerifySuccess = (data) => {
    setPatronData(data);
    setStep(2);
  };
  const onChangePasswordSuccess = () => {
    onSuccess(patronData);
  };

  React.useEffect(() => {
    if (onMount) {
      onMount();
    }
  }, []);

  switch (step) {
    case 1:
      return (
        <VerifyEmailForm
          onSuccess={onVerifySuccess}
          email={email}
          message={message}
        />
      );
    case 2:
      return (
        <UpdatePasswordForm
          onSuccess={onChangePasswordSuccess}
          patron={patronData}
        />
      );
    case 3:
      return <BackToLogin onBackToLogin={onBackToLogin} />;

    default: {
      if (autoSend) {
        return <AutoSendPin email={email} onSuccess={onEmailSuccess} />;
      }
      return (
        <EmailForm
          email={email}
          onSuccess={config.isOlo ? onOloEmailSuccess : onEmailSuccess}
        />
      );
    }
  }
};

export default ForgotPassword;
