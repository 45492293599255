import * as React from "react";
import { Formik, Form } from "formik";

import { Schemas, Copy, config } from "utils";
import { stringReplacer, formatPhoneNumber } from "utils/helpers/string";
import {
  PATRON_SEND_PINCODE,
  PATRON_VERIFY_PINCODE,
  HANDLE_ERROR,
} from "utils/api";
import { Condition, Layout } from "components/elements";
import DrawerButton from "../../DrawerButton";
import {
  Field as FieldComps,
  Text,
  View,
  Button,
} from "components/elementsThemed";
import { withTemplate } from "components/hocs";

import css from "../form.module.scss";

const { Input, Field } = FieldComps;
const { Flex } = Layout;

const { Condition: If } = Condition;

const VerifyPhoneCode = ({
  onSuccess,
  phone,
  token,
  style,
  message,
  onUpdatePhone,
}) => {
  const [requesting, setRequesting] = React.useState(false);
  const verifyPhone = async (values, actions) => {
    actions.setFieldError("code", "");
    actions.setStatus("");
    try {
      const { data } = await PATRON_VERIFY_PINCODE(
        { phone, pinCode: values.code },
        token ? { headers: { authorization: token } } : undefined,
      );
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      const e = HANDLE_ERROR(error);
      actions.setStatus(e.data);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const resend = async () => {
    setRequesting(true);
    try {
      await PATRON_SEND_PINCODE(
        { phone },
        { headers: { authorization: token } },
      );
    } finally {
      setRequesting(false);
    }
  };

  return (
    <Formik
      validateOnChange={false}
      initialStatus={message}
      initialValues={{
        code: "",
      }}
      validationSchema={Schemas.PhoneCodeSchema}
      onSubmit={verifyPhone}
      render={({ errors, values, status, setFieldValue, isSubmitting }) => (
        <View
          type={style.views.background}
          className={css.form}
          Component={Form}
        >
          <div className={css["fields-container"]}>
            <Input
              label={stringReplacer(
                Copy.UPDATE_PHONE_STATIC.VERIFY_CODE_INPUT_LABEL,
                [{ replaceTarget: "{phone}", replaceValue: phone }],
              )}
              name="code"
              type={style.inputs.standard}
              error={errors.code}
              value={values.code}
              placeholder={
                Copy.UPDATE_PHONE_STATIC.VERIFY_CODE_INPUT_PLACEHOLDER
              }
              onChange={(e) => setFieldValue("code", e.target.value)}
              aria-invalid="true"
            />

            <Field
              type={style.cells.form}
              label="Your pin was sent to"
              isClampLines={false}
              value={phone && formatPhoneNumber(phone)}
              buttonProps={{
                children: "Change",
                htmlType: "button",
                onClick: onUpdatePhone,
              }}
            />
            <div>
              {status && <Text type={style.labels.error}>{status}</Text>}
            </div>
            <Button
              type={style.buttons.alternate}
              htmlType="button"
              style={{ float: "right" }}
              disabled={requesting}
              onClick={resend}
            >
              {Copy.UPDATE_PHONE_STATIC.VERIFY_CODE_RESEND_PIN_BUTTON_TEXT}
            </Button>
          </div>
          <If is={["tacombi", "juicyplatters"].includes(config.id)}>
            <Flex direction="col" align="center" justify="center">
              <Text type={style.labels.primary}>
                {"Haven't received your PIN? Please contact "}
                <Button
                  type={style.buttons.link}
                  Component="a"
                  href="mailto:help@lunchbox.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  support
                </Button>
              </Text>
            </Flex>
          </If>
          <DrawerButton
            buttonProps={{ htmlType: "submit", disabled: isSubmitting }}
          >
            {Copy.UPDATE_PHONE_STATIC.VERIFY_CODE_CONFIRM_CODE_BUTTON_TEXT}
          </DrawerButton>
        </View>
      )}
    />
  );
};

export default withTemplate(VerifyPhoneCode, "pincode");
