import * as React from "react";
import classnames from "classnames";

import { Elements, ElementsThemed, HOCs } from "components";
import DrawerButton from "../../../../components/DrawerButton";
import { constants, images, Copy } from "utils";
import { stringReplacer } from "utils/helpers/string";
import Field from "../../Field";

import commonStyles from "../../../index.module.scss";
import styles from "./complete.module.scss";

const {
  Layout: { Flex },
} = Elements;
const { View, Text } = ElementsThemed;
const { withTemplate } = HOCs;
const {
  ORDER_TYPES: { PICKUP, DELIVERY, KIOSK },
} = constants;

const Complete = ({ style, onSuccess, ticketInformation, date, time }) => {
  const { views, cells, labels } = style;

  const text = ticketInformation.scheduledAt
    ? Copy.CHECKOUT_STATIC.COMPLETE_TICKET_INFORMATION_SCHEDULE_MESSAGE
    : Copy.CHECKOUT_STATIC.COMPLETE_TICKET_INFORMATION_PREPARING_MESSAGE;
  s;

  const cellStyle = {
    background: null,
    primary: style.labels.tertiary,
    secondary: style.labels.secondary,
  };

  let fromTitle = null;
  switch (ticketInformation.orderType) {
    case PICKUP:
      fromTitle = Copy.CHECKOUT_STATIC.COMPLETE_PICKUP_FROM_TEXT;
      break;
    case DELIVERY:
      fromTitle = Copy.CHECKOUT_STATIC.COMPLETE_DELIVERING_FROM_TEXT;
      break;
    case KIOSK:
      fromTitle = Copy.CHECKOUT_STATIC.COMPLETE_DINING_AT_TEXT;
      break;
    default:
      fromTitle = "";
      break;
  }

  return (
    // "purchase-confirmation" is gtm Ecommerce-anchor, please fo not remove
    <View
      Component={Flex}
      grow={1}
      type={views.background}
      className={commonStyles.container}
    >
      <Flex
        justify="start"
        grow={0}
        className={classnames(styles.content, "purchase-confirmation")}
      >
        <View type={views.body}>
          <div className={styles.title}>
            <Text type={labels.primary}>
              {stringReplacer(
                Copy.CHECKOUT_STATIC.COMPLETE_ORDER_INFORMATION_MESSAGE,
                [{ replaceTarget: "{text}", replaceValue: text }],
              )}
            </Text>
          </div>
          <br />
          <div className={styles.info}>
            <Field
              style={cellStyle}
              label={fromTitle}
              value={ticketInformation.location.name}
            />
            <br />
            <br />
            <Field
              style={cellStyle}
              label={Copy.CHECKOUT_STATIC.COMPLETE_ORDER_DATE_LABEL}
              value={date}
            />
            <br />
            <br />
            <Field
              style={cellStyle}
              label={Copy.CHECKOUT_STATIC.COMPLETE_ORDER_TIME_LABEL}
              value={time}
            />
            <br />
            <br />
            <Field
              style={cellStyle}
              label={Copy.CHECKOUT_STATIC.COMPLETE_CHECK_LABEL}
              value={ticketInformation.displayNumber}
            />
          </div>
        </View>
      </Flex>
      <Flex
        justify="start"
        grow={1}
        className={styles["empty-space"]}
        style={{
          backgroundImage: `url(${images?.art_misc_1})`,
          flexBasis: "300px",
        }}
      />
      <div className={styles.footer}>
        <DrawerButton buttonProps={{ onClick: onSuccess }}>
          {Copy.CHECKOUT_STATIC.COMPLETE_BACK_TO_MENU_BUTTON_TEXT}
        </DrawerButton>
      </div>
    </View>
  );
};

export default withTemplate(Complete, "confirmation");
