import * as React from "react";
import { Layout } from "components/elements";
import css from "../base.module.scss";

const { Flex } = Layout;

const Empty = ({ children }) => (
  <Flex
    direction="col"
    align="center"
    justify="center"
    grow={1}
    className={css.empty}
  >
    <div>{children}</div>
  </Flex>
);

export default Empty;
