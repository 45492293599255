import * as React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Text, View, Button } from "components/elementsThemed";
import { Row, Col } from "@lunchboxinc/lunchbox-components";

import { withTemplate } from "components/hocs";
import { images } from "utils";
import { useCell } from "hooks";
import { REDEEM_BANK_REWARD } from "utils/api";
import DrawerButton from "../../../../DrawerButton";
import { Drawer } from "components/fragments";
import BackButton from "../../../../BackButton/BackButton";
import BankPoints from "../BankPoints";

import css from "./BankRewardsInfo.module.scss";

const BankRewardsInfo = ({ updateWallet }) => {
  const [isRewardDrawerOpen, setIsRewardDrawerOpen] = React.useState(false);
  const [rewardId, setRewardId] = React.useState("");
  const { labelTextStyles: labels, views, buttons } = useCell("bankCard");

  const toggleRewardDrawer = () => setIsRewardDrawerOpen(!isRewardDrawerOpen);
  const location = useLocation();
  const history = useHistory();
  const {
    name: title,
    additionalDescription,
    description,
    redeemAmount,
    id,
    bankId,
    isRedeemable,
  } = location.state;

  const drawerMessage = `Are you sure you want to redeem ${redeemAmount} points for ${title}?`;

  const onClick = () => {
    setRewardId(id);
    toggleRewardDrawer();
  };

  const claimReward = async () => {
    try {
      const res = await REDEEM_BANK_REWARD({
        bank: bankId,
        reward: rewardId,
      });
      if (res.status === 200) {
        const { wallet } = res.data;
        const newWallet = wallet?.promotions;
        updateWallet(newWallet);
        history.goBack();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onConfirm = () => {
    toggleRewardDrawer();
    claimReward();
  };

  const iconText = `${redeemAmount} Pts`;

  return (
    <>
      <View className={css["bank-rewards-info-section"]}>
        <View className={css["bank-rewards-top"]}>
          <div className={css["bank-rewards-back"]}>
            <BackButton
              imgSrc={images?.button_back_main}
              onClick={history.goBack}
            />
          </div>
          <BankPoints iconText={iconText} title={title} />
        </View>
        <View className={css["bankCardInfo--subheader"]}>
          <Text type={labels.secondary}>{description}</Text>
        </View>
        <div className={css["bankCardInfo--divider"]}> </div>
        <View className={css["bankCardInfo--title"]}>
          <Text type={labels.primary}>Reward Details</Text>
        </View>
        <View type={views.background} className={css["bankCardInfo--details"]}>
          <Text type={labels.secondary}>{additionalDescription}</Text>
        </View>
      </View>
      <View>
        {isRedeemable && (
          <DrawerButton buttonProps={{ onClick }}>Claim Reward</DrawerButton>
        )}
      </View>
      <Drawer.ResponsiveDrawer
        isOpen={isRewardDrawerOpen}
        onChange={setIsRewardDrawerOpen}
        ariaLabel="Loyalty Rewards"
      >
        <View
          className={css["drawer--container"]}
          type={views.drawerBackground}
        >
          <div className={css["drawer--dialogue"]}>
            <Text type={labels.drawer}>{drawerMessage}</Text>
          </div>
          <Col
            xs={{ offset: "1-12", span: "5-6" }}
            sm={{ offset: "1-8", span: "3-4" }}
            style={{
              marginLeft: 0,
            }}
          >
            <Row gutter={60}>
              <Col xs="1-2">
                <Button
                  block
                  className={css["drawer--button"]}
                  type={buttons.confirm}
                  onClick={onConfirm}
                >
                  Confirm
                </Button>
              </Col>
              <Col xs="1-2">
                <Button
                  block
                  className={css["drawer--button"]}
                  type={buttons.cancel}
                  onClick={toggleRewardDrawer}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Col>
        </View>
      </Drawer.ResponsiveDrawer>
    </>
  );
};

export default withTemplate(BankRewardsInfo, "loyalty");
