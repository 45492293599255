/* eslint-disable consistent-return */
import * as React from "react";
import { isNull } from "lodash";

const isBrowser = typeof window !== "undefined";
const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;

const getScrollPosition = ({
  element,
  useWindow,
}: {
  element?: React.MutableRefObject<HTMLElement>;
  useWindow: boolean;
}) => {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element && element.current ? element.current : document.body;

  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
};

type PositionHistory = {
  currPos: { x: number; y: number };
  prevPos: { x: number; y: number };
};

const useScrollPosition = <T>(
  effect: (v: PositionHistory) => void,
  deps: Array<T>,
  element: React.MutableRefObject<HTMLElement>,
  useWindow: boolean,
  parent: string | null = null,
  wait?: number,
) => {
  const position = React.useRef(getScrollPosition({ useWindow }));
  let throttleTimeout: number | null = null;

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow });

    effect({ currPos, prevPos: position.current });
    position.current = currPos;
    throttleTimeout = null;
  };

  useIsomorphicLayoutEffect(() => {
    if (!isBrowser) {
      return;
    }

    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = window.setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    const target = parent ? document.querySelector(parent) : window;

    // Test for null
    if (!isNull(target)) {
      target.addEventListener("scroll", handleScroll);

      return () => target.removeEventListener("scroll", handleScroll);
    }
  }, deps);
};

export default useScrollPosition;
