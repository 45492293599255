import * as React from "react";
import Styled from "styled-components";
import csx from "classnames";
import { View, Button } from "components/elementsThemed";
import { useCell, useMutationObserver } from "hooks";
import {
  enableScroll,
  disableScroll,
  onWheelHandler,
} from "utils/helpers/scroll";
import { debounce } from "utils/helpers/other";

import Option from "../../Options";
import css from "./index.module.scss";

const findError = (errors, id) => {
  const match = errors.find((i) => i.optionId === id);
  if (match) {
    return match.message;
  }
  return null;
};

const deactivateNodes = (optionIds, excluded = []) => {
  optionIds.forEach((optionId) => {
    if (!excluded.includes(optionId)) {
      const node = document.getElementById(`nav-${optionId}`);
      if (node) {
        node.dataset.active = 0;
      }
    }
  });
};

const TabButton = Styled(({ id, type, onClick, children, className }) => (
  <li id={`nav-${id}`} className={`${css["nav-item"]} ${className}`}>
    <Button className={`${css["item-button"]}`} type={type} onClick={onClick}>
      {children}
    </Button>
  </li>
))`
  ${(props) => {
    const {
      theme: { buttons },
    } = props;

    const color = buttons[props.type]
      ? buttons[props.type].stateTextStyles.selected.split("_")[1]
      : "#000";
    const hex = props.theme.colors[color];
    return `
      &[data-active="1"]{
        color: ${hex}
      }

      &[data-active="1"] span{
        color: ${hex}
      }`;
  }}
`;

const additionalOffset = 55;

const Type5 = (props) => {
  const {
    tabs,
    cells,
    errors,
    addModifier,
    removeModifier,
    menuOptions,
    modifications,
    // fix,
    optionRefs,
    handleScroll,
    containerRef,
  } = props;

  const entities = tabs.reduce((accu, dt) => [...accu, ...dt.entities], []);

  entities.sort((a, b) => b.priority - a.priority);
  tabs[0].entities = entities;

  const itemDetailsRef = containerRef?.current;

  // const isParentFixed = (() => {
  //   const parentContainer = document.getElementById('itemDetails');
  //   if (!parentContainer) return false;
  //   return parentContainer.dataset.fixed === 'true' ? 1 : 0;
  // })();

  const { views, buttons } = useCell(cells.header);

  const scrollingTabRef = React.useRef();
  const errorRefs = React.useRef({});
  const tabList = React.useRef();

  const handleHighlight = React.useRef(
    debounce((scrollTop, isScrolledToBottom) => {
      const tabListHeight =
        tabList && tabList.current ? tabList.current.clientHeight : 0;
      const triggerPoint = scrollTop + tabListHeight + additionalOffset;
      const optionIds = Object.keys(optionRefs.current);

      const aboveMidway = [];

      Object.keys(optionRefs.current).forEach((x) => {
        if (Object.prototype.hasOwnProperty.call(optionRefs.current, x)) {
          const { node } = optionRefs.current[x];
          const offset = node?.offsetTop ?? 0;
          const nodeHeight = node?.clientHeight ?? 0;
          const nodeMidwayOffset = offset - nodeHeight / 2;

          if (offset <= triggerPoint) {
            const diffFromMidway = Math.abs(nodeMidwayOffset - triggerPoint);
            aboveMidway.push({
              diff: diffFromMidway,
              nodeMidwayOffset,
              option: x,
              scrollTop,
            });
          }
        }
      });

      if (isScrolledToBottom) {
        const menuOptionId = [...optionIds].pop();
        const tabNode = document.getElementById(`nav-${menuOptionId}`);
        // deactivate any tabs not the active tab
        deactivateNodes(optionIds, [menuOptionId]);
        if (tabNode?.dataset?.active !== undefined) {
          tabNode.dataset.active = 1;
        }
      } else if (aboveMidway.length) {
        const { option: menuOptionId } = aboveMidway.reduce(
          (accu, i) => (i.diff < accu.diff ? i : accu),
          aboveMidway[0],
        );
        const tabNode = document.getElementById(`nav-${menuOptionId}`);
        const navEl = scrollingTabRef.current;
        if (navEl) {
          const navWidth = navEl?.offsetWidth;
          const navCenterOfView = navWidth / 2;
          const nodeWidth = tabNode?.offsetWidth;
          const nodeCenterPosition = tabNode?.offsetLeft + nodeWidth / 2;
          navEl.scrollTo(nodeCenterPosition - navCenterOfView, 0);
        }

        // deactivate any tabs not the active tab
        deactivateNodes(optionIds, [menuOptionId]);
        if (tabNode?.dataset?.active !== undefined) {
          tabNode.dataset.active = 1;
        }
      }
    }),
  );

  const getScrollOffset = (node) => {
    if (!node) return 0;
    if (tabList.current) {
      const tabListHeight = tabList.current.clientHeight;
      return node.offsetTop - tabListHeight - additionalOffset;
    }
    return node.offsetTop - additionalOffset;
  };

  React.useEffect(() => {
    if (!itemDetailsRef)
      return () => {
        return null;
      };

    const handler = () => {
      const scrollPos = itemDetailsRef?.scrollTop || 0;
      const isScrolledToBottom =
        itemDetailsRef?.scrollHeight - scrollPos ===
        itemDetailsRef?.clientHeight;
      if (handleHighlight)
        handleHighlight.current(scrollPos, isScrolledToBottom);
    };

    itemDetailsRef.addEventListener("scroll", handler);

    return () => {
      if (itemDetailsRef) {
        itemDetailsRef.removeEventListener("scroll", handler);
      }
    };
  }, [itemDetailsRef, handleHighlight]);

  // used to scroll to any menu option that has invalid number of selection(s).
  React.useEffect(() => {
    if (errors?.length > 0) {
      const errorOptionId = errors?.[0].optionId;
      const firstErrorRef = optionRefs?.current[errorOptionId];
      const tabListHeight = tabList?.current?.clientHeight;
      const triggerPoint = tabListHeight + additionalOffset;

      if (
        itemDetailsRef &&
        firstErrorRef?.node &&
        firstErrorRef.node !== null
      ) {
        itemDetailsRef.scrollTo({
          behavior: "smooth",
          top: firstErrorRef.node?.offsetTop - triggerPoint,
        });
      }
    }
  }, [itemDetailsRef, errors.length]);

  React.useEffect(() => {
    // Wrapping in a try catch for safety. Wings over (who uses this) is very sensitive client
    try {
      const firstTabRef = Object.entries(optionRefs?.current ?? {})?.[0]?.[1];
      if (!firstTabRef) return;

      const tabListHeight = tabList?.current?.clientHeight;
      const triggerPoint = tabListHeight + additionalOffset;

      if (itemDetailsRef && firstTabRef?.node && firstTabRef.node !== null) {
        itemDetailsRef.scrollTo({
          behavior: "smooth",
          top: firstTabRef.node?.offsetTop - triggerPoint,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [optionRefs.current]);

  React.useEffect(() => {
    const el = scrollingTabRef.current;
    if (!el)
      return () => {
        return null;
      };

    if (el.clientWidth < el.scrollWidth) {
      el.dataset.hasScroll = el.clientWidth < el.scrollWidth ? 1 : 0;
    }

    const storeScroll = debounce(() => {
      el.dataset.scroll = el.scrollLeft;
      el.dataset.atMax =
        el.scrollWidth - el.clientWidth === el.scrollLeft ? 1 : 0;
    });
    el.addEventListener("scroll", storeScroll, { passive: true });

    return function cleanup() {
      el.removeEventListener("scroll", storeScroll);
    };
  }, [scrollingTabRef.current]);

  // Used to hightlight the first tab on initial render
  React.useEffect(() => {
    if (
      tabs &&
      tabs.length &&
      tabs[0].entities &&
      tabs[0].entities.length &&
      tabs[0].entities[0]
    ) {
      const node = document.getElementById(`nav-${tabs[0].entities[0].id}`);
      if (node) {
        node.dataset.active = 1;
      }
    }
  }, []);

  useMutationObserver(
    (mutationsList) => {
      mutationsList.map((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "data-fixed"
        ) {
          if (tabList.current) {
            tabList.current.dataset.fixed = "true";
          }
        }
        return mutation;
      });
    },
    {
      attributes: true,
      selector: "#itemDetails",
    },
  );

  return (
    <div className={css["itemDetails-tabs"]}>
      <div
        ref={tabList}
        className={csx(css["itemDetails-tabs-nav-container"])}
        id="itemDetails-tabs-nav-container"
      >
        <View
          type={views.background}
          Component="ul"
          innerRef={scrollingTabRef}
          className={css.nav}
          data-scroll="0"
          onWheel={(e) => onWheelHandler(e, scrollingTabRef)}
          onMouseEnter={disableScroll}
          onMouseLeave={enableScroll}
        >
          {tabs.slice(0, 1).map((tab) =>
            tab.entities.map((option) => (
              <TabButton
                key={option.id}
                id={option.id}
                type={buttons.primary}
                onClick={() => {
                  handleScroll(option.id);
                }}
              >
                {option.name}
              </TabButton>
            )),
          )}
        </View>
        <div className={css.left}>&lsaquo;</div>
        <div className={css.right}>&rsaquo;</div>
      </div>
      <div
        className={css["itemDetails-tabs-menu-options"]}
        data-cy="itemDetail-tabs"
      >
        {tabs.slice(0, 1).map((i, tabIndex) =>
          i.entities.map((option, index) => (
            <Option
              key={option.id}
              navIndex={option.id}
              optionRef={(node) => {
                optionRefs.current[option.id] = {
                  index,
                  node,
                  optionId: option.id,
                  scrollToPosition: getScrollOffset(node),
                  tabIndex,
                };
              }}
              errorRef={(node) => {
                errorRefs.current[option.id] = {
                  index,
                  node,
                  optionId: option.id,
                  scrollToPosition: getScrollOffset(node),
                  tabIndex,
                };
              }}
              type={cells.group}
              optionItemTypes={{
                default: cells.primary,
                selected: cells.primarySelected,
              }}
              parentOption={menuOptions[option.id]}
              {...menuOptions[option.id]}
              error={findError(errors, option.id)}
              selectedMods={modifications[option.id] || []}
              addModifier={addModifier}
              removeModifier={removeModifier}
            />
          )),
        )}
      </div>
    </div>
  );
};

Type5.displayName = "TabType5";
// tabType5.whyDidYouRender = true;

export default Type5;
