export const ALREADY_HAVE_APP_LOGIN_TEXT =
  "Already have our app? Log in using the same information above";
export const EMAIL_INPUT_LABEL = "Email";
export const EMAIL_INPUT_PLACEHOLDER = "Enter Email Here";
export const ENTER_EMAIL_TEXT = "Enter your email to start";
export const ENTER_PASSWORD_TEXT = "Enter your password!";
export const FORGOT_PASSWORD_TEXT = "Forgot Password";
export const INPUT_LABEL = "Password";
export const INPUT_PLACEHOLDER = "Enter Password Here";
export const LOGIN_BUTTON_TEXT = "Login";
export const NEED_TO_SIGNUP_TEXT =
  "Looks like you're new here. Please sign up to start ordering!";
export const SIGN_UP_CONFIRMATION = "Success!";

export default {
  ALREADY_HAVE_APP_LOGIN_TEXT,
  EMAIL_INPUT_LABEL,
  EMAIL_INPUT_PLACEHOLDER,
  ENTER_EMAIL_TEXT,
  ENTER_PASSWORD_TEXT,
  FORGOT_PASSWORD_TEXT,
  INPUT_LABEL,
  INPUT_PLACEHOLDER,
  LOGIN_BUTTON_TEXT,
  NEED_TO_SIGNUP_TEXT,
  SIGN_UP_CONFIRMATION,
};
