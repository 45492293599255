import * as React from "react";
import { Row, Col } from "@lunchboxinc/lunchbox-components";
import { Condition } from "components/elements";
import { Text, Field } from "components/elementsThemed";
import { withTemplate } from "components/hocs";
import { axios, Copy } from "utils";
import { mapGoogleAddressComponents } from "utils/helpers/geo";
import { useCachedAddresses } from "../../../contexts/CachedAdresses";
import { GoogleAutoComplete, SearchResults, Search } from "./Search";
import initializeAddress from "./initializer";
import addressReducer, { parseAddress } from "./reducer";
import css from "./deliveryForm.module.scss";

const { Condition: If } = Condition;
const { Input } = Field;

const fetchPlaceById = async (placeId) => {
  try {
    const { data } = await axios.methods.get(`/places/${placeId}`, {
      orderType: "delivery",
    });
    return {
      addressComps: mapGoogleAddressComponents(data.addressComponents),
      id: placeId,
      lat: data.lat,
      long: data.long,
    };
  } catch (error) {
    console.error(error);
  }
  return () => {};
};

const DeliveryForm = ({
  style: { buttons, inputs, labels },
  address: initialAddress,
  onSelect,
  ...props
}) => {
  const inputRef = React.useRef(null);

  // TODO: Will probably simpliy this useReducer. Might not need it (2020-20-02)
  const [address] = React.useReducer(
    addressReducer,
    initializeAddress(initialAddress),
  );
  const [err, setErr] = React.useState(null);
  const { recentAddresses, updateRecentAddressesState } = useCachedAddresses();

  const onClick = (values) => {
    setErr(null);
    async function fetchPlace() {
      try {
        const data = await fetchPlaceById(values.id);
        // Whenever we have an incomplete physical address e.g. Hammes Mowbray Hall or Cornell University
        // Google api does not return street1 or street 2 fields so we will need to take the
        // "street" that the user entered, and pass that to the backend as the street1 param
        const parsedStreetOne = values.text.slice(0, values.text.indexOf(","));
        // this just keeps the data google gives us back and overwrites the street1 address if it is blank
        const parsedAddress = parseAddress({
          ...data,
          addressComps: {
            ...data.addressComps,
            street1: data.addressComps.street1 || parsedStreetOne,
          },
        });
        if (parsedAddress.isValid) {
          onSelect(parsedAddress);
          updateRecentAddressesState(values);
        } else {
          setErr("Unable to deliver to the address you've provided.");
        }
      } catch (error) {
        setErr(axios.handleError(error));
      }
    }
    if (values.id) {
      fetchPlace();
    }
  };

  return (
    <GoogleAutoComplete.Provider address={address.text} orderType="delivery">
      <>
        <Row gutter={15} className={css.deliveryForm}>
          <Col xs="2-3">
            <Search type={inputs.standard} inputRef={inputRef} />
          </Col>
          <Col xs="1-3">
            <Input
              className={[css.aptInfoInput, "input-delivery"]}
              label={Copy.DELIVERY_FORM_STATIC.APARTMENT_SUIT_INPUT_LABEL}
              type={inputs.standard}
              placeholder={
                Copy.DELIVERY_FORM_STATIC.APARTMENT_SUIT_INPUT_PLACEHOLDER
              }
              value={initialAddress.street2}
              onChange={(e) => {
                props.setStreet2(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SearchResults
              type={buttons.searchResults}
              onSelect={onClick}
              recentAddresses={recentAddresses}
            />
          </Col>
        </Row>
        <If is={err}>
          <Row>
            <Col>
              <Text type={labels.error}>
                {Copy.DELIVERY_FORM_STATIC.FORM_ADDRESS_UNAVAILABLE_MESSAGE}
              </Text>
            </Col>
          </Row>
        </If>
      </>
    </GoogleAutoComplete.Provider>
  );
};

export default withTemplate(DeliveryForm, "address");
