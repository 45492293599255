import * as React from "react";
import { parseTipInput } from "./helpers";

export type InputProps = {
  customAmount: number;
  handleCustomAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setCustomAmount: (t: number) => void;
  inputRef: React.RefObject<HTMLInputElement>;
};
const CustomInput: React.FC<InputProps> = ({
  customAmount,
  handleCustomAmount,
  setCustomAmount,
  inputRef,
}) => {
  const [moneyVal, setMoneyVal] = React.useState();
  // auto focus input
  React.useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (Number.isNaN(value)) setCustomAmount(0);
    setCustomAmount(parseTipInput(value));
  };

  return (
    <>
      <label htmlFor="dollar-sign-input">$</label>
      <input
        ref={inputRef}
        type="number"
        value={customAmount || ""}
        inputMode="decimal"
        pattern="[0-9]*"
        step={0.01}
        min={0}
        max={999.99}
        name="currency"
        placeholder="0.00"
        onInput={onInput}
        aria-label="custom-tip-input"
        onChange={onInput}
        onBlur={() => setCustomAmount(parseFloat(customAmount.toFixed(2)))}
        data-testid="custom-tip-input"
      />
    </>
  );
};

export { CustomInput };
