import * as React from "react";
import Styled from "styled-components";
import classnames from "classnames";
import styles from "./link.module.css";

const LinkBase = Styled(({ className, letterCase, children, ...props }) => {
  const classes = classnames(
    styles.link,
    styles[letterCase] ? styles[letterCase] : undefined,
    className,
  );
  return (
    <a className={classes} {...props}>
      {children}
    </a>
  );
})`
  font-family: ${(props) => props.theme.fonts.title2.name};
  font-size: ${(props) => `${props.theme.fonts.title2.size / 16}rem`};
`;
const NavLink = Styled(LinkBase)`
  color: ${(props) => props.theme.colors.baseWhite};
  font-family: ${(props) => props.theme.fonts.subheader1.name};
  font-size: ${(props) => `${props.theme.fonts.subheader1.size / 16}rem`};
`;

export { NavLink };
export default {
  NavLink,
};
