export const DefaultImages = {
  app_store_download: "https://assets.lunchbox.io/shared/images/empty.svg",
  art_background: "",
  art_empty: "https://assets.lunchbox.io/munchbox/images/art_empty_default.svg",
  art_empty_cart:
    "https://assets.lunchbox.io/munchbox/images/art_empty_default.svg",
  art_empty_locations:
    "https://assets.lunchbox.io/munchbox/images/art_empty_default.svg",
  art_error: "https://assets.lunchbox.io/munchbox/images/art_empty_default.svg",
  art_footer_rewards:
    "https://assets.lunchbox.io/munchbox/images/art_footer_rewards.png",
  art_gift_card: "https://assets.lunchbox.io/munchbox/images/art_gift_card.svg",
  art_gift_card_logo:
    "https://assets.lunchbox.io/munchbox/images/art_gift_card_logo.svg",
  art_item_placeholder:
    "https://assets.lunchbox.io/munchbox/images/art_item_placeholder.jpg",
  art_location: "https://assets.lunchbox.io/munchbox/images/art_location.svg",
  art_logo: "https://assets.lunchbox.io/munchbox/images/art_logo.svg",
  art_logo_2: "https://assets.lunchbox.io/munchbox/images/art_logo_2.svg",
  art_menu_header:
    "https://assets.lunchbox.io/munchbox/images/art_menu_header.jpg",
  art_misc: "https://assets.lunchbox.io/munchbox/images/art_misc.svg",
  art_misc_1: "https://assets.lunchbox.io/munchbox/images/art_misc_1.svg",
  art_misc_loyalty: "https://assets.lunchbox.io/shared/images/empty.svg",
  art_order_confirmation:
    "https://assets.lunchbox.io/munchbox/images/art_order_confirmation.svg",
  art_order_confirmation_kiosk:
    "https://assets.lunchbox.io/munchbox/images/art_order_confirmation.svg",
  art_search:
    "https://assets.lunchbox.io/munchbox/images/art_empty_default.svg",
  button_arrow: "https://assets.lunchbox.io/shared/images/button_arrow.png",
  button_back: "https://assets.lunchbox.io/munchbox/images/button_back.svg",
  button_back_checkout:
    "https://assets.lunchbox.io/munchbox/images/button_back.svg",
  button_back_item_details:
    "https://assets.lunchbox.io/munchbox/images/button_back.svg",
  button_back_login:
    "https://assets.lunchbox.io/munchbox/images/button_back.svg",
  button_back_main:
    "https://assets.lunchbox.io/munchbox/images/button_back_white.svg",
  button_back_main_secondary:
    "https://assets.lunchbox.io/munchbox/images/button_back.svg",
  button_back_profile:
    "https://assets.lunchbox.io/munchbox/images/button_back.svg",
  button_dialog_close:
    "https://assets.lunchbox.io/shared/images/button_dialog_close.svg",
  button_price_clear:
    "https://assets.lunchbox.io/shared/images/button_price_clear.svg",
  chevron_right:
    "https://assets.lunchbox.io.s3.amazonaws.com/shared/images/chevron-right.png",
  google_play_store_download:
    "https://assets.lunchbox.io/shared/images/google_play_store_download.svg",
  guest_checkout:
    "https://assets.lunchbox.io/munchbox/images/guest_checkout.svg",
  guests: "https://assets.lunchbox.io/munchbox/images/art_logo.svg",
  icon_apple_pay: "https://assets.lunchbox.io/shared/images/icon_apple_pay.svg",
  icon_bank: "https://assets.lunchbox.io/shared/images/icon_bank.svg",
  icon_credit_card:
    "https://assets.lunchbox.io/shared/images/icon_credit_card.svg",
  icon_burger_menu: "https://assets.lunchbox.io/revival/images/icon_burger.svg",
  icon_cart: "https://assets.lunchbox.io/munchbox/images/icon_cart.svg",
  icon_close: "https://assets.lunchbox.io/shared/images/icon_close.svg",
  icon_check: "https://assets.lunchbox.io/munchbox/images/icon_check.svg",
  icon_empty_cart:
    "https://assets.lunchbox.io/munchbox/images/icon_empty_cart.svg",
  icon_geo: "https://assets.lunchbox.io/munchbox/images/icon_geo.svg",
  icon_login: "https://assets.lunchbox.io/munchbox/images/icon_login.svg",
  icon_login_1: "https://assets.lunchbox.io/munchbox/images/icon_login_1.svg",
  icon_logout: "https://assets.lunchbox.io/munchbox/images/icon_logout.svg",
  icon_loyalty_header:
    "https://assets.lunchbox.io/shared/images/icon_loyalty_header.svg",
  icon_pill_delivery:
    "https://assets.lunchbox.io/shared/images/icon_pill_delivery.svg",
  icon_pill_pickup:
    "https://assets.lunchbox.io/shared/images/icon_pill_pickup.svg",
  icon_placement_right:
    "https://assets.lunchbox.io/shared/images/icon_placement_right.svg",
  icon_placement_left:
    "https://assets.lunchbox.io/shared/images/icon_placement_left.svg",
  icon_placement_whole:
    "https://assets.lunchbox.io/shared/images/icon_placement_whole.svg",
  icon_profile: "https://assets.lunchbox.io/munchbox/images/icon_profile.svg",
  icon_tooltip_question:
    "https://assets.lunchbox.io/shared/images/icon_tooltip_question.svg",
  icon_tracker_progress:
    "https://assets.lunchbox.io/shared/images/icon_tracker_progress.svg",
  icon_tracker_progress_complete:
    "https://assets.lunchbox.io/shared/images/icon_tracker_progress_complete.svg",
  indicator_pin_delivery:
    "https://assets.lunchbox.io/munchbox/images/indicator_pin_delivery.svg",
  indicator_pin_driver:
    "https://assets.lunchbox.io/shared/images/indicator_pin_driver.svg",
  indicator_pin_location:
    "https://assets.lunchbox.io/munchbox/images/indicator_pin_location.svg",
  loyalty_rewards_stub_icon:
    "https://assets.lunchbox.io/munchbox/images/loyalty_rewards_stub_icon.svg",
  lunchbox_logo_footer: "https://assets.lunchbox.io/shared/images/empty.svg",
  nav_button_underline:
    "https://assets.lunchbox.io/nascar/images/nav_button_underline.svg",
  signup_confirmation: "https://assets.lunchbox.io/shared/images/empty.svg",
  signup_header: "https://assets.lunchbox.io/shared/images/empty.svg",
  signup_loyalty_header: "https://assets.lunchbox.io/shared/images/empty.svg",
  yellow_star: "http://assets.lunchbox.io/shared/images/yellow-star.png",
  table_number: "https://assets.lunchbox.io/munchbox/images/art_logo.svg",
  web_banner: "https://assets.lunchbox.io/shared/images/empty.svg",
  modifier_item_remove: "",
};

export default DefaultImages;
