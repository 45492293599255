import * as Yup from "yup";

const PatronNameSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Email is not valid")
    .required("Email is required"),
  firstName: Yup.string().required("First Name is Required"),
  lastName: Yup.string().required("Last Name is Required"),
  birthday: Yup.date().max(new Date(), "Please enter a valid birthday"),
});

export default PatronNameSchema;
