import * as React from "react";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { useButton } from "@react-aria/button";

interface ModalButtonProps {
  type?: string;
  onClick: () => void;
  buttonLabel?: string;
}

function ModalButton({ type, onClick, buttonLabel }: ModalButtonProps) {
  const state = useOverlayTriggerState({});
  const closeButtonRef = React.useRef();
  const openButtonRef = React.useRef();

  // useButton ensures that focus management is handled correctly,
  // across all browsers. Focus is restored to the button once the
  // dialog closes.
  const { buttonProps: openButtonProps } = useButton(
    {
      onPress: () => state.open(),
    },
    openButtonRef,
  );

  const { buttonProps: closeButtonProps } = useButton(
    {
      onPress: () => state.close(),
    },
    closeButtonRef,
  );

  if (type === "open") {
    return (
      <button
        {...openButtonProps}
        ref={openButtonRef}
        onClick={onClick}
        type="button"
      >
        {buttonLabel}
      </button>
    );
  }

  return (
    <button
      {...closeButtonProps}
      ref={closeButtonRef}
      style={{ marginTop: 10 }}
      onClick={onClick}
      type="button"
    >
      {buttonLabel}
    </button>
  );
}

ModalButton.defaultProps = {
  type: "",
  buttonLabel: "",
};

export default ModalButton;
