import * as React from "react";
import { useMenuContext } from "components/providers/Menu/MenuContext";
import { ConditionalWrapper } from "components/elements";
import { config, Copy } from "utils";
import { formatPrice } from "utils/helpers/string";
import { populateMods, formatModifierPrice } from "./utils";
import css from "./cartItem.module.scss";

const { images: configImages } = config;
const { EDIT_BUTTON_TEXT, REMOVE_BUTTON_TEXT } = Copy.CART_STATIC;

const withCartItem = (Component) => (props) => {
  const {
    item,
    group,
    price,
    mods,
    isValid,
    edit,
    isRewardItem,
    notes,
    cellType,
    remove,
    removable = true,
  } = props;

  const { isGroup } = props;
  const { itemsHash, groupsHash, upsellsItemsHash } = useMenuContext();

  const entityInformationMap = {
    group: groupsHash[group],
    item: itemsHash[item],
    unavailable: () => {
      const cartItem = JSON.parse(localStorage.getItem("items") ?? "[]").find(
        (el) => el.item === item,
      );
      return {
        description: cartItem?.description || "",
        name: cartItem?.name || "item unavailable",
      };
    },
  };

  const entityInformation =
    entityInformationMap[isGroup ? "group" : "item"] ??
    entityInformationMap.unavailable();

  const mapModItem = (modItem) => ({
    name: modItem.name,
    price: formatModifierPrice(modItem.price, modItem.quantity),
  });

  const modifiers = React.useMemo(() => {
    let populatedMods = [];

    if (Object.keys(itemsHash).includes(item)) {
      populatedMods = populateMods(mods, itemsHash).reduce(
        (accu, { items = [] }) => {
          return [...accu, ...items.map(mapModItem)];
        },
        [],
      );
    }

    if (Object.keys(upsellsItemsHash).includes(item)) {
      populatedMods = populateMods(mods, upsellsItemsHash).reduce(
        (accu, { items = [] }) => {
          return [...accu, ...items.map(mapModItem)];
        },
        [],
      );
    }

    return [
      isGroup
        ? {
            name: itemsHash[item]?.name ?? "",
            price: "",
          }
        : {},
      ...populatedMods,
    ];
  }, [mods]);

  const generateModsArray = (mods) =>
    mods
      ?.reduce((acc, curr) => {
        return [...acc, ...curr.modifiers];
      }, [])
      .map((mod) => {
        // first check whether it has item key, if not then it is a option level modifier
        if (!Boolean(mod?.item)) {
          return generateModsArray(mod.modifiers);
        }

        const { item, modifiers } = mod;
        const { name, price } = itemsHash?.[item] || { name: "", price: 0 };

        // check whether it has modifiers, if not then it is a final level item modifier
        if (!modifiers.length) {
          return Boolean(price) ? `${name} +$${price} ` : `${name} `;
        }

        return Boolean(price)
          ? `${name} (${generateModsArray(modifiers)}) +$${price}`
          : `${name} (${generateModsArray(modifiers)})`;
      });

  const modsStringArray = generateModsArray(mods).map((mod) => ({
    name: mod,
    price: "",
  }));

  const generateButtonActions = ({ isEditable, isRemovable }) => {
    const buttonsActions = [];
    if (isEditable) {
      buttonsActions.push({
        action: edit,
        content: EDIT_BUTTON_TEXT,
        type: "primary",
      });
    }
    if (isRemovable) {
      buttonsActions.push({
        action: remove,
        content: REMOVE_BUTTON_TEXT,
        type: "secondary",
      });
    }
    return buttonsActions;
  };

  const buttonsActions = generateButtonActions({
    isEditable: isValid,
    isRemovable: removable,
  });
  const { images = [], name = "", description = "" } = entityInformation;
  const cardImage = images[0]
    ? images[0].replace("http://", "https://")
    : configImages?.art_item_placeholder;

  const dynamicPrice = isRewardItem
    ? `${props?.redeemAmount} ${Copy.MENU_STATIC.LOYALTY_UNIT}`
    : formatPrice(price);

  return (
    <ConditionalWrapper
      condition={!isValid}
      wrapper={(children) => <div className={css.invalidItem}>{children}</div>}
    >
      <Component
        themeType={config?.theme?.checkout?.cart_item || "Type1"}
        isGroup={isGroup}
        actionButtons={buttonsActions}
        title={name}
        price={dynamicPrice}
        description={description}
        items={modsStringArray}
        imageSrc={cardImage}
        notes={notes}
        cellType={cellType}
      />
    </ConditionalWrapper>
  );
};

export default withCartItem;
