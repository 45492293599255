import * as React from "react";

import cx from "classnames";

import css from "./tooltip.module.scss";

interface ToolTipProps {
  ariaLabel?: string;
  ariaDescribedby?: string;
  children: React.ReactElement;
  direction?: string;
  message?: string;
}

const TOOLTIP_DIRECTIONS = {
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right",
  TOP: "top",
};

const directionClass = (dir = TOOLTIP_DIRECTIONS.TOP) => {
  switch (dir) {
    case TOOLTIP_DIRECTIONS.LEFT:
      return css.left;
    case TOOLTIP_DIRECTIONS.RIGHT:
      return css.right;
    case TOOLTIP_DIRECTIONS.BOTTOM:
      return css.bottom;
    default:
      return css.top;
  }
};

/**
 * Renders a tooltip that only appears whne the child of this component is rendered
 *
 * @todo Implement the arrow rendering for a tooltip based on direction
 * @param {object} props
 * @param {string} props.ariaLabel - aria-label
 * @param {object} props.children - The element that should have the tool tip rendered when hover
 * @param {string} props.direction - Direction to render tooltip. Valid values are top, bottom, right, left. Default is top
 * @param {string} props.message - Message to be displayed in the tooltip
 * @param {string} props.ariaDescribedby - dynamic id for aria-describeby
 */

function Tooltip({
  ariaLabel,
  ariaDescribedby,
  children,
  direction = TOOLTIP_DIRECTIONS.TOP,
  message,
}: ToolTipProps) {
  const [expanded, setExpanded] = React.useState(false);
  const [tooltipMessage, setTooltipMessage] = React.useState(message);

  const onTooltipClick = () => {
    if (expanded) {
      setTooltipMessage("");
      setExpanded(false);
    } else {
      setTooltipMessage(message);
      setExpanded(true);
    }
  };

  const handleMouseEnter = () => {
    setTooltipMessage(message);
    setExpanded(true);
  };

  const handleMouseLeave = () => {
    setTooltipMessage("");
    setExpanded(false);
  };

  return (
    <div className={css.tooltip}>
      <button
        className={css["tooltip-icon"]}
        data-tooltip={tooltipMessage}
        aria-label={ariaLabel}
        onClick={onTooltipClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleMouseEnter}
        onBlur={handleMouseLeave}
        aria-describedby={expanded ? ariaDescribedby : undefined}
        type="submit"
      >
        {children}
      </button>
      {expanded && (
        <p
          id={ariaDescribedby}
          className={cx(css["tooltip-message"], directionClass(direction))}
          role="tooltip"
        >
          {tooltipMessage}
        </p>
      )}
    </div>
  );
}

Tooltip.defaultProps = {
  ariaLabel: "",
  ariaDescribedby: "tooltip-message",
  direction: "",
  message: "",
};

export default Tooltip;
