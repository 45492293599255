import * as React from "react";
import classnames from "classnames";
import css from "./text.module.scss";

interface TextProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  type?: string;
  children?: React.ReactNode;
  className?: string;
  block?: boolean;
  style?: React.CSSProperties;
  as?: React.ElementType;
}

function Text({
  type,
  children,
  className = undefined,
  as: Component = "span",
  ...props
}: TextProps) {
  const classes = classnames(
    props.block ? css.block : undefined,
    className,
    type && css[type],
    css.text,
  );

  return (
    <Component className={classes} {...props} data-value={type}>
      {children}
    </Component>
  );
}

Text.defaultProps = {
  type: "primary",
  as: "span",
  block: false,
  className: "",
  style: {},
  children: null,
};

export { Text };
export default React.memo(Text);
