import * as React from "react";
import { Formik } from "formik";
import { useId } from "@react-aria/utils";
import { Schemas, Copy, config, images } from "utils";
import { Text } from "../../../components/elementsThemed/Text";
import { Button } from "../../../components/elementsThemed/Button";
import { Tooltip } from "../../../components/elements/Tooltip";
import { Image } from "../../../components/fragments/Image";
import { Condition as If } from "../../../components/elements/Condition";
import css from "../checkoutType2.module.scss";

const hasFirstName =
  config.theme.checkout.guest_checkout_fields.includes("firstName");
const hasLastName =
  config.theme.checkout.guest_checkout_fields.includes("lastName");
const hasEmail = config.theme.checkout.guest_checkout_fields.includes("email");
const hasPhone = config.theme.checkout.guest_checkout_fields.includes("phone");

const InformationTab = ({
  theme,
  formData,
  hasSubmitButton = false,
  innerRef,
  inputHandler,
  onSuccess,
}) => {
  // Constants
  const { labels, buttons } = theme;
  const elementId = useId();

  // Actions
  const handleInput = ({ handler, event, errors }) => {
    // Use Formik Handler
    if (typeof handler === "function" && event) {
      handler(event);
    }
    inputHandler(errors);
  };
  const handleFormSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    if (typeof onSuccess === "function") {
      onSuccess(values);
    }
  };

  return (
    <Formik
      initialValues={formData}
      validationSchema={Schemas.GuestCheckoutSchema}
      onSubmit={handleFormSubmit}
      innerRef={innerRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className={css["checkout-field"]}>
            <div className={css["checkout-fields"]}>
              <If is={hasFirstName}>
                <div className={css["checkout-field"]}>
                  <label htmlFor="firstName">
                    First Name
                    <input
                      id="firstName"
                      type="text"
                      name="firstName"
                      onChange={(e) =>
                        handleInput({
                          errors,
                          event: e,
                          handler: handleChange,
                        })
                      }
                      onBlur={(e) =>
                        handleInput({
                          errors,
                          event: e,
                          handler: handleBlur,
                        })
                      }
                      value={values.firstName}
                      placeholder="First Name"
                      className={css["checkout-field--input"]}
                      required
                    />
                  </label>
                  {errors.firstName && touched.firstName && (
                    <Text type={labels.error}>{errors.firstName}</Text>
                  )}
                </div>
              </If>
              <If is={hasLastName}>
                <div className={css["checkout-field"]}>
                  <label htmlFor="lastName">
                    Last Name
                    <input
                      id="lastName"
                      type="text"
                      name="lastName"
                      onChange={(e) =>
                        handleInput({
                          errors,
                          event: e,
                          handler: handleChange,
                        })
                      }
                      onBlur={(e) =>
                        handleInput({
                          errors,
                          event: e,
                          handler: handleBlur,
                        })
                      }
                      value={values.lastName}
                      placeholder="Last Name"
                      className={css["checkout-field--input"]}
                      required
                    />
                  </label>
                  {errors.lastName && touched.lastName && (
                    <Text type={labels.error}>{errors.lastName}</Text>
                  )}
                </div>
              </If>
            </div>
          </div>
          <If is={hasEmail}>
            <div className={css["checkout-field"]}>
              <label htmlFor="email">
                Email
                <input
                  id="email"
                  type="email"
                  name="email"
                  onChange={(e) =>
                    handleInput({ errors, event: e, handler: handleChange })
                  }
                  onBlur={(e) =>
                    handleInput({ errors, event: e, handler: handleBlur })
                  }
                  value={values.email}
                  placeholder="Email"
                  className={css["checkout-field--input"]}
                  required
                  inputMode="email"
                />
                <div className={css["checkout-field-tooltip"]}>
                  <Tooltip
                    ariaLabel="guest information disclaimer"
                    ariaDescribedby={elementId}
                    message={Copy.ORDER_STATIC.GUESTS_INFORMATION_DISCLAIMER}
                    direction="left"
                  >
                    <Image
                      src={images?.icon_tooltip_question}
                      className={css["checkout-field-tooltip-icon"]}
                    />
                  </Tooltip>
                </div>
              </label>
              {errors.email && touched.email && (
                <Text type={labels.error}>{errors.email}</Text>
              )}
            </div>
          </If>
          <If is={hasPhone}>
            <div className={css["checkout-field"]}>
              <label htmlFor="phone">
                Phone Number
                <input
                  type="tel"
                  name="phone"
                  onChange={(e) =>
                    handleInput({
                      errors,
                      event: e,
                      handler: handleChange,
                    })
                  }
                  onBlur={(e) =>
                    handleInput({
                      errors,
                      event: e,
                      handler: handleBlur,
                    })
                  }
                  value={values.phone}
                  placeholder="Phone number"
                  className={css["checkout-field--input"]}
                  required
                />
                <div className={css["checkout-field-tooltip"]}>
                  <Tooltip
                    message={Copy.ORDER_STATIC.GUESTS_INFORMATION_PHONE_MESSAGE}
                    direction="left"
                  >
                    <Image
                      src={images?.icon_tooltip_question}
                      className={css["checkout-field-tooltip-icon"]}
                    />
                  </Tooltip>
                </div>
              </label>
              {errors.phone && touched.phone && (
                <Text type={labels.error}>{errors.phone}</Text>
              )}
            </div>
          </If>
          {hasSubmitButton && (
            <Button
              htmlType="submit"
              className={css["checkout-submit"]}
              type={buttons.primary}
            >
              {Copy.ORDER_STATIC.GUESTS_NEXT_PAYMENT}
            </Button>
          )}
        </form>
      )}
    </Formik>
  );
};

export default InformationTab;
